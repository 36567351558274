import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '../Shared/user.service';
import { Users } from '../models/users';
import { LoginService } from '../Shared/login.service';
import { Country } from '../models/country';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ConfigService } from '../app.config';

@Component({
  selector: 'app-usermanageplan',
  templateUrl: './usermanageplan.component.html',
  styleUrls: ['./usermanageplan.component.css']
})
export class UsermanageplanComponent implements OnInit {
  address: string;
  country: Array<Country> = [];
  userStreetAddressLine1: string = "";
  userStreetAddressLine2: string = "";
  userCity: string;
  userState: string;
  userZipCode: number;
  countryId: number;
  userDetails = new Users();
  user = new Users();
  btnName = "Add";
  id: number;
  planNames: any;
  userSubscriptionId: any;
  userSubscriptionDetails: any;
  currentPlanName: any;
  firstPart: any;
  secondPart: any;
  CurrentPrice: any;
  isFamilyColor: boolean = false;
  baseUrl: string = '';
  inviteUrl: string = '';
  parentId: number;
  memberDetail: any[];
  memberDetailsCount: number;
  userindicateImg = '../../../assets/images/newhomeIcon/accountlogo.svg';
  noUserindicateImg = '../../assets/images/newhomeIcon/accountgreylogo.svg';
  isFamilymemb = 5;
  isDuomemb = 2;
  maxSubImages: number;
  @Input() membersCount: number | null = null;
  availablecount: number;
  isLinkDisplay: boolean = true;
  guid: string = '';

  constructor(private _loginService: LoginService, private _userService: UserService,
    private toastr: ToastrService, private router: Router, public userService: UserService, private configService: ConfigService) {
    this.baseUrl = configService.getServiceBaseUrl();
    //this.baseUrl = 'http://localhost:4200/';
  }

  ngOnInit() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    debugger;
    //this.address='karsta 904, 705 98 Lillkarya, Sweden';
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.id = this.userDetails["userId"];
    this.guid = this.generateShortGuid(this.id);
    this.parentId = this.userDetails.parentId;
    this.getusermemberDetails();
    this.inviteUrl = this.baseUrl + 'invitemember' + '/' + this.guid;
    this.userSubscriptionId = parseInt(this.userDetails.subscriptionPlanId, 10);
    this.userSubscriptionDetails = this.userDetails.subscriptionPlans.filter(x => x.subscriptionPlanId == this.userSubscriptionId);
    this.CurrentPrice = this.userSubscriptionDetails[0].price;
    if (this.userSubscriptionDetails[0].planName == 'Toby Family') {
      this.isFamilyColor = true;
      this.currentPlanName = this.userSubscriptionDetails[0].planName.slice(4, 20);
    }
    else {
      this.isFamilyColor = false;
      this.currentPlanName = this.userSubscriptionDetails[0].planName.slice(4, 20);
    }
    this.getAllCountry();
  }
  getAllCountry() {
    this._loginService.getAllCountries().subscribe((data: any) => {
      this.country = data.country;
      this.displayCurrentAddress();
    })
  }

  displayCurrentAddress() {
    this._userService.GetUserDetails(this.id).subscribe((userInfo: any) => {
      console.log(userInfo);
      this.user = userInfo.userDetail;
      let country: any = this.country.find(x => x.counrtyId == userInfo.userDetail.countryId);
      if (!this.user.pinCode) {
        this.user.pinCode = '';
      }
      this.address = "";
      if (userInfo.userDetail.address != '.' && userInfo.userDetail.address) {
        if (userInfo.userDetail.address.includes(".")) {
          let addresses = userInfo.userDetail.address.split('.');
          console.log(addresses);
          this.userStreetAddressLine1 = addresses[0];
          this.userStreetAddressLine2 = addresses[1];

          if (addresses[0] && addresses[1]) {
            userInfo.userDetail.address = `${userInfo.userDetail.address.replace(".", ",")}`;
            this.address = `${userInfo.userDetail.address}`;
          }
          else {
            userInfo.userDetail.address = `${userInfo.userDetail.address.replace(".", "")},`;
            this.address = `${userInfo.userDetail.address}`;
          }
        }
        //else{
        else {
          this.address = `${userInfo.userDetail.address}`;
          this.userStreetAddressLine1 = this.address;
        }
        if (userInfo.userDetail.city || userInfo.userDetail.states || country) {
          this.address = `${userInfo.userDetail.address},`;
        }

      }
      //  }
      if (userInfo.userDetail.city && !userInfo.userDetail.states && !country) {
        this.address = this.address + `${userInfo.userDetail.city} `;
      }
      if (userInfo.userDetail.city && userInfo.userDetail.states && !country) {
        this.address = this.address + `${userInfo.userDetail.city}, `;
      }
      if (userInfo.userDetail.city && !userInfo.userDetail.states && country) {
        this.address = this.address + `${userInfo.userDetail.city}, `;
      }
      if (userInfo.userDetail.city && userInfo.userDetail.states && country) {
        this.address = this.address + `${userInfo.userDetail.city}, `;
      }
      if (userInfo.userDetail.states && !country) {
        this.address = this.address + `${userInfo.userDetail.states} `;
      }
      if (userInfo.userDetail.states && country) {
        this.address = this.address + `${userInfo.userDetail.states}, `;
      }
      if (country) {
        this.address = this.address + `${country.countryName}`;
      }
      if ((userInfo.userDetail.address || country || userInfo.userDetail.states || userInfo.userDetail.city) && (userInfo.userDetail.pinCode != '')) {
        this.address = this.address + `-${userInfo.userDetail.pinCode}`;
      }
      if (!userInfo.userDetail.address && !country && !userInfo.userDetail.states && !userInfo.userDetail.city && userInfo.userDetail.pinCode != '') {
        this.address = this.address + `${userInfo.userDetail.pinCode}`;
      }
      // if(userInfo.userDetail.pinCode){
      //   this.address=this.address+`${userInfo.userDetail.pinCode} `;
      // }
      if (userInfo.userDetail.address || userInfo.userDetail.city || userInfo.userDetail.states || country || userInfo.userDetail.pinCode) {
        this.btnName = "Change";
      }
      else {
        this.address = 'Please add your address.';//default address
        this.btnName = "Add";
      }
      console.log(this.address);
    })
  }
  onSubmitforupdate(f) {
    debugger;
    console.log(f.value, this.userStreetAddressLine1, this.userCity, this.user);
    this.user.userId = this.userDetails["userId"];

    if (this.userStreetAddressLine1 && this.userStreetAddressLine2) {
      this.user.Address = this.userStreetAddressLine1 + '.' + this.userStreetAddressLine2;
    }
    else if (this.userStreetAddressLine1 && !this.userStreetAddressLine2) {
      this.user.Address = this.userStreetAddressLine1
    }
    else if (!this.userStreetAddressLine1 && this.userStreetAddressLine2) {
      this.user.Address = '.' + this.userStreetAddressLine2
    }
    else {
      this.user.Address = null;
    }
    //  this.user.State=this.userState;
    //  this.user.pinCode=this.userZipCode;
    //  this.user.city=this.userCity;
    //  this.user.countryId=this.countryId;
    this.user.pinCode = Number(this.user.pinCode)
    let country: any = this.country.find(x => x.counrtyId == this.user.countryId);
    if (country) {
      this.user.countryId = this.user.countryId;
    }
    else {
      this.user.countryId = 0;
    }
    if (this.user.city == "") {
      this.user.city = null;
    }
    if (this.user.states == "") {
      this.user.states = null;
    }
    if (this.user.Address == null && this.user.city == null && this.user.countryId == 0 && this.user.states == null && this.user.pinCode == 0) {
      this.toastr.warning("Address field empty!.");
    }
    else {
      this._userService.AddUpdateUserAddress(this.user).subscribe((details: any) => {
        console.log(details)
        if (details.isSuccess) {
          this.toastr.success(details.message);
          this.displayCurrentAddress();
          //  this.router.navigate(['account/usermanagemember']);
          //  this.address=`${this.userStreetAddressLine1},${country.countryName}`;
        }
      })
    }
  }


  shareViaMessenger() {
    debugger
    // const linkToShare = 'https://localhost:44304/'; // Replace with your actual URL
    // this.userService.getFacebookShareUrl(linkToShare);
    const linkToShare = 'https://localhost:44304/'; // Replace with your actual URL
    //const fbShareUrl = this.userService.getFacebookShareUrl(linkToShare);
    //window.open(fbShareUrl, '_blank');
  }
  dynamicURL: string = 'http://localhost:4200/account/userinvite';

  sendWhatsAppMessage() {
    if (this.userSubscriptionDetails[0].planName == 'Toby Family') {
      if (this.memberDetailsCount == 4) {
        this.toastr.warning('No Available Member to Invite, please remove any one Member to Invite');
      }
      else {
        const message = 'You have been invited to Toby Music. Check out this link';
        const url = this.inviteUrl;
        const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}%20${encodeURIComponent(url)}`;

        window.open(whatsappUrl, '_blank');
      }
    }
    // const phoneNumber = '8870334157'; // Replace with the recipient's phone number
    // const message = 'Check out this link: https://example.com'; // Replace with your message and link
    // const encodedMessage = encodeURIComponent(message);
    // const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

    // window.open(whatsappUrl, '_blank');
    // const message = `You have been invited to Toby Music. Check out this link: ${this.dynamicURL}`;
    // const encodedMessage = encodeURIComponent(message);
    // const whatsappUrl = `https://wa.me/?text=${encodedMessage}`;

    // window.open(whatsappUrl, '_blank');

  }

  sendEmail(): void {
    if (this.userSubscriptionDetails[0].planName == 'Toby Family') {
      if (this.memberDetailsCount == 4) {
        this.toastr.warning('No Available Member to Invite, please remove any one Member to Invite');
      }
      else {
        const subject = 'You have been invited to Toby Music.';
        const body = 'Check out this link';
        const formattedBody = `Check out this link: ${encodeURIComponent(this.inviteUrl)}`;
        const mailtoUrl = `mailto:?subject=${encodeURIComponent(subject)}&body=${formattedBody}`;
        // const subject = 'You have been invited to Toby Music.';
        // const body = 'Check out this link: https://uat.toby.audio/account/userinvite';
        // const mailtoUrl = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

        window.location.href = mailtoUrl;
      }
    }


  }

  getusermemberDetails() {
    this.userService.GetMemberDetailsbyId(this.id).subscribe((data: any) => {
      console.log(data);
      if (data.isSuccess) {
        this.memberDetail = data.memberDetails;
        this.memberDetailsCount = data.memberDetails.length;
        this.availablecount = 4 - this.memberDetailsCount;
      }
      if (this.userSubscriptionDetails[0].planName == 'Toby Family') {
        this.maxSubImages = 4;
        if (this.memberDetailsCount == 4) {
          this.isLinkDisplay = false;
        }
        else {
          this.availablecount = 4;
          this.memberDetailsCount = 0;
        }
      }
      else {
        this.maxSubImages = 1;
        if (this.memberDetailsCount == 2) {
          this.isLinkDisplay = false;
        }
        else {
          this.availablecount = 1;
          this.memberDetailsCount = 0;
        }
      }
    });
  }

  generateShortGuid(id: number): string {
    const s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    };

    const idHex = id.toString(16).padStart(4, '0'); // Convert ID to hex and pad it to 4 characters
    const shortGuid = `${s4()}-${s4()}-${idHex}`;
    return shortGuid;
  }

  copyLink() {
    if (this.userSubscriptionDetails[0].planName == 'Toby Family') {
      if (this.memberDetailsCount == 4) {
        this.toastr.warning('No Available Member to Invite, please remove any one Member to Invite');
      }
      else {
        const textField = document.createElement('textarea');
        textField.innerText = this.inviteUrl;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        this.toastr.success('Invite Link Copied')
        //alert('Invite URL copied to clipboard!');
      }
    }
  }
}
