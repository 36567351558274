import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Playlist } from '../models/playlist';
import { HomeService } from '../Shared/home.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgForm } from '@angular/forms';
import { Users } from '../models/users';
import { SharedService } from '../Shared/shared.service';
import { Router } from '@angular/router';
import { UserService } from '../Shared/user.service';
import { ArtistsService } from '../Shared/artists.service';
import { ArtistFollow } from '../models/artist';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { PlayerService } from '../Shared/player.service';
import { Track } from '../../../Player/ngx-audio-player/src/lib/model/track.model'
import { SongsService } from '../Shared/songs.service';


@Component({
  selector: 'app-like-favourite-dropdown',
  templateUrl: './like-favourite-dropdown.component.html',
  styleUrls: ['./like-favourite-dropdown.component.css']
})
export class LikeFavouriteDropdownComponent implements OnInit {
  createchecked: boolean = true;
  updateextchecked: boolean;
  userDetails = new Users();
  playlist = new Playlist();
  UserId: any;
  playListDetails: any;
  favSong: boolean[] = [];
  likeSong: boolean[] = [];
  @Input() songid: any;
  @Input() favId: number = 0;
  @Input() likeId: number = 0;
  @Input() isMostlyLikedScreenEnabled: boolean = false;
  @Input() isLikedFavAlbumsSectionEnabled: boolean = false;
  @Input() artistid: any;
  @Input() albumId: any;
  @Input() isGotoartistScreenEnabled: boolean = false;
  @Output() someEvent = new EventEmitter<any>();
  @Output() someMostlyPlayedArtistEvent = new EventEmitter<any>();
  @Output() someLikedFavAlbumsSectionEvent = new EventEmitter<any>();
  @Input() isGotoartistEnabled: boolean = false;
  @Input() isGotoalbumEnabled: boolean = false;
  @Input() isAddtoplaylistScreenEnabled: boolean = false;
  @Input() isAddtoplaylistmultiplesong: boolean = false;
  @Input() songData: any;
  @Input() isAlbum: boolean = false;
  @Input() artistdropdown: boolean = false;
  @Input() albumdropdown: boolean = false;
  @Input() noartist: boolean = true;
  @Input() playlistId: any;
  @Input() items: any;
  @Input() adminPlaylist: boolean = false;
  @Input() isDiscover: boolean = false;
  @Output() addToPlaylistEvent = new EventEmitter<void>();
  @Output() closePlayerdetails = new EventEmitter<any>();
  msg: any;
  @Input() isRouting: boolean = false;
  @Input() following: boolean = false;
  artistFollow = new ArtistFollow();
  // isFollowing = 0;

  @ViewChild('closeBtn') closeBtn: ElementRef;
  @ViewChild('closeBtn') closeBtn1: ElementRef;
  @ViewChild('f') form: NgForm;
  playlistName: any;
  @ViewChild('renameModal') renameModal: ElementRef;
  //@ViewChild('g') form: NgForm;
  constructor(private _homeService: HomeService, private toastr: ToastrService, private playerService: AudioPlayerService,
    private spinner: NgxSpinnerService, private sharedService: SharedService, private _playerService: PlayerService,
    private router: Router, public userService: UserService,
    private _artistsService: ArtistsService, private _songService: SongsService) { }

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.UserId = this.userDetails["userId"];
    //this.isAddtoplaylistmultiplesong;
    this.freshplaylist();
    //this.getArtistFollowers();
  }

  checked() {
    this.createchecked = true;
    this.updateextchecked = false;
  }
  updatechecked() {
    //this.freshplaylist();
    this.updateextchecked = true;
    this.createchecked = false;
    this.playlist.PlaylistId = undefined;
  }

  onSubmit(f) {
    debugger
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.playlist.UserId = this.UserId;
    if (this._homeService.isAlbumQueue) {
      this._songService.getSongsByAlbumId(this.UserId, this._homeService.likefavalbumId, 1, 20).subscribe((data: any) => {
        this.songData = data.songs; console.log(data)
        this.songData.forEach((s: any) => {

          this.playlist.SongIds.push(s.songId);
        });
        this._homeService.AddPlayListMultiplex(this.playlist).subscribe((res: any) => {
          if (res.status == 200) {
            //this.freshplaylist()
            //this.closeForm();
            this.closeBtn.nativeElement.click();
            this.playlist.PlaylistId = undefined;
            this.updateextchecked = false;
            this.createchecked = true;
            if (f) {
              f.submitted = false;
              this.form.resetForm();
            }
            this.toastr.success("Playlist added successfully");
            this.sharedService.triggerMethodLikeFavorite();
            this.freshplaylist();
          }
          else {
            // this.freshplaylist();
            this.toastr.success("Playlist Already Exists");
          }
        })
      })
    }
    else {
      this.playlist.SongIds.push(this._homeService.albumSongId);
      this._homeService.AddPlayListMultiplex(this.playlist).subscribe((res: any) => {
        if (res.status == 200) {
          //this.freshplaylist()
          //this.closeForm();
          this.closeBtn.nativeElement.click();
          this.playlist.PlaylistId = undefined;
          this.updateextchecked = false;
          this.createchecked = true;
          if (f) {
            f.submitted = false;
            this.form.resetForm();
          }
          this.toastr.success("Playlist added successfully");
          this.sharedService.triggerMethodLikeFavorite();
          this.freshplaylist();
        }
        else {
          // this.freshplaylist();
          this.toastr.success("Playlist Already Exists");
        }
      })
    }
  }

  //   onSubmit(f) {
  //     debugger
  //     this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
  //     this.playlist.UserId = this.UserId;
  //     if (this._homeService.isAlbumQueue) {
  //       this._songService.getSongsByAlbumId(this.UserId, this.albumId, 1, 20).subscribe((data: any) => {
  //         this.songData = data.songs; console.log(data)
  //         this.songData.forEach((s: any) => {

  //           this.playlist.SongIds.push(s.songId);
  //         });
  //         this._homeService.AddPlayListMultiplex(this.playlist).subscribe((res: any) => {
  //           if (res.status == 200) {
  //             //this.freshplaylist()
  //             //this.closeForm();
  //             this.closeBtn.nativeElement.click();
  //             this.playlist.PlaylistId = undefined;
  //             this.updateextchecked = false;
  //             this.createchecked = true;
  //             if (f) {
  //               f.submitted = false;
  //               this.form.resetForm();
  //             }
  //             this.toastr.success("Playlist added successfully");
  //             this.sharedService.triggerMethodLikeFavorite();
  //             this.freshplaylist();
  //           }
  //           else {
  //             // this.freshplaylist();
  //             this.toastr.success("Playlist Already Exists");
  //           }
  //         })
  //       })
  //     }
  //     else {
  //       this.playlist.SongIds.push(this._homeService.albumSongId);
  //     }
  //     this._homeService.AddPlayListMultiplex(this.playlist).subscribe((res: any) => {
  //       if (res.status == 200) {
  //         //this.freshplaylist()
  //         //this.closeForm();
  //         this.closeBtn.nativeElement.click();
  //         this.playlist.PlaylistId = undefined;
  //         this.updateextchecked = false;
  //         this.createchecked = true;
  //         if (f) {
  //           f.submitted = false;
  //           this.form.resetForm();
  //         }
  //         this.toastr.success("Playlist added successfully");
  //         this.sharedService.triggerMethodLikeFavorite();
  //         this.freshplaylist();
  //       }
  //       else {
  //         // this.freshplaylist();
  //         this.toastr.success("Playlist Already Exists");
  //       }
  //     })

  //   }
  //   else
  //   {
  //   this.playlist.SongIds.push(this._homeService.albumSongId);
  //   this._homeService.AddPlayListMultiplex(this.playlist).subscribe((res: any) => {
  //     if (res.status == 200) {
  //       //this.freshplaylist()
  //       //this.closeForm();
  //       this.closeBtn.nativeElement.click();
  //       this.playlist.PlaylistId = undefined;
  //       this.updateextchecked = false;
  //       this.createchecked = true;
  //       if (f) {
  //         f.submitted = false;
  //         this.form.resetForm();
  //       }
  //       this.toastr.success("Playlist added successfully");
  //       this.sharedService.triggerMethodLikeFavorite();
  //       this.freshplaylist();
  //     }
  //     else {
  //       // this.freshplaylist();
  //       this.toastr.success("Playlist Already Exists");
  //     }
  //   })
  // }
  //   }
  //   onSubmitforupdate() {
  //     debugger;
  //     console.log(this._homeService.isAlbumQueue)
  //     if (this._homeService.isAlbumQueue) {
  //       this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
  //       this.playlist.UserId = this.userDetails.userId;
  //       //this.playlist.PlaylistId=this.playlistId;
  //       this._songService.getSongsByAlbumId(this.UserId, this._homeService.likefavalbumId, 1, 20).subscribe((data: any) => {
  //         this.songData = data.songs;
  //         this.songData.forEach((s: any) => {
  //           this.playlist.SongId = s.songId;
  //           this.playlist.SongIds.push(s.songId);
  //         });
  //         this.spinner.show();
  //         this._homeService.AddPlayListMultiplex(this.playlist).subscribe((res: any) => {
  //           if (res.status == 200) {
  //             if (this.playlist.PlaylistId! == 0) {
  //               //this.freshplaylist();
  //               this.toastr.success("Playlist added successfully");
  //               //this.msg="Playlist added successfully";
  //               //this.isChecked = [];
  //               this.playlist.Name = '';
  //               this.createchecked = true;
  //               this.updateextchecked = false
  //               this.form.resetForm();
  //               this.form.reset();
  //             } else {
  //               this.closeBtn.nativeElement.click();
  //               // this.freshplaylist();
  //               this.toastr.success("Playlist updated successfully");

  //               this.playlist.Name = '';
  //               //this.isChecked = [];
  //               this.createchecked = true;
  //               this.updateextchecked = false
  //               this.form.resetForm();
  //               this.form.reset();
  //             }
  //             this.sharedService.triggerMethodLikeFavorite();
  //             this.freshplaylist();
  //             this.spinner.hide();
  //           }
  //           else {
  //             this.toastr.success("Playlist Already Exists");
  //             this.spinner.hide();

  //             this.playlist.Name = '';
  //             //this.isChecked = [];
  //             this.createchecked = true;
  //             this.updateextchecked = false
  //             this.form.resetForm();
  //             this.form.reset();
  //           }
  //           this.sharedService.triggerMethodLikeFavorite();
  //           this.freshplaylist();
  //           this.spinner.hide();
  //         }
  //           else {
  //             this.toastr.success("Playlist Already Exists");
  //             this.spinner.hide();

  //           }
  //           this.spinner.hide();
  //       }, err => {
  //         this.toastr.error(err)
  //         this.spinner.hide();
  //       });
  //     })
  //   }
  //     else {
  //   this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
  //   this.playlist.UserId = this.userDetails.userId;
  //   //this.playlist.PlaylistId=this.playlistId;
  //   this.playlist.SongId = this._homeService.albumSongId;
  //   //this.playlist.Name=this.allsongs.name
  //   this.spinner.show();
  //   this._homeService.addOrUpdateplaylist(this.playlist).subscribe((res: any) => {
  //     if (res.status == 200) {
  //       if (this.playlist.PlaylistId! == 0) {
  //         //this.freshplaylist();
  //         this.toastr.success("Playlist added successfully");
  //         //this.isChecked = [];
  //         this.playlist.Name = '';
  //         this.createchecked = true;
  //         this.updateextchecked = false
  //         this.form.resetForm();
  //         this.form.reset();
  //       } else {
  //         this.closeBtn.nativeElement.click();
  //         // this.freshplaylist();
  //         this.toastr.success("Playlist updated successfully");
  //         this.playlist.Name = '';
  //         //this.isChecked = [];
  //         this.createchecked = true;
  //         this.updateextchecked = false
  //         this.form.resetForm();
  //         this.form.reset();
  //       }
  //       this.sharedService.triggerMethodLikeFavorite();
  //       this.freshplaylist();
  //       this.spinner.hide();
  //     }
  //     else {
  //       this.toastr.success("Playlist Already Exists");
  //       this.spinner.hide();

  //     }
  //     this.spinner.hide();
  //   }, err => {
  //     this.toastr.error(err)
  //     this.spinner.hide();
  //   });
  // }
  //   }
  onSubmitforupdate() {
    debugger;
    console.log(this._homeService.isAlbumQueue)
    if (this._homeService.isAlbumQueue) {
      this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
      this.playlist.UserId = this.userDetails.userId;
      //this.playlist.PlaylistId=this.playlistId;
      this._songService.getSongsByAlbumId(this.UserId, this._homeService.likefavalbumId, 1, 20).subscribe((data: any) => {
        this.songData = data.songs;
        this.songData.forEach((s: any) => {
          this.playlist.SongId = s.songId;
          this.playlist.SongIds.push(s.songId);
        });
        this.spinner.show();
        this._homeService.AddPlayListMultiplex(this.playlist).subscribe((res: any) => {
          if (res.status == 200) {
            if (this.playlist.PlaylistId! == 0) {
              //this.freshplaylist();
              this.toastr.success("Playlist added successfully");
              //this.msg="Playlist added successfully";
              //this.isChecked = [];
              this.playlist.Name = '';
              this.createchecked = true;
              this.updateextchecked = false
              this.form.resetForm();
              this.form.reset();
            } else {
              this.closeBtn.nativeElement.click();
              // this.freshplaylist();
              this.toastr.success("Playlist updated successfully");

              this.playlist.Name = '';
              //this.isChecked = [];
              this.createchecked = true;
              this.updateextchecked = false
              this.form.resetForm();
              this.form.reset();
            }
            this.sharedService.triggerMethodLikeFavorite();
            this.freshplaylist();
            this.spinner.hide();
          }
          else {
            this.toastr.success("Playlist Already Exists");
            this.spinner.hide();

          }
          this.spinner.hide();
        }, err => {
          this.toastr.error(err)
          this.spinner.hide();
        });
      })
    }
    else {
      this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
      this.playlist.UserId = this.userDetails.userId;
      //this.playlist.PlaylistId=this.playlistId;
      this.playlist.SongId = this._homeService.albumSongId;
      //this.playlist.Name=this.allsongs.name
      this.spinner.show();
      this._homeService.addOrUpdateplaylist(this.playlist).subscribe((res: any) => {
        if (res.status == 200) {
          if (this.playlist.PlaylistId! == 0) {
            //this.freshplaylist();
            this.toastr.success("Playlist added successfully");
            //this.isChecked = [];
            this.playlist.Name = '';
            this.createchecked = true;
            this.updateextchecked = false
            this.form.resetForm();
            this.form.reset();
          } else {
            this.closeBtn.nativeElement.click();
            // this.freshplaylist();
            this.toastr.success("Playlist updated successfully");
            this.playlist.Name = '';
            //this.isChecked = [];
            this.createchecked = true;
            this.updateextchecked = false
            this.form.resetForm();
            this.form.reset();
          }
          this.sharedService.triggerMethodLikeFavorite();
          this.freshplaylist();
          this.spinner.hide();
        }
        else {
          this.toastr.success("Playlist Already Exists");
          this.spinner.hide();

        }
        this.spinner.hide();
      }, err => {
        this.toastr.error(err)
        this.spinner.hide();
      });
    }
  }

  freshplaylist() {
    this._homeService.getPlaylist(this.UserId).subscribe((data: any) => {
      this.playListDetails = data.playListDetails;
      this._homeService.getLatestPlaylist(this.playListDetails);

    })
  }

  getPlayListDetails(songId, event) {
    debugger
    if (this.isDiscover) {
      this.addToPlaylistEvent.emit(event);
    }
    else {
      this._homeService.albumSongId = songId;
      this._homeService.isAlbumQueue = this.isAddtoplaylistmultiplesong;
      this._homeService.likefavalbumId = this.albumId
      // console.log(this._homeService.isAlbumQueue, this.isAddtoplaylistmultiplesong);
    }
  }

  FavouriteSongs(songId: string, UserId: string) {
    debugger
    this._homeService.FavouriteSongs(songId, UserId).subscribe((res: any) => {
      console.log(res)
      //this.someEvent.next();
      if (res.data.FavId) {
        this.toastr.success("Song added to favourites.");
      }
      else {
        this.toastr.success("Song removed from favourites.");
      }
      this.someEvent.next();
    })
  }

  likeSongs(songId: string, UserId: string) {
    debugger;
    this._homeService.LikeSong(songId, UserId).subscribe((res: any) => {
      this.someEvent.next();
      if (res.data.LikeId) {
        this.toastr.success("Song liked.");
      }
      else {
        this.toastr.success("Song unliked.");
      }
    }
    )
  }


  //for liked and fav artists and albums
  favouriteArtistOrAlbums() {
    if (this.isMostlyLikedScreenEnabled || this.artistdropdown) {
      this.favouriteArtist(this.artistid, this.UserId);
    } else if (this.isLikedFavAlbumsSectionEnabled) {
      this.favouriteAlbums(this.albumId, this.UserId);
    }
    else if (this.isAlbum) {
      this.favouriteAlbums(this.albumId, this.UserId);
    }
  }

  likedArtistOrAlbums() {
    if (this.isMostlyLikedScreenEnabled || this.artistdropdown) {
      this.likedArtist(this.artistid, this.UserId);
    } else if (this.isLikedFavAlbumsSectionEnabled) {
      this.likedAlbums(this.albumId, this.UserId);
    }
    else if (this.isAlbum) {
      this.likedAlbums(this.albumId, this.UserId);
    }
  }

  favouriteArtist(artistId: string, UserID: string) {
    this._homeService.FavouriteArtist(artistId, UserID).subscribe((res: any) => {
      if (res.data.FavId) {
        this.someMostlyPlayedArtistEvent.next();
        this.toastr.success("Artist added to favorites.")
      } else {
        this.someMostlyPlayedArtistEvent.next();
        this.toastr.success("Artist removed from favourites.")
      }
    });
  }
  likedArtist(artistId: string, UserID: string) {
    this._homeService.LikeArtist(artistId, UserID).subscribe((res: any) => {
      if (res.data.LikeId) {
        this.someMostlyPlayedArtistEvent.next();
        this.toastr.success("Artist Liked.")
      } else {
        this.someMostlyPlayedArtistEvent.next();
        this.toastr.success("Artist Uniked.")
      }
    });
  }

  favouriteAlbums(albumId: string, UserID: string) {
    this._homeService.FavouriteAlbums(albumId, UserID).subscribe((res: any) => {
      if (res.data.FavId) {
        this.someLikedFavAlbumsSectionEvent.next();
        this.toastr.success("Album added to favorites.")
      } else {
        this.someLikedFavAlbumsSectionEvent.next();
        this.toastr.success("Album removed from favourites.")
      }
    });
  }

  likedAlbums(albumId: string, UserID: string) {
    debugger
    this._homeService.LikeAlbums(albumId, UserID).subscribe((res: any) => {
      if (res.data.LikeId) {
        this.someLikedFavAlbumsSectionEvent.next();
        this.toastr.success("Album Liked.")
      } else {
        this.someLikedFavAlbumsSectionEvent.next();
        this.toastr.success("Album Uniked.")
      }
    });
  }

  fetchSongId(i, j) {
    debugger;
    this.playlist.SongId = i;
  }

  goToArtist(ArtistId: number) {
    if (this.isRouting) {
      this.userService.IsRouting(true);
      this.closePlayerdetails.next();
    }
    this.router.navigate(['/newdiscover'], { queryParams: { Id: ArtistId } });
  }
  goToAlbum(albumId) {
    if (this.isRouting) {
      this.userService.IsRouting(true);
      this.closePlayerdetails.next();
    }
    //this.router.navigateByUrl("/discoverAlbum/"+albumId);
    //this.router.navigate(['/discoverAlbum',albumId])
    this.router.navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/discoverAlbum', albumId]));
  }
  addupdateArtistFollow() {
    this.artistFollow.userId = this.UserId;
    this.artistFollow.artistId = this.artistid;
    this._artistsService.addupdateArtistfollow(this.artistFollow).subscribe((res: any) => {
      console.log(res)
      if (res.status == 200) {
        debugger
        if (res.data == null) {
          this.someMostlyPlayedArtistEvent.next();
          this.toastr.success("Artist Unfollowed successfully");
        }
        else {
          if (res.data.artistFollowerID) {
            this.someMostlyPlayedArtistEvent.next();
            //this.getArtistFollowers();
            this.toastr.success("Artist Followed successfully");
          }
        }
      }
    });
  }
  // getArtistFollowers() {
  //   this._homeService.getArtist(this.artistid, this.UserId).subscribe((Response: any) => {
  //     console.log(Response)
  //     this.isFollowing = Response.artistDetails.artistFlwId;
  //   })
  // }
  favoriteMethods(songid, favId, UserId) {
    this.fetchSongId(songid, favId);
    this.FavouriteSongs(songid, UserId)
  }
  likeMethods(songid, likeId, UserId) {
    this.fetchSongId(songid, likeId);
    this.likeSongs(songid, UserId);
  }
  addToQueue(items) {
    debugger;
    var getPlayList = this.playerService.getPlaylist();
    var index = getPlayList.findIndex(x => x.link == items.streamUrl);
    this._playerService.msaapPlaylist = [];
    if (index == -1) {
      let tractObj = new Track;
      tractObj.index = getPlayList.length;
      //tractObj.image = this.path + songs.thumbImage;
      if (items.fullName) {
        tractObj.artistName = items.fullName;
      }
      else {
        tractObj.artistName = items.artistName;
      }
      tractObj.link = items.streamUrl;
      tractObj.title = items.title;
      tractObj.songId = items.songId;
      getPlayList.push(tractObj);
    }
    this._playerService.msaapPlaylist = getPlayList
    this.toastr.success('Added to Queue Successfully.')
    console.log(this._playerService.msaapPlaylist, getPlayList)
  }

  addalbumToQueue() {
    debugger
    var getPlayList = this.playerService.getPlaylist();
    if (this.adminPlaylist) {
      this._homeService.adminPlaylist(this.playlistId, this.userDetails.userId).subscribe((res: any) => {
        console.log(res)
        this.songData = res.playlistDetails;
        var index;
        this.songData.forEach((s, i) => {
          debugger
          index = getPlayList.findIndex(x => x.link == s.streamUrl)
          //index.push(ind)
          //index[i]=ind
          if (index == -1) {
            let tractObj = new Track;
            tractObj.index = getPlayList.length;
            //tractObj.image = this.path + songs.thumbImage;
            tractObj.artistName = this.songData[i].artistName;
            tractObj.link = this.songData[i].streamUrl;
            tractObj.title = this.songData[i].title;
            tractObj.songId = this.songData[i].songId;
            getPlayList.push(tractObj);
          }
        })

        this._playerService.msaapPlaylist = [];
        this._playerService.msaapPlaylist = getPlayList;
        this.toastr.success('Added to Queue Successfully.')

        console.log(this._playerService.msaapPlaylist, getPlayList)
      })
    }
    else if (this.playlistId && !this.adminPlaylist) {
      this._homeService.playlist(this.playlistId, this.UserId).subscribe((Response: any) => {
        debugger
        if (Response.playlistDetails.length == 0 || Response.playlistDetails[0].songId == 0) {
          this.toastr.warning('No songs to add to Queue.')
        }
        else {
          this.songData = Response.playlistDetails;
          var index;
          this.songData.forEach((s, i) => {
            debugger
            index = getPlayList.findIndex(x => x.link == s.streamUrl)
            //index.push(ind)
            //index[i]=ind
            if (index == -1) {
              let tractObj = new Track;
              tractObj.index = getPlayList.length;
              //tractObj.image = this.path + songs.thumbImage;
              tractObj.artistName = this.songData[i].artistName;
              tractObj.link = this.songData[i].streamUrl;
              tractObj.title = this.songData[i].title;
              tractObj.songId = this.songData[i].songId;
              getPlayList.push(tractObj);
            }
          })

          this._playerService.msaapPlaylist = [];
          this._playerService.msaapPlaylist = getPlayList;
          this.toastr.success('Added to Queue Successfully.')
        }
        console.log(this._playerService.msaapPlaylist, getPlayList)
      })
    }
    if (this.isAlbum) {
      this._homeService.getAlbumbyId(this.albumId, this.UserId).subscribe((Response: any) => {
        console.log(Response)
        debugger
        if (Response.albumDetails.songs.length == 0) {
          this.toastr.warning('No songs to add to Queue.')
        }
        else {
          this.songData = Response.albumDetails.songs;
          var index;
          this.songData.forEach((s, i) => {
            debugger
            index = getPlayList.findIndex(x => x.link == s.streamUrl)
            if (index == -1) {
              let tractObj = new Track;
              tractObj.index = getPlayList.length;
              //tractObj.image = this.path + songs.thumbImage;
              tractObj.artistName = this.songData[i].artistName;
              tractObj.link = this.songData[i].streamUrl;
              tractObj.title = this.songData[i].title;
              tractObj.songId = this.songData[i].songId;
              getPlayList.push(tractObj);
            }
          })

          this._playerService.msaapPlaylist = [];
          this._playerService.msaapPlaylist = getPlayList;
          this.toastr.success('Added to Queue Successfully.')
        }
        console.log(this._playerService.msaapPlaylist, getPlayList)
      })
    }
  }
  likePlaylist(playlistId, userId) {
    if (this.adminPlaylist) {
      this._homeService.LikeAdminPlaylist(playlistId, userId).subscribe((res: any) => {
        console.log(res)
        if (res.data != null) {
          if (res.data.LikeId) {
            this.someEvent.next();
            this.toastr.success("Playlist Liked.")
          }
        }

        else {
          this.someEvent.next();
          this.toastr.success("playlist disliked.")
        }
      });
    }
    else {
      this._homeService.LikePlaylist(playlistId, userId).subscribe((res: any) => {
        console.log(res)
        if (res.data != null) {
          if (res.data.LikeId) {
            this.someLikedFavAlbumsSectionEvent.next();
            this.toastr.success("Playlist Liked.")
          }
        }
        else {
          this.someLikedFavAlbumsSectionEvent.next();
          this.toastr.success("playlist disliked.")
        }
      });
    }
  }

  favPlaylist(playlistId, userId) {
    debugger;
    if (this.adminPlaylist) {
      this._homeService.FavAdminPlaylist(playlistId, userId).subscribe((res: any) => {
        console.log(res)
        if (res.data != null) {
          if (res.data.LikeId) {
            this.someEvent.next();
            this.toastr.success("Playlist added to favorites.")
          }
        }
        else {
          this.someEvent.next();
          this.toastr.success("playlist removed from favourites.")
        }
      });
    }
    else {
      this._homeService.FavPlaylist(playlistId, userId).subscribe((res: any) => {
        //let favid=res.data.FavId?res.data.FavId:0;
        console.log(res)
        if (res.data != null) {
          if (res.data.FavId) {
            debugger
            this.someLikedFavAlbumsSectionEvent.next();
            this.toastr.success("Playlist added to favorites.")
          }
        }
        else {
          this.someLikedFavAlbumsSectionEvent.next();
          this.toastr.success("playlist removed from favourites.")
        }
      })
    }
  }

  removePlayList(playlistId) {
    if (confirm('Are you sure you want to remove playlist?')) {
      this._homeService.deleteplaylist(playlistId).subscribe((res: any) => {
        if (res.status == 200) {
          this.someLikedFavAlbumsSectionEvent.next();
          this.toastr.success(res.message)
        }
        //  else{
        //   this.toastr.success()
        //  }
      })
    }
  }
  editPlayListDetails(playlistId) {
    debugger;
    this._songService.playlistId = playlistId;
    if (this.adminPlaylist) {
      this._homeService.adminPlaylist(this.playlistId, this.userDetails.userId).subscribe((res: any) => {
        console.log(res)
        this._homeService.playlistName = res.playlistDetails[0].name;
        this.playlist.PlaylistId = this._songService.playlistId;
      })
    }
    else {
      this._homeService.playlist(playlistId, this.UserId).subscribe((res: any) => {
        console.log(res, playlistId, res.playlistDetails[0].name)
        // this.playlistName = res.playlistDetails[0].name;
        // this.playlist.Name = res.playlistDetails[0].name;
        this._homeService.playlistName = res.playlistDetails[0].name;
        // this.playlist.Name = this._homeService.playlistName;
        // console.log('pname', this.playlistName)
        this.playlist.PlaylistId = this._songService.playlistId;
      })
    }
  }
  onSubmitRenamepl(f: any) {
    debugger
    console.log(f, this.playlist.PlaylistId, this.UserId, this._homeService.playlistName)
    if (this.adminPlaylist) {
      this._homeService.renameAdminPlaylist(this.playlist.PlaylistId, this.UserId, this._homeService.playlistName).subscribe((res: any) => {
        console.log(res)
        this.toastr.success('PlaylistName Changed Successfully');
        setTimeout(() => {
          this.closeModal();  // Close modal on success
        }, 1000);
        //this.closeBtn1.nativeElement.click();
        this.someEvent.next();
        // this.getplaylist();
      }, err => {
        this.toastr.error(err)
        this.spinner.hide();
      });
    }
    else {
      this._homeService.renameplaylist(this._songService.playlistId, this.UserId, this._homeService.playlistName).subscribe((res: any) => {
        this.toastr.success('PlaylistName Changed Successfully');
        setTimeout(() => {
          this.closeModal();  // Close modal on success
        }, 1000);
        //this.closeBtn1.nativeElement.click();
        this.someLikedFavAlbumsSectionEvent.next();
        // this.getplaylist();
      }, err => {
        this.toastr.error(err)
        this.spinner.hide();
      });
    }
  }

  closeModal() {
    debugger;
    // const modalElement: HTMLElement = this.renameModal.nativeElement;
    // (window as any).$(`#${modalElement.id}`).modal('hide');  // Use jQuery to hide the modal
    const modalElement: HTMLElement = this.renameModal.nativeElement;
    (window as any).$(`#${modalElement.id}`).modal('hide');  // Close modal
    (window as any).$('body').removeClass('modal-open');      // Remove 'modal-open' class from <body>
    (window as any).$('.modal-backdrop').remove();

  }
}
