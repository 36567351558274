
import { EventEmitter, Injectable, Output } from '@angular/core';
import { ConfigService } from '../app.config';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class ArtistsService {
  private url = '';
  private baseUrl = '';
  public searchText = '';
  public TCaccept = '';
  favartist: any;
  public playId = [];
  public content = new BehaviorSubject<any>(this.favartist);
  public share = this.content.asObservable();
  private Isnotification = new Subject<boolean>();
  constructor(private http: HttpClient, private configService: ConfigService) {
    this.baseUrl = configService.getServiceBaseUrl();
  }
  getLatestPlaylist(data) {
    this.content.next(data);
    this.playId = data;
  }

  IsblogorCountryScreen(message: boolean) {
    this.Isnotification.next(message);
  }

  getIsblogorCountryScreen(): Observable<any> {
    return this.Isnotification.asObservable();
  }

  //Get Artist
  GetArtist(SearchText, PageIndex, PageSize, UserId: number) {
    this.url = this.baseUrl + 'api/Artists/DiscoverArtist?SearchText=' + SearchText + '&PageIndex=' + PageIndex + '&PageSize=' + PageSize + '&UserId=' + UserId;
    return this.http.get(this.url);
  }

  /*
  * get all artist data with pagination
  */
  getPaginatedArtists(searchText: string, pageIndex: number, pageSize: number, userId: number) {
    this.url = this.baseUrl + 'api/Artists/GetPaginatedArtists?SearchText=' + searchText + '&PageIndex=' + pageIndex + '&PageSize=' + pageSize + '&UserId=' + userId;
    return this.http.get(this.url);
  }

  /*
  * get artist data by ArtistId and UserId
  */
  getArtistDataByArtistIdAndUserId(artistId:number, userId:number){
    this.url = this.baseUrl + 'api/Artists/GetArtistById?id=' + artistId + '&userId=' + userId;
    return this.http.get(this.url);
  }
 

  //Get Specific Artist Detail
  getArtistDetails(artistId) {
    this.url = this.baseUrl + 'api/Artists/GetArtistDetailsById?ArtistId=' + artistId;
    return this.http.get(this.url);
  }

  /*
  * Get Artist Details By UserID and ArtiistID
  */
  getArtistDetailsByUserId(artistId, userId) {
    this.url = this.baseUrl + 'api/Artists/GetArtistDetailsByUserId?ArtistId=' + artistId + '&UserId=' + userId;
    return this.http.get(this.url);
  }

  //Add or Update Artist
  addOrUpdateArtist(artistData) {
    this.url = this.baseUrl + 'api/Artists/AddUpdateArtist';
    return this.http.post(this.url, artistData);
  }

  GetsongLanguage() {
    this.url = this.baseUrl + 'api/Songs/GetsongLanguage';
    return this.http.get(this.url);
  }

  //
  GetAllArtist(columname, sortedorder, searchname, pageIndex, pageSize, isTCAccepted) {
    this.url = this.baseUrl + 'api/Artists/GetAllArtist?ColumName=' + columname + '&SortedOrder=' + sortedorder + '&SearchName=' + searchname + '&PageIndex=' + pageIndex + '&PageSize=' + pageSize + '&isTCAccepted=' + isTCAccepted;
    return this.http.get(this.url);
  }

  //Delete Artist
  deleteArtist(artistId): Observable<any> {
    this.url = this.baseUrl + 'api/Artists/DeleteArtist?ArtistId=' + artistId;
    return this.http.post(this.url, artistId)
  }
  GetArtists() {
    this.url = this.baseUrl + 'api/Artists/GetArtists';
    return this.http.get(this.url);
  }

  Getallfavartist(UserId, SearchText, pageIndex, pageSize) {
    this.url = this.baseUrl + 'api/Artists/ViewAllFavouriteArtist?UserId=' + UserId + '&SearchText=' + SearchText + '&PageIndex=' + pageIndex + '&PageSize=' + pageSize;
    return this.http.get(this.url);
  }

  searhartist(model: any) {
    this.url = this.baseUrl + 'api/Artists/ArtistReoirt';
    return this.http.post<any>(this.url, model);
  }

  SendEmailArtist(artistData) {
    this.url = this.baseUrl + 'api/Artists/SendEmailArtist';
    return this.http.post(this.url, artistData);
  }

  ArtistAudit(artistAudit) {
    this.url = this.baseUrl + 'api/Artists/ArtistAudit';
    return this.http.post(this.url, artistAudit);
  }

  getArtistAudit(artistId) {
    this.url = this.baseUrl + 'api/Artists/GetArtistAudit?ArtistId=' + artistId;
    return this.http.get(this.url);
  }

  addOrUpdateHowto(howtoData) {
    this.url = this.baseUrl + 'api/Artists/AdminHowTo';
    return this.http.post(this.url, howtoData);
  }

  GetAllHowto() {
    this.url = this.baseUrl + 'api/Artists/GetAllHowTo';
    return this.http.get(this.url);
  }

  GetHowtoById(HowtoId) {
    this.url = this.baseUrl + 'api/Artists/GetHowToById?HowtoId=' + HowtoId;
    return this.http.get(this.url);
  }


  addOrUpdateTodoList(todolist) {
    this.url = this.baseUrl + 'api/Artists/AddUpdateToDoList';
    return this.http.post(this.url, todolist);
  }

  GetTodoListArtistById(artistId) {
    this.url = this.baseUrl + 'api/Artists/GetToDoListByArtistId?ArtistId=' + artistId;
    return this.http.get(this.url);
  }

  UpdateTodoListIsCompleted(ToDoListId, IsCompleted) {
    this.url = this.baseUrl + 'api/Artists/UpdateToDoListIsComplete?ToDoListId=' + ToDoListId + '&IsCompleted=' + IsCompleted;
    return this.http.post(this.url, ToDoListId, IsCompleted);
  }
  SaveCases(cases) {
    this.url = this.baseUrl + 'api/Artists/SaveCases';
    return this.http.post(this.url, cases);
  }
  GetAllCases(userid) {
    this.url = this.baseUrl + 'api/Artists/GetAllCases?userid=' + userid;
    return this.http.get(this.url);
  }
  GetAllArtists() {
    this.url = this.baseUrl + 'api/Artists/GetAllArtists'
    return this.http.get(this.url);
  }
  CaseDekete(CaseId) {
    this.url = this.baseUrl + 'api/Artists/Deletecases?CaseId=' + CaseId;
    return this.http.post(this.url, CaseId)
  }

  addArtistFeedback(feedback) {
    this.url = this.baseUrl + 'api/Artists/AddArtistFeedback';
    return this.http.post(this.url, feedback);
  }

  getallartistFeddback() {
    this.url = this.baseUrl + 'api/Artists/GetAllArtistFeedback';
    return this.http.get(this.url);
  }

  GetAllTodoList() {
    this.url = this.baseUrl + 'api/Artists/GetAllToDoList';
    return this.http.get(this.url);
  }

  GettodolistById(ToDoListId) {
    this.url = this.baseUrl + 'api/Artists/GetToDoListById?ToDoListId=' + ToDoListId;
    return this.http.get(this.url);
  }

  GetMostPlayedArtistbyUserId(userid) {
    this.url = this.baseUrl + 'api/Artists/GetMostPlayedArtistbyUserId?userid=' + userid;
    return this.http.get(this.url);
  }

  GetDiscoverDetails(searchname, userid) {
    this.url = this.baseUrl + 'api/Artists/GetDiscoverDetails?SearchName=' + searchname + '&userid=' + userid;
    return this.http.get(this.url);
  }

  GetDiscoverDetailsByArtistId(artistId, userid) {
    this.url = this.baseUrl + 'api/Artists/GetDiscoverDetailsByArtistId?ArtistId=' + artistId + '&userid=' + userid;
    return this.http.get(this.url);
  }

  addupdateArtistfollow(artistFollow) {
    this.url = this.baseUrl + 'api/Artists/AddUpdateArtistFollower';
    return this.http.post(this.url, artistFollow);
  }

  deleteHowto(HowtoId) {
    this.url = this.baseUrl + 'api/Artists/DeleteAdminHowTo?HowtoId=' + HowtoId;
    return this.http.post(this.url, HowtoId)
  }

  deleteToDoList(ToDoListId) {
    this.url = this.baseUrl + 'api/Artists/DeleteAdminToDoList?ToDoListId=' + ToDoListId;
    return this.http.post(this.url, ToDoListId)
  }

  GetTopMostPlayedArtist(){
    this.url = this.baseUrl + 'api/Artists/GetTopMostPlayedArtist';
    return this.http.get(this.url);
  }
}
