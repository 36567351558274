import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Users } from 'src/app/models/users';
import { Role } from '../models/role';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HomeService } from 'src/app/Shared/home.service';
import { PlayerService } from 'src/app/Shared/player.service';
import { UserService } from 'src/app/Shared/user.service';
import { LoadScriptService } from 'src/app/loadScript.service';
import { Track } from 'Player/ngx-audio-player/src/public_api';
import { AlbumService } from '../Shared/album.service';
import { Playlist } from '../models/playlist';
import { SongsService } from '../Shared/songs.service';
import { ArtistsService } from '../Shared/artists.service';
import { ArtistFollow } from '../models/artist';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-new-discover',
  templateUrl: './new-discover.component.html',
  styleUrls: ['./new-discover.component.css']
})
export class NewDiscoverComponent implements OnInit {
  userLogin: boolean = false
  userDetails = new Users();
  artistLogin: boolean = false;
  theme1: boolean = true;
  [x: string]: any;
  slides = [];
  artistList = [];
  topSongs = [];
  homeDetails = [];
  newReleaseSongs = [];
  newRelease = [];
  recommendedSongs = [];
  favouriteSongs = [];
  mostPlayedSongs = [];
  path;
  index = 0;
  playlist = new Playlist();
  track: Track[] = [];
  public count: number = 0;
  songDetails = new Track();
  noImage: boolean[] = [];
  newReleaseImage: boolean[] = [];
  noMostplayedImage: boolean[] = [];
  nofavouriteSongsImage: boolean[] = [];
  noArtistmage: boolean[] = [];
  substatus: any;
  songIsthere: boolean = false;
  themeidcode: string;
  adminplaylist: any;
  theme: any;
  artiststatus: any;
  totalabum: any;
  totalSongs: any;
  totalLikes: any;
  totalFav: any;
  totalListners: any;
  adminStats: any;
  totalUsers: any;
  totalActiveUsers: any;
  totalIOsUsers: any;
  totalAndroidUsers: any;
  totalTobyPremium: any;
  totalTobyFamily: any;
  totalCancelSubscriptions: any;
  totalabums: any;
  totalartistSongs: any;
  artistid: any;
  artistsong: any;
  TransList: Array<any> = [];
  playListDetails: any;
  Details;
  artistname;
  allsongs;
  tm
  timge;
  nooflike;
  description;
  albumId;
  artistId;
  songslikeid;
  noLikeImage1: boolean[] = [];
  noFavImage1: boolean[] = [];
  albumImage: boolean = true;
  noImageForArtist: boolean = true;
  likeid: any;
  noOfLikeOrDislike: boolean[] = [];
  noLikeImage: boolean;
  favid;
  nofavImage: boolean;
  IsMobileReq: boolean;
  lm;
  paramId;
  mostPlayed: any;
  discoverdata: any;
  featuredartist: any;
  tempmostPlayed: any;
  tempFeaturedartist: any = [];
  tempFadata: any;
  tempdata: any;
  isIcon: boolean = false;
  artistFollow = new ArtistFollow();
  isFollowing = 0;
  data: any;
  noOfFollowers: any;
  artistlistners: any;
  showAllLess = "Show all";
  showAllSongs = "Show all songs";
  tempAllsongs: any = [];
  IsActiveSongId: any;
  createchecked: boolean = true;
  updateextchecked: boolean;
  songid: any;
  favId: any;
  likeId: any;
  isGotoartistEnabled: any = true;
  FavArtistImage: boolean;
  userId: number;
  minNum: number = 5;
  isLoading: boolean = true;

  @ViewChild('closeBtn') closeBtn: ElementRef;
  @ViewChild('f') form: NgForm;


  featureAlbums = [
    {
      name: "Leela Khteeta",
      banner: "../../../assets/images/newhomeIcon/Image.svg",
      artistName: "Linda George",
      time: "3:49"
    },
    {
      name: "Leela Khteeta",
      banner: "../../../assets/images/newhomeIcon/Image.svg",
      artistName: "Linda George",
      time: "3:49"
    },
    {
      name: "Leela Khteeta",
      banner: "../../../assets/images/newhomeIcon/Image.svg",
      artistName: "Linda George",
      time: "3:49"
    },
    {
      name: "Leela Khteeta",
      banner: "../../../assets/images/newhomeIcon/Image.svg",
      artistName: "Linda George",
      time: "3:49"
    },
    {
      name: "Leela Khteeta",
      banner: "../../../assets/images/newhomeIcon/Image.svg",
      artistName: "Linda George",
      time: "3:49"
    }
  ]
  constructor(public translate: TranslateService,
    private router: Router,
    public albumService: AlbumService,
    private loadScript: LoadScriptService,
    private _homeService: HomeService,
    private _playerService: PlayerService,
    public userService: UserService,
    public songService: SongsService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private playerService: AudioPlayerService,
    private _artistsService: ArtistsService,
    private toastr: ToastrService) {
    this.updateDisplayCount(window.innerWidth);
    this.playerService.getIsblogorCountryScreen().subscribe(value => {
      //console.info('san: ' + value);
      this.IsActiveSongId = value;
    });
  }

  ngOnInit() {
    this.themeidcode = localStorage.getItem('themeId');
    this.loadScript.getServiceBaseUrl();
    this.substatus = this.userDetails.subscriptionStatus;
    this.translate.use(this.userDetails.languageCode);
    this.theme = this.userDetails.themeId;
    this.paramId = this.route.snapshot.queryParams["Id"];
    this.artistFollow.artistId = this.paramId;
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    debugger;
    this.getFeaandmpsongs(this.paramId);
    this.IsMobileReq = this.userDetails.isMobileReq;
    //this.Changetheme();
    this.artistFollow.userId = this.userDetails.userId;
    if (this.userDetails.userType == Role.superAdmin) {
      this.userLogin = false;
    } else if (this.userDetails.userType == Role.user) {
      this.userLogin = true;
      this.getHomeDetails(this.userDetails.userId);
    }
    else {
      this.artistLogin = true;
      this.userLogin = false;
    }
    let InvId = localStorage.getItem("InvoiceID");
    if (InvId != "0") {
      this.Displayprofie(this.paramId, this.userDetails.userId, this.IsMobileReq);
    }
    this.freshplaylist();
    window.scroll(0, 0);
    this.userId = this.userDetails.userId;
    this.getFavouriteArtist()

  }
  displayCount = 5;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateDisplayCount(event.target.innerWidth);
    console.log('chk width', event.target.innerWidth);

  }
  updateDisplayCount(width: number) {
    // debugger;
    console.log(width);
    if (width >= 1500 && width <= 1600) {
      this.displayCount = 5;
    }
    else if (width >= 1700 && width <= 1800) {
      this.displayCount = 6;
    }
    else if (width >= 1900 && width <= 2048) {
      this.displayCount = 7;
    }
    else if (width >= 2050 && width <= 7000) {
      this.displayCount = 8;
    }
    // else if (width == 2304) {
    //   this.displayCount = 8;
    // }
    // else if (width == 3072) {
    //   this.displayCount = 8;
    // }
    // else if (width == 4608) {
    //   this.displayCount = 8;
    // }
    // else if (width == 6144) {
    //   this.displayCount = 8;
    // }
    else if (width >= 1300 && width <= 1400) {
      this.displayCount = 5;
    }
    else if (width >= 878 && width <= 1024) {
      this.displayCount = 3;
    }
    else if (width >= 700 && width <= 877) {
      this.displayCount = 2;
    }
    // else if (width == 768) {
    //   this.displayCount = 2;
    // }
    else if (width <= 1404) {
      this.displayCount = 4;
    }
  }
  get albumsDetails() {
    // Return the items that should be currently displayed

    if (this.Details) {
      return this.Details.slice(0, this.displayCount);
    }
  }
  get featuredSongs() {
    // Return the items that should be currently displayed

    if (this.tempdata) {
      return this.tempdata.slice(0, this.displayCount);
    }
  }

  getHomeDetails(userId) {
    this.spinner.show();
    this._homeService.getHomeDetails(userId).subscribe((data: any) => {
      this.homeDetails = data;

      this.path = data.path;
      this.artistList = data.homePageDetails.artist;
      this.topSongs = data.homePageDetails.topSongs;
      this.newRelease = data.homePageDetails.newRelease;
      this.newReleaseSongs = data.homePageDetails.newReleaseSongs;
      this.recommendedSongs = data.homePageDetails.recommendedSongs;
      this.favouriteSongs = data.homePageDetails.favouriteSongs;
      this.mostPlayedSongs = data.homePageDetails.mostPlayedSongs;
      this.adminplaylist = data.homePageDetails.adminPlayLists;
      this.spinner.hide();
    });
  }

  freshplaylist() {
    this._homeService.getPlaylist(this.userDetails.userId).subscribe((data: any) => {
      this.playListDetails = data.playListDetails;
      this._homeService.getLatestPlaylist(this.playListDetails);

    })
  }

  Displayprofie(artistId: string, userId, IsMobileReq: boolean) {
    this.spinner.show();
    this._homeService.getArtist(artistId, userId).subscribe((Response: any) => {
      if (Response) {
        this.data = Response;
        this.TransList.push(Response);
        this.allsongs = Response.artistDetails.songs ? Response.artistDetails.songs : [];
        //console.log('songchk', this.allsongs);
        if (this.tempAllsongs.length > 20) {
          this.tempAllsongs = this.allsongs;
        }
        else {
          this.tempAllsongs = this.allsongs.slice(0, 20);
        }
        //console.log('artistdetails', this.data, this.TransList);
        this.isFollowing = this.data.artistDetails.artistFlwId;
        this.noOfFollowers = this.data.artistDetails.noOfFollowers;
        this.artistlistners = this.data.artistDetails.artistListners;
        if (this.TransList && this.TransList.length > 0) {
          const artistDetails = this.TransList[0].artistDetails;

          if (artistDetails) {
            this.artistname = artistDetails.fullName;
            this.description = artistDetails.description;
            this.path = this.TransList[0].path;
            this.Details = artistDetails.album ? artistDetails.album : '';
            this.albumId = artistDetails.album ? artistDetails.album.albumId : 0;
          }
        }
        this.artistId = this.paramId;
        // if (this.TransList) {
        //   if (this.TransList[0].artistDetails) {
        //     this.artistname = this.TransList[0].artistDetails.fullName;
        //     this.description = this.TransList[0].artistDetails.description;
        //     this.path = this.TransList[0].path;
        //     this.Details = this.TransList[0].artistDetails.album ? this.TransList[0].artistDetails.album : '';
        //     this.albumId = this.TransList[0].artistDetails.album ? this.TransList[0].artistDetails.album.albumId : 0;
        //     //this.artistname = this.TransList[0].artistDetails.artistName;

        //   }

        // }
        //console.log(this.allsongs, this.TransList[0].artistDetails);
        // this.allsongs.forEach((s: any) => {
        //   s.length = s.length.slice(3, 8)
        // });

        //console.log(this.TransList, this.Details, this.albumId, this.description, this.allsongs, this.timings)
        // for (var a = 0; a < this.allsongs.length; a++) {
        //   if (this.allsongs[a].likeId == 0) {
        //     this.noLikeImage1[a] = false;
        //   } else {
        //     this.noLikeImage1[a] = true;
        //   }
        // }
        // for (var a = 0; a < this.allsongs.length; a++) {
        //   if (this.allsongs[a].favId == 0) {
        //     this.noFavImage1[a] = false;
        //   } else {
        //     this.noFavImage1[a] = true;
        //   }
        // }

        let currentSongId = localStorage.getItem('CurrentSongId');
        let getSong = this.allsongs.findIndex(x => x.songId == currentSongId)
        if (getSong != -1) {
          let isSongPlaying = localStorage.getItem('IsPlaying');
          if (isSongPlaying == 'true' || isSongPlaying == null) {
            this.playerService.playPauseBtn[getSong] = true;
          } else if (isSongPlaying == 'false') {
            this.playerService.playPauseBtn[getSong] = false;
          }
        } else {
          this.playerService.playPauseBtn[this.playerService.indexSong] = false;
        }

        this.tm = this.TransList[0].artistDetails.thumbImage;
        this.lm = this.TransList[0].artistDetails.largeImage;
        if (this.tm == null) {
          this.albumImage = false;
        } else {
          this.albumImage = true;
          this.artistImage = this.path + this.tm
        }
        this.timge = this.path + this.allsongs.thumbImage;
        this.nooflike = this.TransList[0].artistDetails.noofLikes;
        this.likeid = this.TransList[0].artistDetails.likeId;
        if (this.likeid == 0) {
          this.noLikeImage = true;
        } else {
          this.noLikeImage = false;
        }
        this.faiId = this.TransList[0].artistDetails.favId;
        if (this.favid == 0) {
          this.nofavImage = true;
        } else {
          this.nofavImage = false;
        }
        this.spinner.hide();
      }
    });
  }
  Viewalbum(artistId: number) {

    this.albumService.albumDetails = this.TransList;
    this.router.navigate(['/album/album'], { queryParams: { Id: artistId } });
  }
  Viewallsongs(artistId: number) {
    this.router.navigate(['/album/allsongs'], { queryParams: { Id: artistId } });
  }

  albumdetails(albumId: number) {
    localStorage.removeItem("AlbumId");
    let val = albumId;
    localStorage.answer = JSON.stringify(val);
    let saved = JSON.parse(localStorage.answer);
    localStorage.setItem("AlbumId", saved);
    localStorage.setItem("image", this.timge);
    this.router.navigate(['/album/album-details'], { queryParams: { Id: albumId } });
  }
  songsdetails(songId: String) {
    this.router.navigate(['/album/songsdetails'], { queryParams: { Id: songId } });
  }
  // fetchSongId(i, j) {
  //   this.playlist.SongId = i;
  //   this.songslikeid = j;
  // }
  fetchSongname(i) {
    this.playlist.title = i;
  }

  getFeaandmpsongs(artistid) {
    this.isLoading = true;
    this._artistsService.GetDiscoverDetailsByArtistId(artistid, this.userDetails.userId).subscribe(data => {
      this.spinner.hide();
      debugger
      this.discoverdata = data;

      this.mostPlayed = this.discoverdata.getdiscoverArtist.mostPlayedSongs;
      if (this.minNum == this.mostPlayed.length) {
        this.tempmostPlayed = this.mostPlayed;
      }
      if (this.minNum <= this.mostPlayed.length) {
        this.tempmostPlayed = this.mostPlayed.slice(0, this.minNum);
      }
      else {
        this.tempmostPlayed = this.mostPlayed.slice(0, 5);
      }
      this.isLoading = false;
      // this.tempdata = 
      this.featuredartist = this.discoverdata.getdiscoverArtist.featuredArtist;
      //console.log('songs', this.featuredartist.filter(x => x.typeName === "Song"), this.discoverdata);
      this.tempdata = this.discoverdata.getdiscoverArtist.featuredArtistSongs.slice(0, 5);

      //console.log(this.mostPlayed ,  this.featuredartist,this.featuredartist.filter(x => x.typeName === "Album"));
      // this.tempFadata = this.featuredartist.filter(x => x.typeName === "Album");
      // if (this.featuredartist.filter(x => x.typeName === "Album").length == 1) {
      //   this.tempFeaturedartist = this.featuredartist.filter(x => x.typeName === "Song").slice(0, 4);
      //   this.tempdata = [...this.tempFadata, ...this.tempFeaturedartist];
      // }
      // else if (this.featuredartist.filter(x => x.typeName === "Album").length == 2) {
      //   this.tempFeaturedartist = this.featuredartist.filter(x => x.typeName === "Song").slice(0, 3);
      //   this.tempdata = [...this.tempFadata, ...this.tempFeaturedartist];
      // }
      // else if (this.featuredartist.filter(x => x.typeName === "Album").length >= 3) {
      //   this.tempFeaturedartist = this.featuredartist.filter(x => x.typeName === "Song").slice(0, 2);
      //   this.tempFadata = this.featuredartist.filter(x => x.typeName === "Album").slice(0, 3);
      //   this.tempdata = [...this.tempFadata, ...this.tempFeaturedartist];
      // }
    });
  }

  showMoresongs() {
    debugger
    this.minNum = this.minNum + 5;
    if (this.minNum > this.mostPlayed.length || this.minNum == this.mostPlayed.length) {
      this.minNum = this.mostPlayed.length
      this.showAllLess = "Show less";
    }
    this.tempmostPlayed = this.mostPlayed.slice(0, this.minNum);

  }

  showAllsongs() {
    debugger;
    if (this.showAllLess == "Show all") {
      this.showAllLess = "Show less";
      this.minNum = this.mostPlayed.length;
      this.tempmostPlayed = this.mostPlayed//.slice(0, 20);
    }
    else {
      this.showAllLess = "Show all";
      this.tempmostPlayed = this.mostPlayed.slice(0, 5);
      this.minNum = 5
    }

  }

  setHover(i) {

    this.isIcon = true;
    // if(i){

    //     this.isIcon = e;
    // }
  }
  gotoAlbums() {
    this.router.navigate(['/user-albums'], { queryParams: { Id: this.paramId } });
  }
  addupdateArtistFollow() {
    this._artistsService.addupdateArtistfollow(this.artistFollow).subscribe((res: any) => {
      if (res.status == 200) {
        //debugger

        if (res.data == null) {
          this.toastr.success("Artist Unfollowed successfully");
          this.Displayprofie(this.artistId, this.userDetails.userId, this.IsMobileReq);
        }
        else {
          this.toastr.success("Artist Followed successfully");
          this.Displayprofie(this.artistId, this.userDetails.userId, this.IsMobileReq);
        }
      }
    });
  }
  playAsong(song: any, index) {
    debugger;

    // song.artistName = this.artistname;
    // this._playerService.playSongs(song);
    // this.playerService.playSongCallback(true);
    this._playerService.msaapPlaylist = [];
    song.forEach((song, i) => {
      const trackObj = new Track();
      trackObj.index = i;
      trackObj.image = song.thumbImage;
      trackObj.artistName = song.artistName;
      trackObj.link = song.streamUrl;
      trackObj.title = song.title;
      trackObj.songId = song.songId;
      this._playerService.msaapPlaylist.push(trackObj);
    });
    this.playerService.playSongCallback(true);

    if (this.playerService.repeatSong) {
      this.playerService.repeatSong = false;
    }
    this.playerService.setPlaylist(this._playerService.msaapPlaylist);
    this.playerService.selectATrack(index + 1);
    this._playerService.updateData(this._playerService.msaapPlaylist);
  }

  allSongs() {
    if (this.showAllSongs == "Show all songs") {
      this.showAllSongs = "Show less songs";
      this.tempAllsongs = this.allsongs;
    }
    else {
      this.showAllSongs = "Show all songs";
      this.tempAllsongs = this.allsongs.slice(0, 20);
    }
  }
  FavouriteArtist() {
    debugger;
    this._homeService.FavouriteArtist(this.paramId, this.userId).subscribe((res: any) => {

      if (res.data && res.data.FavId) {
        this.toastr.success(" Artist added to Favourite ");
      }
      else {
        this.toastr.success("Artist removed from Favourite");
      }
      this.getFavouriteArtist();
    })

  }

  getFavouriteArtist() {
    debugger
    this._homeService.getArtist(this.paramId, this.userId).subscribe((res: any) => {

      if (res.artistDetails.favId > 0) {
        this.FavArtistImage = true;
      }
      else {
        this.FavArtistImage = false;
      }
      if (res.artistDetails.likeId == 0) {
        this.noLikeImage = true;
      }
      else {
        this.noLikeImage = false;
      }
    })
  }
  refreshSongsList() {
    debugger
    this.getFeaandmpsongs(this.paramId);
    this.Displayprofie(this.paramId, this.userDetails.userId, this.IsMobileReq);
    // this.showAllLess = "Show all";
    // //this.tempmostPlayed = this.mostPlayed.slice(0, 5);
    // this.minNum = 5
  }

  truncateTitle(title: string, maxLength: number): string {
    if (!title) {
      return "No Title";
    }
    if (title.length <= maxLength) {
      return title;
    }
    return `${title.substring(0, maxLength)}...`;
  }

  addToLikeArtist() {
    this._homeService.LikeArtist(this.paramId, this.userId).subscribe((res: any) => {

      if (res.data && res.data.LikeId) {
        this.toastr.success(" Artist added to Like");
      }
      else {
        this.toastr.success("Artist removed from Like");
      }
      this.getFavouriteArtist();
    })
  }
}
