import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, NgZone, Renderer, Renderer2 } from '@angular/core';
import { LoadScriptService } from 'src/app/loadScript.service';
import { HomeService } from '../../Shared/home.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Playlist } from 'src/app/models/playlist';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/Shared/user.service';
import { PlayerService } from 'src/app/Shared/player.service';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { OwlCarousel } from 'ngx-owl-carousel';
import * as $ from 'jquery';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-songsdetails',
  templateUrl: './songsdetails.component.html',
  styleUrls: ['./songsdetails.component.css']
})
export class SongsdetailsComponent implements OnInit {
  @ViewChild('closeBtn') closeBtn: ElementRef;

  @ViewChild('f') form: NgForm;


  TransList: Array<any> = [];
  path;
  userDetails: any;
  userId;
  result: any;
  songs: any;
  songId: any;
  noofLikes: any;
  likeId: any;
  favId: any;
  likedimage: boolean;
  nofavImage: boolean;
  createchecked: boolean = true;
  updateextchecked: boolean;
  playListDetails: any;
  playd = [];
  index;
  ArtistId: any;
  playlist = new Playlist();
  artistName: any;
  title: any;
  songIndex: any = this.route.snapshot.queryParams["Id"];
  albumimage = ['../assets/images/popular1.jpg', '../assets/images/popular1.jpg', '../assets/images/popular1.jpg', '../assets/images/popular1.jpg', '../assets/images/popular1.jpg', '../assets/images/popular1.jpg'];

  myCarouselOptions = { items: 2, dots: true, nav: true, center: true, loop: true };
  currentSong: any;

  currentIndex: any;

  slidesStore: any[];
  languageCode: any;
  theme: any;
  mySlideImages;
  myCarouselImages
  mySlideOptions = {

    items: 2, dots: true, nav: true, center: true, loop: true, mouseDrag: true, startPosition: this.songIndex, callbacks: true, onChanged: (event) => {
      this.setCurrentSong(this.playerService.playlist, event.page.index + 1); // a value to be updated
    }
    , navText: ["<i #audioPlayer class='clsPlayerPlay fa fa-chevron-left'></i>", "<i #audioPlayer class='clsPlayerPlay fa fa-chevron-right'></i>"]

  };
  sondid: any;
  shuffleEnable: any;

  constructor(private loadScript: LoadScriptService,
    private homeService: HomeService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private playerService: AudioPlayerService,
    public userService: UserService,
    public _playerService: PlayerService,
    private router: Router,
    public eleRef: ElementRef
  ) {
    this.songId = this.route.snapshot.queryParams["Id"];
    this.mySlideImages = this.albumimage;
    this.myCarouselImages = this.albumimage;
    this.homeService.playerSong.subscribe((res) => {
      this.songId = res;
      if (window.location.pathname == '/album/songsdetails') {
        this.router.navigate(['/album/songsdetails'], { queryParams: { Id: this.songId } });
      }
    })
  }

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.userId = this.userDetails.userId;
    if (this.playerService.shufflePlayList) {
      this.playListDetails = this.playerService.playlist;
      //this.setSliderImages();
    } else {
      this.getplaylist();
    }

    this.setSongs(this.playerService.playlist);

    this.loadScript.getServiceBaseUrl();
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.languageCode = this.userDetails.languageCode;

    this.theme = this.userDetails.themeId
    this.songsDetails(this.currentSong.songId, this.userId);
  }

  getplaylist() {
    this.homeService.getPlaylist(this.userDetails.userId).subscribe((data: any) => {
      this.playListDetails = data.playListDetails;

      this.homeService.getLatestPlaylist(this.playListDetails);

    });
  }

  songsDetails(songId: string, UserId: string) {
    this.homeService.getSongbyId(songId, UserId).subscribe((Response: any) => {
      this.TransList.push(Response);
      this.songs = Response.songs;
      this.songId = Response.songs.songId;
      this.path = Response.path;
      this.noofLikes = Response.songs.noofLikes;
      this.ArtistId = Response.songs.artistId
      this.likeId = Response.songs.likeId;

      if (this.likeId == 0) {
        this.likedimage = true;
      }
      else {
        this.likedimage = false
      }
      this.favId = Response.songs.favId;
      if (this.favId == 0) {
        this.nofavImage = true;
      } else {
        this.nofavImage = false;
      }
    })
  }
  Likesongs() {
    this.likedimage = !this.likedimage;
    this.homeService.LikeSong(this.songId, this.userId).subscribe((res: any) => {
      this.result = res.data;
      this.noofLikes = this.result.NoofLikes;

    }
    )
  }
  Artistprofile() {
    this.router.navigate(['/artist/artistprofile'], { queryParams: { Id: this.ArtistId } });
  }

  checked() {
    this.createchecked = true;
    this.updateextchecked = false;
    this.playlist.Name = '';
  }
  updatechecked() {
    this.updateextchecked = true;
    this.createchecked = false;
    this.playlist.PlaylistId = undefined;
  }
  onSubmit() {

    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));

    this.playlist.UserId = this.userDetails.userId;
    this.playlist.SongId = this.songId;

    this.homeService.addOrUpdateplaylist(this.playlist).subscribe((res: any) => {

      if (res.status == 200) {
        this.refreshplaylist();
        if (this.playlist.PlaylistId! == 0) {
          this.toastr.success("Playlist added successfully");
          this.createchecked = true;
          this.updateextchecked = false
          this.form.reset();
          this.form.resetForm();
        } else {
          this.closeBtn.nativeElement.click();
          this.toastr.success("Playlist updated successfully");
          this.createchecked = true;
          this.updateextchecked = false;
          this.form.reset();
          this.form.resetForm();
        }
      }
      else {
        this.toastr.success("Playlist Already Exists");
      }
    })
  }

  refreshplaylist() {
    this.homeService.getPlaylist(this.userDetails.userId).subscribe((data: any) => {
      this.playListDetails = data.playListDetails;
      this.homeService.allPlayList.next(this.playListDetails);

      if (this.playListDetails.length >= 10) {
        for (var i = this.index; i < 10; i++) {
          this.playd[i] = this.playListDetails[i];
          this.homeService.playId[i] = this.playListDetails[i];
        }
      } else {
        for (var i = this.index; i < this.playListDetails.length; i++) {
          this.playd[i] = this.playListDetails[i];
          this.homeService.playId[i] = this.playListDetails[i];
        }
      }
    });
  }
  fetchSongId(i) {
    this.playlist.SongId = i;
  }
  freshplaylist() {

    this.homeService.getPlaylist(this.userDetails.userId).subscribe((data: any) => {
      this.playListDetails = data.playListDetails;
      //this.homeService.getLatestPlaylist(this.playListDetails);
    })
  }
  setSongs(songsDetails) {
    if (songsDetails.length !== 0) {

      this.mySlideImages = [];
      this.currentSong = songsDetails.find(x => x.index == this.songId);
      if (this.currentSong != undefined) {
        this.songIndex = this.currentSong.index;
        this.artistName = this.currentSong.artistName;
        this.title = this.currentSong.title;
        this.sondid = this.currentSong.songId
        let tempSongIndex = this.songIndex;
      } else {
        this.songIndex = 0;
        this.artistName = songsDetails[0].artistName;
        this.title = songsDetails[0].title;
        this.sondid = songsDetails[0].songId;
      }
      for (var i = 0; i < songsDetails.length; i++) {
        this.mySlideImages.push(songsDetails[i].image);
      }
    }
  }
  //////
  setCurrentSong(songsDetails, index) {
    if (index != undefined || index != null) {
      this.currentSong = songsDetails.find(x => x.index == (index - 1))
      if (this.currentSong != undefined) {
        this.songIndex = this.currentSong.index;

        this.artistName = this.currentSong.artistName;
        this.title = this.currentSong.title;

        this.sondid = this.currentSong.songId;
        this.playerService.selectATrack(this.songIndex + 1);
        this.songsDetails(this.sondid, this.userId);
        //this.playerService.playnext();
      }
    }
  }
  FavouriteSongs(songId: string, UserId: string) {
    this.nofavImage = !this.nofavImage;

    this.homeService.FavouriteSongs(this.songId, this.userId).subscribe((res: any) => {
      this.result = res.data;
    })
  }
}
