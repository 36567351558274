export enum Pagination {
    first = 10,
    second = 20,
    third = 30,
    fourth = 40,
    fifth = 50,
    sixth = 15,
    displayNoOfPagesPerPage =10,
    displayNumberperpage =6
}

// export enum SongPagination {
//     first = 10,
//     second = 50,
//     third = 100,
//     // fourth = 40,
//     // fifth = 50,
//     // sixth = 15,
//     displayNoOfPagesPerPage =10,
//     displayNumberperpage =6
// }



