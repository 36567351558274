import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { LoadScriptService } from 'src/app/loadScript.service';
import { UserService } from 'src/app/Shared/user.service';
import { Users } from 'src/app/models/users';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-theme',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.css']
})
export class ThemeComponent implements OnInit {
  @ViewChild('closeBtn') closeBtn: ElementRef;
  Theme: any;
  id: any;
  userDetails = new Users();
  massage: string;
  themeId: any;
  LocalId: any;
  path: string;

  constructor(private toastr: ToastrService, private _router: Router, private loadScript: LoadScriptService, public userService: UserService) { }

  ngOnInit() {
    this.loadScript.getServiceBaseUrl();
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.themeId = (localStorage.getItem('themeId'));
    this.GetThemes();
  }
  
  GetThemes() {

    this.userService.GetThemes().subscribe((data: any) => {
      this.Theme = data.themeDetails;
      this.LocalId = data.themeDetails.themeId;
      this.path = data.path;
    })
  }

  Changetheme() {
    if (this.userDetails.themeId == '2') {
      this.userService.theme1 = true;
      return true;

    }
    else {
      this.userService.theme1 = false;
      return false;
    }
  }



  onChange(data) {
    this.themeId = data.themeId;
  }

  onSubmit() {
    this.userService.ChangeTheme(this.userDetails.userId, this.themeId).subscribe((data: any) => {
    })
    var themeIdFromLocalStorage = JSON.parse(localStorage.getItem('UserDetails'));
    themeIdFromLocalStorage.themeId = this.themeId;
    localStorage.setItem('UserDetails', JSON.stringify(themeIdFromLocalStorage));
    this.Changetheme();
    this.massage = 'Theme changed successfully';
    this.successmsg(this.massage);
    this._router.navigate([`/home`]);
  }
  successmsg(msg: string) {
    this.toastr.success(msg)
  }

  cancel() {
    this._router.navigate([`/home`]);
  }
}
