export class Subscriptions {
    UserId:number;
    SubscriptionPlanId:number;
//<===============================>
    planName:any;
    planDescription:any;
    noofMonths:number;
    noOfDevices:number;
    price:number;
    paypalPlanIdDev:any;
    noOfUsers:number;
}
export class Subscriptionsplanedit {
    planName:any;
    planDescription:any;
    noofMonths:number;
    noOfDevices:number;
    price:number;
    paypalPlanIdDev:any;
}