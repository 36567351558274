import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserNotificationSettings, Users } from 'src/app/models/users';
import { UserService } from 'src/app/Shared/user.service';

@Component({
  selector: 'app-user-notification-settings',
  templateUrl: './user-notification-settings.component.html',
  styleUrls: ['./user-notification-settings.component.css']
})
export class UserNotificationSettingsComponent implements OnInit {
  notifications: any[] = [];
  notificationSettings = new UserNotificationSettings;
  userdataNotification: any;
  userDetails = new Users();
  notificationSettingDetails: any;
  constructor(private _router: Router,
    public userService: UserService,private toastr: ToastrService,
  ) { }

  ngOnInit() {
    window.scroll(0, 0);
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.getAllAdminNotifications();


  }

  // onCheckboxChange(event: Event) {
  //   const inputElement = event.target as HTMLInputElement;
  //   console.log(inputElement.checked); // true if checked, false if not
  //   // Your logic here
  // }

  getAllAdminNotifications() {
    this.userService.GetAllAdminNotification().subscribe((notifs: any) => {
      //this.notifications=notifs.notification.list;
      this.notifications = notifs.notification.list.map(notification => ({
        ...notification,
        isEmail: false, // Initialize with default values
        isPush: false
      }));
      this.GetAllUserNotificationSettings();
    })
  }


  cancel() {
    this._router.navigate([`/account/user-account`]);
  }


  onSave() {
    debugger;
    var userdataNotification = this.notifications.filter(notification => notification.isEmail || notification.isPush)
      .map((notification: any) => ({
        notificationId: notification.notificationId,
        userId: this.userDetails.userId,
        isEmail: notification.isEmail,
        isPush: notification.isPush,
        userNotificationSettings: null // Ensure userId is assigned to each selected notification
      }));
    this.userService.UserNotificationSettings(userdataNotification).subscribe((res: any) => {
      debugger;
      console.log(res);
      if (res.isSuccess == true) {
        this.toastr.success("Notification Settings Updated Successfully")
      }
    });
  }

  GetAllUserNotificationSettings() {
    this.userService.getAllUserNotificationSettings().subscribe((data: any) => {
        var nsDetails = data.userNotificationSettings.filter(x => x.userId == this.userDetails.userId);
        console.log('nsdata',nsDetails);
        if(nsDetails.length > 0){
          nsDetails.forEach(Item => {
            const index = this.notifications.findIndex(notificationItem => notificationItem.notificationId === Item.notificationId);
            if (index !== -1) {
              this.notifications[index].isEmail = Item.isEmail;
              this.notifications[index].isPush = Item.isPush;
            }
          });
        }
    })
  }
}


