import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AlbumService } from 'src/app/Shared/album.service';
import { ArtistsService } from 'src/app/Shared/artists.service';
import { PaginationServiceForAlbum } from 'src/app/Shared/paginationserviceForAlbum.service';
import { LoadScriptService } from 'src/app/loadScript.service';

@Component({
  selector: 'app-admin-todo-list',
  templateUrl: './admin-todo-list.component.html',
  styleUrls: ['./admin-todo-list.component.css']
})
export class AdminTodoListComponent implements OnInit {
  todolist: any=[];

  constructor(
    private loadScript: LoadScriptService,
    private router: Router,
    private albumService: AlbumService, private spinner: NgxSpinnerService,
    private _toastr: ToastrService, private route: ActivatedRoute,
    public paginationService: PaginationServiceForAlbum, public translate: TranslateService,
    private _artistsService: ArtistsService
  ) { }

  ngOnInit() {
    this.getallTodolist();
  }


  add(){
    this.router.navigate(['admin/todolist/add-todo-list']);
  }

  getallTodolist(){
    this._artistsService.GetAllTodoList().subscribe((res: any) => {
      this.todolist = res.alltodoList.list;
      console.log(this.todolist);
  })
  }

  edittodo(e){
    console.log(e);
    this.router.navigate([`admin/todolist/update-todo-list/` + e + ``]);
  }

  deleteTodoList(item){
    debugger;
    if (confirm('Please note! Are you sure you want to delete ToDo List?')) {
      this._artistsService.deleteToDoList(item).subscribe((response) => {
        if (response) {
          this._toastr.success("How To Deleted Succesfully");
          this.getallTodolist();
        }
      }, err => {
        this._toastr.error(err)
        this.spinner.hide();
      });
    }
  }

}
