import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from '@ngx-translate/core';
import { LoadScriptService } from 'src/app/loadScript.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-get-access',
  templateUrl: './get-access.component.html',
  styleUrls: ['./get-access.component.css']
})
export class GetAccessComponent implements OnInit {

  loginPage: boolean;
  registerPage: boolean;
  constructor(public translate: TranslateService, private loadScript: LoadScriptService, private spinner: NgxSpinnerService, private _router: Router) { }

  ngOnInit() {
  }

  tobyMain() {
    this._router.navigate(['/artist-main-page']);
  }

  register() {
    // this._router.navigate(['/login/artist-joinus']);
    this._router.navigate(['/artist-register']);
  }

}
