
export class Users {
    public userId: number;
    public fullName: any;
    public email: any;
    public confirmEmail: any;
    public googleEmail: any;
    public fbEmail: any;
    public mobileNo: any;
    public userName: any;
    public displayName: any;
    public password: any;
    public confirmPassword: any;
    public countryId: number;
    public country: any;
    public countryCode: any;
    public IsTCAccepted: any;
    public isforKid: boolean;
    public isMobileReq: boolean;
    //public languageId:number;
    public languageCode = "en";
    public userType: any;
    public deviceId: any;
    public deviceType: any;
    public deviceName: any;
    public subscriptionType: any;
    public subscriptionStatus: any;
    public lastLoggedIn: any;
    public isActive: boolean;
    public createdOn: any;
    public createdBy: number;
    public updatedOn: any;
    public updatedBy: number;
    public image: any;
    public dateOfBirth: any;
    public gender: any;
    public themeId: any;
    public city: any;
    public provider: any;
    public public: any;
    public provider_pic: any;
    public provider_id: any;
    public Token: any;
    public themeImage: any;
    public braintreeId;
    public brainTreePlanId;
    public subscriptionPlans;
    public brainTreeCustomerId;
    public subscriptionId;
    public braintreeSubscriptionId;
    public braintreeCustomerId;
    public ActivationDays;
    public artistId;
    public sessionId: any;
    public return: any;
    public subscriptionPlanId: any;
    public Address: string;
    public states: string;
    public pinCode: any;
    public parentId: number;
    public isDefaultLogin :boolean;
    public isGoogleLogin :boolean;
    public isFBLogin :boolean;
}

export class UserHomeBanner {
    public HomeBannerId: any;
    public ArtistId: number;
    public AlbumId: number;
    public SongId: any;
    public Promotion: any;
    public OrderImage: number;
    WebthumbImage: any;
    WeblargeImage: any;
    base64Data: any;
    fileName: any;
    fileType: any;
    MobthumbImage: any;
    MoblargeImage: any;
    base64DataMobile: any;
    fileNameMobile: any;
    fileTypeMobile: any;
    ImageType: any;
    ImageTypeId: any;
}

export class UserHomeSection {
    public HomeSectionId: any;
    public SectionTitle: any;
    public ArtistId: any;
    public AlbumId: any;
    public SongId: any;
    public PlaylistId: any;
    public OrderSection: any;
    public isActive: boolean;
    public SectiontypeId: any;
}

export class UserMember {
    public userId: number;
    public parentId: number;
    public fullName: any;
    public displayname : any;
    public email: any;
    public confirmEmail: any;
    public mobileNo: any;
    public userName: any;
    public password: any;
    public confirmPassword: any;
    public countryId: number;
    public country: any;
    public countryCode: any;
    public IsTCAccepted: any;
    public isforKid: boolean;
    public isMobileReq: boolean;
    //public languageId:number;
    public languageCode = "en";
    public userType: any;
    public deviceId: any;
    public deviceType: any;
    public subscriptionType: any;
    public subscriptionStatus: any;
    public lastLoggedIn: any;
    public isActive: boolean;
    public createdOn: any;
    public createdBy: number;
    public updatedOn: any;
    public updatedBy: number;
    public image: any;
    public dateOfBirth: any;
    public gender: any;
    public themeId: any;
    public city: any;
    public provider: any;
    public public: any;
    public provider_pic: any;
    public provider_id: any;
    public Token: any;
    public themeImage: any;
    public braintreeId;
    public brainTreePlanId;
    public subscriptionPlans;
    public brainTreeCustomerId;
    public subscriptionId;
    public braintreeSubscriptionId;
    public braintreeCustomerId;
    public ActivationDays;
    public artistId;
    public sessionId: any;
    public return: any;
    public subscriptionPlanId: any;
    public Address: string;
    public states: string;
    public pinCode: any;
}

export class UserProblemOrSuggestion {
    public ProblemOrSuggestionId: any;
    public UserId: any;
    public ProblemOrSuggessionTypeId: number;
    public Description: string;
    Base64Data: any;
    FileName: any;
    FileType: any;
    thumbImage: any;
}

export class UserNotificationSettings {
    notificationList: {
        UserNotificationSettingsId: any;
        UserId: any;
        notificationId: any;
        isEmail: boolean;
        isPush: boolean;
        createdOn: any;
        updatedOn: any;
    }[];
    // constructor() {
    //     this.notificationList = [];
    // }

}