import { Component, OnInit } from '@angular/core';
import { LoadScriptService } from 'src/app/loadScript.service';
import { Router } from '@angular/router';
import { ArtistsService } from 'src/app/Shared/artists.service';
import { Artist } from 'src/app/models/artist';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/Shared/language.service';
import { Users } from 'src/app/models/users';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ViewartistProfileComponent implements OnInit {
  ArtistDetails = new Artist();
  artistdata: any;
  path;
  fullname;
  description;
  emailid;
  MobileNumber;
  imgURL: any;
  noofLikes;
  nooffav;
  fileToUpload: File;
  noImage: boolean = true;
  languages: any;
  languageId;
  languageCode: any = 'en';
  userDetails = new Users();
  constructor(private loadScript: LoadScriptService,
    private artistservice: ArtistsService,
    public translate: TranslateService,
    private _languageService: LanguageService,
    private router: Router) { }

  ngOnInit() {
    this.ArtistDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.loadScript.getServiceBaseUrl();
    this.GetArtistDetails(this.ArtistDetails.artistId);

  }
  ArtistEdit(artistid: string) {

    this.router.navigate(['/admin/artist/editprofile'], { queryParams: { Id: artistid } });

  }



  GetArtistDetails(artistId) {
    this.ArtistDetails.artistId = artistId;
    this.artistservice.getArtistDetails(this.ArtistDetails.artistId).subscribe((data: any) => {
      this.artistdata = data;
      this.path = data.path;
      this.fullname = data.artistDetails.fullName;
      this.description = data.artistDetails.description;
      this.emailid = data.artistDetails.emailId;
      this.MobileNumber = data.artistDetails.mobileNumber;
      this.noofLikes = data.artistDetails.noofLikes;
      this.nooffav = data.artistDetails.noofFavs;
      this.imgURL = data.path.concat(data.artistDetails.largeImage);
      // this.imgURL= data.artistDetails.largeImage;
    })
  }



}
