import { Component, OnInit } from '@angular/core';
import { Users } from '../../models/users';
import { AlbumService } from 'src/app/Shared/album.service';
import { ArtistsService } from 'src/app/Shared/artists.service';
import { HomeService } from 'src/app/Shared/home.service';
import { SongsService } from 'src/app/Shared/songs.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-artist-howto',
  templateUrl: './artist-howto.component.html',
  styleUrls: ['./artist-howto.component.css']
})
export class ArtistHowtoComponent implements OnInit {

  userDetails = new Users();
  artistalbumcounts: any;
  totalsongcount: any;
  noofLikes: any;
  nooffav: any;
  ArtistId: any = 0;
  howtolist: any;

  constructor(private albumService: AlbumService, private _songsService: SongsService, private _artistsService: ArtistsService, private homeService: HomeService,
    public sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.ArtistId = this.userDetails["artistId"];
    this.getalbumbyartistId();
    this.ArtistSongsById();
    this.GetArtistDetails();
    this.GetAllHowTo();
  }

  howtodo: any = [
    {
      name: 'Lorem Ipsum dolor sit amet, consectetur adipiscing elit',
      role: 'Upload Admin',
      duration: 'Duration: 12:45 min',
      url: '../../../assets/images/home/Rektangel 1.svg'
    },
    {
      name: 'Lorem Ipsum dolor sit amet, consectetur adipiscing elit',
      role: 'Upload Admin',
      duration: 'Duration: 12:45 min',
      url: '../../../assets/images/home/Rektangel 1.svg'
    },
    {
      name: 'Lorem Ipsum dolor sit amet, consectetur adipiscing elit',
      role: 'Upload Admin',
      duration: 'Duration: 12:45 min',
      url: '../../../assets/images/home/Rektangel 1.svg'
    },
    {
      name: 'Lorem Ipsum dolor sit amet, consectetur adipiscing elit',
      role: 'Upload Admin',
      duration: 'Duration: 12:45 min',
      url: '../../../assets/images/home/Rektangel 1.svg'
    },
    {
      name: 'Lorem Ipsum dolor sit amet, consectetur adipiscing elit',
      role: 'Upload Admin',
      duration: 'Duration: 12:45 min',
      url: '../../../assets/images/home/Rektangel 1.svg'
    },
    {
      name: 'Lorem Ipsum dolor sit amet, consectetur adipiscing elit',
      role: 'Upload Admin',
      duration: 'Duration: 12:45 min',
      url: '../../../assets/images/home/Rektangel 1.svg'
    },
    {
      name: 'Lorem Ipsum dolor sit amet, consectetur adipiscing elit',
      role: 'Upload Admin',
      duration: 'Duration: 12:45 min',
      url: '../../../assets/images/home/Rektangel 1.svg'
    },
    {
      name: 'Lorem Ipsum dolor sit amet, consectetur adipiscing elit',
      role: 'Upload Admin',
      duration: 'Duration: 12:45 min',
      url: '../../../assets/images/home/Rektangel 1.svg'
    },
    {
      name: 'Lorem Ipsum dolor sit amet, consectetur adipiscing elit',
      role: 'Upload Admin',
      duration: 'Duration: 12:45 min',
      url: '../../../assets/images/home/Rektangel 1.svg'
    },
  ]

  getalbumbyartistId() {
    this._songsService.GetAllAbumbs(this.ArtistId).subscribe((data: any) => {
      this.artistalbumcounts = data.albumDetails.length;
      console.log('albumartist', this.artistalbumcounts);
    });
  }

  ArtistSongsById() {
    this.homeService.ArtistSongsById(this.ArtistId).subscribe((data: any) => {
      this.totalsongcount = data.artistsongs.length;
    });

  }

  GetArtistDetails() {
    this._artistsService.getArtistDetails(this.ArtistId).subscribe((data: any) => {
      console.log(data);
      this.noofLikes = data.artistDetails.noofLikes;
      this.nooffav = data.artistDetails.noofFavs;
    })
  }

  GetAllHowTo() {
    this._artistsService.GetAllHowto().subscribe((res: any) => {
      this.howtolist = res.allhowto.list;
      this.howtolist.forEach(x => {
        if (x.createdBy == 2) {
          x.createdBy = "Admin"
        };
        x.streamUrl = this.sanitizer.bypassSecurityTrustResourceUrl(x.streamUrl)
      });
      console.log('hwt',this.howtolist);
    })
  }

}
