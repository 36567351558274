import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { PlaylistComponent } from './playlist/playlist.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HomeComponent } from './home/home.component';
import { DiscoverComponent } from './discover/discover.component';
import { SongsComponent } from './songs/songs.component';
import { AdminSongsComponent } from './admin/songs/songs.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from 'src/auth.guard';
import { AdminLoginComponent } from './admin/login/admin-login.component';
import { AlbumComponent } from './admin/album/album.component';
import { ArtistComponent } from './admin/artist/artist.component';
import { AddOrUpdateArtistComponent } from './admin/artist/add-or-update-artist/add-or-update-artist.component';
import { AddUpdateAlbumComponent } from './admin/album/add-update-album/add-update-album.component';
//Move to admin section
import { ResetpasswordComponent } from './admin/resetpassword/resetpassword.component';
import { ArtistResetpasswordComponent } from './admin/artist-resetpassword/artist-resetpassword.component';

import { AddUpdateSongsComponent } from './admin/songs/add-update-songs/add-update-songs.component';
import { UserComponent } from './admin/user/user.component';
import { ViewuserprofileComponent } from './admin/viewuserprofile/viewuserprofile.component';
import { ChangepasswordComponent } from './admin/changepassword/changepassword.component';
import { FavouriteComponent } from './favourite/favourite.component';
import { AllplaylistComponent } from './allplaylist/allplaylist.component';
import { ViewalbumComponent } from './admin/viewalbum/viewalbum.component';
import { ViewsongComponent } from './admin/viewsong/viewsong.component';
import { TobyhomeComponent } from './home/tobyHome/tobyhome/tobyhome.component';
import { ArtistProfileComponent } from './artist/artist-profile/artist-profile.component';
import { AllartistsComponent } from './artist/allartists/allartists.component';
// import { AlbumComponent } from './album/album/album.component';
import { AlbumdetailsComponent } from './album/albumdetails/albumdetails.component';
import { AllsongsComponent } from './album/allsongs/allsongs.component';
import { SongsdetailsComponent } from './album/songsdetails/songsdetails.component';
import { AllfavsongsComponent } from './album/allfavsongs/allfavsongs.component'
import { FavalbumComponent } from './album/favalbum/favalbum.component'
import { FavdjmixComponent } from './album/favdjmix/favdjmix.component'
import { SettingComponent } from './account/setting/setting.component'
import { ThemeComponent } from './account/theme/theme.component';
import { SuggestionComponent } from './account/suggestion/suggestion.component';
import { ReportComponent } from './account/report/report.component';
import { ChangeLanguageComponent } from './account/change-language/change-language.component';
import { EditUserprofileComponent } from './account/editUserprofile/editUserprofile.component';
import { ProfileComponent } from './account/profile/profile.component';
import { AboutusComponent } from './account/aboutus/aboutus.component';
import { TermsComponent } from './account/terms/terms.component';
import { PaymentComponent } from './account/payment/payment.component';
// import { PlaylistComponent } from './playlist/playlist.component';
import { PlaylistsongsComponent } from './playlist/playlistsongs/playlistsongs.component';
import { FavplaylistComponent } from './playlist/favplaylist/favplaylist.component'
import { AlbumComponent1 } from './album/album/album.component';
// import { PlaylistdetailsComponent } from './playlist/playlistdetails/playlistdetails.component';
import { ViewartistProfileComponent } from './admin/artist/profile/profile.component';
import { EditArtistprofileComponent } from './admin/artist/editprofile/editprofile.component';
import { SubscriptionplanComponent } from './admin/subscriptionplan/subscriptionplan.component';
import { UpdatesubscriptionplanComponent } from './admin/subscriptionplan/updatesubscriptionplan/updatesubscriptionplan.component';
import { EdittermsComponent } from './admin/editterms/editterms.component';
import { AdminSuggestionComponent } from './admin/admin-suggestion/admin-suggestion.component';
import { AdminProblemsComponent } from './admin/admin-problems/admin-problems.component';
import { UpdateSuggestionstatusComponent } from './admin/admin-suggestion/update-suggestionstatus/update-suggestionstatus.component';
import { UpdateProblemComponent } from './admin/admin-problems/update-problem/update-problem.component';
import { ArtistJoinUsComponent } from './login/artist-join-us/artist-join-us.component';
import { UpgradeSunscriptionComponent } from './account/upgrade-sunscription/upgrade-sunscription.component';
// import { UploadComponent } from './upload/upload.component';
import { AdminplaylistComponent } from './admin/adminplaylist/adminplaylist.component';
import { AdminPlDetailsComponent } from './admin/adminplaylist/admin-pl-details/admin-pl-details.component';
import { PlaylistdetailsComponent } from './allplaylist/playlistdetails.component';
import { ArtistreportComponent } from './admin/artistreport/artistreport.component';
import { UserInviteComponent } from './account/userinvite/userinvite.component';
import { ArtistMainPageComponent } from './login/artist-main-page/artist-main-page.component';
import { GetAccessComponent } from './login/get-access/get-access.component';
import { ArtistRegisterComponent } from './login/artist-register/artist-register.component';
import { ArtistDashboardComponent } from './home/artist-dashboard/artist-dashboard.component';
import { ArtistAlbumComponent } from './home/artist-album/artist-album.component';
import { ArtistSongsComponent } from './home/artist-songs/artist-songs.component';
import { ArtistCommunicationComponent } from './home/artist-communication/artist-communication.component';
import { ArtistHowtoComponent } from './home/artist-howto/artist-howto.component';
import { ArtistNewProfileComponent } from './home/artist-new-profile/artist-new-profile.component';
import { ArtistChangepasswordComponent } from './home/artist-changepassword/artist-changepassword.component';
import { EditArtistProfileComponent } from './home/edit-artist-profile/edit-artist-profile.component';
import { ArtistpageComponent } from './home/artistpage/artistpage.component';
import { AdminHowtoComponent } from './admin/admin-howto/admin-howto.component';
import { AddUpdateHowtoComponent } from './admin/admin-howto/add-update-howto/add-update-howto.component';
import { AdminTodoListComponent } from './admin/admin-todo-list/admin-todo-list.component';
import { AddUpdateTodolistComponent } from './admin/admin-todo-list/add-update-todolist/add-update-todolist.component';
import { NewHomeComponent } from './home/new-home/new-home.component';
import { NewDiscoverComponent } from './new-discover/new-discover.component';
import { DiscoverAlbumComponent } from './discover-album/discover-album.component';
import { AdminCommunicationComponent } from './admin/admin-communication/admin-communication.component';
import { ArtistFeedbackComponent } from './home/artist-feedback/artist-feedback.component';
import { FeedbackComponent } from './admin/feedback/feedback.component';
import { LikedSongsComponent } from './liked-songs/liked-songs.component';
import { RecommendedSongsComponent } from './recommended-songs/recommended-songs.component';
import { MostPlayedSongsComponent } from './most-played-songs/most-played-songs.component';
import { NewlyAddedSongsComponent } from './newly-added-songs/newly-added-songs.component';
import { MostPlayedAlbumsComponent } from './most-played-albums/most-played-albums.component';
import { MostPlayedArtistComponent } from './most-played-artist/most-played-artist.component';
import { HomePageBannerComponent } from './admin/home-page-banner/home-page-banner.component';
import { AddUpdateHomebannerComponent } from './admin/home-page-banner/add-update-homebanner/add-update-homebanner.component';
import { BannerDetailsComponent } from './banner-details/banner-details.component';
import { ArtistOrAlbumpagesComponent } from './artist-or-albumpages/artist-or-albumpages.component';
import { HomepageSectionComponent } from './admin/homepage-section/homepage-section.component';
import { AddUpdateHomepagesectionComponent } from './admin/homepage-section/add-update-homepagesection/add-update-homepagesection.component';
import { UserAccountComponent } from './account/user-account/user-account.component';
import { ChangeUserPasswordComponent } from './account/change-user-password/change-user-password.component';
import { ChangePaymentComponent } from './account/change-payment/change-payment.component';
import { UsermanageplanComponent } from './usermanageplan/usermanageplan.component';
import { UserNotificationSettingsComponent } from './account/user-notification-settings/user-notification-settings.component';
import { EditLoginMethodsComponent } from './account/edit-login-methods/edit-login-methods.component';
import { UpgradeSubscriptionComponent } from './account/upgrade-subscription/upgrade-subscription.component';
import { UserAlbumsComponent } from './user-albums/user-albums.component';
import { UserHomeComponent } from './home/user-home/user-home.component';
import { UserDiscoverComponent } from './user-discover/user-discover.component';
import { NotificationComponent } from './admin/notification/notification.component';
import { AddUpdateNotificationComponent } from './admin/notification/add-update-notification/add-update-notification.component';
import { UserOrderHistoryComponent } from './account/user-order-history/user-order-history.component';
import { AdminFaqComponent } from './admin/admin-faq/admin-faq.component';
import { AddUpdateFaqComponent } from './admin/admin-faq/add-update-faq/add-update-faq.component';
import { ManageNotificationComponent } from './admin/notification/manage-notification/manage-notification.component';
import { UserProblemsORsuggestionsComponent } from './admin/user-problems-orsuggestions/user-problems-orsuggestions.component';
import { TopmostsongsComponent } from './topmostsongs/topmostsongs.component';
import { TopfavSongspageComponent } from './home/topfav-songspage/topfav-songspage.component';
import { TopCategorySongsComponent } from './home/top-category-songs/top-category-songs.component';
import { LatestArAlSngComponent } from './home/latest-ar-al-sng/latest-ar-al-sng.component';

const appRoutes: Routes = [
  // { path: 'upload', component: UploadComponent },

  { path: 'login', component: LoginComponent },
  { path: 'userregistration/:invitedid', component: LoginComponent },
  // { path: 'memberuserregistration/:mainuserid', component: LoginComponent },
  { path: 'invitemember/:id', component: LoginComponent },
  { path: 'artist-main-page', component: ArtistMainPageComponent },
  { path: 'get-access', component: GetAccessComponent },
  { path: 'artist-register', component: ArtistRegisterComponent },
  { path: 'toby-home', component: TobyhomeComponent, canActivate: [AuthGuard] },


  {
    path: 'artist', component: ArtistpageComponent,
    children: [
      {
        path: 'artist-dashboard', component: ArtistDashboardComponent
      },
      { path: 'artist-album', component: ArtistAlbumComponent },
      { path: 'artist-songs', component: ArtistSongsComponent },
      { path: 'artist-communication', component: ArtistCommunicationComponent },
      { path: 'artist-howto', component: ArtistHowtoComponent },
      { path: 'artist-newprofile', component: ArtistNewProfileComponent },
      { path: 'artist-edit-profile', component: EditArtistProfileComponent },
      { path: 'artist-change-password', component: ArtistChangepasswordComponent },
      { path: 'artist-feedback', component: ArtistFeedbackComponent },
    ]
  },
  // {
  //   path: 'commanHome', component: CommanHomeComponent,
  //   children: [
  //     {
  //       path: 'newhome', component: NewHomeComponent
  //     },
  //   ]
  //     // { path: 'artist-album', component: ArtistAlbumComponent },
  //     // { path: 'artist-songs', component: NewHomeComponent},
  //     // { path: 'artist-communication', component: ArtistCommunicationComponent },
  //     // { path: 'artist-howto', component: ArtistHowtoComponent },
  //     // { path: 'artist-newprofile', component: ArtistNewProfileComponent },
  //     // { path: 'artist-edit-profile', component: EditArtistProfileComponent },
  //     // { path: 'artist-change-password', component: ArtistChangepasswordComponent },
  //   // ]
  // },

  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'nhome', component: NewHomeComponent, canActivate: [AuthGuard] },
  { path: 'newhome', component: UserHomeComponent, canActivate: [AuthGuard] },
  //{ path: 'userhome', component: UserHomeComponent},
  { path: 'user-discover', component: UserDiscoverComponent },
  { path: 'songs', component: SongsComponent },
  { path: 'discover', component: UserDiscoverComponent, canActivate: [AuthGuard] },
  { path: 'newdiscover', component: NewDiscoverComponent, canActivate: [AuthGuard] },
  { path: 'userfav', component: LikedSongsComponent, canActivate: [AuthGuard] },
  { path: 'recommendedsongs', component: RecommendedSongsComponent, canActivate: [AuthGuard] },
  { path: 'mostplayedsongs', component: MostPlayedSongsComponent, canActivate: [AuthGuard] },
  { path: 'mostplayedalbums', component: MostPlayedAlbumsComponent, canActivate: [AuthGuard] },
  { path: 'hitmakers', component: MostPlayedArtistComponent, canActivate: [AuthGuard] },
  { path: 'newlyaddedsongs', component: NewlyAddedSongsComponent, canActivate: [AuthGuard] },
  { path: 'discoverAlbum/:id', component: DiscoverAlbumComponent, canActivate: [AuthGuard] },
  { path: 'favourites', component: FavouriteComponent },
  { path: 'allplaylist', component: AllplaylistComponent },
  { path: 'Suggestedplaylistdetail', component: PlaylistdetailsComponent },
  // { path: 'artist/artistprofile', component: ArtistProfileComponent },
  { path: 'artist/artistprofile', component: ArtistProfileComponent },
  { path: 'artist/allartists', component: AllartistsComponent },
  { path: 'album/album-details', component: AlbumdetailsComponent },
  { path: 'album/allsongs', component: AllsongsComponent },
  { path: 'album/songsdetails', component: SongsdetailsComponent },
  { path: 'album/allfavsongs', component: AllfavsongsComponent },
  { path: 'album/favalbum', component: FavalbumComponent },
  { path: 'album/album', component: AlbumComponent1 },
  { path: 'album/favdjmix', component: FavdjmixComponent },
  { path: 'account/setting', component: SettingComponent },
  { path: 'account/payment', component: PaymentComponent },
  { path: 'account/terms', component: TermsComponent },
  { path: 'account/theme', component: ThemeComponent },
  { path: 'account/suggestion', component: SuggestionComponent },
  { path: 'account/language', component: ChangeLanguageComponent },
  { path: 'account/report', component: ReportComponent },
  { path: 'account/aboutus', component: AboutusComponent },
  { path: 'account/edituserprofile', component: EditUserprofileComponent },
  { path: 'account/profile', component: ProfileComponent },
  { path: 'account/upgradeplan', component: UpgradeSunscriptionComponent },
  { path: 'account/user-order-history', component: UserOrderHistoryComponent },
  { path: 'playlist/playlist1', component: PlaylistComponent },
  { path: 'playlist/allplaylistsongs', component: PlaylistsongsComponent },
  { path: 'playlist/favallplaylist', component: FavplaylistComponent },
  { path: 'login/artist-joinus', component: ArtistJoinUsComponent },
  { path: 'artistalbumpage', component: ArtistOrAlbumpagesComponent },
  // {path:'artistalbumpage/:album/:id', component:ArtistOrAlbumpagesComponent},

  //{ path: '**' , component:ArtistJoinUsComponent},
  { path: 'admin/admin-login', component: AdminLoginComponent },

  { path: 'admin/album', component: AlbumComponent },
  {
    path: 'admin/artist', component: ArtistComponent
  },

  {
    path: 'admin/songs', component: AdminSongsComponent
  },

  {
    path: 'admin/Adminplaylist', component: AdminplaylistComponent
  },
  {
    path: 'admin/playlistdetails', component: AdminPlDetailsComponent
  },
  {
    path: 'admin/viewalbum', component: ViewalbumComponent
  },
  {
    path: 'admin/viewalbum/:id', component: ViewalbumComponent
  },
  {
    path: 'admin/viewsong/:id', component: ViewsongComponent
  },
  {
    path: 'admin/artist/add-artist', component: AddOrUpdateArtistComponent
  },

  {
    path: 'admin/artist/update-artist/:id', component: AddOrUpdateArtistComponent
  },
  {
    path: 'admin/artist/profile', component: ViewartistProfileComponent
  },
  {
    path: 'admin/artist/editprofile', component: EditArtistprofileComponent
  },
  {
    path: 'admin/album/add-album', component: AddUpdateAlbumComponent
  },

  {
    path: 'resetpassword/:id', component: ResetpasswordComponent,
  },
  {
    path: 'admin/album/update-album/:id', component: AddUpdateAlbumComponent
  },
  {
    path: 'artist-resetpassword/:id1', component: ArtistResetpasswordComponent,
  },
  {
    path: 'admin/album/update-album/:id', component: AddUpdateAlbumComponent
  },
  {
    path: 'admin/songs/add-songs', component: AddUpdateSongsComponent

  },
  {
    path: 'admin/songs/update-songs/:id', component: AddUpdateSongsComponent
  },
  {
    path: 'admin/user', component: UserComponent
  },
  {
    path: 'admin/songs/:id1/:id2', component: AdminSongsComponent
  },
  {
    path: 'admin/viewuserprofile/:id', component: ViewuserprofileComponent
  },

  {
    path: 'admin/album/:id', component: AlbumComponent

  },
  {
    path: 'admin/Subscriptionplan', component: SubscriptionplanComponent
  },
  {
    path: 'admin/updatesubscription', component: UpdatesubscriptionplanComponent
  },
  {
    path: 'user/changepassword', component: ChangepasswordComponent

  },
  {
    path: 'artist/changepassword', component: ChangepasswordComponent

  },
  {
    path: 'admin/admin-suggestion', component: AdminSuggestionComponent
  },
  {
    path: 'admin/admin-problems', component: AdminProblemsComponent
  },
  {
    path: 'admin/editterms', component: EdittermsComponent
  },
  {
    path: 'admin/updatesuggestion/:id', component: UpdateSuggestionstatusComponent
  },
  {
    path: 'admin/updateproblem/:id', component: UpdateProblemComponent
  },
  {
    path: 'admin/artistreport', component: ArtistreportComponent
  },
  {
    path: 'admin/admin-faq', component: AdminFaqComponent
  },
  { path: 'account/userinvite', component: UserInviteComponent },

  {
    path: 'admin/howto', component: AdminHowtoComponent
  },

  {
    path: 'admin/howto/add-update-howto', component: AddUpdateHowtoComponent
  },

  {
    path: 'admin/howto/update-howto/:id', component: AddUpdateHowtoComponent
  },

  {
    path: 'admin/todolist', component: AdminTodoListComponent
  },
  {
    path: 'admin/notification', component: NotificationComponent
  },
  {
    path: 'admin/todolist/add-todo-list', component: AddUpdateTodolistComponent
  },
  {
    path: 'admin/notification/add-notification', component: AddUpdateNotificationComponent
  },

  {
    path: 'admin/notification/update-notification/:id', component: AddUpdateNotificationComponent
  },
  {
    path: 'admin/todolist/update-todo-list/:id', component: AddUpdateTodolistComponent
  },

  {
    path: 'admin/communication', component: AdminCommunicationComponent
  },
  {
    path: 'admin/feedback', component: FeedbackComponent
  },
  {
    path: 'admin/home-page-banner', component: HomePageBannerComponent
  },
  {
    path: 'admin/home-page-banner/add-update-homebanner', component: AddUpdateHomebannerComponent
  },
  {
    path: 'admin/home-page-banner/update-homebanner/:id', component: AddUpdateHomebannerComponent
  },
  {
    path: 'admin/admin-faq', component: AdminFaqComponent
  },
  {
    path: 'admin/admin-faq/add-update-faq', component: AddUpdateFaqComponent
  },
  {
    path: 'admin/admin-faq/add-update-faq/:id', component: AddUpdateFaqComponent
  },
  {
    path: 'bannerdetails/:id', component: BannerDetailsComponent
  },
  {
    path: 'artistalbumpage/:id', component: ArtistOrAlbumpagesComponent
  },
  {
    path: 'admin/homepage-Section', component: HomepageSectionComponent
  },
  {
    path: 'admin/homepage-Section/add-update-homesection', component: AddUpdateHomepagesectionComponent
  },
  {
    path: 'admin/homepage-Section/update-homesection/:id', component: AddUpdateHomepagesectionComponent
  },
  {
    path: 'account/user-account', component: UserAccountComponent
  },
  {
    path: 'account/user-changepassword', component: ChangeUserPasswordComponent
  },
  {
    path: 'account/usermanagemember', component: UsermanageplanComponent  // for UserManageMember
  },
  {
    path: 'account/change-payment', component: ChangePaymentComponent
  },
  {
    path: 'account/notification-settings', component: UserNotificationSettingsComponent
  },
  {
    path: 'account/edit-loginmethods', component: EditLoginMethodsComponent
  },
  {
    path: 'account/upgradesubscription', component: UpgradeSubscriptionComponent
  },
  { path: 'user-albums', component: UserAlbumsComponent, canActivate: [AuthGuard] },
  {
    path: 'admin/notification/manage-notification/:id', component: ManageNotificationComponent
  },
  {
    path: 'admin/user-problems-suggestions', component: UserProblemsORsuggestionsComponent
  },
  {
    path: 'topmostsongs/:id', component: TopmostsongsComponent
  },
  {
    path: 'topfavsongs/:id', component: TopfavSongspageComponent
  },
  {
    path: 'topcategorysongs/:id', component: TopCategorySongsComponent
  },
  {
    path: 'latest-ar-al-sng/:id', component: LatestArAlSngComponent
  },
  
];

//initialNavigation: false

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {
    onSameUrlNavigation: 'reload',
  })],
  declarations: []
})
export class AppRoutingModule { }