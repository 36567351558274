import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { UserService } from 'src/app/Shared/user.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Notification } from 'src/app/models/notification';
import { Users } from 'src/app/models/users';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-add-update-notification',
  templateUrl: './add-update-notification.component.html',
  styleUrls: ['./add-update-notification.component.css']
})
export class AddUpdateNotificationComponent implements OnInit {
  
  notification = new Notification();
  userDetails = new Users();
  notificationId:number=0;

  constructor( public location: Location,
    private userService:UserService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.notificationId=Number(this.route.snapshot.paramMap.get("id"));
    this.getNotificationById();
  }

  back(){
    this.location.back();
  }

  onSubmit() {

    if (this.userDetails["userId"] != undefined) {
      this.notification.createdBy = this.userDetails["userId"].toString();
      this.notification.updatedBy = this.userDetails["userId"].toString();
    }

    console.log(this.notification);
    
    this.userService.AddUpdateAdmNotification(this.notification).subscribe((res: any) => {
      if (res.status == 200) {
        console.log(res.data);
        debugger;
        if (this.notification.NotificationId != 0) {
          this.toastr.success("Notification updated successfully");
          this.spinner.hide();
        } else {
          this.toastr.success("Notification added successfully");
          this.spinner.hide();
        }
        this.location.back();
      }
    }, err => {
      this.toastr.error(err)
      this.spinner.hide();
    })
  }
  getNotificationById(){
    this.userService.getNotificationById(this.notificationId).subscribe((notif:any)=>{
      console.log(notif);
      this.notification=notif.notification;
    })
  }
}
