export class Playlist {
   PlaylistId: any;
   UserId: any;
   Name: any;
   NoofSongs: any
   Length: any;
   SongId: any;
   title: any;
   AdminSongId:any[] =[];
   thumbImage:any;
   FileName:any;
   Base64Data:any;
   FileType:any;
   isTCAccepted:any;
   SongIds:any[] =[];
}
