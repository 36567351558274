export class Miniapproval {
    statusId:any;
    comments:any;
    albumId:any;
    songId:any;
    createdBy:any;
    userType:any;
    updateBy:any;

}
