import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { LoadScriptService } from 'src/app/loadScript.service';
import { HomeService } from '../Shared/home.service';
import { Playlist } from '../models/playlist';
import { ActivatedRoute, Router } from '@angular/router';
import { Role } from '../models/role';
import { Track } from '../../../Player/ngx-audio-player/src/lib/model/track.model'
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { PlayerService } from '../Shared/player.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from "ngx-spinner";
import { Location } from '@angular/common';
import { UserService } from '../Shared/user.service';
import { SongsService } from '../Shared/songs.service';
import { AlbumService } from '../Shared/album.service'
import { ToastrService } from 'ngx-toastr';
import { ArtistsService } from '../Shared/artists.service';
import { LoginService } from '../Shared/login.service';
import { Users } from '../models/users';

@Component({
  selector: 'app-favourite',
  templateUrl: './favourite.component.html',
  styleUrls: ['./favourite.component.css']
})
export class FavouriteComponent implements OnInit {

  userLogin: boolean = false
  userDetails = new Users();
  GetArtistDetails = [];
  SearchText = "";
  pageIndex: any = 1;
  alphabeticArray = [];
  sortArray = [];
  searchedArtists = [];
  clickedAlbhabet: boolean[] = [];
  slides = [];
  isAll = true;
  isArtist = false;
  isSong = false;
  isAlbum = false;
  isPlaylist = false;
  ArtistId: any = 0;
  columName: any = "createdOn";
  sortedOrder: any = "desc";
  @ViewChild('sectionNeedToScroll') sectionNeedToScroll: ElementRef;
  temp: any;
  previousAlphabet: any = 'a';
  homeDetails: any;
  path: any;
  artistList: any;
  topSongs: any;
  mostPlayedSongs = [];
  searchName: any;
  songid: any;
  favId: any;
  likeId: any;
  repeat = [];
  imgRepeat = [];

  featureAlbums = [
    {
      name: "Leela Khteeta",
      banner: "../../../assets/images/newhomeIcon/Image.svg",
      artistName: "Linda George",
      time: "3:49"
    },
    {
      name: "Leela Khteeta",
      banner: "../../../assets/images/newhomeIcon/Image.svg",
      artistName: "Linda George",
      time: "3:49"
    },
    {
      name: "Leela Khteeta",
      banner: "../../../assets/images/newhomeIcon/Image.svg",
      artistName: "Linda George",
      time: "3:49"
    },
    {
      name: "Leela Khteeta",
      banner: "../../../assets/images/newhomeIcon/Image.svg",
      artistName: "Linda George",
      time: "3:49"
    },
    {
      name: "Leela Khteeta",
      banner: "../../../assets/images/newhomeIcon/Image.svg",
      artistName: "Linda George",
      time: "3:49"
    }
  ]
  allAlbum: any;
  alldata: any;
  topArtist: any;
  MPSongs: any;
  featuredSong: any;
  discography: any;
  serarchArtist: any = null;
  songList: any;
  topArtistimg: any;
  columname: any;
  sortedorder: any = "desc";
  searchname: any;
  AlbumId: any = 0;
  pageSize: any = 50;
  discoverArtist: any;
  discoverAlbum: any;
  discoverSongs: any;
  discoverPlaylist: any;
  playListDetails: any;
  playlists10: any;
  searchingText: any;
  userid: any;
  songdataList: any = [];
  nextSonglist: any = [];
  isSearch = false;
  languageCode: any;
  theme: any;
  songIsthere: boolean = false;
  substatus: string;

  userId: any;
  favitems: any;
  favdetailitems: any;
  favalbums = [];
  favsongs = [];
  favartists = [];
  favdjmix = [];
  fdjmix = [];
  favplaylist: any;

  fplayLis = [];

  index = 0;
  falbums = [];
  fartists = [];
  playlist = new Playlist();
  public artistListArray = [];
  favsong = [];

  IsMobileReq: boolean;
  isActive: boolean = true;
  IsActiveSongId: any;
  name: any;
  image: any;
  artistData: any;
  isLoading: boolean = false;
  paramId: any;
  screenwidth: any;
  isHeartActive: boolean = false;
  isDisplayanima: boolean = false;
  animationInterval: any;

  constructor(public translate: TranslateService,
    private _toastr: ToastrService,
    private router: Router,
    private loadScript: LoadScriptService,
    private _loginService: LoginService,
    private _artistService: ArtistsService,
    private spinner: NgxSpinnerService,
    public location: Location,
    public userService: UserService,
    private _homeService: HomeService, private albumService: AlbumService,
    public songsService: SongsService,
    private _playerService: PlayerService,
    public playerService: AudioPlayerService,
    private _artistsService: ArtistsService, private route: ActivatedRoute,) {
    this.repeat.length = 4;
    this.imgRepeat.length = 2;
    this.updateDisplayCount(window.innerWidth);
    this.playerService.getIsblogorCountryScreen().subscribe(value => {
      if (value) {
        this.IsActiveSongId = value;
      }
    });
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.loadScript.getServiceBaseUrl();
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.userId = this.userDetails.userId;
    this.IsMobileReq = this.userDetails.isMobileReq == null ? false : true;
    this.getfavourites();
    this.paramId = this.route.snapshot.queryParams["Id"];
    if (this.paramId) {
      this.all(this.paramId, 4)
    }
    this.updateDisplayCount(window.innerWidth);
    this.startAnimationCycle();
  }
  displayCount = 5;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateDisplayCount(event.target.innerWidth);
    this.screenwidth = event.target.innerWidth;
    console.log('chk width', event.target.innerWidth);

  }
  updateDisplayCount(width: number) {
    debugger;
    if (width >= 1500 && width <= 1600) {
      this.displayCount = 5;
    }
    else if (width >= 1700 && width <= 1899) {
      this.displayCount = 6;
    }
    else if (width >= 1900 && width <= 1999) {
      this.displayCount = 7;
    }
    else if (width >= 2000 && width <= 3000) {
      this.displayCount = 7;
    }
    else if (width >= 3000 && width <= 7000) {
      this.displayCount = 8;
    }
    else if (width >= 1300 && width <= 1400) {
      this.displayCount = 5;
    }
    else if (width >= 870 && width <= 1200) { //for 150%
      this.displayCount = 4;
    }
    else if (width >= 700 && width <= 871) {
      this.displayCount = 3;
    }
    else if (width <= 1404) {
      this.displayCount = 4;
    }

  }

  get displayArtists() {
    if (this.favartists) {
      return this.favartists.slice(0, this.displayCount);
    }
  }

  get displayAlbums() {
    if (this.favalbums) {
      return this.favalbums.slice(0, this.displayCount);
    }
  }

  Artistprofile(ArtistId: number) {
    this.router.navigate(['/newdiscover'], { queryParams: { Id: ArtistId } });
  }

  back() {
    this.location.back();
  }

  all(id: any, index: number) {
    for (let i = 0; i < this.allChips.length; i++) {
      this.allChips[i].isActive = false
    }
    this.allChips[index].isActive = true;
    if (id == 1) {
      this.isAll = true;
      this.isSong = false;
      this.isArtist = false;
      this.isAlbum = false;
      this.isPlaylist = false;
    }
    if (id == 2) {
      this.isArtist = true;
      this.isAll = false;
      this.isSong = false;
      this.isAlbum = false;
      this.isPlaylist = false;
    }
    if (id == 3) {
      this.isSong = false;
      this.isArtist = false;
      this.isAll = false;
      this.isAlbum = true;
      this.isPlaylist = false;

    }
    if (id == 4) {
      this.isSong = true;
      this.isArtist = false;
      this.isAll = false;
      this.isAlbum = false;
      this.isPlaylist = false;


    }
    if (id == 5) {
      this.isPlaylist = true;
      this.isSong = false;
      this.isArtist = false;
      this.isAll = false;
      this.isAlbum = false;
      this.getplaylist(this.userId);
    }
  }

  allChips = [
    { isActive: true, id: 1, name: "All" },
    { isActive: false, id: 2, name: "Artists" },
    { isActive: false, id: 3, name: "Albums" },
    { isActive: false, id: 4, name: "Songs" },
    { isActive: false, id: 5, name: "Playlists" },
  ]

  // getDiscoverDetails() {
  //   var serarchArtist = this.serarchArtist == null || this.serarchArtist == '' ? null : this.serarchArtist;
  //   this._artistService.GetDiscoverDetails(serarchArtist, this.userid).subscribe((data: any) => {
  //     this.alldata = data.getdiscover;
  //     this.topArtist = this.alldata.topArtist[0].fullName;
  //     this.topArtistimg = this.alldata.topArtist[0].largeImage;
  //     this.MPSongs = this.alldata.mostPlayedSongs;
  //     this.featuredSong = this.alldata.featuredSongs;
  //     this.discography = this.alldata.discography;
  //     this.songList = this.alldata.songsList;
  //     this.discoverArtist = this.alldata.discoverArtist;
  //     this.discoverAlbum = this.alldata.discoverAlbum;
  //     this.discoverSongs = this.alldata.discoverSongs;
  //     this.discoverPlaylist = this.alldata.discoverPlaylist;
  //     this.songdataList = (this.discoverSongs).slice(0, 10);
  //     this.nextSonglist = (this.discoverSongs).slice(10, 20);
  //   });
  // }

  OnsearchedArtistsChange() {
    this.SearchText = this.serarchArtist;
    if (this.SearchText != null && this.SearchText != "") {


      // this.getDiscoverDetails();

    }
    else if (this.SearchText == null || this.SearchText == "") {
      this.isSearch = false;
      this.userService.IsRouting(true);
      this._toastr.error("Please enter search text.");
    }



  }

  OnsearchedChange($event) {

    if ($event != "") {
      this.searchingText = $event;
      this.SearchText = (this.searchingText);
      //this.getDiscoverDetails();
      this.userService.IsRouting(false);
    }
    else if ($event == "") {
      this.isSearch = false;
      this.userService.IsRouting(true);
    }
    else {
      this.isSearch = false;
    }


  }

  showAllalbum() {
    this.all(3, 2);
    window.scrollTo(0, 0);
  }

  showAllsongs() {
    this.all(4, 3)
    window.scrollTo(0, 0);
  }

  getplaylist(userId) {
    debugger;
    this._homeService.getPlaylist(userId).subscribe((data: any) => {
      console.log(data)
      debugger;
      if(data.playListDetails){
        this.playListDetails = data.playListDetails;
        console.log(this.playListDetails);
        this.playlists10 = this.playListDetails.slice(0, 10);
        this._homeService.allPlayList.next(data.playListDetails);
      }
      
      this.updateDisplayCount(window.innerWidth);
    })
  }

  logOut() {
    let UserId = this.userDetails.userId;
    let SessionId = this.userDetails.sessionId;
    this._loginService.logOutAudit(UserId, SessionId).subscribe((res: any) => {
    })
    localStorage.clear();
    localStorage.setItem('themeid', this.userDetails.themeId);
    localStorage.setItem('languagecode', this.userDetails.languageCode);
    this.translate.use("en");
    localStorage.clear();
    this.router.navigate(['/login']);
    localStorage.removeItem("UserDetails");

  }

  CheckSubscription(song, i) {
    this.songIsthere = true;
    this.playSong(song, i);










  }
  closeSubcheckForm() {
    document.getElementById("SubcheckForm").style.display = "none";
    this.router.navigate(['/account/upgradeplan']);
  }

  IsCheckLang() {
    if (this.translate.currentLang == 'ar', this.translate.currentLang == 'as') {
      return true;
    } else {
      return false;
    }
  }

  getfavourites() {
debugger;
    this.isLoading = true;
    this._homeService.favouritesItems(this.userId, this.IsMobileReq).subscribe((data: any) => {
      console.log(data)

      if (data.isSuccess) {
        if (data.favouriteDetails.favouriteAlbums.length == 0 && data.favouriteDetails.favouriteArtists.length == 0 && data.favouriteDetails.favouriteDjMix
          .length == 0 && data.favouriteDetails.favouriteSong
            .length == 0 ) {
              this.isDisplayanima = true;
        }
        this.favitems = data;

        this.path = this.favitems.path;
        this.favsongs = this.favitems.favouriteDetails.favouriteSong;
        this.favalbums = this.favitems.favouriteDetails.favouriteAlbums;
        this.favartists = this.favitems.favouriteDetails.favouriteArtists;
        var artistId = this.favsongs.length ? this.favsongs[0].artistId : this.favartists.length ? this.favartists[0].artistId : this.favalbums.length ? this.favalbums[0].artistId : 0;
        console.log(this.favartists);
        if (artistId) {
          this.getArtistbyId(artistId);
        }
        this.getplaylist(this.userId);
        console.log(this.playListDetails);

        if (this.playListDetails) {
          if (this.playListDetails.length >= 6) {
            this.falbums = this.favalbums;
            this.songsService.getfavalbum(this.favalbums);
            this.songsService.falbumss = this.favalbums;
            this.falbums.forEach((album, i) => {
              if (i >= this.index && i < 6) {
                this.falbums[i] = this.favalbums[i];
              }
            });
          } else {
            this.falbums = this.favalbums;
            this.songsService.getfavalbum(this.favalbums);
            this.songsService.falbumss = this.favalbums;
            this.falbums.forEach((album, i) => {
              if (i >= this.index && i < this.favalbums.length) {
                this.falbums[i] = this.favalbums[i];
              }
            });
          }
        }

        this.favsongs = this.favitems.favouriteDetails.favouriteSong;
        if (this.favsongs.length >= 6) {
          this.songsService.favsongs = this.favsongs;
          this.favsong.forEach((song, i) => {
            if (i >= this.index && i < 6) {
              this.favsong[i] = this.favsongs[i];
            }
          });
        } else {
          this.songsService.favsongs = this.favsongs;
          this.favsong.forEach((song, i) => {
            if (i >= this.index && i < this.favsongs.length) {
              this.favsong[i] = this.favsongs[i];
            }
          });
        }

        if (this.playListDetails) {
          if (this.playListDetails.length >= 5) {
            this.songsService.favartist = this.favartists;

            this.fartists.forEach((artist, i) => {
              if (i >= this.index && i < 5) {
                this.fartists[i] = this.favartists[i];
              }
            });
          } else {
            this.songsService.favartist = this.favartists;
            this.fartists.forEach((artist, i) => {
              if (i >= this.index && i < this.favartists.length) {
                this.fartists[i] = this.favartists[i];
              }
            });
          }
          console.log(this.songsService.favartist);
        }

        this.favdjmix = this.favitems.favouriteDetails.favouriteDjMix;
        if (this.favdjmix.length >= 6) {
          this.songsService.favdjmix = this.favdjmix;
          this.fdjmix.forEach((mix, i) => {
            if (i >= this.index && i < 6) {
              this.fdjmix[i] = this.favdjmix[i];
            }
          });
        } else {
          this.songsService.favdjmix = this.favdjmix;
          this.fdjmix.forEach((mix, i) => {
            if (i >= this.index && i < this.favdjmix.length) {
              this.fdjmix[i] = this.favdjmix[i];
            }
          });
        }

        if (this.playListDetails) {
          this.playListDetails = this.favitems.favouriteDetails.playlistEntities;
          this.fplayLis = [];
          if (this.playListDetails.length >= 3) {
            this.playListDetails.forEach((entity, i) => {
              if (i >= this.index && i < 3) {
                this.songsService.favplaylistentities[i] = entity;
                this.fplayLis[i] = entity;
              }
            });
          } else {
            this.songsService.favplaylistentities = this.playListDetails;
            this.playListDetails.forEach((entity, i) => {
              if (i >= this.index && i < this.playListDetails.length) {
                this.fplayLis[i] = entity;
              }
            });
          }
        }
        this.spinner.hide();
        this.isLoading = false;
      }
    });

  }

  ShowAllartist() {
    this.all(2, 1);
    window.scrollTo(0, 0);
  }

  playSong(songs, index) {
    // const updatedItem = {
    //   ...songs,
    //   thumbImage: this.path + songs.thumbImage
    // };
    // this._playerService.playSongs(updatedItem);
    // if (this.playerService.repeatSong) {
    //   this.playerService.repeatSong = false;
    // }
    // this.playerService.setPlaylist(this._playerService.msaapPlaylist);
    // this.playerService.selectATrack(index + 1);
    // this._playerService.updateData(this._playerService.msaapPlaylist);
    // this.playerService.playSongCallback(true);

    this._playerService.msaapPlaylist = [];
    songs.forEach((song, i) => {
      const trackObj = new Track();
      trackObj.index = i;
      trackObj.image = this.path + song.thumbImage;
      trackObj.artistName = song.artistName;
      trackObj.link = song.streamUrl;
      trackObj.title = song.title;
      trackObj.songId = song.songId;
      this._playerService.msaapPlaylist.push(trackObj);
    });
    this.playerService.playSongCallback(true);

    if (this.playerService.repeatSong) {
      this.playerService.repeatSong = false;
    }
    this.playerService.setPlaylist(this._playerService.msaapPlaylist);
    this.playerService.selectATrack(index + 1);
    this._playerService.updateData(this._playerService.msaapPlaylist);
  }
  ViewPlaylist(playlistId) {
    this.router.navigate(['/playlist/allplaylistsongs'], { queryParams: { Id: playlistId } });
  }

  getArtistbyId(artistid) {
    this._artistsService.getArtistDetails(artistid).subscribe((data: any) => {

      this.artistData = data.artistDetails
      this.image = data.path.concat(data.artistDetails.thumbImage);

      this.name = data.artistDetails.fullName;

    })
  }

  ngOnDestroy(): void {
    // Clear the interval when the component is destroyed
    clearInterval(this.animationInterval);
  }

  startAnimationCycle(): void {
    // Trigger animation immediately and every 20 seconds
    this.triggerAnimation();
    this.animationInterval = setInterval(() => {
      this.triggerAnimation();
    }, 2000); // 20 seconds
  }

  triggerAnimation(): void {
    // Show hand animation
    //this.showHand = true;

    setTimeout(() => {
      // Trigger heart animation after hand animation
      //this.showHand = false;
      this.isHeartActive = true;

      // Reset heart animation after a delay
      setTimeout(() => {
        this.isHeartActive = false;
      }, 1000); // Matches the heart's animation duration
    }, 1000); // Matches the hand's animation duration
  }

}
