import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/Shared/user.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
  notification: any = [];
  Checkrecords: boolean;

  constructor(private router: Router,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.getallNotification();
  }

  add() {
    this.router.navigate(['admin/notification/add-notification']);
  }

  edit(Id) {
    this.router.navigate([`admin/notification/update-notification/` + Id + ``]);
  } 


  getallNotification() {
    this.userService.GetAllAdminNotification().subscribe((res: any) => {
      this.notification = res.notification.list;
      this.Checkrecords = this.notification.length === 0;
      console.log(this.notification);
    })
  }

  manage(id){
    this.router.navigate(['admin/notification/manage-notification/'+id]);
  }


}
