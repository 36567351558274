import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { LoginService } from 'src/app/Shared/login.service';
import { SubscriptionService } from 'src/app/Shared/subscription.service';
import { ConfigService } from 'src/app/app.config';
import { LoadScriptService } from 'src/app/loadScript.service';
import { Users } from 'src/app/models/users';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-change-payment',
  templateUrl: './change-payment.component.html',
  styleUrls: ['./change-payment.component.css']
})
export class ChangePaymentComponent implements OnInit {
  @ViewChild('paymentRef') paymentRef: ElementRef;
  visibleAEcontent = false;
  visibleDebitCredit = false;
  displayCircleAE = true;
  displayCircleDC = true;
  displayCirclePaypal = true;


  baseUrl: any;
  clientUrlToken: string;
  //clientTokenUrl2: string
  userDetails = new Users();
  userId: number; username: any;
  email: any;
  chargeAmount: any;
  currentDate = new Date();
  currantDate: any;
  endDate: any;
  date: Date;
  Date: Date;
  emailid: any;
  massage: string;
  BrainTreeplanId: any;
  BraintrreId: any;
  b: any = 1;
  userSubscriptionId: number;
  userSubscriptionDetails: any;
  private purchaseSubscription: Subscription;

  constructor(private configService: ConfigService, private _router: Router,
    private _loginService: LoginService,
    private _toastr: ToastrService,
    private loadScript: LoadScriptService,
    private subscriptionService: SubscriptionService,
    private http: HttpClient,
    public translate: TranslateService,
    private datePipe: DatePipe,
  ) {
    this.baseUrl = configService.getServiceBaseUrl();
    this.clientUrlToken = this.baseUrl + 'api/Paypal/GenerateToken';
    this.subscriptionService.UserSubscriptionId.subscribe(id => this.userSubscriptionId = id,
     
    );
    
   console.log(this.userSubscriptionId);
  }

  ngOnInit() {
    // console.log(environment.paypal)
    // environment.paypal.Buttons().render(this.paymentRef.nativeElement)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    this.date = new Date();
    this.date.setDate(this.date.getDate() + 3);
    this.loadScript.getServiceBaseUrl();
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.emailid = this.userDetails.email;
    this.username = this.userDetails.userName;
    this.userId = this.userDetails.userId;

  }

  chooseCard(num: number) {
    debugger
    if (num == 1) {
      var ele1 = (document.getElementsByClassName("paymentbody")[0]) as HTMLElement;
      var elem1 = (document.getElementsByClassName("circlesize")[0]) as HTMLElement;
      var ele2 = (document.getElementsByClassName("paymentbody")[2]) as HTMLElement;
      var elem2 = (document.getElementsByClassName("circlesize")[2]) as HTMLElement;
      console.log(ele1, elem1, ele2, elem2);
      ele1.style.display = "none";
      elem1.style.color = "white";
      ele2.style.display = "none";
      elem2.style.color = "white";
      this.displayCircleDC = !this.displayCircleDC;
      this.displayCirclePaypal = true;
      this.displayCircleAE = true;
    }
    else if (num == 2) {
      var ele1 = (document.getElementsByClassName("paymentbody")[0]) as HTMLElement;
      var elem1 = (document.getElementsByClassName("circlesize")[0]) as HTMLElement;
      var ele2 = (document.getElementsByClassName("paymentbody")[1]) as HTMLElement;
      var elem2 = (document.getElementsByClassName("circlesize")[1]) as HTMLElement;
      console.log(ele1, elem1, ele2, elem2);
      ele1.style.display = "none";
      elem1.style.color = "white";
      ele2.style.display = "none";
      elem2.style.color = "white";
      this.displayCirclePaypal = !this.displayCirclePaypal;
      this.displayCircleAE = true;
      this.displayCircleDC = true;
    }
    else {
      var ele1 = (document.getElementsByClassName("paymentbody")[1]) as HTMLElement;
      var elem1 = (document.getElementsByClassName("circlesize")[1]) as HTMLElement;
      // var  ele2 = (document.getElementsByClassName("paymentbody")[2]) as HTMLElement;
      // var elem2=(document.getElementsByClassName("circlesize")[2]) as HTMLElement;
      var ele2 = (document.getElementsByClassName("paymentbody")[2]) as HTMLElement;
      var elem2 = (document.getElementsByClassName("circlesize")[2]) as HTMLElement;
      console.log(ele1, elem1, ele2, elem2);
      ele1.style.display = "none";
      elem1.style.color = "white";
      ele2.style.display = "none";
      elem2.style.color = "white";
      this.displayCircleAE = !this.displayCircleAE;
      this.displayCircleDC = true;
      this.displayCirclePaypal = true;
    }
    var elem = (document.getElementsByClassName("circlesize")[num]) as HTMLElement;
    //elem.style.color="#FFAA00";
    var ele = (document.getElementsByClassName("paymentbody")[num]) as HTMLElement;
    console.log(ele.style.display)
    if (ele.style.display == "block") {
      ele.style.display = "none";
      elem.style.color = "white";
    }
    else {
      ele.style.display = "block";
      elem.style.color = "#FFAA00";
    } console.log(ele, elem, this.displayCircleAE);

  }
  cancel() {
    this._router.navigate([`/account/user-account`]);
  }

  createPurchase = (nonce: string, chargeAmount: number, Userid: number, email: string, name: string, BraintrreId: string) => {
    debugger;
    this.username = this.userDetails.fullName;
    this.userId = this.userDetails.userId;
    this.email = this.userDetails.email;
    if(this.userSubscriptionId != 0){
      this.userSubscriptionDetails = this.userDetails.subscriptionPlans.filter(x => x.subscriptionPlanId == this.userSubscriptionId);
      this.chargeAmount = this.userSubscriptionDetails[0].offeredprice;
      this.BraintrreId = this.userSubscriptionDetails[0].brainTreePlanId;
    }
    // this.chargeAmount = this.userDetails.subscriptionPlans[this.b].offeredprice;
    // this.BraintrreId = this.userDetails.subscriptionPlans[this.b].brainTreePlanId;

        var purchase = {
            nonce: nonce, chargeAmount: this.chargeAmount, Userid: this.userId, email: this.email, BraintrreId: this.BraintrreId, name: this.username
        }

        this.purchaseSubscription = this.subscriptionService.Purchase(purchase).subscribe((response: any) => {
    debugger;
            if (response == false) {
                this._toastr.success('Pre-Approved Payment enabled PayPal account required for vaulting.')
                // this.showHidePlan = true;
                // this.showPaymentSection = false;
            }
            else {
                console.log('payment' , response);
                var retrievedObject = localStorage.getItem('UserDetails');
                var jsObj = JSON.parse(retrievedObject);
                this.massage = 'Payment done Successfully';
                jsObj.braintreeSubscriptionId = response.subscriptionId;
                jsObj.subscriptionPlanId = response.subscriptionPlanId;
                jsObj.subscriptionStatus = "True";
                localStorage.setItem('UserDetails', JSON.stringify(jsObj))
                localStorage.setItem('susbscid', response.subscriptionId);
                // localStorage.setItem('subscriptionPlanId', response.subscriptionId);
                this._toastr.success(this.massage);
                this._router.navigate([`/account/user-account`]);
            }
        },
            err => {
                this._toastr.error('Something  Went Wrong ! Please Try Again Later');
                // this.showHidePlan = true;
                // this.showPaymentSection = false;
            })
  }
}
