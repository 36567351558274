import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AlbumService } from 'src/app/Shared/album.service';
import { ArtistsService } from 'src/app/Shared/artists.service';
import { PaginationServiceForAlbum } from 'src/app/Shared/paginationserviceForAlbum.service';
import { LoadScriptService } from 'src/app/loadScript.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})


export class FeedbackComponent implements OnInit {
  feedback: any = [];
  fileType: any;

  constructor(private loadScript: LoadScriptService,
    private router: Router, private route: ActivatedRoute,
    public paginationService: PaginationServiceForAlbum, public translate: TranslateService,
    private _artistsService: ArtistsService,private spinner: NgxSpinnerService,
    private _toastr: ToastrService) { }

  ngOnInit() {
    this.GetAllArtistFeedback();
  }

  GetAllArtistFeedback(){
    this._artistsService.getallartistFeddback().subscribe((res: any) => {
      console.log(res);
      this.feedback = res.allfeedback.list;
      console.log(this.feedback[0].thumbImage);
      //this.howtolist = res.allhowto.list;
    // if (res.status == 200) {
    //   console.log(res);
    // }
  })
  }

  Downloadfile(imgtype : any) {
    debugger;
    console.log(imgtype);
    if(imgtype != null){
      let type = [];
      // type = this.albumd.path.concat(this.albumDetails.albumId, '/', this.albumd.albumDetails.thumbImage).split(/[\s.]+/);
      // this.fileType = type[type.length - 1];
      type = imgtype.thumbImage.split(/[\s.]+/);
        this.fileType = type[type.length - 1];
      const linkSource = `data:application/${this.fileType};base64,` + imgtype.thumbImageBytes;
      const downloadLink = document.createElement("a");
      const fileName ="Feedbackdownload" + "."  + this.fileType;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
}
getFileType(url: any): string {
  const parts = url.split('.');
  const extension = parts[parts.length - 1].toLowerCase();

  // Add logic to determine file type based on extension if needed
  // For simplicity, returning the file extension itself
  return extension;
}

}
