import { Component, OnInit, Input, ViewChild, DebugNode, ElementRef } from '@angular/core';
import { Users } from '../../models/users';
import { ChatService } from 'src/app/Shared/chat.service';
import { ChatModel } from '../../models/chat-model';
import { UserService } from 'src/app/Shared/user.service';
import { ArtistsService } from '../../Shared/artists.service';
import { ToastrService } from 'ngx-toastr';
import { Pagination } from 'src/app/models/pagination';
import { PaginationServiceForUserService } from 'src/app/Shared/pagination-service-for-user.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-admin-communication',
  templateUrl: './admin-communication.component.html',
  styleUrls: ['./admin-communication.component.css']
})
export class AdminCommunicationComponent implements OnInit {
  userDetails = new Users();
  message: ChatModel = new ChatModel();
  messages: ChatModel[] = [];
  userFullname:any;
  sbclick:any;
  subjecttitle:any;
  caseId: any = 0;
  caseIdclick: any = 0;
 adminNameclick: any = 0;
  AdminUserList:any;
  caseIddel:any;
  UserId:any;
  CaseSubject:any;
  CaseAdminid:any;
  communicationdata:any;
  Startchatflag: boolean = false;
  @ViewChild('myDiv') myDiv: ElementRef<HTMLElement>;
  @ViewChild('myDivchat') myDivchat: ElementRef<HTMLElement>;
  @ViewChild('myDivdelete') myDivdelete: ElementRef<HTMLElement>;
  constructor(private chatService: ChatService,private artistService: ArtistsService,private _toastr: ToastrService,
    private userService: UserService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    debugger;
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.userFullname= this.userDetails["fullName"];
    this.UserId = this.userDetails["userId"];
    this.chatService
    .getMessages()
    .subscribe((message: ChatModel) => {
      this.messages.push(message);
    });
    this.GetAllArtists();
    this.GetAllCases()
  }
  sendMessage() {
    if (this.message.text != "") {
      this.message.date = new Date;
      this.message.Message = this.message.text;
      this.message.Type = "Admin";
      this.message.Name = this.userDetails["fullName"];
      this.message.senderId = this.UserId;
      this.chatService.sendMessage(this.message);
      this.message = new ChatModel();
    }
  }
  GetAllArtists() {
    debugger;
    this.artistService.GetAllArtists().subscribe((data: any) => {
      console.log(data);
      this.AdminUserList=data.getAllAdminUsers;
    })
  }
  saveCase(){
    var DataCase = {
      "ArtistId": this.CaseAdminid,
      "Subject":this.CaseSubject,
      "AdminId":  this.UserId,
      "Iscompleted": false,
       "CreatedBy":  this.UserId,
    };
    if(this.CaseSubject== null || this.CaseSubject == ""){
      this._toastr.warning("Entre The Subject");
      return;
    }
    if(this.CaseAdminid== null || this.CaseAdminid == ""){
      this._toastr.warning("select The Artist");
      return;
    }
    this.artistService.SaveCases(DataCase).subscribe((res: any) => {
      console.log(res);
      this._toastr.success("Case Added successfully");
      this.triggerFalseClick();
      this.GetAllCases();
    });
  }
  GetAllCases() {
    this.artistService.GetAllCases(this.UserId).subscribe((data: any) => {
      console.log(data);
      this.communicationdata=data.getAllCases;
    })
  }
  triggerFalseClick() {
    let el: HTMLElement = this.myDiv.nativeElement;
    el.click();
    let eld: HTMLElement = this.myDivdelete.nativeElement;
    eld.click();
    let elc: HTMLElement = this.myDivchat.nativeElement;
    elc.click();
}
Chatstart(chatlist:any){
  if(chatlist!=1)
  {
    this.sbclick=chatlist.subject;
    this.caseIdclick=chatlist.caseId;
    this.adminNameclick=chatlist.artistName;
  }
  else{
    this.messages=[];
    this.subjecttitle=this.sbclick;
   this.caseId=this.caseIdclick;
   this.userFullname=this.adminNameclick
   this.Startchatflag=true;
   this.triggerFalseClick()
  }
 
}
Chatdelete(id){
  if(id!=0)
  {
    this.caseIddel=id
  }
  else{
    this.artistService.CaseDekete(this.caseIddel).subscribe((data: any) => {
    this.GetAllCases();
    this.triggerFalseClick();
    })
  }

}

}
