export class InviteUsers {
    public invitedId: string;
    public userId: number;
    public fullName: any;
    public email: any;
    public confirmEmail: any;
    public mobileNo: any;
    public userName: any;
    public password: any;
    public confirmPassword: any;
    public countryId: number;
    public country: any;
    public countryCode: any;
    public IsTCAccepted:any;
    public isforKid : boolean;
    public isMobileReq: boolean;
    public languageCode = "en";
    public userType: any;
    public deviceId: any;
    public deviceType: any;
    public subscriptionType: any;
    public subscriptionStatus: any;
    public lastLoggedIn: any;
    public isActive: boolean;
    public createdOn: any;
    public createdBy: number;
    public updatedOn: any;
    public updatedBy: number;
    public image: any;
    public dateOfBirth: any;
    public gender: any;
    public themeId: any;
    public city: any;
    public provider: any;
    public public: any;
    public provider_pic: any;
    public provider_id: any;
    public Token: any;
    public themeImage: any;
    public braintreeId;
    public brainTreePlanId;
    public subscriptionPlans;
    public brainTreeCustomerId;
    public subscriptionId;
    public braintreeSubscriptionId;
    public braintreeCustomerId;
    public ActivationDays;
    public artistId;
    public sessionId: any;
    public return: any;
    public subscriptionPlanId: any;
}