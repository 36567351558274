import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadScriptService } from 'src/app/loadScript.service';
import { ArtistsService } from 'src/app/Shared/artists.service';
import { ToastrService } from 'ngx-toastr';
import { Pagination } from 'src/app/models/pagination';
import { Approvalstatus } from '../../models/approvalstatus';
import { PaginationService } from '../../Shared/pagination.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from "ngx-spinner";
import { Artist } from 'src/app/models/artist';

@Component({
  selector: 'app-artist',
  templateUrl: './artist.component.html',
  styleUrls: ['./artist.component.css']
})
export class ArtistComponent implements OnInit {
  imageZoom = [];

  pageSize: any = 50;
  artistList: any;
  path: any;
  searchForartist;
  columname: any = "createdOn";
  sortedorder: any = "desc";
  searchname: any;
  artist = new Artist;
  pageField = [];
  Checkrecords: boolean = false;

  prev: boolean = false;
  next: boolean = false;
  activePage: boolean[] = [];
  searchHistory: boolean = false;
  //pageNumber: boolean[] = [];
  totalPage: number;
  tempPageIndex: number;
  first: number = Pagination.first;
  second: number = Pagination.second;
  third: number = Pagination.third;
  fourth: number = Pagination.fourth;
  fifth: number = Pagination.fifth;
  firstrow: boolean = false;
  secondrow: boolean = false;
  thirdrow: boolean = false;
  fourthrow: boolean = false;
  fifthrow: boolean = true;
  artistperpage: number;
  tempArray = [];
  orderByVar: boolean = false;
  tempData: number = 1;
  tempArrayPath: any;
  tempDataArray: any;

  approvalstatus_submit: any;
  approvalstatus_rejected: any;
  approvalstatus_resubmit: any;
  approvalstatus_approved: any;

  //Pagination Variables
  pageNumberPerPage = 0;
  pageNumberShow = Pagination.displayNoOfPagesPerPage;
  temppage: number = 0;
  disabledNextBtn: boolean;
  disabledPrevBtn: boolean = true;
  exactPageList: any;
  prevtrue: boolean;
  nexttrue: boolean;
  //currentPage = 1;
  showNoOfCurrentPage: any = 1;
  showPageOnlyOntabsChange: boolean = true;
  paginationData: number;
  pageIndex: any = 1;
  noDataFound: boolean = false;
  nextpage: boolean = true;
  totalcount;
  data: any;
  totalcounts: [''];
  books: any;
  store: any;
  yourArray: any;
  termscont: any;
  accept: any;
  tcaccept: any;
  isTCAccepted: any = false;
  checks: boolean;
  mailid: any;
  email: any;
  isSelected: any[];
  isChecked: boolean[] = [];
  AttPrdFinals: any = [];
  artistIds: string[];
  chkdata: any[];

  constructor(
    private loadScript: LoadScriptService,
    private router: Router,
    private artistService: ArtistsService,
    private _toastr: ToastrService,
    public paginationService: PaginationService,
    public translate: TranslateService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    //Service to load js file runtime
    this.approvalstatus_submit = Approvalstatus.SUBMIT;
    this.approvalstatus_rejected = Approvalstatus.REJECTED;
    this.approvalstatus_resubmit = Approvalstatus.RESUBMIT;
    this.approvalstatus_approved = Approvalstatus.APPROVED;
    this.loadScript.getServiceBaseUrl();
    this.paginationService.temppage = this.paginationService.temppage;
    this.paginationService.showPageOnlyOntabsChange = true;
    if (this.paginationService.currentpageee != null || this.paginationService.currentpageee != undefined) {
      this.searchname = this.artistService.searchText;
      this.paginationService.currentPage = this.paginationService.currentpageee
      this.activePage[this.paginationService.indexxx] = true;
    }
    else {
      this.searchname = this.artistService.searchText;
      this.paginationService.currentPage = this.paginationService.currentPage;
      this.activePage[0] = true;
    }
    if (this.searchname !== "") {
      this.paginationService.temppage = 0
      this.paginationService.currentPage = 1;
    }
    //this.paginationService.showNoOfCurrentPage = this.paginationService.showNoOfCurrentPage;
    this.pageIndex = this.paginationService.currentPage;
    this.searchname = this.artistService.searchText;

    // this.pageField = [];

    this.getAllArtist();
    //var lis = document.getElementById("p").getElementsByTagName("li");
    // lis[0].style.color="orange";

    this.artistIds = new Array<string>();
  }

  searchArtist($event, i) {
    this.Checkrecords = false;
    this.artistService.searchText = $event
    if ($event == "") {

      this.pageField = [];
      this.pageIndex = 1;
      this.tempPageIndex = 1;
      this.prev = false;
      this.next = false;
      this.searchHistory = false;
      this.artistList = [];
      this.searchname = "";

      this.artistList = this.tempArray;
      this.path = this.tempArrayPath;
      this.getPageNumber(this.tempDataArray);
      this.getRows(this.fifth);
    }

    if ($event !== '') {
      this.searchname = $event;
      this.pageField = [];
      // this.npPageIndex=true;
      this.pageIndex = 1;
      this.paginationService.temppage = 0;
      this.getAllArtist();

      for (var x = 0; x < this.totalPage + 1; x++) {
        this.activePage[x] = false;
      }
      if (i == 0) {
        this.activePage[0] = true;
      } else {
        this.activePage[i] = true;
      }
    }
  }


  getAllArtist() {
    
    this.spinner.show();
    this.artistService.GetAllArtist(this.columname, this.sortedorder, this.searchname, this.pageIndex, this.pageSize, this.isTCAccepted).subscribe((data: any) => {

      this.totalcount = data.count;
      if (this.tempData == 1) {
        this.tempDataArray = data;
        this.tempArray = data.artistDetails;
        this.tempArrayPath = data.path;
        // this.paginationService.temppage = 0;
      }
      //this.paginationService.temppage = 0;
      this.artistList = data.artistDetails;
      if (this.artistIds.length > 0) {
        this.artistList.forEach(element => {
          var exist = this.artistIds.filter(f => f == element.artistId)
          if (exist.length > 0) {
            element.isSelected = true;
          }

        });
      }

      //console.log(data.artistDetails);
      this.path = data.path;
      this.getPageNumber(data);
      this.spinner.hide();
    });
  }
  getPageNumber(data) {

    if (data.artistDetails.length == 0) {
      this.pageField = [];
      this.noDataFound = true;
      this.Checkrecords = true;
    } else {
      this.noDataFound = false;
      this.Checkrecords = false;
      this.paginationData = Number(data.count / this.pageSize);
      let tempPageData = this.paginationData.toFixed();
      if (Number(tempPageData) < this.paginationData) {
        this.exactPageList = Number(tempPageData) + 1;
        this.paginationService.exactPageList = this.exactPageList;
      } else {
        this.exactPageList = Number(tempPageData);
        this.paginationService.exactPageList = this.exactPageList
      }
      this.paginationService.pageOnLoad();
      this.pageField = this.paginationService.pageField;
    }

  }

  getRows(page) {
    var k;
    this.activePage = [];
    var lis = document.getElementById("p").getElementsByTagName("li");
    for (k = 0; k < lis.length; k++) {
      lis[k].removeAttribute("style");
    }
    if (page == Pagination.first) {
      this.firstrow = true;
      this.secondrow = false;
      this.thirdrow = false;
      this.fourthrow = false;
      this.fifthrow = false;
      this.pageIndex = 1;
      this.paginationService.temppage = 0;
      this.pageSize = Pagination.first;
      this.activePage[0] = true;
      this.getAllArtist();

    } else if (page == Pagination.second) {
      this.firstrow = false;
      this.secondrow = true;
      this.thirdrow = false;
      this.fourthrow = false;
      this.fifthrow = false;
      this.pageIndex = 1;
      this.paginationService.temppage = 0;
      this.pageSize = Pagination.second;
      this.activePage[0] = true;
      this.getAllArtist();

    }
    else if (page == Pagination.third) {
      this.firstrow = false;
      this.secondrow = false;
      this.thirdrow = true;
      this.fourthrow = false;
      this.fifthrow = false;
      this.pageIndex = 1;
      this.paginationService.temppage = 0;
      this.pageSize = Pagination.third;
      this.activePage[0] = true;
      this.getAllArtist();

    }
    else if (page == Pagination.fourth) {
      this.firstrow = false;
      this.secondrow = false;
      this.thirdrow = false;
      this.fourthrow = true;
      this.fifthrow = false;
      this.pageIndex = 1;
      this.paginationService.temppage = 0;
      this.pageSize = Pagination.fourth;
      this.activePage[0] = true;
      this.getAllArtist();

    }
    else if (page == Pagination.fifth) {
      this.firstrow = false;
      this.secondrow = false;
      this.thirdrow = false;
      this.fourthrow = false;
      this.fifthrow = true;
      this.pageIndex = 1;
      this.paginationService.temppage = 0;
      this.pageSize = Pagination.fifth;
      this.activePage[0] = true;
      this.getAllArtist();

    }
  }
  showNextArtist(page, i) {
    this.pageIndex = page;
    this.paginationService.currentpageee = this.pageIndex
    this.paginationService.indexxx = i;
    this.activePage = [];
    this.activePage[i] = true;
    this.getAllArtist();

  }


  nextPage() {
    if (this.paginationService.disabledNextBtn == false) {
      this.nextpage = false;
      this.activePage = [];
      this.activePage[0] = true;
      this.paginationService.nextPage();
      this.pageIndex = this.paginationService.pageField[0];
      //this.pageField=this.paginationService.pageField;
      this.getAllArtist();

    }

  }

  previous() {
    if (this.paginationService.disabledPrevBtn == false) {
      this.activePage = [];
      this.activePage[0] = true;
      this.paginationService.previous();
      this.pageIndex = this.paginationService.pageField[0];
      this.getAllArtist();
    }


  }

  orderBy(coloumnName) {
    var i = 0;
    var t = document.getElementsByTagName("th");
    for (i = 0; i < t.length; i++) {
      t[i].removeAttribute("style");
    }
    // ="#bec1c3";
    let r = document.getElementById(coloumnName);
    r.style.color = "orange";

    this.columname = coloumnName;
    if (this.sortedorder == "asc") {
      this.sortedorder = "desc";

    } else {
      this.sortedorder = "asc";
    }

    this.orderByVar = true;
    this.getAllArtist();
  }

  addArtist() {
    this.router.navigate(['/admin/artist/add-artist']);
  }

  public editArtistProfile(artistId) {
    this.artistService.searchText = this.searchname;

    this.router.navigate([`/admin/artist/update-artist/` + artistId + ``]);
  }

  deleteArtist(artistId) {

    if (confirm('Please note! This action can NOT be undone. Are you sure you want to delete?')) {

      this.artistService.deleteArtist(artistId).subscribe((response) => {

        if (response.isSuccess) {
          this._toastr.success("Artist has been deleted successfully");
          this.getAllArtist();
        }
      }, err => { this._toastr.error(err) });
    }
  }
  picbig(i) {
    this.imageZoom[i] = false;
  }
  pic(i) {
    this.imageZoom[i] = true;
  }
  getallalbums(artistId) {
    this.router.navigate([`/admin/album/` + artistId + ``]);
  }

  accepted(e) {
    //console.log(e.target.value);
    this.getAllArtist();
  }

  sendmail() {
    // var art = this.checkbox
    // var lng = this.checkbox.length
    console.log('mail' , this.artistIds)
    let artists = this.artistList.filter(f => this.artistIds.includes(f.artistId));
    console.log(artists);
    if (artists.length > 0) {
      this.artistService.SendEmailArtist(artists).subscribe((data) => {
        this.data = data;
        console.log('chkdetails', data);
        this._toastr.success("Mail Sent Successfully")
      });
    }
    else {
      this._toastr.error("Please Select the Check Box")
    }
  }

  checkEmp(e: any, item: any) {

    // item.isSelected = e.target.checked;
    // console.log('chk', item.isSelected)
    //let artists = this.artistList.filter(f => f.isSelected == true);
    //console.log('...' , artistsids)
    
    if (e.target.checked == true) {
      this.artistIds.push(item);
    }
    else {
      this.artistIds = this.artistIds.filter(x => x != item);
    }
    console.log('...', this.artistIds)    
  }

}
