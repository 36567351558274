import { ElementRef, EventEmitter, Injectable, ViewChild } from '@angular/core';
import { BehaviorSubject, Subscription, Subject, Observable } from 'rxjs';
import { Track } from '../../model/track.model';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from 'src/app/app.config';
import { BaseAudioPlayerFunctions } from '../../component/base/base-audio-player-components';
import { SongsService } from 'src/app/Shared/songs.service';
@Injectable()
export class AudioPlayerService {
  private url = '';
  private baseUrl = '';
  repeatSong: boolean = false;
  singlePlayList = [];
  playlist = [];
  playlistForPlayer = new Subject<any>();
  temp = [];
  isPlay: boolean = false;
  playPauseBtn: boolean[] = [];
  playPauseImage = [];
  playBtn: boolean[] = [];
  currentId;
  songPlayPauseIndex;
  indexSong: number = 0;
  currentTrack: BehaviorSubject<{}> = new BehaviorSubject(
    this.playlist[this.indexSong]);

  currentTime: number = 0;
  duration: number = 0;
  currentsongid: any;
  getcurrentsongid: any;
  disablePrevAndNextBtn: boolean = false;
  shufflePlayList: boolean;
  shuffleSongs = [];
  repeatIndex: number;
  userDetails: any;
  userId: number = 0;
  IsPlaying: boolean;
  isTextcolor: any;
  tempIndex: number = 0;
  private Iscolorchange = new Subject<number>();
  loaderDisplay: any;
  private IsSongPlay = new Subject<boolean>();
  private dataSource = new BehaviorSubject<any>(true); // Replace `any` with your data type
  currentData = this.dataSource.asObservable();
  private playSongSubject = new Subject<any>();
  playSong$ = this.playSongSubject.asObservable();
  playSongEvent = new EventEmitter<any>();
  issongplay: boolean = true;
  userPlaylistId: number;
  isUserPlaylist: boolean;
  // playEventEmitter = new EventEmitter<string>();
  //private Isplay = new Subject<boolean>();
  constructor(private http: HttpClient, private configService: ConfigService, private _songservice : SongsService) {
    this.baseUrl = configService.getServiceBaseUrl();
    // this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    // if (this.userDetails != null) {
    //   this.userId = this.userDetails.userId;
    // }

    this.IsPlaying = JSON.parse(localStorage.getItem('IsPlaying'));

  };

  init(): void {
    this.updateCurrentSong();
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    if (this.userDetails != null) {
      this.userId = this.userDetails.userId;
    }
  };



  nextSong(): void {

    if ((this.indexSong + 1) >= this.playlist.length) {
      this.indexSong = 0;

    } else {
      this.indexSong++;
    }
    console.log(this.indexSong)
    localStorage.setItem('currentSongIndex', this.indexSong.toString());
    this.updateCurrentSong();
  };

  previousSong(): void {
    if ((this.indexSong - 1) < 0) {
      this.indexSong = (this.playlist.length - 1);
    } else {
      this.indexSong--;
    }
    this.updateCurrentSong();
  };

  resetPlaylist(): void {
    this.indexSong = 0;
    this.updateCurrentSong();
  };

  selectATrack(index: number): void {
    this.indexSong = index - 1;
    //this.indexSong = index;
    this.updateCurrentSong();
    //   // document.getElementById("audioPlayer").pause(this.currentTime)
    //   //$('audio#audioPlayer')[0].play(this.currentTime);
    //   if(localStorage.getItem('IsSongPlaying')=='false'){
    //   // document.getElementById("audioPlayer").play(this.currentTime)
    //   (document.getElementById("audioPlayer") as HTMLMediaElement).play();
    // }
    // if(localStorage.getItem('IsSongPlaying')=='true') {
    //   // document.getElementById("audioPlayer").pause(this.currentTime)
    //   (document.getElementById("audioPlayer") as HTMLMediaElement).pause();
    // }
  };

  updateCurrentSong(): void {
    debugger;
    //Check if song is in repeat
    if (this.indexSong == -1) {
      this.indexSong = 0;
    }
    if (this.playlist != undefined) {
      if (this.playlist.length == 1) {
        this.shufflePlayList = false;
        this.disablePrevAndNextBtn = true;
        this.indexSong = 0;
        this.playSingle();
        this.currentsongid = this.playlist[this.indexSong];
        this.getcurrentsongid = this.currentsongid.songId;
        this.IsblogorCountryScreen(this.getcurrentsongid);
      } else if (this.repeatSong == true) {
        this.disablePrevAndNextBtn = true;
        this.indexSong = this.repeatIndex;
        this.playnext();
      } else if (this.playlist.length != 0) {
        this.disablePrevAndNextBtn = false;
        let current = this.playlist[this.indexSong];
        this.currentsongid = this.playlist[this.indexSong];
        this.isTextcolor = this.indexSong;
        this.getcurrentsongid = this.currentsongid.songId;
        this.IsblogorCountryScreen(this.getcurrentsongid);
        if (this.getcurrentsongid) {
          if (this.userDetails.subscriptionStatus == "True") {
            this._songservice.AddLastPlayedSong(this.getcurrentsongid , this.userId).subscribe((res : any) =>{
              // console.log(res);
            });
          }
          
          //this.updateData(this.currentData);
        }
        //alert(this.getcurrentsongid);
        // if (JSON.parse(localStorage.getItem('IsSongPlaying')) == true) {
        //   if(this.currentTime==60){
        //   this.updatecount(current.songId);
        //   }
        // }
        this.currentId = this.currentsongid.songId;
        let previous = ((this.indexSong - 1) >= 0) ? this.playlist[this.indexSong - 1] : this.playlist[this.playlist.length - 1];
        let next = ((this.indexSong + 1) >= this.playlist.length) ? this.playlist[0] : this.playlist[this.indexSong + 1];
        localStorage.setItem('CurrentSongId', this.currentId);
        this.currentTrack.next([
          previous,
          current,
          next
        ]);
        //this.playSongCallback(true);
      }
    }
  };

  updateData(data: any) {
    this.dataSource.next(data);
  }

  IsblogorCountryScreen(message: number) {
    this.Iscolorchange.next(message);
  }

  getIsblogorCountryScreen(): Observable<any> {
    return this.Iscolorchange.asObservable();
  }

  updatecount(SongId) {
    debugger;
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
      // if (this.readyState == 1 && this.status == 200) {
      // }
    };
    xhttp.open("Post", this.baseUrl + "api/Songs/UpdatePlayCount?SongId=" + SongId + '&UserId=' + this.userId, true);
    xhttp.send(SongId);

  }

  toggleplaypause() {
    if (localStorage.getItem('IsSongPlaying') === 'true') {
      this.issongplay = true;
    } else {
      this.issongplay = false;
    }
  }

  playnext() {
    let current = this.playlist[this.indexSong];
    let previous = this.playlist[this.indexSong];
    let next = this.playlist[this.indexSong];

    this.currentTrack.next([
      previous,
      current,
      next
    ]);
  }
  playSingle() {
    let current = this.playlist[0];
    let previous = this.playlist[0];
    let next = this.playlist[0];

    this.currentTrack.next([
      previous,
      current,
      next
    ]);
  }
  getSubjectCurrentTrack(): BehaviorSubject<{}> {
    return this.currentTrack;
  };

  getPlaylist() {
    return this.playlist;
  };

  setPlaylist(playlist) {
    if (JSON.stringify(playlist) !== JSON.stringify(this.shuffleSongs)) {
      this.shufflePlayList = false;
    }
    if (playlist != undefined) {
      this.playlist = playlist;
    }

  }
  // getPlaylist(): Track[] {
  //   return this.playlist;
  // };

  // setPlaylist(playlist: Track[]) {
  //   this.playlist = playlist;
  // }

  getIndexSong() {
    return this.indexSong;
  }

  //Play Single Song
  playSingleSong() {

    this.repeatSong = !this.repeatSong;
    if (this.repeatSong == true) {
      this.disablePrevAndNextBtn = true;
      this.temp = this.playlist;
      this.repeatIndex = this.indexSong;
      // this.singlePlayList[0] = this.playlist[this.indexSong];
      // this.playlist = this.singlePlayList;
      this.playnext();

    } else {
      this.disablePrevAndNextBtn = false;
      this.playlist = this.temp;
      this.updateCurrentSong();
    }

  }


  // playSong(song: any) {
  //   this.playSongEvent.emit(song);
  // }

  playSongCallback: (song: any) => void; // Define a callback function

  registerPlaySongCallback(callback: (song: any) => void) {
    this.playSongCallback = callback;
  }

  playSong(song: any) {
    if (this.playSongCallback) {
      this.playSongCallback(song);
    }
  }

  playSongCallbackno: (songno: any) => void; //from footer to all component

  registerPlaySongCallbackno(callback: (songno: any) => void) {
    this.playSongCallbackno = callback;
  }

  playSongno(songno: any) {
    if (this.playSongCallbackno) {
      this.playSongCallbackno(songno);
    }
  }

  playSongCallbackmain: (song: any) => void; // Define a callback function

  registerPlaySongCallbackmain(callback: (song: any) => void) {
    this.playSongCallbackmain = callback;
  }

  playSongmain(song: any) {
    if (this.playSongCallbackmain) {
      this.playSongCallbackmain(song);
    }
  }

  updatePlaylistplaycount(playlistId, songId, userId, IsUserPlaylist) {
    debugger;
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
      // if (this.readyState == 1 && this.status == 200) {
      // }
    };
    xhttp.open("Post", this.baseUrl + "api/PlayLists/UpdatePlaylistPlayCount?PlaylistId=" + playlistId + '&SongId=' + songId + '&UserId=' + userId + '&IsUserPlaylist=' + IsUserPlaylist);
    xhttp.send(playlistId);

  }

  playlistCallbackmain: (playlistId: any, isplaylist: boolean) => void; // Define a callback function

  registerPlaylistCallbackmain(callback: (playlistId: any, isplaylist: boolean) => void) {
    this.playlistCallbackmain = callback;
  }

  playlistmain(playlistId: any, isplaylist: boolean) {
    if (this.playlistCallbackmain) {
      this.playlistCallbackmain(playlistId, isplaylist);
    }
  }

}
