import { Injectable } from '@angular/core';
import { Pagination } from '../models/pagination';

@Injectable()

export class PaginationService {
  //Pagination Variables
  pageNumberPerPage = 0;
  pageNumberShow = Pagination.displayNoOfPagesPerPage;
  temppage: number = 0;
  disabledNextBtn: boolean;
  disabledPrevBtn: boolean = true;
  pageField = [];
  exactPageList: any;
  prevtrue: boolean;
  nexttrue: boolean;
  currentPage = 1;
  pageNumber: boolean[] = [];
  showNoOfCurrentPage: any = 1;
  showPageOnlyOntabsChange: boolean = true;
  lastPage: any = 0;
  currentpageee: any;
  indexxx: any;
  //Pagination Variables for songs
  pageNumberShowSong = Pagination.displayNoOfPagesPerPage;
  temppageSong: number = 0;
  disabledNextBtnSong: boolean;
  disabledPrevBtnSong: boolean = true;
  pageFieldSong = [];
  exactPageListSong: any;
  prevtrueSong: boolean;
  nexttrueSong: boolean;
  currentPageSong = 1;
  pageNumberSong: boolean[] = [];
  showNoOfCurrentPageSong: any = 1;
  showPageOnlyOntabsChangeSong: boolean = true;
  lastPageSong: any = 0;
  //Pagination Variables for likes album and songs
  pageNumberShowLAS = Pagination.displayNoOfPagesPerPage;
  temppageLAS: number = 0;
  disabledNextBtnLAS: boolean;
  disabledPrevBtnLAS: boolean = true;
  pageFieldLAS = [];
  exactPageListLAS: any;
  prevtrueLAS: boolean;
  nexttrueLAS: boolean;
  currentPageLAS = 1;
  pageNumberLAS: boolean[] = [];
  showNoOfCurrentPageLAS: any = 1;
  showPageOnlyOntabsChangeLAS: boolean = true;
  lastPageLAS: any = 0;
  constructor() {
  }

  // On page load 
  pageOnLoad() {
    if (this.temppage == 0 && this.showPageOnlyOntabsChange) {
      this.showPageOnlyOntabsChange = true;
      this.pageField = [];
      for (var a = 0; a < this.pageNumberShow; a++) {
        this.pageField[a] = this.temppage + 1;
        this.temppage = this.temppage + 1;
        if (this.exactPageList == this.pageField[a]) {
          for (var b = 0; b < this.pageNumberShow - 7; b++) {
            if (a == b) {
              this.temppage = this.temppage - (b + 1);
              this.prevtrue = false;
              break;
            }
          }
          this.disabledNextBtn = true;
          break;
        } else {
          this.disabledNextBtn = false;
        }
      }
    }
  }  

  previous() {
    if (this.exactPageList > 10) {
      this.disabledNextBtn = false;
    }
    this.pageNumber[0] = true;
    this.nexttrue = true;
    if (this.showNoOfCurrentPage != 1) {
      this.disabledNextBtn = false;
      this.showNoOfCurrentPage = this.showNoOfCurrentPage - 1;
      if (this.prevtrue) {
        if (this.lastPage == 0) {
          this.temppage = this.temppage - 10;
          this.prevtrue = false;
        } else {
          this.temppage = this.lastPage;
          this.prevtrue = false;
          this.lastPage = 0;
        }
      }
      for (var a = this.pageNumberShow - 1; a >= 0; a--) {
        this.pageField[a] = this.temppage;
        this.temppage = this.temppage - 1;
      }
      if (this.temppage == 0) {
        this.showPageOnlyOntabsChange = false;
      }
      this.currentPage = this.pageField[0];
    }
  }

  nextPage() {
    if (this.exactPageList > 10) {
      this.disabledNextBtn = false;
    }
    if (this.disabledNextBtn == false) {
      this.disabledPrevBtn = false;
      this.pageField = [];
      this.prevtrue = true;
      this.showNoOfCurrentPage = this.showNoOfCurrentPage + 1;
      this.pageNumber[0] = true;
      if (this.nexttrue) {
        this.temppage = this.temppage + 10;
        this.nexttrue = false;
      }
      for (var a = 0; a < this.pageNumberShow; a++) {
        this.pageField[a] = this.temppage + 1;
        this.temppage = this.temppage + 1;
        if (this.exactPageList == this.pageField[a]) {
          this.lastPage = this.pageField[a];
          this.lastPage = this.lastPage - (a + 1);
          for (var b = 0; b < this.pageNumberShow - 7; b++) {
            if (a == b) {
              this.temppage = this.temppage - (b + 1);

              this.prevtrue = false;
              break;
            }
          }
          this.disabledNextBtn = true;
          break;
        } else {
          this.disabledNextBtn = false;
        }
      }
      this.currentPage = this.pageField[0];
    }
  }

  // On page load for song
  pageOnLoadSong() {
    if (this.temppageSong == 0 && this.showPageOnlyOntabsChangeSong) {
      this.showPageOnlyOntabsChangeSong = true;
      this.pageFieldSong = [];
      for (var a = 0; a < this.pageNumberShowSong; a++) {
        this.pageFieldSong[a] = this.temppageSong + 1;
        this.temppageSong = this.temppageSong + 1;
        if (this.exactPageListSong == this.pageFieldSong[a]) {
          for (var b = 0; b < this.pageNumberShowSong - 7; b++) {
            if (a == b) {
              this.temppageSong = this.temppageSong - (b + 1);
              this.prevtrueSong = false;
              break;
            }
          }
          this.disabledNextBtnSong = true;
          break;
        } else {
          this.disabledNextBtnSong = false;
        }
      }
    }
  }

  previousPageSong() {
    if (this.exactPageListSong > 10) {
      this.disabledNextBtnSong = false;
    }
    this.pageNumberSong[0] = true;
    this.nexttrueSong = true;
    if (this.showNoOfCurrentPageSong != 1) {
      this.disabledNextBtnSong = false;
      this.showNoOfCurrentPageSong = this.showNoOfCurrentPageSong - 1;
      if (this.prevtrueSong) {
        if (this.lastPageSong == 0) {
          this.temppageSong = this.temppageSong - 10;
          this.prevtrueSong = false;
        } else {
          this.temppageSong = this.lastPageSong;
          this.prevtrueSong = false;
          this.lastPageSong = 0;
        }
      }
      for (var a = this.pageNumberShowSong - 1; a >= 0; a--) {
        this.pageFieldSong[a] = this.temppageSong;
        this.temppageSong = this.temppageSong - 1;
      }
      if (this.temppageSong == 0) {
        this.showPageOnlyOntabsChangeSong = false;
      }
      this.currentPageSong = this.pageFieldSong[0];
    }
  }

  nextPageSong() {
    if (this.exactPageListSong > 10) {
      this.disabledNextBtnSong = false;
    }
    if (this.disabledNextBtnSong == false) {
      this.disabledPrevBtnSong = false;
      this.pageFieldSong = [];
      this.prevtrueSong = true;
      this.showNoOfCurrentPageSong = this.showNoOfCurrentPageSong + 1;
      this.pageNumberSong[0] = true;
      if (this.nexttrueSong) {
        this.temppageSong = this.temppageSong + 10;
        this.nexttrueSong = false;
      }
      for (var a = 0; a < this.pageNumberShowSong; a++) {
        this.pageFieldSong[a] = this.temppageSong + 1;
        this.temppageSong = this.temppageSong + 1;
        if (this.exactPageListSong == this.pageFieldSong[a]) {
          this.lastPageSong = this.pageFieldSong[a];
          this.lastPageSong = this.lastPageSong - (a + 1);
          for (var b = 0; b < this.pageNumberShowSong - 7; b++) {
            if (a == b) {
              this.temppageSong = this.temppageSong - (b + 1);
              this.prevtrueSong = false;
              break;
            }
          }
          this.disabledNextBtnSong = true;
          break;
        } else {
          this.disabledNextBtnSong = false;
        }
      }
      this.currentPageSong = this.pageFieldSong[0];
    }
  }
  // On page load for most like album and song
  pageOnLoadLAS() {
    if (this.temppageLAS == 0 && this.showPageOnlyOntabsChangeLAS) {
      this.showPageOnlyOntabsChangeLAS = true;
      this.pageFieldLAS = [];
      for (var a = 0; a < this.pageNumberShowLAS; a++) {
        this.pageFieldLAS[a] = this.temppageLAS + 1;
        this.temppageLAS = this.temppageLAS + 1;
        if (this.exactPageListLAS == this.pageFieldLAS[a]) {
          for (var b = 0; b < this.pageNumberShowLAS - 7; b++) {
            if (a == b) {
              this.temppageLAS = this.temppageLAS - (b + 1);
              this.prevtrueLAS = false;
              break;
            }
          }
          this.disabledNextBtnLAS = true;
          break;
        } else {
          this.disabledNextBtnLAS = false;
        }
      }
    }
  }

  previousPageLAS() {
    if (this.exactPageListLAS > 10) {
      this.disabledNextBtnLAS = false;
    }
    this.pageNumberLAS[0] = true;
    this.nexttrueLAS = true;
    if (this.showNoOfCurrentPageLAS != 1) {
      this.disabledNextBtnLAS = false;
      this.showNoOfCurrentPageLAS = this.showNoOfCurrentPageLAS - 1;
      if (this.prevtrueLAS) {
        if (this.lastPageLAS == 0) {
          this.temppageLAS = this.temppageLAS - 10;
          this.prevtrueLAS = false;
        } else {
          this.temppageLAS = this.lastPageLAS;
          this.prevtrueLAS = false;
          this.lastPageLAS = 0;
        }
      }
      for (var a = this.pageNumberShowLAS - 1; a >= 0; a--) {
        this.pageFieldLAS[a] = this.temppageLAS;
        this.temppageLAS = this.temppageLAS - 1;
      }
      if (this.temppageLAS == 0) {
        this.showPageOnlyOntabsChangeLAS = false;
      }
      this.currentPageLAS = this.pageFieldLAS[0];
    }
  }

  nextPageLAS() {
    if (this.exactPageListLAS > 10) {
      this.disabledNextBtnLAS = false;
    }
    if (this.disabledNextBtnLAS == false) {
      this.disabledPrevBtnLAS = false;
      this.pageFieldLAS = [];
      this.prevtrueLAS = true;
      this.showNoOfCurrentPageLAS = this.showNoOfCurrentPageLAS + 1;
      this.pageNumberLAS[0] = true;
      if (this.nexttrueLAS) {
        this.temppageLAS = this.temppageLAS + 10;
        this.nexttrueLAS = false;
      }
      for (var a = 0; a < this.pageNumberShowLAS; a++) {
        this.pageFieldLAS[a] = this.temppageLAS + 1;
        this.temppageLAS = this.temppageLAS + 1;
        if (this.exactPageListLAS == this.pageFieldLAS[a]) {
          this.lastPageLAS = this.pageFieldLAS[a];
          this.lastPageLAS = this.lastPageLAS - (a + 1);
          for (var b = 0; b < this.pageNumberShowLAS - 7; b++) {
            if (a == b) {
              this.temppageLAS = this.temppageLAS - (b + 1);
              this.prevtrueLAS = false;
              break;
            }
          }
          this.disabledNextBtnLAS = true;
          break;
        } else {
          this.disabledNextBtnLAS = false;
        }
      }
      this.currentPageLAS = this.pageFieldLAS[0];
    }
  }

}