import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HomeService } from 'src/app/Shared/home.service';
import { Role } from 'src/app/models/role';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/Shared/user.service';
import { LoginService } from 'src/app/Shared/login.service';
import { PlayerService } from 'src/app/Shared/player.service';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  Role: string;
  //Checks wheather browser tab is closed
  @HostListener('window:unload', ['$event'])
  unloadHandler(event) {
    // ...Calling Logout Audit API
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    let UserId = this.userDetails.userId;
    let SessionId = this.userDetails.sessionId;
    this._loginService.logOutAudit(UserId, SessionId).subscribe((res) => {
      localStorage.clear();
    })
  }

  userDetails: any;
  role: any;
  superAdminLoggedIn: boolean;
  userLoggedIn: boolean;
  locale: string;
  type: any;
  themeId: any;
  imglogo: boolean;
  languageCode: any;
  theme: any
  artistLoggeIn = true;
  constructor(private _router: Router, private playerService: PlayerService, private audioPlayerService: AudioPlayerService,
    private _loginService: LoginService,
    public translate: TranslateService, public userService: UserService) {
  }

  ngOnInit() {
    // debugger;
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.translate.use(this.userDetails.languageCode);
    this.role = this.userDetails.userType;
    if (this.role == Role.superAdmin) {
      this.superAdminLoggedIn = true;
      this.artistLoggeIn = false;
    }
    else if (this.role == Role.user) {
      this.userLoggedIn = true;
      this.artistLoggeIn = true;
    }
    else if (this.Role == 'artist') {
      this.artistLoggeIn = true;
      this.superAdminLoggedIn = false;
      this.userLoggedIn = false;
    }
    this.Changetheme();
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.languageCode = this.userDetails.languageCode;
    this.theme = this.userDetails.themeId;

    // this.superAdminLoggedIn = false;
    // this.userLoggedIn = false;
  }
  changeLang(language: string) {
    this.locale = language;
    localStorage.setItem('locale', language);
    this.translate.use(language);
  }
  logOut() {
    let UserId = this.userDetails.userId;
    let SessionId = this.userDetails.sessionId;
    this._loginService.logOutAudit(UserId, SessionId).subscribe((res: any) => {
    })
    if (this.superAdminLoggedIn) {
      localStorage.clear();

      this._router.navigate(['/admin/admin-login']);
    } else if (this.userLoggedIn) {
      localStorage.clear();
      localStorage.setItem('themeid', this.userDetails.themeId);
      localStorage.setItem('languagecode', this.userDetails.languageCode);
      this.translate.use("en");
      localStorage.clear();
      this._router.navigate(['/login']);
      localStorage.removeItem("UserDetails");
    }
    else {
      localStorage.clear();
      localStorage.setItem('languagecode', this.userDetails.languageCode);
      this.translate.use(this.userDetails.languageCode);
      this.playerService.msaapPlaylist = [];
      // this.audioPlayerService.currentTrack.value = [];
      this.audioPlayerService.currentsongid = [];
      this.audioPlayerService.playlist = [];
      this._router.navigate(['/login/artist-joinus']);
    }

  }
  IsCheckLang() {
    if (this.translate.currentLang == 'ar') {
      return true;

    } else if (this.translate.currentLang == 'as') {
      return true;
    }
    else {
      return false;
    }
  }

  homelogo() {
    this.type = this._router.routerState.snapshot.url;
    if (this.type != '/home') {
      this._router.navigate(['/home']);
    }
  }
  Changetheme() {
    if (this.userDetails.themeId == '2') {
      this.userService.theme1 = false;

      this.imglogo = false;
    }
    else {
      this.userService.theme1 = true;
      this.imglogo = true;
    }
  }


}
