import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LoadScriptService } from 'src/app/loadScript.service';
import { HomeService } from "../../../Shared/home.service";
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { PlayerService } from 'src/app/Shared/player.service';
import { UserService } from 'src/app/Shared/user.service';
@Component({
  selector: 'app-admin-pl-details',
  templateUrl: './admin-pl-details.component.html',
  styleUrls: ['./admin-pl-details.component.css']
})
export class AdminPlDetailsComponent implements OnInit {
  userDetails: any;
  substatus: any;
  userId: any;
  allsongs: any;
  TransList: Array<any> = [];
  path: any;
  paramId: any;
  title: any;
  playlistname: any;
  tempIndex: number = 0;
  @ViewChild('audioPlayer') player: ElementRef;
  loaderDisplay: boolean = false;
  currentTime: number = 0;
  songIsthere: boolean = false;
  isPlaying: boolean = false;

  constructor
    (
      private loadScript: LoadScriptService,
      public homeService: HomeService,
      private route: ActivatedRoute,
      private playerService: AudioPlayerService,
      private _playerService: PlayerService,
      private toastr: ToastrService,
      private router: Router,
      public userService: UserService,
      public playlistService: AudioPlayerService
    ) {
  }

  ngOnInit() {
    this.loadScript.getServiceBaseUrl();
    this.paramId = this.route.snapshot.queryParams["Id"];
    this.playlistname = this.route.snapshot.queryParams["playListName"];
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.substatus = this.userDetails.subscriptionStatus;
    this.userId = this.userDetails.userId;
    if (this.homeService.playListArray.length == 0) {
      this.title = JSON.parse(localStorage.getItem("service"));
      this.allsong(this.paramId);
    } else {
      this.title = this.homeService.playlistName;
      this.allsongs = this.homeService.playListArray;
    }
    this.title = JSON.parse(localStorage.getItem("service"));
    this.allsong(this.paramId);
  }
  allsong(playlistId: string) {
    this.homeService.adminPlaylist(this.paramId, this.userId).subscribe(Response => {
      this.TransList.push(Response);
      this.path = this.TransList[0].path;
      this.allsongs = this.TransList[0].playlistDetails;
    })
  }
  removefromplaylist(SongId: number) {
    if (confirm('Are you sure you want to delete?')) {
      let paramId = this.route.snapshot.queryParams["Id"];
      this.homeService.removefromAdminplaylist(paramId, this.userId, SongId).subscribe((res: any) => {
        this.TransList = [];
        this.allsong(paramId);
      })
    }
  }

  playSong(songs, index) {
    this.isPlaying = !this.isPlaying;
    this._playerService.playSong(songs, this.path, index);
    this.songIsthere = true;
    this.playPause();
    this.playBtnHandler();
    this.isPlaying = true;
  }


  playPause() {
    if (this.playlistService.playlist.length != 1) {
      let currentIndex = this.playlistService.playlist.findIndex(x => x.songId == localStorage.getItem('CurrentSongId'))
      if (currentIndex != -1) {
        if (this.playlistService.playPauseBtn[this.playlistService.indexSong]) {
          this.playlistService.playPauseBtn[this.playlistService.indexSong] = false;
          localStorage.setItem('IsSongPlaying', 'false');
          localStorage.setItem('IsPlaying', 'false');
        } else {
          this.playlistService.playPauseBtn[this.playlistService.indexSong] = true;
          localStorage.setItem('IsSongPlaying', 'true');
          localStorage.setItem('IsPlaying', 'false');
        }
      }
    } else {
      let currentIndex = this.playlistService.playPauseBtn.findIndex(x => x == true);

      if (this.playlistService.playPauseBtn[currentIndex]) {
        this.playlistService.playPauseBtn[currentIndex] = false;
        this.tempIndex = currentIndex;
        localStorage.setItem('IsSongPlaying', 'false');
        localStorage.setItem('IsPlaying', 'false');
      } else {
        this.playlistService.playPauseBtn[this.tempIndex] = true;
        localStorage.setItem('IsSongPlaying', 'true');
        localStorage.setItem('IsPlaying', 'false');
      }

      let Cursong = localStorage.getItem('IsSongPlaying');
      if (Cursong == 'true') {
        localStorage.setItem('IsSongPlaying', 'false');
      } else {
        localStorage.setItem('IsSongPlaying', 'true');
      }
      let CurPlayingsong = localStorage.getItem('IsPlaying');
      if (CurPlayingsong == 'true') {
        localStorage.setItem('IsPlaying', 'false');
      } else {
        localStorage.setItem('IsPlaying', 'true');
      }
      //this.playlistService.playPauseBtn = [];
    }
  }

  playBtnHandler(): void {
    if (this.loaderDisplay) {
      return;
    }
    if (this.player.nativeElement.paused) {
      this.player.nativeElement.play(this.currentTime);
    } else {
      this.currentTime = this.player.nativeElement.currentTime;
      this.player.nativeElement.pause();
    }
  };


}
