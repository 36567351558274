import { Component, OnInit } from '@angular/core';
import { Users } from '../models/users';
import { Router } from '@angular/router';
import { HomeService } from '../Shared/home.service';
import { LoadScriptService } from '../loadScript.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlbumService } from '../Shared/album.service';
import { ArtistsService } from '../Shared/artists.service';

@Component({
  selector: 'app-most-played-artist',
  templateUrl: './most-played-artist.component.html',
  styleUrls: ['./most-played-artist.component.css']
})
export class MostPlayedArtistComponent implements OnInit {

  userDetails = new Users();
  UserId: any;
  userFullname: any;
  mplayedArtist = [];
  path;
  totalcount: any = 0;
  userImage: any;
  mostPlayedImage: any;
  tempHitMakers: any = new Array();
  showAllLess = "Show all";
  showAllArtists = "Show all artists";
  minNum: number = 50;
  isLoading: boolean;
  isFollowing = 0;

  constructor(
    private _homeService: HomeService,
    private router: Router,
    private loadScript: LoadScriptService,
    private spinner: NgxSpinnerService,
    private albumService: AlbumService,
    private _artistsService: ArtistsService
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.UserId = this.userDetails["userId"];
    this.userFullname = this.userDetails["fullName"];
    this.userImage = this.userDetails["image"];
    if (!this.userImage) {
      this.userImage = "../../../assets/images/NoUserFound.png";
    }
    this.getmostplayedartistbyUserid(this.userDetails.userId);

  }

  getmostplayedartistbyUserid(userId) {
    this.isLoading = true;
    this._artistsService.GetMostPlayedArtistbyUserId(userId).subscribe((data: any) => {
      console.log(data);
      this.isLoading = false;
      this.mplayedArtist = data.getMPArtist;
      if (this.mplayedArtist.length) {
        this.tempHitMakers = this.mplayedArtist.slice(0, this.minNum);
        this.mostPlayedImage = data.getMPArtist[0].thumbImage;
        this.totalcount = this.formatSongCount(data.getMPArtist.length);
      }
    });

  }

  goTonewdiscover(ArtistId) {
    this.router.navigate(['/newdiscover'], { queryParams: { Id: ArtistId } });
  }

  showMoreartists() {
    this.isLoading = true;
    this.minNum = this.minNum + 50;
    if (this.minNum > this.mplayedArtist.length || this.minNum == this.mplayedArtist.length) {
      this.minNum = this.mplayedArtist.length
      this.showAllLess = "Show less";
    }
    // Introduce an artificial delay of 5-10 seconds
    const delay = Math.floor(Math.random() * 4000) + 1000; // Random delay between 5000ms (5s) and 11000ms (11s)

    setTimeout(() => {
      this.tempHitMakers = this.mplayedArtist.slice(0, this.minNum);
      this.isLoading = false;
    }, delay);
  }

  showMoresongs() {
    this.isLoading = true;
    this.minNum = this.minNum + 50;
    if (this.minNum > this.mplayedArtist.length || this.minNum == this.mplayedArtist.length) {
      this.minNum = this.mplayedArtist.length
      this.showAllLess = "Show less";
    }
    // Introduce an artificial delay of 5-10 seconds
    const delay = Math.floor(Math.random() * 4000) + 1000; // Random delay between 5000ms (5s) and 11000ms (11s)

    setTimeout(() => {
      this.tempHitMakers = this.mplayedArtist.slice(0, this.minNum);
      this.isLoading = false;
    }, delay);
  }

  formatSongCount(length: number): string {
    if (length < 10 && length > 0) {
      // Format numbers less than 10 with a leading zero
      return '0' + length;
    } else if (length >= 10000) {
      // Round the number to the nearest hundred for numbers >= 10,000
      const roundedLength = Math.round(length / 100) * 100;
      return new Intl.NumberFormat('en-US').format(roundedLength);
    } else {
      // Simply format numbers >= 10 and < 10,000 with commas
      return new Intl.NumberFormat('en-US').format(length);
    }
  }

}
