import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { HomeService } from 'src/app/Shared/home.service';
import { PaginationServiceForAlbum } from 'src/app/Shared/paginationserviceForAlbum.service';
import { LoadScriptService } from 'src/app/loadScript.service';

@Component({
  selector: 'app-admin-faq',
  templateUrl: './admin-faq.component.html',
  styleUrls: ['./admin-faq.component.css']
})
export class AdminFaqComponent implements OnInit {
  faqDetails: any[] = [];
  noRecords: boolean = false;
  truncatedText: string[];

  constructor(private loadScript: LoadScriptService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private _toastr: ToastrService, private route: ActivatedRoute,
    public paginationService: PaginationServiceForAlbum,
    public translate: TranslateService, private homeService: HomeService
  ) { }

  ngOnInit() {
    this.getAllFaqs();

  }
  getAllFaqs() {
    this.homeService.getAllFaqs().subscribe((faq: any) => {
      console.log(faq)
      this.faqDetails = faq.faqDetails;
      //this.truncatedText = this.faqDetails.map(text => this.getFirstSentence(text.faqTitle));
    })
  }
  add() {
    this.spinner.show();
    this.router.navigate([`/admin/admin-faq/add-update-faq`]);
  }
  editSection(item) {
    this.router.navigate([`/admin/admin-faq/add-update-faq/` + item + ``]);
  }
  deleteSection(item) {
    if (confirm('Please note! Are you sure you want to delete this FAQ?')) {
      this.homeService.DeleteFaq(item).subscribe((response: any) => {
        if (response.isSuccess) {
          this._toastr.success(response.message);
          this.getAllFaqs();
        }
      }, err => {
        this._toastr.error(err)
        this.spinner.hide();
      });
    }
  }

  getFirstSentence(text: string): string {
    debugger;
    if (text) {
      const match = text.match(/[^.!?]*[.!?]/);
      return match ? match[0] : text;
    }

  }

}
