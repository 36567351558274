import { Component, OnInit, Input, ViewChild, DebugNode, ElementRef } from '@angular/core';
import { AlbumService } from 'src/app/Shared/album.service';
import { ArtistsService } from 'src/app/Shared/artists.service';
import { HomeService } from 'src/app/Shared/home.service';
import { SongsService } from 'src/app/Shared/songs.service';
import { Users } from '../../models/users';
import { ChatService } from 'src/app/Shared/chat.service';
import { ChatModel } from '../../models/chat-model';
import { UserService } from 'src/app/Shared/user.service';
import { ToastrService } from 'ngx-toastr';
import { Pagination } from 'src/app/models/pagination';
import { PaginationServiceForUserService } from 'src/app/Shared/pagination-service-for-user.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-artist-communication',
  templateUrl: './artist-communication.component.html',
  styleUrls: ['./artist-communication.component.css']
})
export class ArtistCommunicationComponent implements OnInit {

  userDetails = new Users();
  artistalbumcounts:any;
  message: ChatModel = new ChatModel();
  totalsongcount:any;
  noofLikes:any;
  messages: ChatModel[] = [];
  nooffav:any;
  caseIddel:any;
  userFullname:any;
  ArtistId: any = 0;
  sbclick:any;
  subjecttitle:any;
  caseId: any = 0;
  caseIdclick: any = 0;
 adminNameclick: any = 0;
  AdminUserList:any;
  UserId:any;
  CaseSubject:any;
  CaseAdminid:any;
  communicationdata:any;
  Startchatflag: boolean = false;
  @ViewChild('myDiv') myDiv: ElementRef<HTMLElement>;
  @ViewChild('myDivchat') myDivchat: ElementRef<HTMLElement>;
  @ViewChild('myDivdelete') myDivdelete: ElementRef<HTMLElement>;

  constructor(private albumService: AlbumService, private _songsService: SongsService,private _artistsService: ArtistsService, private chatService: ChatService,private homeService: HomeService,private _toastr: ToastrService,
    private userService: UserService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    debugger;
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.userFullname= this.userDetails["fullName"]
    this.ArtistId = this.userDetails["artistId"];
    this.getalbumbyartistId();
    this.ArtistSongsById();
    this.GetArtistDetails();
    this.GetAllAdminUsers();
    this.GetAllCases();
    this.chatService
    .getMessages()
    .subscribe((message: ChatModel) => {
      debugger
      this.messages.push(message);
    });
  }

  getalbumbyartistId() {
    this._songsService.GetAllAbumbs(this.ArtistId).subscribe((data: any) => {
      this.artistalbumcounts = data.albumDetails.length;
      console.log('albumartist', this.artistalbumcounts);
    });
  }

  ArtistSongsById() {
    this.homeService.ArtistSongsById(this.ArtistId).subscribe((data: any) => {
      this.totalsongcount = data.artistsongs.length;
    });

  }

  GetArtistDetails() {
    this._artistsService.getArtistDetails(this.ArtistId).subscribe((data: any) => {
      console.log(data);
      this.noofLikes = data.artistDetails.noofLikes;
      this.nooffav = data.artistDetails.noofFavs;

      // this.imgURL= data.artistDetails.largeImage;
    })
  }
  sendMessage() {
    if (this.message.text != "") {
      this.message.date = new Date;
      this.message.Message = this.message.text;
      this.message.Type = "Artist";
      this.message.Name = this.userDetails["fullName"];
      this.message.senderId = this.ArtistId;
      this.message.caseId = this.caseId;
      this.chatService.sendMessage(this.message);
      this.message = new ChatModel();
    }
  }
  GetAllAdminUsers() {
    this.userService.GetAllAdminUsers().subscribe((data: any) => {
      console.log(data);
      this.AdminUserList=data.getAllAdminUsers;
    })
  }
  saveCase(){
    debugger;
    var DataCase = {
      "ArtistId":this.ArtistId,
      "Subject":this.CaseSubject,
      "AdminId":  this.CaseAdminid,
      "Iscompleted": false,
       "CreatedBy":  this.ArtistId,
    };
    if(this.CaseSubject== null || this.CaseSubject == ""){
      this._toastr.warning("Entre The Subject");
      return;
    }
    if(this.CaseAdminid== null || this.CaseAdminid == ""){
      this._toastr.warning("select The Admin");
      return;
    }
    this._artistsService.SaveCases(DataCase).subscribe((res: any) => {
      console.log(res);
      this._toastr.success("Case Added successfully");
      this.triggerFalseClick()
      this.GetAllCases();
    });
  }
  GetAllCases() {
    this._artistsService.GetAllCases(this.ArtistId).subscribe((data: any) => {
      console.log(data);
      this.communicationdata=data.getAllCases;
    })
  }
  triggerFalseClick() {
    let el: HTMLElement = this.myDiv.nativeElement;
    el.click();
    let eld: HTMLElement = this.myDivdelete.nativeElement;
    eld.click();
    let elc: HTMLElement = this.myDivchat.nativeElement;
    elc.click();
}
Chatstart(chatlist:any){
  if(chatlist!=1)
  {
    this.sbclick=chatlist.subject;
    this.caseIdclick=chatlist.caseId;
    this.adminNameclick=chatlist.adminName;
  }
  else{
    this.messages=[];
    this.subjecttitle=this.sbclick;
   this.caseId=this.caseIdclick;
   this.userFullname=this.adminNameclick
   this.Startchatflag=true;
   this.triggerFalseClick()
  }
 
}
Chatdelete(id){
  if(id!=0)
  {
    this.caseIddel=id
  }
  else{
    this._artistsService.CaseDekete(this.caseIddel).subscribe((data: any) => {
    this.GetAllCases();
    this.triggerFalseClick();
    })
  }

}

}
