import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Users } from 'src/app/models/users';
import { Role } from '../../models/role';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HomeService } from 'src/app/Shared/home.service';
import { UserService } from 'src/app/Shared/user.service';
import { LoadScriptService } from 'src/app/loadScript.service';
import { AlbumService } from 'src/app/Shared/album.service';
import { SongsService } from 'src/app/Shared/songs.service';
import { LoginService } from 'src/app/Shared/login.service';
import { Playlist } from 'src/app/models/playlist';
import { Track } from 'Player/ngx-audio-player/src/public_api';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { PlayerService } from 'src/app/Shared/player.service';
import { ConfigService } from 'src/app/app.config';
import { ArtistsService } from 'src/app/Shared/artists.service';
import { Alldetails } from 'src/app/models/alldetails';
declare const $: any;





@Component({
  selector: 'app-new-home',
  templateUrl: './new-home.component.html',
  styleUrls: ['./new-home.component.css']
})
export class NewHomeComponent implements OnInit {
  // @ViewChild('audioPlayer') player: ElementRef;
  // ShowPlayer: boolean = true;
  // loaderDisplay: boolean = true;


  @ViewChild('audioPlayer') player: ElementRef;
  isPlaying: boolean = false;
  ShowPlayer: boolean = true;
  userLogin: boolean = false
  loaderDisplay: boolean = false;
  userDetails = new Users();
  artistLogin: boolean = false;
  theme1: boolean = true;
  // [x: string]: any;
  slides = [];
  artistList = [];
  topSongs = [];
  homeDetails = [];
  newReleaseSongs = [];
  newRelease = [];
  recommendedSongs = [];
  favouriteSongs = [];
  mostPlayedSongs = [];
  songIds = [];
  albumIds = [];
  artistIds = [];
  playlistIds = [];
  path;
  index = 0;
  track: Track[] = [];
  public count: number = 0;
  songDetails = new Track();
  noImage: boolean[] = [];
  newReleaseImage: boolean[] = [];
  noMostplayedImage: boolean[] = [];
  nofavouriteSongsImage: boolean[] = [];
  noArtistmage: boolean[] = [];
  substatus: any;
  songIsthere: boolean = false;
  themeidcode: string;
  adminplaylist: any;
  theme: any;
  artiststatus: any;
  totalabum: any;
  totalSongs: any;
  totalLikes: any;
  totalFav: any;
  totalListners: any;
  adminStats: any;
  totalUsers: any;
  totalActiveUsers: any;
  totalIOsUsers: any;
  totalAndroidUsers: any;
  totalTobyPremium: any;
  totalTobyFamily: any;
  totalCancelSubscriptions: any;
  totalabums: any;
  totalartistSongs: any;
  artistid: any;
  artistsong: any;
  featuredPlaylist: any[] = [];
  superAdminLoggedIn: boolean = false;
  userLoggedIn: boolean = false;
  allData = [];
  allDetails: Alldetails[] = [];

  albums = [
    { image: '../../../assets/images/NoUserFound.png', title: 'Album 1', subtitle: 'Subtitle 1' },
    { image: '../../../assets/images/NoUserFound.png', title: 'Album 2', subtitle: 'Subtitle 2' },
    { image: '../../../assets/images/NoUserFound.png', title: 'Album 3', subtitle: 'Subtitle 3' },
    { image: '../../../assets/images/NoUserFound.png', title: 'Album 4', subtitle: 'Subtitle 4' },
    { image: '../../../assets/images/NoUserFound.png', title: 'Album 5', subtitle: 'Subtitle 5' },
    { image: '../../../assets/images/NoUserFound.png', title: 'Album 6', subtitle: 'Subtitle 6' },
    { image: '../../../assets/images/NoUserFound.png', title: 'Album 7', subtitle: 'Subtitle 7' },
    { image: '../../../assets/images/NoUserFound.png', title: 'Album 8', subtitle: 'Subtitle 8' },
  ];

  backgroundSlideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000
  };

  albumSlideConfig = {
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };


  public songsLists: Track = new Track();
  bannerDetails: any;
  featuredAlbum: any;
  featuredSong: any;
  tempIndex: number = 0;
  currentTime: number = 0;
  hImages: any = [];
  songDetail: any = [];
  albumDetail: any = [];
  artistDetail: any = [];
  playlistDetail: any = [];
  featureName: any;
  featureTitle: any;
  type: any = [];
  ind: any = [];
  cnt = 0;
  indx = 0;
  isUser: boolean = false;
  isLoading = false;
  routing = ["/likedsongs", "/mostplayedsongs", "/mostplayedartist", "/recommendedsongs", "/mostplayedalbums", "/newlyaddedsongs"];
  //["/recommendedsongs","/newlyaddedsongs","/mostplayedsongs","/mostplayedalbums","/mostplayedartist"]
  favoratesArea = [
    {
      image: "../../../assets/images/home/likedsng.png",
      name: "Liked Songs",
      routingLink: "/likedsongs"
    },
    {
      image: "../../assets/images/home/mostplayedsong.svg",
      name: "Most Played Songs",
      routingLink: "/mostplayedsongs"
    },
    {
      image: "../../assets/images/home/mostplayedartist.svg",
      name: "Most Played Artist",
      routingLink: "/mostplayedartist"

    },
    {
      image: "../../assets/images/home/recommended-song.svg",
      name: "Recommended Songs",
      routingLink: "/recommendedsongs"
    },
    {
      image: "../../assets/images/home/mostplayed album.svg",
      name: "Most Played Albums",
      routingLink: "/mostplayedalbums"
    },
    {
      image: "../../assets/images/home/newlyadded.svg",
      name: "Newly Added Songs",
      routingLink: "/newlyaddedsongs"
    }
  ]


  adminplstpath: any;
  isBannerLoading: boolean = false;

  constructor(private renderer: Renderer2, private el: ElementRef, public translate: TranslateService,
    private router: Router,
    private loadScript: LoadScriptService,
    private _loginService: LoginService,
    private _homeService: HomeService,
    public userService: UserService,
    private spinner: NgxSpinnerService,
    private albumService: AlbumService,
    private artistService: ArtistsService,
    private _songservice: SongsService,
    private configService: ConfigService
    , private _playerService: PlayerService, private playerService: AudioPlayerService) {
    this.updateDisplayCount(window.innerWidth);
    this.updateVisibleSlides(window.innerWidth);
    // this.arabicRegex = '[\u0600-\u06FF]';
    // this.startyear = configService.getstartDate();
  }

  ngOnInit() {
    this.themeidcode = localStorage.getItem('themeId');
    this.loadScript.getServiceBaseUrl();
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.substatus = this.userDetails.subscriptionStatus;
    this.translate.use(this.userDetails.languageCode);
    this.theme = this.userDetails.themeId;
    this.Changetheme();
    this.getAllDetails();
    this.getallbannerdetails();
    this.getfeaAlbum();
    this.getfeaSongs();
    this.getfeaPlaylists();
    //this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    if (this.userDetails.userType == Role.superAdmin) {
      this.userLogin = false;
      this.superAdminLoggedIn = true;
    } else if (this.userDetails.userType == Role.user) {
      this.userLogin = true;
      this.userLoggedIn = true;
      this.getmostplayedalbumbyUserid(this.userDetails.userId);
      this.gethomeimages(this.userDetails.userId);
    }
    else {
      this.artistLogin = true;
      this.userLogin = false;
    }

    //this.updateVisibleAlbums();
  }



  IsCheckLang() {
    if (this.translate.currentLang == 'ar') {
      return true;

    } else if (this.translate.currentLang == 'as') {
      return true;
    }
    else {
      return false;
    }
  }
  Changetheme() {
    if (this.userDetails.themeId == '2') {
      this.theme1 = false;
      return true;

    }
    else {
      this.theme1 = true;
      return false;
    }
  }

  getHomeDetails(userId) {
    this.spinner.show();
    this._homeService.getHomeDetails(userId).subscribe((data: any) => {
      this.homeDetails = data;
      //console.log('this.homeDetails', this.homeDetails)
      this.path = data.path;
      // this.artistList = data.homePageDetails.artist;
      // this.topSongs = data.homePageDetails.topSongs;
      this.newRelease = data.homePageDetails.newRelease;
      this.newReleaseSongs = data.homePageDetails.newReleaseSongs;
      // this.recommendedSongs = data.homePageDetails.recommendedSongs;
      // this.favouriteSongs = data.homePageDetails.favouriteSongs;
      // this.mostPlayedSongs = data.homePageDetails.mostPlayedSongs;
      // this.adminplaylist = data.homePageDetails.adminPlayLists;
      this.spinner.hide();
    });

  }

  getAllDetails() {
    debugger;
    this.userService.getAllHomeSection().subscribe((hsData: any) => {
      //console.log(hsData);
      debugger;
      hsData.homeSectionobj.forEach(dt => {
        if (dt.isActive) {
          this.allData.push(dt)
        }
      })
      //console.log(this.allData)
      this.allData.forEach(d => {
        // console.log(d);
        if (d.songId != null) {
          const songIds = d.songId.split(',');
          const songDetailsPromises = songIds.map(songId => this._songservice.getSongDetails(songId).toPromise());
          Promise.all(songDetailsPromises).then((songDetails: any) => {
            songDetails.forEach(s => {
              s.type = d.sectionTitle,
                s.songs.thumbImage = s.path + s.songs.thumbImage,
                s.songs.length = s.songs.length.slice(3, 8);
            });
            this.songDetail.push(songDetails);
          }
          )
        }
        if (d.albumId != null) {
          const albumIds = d.albumId.split(',');
          const albumDetailsPromises = albumIds.map(albumId => this.albumService.GetAlbumsbyAlbumId(albumId).toPromise());
          Promise.all(albumDetailsPromises).then((albumDetails: any) => {
            albumDetails.forEach(s => {
              s.type = d.sectionTitle,
                s.albumDetails.thumbImage = s.path + '/' + s.albumDetails.thumbImage
            })
            this.albumDetail.push(albumDetails);
            //console.log('albums', albumDetails, this.albumDetail);

          })
        }
        if (d.artistId != null) {
          const artistIds = d.artistId.split(',');
          const artistDetailsPromises = artistIds.map(artistId => this.artistService.getArtistDetails(artistId).toPromise());
          Promise.all(artistDetailsPromises).then((artistDetails: any) => {
            artistDetails.forEach(s => {
              s.type = d.sectionTitle,
                s.artistDetails.thumbImage = s.path + s.artistDetails.thumbImage
            })
            this.artistDetail.push(artistDetails);
            //console.log(artistDetails, this.artistDetail)
          })
        }
        if (d.playlistId != null) {
          const playlistIds = d.playlistId.split(',');
          const playlistDetailsPromises = playlistIds.map(playlistId => this._homeService.getadminPlaylistById(playlistId).toPromise());
          Promise.all(playlistDetailsPromises).then((playlistDetails: any) => {
            playlistDetails.forEach(s => {
              s.type = d.sectionTitle,
                s.playListDetails.thumbImage = s.path + s.playListDetails.thumbImage
            })
            this.playlistDetail.push(playlistDetails);
            //console.log(playlistDetails, this.playlistDetail)
          })
        }
      })
      //console.log(this.allData, this.songIds, this.albumIds, this.artistIds, this.playlistIds);
      // console.log(this.songDetail, this.albumDetail, this.artistDetail, this.playlistDetail, this.ind, this.songItems)
    })
  }



  CheckSubscription(song, i) {
    debugger;
    this.songIsthere = true;
    this.playSong(song, i);
    //this.playSong(song, i);
    // this.playalbumSong(song, i);
    // if (this.substatus == "False") {
    //   document.getElementById("Subscriptioncheck").style.display = "block";
    //   //this.router.navigate(['/account/upgradeplan']);
    // }
    // else {

    // }
  }

  play






  playSong(songs, index) {
    debugger;
    this._playerService.msaapPlaylist = [];
    this._playerService.playSongs(songs);
    // if (this.playerService.repeatSong) {
    //   this.playerService.repeatSong = false;
    // }
    // this.playerService.setPlaylist(this._playerService.msaapPlaylist);
    // this.playerService.selectATrack(index + 1);
    // this._playerService.updateData(this._playerService.msaapPlaylist);
    //this.player.nativeElement.paused = true;
    this.playerService.playSongCallbackmain(true);
  }

  updatecount(SongId, userId) {
    debugger;
    this._homeService.updateCountnew(SongId, userId).subscribe((data: any) => {
    })
  }

  getmostplayedalbumbyUserid(userId) {
    this.albumService.getmostplayedalbumbyuserid(userId).subscribe((data: any) => {
      //console.log(data);
    })
  }

  getallbannerdetails() {
    this.userService.getAllBannerDetails().subscribe((data: any) => {
      this.bannerDetails = data.bannerDetails;
    });
  }

  // ngAfterViewInit() {
  //   $('#carouselExampleCaptions').carousel()
  // }

  finalAlbumList = [];
  displayCount = 5;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateDisplayCount(event.target.innerWidth);
    //const target = event.target as Window;
    this.updateVisibleSlides(event.target.innerWidth);

    //console.log('chk width', event.target.innerWidth);

  }
  updateDisplayCount(width: number) {
    debugger;
    if (width >= 1500 && width <= 1600) {
      this.displayCount = 5;
      //this.getdata();
      // this.getfeaAlbum();
    }
    else if (width >= 1700 && width <= 1800) {
      this.displayCount = 6;
      //this.getfeaAlbum();
    }
    else if (width >= 1900 && width <= 1999) {
      this.displayCount = 7;
      //this.getfeaAlbum();
    }
    else if (width >= 2000 && width <= 7000) {
      this.displayCount = 8;
      //this.getfeaAlbum();
    }
    // else if (width == 2304) {
    //   this.displayCount = 8;
    //   //this.getfeaAlbum();
    // }
    // else if (width == 3072) {
    //   this.displayCount = 8;
    //   //this.getfeaAlbum();
    // }
    // else if (width == 4608) {
    //   this.displayCount = 8;
    //   //this.getfeaAlbum();
    // }
    // else if (width == 6144) {
    //   this.displayCount = 8;
    //   //this.getfeaAlbum();
    // }
    else if (width >= 1300 && width <= 1400) {
      this.displayCount = 5;
      //this.getfeaAlbum();
    }
    else if (width >= 900 && width <= 1000) {
      this.displayCount = 3;
      //this.getfeaAlbum();
    }
    else if (width >= 700 && width <= 877) {
      this.displayCount = 2;
      //this.getfeaAlbum();
    }
    // else if (width == 768) {
    //   this.displayCount = 2;
    //   //this.getfeaAlbum();
    // }
    else if (width <= 1404) {
      this.displayCount = 4;
      //this.getfeaAlbum();
    }

  }

  get displayedItems() {
    // Return the items that should be currently displayed
    if (this.featuredSong) {
      return this.featuredSong.slice(0, this.displayCount);
    }
  }

  get feaalbumsItems() {
    // Return the items that should be currently displayed
    if (this.featuredAlbum) {
      return this.featuredAlbum.slice(0, this.displayCount);
    }
  }


  get feaplaylstItems() {
    // Return the items that should be currently displayed
    if (this.featuredPlaylist) {
      return this.featuredPlaylist.slice(0, this.displayCount);
    }
  }

  get songItems() {
    return this.songDetail.map(innerArray => {
    });
  }

  get albumItems() {
    return this.albumDetail.map(innerArray => {
      if (innerArray.length > 5 || innerArray.length <= 5) {
        return innerArray.slice(0, this.displayCount);
      }
      return innerArray;
    });
  }

  get artistItems() {
    return this.artistDetail.map(innerArray => {
      if (innerArray.length > 5 || innerArray.length <= 5) {
        return innerArray.slice(0, this.displayCount);
      }
      return innerArray;
    });
  }

  get playlistItems() {
    return this.playlistDetail.map(innerArray => {
      if (innerArray.length > 5 || innerArray.length <= 5) {
        return innerArray.slice(0, this.displayCount);
      }
      return innerArray;
    });
  }

  getfeaAlbum() {
    this.isLoading = true;
    this.albumService.GetFeaturedAlbum().subscribe((data: any) => {
      this.featuredAlbum = data.feaAlbumDetails.list;
      this.isLoading = false;
      // this.featuredAlbum.push(this.tempAlbum[0]);
      // this.featuredAlbum.push(this.tempAlbum[1]);
      // this.featuredAlbum.push(this.tempAlbum[2]);


      // let initialLength = Math.floor((this.curentDivwith / 200))
      // if (initialLength < this.featuredAlbum.length)
      //   for (let i = 0; i < initialLength; i++) {
      //     this.finalAlbumList.push(this.featuredAlbum[i]);
      //   }

      // console.log('featuredalbumss', this.finalAlbumList);
      //this.featuredAlbum = data.feaAlbumDetails.list;

      // console.log('album', this.featuredAlbum);
      // data.feaAlbumDetails =  this.feaAlbumData;
      // this.feaAlbumId = data.feaAlbumDetails.albumId;
    });
  }


  getfeaSongs() {
    this.isLoading = true;
    this._songservice.GetFeaturedSongs().subscribe((data: any) => {
      //console.log(data);
      this.featuredSong = data.feaSongs;
      this.isLoading = false;
      this.path = data.feaSongs.path;
      this._playerService.msaapPlaylist.push(this.featuredSong);
      this.playerService.setPlaylist(this._playerService.msaapPlaylist);
    });
  }

  getfeaPlaylists() {
    this._homeService.getadminPlaylist().subscribe((data: any) => {
      // console.log('adminplts', data);
      debugger;
      let playList = data.playListDetails;
      this.adminplstpath = data.path;
      this.adminplaylist = data.adminPlaylistPath;
      for (let i = 0; i < 5; i++) {
        var j = Math.floor(Math.random() * playList.length);
        this.featuredPlaylist.push(playList[j]);
        playList.splice(j, 1);
      }
      // this.path = data.path + 'Images/AdminPlaylist';

      //console.log(this.featuredPlaylist);
    })
  }


  onBannerClick(item) {
    //console.log(item);
    if (item.promotion != null) {
      window.open(item.promotion, '_blank');
    }
    else {
      this.router.navigate([`/bannerdetails/` + item.homeBannerId + ``]);
    }
  }


  openAlbum(item) {
    //console.log(item);
    var Album = {
      queryParams: {
        album: 'Album'
      }
    }
    this.router.navigate([`/artistalbumpage/` + item + ``], Album)
  }


  getPlaylistDetails(item) {
    //console.log(item);
    this.router.navigate(['/playlist/allplaylistsongs'], { queryParams: { Id: item.playlistId, admin: item.name } });
  }

  addSlide() {
    this.slides.push({ img: "http://placehold.it/350x150/777777" })
  }

  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }

  slickInit(e) {

  }

  breakpoint(e) {

  }

  afterChange(e) {

  }

  beforeChange(e) {

  }

  gethomeimages(userId) {
    var folderName = "";
    this.isBannerLoading = true;
    this._homeService.gethomeimages(userId).subscribe((data: any) => {
      // console.log('images', data);
      this.isBannerLoading = false;
      data.homeImages.map((im, i) => {
        if (im.type == "Most Played Albums") {
          folderName = "Albums";
        }
        else if (im.type == "Most Played Artists") {
          folderName = "Artists";
        }
        else {
          folderName = "Songs";
        }
        im.thumbImage = data.path + 'images/' + folderName + '/' + im.homeImageId + '/' + im.thumbImage;
        im.routing = this.routing[i]
      })
      this.hImages = data.homeImages;
      console.log(this.hImages);
    })
  }

  // 
  truncateTitle(title: string, maxLength: number): string {
    if (!title) {
      return "No Title";
    }
    if (title.length <= maxLength) {
      return title;
    }
    return `${title.substring(0, maxLength)}...`;
  }

  playBtnHandler(): void {
    debugger;
    if (this.loaderDisplay) {
      return;
    }
    if (this.player.nativeElement.paused) {
      // this.player.nativeElement.play(this.currentTime);
      (document.getElementById("audioPlayer") as HTMLMediaElement).play();
    } else {
      this.currentTime = this.player.nativeElement.currentTime;
      // this.player.nativeElement.pause();
      (document.getElementById("audioPlayer") as HTMLMediaElement).pause();
    }
  };

  currentSlide = 0;
  visibleSlides = 5;



  updateVisibleSlides(width: number) {
    debugger;
    if (width >= 1200) {
      this.visibleSlides = 5;
    } else if (width >= 992) {
      this.visibleSlides = 4;
    } else if (width >= 768) {
      this.visibleSlides = 3;
    } else if (width >= 576) {
      this.visibleSlides = 2;
    } else {
      this.visibleSlides = 1;
    }
  }

  nextSlide() {
    this.currentSlide = (this.currentSlide + 1) % this.slides.length;
  }

  previousSlide() {
    this.currentSlide = (this.currentSlide - 1 + this.slides.length) % this.slides.length;
  }


}
