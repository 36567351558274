import { Injectable } from '@angular/core';
import { ConfigService } from '../app.config';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private url = '';
  private baseUrl = '';
  constructor(private http: HttpClient, private configService: ConfigService) {
    this.baseUrl = configService.getServiceBaseUrl();

  }

  updateLanguage(userId, LanguageCode) {
    this.url = this.baseUrl + 'api/Settings/ChangeLanguage?UserId=' + userId + '&LanguageCode=' + LanguageCode;
    return this.http.post(this.url, userId, LanguageCode)

  }
  getLanguages(UserId) {

    this.url = this.baseUrl + 'api/Language/GetAllLanguages?UserId=' + UserId;
    return this.http.get(this.url);
  }
}
