import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PaypalService } from '../../Shared/paypal.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer,SafeHtml } from '@angular/platform-browser';
@Component({
  selector: 'app-subscriptionplan',
  templateUrl: './subscriptionplan.component.html',
  styleUrls: ['./subscriptionplan.component.css']
})

export class SubscriptionplanComponent implements OnInit {
  subplans: any;
  basicAuth = 'Basic QVlvb25tN3dKaWpfb1FnNjNYLTBCcjBlVGdHcjVzRS1rT1ZZb3g0OVk4eWZ5SUpVVHY2UElaQ1pxb09vN19Cb25XbE92bDhhOW5ZaDZ3UU46RURUbEF3N3NwUGN4ajdkRTY3VXdJYW1obEJrdUtTRXFUUWxNNmd1NHhWdURuRlV6cnJXMlZqaFMwek1rR3l6YWtqSlVnLWxHM0FZQjQtcFk=';
  constructor(private paypalService: PaypalService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.getsubscription();
  }
  getsubscription() {
    this.spinner.show();
    this.paypalService.GetAllSubscriptionplan().subscribe((data: any) => {
      this.subplans = data.subscriptionplans;
      this.spinner.hide();
    }, err => { this.toastr.error(err) });
  }
  Editsunscriptionplan(SubscriptionPlanId: string) {
    this.router.navigate(['/admin/updatesubscription'], { queryParams: { Id: SubscriptionPlanId } });
  }
  GetsandboxData(PlanId) {
    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
      }
    };
    xhttp.open('GET', 'https://api.sandbox.paypal.com/v1/billing/plans/' + PlanId, true);
    xhttp.setRequestHeader('Authorization', this.basicAuth);
    xhttp.send();
  }

  sanitizeContent(content: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(content);
}

}
