import { Component, OnInit, ViewChild, ElementRef, HostListener, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../Shared/login.service'
import { UserMember, Users } from '../models/users';
import { Country } from '../models/country';
import { ToastrService } from 'ngx-toastr';
import { disableDebugTools } from '@angular/platform-browser';
import { LoadScriptService } from 'src/app/loadScript.service';
import { SubscriptionService } from '../Shared/subscription.service';
import { Subscriptions } from '../models/subscriptions';
import { PaymentTransaction } from '../models/payment-transaction';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../Shared/language.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators, NgForm, NgModel } from '@angular/forms';
import * as $ from 'jquery';
import { fromEvent, interval, Observable, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
    AuthService, SocialLoginModule,
    FacebookLoginProvider,
    GoogleLoginProvider,

} from 'angular-6-social-login';
import { UserService } from '../Shared/user.service';
import { Artist } from '../models/artist';
import { ArtistsService } from '../Shared/artists.service';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { PaypalService } from '../Shared/paypal.service';
import { ConfigService } from '../app.config';
import { NgxBraintreeService } from 'ngx-braintree';
import { InviteUsers } from '../models/inviteusers';
import { Usersubscription } from '../models/usersubscription';
// import { HomeService } from '../Shared/home.service';
// import { Faq } from '../models/faq';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';




@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
    providers: [SocialLoginModule, AuthService]
})
export class LoginComponent implements OnInit {
    @ViewChild('closeBtn') closeBtn: ElementRef;
    @ViewChild('closeBtn1') closeBtn1: ElementRef;
    @ViewChild('closeBtn2') closeBtn2: ElementRef;
    @ViewChild('closeBtn3') closeBtn3: ElementRef;

    @ViewChild('closeResetBtn') closeResetBtn: ElementRef;
    closeCookie: boolean = true;
    country: Array<Country> = [];
    countryList: Array<Country> = [];
    userDetails = new Users();
    validEmail;
    searchCountry: boolean = false;
    userNameError: boolean;
    passwordError: boolean;
    emailIdError: boolean;
    countryError: boolean;
    showValidationModalError: boolean = false;
    abouttoby: any;
    //Validation for Login 
    userName;
    password;
    loginUserNameError: boolean;
    loginPasswordError: boolean;
    loginFailed: boolean = false;
    isArtistLogIn: boolean;
    indexForCountry = 0;
    highlightCountry: boolean[] = [];
    emailError: boolean = false;
    massage: string;
    Email: any;
    Emaillist: Array<any> = [];
    inCorrectEmail: boolean = false;
    userEmaillist: Array<any> = [];
    userId: number;
    subscription: any;
    singledevice: any;
    multipledevice: any;
    Singlesubscription = 0;
    Multiplesubscription = 0;
    offerprice: any;
    discountedPrice;
    savedPrice;
    offerprice1: any;
    discountedPrice1;
    savedPrice1;
    showPaymentSection: boolean = false;
    showconfirmpayment: boolean = false;
    showHidePlan: boolean = false;
    showHideRegistraionPage: boolean = true;
    subDuration: string;
    saveprice: any;
    MultiplediscountedPrice1: any;
    Multipleofferprice: any;
    MultiplesavedPrice: number;
    MultiplediscountedPrice: any;
    userSubscriptions = new Subscriptions();
    paymentTransaction = new PaymentTransaction();
    usersubid: any;
    usertype: any;
    userchecked: boolean = true;
    artistchecked: boolean;
    logindate: boolean = false;
    loginusername: boolean = false;
    languages: any;
    languageId;
    languageCode: any = 'en';
    countryId: any = 1;
    langcode: any;
    topthree = [];
    comingUp = [];
    PopularNow = [];
    path: any;
    topthrees = [];
    index = 0;
    result1: any;
    UserId: any;
    subscriptionStatus;
    result: any;
    terms: any;
    lanCode: any;
    Code: any;
    isChecked: boolean = true;
    showArtistlogin: boolean = false;
    showUserlogin: boolean = true;
    artistDetails = new Artist();
    themeidcode: string;
    plainid: any;
    subStatus: any;
    theme1: boolean;
    CountryName;
    IsValid: any;
    indexPage: boolean = true;
    loginPage: boolean
    registerPage: boolean;
    invitedUserRegisterPage: boolean;
    userGender = 0;
    selectedmonth: any;
    //year:any;
    recaptcha;
    // siteKey: string = "6LdkBNkZAAAAAA_eGbDIJ_kQBK8btSJzRAIaAOnb";
    siteKey: string = "6LctpVolAAAAAM3FPMsrVvhC9Wv9GYMv2SERbrNp";
    daysLeft: any;
    baseUrl: string;
    subscriptionPlan: any;
    dayError: boolean;
    yearError: boolean;
    // multidevicecheck:boolean=false;
    showAllQuestions: boolean = false;
    queLabel = "See all questions";
    termscondbt: boolean = false;
    termscondbtns: boolean = false;
    termscondbtcoks: boolean = false;
    termscondbtads: boolean = false;
    termscondbtart: boolean = false;
    termscondbtinv: boolean = false;
    termscondbtabt: boolean = false;
    termscondbtwork: boolean = false;
    termscondbtcontact: boolean = false;
    heading: string = "";
    inviteid: any = "";
    inviteUser: any;
    receiverEmail: any;
    inviteUserDetails = new InviteUsers();
    userMemberDetails = new UserMember();
    currentYear: number;
    startYear = 1930;
    years: number[] = [];
    Toby: boolean = false;
    Communities: boolean = false;
    LegalInformation: boolean = false;
    requiredError: boolean = false;
    planName: string = '';
    b: number;
    subcriptiondetails = new Usersubscription();
    allFaqs: any[] = [];
    days: string[] = [];
    regyear: number;
    memberUserRegisterPage: boolean = false;
    mainUserId: any;
    activeMonths: any[] = [];
    selectedMonth: any | null = null;
    months: any[] = [
        { id: '01', name: 'January' },
        { id: '02', name: 'February' },
        { id: '03', name: 'March' },
        { id: '04', name: 'April' },
        { id: '05', name: 'May' },
        { id: '06', name: 'June' },
        { id: '07', name: 'July' },
        { id: '08', name: 'August' },
        { id: '09', name: 'September' },
        { id: '10', name: 'October' },
        { id: '11', name: 'November' },
        { id: '12', name: 'December' }
    ];
    activeDates: number[] = [];
    inviteMemberId: number;
    isSimilar: boolean = false;
    //allFaqs: Faq[] = [];
    shuffledPlans: any[];
    subscriptionPlans: any[];
    IsQuesBtn: boolean = false;
    CountryCode: any;
    refreshCarousel: boolean = true;

    constructor(
        private _router: Router,
        private _loginService: LoginService,
        private _toastr: ToastrService,
        private loadScript: LoadScriptService,
        private subscriptionService: SubscriptionService,
        public translate: TranslateService,
        private http: HttpClient,
        public socialAuthService: AuthService,
        private _userService: UserService,
        private paypalService: PaypalService,
        public userService: UserService,
        private spinner: NgxSpinnerService,
        private reCaptchaV3Service: ReCaptchaV3Service,
        private configService: ConfigService,
        private NgxBraintreeService: NgxBraintreeService,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer
    ) {
        this.userDetails.languageCode = 'en';
        this.inviteUserDetails.languageCode = 'en';
        this.baseUrl = configService.getServiceBaseUrl();
        //this.inviteid = this.route.snapshot.paramMap.get('invitedid');
        this.mainUserId = this.route.snapshot.paramMap.get('id');
        // console.log(this.mainUserId);
        if (this.mainUserId) {
            var id = this.decodeShortGuidToId(this.mainUserId);
            this.inviteMemberId = id;
            //console.log(this.inviteMemberId);
            this.registerMemberUser();
        }
        //this.generateYearRange();
    }
    //When user clicks on FAQ it should scroll to down portion of Html
    @ViewChild('sectionNeedToScroll') sectionNeedToScroll: ElementRef;
    @ViewChild('premiumScoll') premiumScoll: ElementRef;
    @ViewChild('scrollToTop') scrollToTop: ElementRef;
    LoginForm: NgForm;
    // languageChange(language: string) {
    //     localStorage.setItem('locale', language);
    //     this.translate.use(language);
    // }
    sanitizeContent(content: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(content);
    }
    ngOnInit() {
        this.UserId = 0;
        this.loadScript.getServiceBaseUrl();
        this.getSubscriptionPlans();
        this.currentYear = new Date().getFullYear();
        //this.getAllCountry();
        this.getalluseremail();
        this.generateYearRange();
        // this.refreshCarousel = false;
        // setTimeout(() => {
        //     this.refreshCarousel = true;
        //     this.cdr.detectChanges();  // Ensure the change is reflected
        // }, 300);
        // this.getallartistemail();
        // this.getLanguages(this.UserId);
        // this.gettopthree();
        // this.GetWtComingUp();
        // this.GetPopularNow();
        // this.langcode = localStorage.getItem('languagecode');
        // this.themeidcode = localStorage.getItem('themeid');
        // this.translate.use(this.langcode);
        // this.getAboutUsByCode('ABOUT_TOBY_');
        const source = interval(600000);
        //const source = interval(90000);
        this.subscription = source.subscribe(val => this.opensnack());
        if (this.inviteid != "" && this.inviteid != undefined && this.inviteid != null) {
            this.GetInvitedUserById(this.inviteid);
        } else {
            this.inviteid = null;
        }
        $(document).ready(function () {
            $(".focusss").focus();
        })
        this.getAllFAQs();
        this.getAllCountry();

    }
    generateYearRange(): void {
        debugger;
        for (let year = this.currentYear; year >= this.startYear; year--) {
            this.years.push(year);
        }
    }
    updateDays() {
        debugger
        console.log('updateDays() called');
        const selectedYear = this.regyear;
        const selectedMonth = this.selectedmonth;
        this.generateActiveMonths(selectedYear);

        if (selectedYear && selectedMonth) {
            let daysInMonth;
            if (selectedMonth === '02') {
                // Check for leap year
                if ((selectedYear % 4 === 0 && selectedYear % 100 !== 0) || selectedYear % 400 === 0) {
                    daysInMonth = 29; // February has 29 days in a leap year
                } else {
                    daysInMonth = 28; // February has 28 days in a non-leap year
                }
            } else if (['04', '06', '09', '11'].includes(selectedMonth)) {
                daysInMonth = 30; // April, June, September, November have 30 days
            } else {
                daysInMonth = 31; // All other months have 31 days
            }
            this.days = Array.from({ length: daysInMonth }, (_, i) => (i + 1).toString().padStart(2, '0'));
            //console.log('Days:', this.days); // Log the days array
        } //else {
        //   this.days = [];
        // }
    }


    closeCookiePopup() {
        this.closeCookie = false;
    }

    checkForError($event, formValue) {
        if ($event != '') {

            this.setValidation(formValue, $event)
            if (formValue == 'countryError') {
                this.countryList = this.country.filter((res: any) => {
                    return (res.countryName.toLowerCase()).startsWith($event.toLowerCase());
                })
                this.highlightCountry.length = 0;
                this.indexForCountry = 0;
                if (this.countryList.length !== 0) {
                    this.searchCountry = true;
                } else {
                    this.searchCountry = false;
                }
            }

        } else {
            if (formValue == 'countryError') {
                this.countryList = [];
                this.searchCountry = false;

            }
            this.setValidationForError(formValue)
        }
    }

    setValidation(formValue, event) {
        if (formValue == 'userNameError') {
            this.userNameError = false;
        }
        if (formValue == 'emailIdError') {
            const validEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (validEmailRegEx.test(event)) {
                this.validEmail = false;
                this.emailIdError = false;

            } else {

                this.validEmail = true;
                this.emailIdError = true;
            }
            this.logindate = false;
        }
        if (formValue == 'passwordError') {
            this.passwordError = false;
        }
        if (formValue == 'countryError') {
            this.countryError = false;
        }

        //Validation for login
        if (formValue == 'loginUserNameError') {
            this.loginUserNameError = false;
        }
        if (formValue == 'loginPasswordError') {
            this.loginPasswordError = false;
        }
    }

    setValidationForError(formValue) {
        if (formValue == 'userNameError') {
            this.userNameError = true;
        }
        if (formValue == 'emailIdError') {
            this.emailIdError = true;
        }
        if (formValue == 'passwordError') {
            this.passwordError = true;
        }
        if (formValue == 'countryError') {
            this.countryError = true;
        }
        //Validation for login
        if (formValue == 'loginUserNameError') {
            this.loginUserNameError = true;
        }
        if (formValue == 'loginPasswordError') {
            this.loginPasswordError = true;
        }
    }

    //Send Id as 2 When User wants to login
    pressEnterUser(event) {
        if (event.keyCode === 13) {
            this.userLogIn();
        }
    }

    //Clear all Textbox
    clear() {
        this.userName = '';
        this.password = '';
        this.loginFailed = false;
    }

    clearemail() {
        this.Email = '';
        this.emailError = false;
        this.requiredError = false;
    }
    //User Login 
    userLogIn() {
        debugger
        this.spinner.show();
        if (this.userName == undefined || this.userName.trim() == "") {
            this.loginUserNameError = true;
        }
        if (this.password == undefined || this.password.trim() == "") {
            this.loginPasswordError = true;
        }
        if (this.password != undefined && this.userName != undefined) {
            this._loginService.userLogin(this.userName, this.password).subscribe((res: any) => {
                if (res.status == "200") {
                    debugger;
                    console.log(res.userDetails);
                    this.userDetails = res.userDetails;
                    this.plainid = this.userDetails.brainTreeCustomerId;
                    this.subStatus = this.userDetails.subscriptionStatus;
                    this.usertype = this.userDetails.userType;
                    // Logic for auto subscription

                    // this.daysLeft = res.userDetails.daysLeft;
                    // if (this.daysLeft <= 0) {
                    //     console.log(this.daysLeft);
                    //     this.userId = this.userDetails.userId;

                    //     this.paypalService.GenerateToken().subscribe((response: any) => {
                    //         console.log(response);
                    //         this.subscriptionService.getSubscriptionplan().subscribe((res: any) => {
                    //             this.subscriptionPlan = res;

                    //             //let nonce = 'tokencc_bh_h3gcdz_hpczfh_7b5y7d_58gtf7_g87';
                    //             let bpainid = this.subscriptionPlan.subscriptionDetails[0].brainTreePlanId;
                    //             let updateprice = this.subscriptionPlan.subscriptionDetails[0].price;
                    //             let BraintrreId = this.subscriptionPlan.subscriptionDetails[0].brainTreePlanId;
                    //             let chargeAmount = this.subscriptionPlan.subscriptionDetails[0].offeredprice;
                    //             const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
                    //             return this.http
                    //                 .post(this.baseUrl + '/api/Paypal/AutoSubscription', { chargeAmount: chargeAmount, Userid: this.userId, email: this.userDetails.email, bpainid: bpainid, BraintrreId: BraintrreId, updateprice: updateprice, braintreeSubscriptionId: this.userDetails.braintreeSubscriptionId, braintreecustomerId: this.userDetails.braintreeCustomerId, name: this.userDetails.fullName }, { 'headers': headers })
                    //                 .subscribe((response: any) => {
                    //                     var retrievedObject = localStorage.getItem('UserDetails');
                    //                     var jsObj = JSON.parse(retrievedObject);
                    //                     this.massage = 'Payment done Successfully';
                    //                     jsObj.braintreeSubscriptionId = response.subscriptionId;
                    //                     localStorage.setItem('UserDetails', JSON.stringify(jsObj))

                    //                     if (response == false) {
                    //                         // this._toastr.success('Pre-Approved Payment enabled PayPal account required for vaulting.')
                    //                         console.log('Pre-Approved Payment enabled PayPal account required for vaulting.');

                    //                         this.showHidePlan = true;
                    //                         this.showPaymentSection = false;
                    //                     }
                    //                     else {
                    //                         var retrievedObject = localStorage.getItem('UserDetails');
                    //                         var jsObj = JSON.parse(retrievedObject);
                    //                         this.massage = 'Payment done Successfully';
                    //                         jsObj.subscriptionStatus = "True";
                    //                         jsObj.braintreeSubscriptionId = response.subscriptionId;
                    //                         localStorage.setItem('UserDetails', JSON.stringify(jsObj))
                    //                         // this._toastr.success('Subscription Update done successfully.');
                    //                         console.log('Subscription Update done successfully.');

                    //                         // this._router.navigate([`/home`]);

                    //                     }
                    //                 },
                    //                     err => {
                    //                         // this._toastr.error('Something  Went Wrong ! Please Try Again Later');
                    //                         console.log('Something  Went Wrong ! Please Try Again Later');
                    //                         this.showHidePlan = true;
                    //                         this.showPaymentSection = false;

                    //                     });
                    //         });
                    //     })
                    // }
                    // Auto subscription logic ends
                    this.spinner.hide();
                    if (this.subStatus == null) {
                        localStorage.setItem('UserDetails', JSON.stringify(this.userDetails));
                        localStorage.setItem('themeId', JSON.stringify(this.userDetails.themeId));
                        this._router.navigate(['/toby-home']);
                    }
                    else if (this.subStatus == 'False' || this.subStatus == undefined) {
                        localStorage.setItem('UserDetails', JSON.stringify(this.userDetails));
                        localStorage.setItem('themeId', JSON.stringify(this.userDetails.themeId));
                        // this._router.navigate([`/home`]);
                        this._router.navigate([`/newhome`]);
                        this.userService.IsUser(true);
                        localStorage.setItem('Isuser', 'true');
                    }
                    else if (this.usertype == "USER") {
                        localStorage.clear();
                        localStorage.setItem('UserDetails', JSON.stringify(this.userDetails));
                        localStorage.setItem('themeId', JSON.stringify(this.userDetails.themeId));
                        // this._router.navigate([`/home`]);
                        this._router.navigate([`/newhome`]);
                        this.userService.IsUser(true);
                        localStorage.setItem('Isuser', 'true');

                    }
                    else {
                        this.userDetails.userName = '';
                        this.userDetails.email = '';
                        this.loginFailed = true;
                    }
                } else {
                    this.spinner.hide();
                    this.loginFailed = true;
                }
            }, err => { this.spinner.hide(); this._toastr.error(err) })
        }
    }

    lang = "sv";
    checkCapcha(): void {
        this.reCaptchaV3Service.execute(this.siteKey, 'homepage', (token) => {
            this.spinner.hide();
            console.log('this is token', token);
        });
    }

    onSubmit(f: NgForm) {
        debugger;
        console.log(f)
        this.spinner.show();
        if (f.form.invalid) {
            this.spinner.hide();
            return;
        } else if (f.form.controls.email.value !== f.form.controls.confirmEmail.value) {
            this._toastr.warning("Email and confirm email must be equal");
            this.spinner.hide();
            return;
        }
        else if (f.form.controls.password.value !== f.form.controls.confirmPassword.value) {
            this._toastr.warning("Password and confirm password must be equal");
            this.spinner.hide();
            return;
        }
        // else if (this.recaptcha == undefined) {
        //     this._toastr.warning("Please tick the captcha");
        //     this.spinner.hide();
        // }
        else {
            // this.checkCapcha();
            this.userDetails = new Users();
            const value = f.value;
            this.userDetails.fullName = value.username;
            this.userDetails.email = value.email;
            this.userDetails.password = value.password;
            this.userDetails.displayName = value.displayname;
            this.userDetails.gender = this.userGender == 0 ? 'Male' : this.userGender == 1 ? 'Female' : 'Non-Binary';
            if (value.day == undefined && this.selectedmonth == undefined && value.regyear == undefined) {
                this.userDetails.dateOfBirth = null;
            } else {
                this.userDetails.dateOfBirth = value.day + "/" + this.selectedmonth + "/" + value.regyear;
            }

            // this.userDetails.dateOfBirth = "03/11/1988";

            this.userDetails.userType = "USER";
            this.userDetails.provider = "Toby";
            this.userDetails.deviceType = "WebUser";
            this.userDetails.themeId = 1;
            this.userDetails.countryId = this.countryId;
            console.log(this.userDetails);
            console.log('planname1', this.planName);
            this._loginService.userRegistration(this.userDetails).subscribe((res: any) => {
                debugger;
                console.log('planname2', this.planName);
                if (res.status == "200" || res.status == "400") {
                    this.userDetails = res.userDetail;
                    localStorage.clear();
                    localStorage.setItem('UserDetails', JSON.stringify(this.userDetails));
                    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
                    this.userId = this.userDetails.userId;
                    this._toastr.success("You are successfully registered !");
                    res.status == "400" ? this._toastr.warning("Email not sent due to network error") : '';
                    this.showHideRegistraionPage = false;
                    this.showHidePlan = true;
                    this.spinner.hide();
                    if (this.planName == "Free Trial" || this.planName == "Toby Free") {
                        this.freetrial();
                    }
                    else {
                        this._loginService.change(this.planName);
                        this._router.navigate(['/toby-home']);
                    }
                } else if (res.status == 401) {
                    // this.logindate = true;
                    this._toastr.warning("Email is already exist!");
                    this.spinner.hide();
                }
            },
                err => {
                    this._toastr.error(err)
                    this.spinner.hide();
                });
        }

    }

    loginArtist() {
        this.scrollToTop.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        this._router.navigate(['/login/artist-joinus']);
    }

    pressEnterForResetPass(event) {
        if (event.keyCode === 13) {
            this.resetpassword();
        }
    }

    resetpassword() {
        this.spinner.show();
        if (!this.Email) {
            this.requiredError = true; // Set required error flag
            this.spinner.hide();
            return; // Exit the function if the email is empty
        } else {
            this.requiredError = false; // Clear required error flag
        }
        if (this.Email == null || this.Email == "" || this.Email == undefined || this.emailError == true) {
            if (this.Email == undefined || this.Email == "") {
                this.emailError = true;
                setTimeout(() => {
                    this.Email = "";
                }, 1000);
            }
            else if (this.Email != undefined || this.Email != "") {
                this.massage = 'Enter valid email';
                this.successmsg(this.massage);
            }
            this.spinner.hide();
        }
        else {
            this.Emaillist.forEach(element => {
                if (element.email == this.Email) {
                    this.inCorrectEmail = true;
                }
            });
            if (this.inCorrectEmail) {
                this._loginService.resetpassword(this.Email).subscribe((res: any) => {
                    if (res.status == 200) {
                        this.massage = 'Mail sent Successfully';
                        this.successmsg(this.massage);
                    } else {
                        this._toastr.error('Mail sending failed');
                    }
                    this.closeResetBtn.nativeElement.click();
                    this.Email = "";
                    this.inCorrectEmail = false;
                    this.spinner.hide();
                })
            }
            else {
                //this.closeResetBtn.nativeElement.click();
                this._toastr.error('Email is not Registered')
                this.spinner.hide();
            }
        }
    }
    successmsg(msg: string) {
        this._toastr.success(msg)
    }

    onCountryChange(event) {
        debugger;
        let countryID = event.target.value;
        // this.userDetails.countryId = countryID;
        this.countryId = countryID;
        let countryList: any = this.country.find(x => x.counrtyId == countryID);
        this.CountryCode = countryList.countryPhoneCode;
    }

    userCheckError(event) {
        debugger
        this.Email = event;
        const validEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!this.Email.trim()) {
            this.requiredError = true;
            this.emailError = false; // Clear email error
        } else {
            this.requiredError = false;


            if (!validEmailRegEx.test(this.Email)) {
                this.emailError = true; // Email is invalid, set email error
            } else {
                this.emailError = false;
            }

        }




    }

    getalluseremail() {
        this._loginService.getalluseremail().subscribe((data: any) => {
            this.Emaillist = data.users;
        });
    }

    public socialSignIn(socialPlatform: string) {
        let socialPlatformProvider;
        if (socialPlatform === 'facebook') {
            socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
        } else if (socialPlatform === 'google') {
            socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
        }
        console.log(socialPlatformProvider);
        debugger;
        this.socialAuthService.signIn(socialPlatformProvider).then(userData => {
            this.apiConnection(userData);
        },
            err => {
                this._toastr.error(err)
            });
    }
    apiConnection(data) {
        debugger;
        this.userDetails = new Users();
        this.userDetails.email = data.email;
        if (data.provider === "google") {
            this.userDetails.googleEmail = data.email;
        }
        else {
            this.userDetails.fbEmail = data.email;
        }
        this.userDetails.displayName = data.name;
        this.userDetails.provider = data.provider;
        this.userDetails.provider_id = data.id;
        this.userDetails.image = data.image;
        // this.userDetails.Token = data.token;

        this.userDetails.userType = "USER";
        this.userDetails.deviceType = "WebUser";
        this.userDetails.countryId = 1;
        this._loginService.postData(this.userDetails).subscribe((res: any) => {
            this.userDetails.userType = "USER";
            this.userDetails = res.userDetail;
            localStorage.clear();
            localStorage.setItem('UserDetails', JSON.stringify(this.userDetails));
            this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
            this.userId = this.userDetails.userId;
            this.subscriptionStatus = this.userDetails.subscriptionStatus;

            if (this.subscriptionStatus == "True") {
                // this._router.navigate([`/home`]);
                this.userService.IsUser(true);
                this._router.navigate([`/newhome`]);
            }
            else if (this.subscriptionStatus == "False") {
                // this._router.navigate([`/home`]);
                this.userService.IsUser(true);
                this._router.navigate([`/newhome`]);

            }
            else if (this.subscriptionStatus == null) {
                this._toastr.success("Your are successfully registered !");
                this.showHideRegistraionPage = false;
                this.showHidePlan = true;
                this._router.navigate(['/toby-home']);
            }
        }, err => {
            this._toastr.error(err)
        });
    }
    // openForm() {
    //     $(document).ready(function () {
    //         $(".autofocusss").focus();
    //     })

    //     document.getElementById("myForm").style.display = "block";
    // }
    // closeForm() {
    //     document.getElementById("myForm").style.display = "none";
    // }
    getContentByCode(Code) {
        this.lanCode = this.languageCode.toUpperCase()
        this.Code = Code + this.lanCode;
        this._userService.GetContentByCode(this.Code).subscribe((data: any) => {
            this.result = data.data.pageContent;
            this.terms = data.data.pageContentTitle;
        })
    }
    getAboutUsByCode(Code) {
        // this.lanCode = this.languageCode.toUpperCase()
        // this.Code = Code + this.lanCode;
        this._userService.GetContentByCode(Code).subscribe((data: any) => {
            this.abouttoby = data.data.pageContent;
            this.result = data.data.pageContent;
            this.terms = data.data.pageContentTitle;
            this.Toby = true;
            this.Communities = false;
            this.LegalInformation = false;
            this.heading = "Toby";
            this.term('ABOUT_TOBY_EN_LIGHT');
        })
    }

    term(Code) {

        if (Code == 'TOBY_WORK_' || Code == 'TOBY_CONTACT_' || Code == 'ABOUT_TOBY_EN_LIGHT') {
            this.Toby = true;
            this.Communities = false;
            this.LegalInformation = false;
            this.heading = "Toby";
        }
        if (Code == 'TOBY_MUSIC_CONDITIONS_AND_TERMS_OF_USE_' || Code == 'TOBY_MUSIC_PRIVACY_POLICY_' || Code == 'TOBY_MUSIC_COOKIE_POLICY_' || Code == 'TOBY_MUSIC_ADVERTISING_') {
            this.Toby = false;
            this.Communities = false;
            this.LegalInformation = true;
            this.heading = "Legal Information";
        }
        if (Code == 'TOBY_ARTISTS_' || Code == 'TOBY_INVESTORS_') {
            this.Toby = false;
            this.Communities = true;
            this.LegalInformation = false;
            this.heading = "Communities";
        }
        console.log(this.Toby, this.Communities, this.LegalInformation, "Tobyyyy")
        if (Code != 'ABOUT_TOBY_EN_LIGHT') {
            this.getContentByCode(Code);
        }
        if ('TOBY_MUSIC_CONDITIONS_AND_TERMS_OF_USE_' == Code) {
            this.termscondbt = true;
            this.termscondbtns = false;
            this.termscondbtcoks = false;
            this.termscondbtads = false;

        }
        else if ('TOBY_MUSIC_PRIVACY_POLICY_' == Code) {
            this.termscondbtns = true;
            this.termscondbt = false;
            this.termscondbtcoks = false;
            this.termscondbtads = false;

        }
        else if ('TOBY_MUSIC_COOKIE_POLICY_' == Code) {
            this.termscondbtcoks = true;
            this.termscondbtns = false;
            this.termscondbt = false;
            this.termscondbtads = false;

        }
        else if ('TOBY_MUSIC_ADVERTISING_' == Code) {
            this.termscondbtcoks = false;
            this.termscondbtns = false;
            this.termscondbt = false;
            this.termscondbtads = true;

        }
        else if ('TOBY_ARTISTS_' == Code) {
            this.termscondbtart = true;
            this.termscondbtinv = false;
        }
        else if ('TOBY_INVESTORS_' == Code) {
            this.termscondbtinv = true;
            this.termscondbtart = false;
        }
        else if ('ABOUT_TOBY_EN_LIGHT' == Code) {
            this.termscondbtabt = true;
            this.termscondbtwork = false;
            this.termscondbtcontact = false;
        }
        else if ('TOBY_WORK_' == Code) {
            this.termscondbtabt = false;
            this.termscondbtwork = true;
            this.termscondbtcontact = false;
        }
        else if ('TOBY_CONTACT_' == Code) {
            this.termscondbtabt = false;
            this.termscondbtwork = false;
            this.termscondbtcontact = true;
        }
        else {
            this.termscondbt = false;
            this.termscondbtns = false;
            this.termscondbtcoks = false;
            this.termscondbtads = false;

        }
    }

    dateofbirthvalidation(e, type) {
        console.log(e.target.value); debugger
        if (type == 'day') {
            if (e.target.value.length > 1) {
                //error
                this.dayError = true;
                // this._toastr.warning("Please select a valid date");
                // let str = e.target.value.substring(0, e.target.value.length - 1);
                //this.LoginForm.form.setValue({ 'day': str });
            } else {
                //this.LoginForm.form.setValue({ 'day': e.target.value });
            }
        } else if (type == 'year') {
            const yr = Number(e.target.value)
            // if(yr<1900 || yr>2024){
            //     this._toastr.warning("Year must be between 1900 and 2024");  
            // }

            if (e.target.value.length > 4) {
                this.yearError = true;
                // this._toastr.warning("Please select a valid year");
                // let str = e.target.value.substring(0, e.target.value.length - 1);
                //this.LoginForm.form.setValue({ 'year': str });
            } else {
                //this.LoginForm.form.setValue({ 'year': e.target.value });
            }
        }
    }
    validateYear(year: NgModel) {
        debugger
        const value = year.value;
        const numValue = parseInt(value, 10);
        if (isNaN(numValue)) {
            year.control.setErrors({ 'pattern': true });
        } else if (numValue < 1900 || numValue > this.currentYear) {
            year.control.setErrors({ 'range': true });
        } else {
            year.control.setErrors(null);
        }
    }

    // IsCheckLang() {
    //     if (this.translate.currentLang == 'ar') {
    //         return true;

    //     } else if (this.translate.currentLang == 'as') {
    //         return true;
    //     }
    //     else {
    //         return false;
    //     }
    // }
    Checked(value) {
        this.isChecked = value;
    }
    // Changetheme() {
    //     if (this.userDetails.themeId == '2') {
    //         this.theme1 = false;
    //         return true;

    //     }
    //     else {
    //         this.theme1 = true;
    //         return false;
    //     }
    // }
    login() {
        this.scrollToTop.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        this.loginPage = true;
        this.registerPage = false;
        this.indexPage = false;
        this.invitedUserRegisterPage = false;
    }
    register(e) {
        debugger;
        // this.scrollToTop.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.registerPage = true;
        this.loginPage = false;
        this.indexPage = false;
        this.invitedUserRegisterPage = false;
        this.planName = e;

        // if(e == 'Free trial'){
        //     this.planName = 'Toby Free';
        // }
        // else{

        // }
    }
    tobyMain() {
        this.registerPage = false;
        this.loginPage = false;
        this.indexPage = true;
        this.invitedUserRegisterPage = false;
    }
    registerInvitUser() {
        this.scrollToTop.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        this.invitedUserRegisterPage = true;
        this.registerPage = false;
        this.loginPage = false;
        this.indexPage = false;
    }

    registerMemberUser() {
        // this.scrollToTop.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.invitedUserRegisterPage = false;
        this.memberUserRegisterPage = true;
        this.registerPage = false;
        this.loginPage = false;
        this.indexPage = false;
    }

    faq() {
        this.sectionNeedToScroll.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    premium() {
        this.premiumScoll.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    showAll() {
        this.showAllQuestions = !this.showAllQuestions;

        this.showAllQuestions ? this.queLabel = "See less questions" : this.queLabel = "See all questions";

    }
    opensnack() {
        // I've just commented this so that you're not bombarded with an alert.

        var Details = JSON.parse(localStorage.getItem('UserDetails'));
        if (Details) {
            if(Details.userType = "USER"){
                this._loginService.CheckMultidevices(Details.userId, Details.sessionId)
                .subscribe((res: any) => {
                    this.IsValid = res.isValid;
                    console.log(res.data.noOfDevice);
                    if (res.data.isValid == 0) {
                        if(res.data.noOfDevice){
                            alert(`You are allowed to login with ${res.data.noOfDevice} device only`);
                        }
                        // else if(res.data.noOfDevice == 3){
                        //     alert(`You are allowed to login with ${res.data.noOfDevice} device(s) only`);
                        // }
                        // else{
                        //     alert(`You are allowed to login with ${res.data.noOfDevice} device only`);
                        // }
                        // if (Details.subscriptionPlanId == '3' || Details.subscriptionPlanId == '5') {
                        //     alert("You are allowed to login with 5 device only");
                        // } else {
                        //     alert("You are allowed to login with single device only");

                        // }

                        localStorage.setItem('themeid', this.userDetails.themeId);
                        localStorage.setItem('languagecode', this.userDetails.languageCode);
                        //this.translate.use(this.userDetails.languageCode);
                        this.translate.use("en");
                        localStorage.clear();
                        this._router.navigate(['/login']);
                        localStorage.removeItem("UserDetails");
                    }
                })
            }
            

        }

    }

    GetInvitedUserById(inviteid) {
        this.spinner.show();
        this._loginService.GetInvitedUserById(inviteid).subscribe((res: any) => {
            if (res.isSuccess == true) {
                this.inviteUser = res.inviteUser;
                this.receiverEmail = this.inviteUser.receiverEmail;
                this.registerInvitUser();
                this.spinner.hide();
            } else {
                this.spinner.hide();
                this._router.navigate([`/login`]);
            }
        },
            err => {
                this._toastr.error(err)
                this.spinner.hide();
            });
    }

    onSubmitInvited(fi: NgForm) {
        debugger
        this.spinner.show();
        if (fi.form.invalid) {
            this.spinner.hide();
            return;
        } else if (fi.form.controls.password.value !== fi.form.controls.confirmPassword.value) {
            this._toastr.warning("Password and confirm password must be equal");
            this.spinner.hide();
            return;
        }
        else if (this.recaptcha == undefined) {
            this._toastr.warning("Please tick the captcha");
            this.spinner.hide();
        }
        else {
            this.inviteUserDetails = new InviteUsers();
            const value = fi.value;
            this.inviteUserDetails.invitedId = this.inviteid;
            this.inviteUserDetails.fullName = value.username;
            this.inviteUserDetails.email = this.receiverEmail;
            this.inviteUserDetails.password = value.password;
            this.inviteUserDetails.gender = this.userGender == 0 ? 'Male' : this.userGender == 1 ? 'Female' : 'Non-Binary';
            if (value.day == undefined && this.selectedmonth == undefined && value.year == undefined) {
                this.inviteUserDetails.dateOfBirth = null;
            } else {
                this.inviteUserDetails.dateOfBirth = value.day + "/" + this.selectedmonth + "/" + value.year;
            }
            this.inviteUserDetails.userType = "USER";
            this.inviteUserDetails.provider = "Toby";
            this.inviteUserDetails.deviceType = "WebUser";
            this.inviteUserDetails.themeId = 1;
            this._loginService.invitedUserRegistration(this.inviteUserDetails).subscribe((res: any) => {
                if (res.status == "200" || res.status == "400") {
                    this.userDetails = res.userDetail;
                    this.plainid = this.userDetails.brainTreeCustomerId;
                    this.subStatus = this.userDetails.subscriptionStatus;
                    this.usertype = this.userDetails.userType;
                    res.status == "400" ? this._toastr.warning("You are successfully registered but Email not sent due to network error") : this._toastr.success("You are successfully registered !");
                    localStorage.clear();
                    localStorage.setItem('UserDetails', JSON.stringify(this.userDetails));
                    localStorage.setItem('themeId', JSON.stringify(this.userDetails.themeId));
                    // this._router.navigate([`/home`]);
                    this._router.navigate([`/newhome`]);
                } else if (res.status == 401) {
                    this._toastr.warning("Email is already exist !");
                    this.spinner.hide();
                } else if (res.status == 402) {
                    this._toastr.warning("Inactive parent user please contact your parent user !");
                    this.spinner.hide();
                }
            },
                err => {
                    this._toastr.error(err)
                    this.spinner.hide();
                });
        }
    }

    getAllFAQs() {
        debugger
        this._loginService.getAllFaqs().subscribe((faqs: any) => {
            faqs.faqDetails.forEach(faq => {
                if (faq.faqType == "Web player") {
                    this.allFaqs.push(faq)
                }
            })
            if (this.allFaqs.length > 6) {
                this.IsQuesBtn = true;
            }

        });


    }

    freetrial() {
        debugger;
        this.b = 0;
        this.userDetails.subscriptionPlans[0].brainTreePlanId;

        this.subcriptiondetails.UserId = this.userId;
        this.subcriptiondetails.UserId = this.userId;

        this.subcriptiondetails.BrainTreePlanId = this.userDetails.subscriptionPlans[0].brainTreePlanId;
        this.subcriptiondetails.PayableAmount = this.userDetails.subscriptionPlans[0].price;

        // this.showskip = true;
        // this.showbraintree = false;
        // this.showpaywith = false;
        console.log('planname1', this.planName);
        this.subscriptionService.addsubscriptiondetails(this.subcriptiondetails).subscribe((data: any) => {
            console.log(data);
            console.log('planname2', this.planName);
            var retrievedObject = localStorage.getItem('UserDetails');
            var jsObj = JSON.parse(retrievedObject);
            jsObj.subscriptionPlanId = data.subscriptionDetails.subscriptionPlanId;
            localStorage.setItem('UserDetails', JSON.stringify(jsObj))
            this._router.navigate(['/newhome']);
            this.userService.IsUser(true);
            localStorage.setItem('Isuser', 'true');

        });
    }


    onSubmitMember(fm: NgForm) {
        this.spinner.show();
        if (fm.form.invalid) {
            this.spinner.hide();
            return;
        } else if (fm.form.controls.password.value !== fm.form.controls.confirmPassword.value) {
            this._toastr.warning("Password and confirm password must be equal");
            this.spinner.hide();
            return;
        }
        // else if (this.recaptcha == undefined) {
        //     this._toastr.warning("Please tick the captcha");
        //     this.spinner.hide();
        // }
        else {
            this.userMemberDetails = new UserMember();
            const value = fm.value;
            this.userMemberDetails.parentId = this.inviteMemberId;
            this.userMemberDetails.fullName = value.username;
            this.userMemberDetails.displayname = value.displayname;
            this.userMemberDetails.email = value.email;
            this.userMemberDetails.password = value.password;
            this.userMemberDetails.gender = this.userGender == 0 ? 'Male' : this.userGender == 1 ? 'Female' : 'Non-Binary';
            if (value.day == undefined && this.selectedmonth == undefined && value.regyear == undefined) {
                this.userMemberDetails.dateOfBirth = null;
            } else {
                this.userMemberDetails.dateOfBirth = value.day + "/" + this.selectedmonth + "/" + value.regyear;
            }
            this.userMemberDetails.userType = "USER";
            this.userMemberDetails.provider = "Toby";
            this.userMemberDetails.deviceType = "WebUser";
            this.userMemberDetails.themeId = 1;
            console.log(this.userMemberDetails);
            this._loginService.userMemberRegistration(this.userMemberDetails).subscribe((res: any) => {
                if (res.status == "200" || res.status == "400") {
                    this.userDetails = res.userDetail;
                    this.plainid = this.userDetails.brainTreeCustomerId;
                    this.subStatus = this.userDetails.subscriptionStatus;
                    this.usertype = this.userDetails.userType;
                    res.status == "400" ? this._toastr.warning("You are successfully registered but Email not sent due to network error") : this._toastr.success("You are successfully registered !");
                    localStorage.clear();
                    localStorage.setItem('UserDetails', JSON.stringify(this.userDetails));
                    localStorage.setItem('themeId', JSON.stringify(this.userDetails.themeId));
                    // this._router.navigate([`/home`]);
                    this.userService.IsUser(true);
                    localStorage.setItem('Isuser', 'true');
                    this._router.navigate([`/newhome`]);
                } else if (res.status == 401) {
                    this._toastr.warning("Email is already exist !");
                    this.spinner.hide();
                } else if (res.status == 402) {
                    this._toastr.warning("Inactive parent user please contact your parent user !");
                    this.spinner.hide();
                }
            },
                err => {
                    this._toastr.error(err)
                    this.spinner.hide();
                });
        }
    }

    onMonthChange(event: any) {
        const selectedMonthId = parseInt(event.target.value, 10);
        this.generateActiveDates(selectedMonthId);
    }

    generateActiveMonths(year) {
        debugger;
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth(); // 0-indexed, so January is 0, February is 1, etc.

        if (parseInt(year) === currentYear) {
            this.activeMonths = this.months.slice(0, currentMonth + 1); // Include current month
        } else {
            this.activeMonths = [...this.months]; // All months
        }
    }

    // formatDate(date: number): string {
    //     return date < 10 ? `0${date}` : `${date}`;
    //   }

    generateActiveDates(monthId: number) {
        const currentDate = new Date();
        const selectedYear = parseInt((document.getElementById('year-select') as HTMLSelectElement).value, 10);
        const isCurrentMonth = selectedYear === currentDate.getFullYear() && monthId === currentDate.getMonth() + 1;
        const daysInMonth = new Date(selectedYear, monthId, 0).getDate();

        this.activeDates = [];
        for (let i = 1; i <= daysInMonth; i++) {
            if (isCurrentMonth && i > currentDate.getDate()) {
                break;
            }
            this.activeDates.push(this.formatDate(i));
        }
    }

    formatDate(date: number): any {
        return date < 10 ? `0${date}` : `${date}`;
    }

    decodeShortGuidToId(shortGuid: string): number {
        const idHex = shortGuid.split('-')[2]; // Extract the last part of the short GUID
        return parseInt(idHex, 16); // Convert hex back to integer
    }
    shufflePlans() {
        debugger;
        const order = ['Free Trial', 'Toby Premium', 'Toby Duo', 'Toby Family']; // Desired order of plan names
        this.shuffledPlans = order.map(name => this.subscriptionPlans.find(plan => plan.planName === name));
    }
    getSubscriptionPlans() {
        this.subscriptionService.getSubscriptionplan().subscribe((subscriptions: any) => {
            this.subscriptionPlans = subscriptions.subscriptionDetails;
            this.shufflePlans();
            console.log(this.shuffledPlans, this.subscriptionPlans)
        })
    }

    // getAllFAQs() {
    //     this._homeService.getAllFaqs().subscribe((faqs: any) => {
    //         this.allFaqs = faqs.faqDetails
    //         console.log(this.allFaqs)
    //     });
    //  }
    checkDisplayNameSimilar() {
        this._userService.checkDisplayName(this.userDetails.displayName).subscribe(res => {
            console.log(res);
            if (res.isSuccess == true) {
                this.isSimilar = true;
            }
            else {
                this.isSimilar = false;
            }
        })
    }

    getAllCountry() {
        this._loginService.getAllCountries().subscribe((data: any) => {
            this.country = data.country;
        })
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: Event) {
        debugger;
        const screenWidth = (event.target as Window).innerWidth;   
        console.log(screenWidth);
        // this.refreshCarousel = false; // Trigger change detection to update view
        // setTimeout(() => {
        //     this.refreshCarousel = true;
        //     this.cdr.detectChanges();  // Ensure the change is reflected
        // }, 300);
    }

}
