import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { LoadScriptService } from 'src/app/loadScript.service';
import { HomeService } from "../Shared/home.service";
import { Router, ActivatedRoute, Params } from '@angular/router';
import { PlayerService } from '../Shared/player.service';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { Track } from '../../../Player/ngx-audio-player/src/lib/model/track.model'
import { Playlist } from 'src/app/models/playlist';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { UserService } from 'src/app/Shared/user.service';
import { ArtistsService } from '../Shared/artists.service';
import { AlbumService } from '../Shared/album.service';
import { SongsService } from '../Shared/songs.service';

@Component({
  selector: 'app-artist-or-albumpages',
  templateUrl: './artist-or-albumpages.component.html',
  styleUrls: ['./artist-or-albumpages.component.css']
})
export class ArtistOrAlbumpagesComponent implements OnInit {

  userDetails: any;
  Id: any;
  image: any;
  name: any;
  date: any;
  imagel: any;
  path:any;
  selectedName: any;
  songdata: any;
  pageIndex: any = 1;
  pageSize: any = 10;
  IsActiveSongId: any;
  totalcount: any;
  userFullname: any;
  userImage: any;

  constructor(
    private loadScript: LoadScriptService,
    public homeService: HomeService,
    private route: ActivatedRoute,
    private playerService: AudioPlayerService,
    private _playerService: PlayerService,
    private toastr: ToastrService,
    public location: Location,
    private router: Router,
    public userService: UserService,
    private _artistsService: ArtistsService,
    private albumService: AlbumService,
    private _songsService: SongsService
  ) {
    debugger;
    this.route.paramMap.subscribe(params => this.selectedName = params.get('album'));
    console.log(this.selectedName);

    this.Id = this.route.snapshot.paramMap.get("id");
    this.getdata(this.selectedName);
    this.playerService.getIsblogorCountryScreen().subscribe(value => {
      //console.info('san: ' + value);
      this.IsActiveSongId = value;
      // this.GetAlllikedSongs();
    });
  }

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.userFullname = this.userDetails["fullName"];
    this.userImage = this.userDetails["image"];
  }

  getArtist(artistid) {
    this.selectedName = "Artist";
    this._artistsService.getArtistDetails(artistid).subscribe((data: any) => {
      console.log('getArtistdata', data);
      this.image = data.path.concat(data.artistDetails.thumbImage);
      this.imagel = data.path.concat(data.artistDetails.largeImage);
      this.name = data.artistDetails.fullName;
      this.date = data.artistDetails.createdOn;
    })
  }

  getdata(Name) {
    debugger;
    if (Name == "Album") {
      // this.getAlbum(this.Id);
      this.GetSongsDetailsbyalbumid(this.Id);
    }
    else {
      this.getArtist(this.Id);
    }
  }

  // getAlbum(albumId) {
  //   this.albumService.GetAlbumsbyalbum(albumId).subscribe((data: any) => {
  //     console.log('getalbdata', data)
  //     this.image=this.imagel="";
  //     if(data.albumDetails.thumbImage!=null){
  //     this.image = data.path.concat(data.albumDetails.albumId, '/', data.albumDetails.thumbImage);
  //     }
  //     if(data.albumDetails.thumbImageNew!=null){
  //       this.imagel = data.path.concat(data.albumDetails.albumId, '/', data.albumDetails.thumbImageNew);
  //     }
  //     this.name = data.albumDetails.title;
  //     this.date = data.albumDetails.createdOn;
  //     console.log(this.image,this.imagel)
  //   })
  // }

  GetSongsDetailsbyalbumid(album: any) {
    debugger;
    this._songsService.getSongsByAlbumId(this.userDetails.userId,album, this.pageIndex, this.pageSize).subscribe((data: any) => {
      // this.totalcount = data.count;
      console.log('chkalbumssong',data);
      this.path=data.path;
      this.totalcount = data.count;
      this.songdata = data.songs;
      // this.playalbumSong(this.songdata, index);
      // this.songurls = 
    });
  }
  playAllsongs(items) {
    debugger;
    this.CheckSubscription(items, 0);
  }
  CheckSubscription(song, i) {
    debugger;
    this.playSong(song, i);
    // if (this.substatus == "False") {

    //     document.getElementById("Subscriptioncheck").style.display = "block";
    //     //this.router.navigate(['/account/upgradeplan']);
    // }
    // else {
    //     this.songIsthere = true;

    // }
  }

  playSong(songs, index) {
    // this._playerService.msaapPlaylist = [];
    // for (var i = 0; i < songs.length; i++) {
    //   const tractObj = new Track();
    //   tractObj.index = i;
    //   tractObj.image = songs[i].thumbImage;
    //   tractObj.artistName = songs[i].artistName;
    //   tractObj.link = songs[i].streamUrl;
    //   tractObj.title = songs[i].title;
    //   tractObj.songId = songs[i].songId;
    //   this._playerService.msaapPlaylist.push(tractObj);
    // }
    this._playerService.playSongs(songs);
    if (this.playerService.repeatSong) {
      this.playerService.repeatSong = false;
    }
    this.playerService.setPlaylist(this._playerService.msaapPlaylist);
    this.playerService.selectATrack(index + 1);
    this._playerService.updateData(this._playerService.msaapPlaylist);
  }


}
