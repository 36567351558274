import { Component, OnInit } from '@angular/core';
import { Users } from 'src/app/models/users';
import { HomeService } from '../../Shared/home.service';
import { Role } from '../../models/role';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { Track } from '../../../../Player/ngx-audio-player/src/lib/model/track.model';
import { PlayerService } from 'src/app/Shared/player.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/Shared/user.service';
import { Artist } from 'src/app/models/artist';
import { LoadScriptService } from 'src/app/loadScript.service';
import { ArtistsService } from 'src/app/Shared/artists.service';
import { SongsService } from 'src/app/Shared/songs.service';

@Component({
  selector: 'app-artistpage',
  templateUrl: './artistpage.component.html',
  styleUrls: ['./artistpage.component.css']
})
export class ArtistpageComponent implements OnInit {

  userDetails = new Users();
  userImage: boolean = true;
  playListDetails: any;
  //Songs Details To play
  songDetails = new Track();
  showHideSettingTab: boolean = false;
  showSettingTab: boolean = true;
  showPlayList: boolean = true;
  shownestingset: boolean = false;
  role: any;
  userLoggedIn: boolean = false;
  artistLoggedIn: boolean = false;
  superAdminLoggedIn: boolean = false;
  path: any;
  playlistid: void;
  index = 0;
  playd = [];
  imgURL: any;
  playListLatest: any;
  theme1: boolean = true;
  showview: boolean;
  type: any;
  languageCode: any;
  theme: any;
  IsAddlUser: boolean = false;

  //
  ArtistDetails = new Artist();
  artistdata: any;

  fullname;
  halfName: any;
  description;
  emailid;
  MobileNumber;
  fileToUpload: File;
  noImage: boolean = true;
  languages: any;
  languageId;
  ArtistId: any = 0;
  showalbum = false;
  showdashboard = true;
  noOfSongTtlPlayCount: number = 0;
  ttlFollowers: number = 0;
  artistalbumcounts: number = 0;
  totalsongcount: number = 0;
  noofLikes: number = 0;
  nooffav: number = 0;
  mostPlayedAlbum : any;
  countMostPlayedAlbum: number = 0;
  totalSongsLengthHours: number = 0;

  isActive1 : boolean = false;
  isActive2 : boolean = false;
  isActive3 : boolean = false;
  isActive4 : boolean = false;
  isActive5 : boolean = false;
  isActive6 : boolean = false;
  isActive7 : boolean = false;
  isActive8 : boolean = false;
  isActive9 : boolean = false;
  todoListArtist: any;
  todoListTotal: any;
  todoListCompleted: any;
  todoListArtistIsActive: any;
  Isnotification = false;

  constructor(private _homeService: HomeService,
    private playerService: AudioPlayerService,
    private artistservice: ArtistsService,
    private _songsService: SongsService,
    private _playerService: PlayerService, private router: Router,
    public translate: TranslateService, public userService: UserService,
    private loadScript: LoadScriptService,
  ) {
    // this._homeService.allPlayList.subscribe((res: any) => {
    //   if (res.length == 0) {
    //     this._homeService.playId = [];
    //   } else {
    //     this._homeService.playId = res;
    //   }
    // })
  }

  ngOnInit() {
    this.activeMenu(localStorage.getItem('ActiveMenu'));
    this.ArtistDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.ArtistId = this.ArtistDetails["artistId"];
    this.loadScript.getServiceBaseUrl();
    this.getDashboardArtistStatus();
    this.GetArtistDetails(this.ArtistDetails.artistId);
    this.getalbumbyartistId();
    this.ArtistSongsById();
    this.GetTodoListArtistById();
    this.artistservice.getIsblogorCountryScreen().subscribe(value => {
      this.Isnotification = value;
      this.GetTodoListArtistById();
    });
    this._homeService.share.subscribe(x =>
      this.playListLatest = x)
      this.playListLatest = this.playListLatest;
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));

    this.role = this.userDetails.userType;
    if (this.role == Role.user) {
      this.userLoggedIn = true;
    } else if (this.role === Role.superAdmin) {
      this.superAdminLoggedIn = true;
    } else {
      this.artistLoggedIn = true;
      this.imgURL = this.userDetails["thumbImage"];
    }
    if(this.userDetails.image == null) {
      this.userImage = false;
      this._homeService.profileImage = this.userDetails.image;
    }
    else if (this.userDetails.image != null) {
      this.userImage = true;
      this._homeService.profileImage = this.userDetails.image;
    }
    if (this.userDetails["thumbImage"] != null) {
      this.userImage = true;
      this._homeService.profileImage = this.userDetails.image;
    }

    // if (this.userLoggedIn) {
    //   this._homeService.getPlaylist(this.userDetails.userId).subscribe((data: any) => {
    //     this.playListDetails = data.playListDetails;
    //     if (this.playListDetails.length >= 5) {
    //       for (var i = this.index; i < 5; i++) {
    //         this._homeService.playId[i] = this.playListDetails[i];
    //       }
    //     } else if (this.playListDetails.length != 0) {
    //       for (var i = this.index; i < this.playListDetails.length; i++) {
    //         this._homeService.playId[i] = this.playListDetails[i];
    //       }
    //     } else {
    //       this._homeService.playId.length = 0;
    //     }
    //   })
    // }
    this.languageCode = this.userDetails.languageCode;
    this.theme = this.userDetails.themeId;
    // let subs = this.userDetails.subscriptionPlans.filter((res: any) => {
    //   if(res.planCode == "MON_ADD_USER"){
    //     return res;
    //   }
    // });
    // if(this.userDetails.subscriptionPlanId == null || this.userDetails.subscriptionPlanId == "" || this.userDetails.subscriptionPlanId == subs[0].subscriptionPlanId || this.userDetails.subscriptionPlanId == subs[0].subscriptionPlanId.toString()){
    //   this.IsAddlUser = true;
    // } else {
    //   this.IsAddlUser = false;
    // }
  }

  // viewallsongs(playlistId: number, playListName: string) {
  //   this._homeService.playListArray = [];
  //   this._homeService.playlistName = playListName;

  //   localStorage.setItem('service', JSON.stringify(this._homeService.playlistName));
  //   this._homeService.playlist(playlistId, this.userDetails.userId).subscribe((Response: any) => {

  //     let test = Response.playlistDetails;
  //     for (var a = 0; a < Response.playlistDetails.length; a++) {
  //       this._homeService.playListArray.push(Response.playlistDetails[a]);
  //     }
  //     this.router.navigate(['/playlist/allplaylistsongs'], { queryParams: { Id: playlistId } });
  //   })

  // }
  // playSong(songs) {

  //   var getPlayList = this.playerService.getPlaylist();
  //   var index = getPlayList.findIndex(x => x.link == this.songDetails.link);

  //   this.songDetails.image = this.path + songs.thumbImage;
  //   this.songDetails.index = 1;
  //   this.songDetails.link = songs.streamUrl;
  //   this.songDetails.title = songs.title;
  //   this.songDetails.artistName = songs.artistName;
  //   this._playerService.updateData(this.songDetails);
  //   if (index == -1) {
  //     getPlayList.push(this.songDetails);
  //     this.playerService.setPlaylist(getPlayList);
  //     this.playerService.selectATrack(getPlayList.length);
  //   }
  //   else {
  //     this.playerService.selectATrack(index);
  //   }
  // }
  // showHideSetting() {
  //   this.showSettingTab = !this.showSettingTab;
  //   this.showPlayList = true;

  // }
  // showHidePlayList() {
  //   this.showPlayList = !this.showPlayList;
  //   this.showSettingTab = true;

  // }
  // showHidenestingset() {
  //   this.showHideSettingTab = !this.showHideSettingTab;

  // }
  // closeAllTabs() {
  //   this.showSettingTab = true;
  //   this.showPlayList = true;
  // }
  // IsCheckLang() {
  //   if (this.translate.currentLang == 'ar') {
  //     return true;

  //   } else if (this.translate.currentLang == 'as') {
  //     return true;
  //   }
  //   else {
  //     return false;
  //   }
  // }
  // Changetheme() {
  //   if (this.userDetails.themeId == '2') {
  //     this.theme1 = false;
  //     return true;

  //   }
  //   else {
  //     this.theme1 = true;
  //     return false;
  //   }
  // }
  // homelogo() {
  //   this.type = this.router.routerState.snapshot.url;
  //   if (this.type != '/home') {
  //     this.router.navigate(['/home']);
  //   }

  // }
  GetArtistDetails(artistId) {
    this.ArtistDetails.artistId = artistId;
    this.artistservice.getArtistDetails(this.ArtistDetails.artistId).subscribe((data: any) => {
      this.artistdata = data;
      this.path = data.path;
      this.fullname = data.artistDetails.fullName;
      this.halfName = data.artistDetails.fullName.split(' ')[0];
      this.description = data.artistDetails.description;
      this.emailid = data.artistDetails.emailId;
      this.MobileNumber = data.artistDetails.mobileNumber;
      this.imgURL = data.path.concat(data.artistDetails.largeImage);
    })
  }

  getalbumbyartistId() {
    this._songsService.GetAllAbumbs(this.ArtistId).subscribe((data: any) => {
      this.artistalbumcounts = data.albumDetails.length;
    });
  }

  ArtistSongsById() {
    this._homeService.ArtistSongsById(this.ArtistId).subscribe((data: any) => {
      this.totalsongcount = data.artistsongs.length;
    });
  }

  activeMenu(val){
    localStorage.setItem('ActiveMenu',val);
    const value = localStorage.getItem('ActiveMenu');
    if(value == "1"){
      this.showalbum = false;
      this.showdashboard = true;
      this.isActive1 = true;
      this.isActive2 = false;
      this.isActive3 = false;
      this.isActive4 = false;
      this.isActive5 = false;
      this.isActive6 = false;
      this.isActive7 = false;
      this.isActive8 = false;
      this.isActive9 = false;
    } else if(value == "2"){
      this.showalbum = true;
      this.showdashboard = false;
      this.isActive1 = false;
      this.isActive2 = true;
      this.isActive3 = false;
      this.isActive4 = false;
      this.isActive5 = false;
      this.isActive6 = false;
      this.isActive7 = false;
      this.isActive8 = false;
      this.isActive9 = false;
    } else if(value == "3"){
      this.showalbum = true;
      this.showdashboard = false;
      this.isActive1 = false;
      this.isActive2 = false;
      this.isActive3 = true;
      this.isActive4 = false;
      this.isActive5 = false;
      this.isActive6 = false;
      this.isActive7 = false;
      this.isActive8 = false;
      this.isActive9 = false;
    } else if(value == "4"){
      this.showalbum = false;
      this.showdashboard = true;
      this.isActive1 = false;
      this.isActive2 = false;
      this.isActive3 = false;
      this.isActive4 = true;
      this.isActive5 = false;
      this.isActive6 = false;
      this.isActive7 = false;
      this.isActive8 = false;
      this.isActive9 = false;
    } else if(value == "5"){
      this.showalbum = false;
      this.showdashboard = true;
      this.isActive1 = false;
      this.isActive2 = false;
      this.isActive3 = false;
      this.isActive4 = false;
      this.isActive5 = true;
      this.isActive6 = false;
      this.isActive7 = false;
      this.isActive8 = false;
      this.isActive9 = false;
    } else if(value == "6"){
      this.showalbum = false;
      this.showdashboard = true;
      this.isActive1 = false;
      this.isActive2 = false;
      this.isActive3 = false;
      this.isActive4 = false;
      this.isActive5 = false;
      this.isActive6 = true;
      this.isActive7 = false;
      this.isActive8 = false;
      this.isActive9 = false;
    } else if(value == "7"){
      this.showalbum = false;
      this.showdashboard = true;
      this.isActive1 = false;
      this.isActive2 = false;
      this.isActive3 = false;
      this.isActive4 = false;
      this.isActive5 = false;
      this.isActive6 = false;
      this.isActive7 = true;
      this.isActive8 = false;
      this.isActive9 = false;
    }
    else if(value == "8"){
      this.showalbum = false;
      this.showdashboard = true;
      this.isActive1 = false;
      this.isActive2 = false;
      this.isActive3 = false;
      this.isActive4 = false;
      this.isActive5 = false;
      this.isActive6 = false;
      this.isActive7 = false;
      this.isActive8 = true;
      this.isActive9 = false;
    }
       else {
      this.showalbum = false;
      this.showdashboard = true;
      this.isActive1 = false;
      this.isActive2 = false;
      this.isActive3 = false;
      this.isActive4 = false;
      this.isActive5 = false;
      this.isActive6 = false;
      this.isActive7 = false;
      this.isActive8 = false;
      this.isActive9 = true;
    }
    
  }

  getDashboardArtistStatus() {
    this._homeService.getDashboardArtistStatus(this.ArtistId).subscribe((data: any) => {
      if(data.isSuccess){
        if (data.das !== null) {
          this.noOfSongTtlPlayCount = data.das.totalSongs;
          this.ttlFollowers = data.das.totalFollowers;
          this.artistalbumcounts = data.das.totalAlbums;
          this.totalsongcount = data.das.totalSongPlayCount;
          this.noofLikes = data.das.totalLikes;
          this.nooffav = data.das.totalFav;
          this.mostPlayedAlbum = data.das.mostPlayedAlbum;
          this.countMostPlayedAlbum = data.das.countMostPlayedAlbum;
          this.totalSongsLengthHours = data.das.totalSongsLengthHours;
        }
      }
    });
  }

  GetTodoListArtistById() {
    this.artistservice.GetTodoListArtistById(this.ArtistId).subscribe((data: any) => {
        this.todoListArtist = data.todoList;
        this.todoListTotal = this.todoListArtist.filter(x => x.isCompleted == false).length;
        this.todoListCompleted = this.todoListArtist.filter(x => x.isCompleted).length;

    });
  }

  logOut() {
    localStorage.clear();
    this.router.navigate(['/artist-main-page']);
  }

  notClick(){
    debugger;
    this.artistservice.IsblogorCountryScreen(false);
  }


}
