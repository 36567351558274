export class Howto {
    public HowToId: any;
    public tittle: string;
    public description: string;
    public streamUrl: any;
    public length: any;
    Base64DataForVideo: any;
    VideoName: any;
    VideoType: any;
    public createdBy: any;
    public updatedBy: any;
    lengthString: any;
    public userType:string;
}