import { Component, OnInit } from '@angular/core';
import { LoadScriptService } from 'src/app/loadScript.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoginService } from 'src/app/Shared/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/Shared/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit {

  userName;
  password;
  ConfirmPassword;
  sub: any;
  id: any;
  Id: any;
  massage: string;
  Email: any;

  constructor(private loadScript: LoadScriptService, private route: ActivatedRoute,
    private _router: Router,
    private _loginService: LoginService,
    private _toastr: ToastrService,
    private userService: UserService,
    private formBuilder: FormBuilder, public translate: TranslateService
  ) {
    let url = this._router.url
    let subStringData = url.substr(14);

    this.Id = subStringData;
    this.id = this.Id.slice(1);
  }

  ngOnInit() {
  }


  onSubmit() {
    if (this.password == this.ConfirmPassword) {
      this._loginService.forgetpassword(this.id, this.password, this.ConfirmPassword).subscribe((res: any) => {
        this.massage = 'Password changed successfully';
        this.successmsg(this.massage);
        this.clear();
        this._router.navigate([`/home`]);
      })

    }

    else {
      this.massage = 'Password does not match';
      this.successmsg(this.massage);
    }
  }


  clear() {
    this.userName = "";
    this.password = "";
    this.ConfirmPassword = "";
  }
  cancel() {
    this.password = "";
    this.ConfirmPassword = "";
  }
  successmsg(msg: string) {
    this._toastr.success(msg)
  }
}
