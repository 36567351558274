import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AlbumService } from 'src/app/Shared/album.service';
import { ArtistsService } from 'src/app/Shared/artists.service';
import { PaginationServiceForAlbum } from 'src/app/Shared/paginationserviceForAlbum.service';
import { UserService } from 'src/app/Shared/user.service';
import { LoadScriptService } from 'src/app/loadScript.service';
import { UserHomeBanner } from 'src/app/models/users';


@Component({
  selector: 'app-home-page-banner',
  templateUrl: './home-page-banner.component.html',
  styleUrls: ['./home-page-banner.component.css']
})
export class HomePageBannerComponent implements OnInit {
  bannerDetails: any;
  imageType: string;
  homeBanner = new UserHomeBanner();

  constructor(private loadScript: LoadScriptService,
    private router: Router,
    private albumService: AlbumService, private spinner: NgxSpinnerService,
    private _toastr: ToastrService, private route: ActivatedRoute,
    public paginationService: PaginationServiceForAlbum, public translate: TranslateService,
    private _artistsService: ArtistsService, private userService: UserService) { }

  ngOnInit() {
    this.getallbannerdetails();
  }

  add() {
    this.router.navigate(['/admin/home-page-banner/add-update-homebanner']);
  }

  getallbannerdetails() {
    this.userService.getAllBannerDetails().subscribe((data: any) => {
      this.bannerDetails = data.bannerDetails;
      console.log(data);
    });
  }

  editbanner(homebannerId) {
    this.router.navigate([`/admin/home-page-banner/update-homebanner/` + homebannerId + ``]);
  }

  deleteSection(item) {
    debugger;
    if (confirm('Please note! Are you sure you want to delete Home Banner?')) {
      this.userService.deleteHomeBanner(item).subscribe((response) => {

        if (response.isSuccess) {
          this._toastr.success(response.message);
          this.getallbannerdetails();
        }
      }, err => {
        this._toastr.error(err)
        this.spinner.hide();
      });
    }
  }

}
