import { Component, OnInit } from '@angular/core';
import { Artist } from '../../../models/artist';
import { ArtistsService } from 'src/app/Shared/artists.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Users } from '../../../models/users';
import { Approvalstatus } from 'src/app/models/approvalstatus';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { PaginationService } from 'src/app/Shared/pagination.service';
@Component({
  selector: 'app-add-or-update-artist',
  templateUrl: './add-or-update-artist.component.html',
  styleUrls: ['./add-or-update-artist.component.css']
})
export class AddOrUpdateArtistComponent implements OnInit {
  imgURL: any;
  fileToUpload: File;
  artisttitle: any = "Add Artist";
  artistImage: any;
  noImage: boolean = true;
  artistDetails = new Artist();
  addArtistProfile: boolean = true;
  path: any;
  isChecked: boolean = true;
  isSelected:boolean;
  userDetails = new Users();
  timg: any;
  limg: any;
  files: any;
  songlangugae: any;
  constructor(
    private artistService: ArtistsService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    public translate: TranslateService,
    private spinner: NgxSpinnerService) {
    this.artistDetails.artistId = this.route.snapshot.paramMap.get("id");
  }

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    if (this.artistDetails.artistId != null) {
      this.artisttitle = "Update Artist";
      this.addArtistProfile = false;
      this.artistService.getArtistDetails(this.artistDetails.artistId).subscribe((data: any) => {
        console.log('getdata' , data)
        this.imgURL = data.path.concat(data.artistDetails.largeImage);
        if(data.artistDetails.isforKid == true){
          this.isSelected = true;
        }
        else{
          this.isSelected = false;
        }

        if (data.artistDetails.statusId == Approvalstatus.APPROVED) {
          this.isChecked = true;
        }
        else {
          this.isChecked = false;
        }

        this.artistDetails = data.artistDetails;
        if (data.artistDetails.largeImage != null) {
          this.limg = data.artistDetails.largeImage.split('/');
          this.limg = this.limg[this.limg.length - 1];
          this.artistDetails.largeImage = this.limg;
        }
        //this.isChecked = data.artistDetails.isApproved;
        if (data.artistDetails.thumbImage != null) {

          this.timg = data.artistDetails.thumbImage.split('/');
          this.timg = this.timg[this.timg.length - 1];
          this.artistDetails.thumbImage = this.timg;
          this.noImage = false;
        } else {
          this.noImage = true;
        }

        this.path = data.path;
      }, err => {
        this.toastr.error(err)
        this.spinner.hide();
      })
    } else {
      this.artistDetails.artistId = 0;
    }
    this.getsongLanguage();
  }

  onSubmit() {
    this.spinner.show();
    if (this.isChecked) {
      this.artistDetails.statusId = Approvalstatus.APPROVED;
    }
    else {
      this.artistDetails.statusId = Approvalstatus.SUBMIT;
    }
    if (this.noImage) {
      this.artistDetails.base64Data = null;
    } else {
      this.artistDetails.base64Data = this.imgURL.split(",")[1];
    }
    if (this.userDetails["artistId"] != undefined) {
      this.artistDetails.createdBy = this.userDetails["artistId"];
      this.artistDetails.updatedBy = this.userDetails["artistId"];
    }
    if (this.userDetails["userId"] != undefined) {
      this.artistDetails.createdBy = this.userDetails["userId"];
      this.artistDetails.updatedBy = this.userDetails["userId"];
    }
    console.log('submit' , this.artistDetails)
    this.artistService.addOrUpdateArtist(this.artistDetails).subscribe((res: any) => {
      if (res.status == 200) {
        if (this.artistDetails.artistId == 0) {
          this.toastr.success("Artist added successfully");
          this.spinner.hide();
        } else {
          this.toastr.success("Artist updated successfully");
          this.spinner.hide();

        }

        this.router.navigate(['/admin/artist']);
      }
    }, err => {
      this.toastr.error(err)
      this.spinner.hide();
    })
  }

  //Go to Artist List
  back() {
    this.router.navigate(['/admin/artist']);
  }

  Checked(value) {
    this.isChecked = value;
  }

  //is for kid
  Checkedkid(event){
    this.artistDetails.isforKid = event.target.checked;
    //console.log(';;', this.artistDetails.isforKid)
  }

  selectPhoto(file: FileList) {
    this.artistDetails.thumbImage = file.item(0).name;
    this.fileToUpload = file.item(0);
    var File = this.fileToUpload.name;
    this.artistDetails.fileName = File.split('.')[0];
    this.files = File.split('.');
    this.artistDetails.fileType = this.files[this.files.length - 1];
    //this.artistDetails.FileType = File.split('.')[1];
    this.noImage = false;
    //Show Image Preview
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imgURL = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);

  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.artistDetails.base64Data = btoa(binaryString);
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  getsongLanguage() {
    this.artistService.GetsongLanguage().subscribe((data: any) => {
      this.songlangugae = data.languages;
    });
  }

}
