import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Suggestionstatus } from '../../../models/suggestionstatus';
import { UserService } from 'src/app/Shared/user.service';
import { Suggestion } from 'src/app/models/suggestion';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { Users } from '../../../models/users';
@Component({
  selector: 'app-update-suggestionstatus',
  templateUrl: './update-suggestionstatus.component.html',
  styleUrls: ['./update-suggestionstatus.component.css']
})
export class UpdateSuggestionstatusComponent implements OnInit {
  suggestionId: any;
  status: any;
  suggestiondetails = new Suggestion();
  sgstatus: any;
  sgstatusForDone: Suggestionstatus;
  sgstatusForHol: Suggestionstatus;
  userDetails = new Users();
  constructor(private route: ActivatedRoute, private userService: UserService, private toastr: ToastrService, public location: Location, private _router: Router) {
    this.suggestionId = this.route.snapshot.paramMap.get("id");
  }

  ngOnInit() {
    this.GetsugestionById(this.suggestionId)
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
  }
  GetsugestionById(SuggetionId: string) {
    this.userService.GetsugestionById(SuggetionId).subscribe((data: any) => {
      this.suggestiondetails = data.suggestiondetail;
    })
  };
  onSubmit() {
    if (this.userDetails.userId != undefined) {
      this.suggestiondetails.updatedBy = this.userDetails.userId;
    }
    this.userService.addupdatesuggestion(this.suggestiondetails).subscribe((res: any) => {
      if (res.status == 200) {
      }
    })
    this.toastr.success("Status Updated Successsfully");
    this._router.navigate(['/admin/admin-suggestion']);
  }
  cancel() {
    this._router.navigate(['/home']);
  }
}
