import { Component, OnInit } from '@angular/core';
import { LoadScriptService } from '../../loadScript.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SongsService } from '../../Shared/songs.service';
import { ArtistsService } from '../../Shared/artists.service';
import { ToastrService } from 'ngx-toastr';
import { AlbumService } from 'src/app/Shared/album.service';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Artist } from '../../models/artist';
import { LanguageService } from 'src/app/Shared/language.service';
import { TranslateService } from '@ngx-translate/core';
import { Users } from '../../models/users';
import { HomeService } from 'src/app/Shared/home.service';

@Component({
  selector: 'app-edit-artist-profile',
  templateUrl: './edit-artist-profile.component.html',
  styleUrls: ['./edit-artist-profile.component.css']
})
export class EditArtistProfileComponent implements OnInit {

  artistId: any;
  timg: any;
  limg: any;
  noImage: boolean = true;
  path: any;
  imgURL: any;
  artistDetails = new Artist();
  languages: any;
  languageCode;
  fileToUpload: File;
  UserId: any;
  userDetails = new Users();
  songlangugae: any;

  artistalbumcounts:any;
  totalsongcount:any;
  noofLikes:any;
  nooffav:any;
  ArtistId: any = 0;
  Action: string;

  constructor(private artistService: ArtistsService, private albumService: AlbumService, private _router: Router, private loadScript: LoadScriptService, private _songsService: SongsService, private _artistsService: ArtistsService, private formBuilder: FormBuilder, private toastr: ToastrService
    , private route: ActivatedRoute, private _languageService: LanguageService,
    public translate: TranslateService,private homeService: HomeService) { }

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.artistId = this.userDetails.artistId;
    this.ArtistId = this.userDetails["artistId"];
    this.UserId = 0;
    this.getLanguages(this.UserId);
    if (this.artistId != null) {
      this.getalbumbyartistId();
      this.ArtistSongsById();
      this.GetArtistDetails();
      this.artistService.getArtistDetails(this.artistId).subscribe((data: any) => {
        console.log("data.artistDetails", data.artistDetails);
        this.imgURL = data.path.concat(data.artistDetails.largeImage);
        this.artistDetails = data.artistDetails;
        if (data.artistDetails.largeImage != null) {
          this.limg = data.artistDetails.largeImage.split('/');
          this.limg = this.limg[this.limg.length - 1];
          this.artistDetails.largeImage = this.limg;
        }
        //this.isChecked = data.artistDetails.isApproved;
        if (data.artistDetails.thumbImage != null) {

          this.timg = data.artistDetails.thumbImage.split('/');
          this.timg = this.timg[this.timg.length - 1];
          this.artistDetails.thumbImage = this.timg;
          this.noImage = false;
        } else {
          this.noImage = true;
        }
        this.path = data.path;
      }, err => { this.toastr.error(err) })
    }
    this.getsongLanguage();
  }

  onSubmit() {
    if (this.noImage) {
      this.artistDetails.base64Data = null;
    } else {
      this.artistDetails.base64Data = this.imgURL.split(",")[1];
    }
    this.artistService.addOrUpdateArtist(this.artistDetails).subscribe((res: any) => {
      if (res.status == 200) {
        res.artistDetails.thumbImage = res.path.concat(res.artistDetails.artistId, '/', res.artistDetails.thumbImage)
        this.userDetails = res.artistDetails;
        this.toastr.success("Artist Updated successfully");
        localStorage.clear();
        localStorage.setItem('UserDetails', JSON.stringify(this.userDetails));
        this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
        this.Action = "Profile Edited"
        this.ArtistAudit(this.Action, this.userDetails.fullName);
        // this._router.navigate([`/admin/artist/profile`]);
        //this._router.navigate(['/home']);
      }
    }, err => { this.toastr.error(err) })
  }
  getLanguages(UserId) {
    this._languageService.getLanguages(UserId).subscribe((data: any) => {
      this.languages = data.languageDetails;
    }, err => { this.toastr.error(err) });
  }
  selectPhoto(file: FileList) {
    this.artistDetails.thumbImage = file.item(0).name;
    this.fileToUpload = file.item(0);
    var File = this.fileToUpload.name;
    this.artistDetails.fileName = File.split('.')[0];
    this.artistDetails.fileType = File.split('.')[1];
    this.noImage = false;
    //Show Image Preview
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imgURL = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);

  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.artistDetails.base64Data = btoa(binaryString);
  }
  cancel() {
    this._router.navigate([`/artist/artist-newprofile`]);
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  getsongLanguage() {
    this.artistService.GetsongLanguage().subscribe((data: any) => {
      this.songlangugae = data.languages;
    });
  }

  getalbumbyartistId() {
    this._songsService.GetAllAbumbs(this.ArtistId).subscribe((data: any) => {
      this.artistalbumcounts = data.albumDetails.length;
      console.log('albumartist', this.artistalbumcounts);
    });
  }

  ArtistSongsById() {
    this.homeService.ArtistSongsById(this.ArtistId).subscribe((data: any) => {
      this.totalsongcount = data.artistsongs.length;
    });

  }

  GetArtistDetails() {
    this._artistsService.getArtistDetails(this.ArtistId).subscribe((data: any) => {
      this.noofLikes = data.artistDetails.noofLikes;
      this.nooffav = data.artistDetails.noofFavs;
      // this.imgURL= data.artistDetails.largeImage;
    })
  }

  ArtistAudit(action, artistname) {
    debugger;
    var Data = {
      "ArtistId": this.ArtistId,
      "Type": action,
      "FieldName": "Artist" + " " + artistname,
      "CreatedBy": this.ArtistId,
    };
    console.log("chk", Data);
    this.artistService.ArtistAudit(Data).subscribe((res: any) => {
      console.log(res);
    });
  }


}
