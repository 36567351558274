import { Component, OnInit } from '@angular/core';
import { LoadScriptService } from 'src/app/loadScript.service';
import { HomeService } from "../../Shared/home.service";
import { LoginService } from "../../Shared/login.service";
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Users } from 'src/app/models/users';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Country } from '../../models/country';
import { ConfigService } from 'src/app/app.config';
import { UserService } from 'src/app/Shared/user.service';
import * as $ from 'jquery';
import { Miniuser } from 'src/app/models/miniuser';
@Component({
  selector: 'app-editUserprofile',
  templateUrl: './editUserprofile.component.html',
  styleUrls: ['./editUserprofile.component.css']
})
export class EditUserprofileComponent implements OnInit {
  UserForm: FormGroup;
  ProfileIdUpdate = 0;
  userdata = new Users();
  fullName;
  city;
  email;
  image;
  MobileNumber;
  password;
  userdata1;
  userId;
  massage: string;
  submitted = false;
  userDetails: any;
  country: Array<Country> = [];
  startyear: any;
  Years: any[] = [];
  Index: any = 0;
  countryId: any;
  gender: any;
  dob: any;
  CountryCode: any;
  IsMobileReq: boolean;
  currentYear = new Date().getFullYear();
  startYear = 1900;
  years: number[] = [];
  isChecked: boolean = false;
  userImage: boolean;
  editUserName: boolean = false;
  path: any;
  parentId: any;
  toggleSaveButton() {
    this.isChecked = !this.isChecked;
  }
  fileToUpload: File;
  userdatalist = new Miniuser();
  noImage: boolean = true;
  // days: string[] = Array.from({ length: 31 }, (_, i) => (i + 1).toString().padStart(2, '0'));
  days: string[] = [];
  selectedGender: string;
  constructor(private toastr: ToastrService,
    private router: Router,
    private loadScript: LoadScriptService, public translate: TranslateService, public userService: UserService,
    private loginService: LoginService, private homeService: HomeService, private formbulider: FormBuilder, private configService: ConfigService) {
    this.startyear = configService.getstartDate();
    this.generateYearRange();
    // this.updateDays();
  }

  AddUser(Users: Users) {
    debugger;
    Users.userId = this.ProfileIdUpdate;
    let day = this.UserForm.get('day').value;
    let month = this.UserForm.get('month').value;
    let year = this.UserForm.get('year').value;
    
    Users.dateOfBirth = day + '/' + month + '/' + year;
    Users.displayName = Users.fullName;
    //console.log('editd' , Users)
    this.loginService.userRegistration(Users).subscribe(
      (data: any) => {
        console.log('profiledata', data);
        if (this.ProfileIdUpdate == 0) {
        }
        else {
          this.userDetails.image = data.userDetail.image;
          localStorage.setItem('UserDetails', JSON.stringify(this.userDetails));
          this.massage = 'Profile Updated Successfully';
          this.userDetails.fullName = Users.fullName;
          this.UserEdit(this.userDetails.userId);
        }
        this.successmsg(this.massage);
        // this.router.navigate(['/account/user-account']);
      });
  }
  successmsg(msg: string) {
    this.toastr.success(msg)
  }
  // get f() { return this.UserForm.controls; }
  onFormSubmit() {
    debugger
    this.editUserName = false;
    if (!this.isChecked) {
      this.submitted = true;
      this.toastr.error("Please Accept Privacy Policy");
      return;
    }

    this.submitted = true;


    if (this.UserForm.invalid) {
      return;
    }
    // let MobileNo = this.CountryCode + this.UserForm.controls['mobileNo'].value;
    // this.UserForm.controls['mobileNo'].setValue(MobileNo);
    const user = this.UserForm.value;
    console.log(user)
    this.AddUser(user);
    // this.userDetails.fullName = user.fullName;
    //this.userDetails.displayName = user.fullName;
    localStorage.setItem('UserDetails', JSON.stringify(this.userDetails));
    this.homeService.userInitials = this.getInitials(user.fullName)
  }
  get f() { return this.UserForm.controls; }
  UserEdit(Userid: string) {
    debugger;
    this.homeService.GetUserDetails(Userid).subscribe((user: any) => {
      this.userdata = user.userDetail;
      this.ProfileIdUpdate = this.userdata.userId;
      this.fullName = this.userdata.fullName;
      this.email = this.userdata.email;
      this.MobileNumber = this.userdata.mobileNo;
      this.CountryCode = this.userdata.countryCode;
      this.image = this.userdata.image;
      console.log(this.userdata);
      if (this.image == null || this.image=="") {
       // this.userImage = false;
      this.image="../../../assets/images/NoUserFound.png";
      }
      else if (this.image != null) {
        this.userImage = true;
      }
      if (this.userdata.countryId == 3) {
        this.MobileNumber = this.MobileNumber ? this.MobileNumber.substring(2) : this.MobileNumber;
      } else {
        this.MobileNumber = this.MobileNumber ? this.MobileNumber.substring(3) : this.MobileNumber;
      }
      this.city = this.userdata.city;
      this.countryId = this.userdata.countryId;
      if(this.userdata.gender==null || this.userdata.gender==""){
       this.gender=undefined;
      }
      else{
        this.gender = this.userdata.gender;
      }
      //this.gender = this.userdata.gender?this.userdata.gender:0;
      debugger
      if (this.userdata.dateOfBirth) {
        let day = this.userdata.dateOfBirth.split('/')[0];
        let month = this.userdata.dateOfBirth.split('/')[1];
        let year = this.userdata.dateOfBirth.split('/')[2];
        this.UserForm.controls['day'].setValue(day);
        if (month != 'undefined') {
          this.UserForm.controls['month'].setValue(month);
        }
        year == "" ? this.UserForm.controls['year'].setValue(null) : this.UserForm.controls['year'].setValue(year);
        this.updateDays();
      }
      this.UserForm.controls['fullName'].setValue(this.fullName);
      // this.UserForm.controls['city'].setValue(this.city);
      this.UserForm.controls['email'].setValue(this.email);
      // this.UserForm.controls['mobileNo'].setValue(this.MobileNumber);
      this.UserForm.controls['countryId'].setValue(this.countryId);
      this.UserForm.controls['gender'].setValue(this.gender);
      console.log(this.UserForm, this.userdata)
    });
  }

  ngOnInit() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.UserForm = this.formbulider.group({
      fullName: ['', Validators.required],
      // city: ['', Validators.required],
      email: ['', Validators.required],
      // mobileNo: ['', Validators.required],
      countryId: ['', Validators.required],
      gender: ['', Validators.required],
      month: [''],
      day: [''],
      year: [null]
    })
    // this.dropdownyear();

    this.IsMobileReq = this.userdata.isMobileReq;
    this.loadScript.getServiceBaseUrl();
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    let id = this.userDetails.userId;
    this.parentId = this.userDetails.parentId;
    this.UserEdit(id);
    this.translate.use(this.userDetails.languageCode);
    this.getAllCountry();

    $(document).ready(function () {
      $(".focus").focus();
    })
  }

  cancel() {
    // this.router.navigate([`/account/profile`]);
    this.router.navigate(['/account/user-account']);
  }

  getAllCountry() {
    this.loginService.getAllCountries().subscribe((data: any) => {
      this.country = data.country;
      console.log('cnry',data.country);
      
    })
  }

  onCountryChange(e) {
    let countryID = e.target.value;
    this.UserForm.patchValue({ 'countryId': countryID });
    let countryList: any = this.country.find(x => x.counrtyId == countryID);
    this.CountryCode = countryList.countryPhoneCode;
  }
  dropdownyear() {
    this.startyear = 1900;
    let endYear = new Date().getFullYear();
    let year = endYear - this.startyear;
    for (let i = 0; i <= year; i++) {
      this.Years[this.Index] = this.startyear + i;
      this.Index++;

    }
    this.Years = this.Years.reverse();
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  generateYearRange(): void {
    for (let year = this.currentYear; year >= this.startYear; year--) {
      this.years.push(year);
    }
  }

  updateDays() {
    debugger
    console.log('updateDays() called');
    const selectedYear = this.UserForm.get('year').value;
    const selectedMonth = this.UserForm.get('month').value;

    if (selectedYear && selectedMonth) {
      let daysInMonth;
      if (selectedMonth === '02') {
        // Check for leap year
        if ((selectedYear % 4 === 0 && selectedYear % 100 !== 0) || selectedYear % 400 === 0) {
          daysInMonth = 29; // February has 29 days in a leap year
        } else {
          daysInMonth = 28; // February has 28 days in a non-leap year
        }
      } else if (['04', '06', '09', '11'].includes(selectedMonth)) {
        daysInMonth = 30; // April, June, September, November have 30 days
      } else {
        daysInMonth = 31; // All other months have 31 days
      }
      this.days = Array.from({ length: daysInMonth }, (_, i) => (i + 1).toString().padStart(2, '0'));
      console.log('Days:', this.days); // Log the days array
    } else {
      this.days = [];
    }
  }



  // selectPhoto(file: FileList) {
  //   this.userDetails.image = file.item(0).name;
  //   this.fileToUpload = file.item(0);
  //   var File = this.fileToUpload.name;
  //   var filetype = File.split('.');
  //   this.userdatalist.fileName = File.split('.')[0];
  //   filetype.forEach(element => {
  //     this.userdatalist.fileType = element;
  //   });


  //   //this.userdatalist.fileType = File.split('.');
  //   this.noImage = false;
  //   //Show Image Preview
  //   //this.file[x].name.split('.')[0]
  //   var reader = new FileReader();
  //   reader.onload = (event: any) => {
  //     this.image = event.target.result;
  //     this.homeService.profileImage = this.image;
  //     this.uploadphoto();
  //   }
  //   reader.readAsDataURL(this.fileToUpload);

  // }

  selectPhoto(file: FileList) {
    debugger;
    // Get the selected file
    this.fileToUpload = file.item(0);

    // Get the file name and extension
    var File = this.fileToUpload.name;
    var filetype = File.split('.').pop().toLowerCase();

    // Check if the file type is valid
    if (filetype !== 'jpg' && filetype !== 'jpeg' && filetype !== 'png') {
      this.toastr.error('Invalid file type. Only JPG, JPEG, and PNG files are allowed.');
      return;
    }

    // Proceed with the valid file
    this.userDetails.image = File;
    this.userdatalist.fileName = File.split('.')[0];
    this.userdatalist.fileType = filetype;

    this.noImage = false;

    // Show Image Preview
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.image = event.target.result;
      this.homeService.profileImage = this.image;
      this.uploadphoto();
    }
    reader.readAsDataURL(this.fileToUpload);
  }


  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.userdatalist.base64String = btoa(binaryString);
  }

  uploadphoto() {
    debugger
    if (this.noImage) {
      this.userdatalist.base64String = null;
    } else {
      this.userdatalist.base64String = this.image.split(",")[1];
    }
    this.userId = this.userDetails.userId;
    this.userdatalist.userId = this.userId;
    this.homeService.uploadphoto(this.userdatalist).subscribe((data: any) => {
      this.image = data.userProfile.fileName

      let userDetails = JSON.parse(localStorage.getItem('UserDetails'));
      userDetails.image = this.image;
      localStorage.setItem('UserDetails', JSON.stringify(userDetails));

      if (this.image == null) {
        this.userImage = false;
      }
      else if (this.image != null) {
        this.userImage = true;
      }
      this.toastr.success("Profile Image uploaded successfully.");
      this.userdata = data;
      this.path = data.path;
    });
  }

  uploadPic() {
    const fileInput = document.getElementById('fileInput');
    if (fileInput) fileInput.click();

  }

  deleteProfileImage() {
    debugger
    this.userService.deleteProfileImage(this.userDetails.userId).subscribe((data: any) => {
      if (data.isSuccess == true) {
        this.toastr.success("Profile image deleted successfully.");
        this.image = null;
        this.UserEdit(this.userDetails.userId);
        let userDetails = JSON.parse(localStorage.getItem('UserDetails'));
        // userDetails.image = this.image;
        // localStorage.setItem('UserDetails', JSON.stringify(userDetails));
        userDetails.image = this.image;
        localStorage.setItem('UserDetails', JSON.stringify(userDetails));
        this.homeService.profileImage = null;
      }
    })
  }
  openEditUser() {
    this.UserEdit(this.userDetails.userId);
    this.getAllCountry();
    this.editUserName = true;
  }

  getInitials(fullName: string): string {
    debugger;
    if (!fullName) {
      return '';
    }

    const names = fullName.trim().split(' ');

    if (names.length === 1) {
      return names[0].charAt(0).toUpperCase();
    }

    const firstNameInitial = names[0].charAt(0).toUpperCase();
    const lastNameInitial = names[1] ? names[1].charAt(0).toUpperCase() : '';

    return firstNameInitial + lastNameInitial;
  }
}
