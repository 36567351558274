import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from '@ngx-translate/core';
import { LoadScriptService } from 'src/app/loadScript.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-artist-main-page',
  templateUrl: './artist-main-page.component.html',
  styleUrls: ['./artist-main-page.component.css']
})
export class ArtistMainPageComponent implements OnInit {

  showAllQuestions: boolean = false;
  queLabel = "See all questions";
  constructor( public translate: TranslateService,private loadScript: LoadScriptService,private spinner: NgxSpinnerService, private _router: Router,) {
    this.spinner.show();
   }

  ngOnInit() {
    this.translate.use("en");
  }

  showAll() {
    this.showAllQuestions = !this.showAllQuestions;

    this.showAllQuestions ? this.queLabel = "See less questions" : this.queLabel = "See all questions";

}

tobyMain(){
  this._router.navigate(['/artist-main-page']);
}

jointeam(){
  this._router.navigate(['/artist-register']);
}



}
