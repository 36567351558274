import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/Shared/user.service';

@Component({
  selector: 'app-user-problems-orsuggestions',
  templateUrl: './user-problems-orsuggestions.component.html',
  styleUrls: ['./user-problems-orsuggestions.component.css']
})
export class UserProblemsORsuggestionsComponent implements OnInit {
  userProblemOrSuggestions: any;
  fileType: any;
  problemSuggestionId: any;
  subject: any;
  UserEmail: any;
  description: any;
  isSubmited: boolean = false;
  IsTicketStatus: any;
  allUserProblemOrSuggestions: any;
  isclosed: boolean = false;
  Checkrecords:boolean=false;

  constructor(private toastr: ToastrService, private router: Router, public userService: UserService,) { }

  ngOnInit() {
    this.getAlluserProblemOrSuggestions();
  }

  getAlluserProblemOrSuggestions() {
    this.userService.GetAllUserProblemorSuggestion().subscribe((data: any) => {
      this.allUserProblemOrSuggestions = data.userProblemsSuggestions;
      this.userProblemOrSuggestions = data.userProblemsSuggestions.filter(x => x.isTicketStatus == true);
      this.IsTicketStatus = true;
      if(!this.userProblemOrSuggestions.length){
        this.Checkrecords=true;
      }
      //this.IsTicketStatus = 
      console.log(data);
    })
  }

  Change(e) {
    if (e.target.value == "true") {
      this.userProblemOrSuggestions = this.allUserProblemOrSuggestions.filter(x => x.isTicketStatus == true);
      this.isclosed = false;
    }
    else {
      this.userProblemOrSuggestions = this.allUserProblemOrSuggestions.filter(x => x.isTicketStatus == false);
      this.isclosed = true;
    }
    if(!this.userProblemOrSuggestions.length){
      this.Checkrecords=true;
    }
    else{
      this.Checkrecords=false;
    }
  }

  Downloadfile(imgtype: any) {
    if (imgtype != null) {
      let type = [];
      // type = this.albumd.path.concat(this.albumDetails.albumId, '/', this.albumd.albumDetails.thumbImage).split(/[\s.]+/);
      // this.fileType = type[type.length - 1];
      type = imgtype.thumbImage.split(/[\s.]+/);
      this.fileType = type[type.length - 1];
      const linkSource = `data:application/${this.fileType};base64,` + imgtype.thumbImageBytes;
      const downloadLink = document.createElement("a");
      const fileName = "Problems or Suggestions" + "." + this.fileType;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }
  getFileType(url: any): string {
    const parts = url.split('.');
    const extension = parts[parts.length - 1].toLowerCase();
    return extension;
  }

  onEmailOpen(item) {
    this.problemSuggestionId = item.problemOrSuggestionId;
    this.subject = item.problemOrSuggessionTypeName;
    this.UserEmail = item.email;
    console.log(this.problemSuggestionId);
  }

  respondBack() {
    this.problemSuggestionId = 0;
    //this.respondMessage = this.message;
    // this.userService.updateProblemSuggestionRespond(this.problemSuggestionId, this.UserEmail, this.subject, this.description).subscribe((res: any) => {
    //   console.log(res);
    //   if (res.isSuccess == true) {
    //     this.toastr.success('Respond Back successfully');
    //   }
    // });

    this.isSubmited = false
    if (this.description == null || this.description == undefined || this.description == "") {
      this.isSubmited = true;
      return;
    }
    else {
      this.isSubmited = false;
      this.userService.updateProblemSuggestionRespond(this.problemSuggestionId, this.UserEmail, this.subject, this.description).subscribe((res: any) => {
         console.log(res);
        if (res.isSuccess == true) {
          this.toastr.success('Respond Back successfully');
          this.isSubmited = false;
          this.OnClose();
        }
        else{
          this.toastr.error(res.message);
          //this.isSubmited = true;
          this.OnClose();
        }
      });
    }
  }

  closeTicket() {
    this.isSubmited = false
    if (this.description == null || this.description == undefined || this.description == "") {
      this.isSubmited = true;
      return
    }
    else {
      this.isSubmited = false;
      this.userService.updateProblemSuggestionRespond(this.problemSuggestionId, this.UserEmail, this.subject, this.description).subscribe((res: any) => {
        // console.log(res);
        if (res.isSuccess == true) {
          this.toastr.success('Respond Back and ticket closed successfully');
          this.isSubmited = false;
          this.OnClose();
        }
      });
    }
  }

  OnClose() {
    this.description = "";
  }

  OnCancleTicket(item) {
    if (confirm('Please note! Are you sure you want to Close Ticket?')) {
      this.UserEmail = "";
      this.subject = "";
      this.description = "";
      this.userService.updateProblemSuggestionRespond(item.problemOrSuggestionId, this.UserEmail, this.subject, this.description).subscribe((res: any) => {
        // console.log(res);
        if (res.isSuccess == true) {
          this.toastr.success('Ticket closed successfully');
          this.isSubmited = false;
          this.getAlluserProblemOrSuggestions();
          //this.OnClose();
        }
      });
    }

  }

}
