import { Injectable } from '@angular/core';
import { ConfigService } from '../app.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  private url = '';
  private baseUrl = '';
  private idSource = new BehaviorSubject<number | null>(null);
  UserSubscriptionId = this.idSource.asObservable();
  constructor(private http: HttpClient, private configService: ConfigService) {
    this.baseUrl = configService.getServiceBaseUrl();
  }
  getSubscriptionplan() {
    this.url = this.baseUrl + 'api/SubscriptionPlan/GetSubscriptionPlan';
    return this.http.get(this.url);
  }
  addupdateusersubscription(usersubscription) {
    this.url = this.baseUrl + 'api/UserSubscription/UpdateSubscription';
    return this.http.post(this.url, usersubscription);
  }

  Adduserpayment(userpayment, discountedPrice) {
    this.url = this.baseUrl + 'api/UserSubscription/AddpaymentSubscription';
    return this.http.post(this.url, userpayment, discountedPrice);
  }
  addsubscriptiondetails(usersubscription) {
    this.url = this.baseUrl + 'api/PayPal/UpdateUserSubcription';
    return this.http.post(this.url, usersubscription);
  }
  Getsubscriptionhistory(UserID) {
    this.url = this.baseUrl + 'api/SubscriptionPlan/UserSubscriptionHistory?UserID=' + UserID;
    return this.http.get(this.url);
  }
  cancelsubscription(subscriptionid, userId) {
    this.url = this.baseUrl + 'api/PayPal/Cancelsubscription?subscriptionid=' + subscriptionid + '&userId=' + userId;;
    return this.http.get(this.url);
  }

  Purchase(purchase: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.url = this.baseUrl + 'api/Paypal/Braintreepayment';
    return this.http.post(this.url, purchase,{ headers });
  }

  changeId(id: number) {
    this.idSource.next(id);
  }
}
