import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HomeService } from '../../Shared/home.service';
import { SongsService } from '../../Shared/songs.service';
import { PlayerService } from '../../Shared/player.service';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { Track } from '../../../../Player/ngx-audio-player/src/lib/model/track.model';
import { Playlist } from 'src/app/models/playlist';
import { ToastrService } from 'ngx-toastr';
import { LoadScriptService } from 'src/app/loadScript.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { UserService } from 'src/app/Shared/user.service';
import { NgForm } from '@angular/forms';

@Component({

  selector: 'app-favdjmix',
  templateUrl: './favdjmix.component.html',
  styleUrls: ['./favdjmix.component.css']
})
export class FavdjmixComponent implements OnInit {
  @ViewChild('closeBtn') closeBtn: ElementRef;

  @ViewChild('f') form: NgForm;

  userDetails: any;
  userId: any;
  favitems: any;
  favdjmix: any;
  path: any;
  playlist = new Playlist();
  createchecked: boolean = true;
  updateextchecked: boolean;
  noLikeImage1: boolean[] = [];
  nofavimage1: boolean[] = [];
  playListDetails: any;
  songsid;
  playd = [];
  index;
  substatus: any;
  songIsthere: boolean = false;
  languageCode: any;
  theme: any;
  constructor(private loadScript: LoadScriptService,
    private homeService: HomeService,
    public songsService: SongsService,
    private _playerService: PlayerService,
    private router: Router,
    public translate: TranslateService,
    public location: Location, public userService: UserService,
    private toastr: ToastrService, private playerService: AudioPlayerService) { }

  ngOnInit() {
    this.loadScript.getServiceBaseUrl();
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.userId = this.userDetails.userId;
    this.substatus = this.userDetails.subscriptionStatus;
    this.getfavouritesdjmix();
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.languageCode = this.userDetails.languageCode;
    this.theme = this.userDetails.themeId
    this.refreshdjmix();
  }

  freshplaylist() {
    this.homeService.getPlaylist(this.userDetails.userId).subscribe((data: any) => {
      this.playListDetails = data.playListDetails;
      this.homeService.getLatestPlaylist(this.playListDetails);
    })
  }
  getfavouritesdjmix() {
    let a = '';
    let b = 0;
    let c = 0;
    this.songsService.Getallfavdjmix(this.userId, 1, a, b, c).subscribe((data: any) => {
      this.favitems = data;
      this.favdjmix = this.favitems.viewAllFavouriteSongs;
      this.path = this.favitems.path;
      for (var a = 0; a < this.favdjmix.length; a++) {
        if (this.favdjmix[a].likeId == 0) {
          this.noLikeImage1[a] = false;
        } else {
          this.noLikeImage1[a] = true;
        }
      }
      for (var a = 0; a < this.favdjmix.length; a++) {
        if (this.favdjmix[a].favId == 0) {
          this.nofavimage1[a] = false;
        } else {
          this.nofavimage1[a] = true;
        }
      }
    });
  }

  playSong(songs, index) {
    let songId = songs[index].songId;
    localStorage.setItem('CurrentSongId', songId);

    let indexForPlayPause = this.playerService.playPauseBtn.findIndex(x => x == true);
    if (indexForPlayPause == index) {
      localStorage.setItem('IsPlaying', 'false');
      this.playerService.playPauseBtn[index] = !this.playerService.playPauseBtn[index];
    }
    else {
      localStorage.setItem('IsPlaying', 'true');
      if (indexForPlayPause == -1) {
        indexForPlayPause = 0;
      }
      this.playerService.playPauseBtn[indexForPlayPause] = false;
      this.playerService.playPauseBtn[index] = true;
    }
    this._playerService.playSong(songs, this.path, index);
  }

  refreshdjmix() {
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.userId = this.userDetails.userId;
    let a = '';
    let b = 0;
    let c = 0;
    this.songsService.Getallfavdjmix(this.userId, 1, a, b, c).subscribe((data: any) => {
      //this.userService.playId=data.viewAllFavouriteSongs;
      this.userService.getLatestPlaylist(data.viewAllFavouriteSongs);
    });
  }

  checked() {
    this.createchecked = true;
    this.updateextchecked = false;
    this.playlist.Name = '';
  }
  updatechecked() {
    this.updateextchecked = true;
    this.createchecked = false;
    this.playlist.PlaylistId = undefined;
  }
  fetchSongId(i) {
    this.playlist.SongId = i;
  }
  onSubmit() {
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.playlist.UserId = this.userDetails.userId;
    this.homeService.addOrUpdateplaylist(this.playlist).subscribe((res: any) => {
      if (res.status == 200) {
        if (this.playlist.PlaylistId! == 0) {
          this.toastr.success("Playlist added successfully");
          this.createchecked = true;
          this.updateextchecked = false
          this.form.reset();
          this.form.resetForm();
        } else {
          this.freshplaylist();
          this.closeBtn.nativeElement.click();
          this.toastr.success("Playlist updated successfully");
          this.createchecked = true;
          this.updateextchecked = false
          this.form.reset();
          this.form.resetForm();
        }
      }
      else {
      }
    })
  }
  Likesongs(songId: string, UserId: string, i) {
    this.noLikeImage1[i] = !this.noLikeImage1[i];
    this.homeService.LikeSong(songId, this.userId).subscribe((res: any) => {
    }
    )
  }
  FavouriteSongs(songId: string, UserId: string, i) {
    this.nofavimage1[i] = !this.nofavimage1[i];
    this.homeService.FavouriteSongs(this.playlist.SongId, this.userId).subscribe((res: any) => {
      this.refreshdjmix();
    })

  }
  updatecount(songId) {
    debugger;
    this.homeService.updatecount(songId).subscribe((res: any) => {

    })
  }
  IsCheckLang() {
    if (this.translate.currentLang == 'ar') {
      return true;

    } else {
      return false;
    }
  }
  refreshplaylist() {
    this.homeService.getPlaylist(this.userDetails.userId).subscribe((data: any) => {
      this.playListDetails = data.playListDetails;
      if (this.playListDetails.length >= 10) {
        for (var i = this.index; i < 10; i++) {
          this.playd[i] = this.playListDetails[i];
          this.homeService.playId[i] = this.playListDetails[i];
        }
      } else {
        for (var i = this.index; i < this.playListDetails.length; i++) {
          this.playd[i] = this.playListDetails[i];
          this.homeService.playId[i] = this.playListDetails[i];
        }
      }
    });
  }
  back() {

    this.location.back();
  }
  CheckSubscription(song, i) {

    if (this.substatus == "False") {

      document.getElementById("Subscriptioncheck").style.display = "block";
    }
    else {
      this.songIsthere = true;
      this.playSong(song, i);
    }
  }
  closeForm() {
    document.getElementById("Subscriptioncheck").style.display = "none";
    this.router.navigate(['/account/upgradeplan']);
  }
}
