import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Track } from 'ngx-audio-player';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { PlayerService } from '../../Shared/player.service';
import { NgxSpinnerService } from "ngx-spinner";
import { HomeService } from 'src/app/Shared/home.service';

import { UserService } from 'src/app/Shared/user.service';
import { LoadScriptService } from 'src/app/loadScript.service';
import { Role } from 'src/app/models/role';
import { Users } from 'src/app/models/users';
import * as $ from 'jquery';
import { SongsService } from 'src/app/Shared/songs.service';
import { ArtistsService } from 'src/app/Shared/artists.service';
import { PaginationService } from 'src/app/Shared/pagination.service';
import { Pagination } from 'src/app/models/pagination';

@Component({
  selector: 'app-artist-dashboard',
  templateUrl: './artist-dashboard.component.html',
  styleUrls: ['./artist-dashboard.component.css']
})
export class ArtistDashboardComponent implements OnInit {

  header = false;
  [x: string]: any;

  slides = [];
  artistList = [];
  topSongs = [];
  homeDetails = [];
  newReleaseSongs = [];
  recommendedSongs = [];
  favouriteSongs = [];
  mostPlayedSongs = [];
  userLogin: boolean;
  path;
  index = 0;
  track: Track[] = [];
  public count: number = 0;
  userDetails = new Users();
  songDetails = new Track();
  artistLogin: boolean = false;

  noImage: boolean[] = [];
  newReleaseImage: boolean[] = [];
  noMostplayedImage: boolean[] = [];
  nofavouriteSongsImage: boolean[] = [];
  noArtistmage: boolean[] = [];
  substatus: any;
  songIsthere: boolean = false;
  themeidcode: string;
  adminplaylist: any;
  theme: any;
  artiststatus: any;
  totalabum: any;
  totalSongs: any;
  totalLikes: any;
  totalFav: any;
  totalListners: any;
  adminStats: any;
  totalUsers: any;
  totalActiveUsers: any;
  totalIOsUsers: any;
  totalAndroidUsers: any;
  totalTobyPremium: any;
  totalTobyFamily: any;
  totalCancelSubscriptions: any;
  totalabums: any;
  totalartistSongs: any;
  artistid: any;
  artistsong: any;
  artistalbumcounts: any;
  totalsongcount: any;
  noofLikes: any;
  nooffav: any;
  tempartistaudits: any = [];
  artistaudits: any;
  todoListArtist: any;
  iscompld: any;
  TodolistId: any;
  todoListCompleted: any;
  todoListTotal: any;
  tempmostplayedartistsongs: any = [];
  mostplayedartistsongs: any = [];
  nomostplayedartistsongs: boolean = false;
  tempmostplayedartistalbums: any = [];
  mostplayedartistalbums: any = [];
  nomostplayedartistalbums: boolean = false;
  totalmostplayedartistalbums: any = 0;
  totalmostplayedartistsongs: any = 0;
  showViewAllAlbums: boolean = true;
  showViewAllSongs: boolean = true;
  tempmostlikeAlbumsSongs: any = [];
  mostlikeAlbumsSongs: any = [];
  nomostlikeAlbumsSongs: boolean = false;
  totalmostlikeAlbumsSongs: any = 0;
  showViewAllLikesAlbumsSongs: boolean = true;
  slicePage: number = Pagination.displayNumberperpage;
  //Pagination Variables
  PageSize: any = Pagination.sixth;
  PageNo: any = 1;
  temppage: number = 0;
  temppageSong: number = 0;
  disabledNextBtn: boolean;
  disabledPrevBtn: boolean = true;
  exactPageList: any;
  exactPageListSong: any;
  exactPageListLAS: any;
  paginationData: number;
  pageField = [];
  pageFieldSong = [];
  pageFieldLAS = [];
  albumActivePage: boolean[] = [];
  songActivePage: boolean[] = [];
  lasActivePage: boolean[] = [];
  totalAlbumPage: any = 0;
  totalSongPage: any = 0;
  totalLASPage: any = 0;
  disabledNextBtnAlbum: boolean = true;
  disabledPrevBtnAlbum: boolean = true;
  disabledNextBtnSong: boolean = true;
  disabledPrevBtnSong: boolean = true;
  disabledNextBtnLAS: boolean = true;
  disabledPrevBtnLAS: boolean = true;
  albumPageNo: number = 0;
  songPageNo: number = 0;
  likesPageNo: number = 0;
  isAll: any;
  isActive: any;
  temptodoListArtist: any;
  btnactive = false;
  @ViewChild('premiumScoll') premiumScoll: ElementRef;
  constructor(
    private router: Router,
    private loadScript: LoadScriptService,
    private _homeService: HomeService,
    private playerService: AudioPlayerService,
    private _playerService: PlayerService,
    public translate: TranslateService,
    public userService: UserService,
    private spinner: NgxSpinnerService,
    private _songservice: SongsService,
    private artistservice: ArtistsService,
    public paginationService: PaginationService
  ) {

  }


  ngOnInit() {
    debugger;
    window.scrollTo(0, 0);
    $(document).ready(function () {
      $(".Focus").focus();
    })
    this.themeidcode = localStorage.getItem('themeId');
    this.loadScript.getServiceBaseUrl();
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.substatus = this.userDetails.subscriptionStatus;
    this.translate.use(this.userDetails.languageCode);
    this.theme = this.userDetails.themeId;
    // this.Changetheme();
    this.header = true;
    // this.getartistStatus();
    this.paginationService.temppage = 0;
    this.paginationService.temppageSong = 0;
    this.paginationService.currentPage = 1;
    this.paginationService.currentPageSong = 1
    this.paginationService.showNoOfCurrentPage = 1;
    this.paginationService.showNoOfCurrentPageSong = 1;
    this.paginationService.showNoOfCurrentPageLAS = 1;
    this.paginationService.showPageOnlyOntabsChange = true;
    this.paginationService.showPageOnlyOntabsChangeSong = true;
    this.paginationService.showPageOnlyOntabsChangeLAS = true;
    if (this.userDetails.userType == Role.superAdmin) {
      this.userLogin = false;
      this.getAdminStats();
    } else if (this.userDetails.userType == Role.user) {
      this.userLogin = true;
      this.getHomeDetails(this.userDetails.userId);
    }
    else {
      this.artistLogin = true;
      this.userLogin = false;
      this.albumActivePage[0] = true;
      this.songActivePage[0] = true;
      this.lasActivePage[0] = true;
      this.albumPageNo = this.PageNo;
      this.songPageNo = this.PageNo;
      this.likesPageNo = this.PageNo;
      this.ArtistSongsById();
      this.getalbumbyartistId();
      this.GetArtistDetails();
      this.GetArtistAudit();
      this.GetTodoListArtistById();
      this.getmostplayedSongsByArtistId();
      this.getmostplayedAlbumsByArtistId();
      this.getMostLikesAlbumsSongsByArtistId();
    }

    this.artistservice.getIsblogorCountryScreen().subscribe(value => {
      this.Isnotification = value;
      this.premiumScoll.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    });
    // console.log( );
  }

  languageChange(language: string) {
    localStorage.setItem('locale', language);
    this.translate.use(language);
  }

  CheckSubscription(song, i) {
    if (this.substatus == "False") {
      document.getElementById("Subscriptioncheck").style.display = "block";
      //this.router.navigate(['/account/upgradeplan']);
    }
    else {
      this.songIsthere = true;
      this.playSong(song, i);
    }
  }

  openForm() {
    document.getElementById("myForm").style.display = "block";
  }
  getHomeDetails(userId) {
    this.spinner.show();
    this._homeService.getHomeDetails(userId).subscribe((data: any) => {
      this.homeDetails = data;
      this.path = data.path;
      this.artistList = data.homePageDetails.artist;
      this.topSongs = data.homePageDetails.topSongs;
      this.newReleaseSongs = data.homePageDetails.newRelease;
      this.recommendedSongs = data.homePageDetails.recommendedSongs;
      this.favouriteSongs = data.homePageDetails.favouriteSongs;
      this.mostPlayedSongs = data.homePageDetails.mostPlayedSongs;
      this.adminplaylist = data.homePageDetails.adminPlayLists;
      this.spinner.hide();
    });

  }

  getalbumbyartistId() {
    this.artistid = this.userDetails.artistId;
    this._songservice.GetAllAbumbs(this.artistid).subscribe((data: any) => {
      this.artistalbumcounts = data.albumDetails.length;
    });
  }

  getAdminStats() {
    this._homeService.getAdminStats().subscribe((data: any) => {
      this.adminStats = data;
      this.totalUsers = this.adminStats.adminStats[0].totalUsers;
      this.totalArtists = this.adminStats.adminStats[0].totalArtists;
      this.totalSongs = this.adminStats.adminStats[0].totalSongs;
      this.totalabum = this.adminStats.adminStats[0].totalAlbums;
      this.totalActiveUsers = this.adminStats.adminStats[0].totalActiveUsers;
      this.totalIOsUsers = this.adminStats.adminStats[0].totalIOsUsers;
      this.totalAndroidUsers = this.adminStats.adminStats[0].totalAndroidUsers;
      this.totalWebUsers = this.adminStats.adminStats[0].totalWebUsers;
      this.totalFreeSubscriptions = this.adminStats.adminStats[0].totalFreeSubscriptions;
      this.totalTobyPremium = this.adminStats.adminStats[0].totalTobyPremium;
      this.totalTobyFamily = this.adminStats.adminStats[0].totalTobyFamily;
      this.totalCancelSubscriptions = this.adminStats.adminStats[0].totalCancelSubscriptions;
      this.spinner.hide();
    });
  }
  ArtistSongsById() {
    this._homeService.ArtistSongsById(this.artistid).subscribe((data: any) => {
      this.artistsong = data.artistsongs;
      this.totalsongcount = data.artistsongs.length;
    });
  }


  Artistprofile(ArtistId: number) {
    this.router.navigate(['/artist/artistprofile'], { queryParams: { Id: ArtistId } });
  }

  playSong(songs, index) {
    this._playerService.playSong(songs, this.path, index);
  }

  slideConfig = {
    "slidesToShow": 2,
    "slidesToScroll": 2,
    "prevArrow": "<div class='nav-btn next-slide'> <button style='float:left;margin-top: 42px;background: none;border: none;padding: 0 !important;color:  #6c757d;font-size: 30px;margin: 0px; padding: 4px 7px;/* background: #D6D6D6; */display: inline-block;cursor: pointer; border-radius: 3px;opacity: 0.5;' type='button' role='presentation'><i class='fa fa-angle-left' aria-hidden='true'></i></button></div>",
    "nextArrow": "<div class='nav-btn prev-slide'> <button style='float:right;margin-top: 42px;  background: none;border: none;padding: 0 !important;color:  #6c757d;font-size: 30px;margin: 0px; padding: 4px 7px;/* background: #D6D6D6; */display: inline-block;cursor: pointer; border-radius: 3px;opacity: 0.5;' type='button' role='presentation'><i class='fa fa-angle-right' aria-hidden=true'></i></button></div>",
    "dots": false,
    "infinite": false

  };

  slideConfigForSongs = {
    "slidesToShow": 6,
    "slidesToScroll": 6,
    "prevArrow": "<div class='nav-btn next-slide'> <button style='float:left;margin-top: 42px;  background: none;border: none;padding: 0 !important;color:  #6c757d;font-size: 30px;color:#596675;margin: 0px; padding: 4px 7px;/* background: #D6D6D6; */display: inline-block; border-radius: 3px;opacity: 0.5;cursor: pointer;' type='button' role='presentation'><i class='fa fa-angle-left'  aria-hidden='true'></i></button></div>",
    "nextArrow": "<div class='nav-btn prev-slide'> <button style='float:right;margin-top: 42px; background: none;border: none;padding: 0 !important;color:  #6c757d;font-size: 30px;color:#596675;margin: 0px; padding: 4px 7px;/* background: #D6D6D6; */display: inline-block; border-radius: 3px;opacity: 0.5;cursor: pointer;' type='button' role='presentation'><i class='fa fa-angle-right' aria-hidden=true'></i></button></div>",
    "dots": false,
    "infinite": false
  };

  addSlide() {
    this.slides.push({ img: "http://placehold.it/350x150/777777" })
  }

  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }

  slickInit(e) {

  }

  breakpoint(e) {

  }

  afterChange(e) {

  }

  beforeChange(e) {

  }
  filersongs(data, songData, index) {

    if (data.recType == "S" || data.recType == "DJ") {
      if (this.substatus == "False") {
        document.getElementById("Subscriptioncheck").style.display = "block";
        // document.getElementById("Subscriptioncheck").style.display = "block";
        //this.router.navigate(['/account/upgradeplan']);
        //alert("Your Subscription has Ended");

      }
      else {
        this.songIsthere = true;
        let tempSongList = [];
        let j = 0;
        for (var i = 0; i < songData.length; i++) {

          if (songData[i].recType == "S" || songData[i].recType == "DJ") {
            tempSongList[j] = songData[i];
            if (index == i) {
              index = j;
            }
            j++;
          }
        }
        this._playerService.playSong(tempSongList, this.path, index);
      }

    } else {

      this.router.navigate(['/album/album-details'], { queryParams: { Id: data.recId } });

    }
  }

  viewallsongs(playlistId: number, Name: any) {
    this.router.navigate([`/Suggestedplaylistdetail`], { queryParams: { Id: playlistId, playListName: Name } });
  }
  // Changetheme() {
  //     if (this.userDetails.themeId == '2') {
  //         this.userService.theme1 = false;
  //     }
  //     else {
  //         this.userService.theme1 = true;
  //     }
  // }

  closeForm() {
    document.getElementById("Subscriptioncheck").style.display = "none";
    this.router.navigate(['/account/upgradeplan']);
  }

  tabledata: any = [
    {
      name: 'Doushi',
      noofsongs: '8 songs',
      year: '2008',
      alength: '24min 36sec',
      timeplayed: '1236',
    },
    {
      name: 'Doushi',
      noofsongs: '8 songs',
      year: '2008',
      alength: '24min 36sec',
      timeplayed: '1236',
    },
    {
      name: 'Doushi',
      noofsongs: '8 songs',
      year: '2008',
      alength: '24min 36sec',
      timeplayed: '1236',
    },
    {
      name: 'Doushi',
      noofsongs: '8 songs',
      year: '2008',
      alength: '24min 36sec',
      timeplayed: '1236',
    },
    {
      name: 'Doushi',
      noofsongs: '8 songs',
      year: '2008',
      alength: '24min 36sec',
      timeplayed: '1236',
    },
    {
      name: 'Doushi',
      noofsongs: '8 songs',
      year: '2008',
      alength: '24min 36sec',
      timeplayed: '1236',
    },
    {
      name: 'Doushi',
      noofsongs: '8 songs',
      year: '2008',
      alength: '24min 36sec',
      timeplayed: '1236',
    }
  ]

  accodingdata: any = [
    {
      name: 'Doushi',
      times: '2mins',
    },
    {
      name: 'Doushi',
      times: '10mins',
    },
    {
      name: 'Doushi',
      times: '2mins',
    },
    {
      name: 'Doushi',
      times: '2mins',
    },
    {
      name: 'Doushi',
      times: '2mins',
    },
    {
      name: 'Doushi',
      times: '2mins',
    },
    {
      name: 'Doushi',
      times: '2mins',
    },
    {
      name: 'Doushi',
      times: '2mins',
    },
    {
      name: 'Doushi',
      times: '2mins',
    },
    {
      name: 'Doushi',
      times: '2mins',
    },
    {
      name: 'Doushi',
      times: '2mins',
    },
    {
      name: 'Doushi',
      times: '2mins',
    },
    {
      name: 'Doushi',
      times: '2mins',
    },
    {
      name: 'Doushi',
      times: '2mins',
    },
  ]

  GetArtistDetails() {
    this.artistservice.getArtistDetails(this.artistid).subscribe((data: any) => {
      this.artistdata = data;
      this.noofLikes = data.artistDetails.noofLikes;
      this.nooffav = data.artistDetails.noofFavs;
      // this.imgURL= data.artistDetails.largeImage;
    })
  }

  GetArtistAudit() {
    this.artistservice.getArtistAudit(this.artistid).subscribe((data: any) => {
      this.artistaudits = data.artistaudit.slice(0, 12);
      console.log(this.artistaudits);
    })
  }

  GetTodoListArtistById() {
    this.artistservice.GetTodoListArtistById(this.artistid).subscribe((data: any) => {
      this.todoListArtist = data.todoList;
      this.temptodoListArtist = this.todoListArtist;
      this.todoListTotal = this.todoListArtist.filter(x => x.isCompleted == false).length;
      this.todoListCompleted = this.todoListArtist.filter(x => x.isCompleted).length;
    });
  }

  isCompleted(e: any, id: any) {
    this.iscompld = e.target.checked;
    this.TodolistId = id;
    this.artistservice.UpdateTodoListIsCompleted(this.TodolistId, this.iscompld).subscribe((data: any) => {
      this.GetTodoListArtistById();
      this.artistservice.IsblogorCountryScreen(false);
    });
  }

  getmostplayedAlbumsByArtistId() {
    this._homeService.getmostplayedAlbumsByArtistId(this.artistid, this.PageSize, this.albumPageNo).subscribe((data: any) => {
      if (data.isSuccess == true) {
        this.tempmostplayedartistalbums = data.mostplayedartistalbums.mostPlayAlbums;
        this.totalmostplayedartistalbums = data.mostplayedartistalbums.totalCount;
        if (this.showViewAllAlbums == true) {
          this.mostplayedartistalbums = this.tempmostplayedartistalbums.slice(0, this.slicePage);
        }
        else {
          this.mostplayedartistalbums = this.tempmostplayedartistalbums;
        }
        if (this.this.mostplayedartistalbums.length > 0) {
          this.nomostplayedartistalbums = false;
        } else {
          this.nomostplayedartistalbums = true;
        }
      }
      else {
        this.nomostplayedartistalbums = true;
        this.tempmostplayedartistalbums = [];
        this.mostplayedartistalbums = []
      }
    });
  }

  getmostplayedSongsByArtistId() {
    this._homeService.GetMostPlayedSongsByArtistId(this.artistid, this.PageSize, this.songPageNo).subscribe((data: any) => {
      if (data.isSuccess == true) {
        this.tempmostplayedartistsongs = data.mostplayedartistsongs.mostPlaySongs;
        this.totalmostplayedartistsongs = data.mostplayedartistsongs.totalCount;
        if (this.showViewAllSongs == true) {
          this.mostplayedartistsongs = this.tempmostplayedartistsongs.slice(0, this.slicePage);
        }
        else {
          this.mostplayedartistsongs = this.tempmostplayedartistsongs;
        }
        if (this.mostplayedartistsongs.length > 0) {
          this.nomostplayedartistsongs = false;
        } else {
          this.nomostplayedartistsongs = true;
        }
      }
      else {
        this.nomostplayedartistsongs = true;
        this.tempmostplayedartistsongs = [];
        this.mostplayedartistsongs = []
      }
    });
  }

  getMostLikesAlbumsSongsByArtistId() {
    this._homeService.getMostLikesAlbumsSongsByArtistId(this.artistid, this.PageSize, this.likesPageNo).subscribe((data: any) => {
      if (data.isSuccess == true) {
        this.tempmostlikeAlbumsSongs = data.mostlikeAlbumsSongs.mostLikes;
        this.totalmostlikeAlbumsSongs = data.mostlikeAlbumsSongs.totalCount;
        if (this.showViewAllLikesAlbumsSongs == true) {
          this.mostlikeAlbumsSongs = this.tempmostlikeAlbumsSongs.slice(0, this.slicePage);
        }
        else {
          this.mostlikeAlbumsSongs = this.tempmostlikeAlbumsSongs;
        }
        if (this.mostlikeAlbumsSongs.length > 0) {
          this.nomostlikeAlbumsSongs = false;
        } else {
          this.nomostlikeAlbumsSongs = true;
        }
      }
      else {
        this.nomostlikeAlbumsSongs = true;
        this.tempmostplayedartistalbums = [];
        this.mostplayedartistalbums = []
      }
    });
  }

  ViewAll(flag) {
    if (flag == "MPA") {
      this.mostplayedartistalbums = [];
      this.mostplayedartistalbums = this.tempmostplayedartistalbums;
      this.getPageNumber(this.totalmostplayedartistalbums, flag);
      this.showViewAllAlbums = false;
    }
    if (flag == "MPS") {
      this.mostplayedartistsongs = [];
      this.mostplayedartistsongs = this.tempmostplayedartistsongs;
      this.getPageNumber(this.totalmostplayedartistsongs, flag);
      this.showViewAllSongs = false;
    }
    if (flag == "MLAS") {
      this.mostlikeAlbumsSongs = [];
      this.mostlikeAlbumsSongs = this.tempmostlikeAlbumsSongs;
      this.getPageNumber(this.totalmostlikeAlbumsSongs, flag);
      this.showViewAllLikesAlbumsSongs = false;
    }
  }

  getPageNumber(data, flag) {
    if (data == 0) {
      if (flag == "MPA") {
        this.pageField = [];
      }
      if (flag == "MPS") {
        this.pageFieldSong = [];
      }
      if (flag == "MLAS") {
        this.pageFieldLAS = [];
      }
    } else {
      this.paginationData = Number(data / this.PageSize);
      let tempPageData = this.paginationData.toFixed();
      if (Number(tempPageData) < this.paginationData) {
        if (flag == "MPA") {
          this.exactPageList = Number(tempPageData) + 1;
          this.paginationService.exactPageList = this.exactPageList;
          this.totalAlbumPage = this.exactPageList;
        }
        if (flag == "MPS") {
          this.exactPageListSong = Number(tempPageData) + 1;
          this.paginationService.exactPageListSong = this.exactPageListSong;
          this.totalSongPage = this.exactPageListSong;
        }
        if (flag == "MLAS") {
          this.exactPageListLAS = Number(tempPageData) + 1;
          this.paginationService.exactPageListLAS = this.exactPageListLAS;
          this.totalLASPage = this.exactPageListLAS;
        }
      } else {
        if (flag == "MPA") {
          this.exactPageList = Number(tempPageData);
          this.paginationService.exactPageList = this.exactPageList
          this.totalAlbumPage = this.exactPageList;
        }
        if (flag == "MPS") {
          this.exactPageListSong = Number(tempPageData);
          this.paginationService.exactPageListSong = this.exactPageListSong
          this.totalSongPage = this.exactPageListSong;
        }
        if (flag == "MLAS") {
          this.exactPageListLAS = Number(tempPageData);
          this.paginationService.exactPageListLAS = this.exactPageListLAS
          this.totalLASPage = this.exactPageListLAS;
        }
      }
      if (flag == "MPA") {
        this.paginationService.pageOnLoad();
        this.pageField = this.paginationService.pageField;
      }
      if (flag == "MPS") {
        this.paginationService.pageOnLoadSong();
        this.pageFieldSong = this.paginationService.pageFieldSong;
      }
      if (flag == "MLAS") {
        this.paginationService.pageOnLoadLAS();
        this.pageFieldLAS = this.paginationService.pageFieldLAS;
      }
    }
    if (flag == "MPA") {
      if (this.pageField.length == 1 && this.albumPageNo == 1) {
        this.paginationService.disabledNextBtn = true;
        this.paginationService.disabledPrevBtn = true;
      } else if (this.albumPageNo == 1 && this.pageField.length > 1) {
        this.paginationService.disabledNextBtn = false;
        this.paginationService.disabledPrevBtn = true;
      } else if (this.albumPageNo == this.pageField.length) {
        this.paginationService.disabledNextBtn = true;
        this.paginationService.disabledPrevBtn = false;
      } else {
        this.paginationService.disabledNextBtn = false;
        this.paginationService.disabledPrevBtn = false;
      }
      this.disabledNextBtnAlbum = this.paginationService.disabledNextBtn;
      this.disabledPrevBtnAlbum = this.paginationService.disabledPrevBtn;
    }
    if (flag == "MPS") {
      if (this.pageFieldSong.length == 1 && this.songPageNo == 1) {
        this.paginationService.disabledNextBtnSong = true;
        this.paginationService.disabledPrevBtnSong = true;
      } else if (this.songPageNo == 1 && this.pageFieldSong.length > 1) {
        this.paginationService.disabledNextBtnSong = false;
        this.paginationService.disabledPrevBtnSong = true;
      } else if (this.songPageNo == this.pageFieldSong.length) {
        this.paginationService.disabledNextBtnSong = true;
        this.paginationService.disabledPrevBtnSong = false;
      } else {
        this.paginationService.disabledNextBtnSong = false;
        this.paginationService.disabledPrevBtnSong = false;
      }
      this.disabledNextBtnSong = this.paginationService.disabledNextBtnSong;
      this.disabledPrevBtnSong = this.paginationService.disabledPrevBtnSong;
    }
    if (flag == "MLAS") {
      if (this.pageFieldLAS.length == 1 && this.likesPageNo == 1) {
        this.paginationService.disabledNextBtnLAS = true;
        this.paginationService.disabledPrevBtnLAS = true;
      } else if (this.likesPageNo == 1 && this.pageFieldLAS.length > 1) {
        this.paginationService.disabledNextBtnLAS = false;
        this.paginationService.disabledPrevBtnLAS = true;
      } else if (this.likesPageNo == this.pageFieldLAS.length) {
        this.paginationService.disabledNextBtnLAS = true;
        this.paginationService.disabledPrevBtnLAS = false;
      } else {
        this.paginationService.disabledNextBtnLAS = false;
        this.paginationService.disabledPrevBtnLAS = false;
      }
      this.disabledNextBtnLAS = this.paginationService.disabledNextBtnLAS;
      this.disabledPrevBtnLAS = this.paginationService.disabledPrevBtnLAS;
    }
  }

  showNextPage(page, i, flag) {
    if (flag == "MPA") {
      this.albumPageNo = page;
      this.albumActivePage = [];
      this.albumActivePage[i] = true;
      this.getmostplayedAlbumsByArtistId();
      if (this.pageField.length == 1 && this.albumPageNo == 1) {
        this.paginationService.disabledNextBtn = true;
        this.paginationService.disabledPrevBtn = true;
      } else if (this.albumPageNo == 1 && this.pageField.length > 1) {
        this.paginationService.disabledNextBtn = false;
        this.paginationService.disabledPrevBtn = true;
      } else if (this.albumPageNo == this.pageField.length) {
        this.paginationService.disabledNextBtn = true;
        this.paginationService.disabledPrevBtn = false;
      } else {
        this.paginationService.disabledNextBtn = false;
        this.paginationService.disabledPrevBtn = false;
      }
      this.disabledNextBtnAlbum = this.paginationService.disabledNextBtn;
      this.disabledPrevBtnAlbum = this.paginationService.disabledPrevBtn;
    }
    if (flag == "MPS") {
      this.songPageNo = page;
      this.songActivePage = [];
      this.songActivePage[i] = true;
      this.getmostplayedSongsByArtistId();
      if (this.pageFieldSong.length == 1 && this.songPageNo == 1) {
        this.paginationService.disabledNextBtnSong = true;
        this.paginationService.disabledPrevBtnSong = true;
      } else if (this.songPageNo == 1 && this.pageFieldSong.length > 1) {
        this.paginationService.disabledNextBtnSong = false;
        this.paginationService.disabledPrevBtnSong = true;
      } else if (this.songPageNo == this.pageFieldSong.length) {
        this.paginationService.disabledNextBtnSong = true;
        this.paginationService.disabledPrevBtnSong = false;
      } else {
        this.paginationService.disabledNextBtnSong = false;
        this.paginationService.disabledPrevBtnSong = false;
      }
      this.disabledNextBtnSong = this.paginationService.disabledNextBtnSong;
      this.disabledPrevBtnSong = this.paginationService.disabledPrevBtnSong;
    }
    if (flag == "MLAS") {
      this.likesPageNo = page;
      this.lasActivePage = [];
      this.lasActivePage[i] = true;
      this.getMostLikesAlbumsSongsByArtistId();
      if (this.pageFieldLAS.length == 1 && this.likesPageNo == 1) {
        this.paginationService.disabledNextBtnLAS = true;
        this.paginationService.disabledPrevBtnLAS = true;
      } else if (this.likesPageNo == 1 && this.pageFieldLAS.length > 1) {
        this.paginationService.disabledNextBtnLAS = false;
        this.paginationService.disabledPrevBtnLAS = true;
      } else if (this.likesPageNo == this.pageFieldLAS.length) {
        this.paginationService.disabledNextBtnLAS = true;
        this.paginationService.disabledPrevBtnLAS = false;
      } else {
        this.paginationService.disabledNextBtnLAS = false;
        this.paginationService.disabledPrevBtnLAS = false;
      }
      this.disabledNextBtnLAS = this.paginationService.disabledNextBtnLAS;
      this.disabledPrevBtnLAS = this.paginationService.disabledPrevBtnLAS;
    }
  }


  nextPage(page, flag) {
    if (flag == "MPA") {
      if (this.disabledNextBtnAlbum == false) {
        this.paginationService.nextPage();
        this.albumPageNo = page + 1;
        //this.paginationService.pageField[0];
        this.albumActivePage = [];
        this.albumActivePage[this.albumPageNo - 1] = true;
        this.getmostplayedAlbumsByArtistId();
        if (this.albumPageNo == 1 && this.pageField.length > 1) {
          this.paginationService.disabledNextBtn = false;
          this.paginationService.disabledPrevBtn = true;
        } else if (this.albumPageNo == this.pageField.length) {
          this.paginationService.disabledNextBtn = true;
          this.paginationService.disabledPrevBtn = false;
        } else {
          this.paginationService.disabledNextBtn = false;
          this.paginationService.disabledPrevBtn = false;
        }
        this.disabledNextBtnAlbum = this.paginationService.disabledNextBtn;
        this.disabledPrevBtnAlbum = this.paginationService.disabledPrevBtn;
      }
    }
    if (flag == "MPS") {
      if (this.disabledNextBtnSong == false) {
        this.paginationService.nextPageSong();
        this.songPageNo = page + 1;
        //this.paginationService.pageField[0];
        this.songActivePage = [];
        this.songActivePage[this.songPageNo - 1] = true;
        this.getmostplayedSongsByArtistId();
        if (this.songPageNo == 1 && this.pageFieldSong.length > 1) {
          this.paginationService.disabledNextBtnSong = false;
          this.paginationService.disabledPrevBtnSong = true;
        } else if (this.songPageNo == this.pageFieldSong.length) {
          this.paginationService.disabledNextBtnSong = true;
          this.paginationService.disabledPrevBtnSong = false;
        } else {
          this.paginationService.disabledNextBtnSong = false;
          this.paginationService.disabledPrevBtnSong = false;
        }
        this.disabledNextBtnSong = this.paginationService.disabledNextBtnSong;
        this.disabledPrevBtnSong = this.paginationService.disabledPrevBtnSong;
      }
    }
    if (flag == "MLAS") {
      if (this.disabledNextBtnLAS == false) {
        this.paginationService.nextPageLAS();
        this.likesPageNo = page + 1;
        //this.paginationService.pageField[0];
        this.lasActivePage = [];
        this.lasActivePage[this.likesPageNo - 1] = true;
        this.getMostLikesAlbumsSongsByArtistId();
        if (this.likesPageNo == 1 && this.pageFieldLAS.length > 1) {
          this.paginationService.disabledNextBtnLAS = false;
          this.paginationService.disabledPrevBtnLAS = true;
        } else if (this.likesPageNo == this.pageFieldLAS.length) {
          this.paginationService.disabledNextBtnLAS = true;
          this.paginationService.disabledPrevBtnLAS = false;
        } else {
          this.paginationService.disabledNextBtnLAS = false;
          this.paginationService.disabledPrevBtnLAS = false;
        }
        this.disabledNextBtnLAS = this.paginationService.disabledNextBtnLAS;
        this.disabledPrevBtnLAS = this.paginationService.disabledPrevBtnLAS;
      }
    }
  }

  previous(page, flag) {
    if (flag == "MPA") {
      if (this.disabledPrevBtnAlbum == false) {
        this.paginationService.previous();
        this.albumPageNo = page - 1;
        //this.paginationService.pageField[0];
        this.albumActivePage = [];
        this.albumActivePage[this.albumPageNo - 1] = true;
        this.getmostplayedAlbumsByArtistId();
        if (this.PageNo == 1 && this.pageField.length > 1) {
          this.paginationService.disabledNextBtn = false;
          this.paginationService.disabledPrevBtn = true;
        } else if (this.PageNo == this.pageField.length) {
          this.paginationService.disabledNextBtn = true;
          this.paginationService.disabledPrevBtn = false;
        } else {
          this.paginationService.disabledNextBtn = false;
          this.paginationService.disabledPrevBtn = false;
        }
        this.disabledNextBtnAlbum = this.paginationService.disabledNextBtn;
        this.disabledPrevBtnAlbum = this.paginationService.disabledPrevBtn;
      }
    }
    if (flag == "MPS") {
      if (this.disabledPrevBtnSong == false) {
        this.paginationService.previousPageSong();
        this.songPageNo = page - 1;
        //this.paginationService.pageField[0];
        this.songActivePage = [];
        this.songActivePage[this.songPageNo - 1] = true;
        this.getmostplayedSongsByArtistId();
        if (this.songPageNo == 1 && this.pageFieldSong.length > 1) {
          this.paginationService.disabledNextBtnSong = false;
          this.paginationService.disabledPrevBtnSong = true;
        } else if (this.songPageNo == this.pageFieldSong.length) {
          this.paginationService.disabledNextBtnSong = true;
          this.paginationService.disabledPrevBtnSong = false;
        } else {
          this.paginationService.disabledNextBtnSong = false;
          this.paginationService.disabledPrevBtnSong = false;
        }
        this.disabledNextBtnSong = this.paginationService.disabledNextBtnSong;
        this.disabledPrevBtnSong = this.paginationService.disabledPrevBtnSong;
      }
    }
    if (flag == "MLAS") {
      if (this.disabledPrevBtnLAS == false) {
        this.paginationService.previousPageLAS();
        this.likesPageNo = page - 1;
        //this.paginationService.pageField[0];
        this.lasActivePage = [];
        this.lasActivePage[this.likesPageNo - 1] = true;
        this.getMostLikesAlbumsSongsByArtistId();
        if (this.likesPageNo == 1 && this.pageFieldLAS.length > 1) {
          this.paginationService.disabledNextBtnLAS = false;
          this.paginationService.disabledPrevBtnLAS = true;
        } else if (this.likesPageNo == this.pageFieldLAS.length) {
          this.paginationService.disabledNextBtnLAS = true;
          this.paginationService.disabledPrevBtnLAS = false;
        } else {
          this.paginationService.disabledNextBtnLAS = false;
          this.paginationService.disabledPrevBtnLAS = false;
        }
        this.disabledNextBtnLAS = this.paginationService.disabledNextBtnLAS;
        this.disabledPrevBtnLAS = this.paginationService.disabledPrevBtnLAS;
      }
    }
  }

  GetFilterdTodoListArtistById(filterby: any) {
    if (filterby == "isactive") {
      this.btnactive = true;
      this.temptodoListArtist = this.todoListArtist.filter(x => x.isCompleted == false);
      this.todoListTotal = this.todoListArtist.filter(x => x.isCompleted == false).length;
      this.todoListCompleted = this.todoListArtist.filter(x => x.isCompleted).length;
    }
    else if (filterby == "isall") {
      this.btnactive = false;
      this.temptodoListArtist = this.todoListArtist;
      //this.todoListArtistIsActive = this.todoListArtist.filter(x => x.isCompleted == false);
      this.todoListTotal = this.temptodoListArtist.filter(x => x.isCompleted == false).length;
      this.todoListCompleted = this.temptodoListArtist.filter(x => x.isCompleted).length;
    }
  }
}

// navigationList: any = [
//   //   {
//   //     name: 'Main',
//   //     icon: "fa-users",
//   //     isSelected: false,
//   //     id: '1',
//   //     subNav: [
//   // 	{icon:"fa-address-book", name: 'Countries', isSelected: true,router:"/main/countries"},
//   //       {icon:"fa-address-book", name: 'states', isSelected: true,router:"/main/states"}
//   //     ],
//   //   },



