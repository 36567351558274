import { Injectable } from '@angular/core';
import { ConfigService } from '../app.config';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlbumService {
  private url = '';
  private baseUrl = '';
  public searchText = '';
  public artistId = 0;
  public albumId = 0;
  albumDetails: any;
  artistfav: any = [];
  public playId = [];


  public content = new BehaviorSubject<any>(this.artistfav);
  public share = this.content.asObservable();
  constructor(private http: HttpClient, private configService: ConfigService) {
    this.baseUrl = configService.getServiceBaseUrl();
  }

  getLatestPlaylist(data) {
    this.content.next(data);
    this.playId = data;
  }
  //Get Album
  GetAlbum(SearchText, PageIndex, PageSize, UserId) {
    this.url = this.baseUrl + 'api/Albums/SearchAlbum?SearchText=' + SearchText + '&PageIndex=' + PageIndex + '&PageSize=' + PageSize + '&UserId=' + UserId;
    return this.http.get(this.url);
  }
  //Get Specific Album Detail
  GetAlbumsbyalbum(albumId , UserId) {
    this.url = this.baseUrl + 'api/Albums/GetAlbumsbyId?AlbumId=' + albumId + '&UserId=' + UserId;
    return this.http.get(this.url);
  }

  /*
  * get banner album details as per userid
  */
  GetBannerAlbumsDetailByUserId(albumId, UserId) {
    this.url = this.baseUrl + 'api/Albums/GetBannerAlbumsDetailByUserId?AlbumId=' + albumId + '&UserId=' + UserId;
    return this.http.get(this.url);
  }

  /*
  * for getting all Albums by UserId
  */
  GetAlbumsbyUserId(UserId: number) {
    this.url = this.baseUrl + 'api/Albums/GetAlbumsByUserId?UserId=' + UserId;
    return this.http.get(this.url);
  }

  /*
  * get all songs data with pagination
  */
  GetPaginatedAlbums(searchText: string, pageIndex: number, pageSize: number, userId: number) {
    this.url = this.baseUrl + 'api/Albums/GetPaginatedAlbums?SearchText=' + searchText + '&PageIndex=' + pageIndex + '&PageSize=' + pageSize + '&UserId=' + userId;
    return this.http.get(this.url);
  }

  /*
  * get album by AlbumId and UserId
  */
  GetAlbumByAlbumIdAndUserId(albumId:number, userId:number){
    this.url = this.baseUrl + 'api/Albums/GetAlbumById?id=' + albumId + '&userId=' + userId;
    return this.http.get(this.url);
  }
  
  //Add or Update Artist
  addOrUpdateAlbum(albumData) {
    this.url = this.baseUrl + 'api/Albums/AddUpdateAlbum';
    return this.http.post(this.url, albumData);
  }

  GetAllAlbum(ArtistId, columName, sortedOrder, searchName, pageIndex, pageSize) {
    this.url = this.baseUrl + 'api/Albums/GetAllAlbums?ArtistId=' + ArtistId + '&ColumName=' + columName + '&SortedOrder=' + sortedOrder + '&SearchName=' + searchName + '&PageIndex=' + pageIndex + '&PageSize=' + pageSize;
    return this.http.get(this.url);
  }
  //Delete Artist
  deleteAlbum(albumId): Observable<any> {
    this.url = this.baseUrl + 'api/Albums/DeleteAlbum?AlbumId=' + albumId;
    return this.http.post(this.url, albumId)
  }
  GetAlbumsbyAlbumId(albumId) {
    this.url = this.baseUrl + 'api/Albums/GetAlbumsbyId?AlbumId=' + albumId;
    return this.http.get(this.url)
  }

  Getallfavlbum(UserId, SearchText, pageIndex, pageSize) {
    this.url = this.baseUrl + 'api/Albums/ViewAllFavouriteAlbum?UserId=' + UserId + '&SearchText=' + SearchText + '&PageIndex=' + pageIndex + '&PageSize=' + pageSize;
    return this.http.get(this.url);
  }

  GetAlbumByArtistAndCoArtistId(ArtistId, columName, sortedOrder, searchName, pageIndex, pageSize) {
    this.url = this.baseUrl + 'api/Albums/GetAlbumsByArtistAndCoArtistId?ArtistId=' + ArtistId + '&ColumName=' + columName + '&SortedOrder=' + sortedOrder + '&SearchName=' + searchName + '&PageIndex=' + pageIndex + '&PageSize=' + pageSize;
    return this.http.get(this.url);
  }

  getmostplayedalbumbyuserid(UserId) {
    this.url = this.baseUrl + 'api/Albums/GetMostPlayedAlbum?UserId=' + UserId;
    return this.http.get(this.url)
  }

  getalbum() {
    this.url = this.baseUrl + 'api/Albums/GetAlbums';
    return this.http.get(this.url)
  }

  AddUpdateFeaturedAlbums(featuredAlbum) {
    this.url = this.baseUrl + 'api/Albums/AddIsFeaturedAlbum';
    return this.http.post(this.url, featuredAlbum);
  }

  GetFeaturedAlbum() {
    this.url = this.baseUrl + 'api/Albums/GetFeaturedAlbum';
    return this.http.get(this.url)
  }

}
