import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-songs',
  templateUrl: './songs.component.html',
  styleUrls: ['./songs.component.css']
})
export class SongsComponent implements OnInit {

  constructor() { }
  slides = [
    { img: "../assets/images/album1.jpg" },
    { img: "../assets/images/album2.jpg" },
    { img: "../assets/images/album3.jpg" },
    { img: "../assets/images/album4.jpg" },
    { img: "../assets/images/album5.jpg" },
    { img: "../assets/images/album6.jpg" },
    { img: "../assets/images/album7.jpg" },
    { img: "../assets/images/album8.jpg" },
    { img: "../assets/images/9.png" },
    { img: "../assets/images/10.png" },
    { img: "../assets/images/11.png" },
    { img: "../assets/images/12.png" }
  ];
  ngOnInit() {
  }
  slideConfig = {
    "slidesToShow": 2,
    "slidesToScroll": 2,
    "nextArrow": "<div class='nav-btn next-slide'></div>",
    "prevArrow": "<div class='nav-btn prev-slide'></div>",
    "dots": true,
    "infinite": true
  };

  addSlide() {
    this.slides.push({ img: "http://placehold.it/350x150/777777" })
  }
  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }

  slickInit(e) {

  }

  breakpoint(e) {
  }

  afterChange(e) {

  }

  beforeChange(e) {

  }
}
