import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Users } from '../models/users';
import { Router } from '@angular/router';
import { HomeService } from '../Shared/home.service';
import { LoadScriptService } from '../loadScript.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PlayerService } from '../Shared/player.service';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { Playlist } from '../models/playlist';
import { ToastrService } from 'ngx-toastr';
import { Track } from 'Player/ngx-audio-player/src/public_api';

@Component({
  selector: 'app-most-played-songs',
  templateUrl: './most-played-songs.component.html',
  styleUrls: ['./most-played-songs.component.css']
})
export class MostPlayedSongsComponent implements OnInit {

  userDetails = new Users();
  UserId: any;
  userFullname: any;
  homeDetails = [];
  mostPlayedSongs = [];
  path;
  totalcount: any = 0;
  userImage: any;
  mostplSongs: any;
  mostplSongImage: any;
  IsActiveSongId: any;
  createchecked: boolean = true;
  updateextchecked: boolean;
  playlist = new Playlist();
  songslikeid;
  songId: any;
  result: any;
  noLikeImage1: boolean[] = [];
  nofavimage1: boolean[] = [];
  theme: any;
  playListDetails: any;
  favSong: boolean[] = [];
  likeSong: boolean[] = [];
  isGotoartistScreenEnabled: any;
  @ViewChild('closeBtn') closeBtn: ElementRef;
  isSongplay: boolean = false;
  @ViewChild('audioPlayer') player: ElementRef;
  ShowPlayer: boolean = true;
  loaderDisplay: boolean = false;
  isPlaying: boolean;
  duration: number;
  currentTime: number = 0;
  tempBelovedBeats: any = new Array();
  showAllLess = "Show all";
  showAllSongs = "Show all songs";
  minNum: number = 50;
  isLoading: boolean = false;
  public songsLists: Track = new Track();

  constructor(
    private _homeService: HomeService,
    private router: Router,
    private _playerService: PlayerService,
    public playerService: AudioPlayerService,
    private loadScript: LoadScriptService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.UserId = this.userDetails["userId"];
    this.userFullname = this.userDetails["fullName"];
    this.userImage = this.userDetails["image"];
    //this.getHomeDetails(this.userDetails.userId);
    if (!this.userImage) {
      this.userImage = "../../../assets/images/NoUserFound.png";
    }
    this.getMostPlayedSongs(this.userDetails.userId);
    window.scrollTo(0, 0);
    this.playerService.getIsblogorCountryScreen().subscribe(value => {
      //console.info('san: ' + value);
      this.IsActiveSongId = value;
      //this.getMostPlayedSongs(this.userDetails.userId);
    });
    this.theme = this.userDetails.themeId;
    this.freshplaylist();
    this.isGotoartistScreenEnabled = true;
    this.playerService.registerPlaySongCallbackno((song) => {
      if (song == true) {
        this.isSongplay = true;
      }
      else {
        this.isSongplay = false;
      }
    });
  }

  // getHomeDetails(userId) {
  //   this.spinner.show();
  //   this._homeService.getHomeDetails(userId).subscribe((data: any) => {
  //     this.homeDetails = data;
  //     console.log('this.homeDetails', this.homeDetails);
  //     this.path = data.path;
  //     this.mostPlayedSongs = data.homePageDetails.mostPlayedSongs;
  //     this.totalcount = this.mostPlayedSongs.length;
  //     this.spinner.hide();
  //   });
  // }

  getMostPlayedSongs(userId) {
    this.isLoading = true;
    this._homeService.getMostPlayedSongs(userId).subscribe((data: any) => {
      console.log(data);
      if (data.mostPlydSongs) {
        this.isLoading = false;
        this.mostplSongs = data.mostPlydSongs;
        this.tempBelovedBeats = this.mostplSongs.slice(0, this.minNum);
        this.totalcount = this.formatSongCount(this.mostplSongs.length);
        this.mostplSongImage = this.mostplSongs[0].thumbImage;
        for (var a = 0; a < this.mostplSongs.length; a++) {
          if (this.mostplSongs[a].likeId == 0) {
            this.noLikeImage1[a] = false;
          } else {
            this.noLikeImage1[a] = true;
          }
        }

        for (var a = 0; a < this.mostplSongs.length; a++) {
          if (this.mostplSongs[a].favId == 0) {
            this.nofavimage1[a] = false;
          } else {
            this.nofavimage1[a] = true;
          }
        }
      }
    });
  }

  private areArraysSameDeep(a: any[], b: any[]): boolean {
    return a.length === b.length && a.every((val, index) => val === b[index]);
  }

  playAllsongs(items, play) {
    debugger;
    //let isSongPlaying = localStorage.getItem('IsSongPlaying');
    //this.CheckSubscription(items, 0 );
    if (play == 'play') {
      if (!this.areArraysSameDeep(this._playerService.msaapPlaylist, items)) {
        this._playerService.msaapPlaylist = [];
        this.CheckSubscription(items, 0);
        this.isSongplay = true;
      }
      else if (this._playerService.msaapPlaylist == items) {
        this.playerService.playSongCallback(true);
      }
      else {
        this.playerService.playSongCallback(true);
      }

    }
    else {
      this.playerService.currentTime = this.player.nativeElement.currentTime;
      console.log('onpause', this._playerService.msaapPlaylist, items.length, this.currentTime);
      this.isSongplay = false;
      // this.player.nativeElement.paused = false;
      //this.playBtnHandler();
      this.playerService.playSongCallback(false);

    }

  }
  CheckSubscription(song, i) {
    debugger;
    if (song.length > 0) {
      this.playSong(song, i);
    }
    else {
      this.playSong(song, i);

    }
    //this.playerService.playPause();
    // if (this.substatus == "False") {

    //     document.getElementById("Subscriptioncheck").style.display = "block";
    //     //this.router.navigate(['/account/upgradeplan']);
    // }
    // else {
    //     this.songIsthere = true;

    // }
  }

  playSong(songs, index) {
    debugger;
    // this._playerService.playSongs(songs);
    // if (this.playerService.repeatSong) {
    //   this.playerService.repeatSong = false;
    // }
    // this.playerService.setPlaylist(this._playerService.msaapPlaylist);
    // this.playerService.selectATrack(index + 1);
    // this._playerService.updateData(this._playerService.msaapPlaylist);
    // this.playerService.playSongCallback(true);

    this._playerService.msaapPlaylist = [];
    songs.forEach((song, i) => {
      const trackObj = new Track();
      trackObj.index = i;
      trackObj.image = song.thumbImage;
      trackObj.artistName = song.artistName;
      trackObj.link = song.streamUrl;
      trackObj.title = song.title;
      trackObj.songId = song.songId;
      this._playerService.msaapPlaylist.push(trackObj);
    });
    this.playerService.playSongCallback(true);

    if (this.playerService.repeatSong) {
      this.playerService.repeatSong = false;
    }
    this.playerService.setPlaylist(this._playerService.msaapPlaylist);
    this.playerService.selectATrack(index + 1);
    this._playerService.updateData(this._playerService.msaapPlaylist);
  }

  getPlayListDetails(songDetails) {
    debugger
    this.songId = songDetails.songId;
  }
  checked() {
    this.createchecked = true;
    this.updateextchecked = false;
  }
  updatechecked() {
    this.updateextchecked = true;
    this.createchecked = false;
    this.playlist.PlaylistId = undefined;
  }


  likeSongs(songId: string, UserId: string, i) {
    debugger
    this.likeSong[i] = !this.likeSong[i];
    this._homeService.LikeSong(songId, UserId).subscribe((res: any) => {
      this.result = res.data;
      this.getMostPlayedSongs(this.userDetails.userId);
      console.log(res, this.result)
    })
    if (this.likeSong[i]) {
      this.toastr.success("Song liked.");
    }
    else {
      this.toastr.success("Song unliked.");
    }
  }
  FavouriteSongs(songId: string, UserId: string, i) {
    debugger
    this.favSong[i] = !this.favSong[i];
    this._homeService.FavouriteSongs(songId, UserId).subscribe((res: any) => {
      this.result = res.data;
      this.getMostPlayedSongs(this.userDetails.userId);

    })

    if (this.favSong[i]) {
      this.toastr.success("Song added to favourites.");
    }
    else {
      this.toastr.success("Song removed from favourites.");
    }
  }

  fetchSongId(i, j) {
    this.playlist.SongId = i;
  }

  freshplaylist() {
    this._homeService.getPlaylist(this.userDetails.userId).subscribe((data: any) => {
      this.playListDetails = data.playListDetails;
      this._homeService.getLatestPlaylist(this.playListDetails);

    })
  }

  // onSubmitforupdate() {
  //   debugger;
  //   this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
  //   this.playlist.UserId = this.userDetails.userId;
  //   //this.playlist.PlaylistId=this.playlistId;
  //   this.playlist.SongId = this.songId;
  //   //this.playlist.Name=this.allsongs.name
  //   this.spinner.show();
  //   console.log(this.playlist);
  //   this._homeService.addOrUpdateplaylist(this.playlist).subscribe((res: any) => {
  //     if (res.status == 200) {
  //       if (this.playlist.PlaylistId! == 0) {
  //         this.freshplaylist();
  //         this.toastr.success("Playlist added successfully");
  //         //this.isChecked = [];
  //         this.playlist.Name = '';
  //         this.createchecked = true;
  //         this.updateextchecked = false
  //         //this.form.resetForm();
  //         //this.form.reset();
  //       } else {
  //         this.closeBtn.nativeElement.click();
  //         this.freshplaylist();
  //         this.toastr.success("Playlist updated successfully");
  //         this.playlist.Name = '';
  //         //this.isChecked = [];
  //         this.createchecked = true;
  //         this.updateextchecked = false
  //         //this.form.resetForm();
  //         //this.form.reset();
  //       }
  //       this.spinner.hide();
  //     }
  //     else {
  //       this.toastr.success("Playlist Already Exists");
  //       this.spinner.hide();

  //     }
  //     this.spinner.hide();
  //   }, err => {
  //     this.toastr.error(err)
  //     this.spinner.hide();
  //   });
  // }


  showMoresongs() {
    this.isLoading = true;
    this.minNum = this.minNum + 50;
    if (this.minNum > this.mostplSongs.length || this.minNum == this.mostplSongs.length) {
      this.minNum = this.mostplSongs.length
      this.showAllLess = "Show less";
    }
    // Introduce an artificial delay of 5-10 seconds
    const delay = Math.floor(Math.random() * 4000) + 1000; // Random delay between 5000ms (5s) and 11000ms (11s)

    setTimeout(() => {
      this.tempBelovedBeats = this.mostplSongs.slice(0, this.minNum);
      this.isLoading = false;
    }, delay);
  }

  formatSongCount(length: number): string {
    if (length < 10 && length>0) {
      // Format numbers less than 10 with a leading zero
      return '0' + length;
    } else if (length >= 10000) {
      // Round the number to the nearest hundred for numbers >= 10,000
      const roundedLength = Math.round(length / 100) * 100;
      return new Intl.NumberFormat('en-US').format(roundedLength);
    } else {
      // Simply format numbers >= 10 and < 10,000 with commas
      return new Intl.NumberFormat('en-US').format(length);
    }
  }

}
