import { Component, OnInit } from '@angular/core';
import { LoadScriptService } from 'src/app/loadScript.service';
import { ArtistsService } from 'src/app/Shared/artists.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-artistreport',
  templateUrl: './artistreport.component.html',
  styleUrls: ['./artistreport.component.css']
})
export class ArtistreportComponent implements OnInit {
  model: any = {};
  artistreport: any;
  constructor(private loadScript: LoadScriptService,
    private router: Router,
    private artistService: ArtistsService,) { }

  ngOnInit() {
  }
  searchdata() {
    this.artistService.searhartist(this.model).subscribe((res: any) => {
      this.artistreport = res;
    })
  }
}
