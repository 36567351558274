import { Component, OnInit } from '@angular/core';
import { LoadScriptService } from 'src/app/loadScript.service';
import { Router } from '@angular/router';
import { ArtistsService } from 'src/app/Shared/artists.service';
import { Artist } from 'src/app/models/artist';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/Shared/language.service';
import { Users } from 'src/app/models/users';
import { HomeService } from 'src/app/Shared/home.service';
import { SongsService } from 'src/app/Shared/songs.service';

@Component({
  selector: 'app-artist-new-profile',
  templateUrl: './artist-new-profile.component.html',
  styleUrls: ['./artist-new-profile.component.css']
})
export class ArtistNewProfileComponent implements OnInit {

  ArtistDetails = new Artist();
  artistdata: any;
  path;
  fullname;
  description;
  emailid;
  MobileNumber;
  imgURL: any;
  noofLikes;
  nooffav;
  fileToUpload: File;
  noImage: boolean = true;
  languages: any;
  languageId;
  languageCode: any = 'en';
  userDetails = new Users();
  artistalbumcounts:any;
  totalsongcount:any;
  ArtistId: any = 0;
  artistaudits: any;

  constructor(private loadScript: LoadScriptService,
    private artistservice: ArtistsService,
    public translate: TranslateService,
    private _languageService: LanguageService,
    private router: Router,private _songsService: SongsService,private homeService: HomeService,) { }

  ngOnInit() {
    this.ArtistDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.ArtistId = this.ArtistDetails["artistId"];
    this.loadScript.getServiceBaseUrl();
    this.GetArtistDetails(this.ArtistDetails.artistId);
    this.getalbumbyartistId();
    this.ArtistSongsById();
    this.GetArtistAudit();
  }

  ArtistEdit(artistid: string) {

    this.router.navigate(['/admin/artist/editprofile'], { queryParams: { Id: artistid } });

  }

  GetArtistDetails(artistId) {
    this.ArtistDetails.artistId = artistId;
    this.artistservice.getArtistDetails(this.ArtistDetails.artistId).subscribe((data: any) => {
      console.log(data);
      this.artistdata = data;
      this.path = data.path;
      this.fullname = data.artistDetails.fullName;
      this.description = data.artistDetails.description;
      this.emailid = data.artistDetails.emailId;
      this.MobileNumber = data.artistDetails.mobileNumber;
      this.noofLikes = data.artistDetails.noofLikes;
      this.nooffav = data.artistDetails.noofFavs;
      this.imgURL = data.path.concat(data.artistDetails.largeImage);
      // this.imgURL= data.artistDetails.largeImage;
    })
  }

  accodingdata :any = [
    {
      name: 'Doushi',
      times : '2mins',
    },
    {
      name: 'Doushi',
      times : '10mins',
    },
    {
      name: 'Doushi',
      times : '2mins',
    },
    {
      name: 'Doushi',
      times : '2mins',
    },
    {
      name: 'Doushi',
      times : '2mins',
    },
    {
      name: 'Doushi',
      times : '2mins',
    },
    {
      name: 'Doushi',
      times : '2mins',
    },
    {
      name: 'Doushi',
      times : '2mins',
    },
    {
      name: 'Doushi',
      times : '2mins',
    },
    {
      name: 'Doushi',
      times : '2mins',
    },
    {
      name: 'Doushi',
      times : '2mins',
    },
    {
      name: 'Doushi',
      times : '2mins',
    },
    {
      name: 'Doushi',
      times : '2mins',
    },
    {
      name: 'Doushi',
      times : '2mins',
    },
  ]

  getalbumbyartistId() {
    this._songsService.GetAllAbumbs(this.ArtistId).subscribe((data: any) => {
      this.artistalbumcounts = data.albumDetails.length;
      console.log('albumartist', this.artistalbumcounts);
    });
  }

  ArtistSongsById() {
    this.homeService.ArtistSongsById(this.ArtistId).subscribe((data: any) => {
      this.totalsongcount = data.artistsongs.length;
    });
  }

  GetArtistAudit(){
    debugger;
    this.artistservice.getArtistAudit(this.ArtistId).subscribe((data: any) => {
      console.log(data);
      this.artistaudits = data.artistaudit.slice(0,10);
    })
  }

}
