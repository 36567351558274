import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SongsService } from 'src/app/Shared/songs.service';
import { ToastrService } from 'ngx-toastr';
import { Users } from '../../models/users';
import { Pagination} from 'src/app/models/pagination';
import { $ } from 'protractor';
import { AlbumService } from 'src/app/Shared/album.service';
import { Approvalstatus } from '../../models/approvalstatus';
//import { PaginationServiceForSongs } from 'src/app/Shared/paginationserviceForSongs.service';
import { PaginationService } from 'src/app/Shared/pagination.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from "ngx-spinner";
import { Playlist } from 'src/app/models/playlist';
import { HomeService } from "../../Shared/home.service";
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { LoadScriptService } from 'src/app/loadScript.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from 'src/app/app.config';
import { Song } from '../../models/song';
//

import { ArtistsService } from '../../Shared/artists.service';
import { enableDebugTools } from '@angular/platform-browser';

import { Location } from '@angular/common';

import { splitAtColon } from '@angular/compiler/src/util';
import { Miniapproval } from 'src/app/models/miniapproval';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { PlayerService } from 'src/app/Shared/player.service';
import { Track } from 'Player/ngx-audio-player/src/lib/model/track.model';


@Component({
  selector: 'app-artist-songs',
  templateUrl: './artist-songs.component.html',
  styleUrls: ['./artist-songs.component.css']
})
export class ArtistSongsComponent implements OnInit {


  albumname: any = "";  
  AlbumId: any = 0;
  userDetails = new Users();
  pageSize: number = Pagination.fifth;
  pageIndex: number = 0;
  PageNo: number = 1;
  sogsLists: any = [];
  nosogsLists: boolean = false;
  path: any;  
  pageField = [];
  Checkrecords: boolean = false;  
  activePage: boolean[] = [];  
  ArtistId: number;
  loginasartist: boolean = true;
  columname: any;
  sortedorder: any = "desc";
  searchname: any;
  tempData: number = 1;
  tempArray = [];
  tempArrayPath: any;
  tempDataArray: any;
  playlist = new Playlist();
  index: number = 0;
  playd = [];
  //Pagination Variables
  pageNumberShow = Pagination.displayNoOfPagesPerPage;
  temppage: number = 0;
  disabledNextBtn: boolean;
  disabledPrevBtn: boolean = true;
  exactPageList: any;
  noDataFound: boolean = false;
  
  paginationData: number;
  fileToUpload: File = null;
  files: any;
  noImage: boolean = true;isrelesedon: boolean;
  isComposer: boolean;
  page: any;
;
  approvalstatus_submit: any;
  approvalstatus_rejected: any;
  approvalstatus_resubmit: any;
  approvalstatus_approved: any;
  approvalstatus_delete: any;
  
  playListDetails: any;
  totalcount: any = 0;
  imgURL: any;
  
  isChecked: boolean = true;
  placeholder: string;

  //add songs
  imgURLS: any;
  isDesabled: boolean = false;
  isDesabledalbum: boolean = false;
  songtitle: any = "ADD SONGS"
  artists: any;
  categories: any;
  albums: any;
  submitted = false;
  addSongProfile: boolean = true;
  noImages: boolean = true;
  noUrl: boolean = true;
  fileToUploads: File = null;
  
  duration: number = 0;
  file: File;
  timg: any;
  timgN: any;
  limg: any;
  limgN: any;
  restricts: boolean = false;
  isSelected: boolean;
  arabicRegex: any;
  startyear: any;
  Years: any[] = [];
  Index: any = 0;
  songlangugae: any;
  artistalbumcounts: any;
  totalsongcount: any;
  noofLikes: any;
  nooffav: any;
  songForm: FormGroup;
  SongId: any;
  TransList: Array<any> = [];
  
  allsongs;
  album;
  albumby;
  
  releasedOn;
  approverejectsong = new Miniapproval();
  title;
  thumbImage;
  
  length;
  statusCode;
  commnets;
  largeImage;
  statusId;
  isPlaying: boolean = false;
  tempIndex: number = 0;
  viewSongs = false;
  Action: string;
  song = new Song();
  viewSongData: any;
  songId: any;
  currentTime: number = 0;
  @ViewChild('audioPlayer') player: ElementRef;
  ShowPlayer: boolean = true;
  loaderDisplay: boolean = false;

  @ViewChild('jdScollsong')
  jdScollsong!: ElementRef;
  @ViewChild('jdScoll') jdScoll: ElementRef;
  prev: boolean = false;
  next: boolean = false;

  searchHistory: boolean = false;
  pageNumber: any;
  totalPage: number;
  tempPageIndex: number;
  SongPagination = [
    {
      id:1,
      name:20
    },
    {
      id:1,
      name:50
    },
    {
      id:1,
      name:100
    },
  ]
  // first: number = SongPagination.first;
  // second: number = SongPagination.second;
  // third: number = SongPagination.third;

  constructor(private loadScript: LoadScriptService, private router: Router, private _songsService: SongsService, private toastr: ToastrService
    , private albumService: AlbumService, private route: ActivatedRoute, private spinner: NgxSpinnerService, private _artistsService: ArtistsService,
    public paginationService: PaginationService, public translate: TranslateService, private homeService: HomeService,
    public location: Location, private formBuilder: FormBuilder,
    private configService: ConfigService, private _playerService: PlayerService, private playerService: AudioPlayerService,public playlistService: AudioPlayerService) { 
    this.song.songId = this.route.snapshot.paramMap.get("id");
    this.arabicRegex = '[\u0600-\u06FF]';
    this.startyear = configService.getstartDate();
    // if (this.route.snapshot.paramMap.get("id2") == null)
    //   this.AlbumId = 0;
    // else {
    //   this.AlbumId = this.route.snapshot.paramMap.get("id2");
    //   this.ArtistId = this.route.snapshot.paramMap.get("id1");
    // }
  }
  ngOnInit() {
    this.song.categoryId = 0;
    this.song.songLanguageCode = 0;
    this.song.isComingUp = this.isChecked;
    this.getArtists();
    this.song.artistId = this.albumService.artistId;
    this.song.albumId = this.albumService.albumId;
    this.dropdownyear();
    if (this.song.artistId == 0)
      this.isDesabled = false;
    else
      this.isDesabled = true;
    if (this.song.albumId == 0)
      this.isDesabledalbum = false;
    else {
      this.isDesabledalbum = true;
      this.getAllAlbums(this.song.artistId);
    }
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));

    if (this.userDetails["artistId"] != undefined) {
      this.song.artistId = (this.userDetails["artistId"]);
      this.isDesabled = true;
      this.getAllAlbums(this.song.artistId);
      this.loginasartist = true;
    }
    this.getallCategories();
    this.getsongLanguage();
    if (this.song.songId != null) {
      this.songtitle = "UPDATE SONG";
      this.isDesabled = true;
      this.isDesabledalbum = true;
      this.addSongProfile = false;
      this._songsService.getSongDetails(this.SongId).subscribe((data: any) => {
        if (data.songs.isForKid == true) {
          this.isSelected = true;
        }
        else {
          this.isSelected = false;
        }
        this.imgURL = data.path.concat(data.songs.largeImage);
        this.imgURLS = data.path.concat(data.songs.largeImageNew);
        this.isChecked = data.songs.isComingUp;
        this.song = data.songs;
        this.song.streamUrl = data.songs.streamUrl;
        this.song.length = data.songs.length;
        if (data.songs.largeImage != null) {
          this.limg = data.songs.largeImage.split('/');
          this.limg = this.limg[this.limg.length - 1];
          this.song.largeImage = this.limg;
        }
        if (data.songs.largeImageNew != null) {
          this.limgN = data.songs.largeImageNew.split('/');
          this.limgN = this.limgN[this.limgN.length - 1];
          this.song.largeImageNew = this.limgN;
        }

        if (data.songs.thumbImage != null) {
          this.timg = data.songs.thumbImage.split('/');
          this.timg = this.timg[this.timg.length - 1];
          this.song.thumbImage = this.timg;
          this.noImage = false;
        } else {
          this.noImage = true;
        }

        if (data.songs.thumbImageNew != null) {
          this.timgN = data.songs.thumbImageNew.split('/');
          this.timgN = this.timgN[this.timgN.length - 1];
          this.song.thumbImageNew = this.timgN;
          this.noImages = false;
        } else {
          this.noImages = true;
        }
        if (data.songs.streamUrl != null) {
          this.song.streamUrl = data.songs.streamUrl.split('/');
          this.song.streamUrl = this.song.streamUrl[this.song.streamUrl.length - 1];
          this.noUrl = false;
        }
        else {
          this.noUrl = true;
        }
        this.song = data.songs;
        this.getAllAlbums(this.song.artistId);
        this.path = data.path;
      })
    } else {
      this.song.songId = 0;
    }

    // this.albumService.artistId = 0;
    // this.albumService.albumId = 0;
    this.approvalstatus_submit = Approvalstatus.SUBMIT;
    this.approvalstatus_rejected = Approvalstatus.REJECTED;
    this.approvalstatus_resubmit = Approvalstatus.RESUBMIT;
    this.approvalstatus_approved = Approvalstatus.APPROVED;
    this.approvalstatus_delete = Approvalstatus.DELETED;
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    if (this.userDetails["artistId"] != undefined) {
      this.ArtistId = this.userDetails["artistId"];
      this.getalbumbyartistId();
      this.ArtistSongsById();
      this.GetArtistDetails();
    }

    this.loadScript.getServiceBaseUrl();
    this.paginationService.temppage = this.paginationService.temppage;
    if (this.paginationService.currentpageee != null || this.paginationService.currentpageee != undefined) {
      this.searchname = this._songsService.searchText;
      this.paginationService.currentPage = this.paginationService.currentpageee
      this.activePage[this.paginationService.indexxx] = true;
    }
    else {
      this.searchname = this._songsService.searchText;
      this.paginationService.currentPage = this.paginationService.currentPage;
      this.activePage[0] = true;
    }
    this.paginationService.showPageOnlyOntabsChange = this.paginationService.showPageOnlyOntabsChange;

    if (this.searchname !== "") {
      this.paginationService.temppage = 0
      this.paginationService.currentPage = 1;
      this.activePage[0] = true;
    }
    this.pageIndex = this.PageNo;
    //this.paginationService.currentPage;
    this.searchname = this._songsService.searchText;

    this.GetSongsDetails();
    this.refreshplaylist();
    this.placeholder = 'Upload Image'

   
  }

  searchSongs($event, i) {
    this._songsService.searchText = $event;
    this.Checkrecords = false;
    if ($event == "") {
      this.pageField = [];
      this.pageIndex = 1;
      this.tempPageIndex = 1;
      // this.GetSongsDetails();
      this.prev = false;
      this.next = false;
      this.searchHistory = false;
      this.sogsLists = [];
      this.searchname = "";
      this.sogsLists = this.tempArray;
      this.path = this.tempArrayPath;
      this.getPageNumber(this.tempDataArray);
      this.GetSongsDetails();
      //this.getRows(this.fifth);

    }
    if ($event !== '') {
      this.searchname = $event;
      this.pageField = [];
      this.pageIndex = 1;
      this.paginationService.temppage = 0;
      //this.npPageIndex=true;
      this.GetSongsDetails();
      for (var x = 0; x < this.totalPage + 1; x++) {
        this.activePage[x] = false;
      }
      if (i == 0) {
        this.activePage[0] = true;
      } else {
        this.activePage[i] = true;
      }
    }
  }

  //
  getArtists() {
    //this._songsService.getArtists();
    this._songsService.getArtists().subscribe((data: any) => {
      this.artists = data.artistDetails;
    });
  }
  getallCategories() {
    this._songsService.GetallCategories().subscribe((data: any) => {
      this.categories = data.categories;
    });
  }
  getsongLanguage() {
    this._songsService.GetsongLanguage().subscribe((data: any) => {
      this.songlangugae = data.languages;
    });
  }
  artistChange($event) {
    this.ArtistId = $event.target.value;
    this.getAllAlbums(this.ArtistId);
  }
  langugaeChange($event) {
    this.ArtistId = $event.target.value;
    this.getlanguage(this.ArtistId);
  }
  getlanguage(ArtistId) {
    this._songsService.Getlangugae(ArtistId).subscribe((data: any) => {
      this.songlangugae = data.langDetails;
    });
  }
  getAllAlbums(ArtistId) {
    this._songsService.GetAllAbumbs(ArtistId).subscribe((data: any) => {
      this.albums = data.albumDetails;
    });
  }

  Checked(value) {
    this.song.isComingUp = value;
  }

  Checkedkid(event) {
    this.song.isforKid = event;
  }

  onSubmit() {
    debugger;
    if (this.noImage) {
      this.song.base64Data = null;
    } else {
      this.song.base64Data = this.imgURL.split(",")[1];
    }
    if (this.noImages) {
      this.song.base64DataForNewRelease = null;
    } else {
      this.song.base64DataForNewRelease = this.imgURLS.split(",")[1];
    }
    if(this.song.isComingUp == undefined){
      this.song.isComingUp = false;
    }

    if(this.song.title == null || this.song.releasedOn == null || this.song.composerName == null){
      if(this.song.title == null){
        this.submitted = true
      }
      if(this.song.releasedOn == null){
        this.isrelesedon = true
      }
      if(this.song.composerName == null){
        this.isComposer = true;
      }
      return;
    }

    if (this.userDetails["artistId"] != undefined) {
      this.song.createdBy = this.userDetails["artistId"].toString();
      this.song.updatedBy = this.userDetails["artistId"].toString();
    }
    if (this.userDetails["userId"] != undefined) {
      this.song.createdBy = this.userDetails["userId"].toString();
      this.song.updatedBy = this.userDetails["userId"].toString();
    }    
    if(this.song.songId == 0){
      this.song.statusId = Approvalstatus.SUBMIT;
    }
    if(this.song.artistId == 0 || this.song.artistId == "0"){
      this.toastr.warning("Add Artist");
      return;
    }
    // if (this.song.albumId == 0 || this.song.albumId == "0"){
    //   this.toastr.warning("Add Album");
    //   return;
    // }
    // if(this.song.composerName == null || this.song.composerName == "" || this.song.composerName == undefined){
    //   this.toastr.warning("Add Composer");
    //   this.isComposer = true;
    //   return;
    // }
    // if(this.song.writerName == null || this.song.writerName == "" || this.song.writerName == undefined){
    //   this.toastr.warning("Add Writer");
    //   return;  
    // }
    this.song.lengthString = this.song.length != null ? this.song.length.toString() : "";
    const time = {
      "Hours":16,
      "Minutes":8,
      "Seconds":45,
      "Milliseconds":0,
      "Ticks":581250000000,
      "Days":0,
      "TotalDays":0.6727430555555556,
      "TotalHours":16.145833333333332,
      "TotalMilliseconds":58125000,
      "TotalMinutes":968.75,
      "TotalSeconds":58125
     };
     this.song.length = time;
    this.spinner.show();
    this._songsService.addupdatesong(this.song).subscribe((res: any) => {
      if (res.status == 200) {
        if (this.song.songId == 0) {
          this.toastr.success("song added successfully");
          this.Action = "Added";
          this.spinner.hide();
          this.ArtistAudit(this.Action, this.song.title);        
          this.GetSongsDetails();
          this.cancel();
        } else {
          this.toastr.success("song updated successfully");
          this.Action = "Updated";
          this.spinner.hide();
          this.ArtistAudit(this.Action, this.song.title);        
          this.GetSongsDetails();
          this.cancel();         
        }
      }
    }, err => {
      this.toastr.error(err)
      this.spinner.hide();
    })
  }

  //Upload Files

  selectPhoto(file: FileList) {
    this.song.thumbImage = file.item(0).name;
    this.fileToUpload = file.item(0);
    var File = this.fileToUpload.name;
    this.song.fileName = File.split('.')[0];
    this.files = File.split('.');
    this.song.fileType = this.files[this.files.length - 1];
    this.noImage = false;
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imgURL = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }

  //upload Files for New Release
  selectPhotos(file: FileList) {
    this.song.thumbImageNew = file.item(0).name;
    this.fileToUploads = file.item(0);
    var File = this.fileToUploads.name;
    this.song.fileNameNew = File.split('.')[0];
    this.files = File.split('.');
    this.song.fileTypeNew = this.files[this.files.length - 1];
    //this.song.FileType = File.split('.')[1];
    this.noImages = false;
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imgURLS = event.target.result;
    }
    reader.readAsDataURL(this.fileToUploads);
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.song.base64Data = btoa(binaryString);
  }

  //Upload Mp3 File
  selectSong(evt) {
    this.file = evt.target.files[0];
    new Audio(URL.createObjectURL(this.file)).onloadedmetadata = (e: any) => {
      this.duration = e.currentTarget.duration;
      this.songlength(this.duration);
    }
    //var duration = moment.duration(seconds, "seconds");
    var File = evt.target.value;
    this.song.streamUrl = File.substr(12);
    let subStringData = File.substr(12);
    var FileName = subStringData.split('.')[0];
    var songfiletype = subStringData.split('.');
    songfiletype = songfiletype[songfiletype.length - 1];
    //var FileType = subStringData.split('.')[1];
    this.noUrl = false;
    this.song.songName = FileName;
    this.song.songType = songfiletype;
    var files = evt.target.files;
    var file = files[0];
    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoadedForSong.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoadedForSong(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.song.base64DataForSong = btoa(binaryString);
  }

  songlength(duration) {
    var h = Math.floor(duration / 3600);
    var m = Math.floor(duration % 3600 / 60);
    var s = Math.floor(duration % 3600 % 60);
    var minutes = (h + ':' + m + ':' + s);
    this.song.length = minutes;
  }

  doSomething(e) {
    const e1 = <KeyboardEvent>event;
    if (e.key === 'Tab' || e.key === 'TAB') {
      return;
    }
    let k;
    k = e.keyCode;  // k = event.charCode;  (Both can be used)
    if ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57)) {
      this.restricts = false;
      return;
    }
    else {
      this.restricts = true;
    }
    const ch = String.fromCharCode(e.keyCode);
    const regEx = new RegExp(this.arabicRegex);
    if (regEx.test(ch)) {
      this.restricts = true;
      return;
    }
    e.preventDefault();
  }

  cancel() {
    this.songtitle = "ADD SONG"
    this.noImage = true;
    this.noImages = true;
    this.isDesabledalbum = false;
    this.addSongProfile = true;
    this.imgURL = "";
    this.imgURLS = "";
    this.song = new Song();
    this.song.artistId = (this.userDetails["artistId"]);
    this.submitted = false;
    this.isComposer = false;
    this.isrelesedon = false;
  }

  dropdownyear() {
    this.startyear = 1900;
    let endYear = new Date().getFullYear();
    let year = endYear - this.startyear;
    for (let i = 0; i <= year; i++) {
      this.Years[this.Index] = this.startyear + i;
      this.Index++;

    }
    this.Years = this.Years.reverse();
  }

  GetSongsDetails() {
    this.spinner.show();
    this._songsService.GetSongsDetails(this.ArtistId, this.AlbumId, this.columname, this.sortedorder, this.searchname, this.pageIndex, this.pageSize).subscribe((data: any) => {
      this.totalcount = data.count;
      if (this.tempData == 1) {
        this.tempDataArray = data;
        this.tempArray = data.artistDetails;
        this.tempArrayPath = data.path;
        this.paginationService.temppage = 0;
      }
      this.paginationService.temppage = 0;
      if (this.AlbumId != 0 && data.isSuccess == "true") {
        var str3 = "(By ";
        this.albumname = str3.concat(data.songs[0].albumName, ')');
      }
      this.sogsLists = data.songs;
      this.path = data.path;
      this.getPageNumber(data);
      if(this.sogsLists.length > 0){
        this.nosogsLists = false;
      } else{
        this.nosogsLists = true;
      }
      this.spinner.hide();
    }, err => {
      this.toastr.error(err)
      this.spinner.hide();
    });
  }

  getPageNumber(data) {
    if (data.count == 0) {
      this.totalcount = 0;
      this.pageField = [];
      this.noDataFound = true;
      this.Checkrecords = true;
    } else {
      this.Checkrecords = false;
      this.paginationData = Number(data.count / this.pageSize);
      let tempPageData = this.paginationData.toFixed();
      if (Number(tempPageData) < this.paginationData) {
        this.exactPageList = Number(tempPageData) + 1;
        this.paginationService.exactPageList = this.exactPageList;
      } else {
        this.exactPageList = Number(tempPageData);
        this.paginationService.exactPageList = this.exactPageList
      }
      this.paginationService.pageOnLoad();
      this.pageField = this.paginationService.pageField;
      
      if(this.pageField.length == 1 && this.pageIndex == 1){
        this.paginationService.disabledNextBtn = true;
        this.paginationService.disabledPrevBtn = true;
      } else if(this.pageIndex == 1 && this.pageField.length > 1){
        this.paginationService.disabledNextBtn = false;
        this.paginationService.disabledPrevBtn = true;
      } else if(this.pageIndex == this.pageField.length){
        this.paginationService.disabledNextBtn = true;
        this.paginationService.disabledPrevBtn = false;
      } else{
        this.paginationService.disabledNextBtn = false;
        this.paginationService.disabledPrevBtn = false;
      }
      this.disabledNextBtn = this.paginationService.disabledNextBtn;
      this.disabledPrevBtn = this.paginationService.disabledPrevBtn;
    }
  }

  previous(page) {
      if (this.disabledPrevBtn == false) {
        this.paginationService.previous();
        this.pageIndex = page - 1;
        //this.paginationService.pageField[0];
        this.activePage = [];
        this.activePage[this.pageIndex - 1] = true;
        this.GetSongsDetails();
        if(this.pageIndex == 1 && this.pageField.length > 1){
          this.paginationService.disabledNextBtn = false;
          this.paginationService.disabledPrevBtn = true;
        } else if(this.pageIndex == this.pageField.length){
          this.paginationService.disabledNextBtn = true;
          this.paginationService.disabledPrevBtn = false;
        } else{
          this.paginationService.disabledNextBtn = false;
          this.paginationService.disabledPrevBtn = false;
        }
        this.disabledNextBtn = this.paginationService.disabledNextBtn;
        this.disabledPrevBtn = this.paginationService.disabledPrevBtn;
      }      
  }

  showNextSongs(page, i) {
      this.pageIndex = page;
      this.activePage = [];
      this.activePage[i] = true;
      this.GetSongsDetails();
      if(this.pageField.length == 1 && this.pageIndex == 1){
        this.paginationService.disabledNextBtn = true;
        this.paginationService.disabledPrevBtn = true;
      } else if(this.pageIndex == 1 && this.pageField.length > 1){
        this.paginationService.disabledNextBtn = false;
        this.paginationService.disabledPrevBtn = true;
      } else if(this.pageIndex == this.pageField.length){
        this.paginationService.disabledNextBtn = true;
        this.paginationService.disabledPrevBtn = false;
      } else{
        this.paginationService.disabledNextBtn = false;
        this.paginationService.disabledPrevBtn = false;
      }
      this.disabledNextBtn = this.paginationService.disabledNextBtn;
      this.disabledPrevBtn = this.paginationService.disabledPrevBtn;
  }

  nextPage(page) {
      if (this.disabledNextBtn == false) {
        this.paginationService.nextPage();
        this.pageIndex = page + 1;
        //this.paginationService.pageField[0];
        this.activePage = [];
        this.activePage[this.pageIndex - 1] = true;
        this.GetSongsDetails();
        if(this.pageIndex == 1 && this.pageField.length > 1){
          this.paginationService.disabledNextBtn = false;
          this.paginationService.disabledPrevBtn = true;
        } else if(this.pageIndex == this.pageField.length){
          this.paginationService.disabledNextBtn = true;
          this.paginationService.disabledPrevBtn = false;
        } else{
          this.paginationService.disabledNextBtn = false;
          this.paginationService.disabledPrevBtn = false;
        }
        this.disabledNextBtn = this.paginationService.disabledNextBtn;
        this.disabledPrevBtn = this.paginationService.disabledPrevBtn;
      }      
  }

  refreshplaylist() {
    this.homeService.getadminPlaylist().subscribe((data: any) => {
      this.playListDetails = data.playListDetails;
      if (this.playListDetails.length >= 10) {
        for (var i = this.index; i < 10; i++) {
          this.playd[i] = this.playListDetails[i];
          this.homeService.playId[i] = this.playListDetails[i];
        }
      } else {
        for (var i = this.index; i < this.playListDetails.length; i++) {
          this.playd[i] = this.playListDetails[i];
          this.homeService.playId[i] = this.playListDetails[i];
        }
      }
    });
  }

  getalbumbyartistId() {
    this._songsService.GetAllAbumbs(this.ArtistId).subscribe((data: any) => {
      this.artistalbumcounts = data.albumDetails.length;
    });
  }

  ArtistSongsById() {
    this.homeService.ArtistSongsById(this.ArtistId).subscribe((data: any) => {
      this.totalsongcount = data.artistsongs.length;
    });
  }

  GetArtistDetails() {
    this._artistsService.getArtistDetails(this.ArtistId).subscribe((data: any) => {
      this.noofLikes = data.artistDetails.noofLikes;
      this.nooffav = data.artistDetails.noofFavs;
      // this.imgURL= data.artistDetails.largeImage;
    })
  }

  editSongDetails(songId) {
    this.jdScollsong.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
    this.viewSongs = false;
    this._songsService.getSongDetails(songId).subscribe((data: any) => {      
      if (data.songs.isForKid == true) {
        this.isSelected = true;
      }
      else {
        this.isSelected = false;
      }
      this.isDesabled = true;
      this.isDesabledalbum = true;
      this.addSongProfile = false;
      this.songtitle = "UPDATE SONG";
      this.imgURL = data.path.concat(data.songs.largeImage);
      this.imgURLS = data.path.concat(data.songs.largeImageNew);
      this.isChecked = data.songs.isComingUp;
      this.song = data.songs;
      this.song.streamUrl = data.songs.streamUrl;
      this.song.length = data.songs.length;
      if (data.songs.largeImage != null) {
        this.limg = data.songs.largeImage.split('/');
        this.limg = this.limg[this.limg.length - 1];
        this.song.largeImage = this.limg;
      }
      if (data.songs.largeImageNew != null) {
        this.limgN = data.songs.largeImageNew.split('/');
        this.limgN = this.limgN[this.limgN.length - 1];
        this.song.largeImageNew = this.limgN;
      }

      if (data.songs.thumbImage != null) {
        this.timg = data.songs.thumbImage.split('/');
        this.timg = this.timg[this.timg.length - 1];
        this.song.thumbImage = this.timg;
        this.noImage = false;
      } else {
        this.noImage = true;
      }

      if (data.songs.thumbImageNew != null) {
        this.timgN = data.songs.thumbImageNew.split('/');
        this.timgN = this.timgN[this.timgN.length - 1];
        this.song.thumbImageNew = this.timgN;
        this.noImages = false;
      } else {
        this.noImages = true;
      }
      if (data.songs.streamUrl != null) {
        this.song.streamUrl = data.songs.streamUrl.split('/');
        this.song.streamUrl = this.song.streamUrl[this.song.streamUrl.length - 1];
        this.noUrl = false;
      }
      else {
        this.noUrl = true;
      }
      this.song = data.songs;
      this.getAllAlbums(this.song.artistId);
      this.path = data.path;
    });
  }

  deleteSong(songId) {
    if (confirm('Please note! This action can not be undo. Are you sure you want to delete?')) {
      this._songsService.deleteSong(songId).subscribe((response) => {
        if (response.isSuccess) {
          this.toastr.success("Song has been deleted successfully");
          this.Action = "Deleted"
          this.ArtistAudit(this.Action, this.song.title);
          this.GetSongsDetails();
          this.cancel();
        }
      }, err => {
        this.toastr.error(err)
        this.spinner.hide();
      });
    }
  }

  viewSong(SongId){
    this.jdScoll.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
    this._songsService.getSongDetails(SongId).subscribe((Response: any) => {
      this.viewSongs = true;
      this.TransList.push(Response);
      this.viewSongData = Response.songs;
      this.songId = Response.songs.songId;
      this.path = Response.path;
      this.album = Response.songs.albumName;
      this.albumby = Response.songs.artistName;
      this.title = Response.songs.title;
      this.length = Response.songs.length;
      this.releasedOn = Response.songs.releasedOn;
      this.allsongs = Response.songs;
      this.thumbImage = Response.songs.thumbImage;
      this.largeImage = Response.songs.largeImage;
      this.statusCode = Response.songs.statusCode;
      this.statusId = Response.songs.statusId;
      //this.albumId=Response.songs.albumId;
      this.approverejectsong.albumId = Response.songs.albumId;

      if (this.statusId == Approvalstatus.APPROVED) {
        document.getElementById("resubmit").style.display = "none";
        document.getElementById("approve").style.display = "none";
        //document.getElementById("reject").style.display="none";
      }
      if (this.statusId == Approvalstatus.REJECTED || this.statusId == Approvalstatus.DELETED) {
        document.getElementById("reject").style.display = "none";
      }
      if (this.statusId == Approvalstatus.DELETED) {
        document.getElementById("approve").style.display = "none";
        document.getElementById("comment").style.display = "none";
        document.getElementById("reject").style.display = "none";
      }

    });

  }

  ArtistAudit(action, songname) {
    var Data = {
      "ArtistId": this.ArtistId,
      "Type": action,
      "FieldName": "Song" + " " + songname,
      "CreatedBy": this.ArtistId,
    };
    this._artistsService.ArtistAudit(Data).subscribe((res: any) => {
    });
  }

  playSong(songs) {
    debugger;
    this.ShowPlayer = false;
    var getPlayList = this.playerService.getPlaylist();
    this._playerService.playForArtist = true;
    var index = getPlayList.findIndex(x => x.link == songs.streamUrl);
    this._playerService.msaapPlaylist = [];
    if (index == -1) {
      const tractObj = new Track();
      tractObj.image = this.path + songs.thumbImage;
      tractObj.artistName = songs.artistName;
      tractObj.link = songs.streamUrl;
      tractObj.title = songs.title;
      this._playerService.msaapPlaylist.unshift(tractObj);
      this.playerService.setPlaylist(this._playerService.msaapPlaylist);
      this.playerService.selectATrack(1);
    }
    else {
      this.playerService.selectATrack(1);
    }
    this._playerService.updateData(songs);
    //this.isPlaying = !this.isPlaying;

    // var getPlayList = this.playerService.getPlaylist();
    // this._playerService.playForArtist = true;
    // var index = getPlayList.findIndex(x => x.link == songs.streamUrl);    
    // this._playerService.msaapPlaylist = [];
    // if (index == -1) {
    //   const tractObj = new Track();
    //   tractObj.image = this.path + songs.thumbImage;
    //   tractObj.artistName = songs.artistName;
    //   tractObj.link = songs.streamUrl;
    //   tractObj.title = songs.title;
    //   this._playerService.msaapPlaylist.unshift(tractObj);
    //   this.playerService.setPlaylist(this._playerService.msaapPlaylist);
    //   this.playerService.selectATrack(1);
    // }
    // else {
    //   this.playerService.selectATrack(1);
    // }
    // this._playerService.updateData(songs);
    // this.isPlaying = !this.isPlaying;
    // //this.play();
    // //this.player.nativeElement.play();
    // this.ShowPlayer = true;
  }


  // play(): void {
  //   setTimeout(() => {
  //       this.player.nativeElement.play();
  //   }, 0);
  // };

  //Play Songs


  playPause() {
    if (this.playlistService.playlist.length != 1) {
      let currentIndex = this.playlistService.playlist.findIndex(x => x.songId == localStorage.getItem('CurrentSongId'))
      if (currentIndex != -1) {
        if (this.playlistService.playPauseBtn[this.playlistService.indexSong]) {
          this.playlistService.playPauseBtn[this.playlistService.indexSong] = false;
          localStorage.setItem('IsSongPlaying', 'false');
          localStorage.setItem('IsPlaying', 'false');
        } else {
          this.playlistService.playPauseBtn[this.playlistService.indexSong] = true;
          localStorage.setItem('IsSongPlaying', 'true');
          localStorage.setItem('IsPlaying', 'false');
        }
      }
    } else {
      let currentIndex = this.playlistService.playPauseBtn.findIndex(x => x == true);

      if (this.playlistService.playPauseBtn[currentIndex]) {
        this.playlistService.playPauseBtn[currentIndex] = false;
        this.tempIndex = currentIndex;
        localStorage.setItem('IsSongPlaying', 'false');
        localStorage.setItem('IsPlaying', 'false');
      } else {
        this.playlistService.playPauseBtn[this.tempIndex] = true;
        localStorage.setItem('IsSongPlaying', 'true');
        localStorage.setItem('IsPlaying', 'false');
      }

      let Cursong = localStorage.getItem('IsSongPlaying');
      if (Cursong == 'true') {
        localStorage.setItem('IsSongPlaying', 'false');
      } else {
        localStorage.setItem('IsSongPlaying', 'true');
      }
      let CurPlayingsong = localStorage.getItem('IsPlaying');
      if (CurPlayingsong == 'true') {
        localStorage.setItem('IsPlaying', 'false');
      } else {
        localStorage.setItem('IsPlaying', 'true');
      }
      //this.playlistService.playPauseBtn = [];
    }
  }

  playBtnHandler(): void {
    if (this.loaderDisplay) {
      return;
    }
    if (this.player.nativeElement.paused) {
      this.player.nativeElement.play(this.currentTime);
    } else {
      this.currentTime = this.player.nativeElement.currentTime;
      this.player.nativeElement.pause();
    }
  };

  getRows(e:any) { 
    this.page = e.target.value
    var k;
    var lis = document.getElementById("page1").getElementsByTagName("li");
    for (k = 0; k < lis.length; k++) {
      lis[k].removeAttribute("style");
    }
    if (this.page == this.SongPagination[0].name) {

      this.pageIndex = 1;
      this.paginationService.temppage = 0;
      this.pageSize = this.SongPagination[0].name;
      this.activePage[0] = true;
      this.GetSongsDetails();

    } else if (this.page == this.SongPagination[1].name) {

      this.pageIndex = 1;
      this.paginationService.temppage = 0;
      this.pageSize = this.SongPagination[1].name;
      this.activePage[0] = true;
      this.GetSongsDetails();

    }
    else if (this.page == this.SongPagination[2].name) {

      this.pageIndex = 1;
      this.paginationService.temppage = 0;
      this.pageSize = this.SongPagination[2].name;
      this.activePage[0] = true;
      this.GetSongsDetails();

    }
  }

  orderBy(coloumnName) {
    debugger;
    var i = 0;
    var t = document.getElementsByTagName("th");
    for (i = 0; i < t.length; i++) {
      t[i].removeAttribute("style");
    }
    // ="#bec1c3";
    let r = document.getElementById(coloumnName);

    this.columname = coloumnName;
    if (this.sortedorder == "asc") {
      this.sortedorder = "desc";

    } else {
      this.sortedorder = "asc";
    }

    this.GetSongsDetails();
  }

}
