import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Track } from 'Player/ngx-audio-player/src/public_api';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
// import  { PlaycountService } from '../Shared/playcount.service';
import { HttpClient } from '@angular/common/http';
@Injectable()

export class PlayerService {
    //msaapPlaylist = [];
    songid;
    playForArtist = true;
    private url = '';
    private baseUrl = '';
    msaapPlaylist: Track[] = [];
    public songsLists: Track = new Track();
    public content = new BehaviorSubject<any>(this.msaapPlaylist);
    public share = this.content.asObservable();
   
    constructor(public playerService: AudioPlayerService, private http: HttpClient,
    ) {

    }
    updateData(currentSong) {
        console.log(currentSong);
        this.songsLists = new Track();
        this.songsLists.image = currentSong.image;
        this.songsLists.link = currentSong.link;
        this.songsLists.title = currentSong.title;
        this.songsLists.artistName = currentSong.artistName; console.log(this.songsLists)
       // this.updatecount(4066);
        this.content.next(currentSong);
    }

    playSong(songs, path, index) {
        debugger
        this.msaapPlaylist = [];
        for (var i = 0; i < songs.length; i++) {
            const tractObj = new Track();
            tractObj.index = i;
            tractObj.image = path + songs[i].thumbImage;
            tractObj.artistName = songs[i].artistName;
            tractObj.link = songs[i].streamUrl;
            tractObj.title = songs[i].title;
            tractObj.songId = songs[i].songId;
            this.msaapPlaylist.push(tractObj);
            // this.updatecount(tractObj.songId);
        }

        if (this.playerService.repeatSong) {
            this.playerService.repeatSong = false;
        }
        this.playerService.setPlaylist(this.msaapPlaylist);

        this.playerService.selectATrack(index + 1);
        this.updateData(this.msaapPlaylist);
    }

    updatecount(SongId) {
        this.url = this.baseUrl + 'api/Songs/UpdatePlayCount?SongId=' + SongId;
        return this.http.post(this.url, SongId)
    }
    playSongs(songs) {
        this.msaapPlaylist = [];
        if (songs.length > 1) {
            for (var i = 0; i < songs.length; i++) {
                const tractObj = new Track();
                tractObj.index = i;
                tractObj.image = songs[i].thumbImage;
                tractObj.artistName = songs[i].artistName;
                tractObj.link = songs[i].streamUrl;
                tractObj.title = songs[i].title;
                tractObj.songId = songs[i].songId;
                this.msaapPlaylist.push(tractObj);
            }
        }
        else {
            this.msaapPlaylist = [];
            const tractObj = new Track();
            tractObj.image = songs.thumbImage;
            tractObj.artistName = songs.artistName;
            tractObj.link = songs.streamUrl;
            tractObj.title = songs.title;
            tractObj.songId = songs.songId;
            this.msaapPlaylist.unshift(tractObj);
            //this._playerService.msaapPlaylist.push(tractObj);
            this.playerService.setPlaylist(this.msaapPlaylist);
            this.playerService.selectATrack(1);
            this.updateData(songs);
        }

    }

   
}