import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { SettingComponent } from './setting/setting.component'
// import { ThemeComponent } from './theme/theme.component';
// import { SuggestionComponent } from './suggestion/suggestion.component';
// import { ReportComponent } from './report/report.component';
// import { ChangeLanguageComponent } from './change-language/change-language.component';
// import { EditprofileComponent } from './editprofile/editprofile.component';
// import { ProfileComponent } from './profile/profile.component';
// import { AboutusComponent } from './aboutus/aboutus.component';
// import { TermsComponent } from './terms/terms.component';
// import { PaymentComponent } from './payment/payment.component';

const routes: Routes = [
    // {
    //     path: 'setting',
    //     component: SettingComponent,
    //     data: {
    //         title: 'Setting'
    //     }
    // },
    // {
    //     path: 'payment',
    //     component: PaymentComponent,
    //     data: {
    //         title: 'Payment'
    //     }
    // },
    // {
    //     path: 'terms',
    //     component: TermsComponent,
    //     data: {
    //         title: 'Terms'
    //     }
    // },
    // {
    //     path: 'theme',
    //     component: ThemeComponent,
    //     data: {
    //         title: 'theme'
    //     }
    // },
    // {
    //     path: 'suggestion',
    //     component: SuggestionComponent,
    //     data: {
    //         title: 'Suggestion'
    //     }
    // },
    // {
    //     path: 'language',
    //     component: ChangeLanguageComponent,
    //     data: {
    //         title: 'Language'
    //     }
    // },
    // {
    //     path: 'report',
    //     component: ReportComponent,
    //     data: {
    //         title: 'Report'
    //     }
    // },
    // {
    //     path: 'aboutus',
    //     component: AboutusComponent,
    //     data: {
    //         title: 'About us'
    //     }
    // },
    // {
    //     path: 'editprofile',
    //     component: EditprofileComponent,
    //     data: {
    //         title: 'Edit Profile'
    //     }
    // },
    // {
    //     path: 'profile',
    //     component: ProfileComponent,
    //     data: {
    //         title: 'Profile'
    //     }
    // },
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AccountsRoutingModule { }
