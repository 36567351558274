import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AlbumService } from 'src/app/Shared/album.service';
import { ArtistsService } from 'src/app/Shared/artists.service';
import { PaginationServiceForAlbum } from 'src/app/Shared/paginationserviceForAlbum.service';
import { UserService } from 'src/app/Shared/user.service';
import { LoadScriptService } from 'src/app/loadScript.service';
import { UserHomeBanner } from 'src/app/models/users';

@Component({
  selector: 'app-homepage-section',
  templateUrl: './homepage-section.component.html',
  styleUrls: ['./homepage-section.component.css']
})
export class HomepageSectionComponent implements OnInit {
  allDetails:any=[];
  isExpanded = false;
  songName = 'Your Song Name';
  songImageUrl = 'path/to/your/image.jpg';
  showSongDetail : boolean = false;
  collapse: boolean = false;

  

  constructor(private loadScript: LoadScriptService,
    private router: Router,
    private albumService: AlbumService, private spinner: NgxSpinnerService,
    private _toastr: ToastrService, private route: ActivatedRoute,
    public paginationService: PaginationServiceForAlbum, public translate: TranslateService,
    private _artistsService: ArtistsService, private userService: UserService) { }

  ngOnInit() {
  this.getAllHomeSection();
  }
 
  getAllHomeSection(){
    this.userService.getAllHomeSection().subscribe((data:any)=>{
      this.allDetails=data.homeSectionobj
      console.log("this is "+this.allDetails)
    });
  }

  add() {
    this.router.navigate(['/admin/homepage-Section/add-update-homesection']);
  }

  editSection(item){
    this.router.navigate([`/admin/homepage-Section/update-homesection/` + item + ``]);
  }


 
 deleteSection(item){
    if (confirm('Please note! Are you sure you want to delete Home Section?')) {
      this.userService.deleteSection(item).subscribe((response) => {

        if (response.isSuccess) {
          this._toastr.success(response.message);
          this.getAllHomeSection();
        }
      }, err => {
        this._toastr.error(err)
        this.spinner.hide();
      });
    }
  }

}
