import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  private url = 'https://eapnodeserver.azurewebsites.net/';
  //private url = 'http://localhost:3000/';
  private socket;
  constructor(private http: HttpClient) {
    /*this.socket = io(this.url);*/
    this.socket = io.connect(this.url, {
      forceNew: true,
      transports: ["polling"],
    });
  }
  public sendCustMessage(custmessage) {
      this.socket.emit('new-message', custmessage);
  }
  public sendMessage(message) {
      this.socket.emit('new-message', message);
  }
  public getMessages = () => {
      return Observable.create((observer) => {
          //
          this.socket.on('new-message', (message) => {
           //   
              observer.next(message);
          });
      });
  }
  SetLoginClient(data) {
   this.socket.emit('login', data);
  }
}
