import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Users } from 'src/app/models/users';
import { LoadScriptService } from 'src/app/loadScript.service';
import { ToastrService } from 'ngx-toastr';
import { Role } from '../../models/role';
import { ArtistsService } from 'src/app/Shared/artists.service';
import { UserService } from 'src/app/Shared/user.service';
import * as $ from 'jquery';
import { HomeService } from 'src/app/Shared/home.service';
import { SongsService } from 'src/app/Shared/songs.service';
import { ArtistFeedback } from 'src/app/models/artistFeedback';

@Component({
  selector: 'app-artist-feedback',
  templateUrl: './artist-feedback.component.html',
  styleUrls: ['./artist-feedback.component.css']
})
export class ArtistFeedbackComponent implements OnInit {

  userDetails = new Users();
  ArtistId: any;
  roleType: any;
  artistresult: any;
  tempUserData: any;
  artistalbumcounts: any;
  totalsongcount: any;
  noofLikes: any;
  nooffav: any;
  Action: string;
  feedbackForm: FormGroup;
  fileToUpload: File;
  feedbackDetails = new ArtistFeedback();
  noImage: boolean = true;
  imgURL: any;
  ispdf: boolean = false;
  isdoc: boolean = false;
  isImg: boolean = false;

  constructor(private toastr: ToastrService, public userService: UserService, private _router: Router,
    private loadScript: LoadScriptService, private formBuilder: FormBuilder,
    private _artistService: ArtistsService, private _songsService: SongsService,
    private homeService: HomeService,) { }

  ngOnInit() {
    this.loadScript.getServiceBaseUrl();
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.ArtistId = this.userDetails["artistId"];
    this.roleType = this.userDetails.userType;

    $(document).ready(function () {
      $(".focussss").focus();
    });
    // this.getalbumbyartistId();
    // this.ArtistSongsById();
    // this.GetArtistDetails();
  }

  onSubmit() {
    if (this.noImage) {
      this.feedbackDetails.Base64Data = null;
    } else {
      this.feedbackDetails.Base64Data = this.imgURL.split(",")[1];
    }
    this.feedbackDetails.artistId = this.ArtistId;
    console.log(this.feedbackDetails);
    this._artistService.addArtistFeedback(this.feedbackDetails).subscribe((res: any) => {
      if (res.status == 200) {
        this.toastr.success("Feedback added successfully");
        this.Action = "Added"
        this.ArtistAudit(this.Action, this.userDetails.fullName);
        this.feedbackDetails.feedbackText = "";
        this.feedbackDetails.feedbackTitle = "";
        this.imgURL = null;
        this.noImage = true;
      }
    }, err => { this.toastr.error(err) })
  }
  // this.artistService.addOrUpdateArtist(this.artistDetails).subscribe((res: any) => {
  //   if (res.status == 200) {
  //   }

  ArtistAudit(action, artistname) {
    debugger;
    var Data = {
      "ArtistId": this.ArtistId,
      "Type": action,
      "FieldName": "Feedback" + " " + artistname,
      "CreatedBy": this.ArtistId,
    };
    console.log("chk", Data);
    this._artistService.ArtistAudit(Data).subscribe((res: any) => {
      console.log(res);
    });
  }

  cancel() {
    this.feedbackDetails.feedbackText = "";
    this.feedbackDetails.feedbackTitle = "";
    this.noImage = true;
    this.imgURL = null;
  }

  selectPhoto(file: FileList) {
    debugger;
    this.feedbackDetails.thumbImage = file.item(0).name;
    this.fileToUpload = file.item(0);
    console.log(this.fileToUpload);
    var File = this.fileToUpload.name;
    this.feedbackDetails.FileName = File.split('.')[0];
    this.feedbackDetails.FileType = File.split('.')[1];
    this.noImage = false;
    let FileSize = this.fileToUpload.size;
    if (FileSize > 26214400) {
      this.toastr.error('File size is more than 25mb');
      this.noImage = true;
      return;
    }
    //Show Image Preview  26,214,400
    var reader = new FileReader();
    let type = [];
    type = this.fileToUpload.name.split(/[\s.]+/);
    const fileType = (type[type.length - 1]).toLowerCase();
    if (fileType == "pdf") {
      this.ispdf = true;
      this.isdoc = false;
    }
    else if (fileType == "docx") {
      this.isdoc = true;
      this.ispdf = false;
    }
    else {
      this.ispdf = false;
      this.isdoc = false;
    }
    reader.onload = (event: any) => {
      this.imgURL = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);

  }


}
