import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class LoadScriptService {
    url = "../assets/js/custom.js";
    loadAPI;


    constructor() { }

    getServiceBaseUrl() {
        this.loadAPI = new Promise(resolve => {
            this.loadScript();
        });
    }
    public loadScript() {
        let node = document.createElement("script");
        node.src = this.url;
        node.type = "text/javascript";
        node.async = true;
        node.charset = "utf-8";
        document.getElementsByTagName("head")[0].appendChild(node);
    }

}
