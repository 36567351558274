export class PaymentTransaction {
    PaymentTransId: number;
    UserSubscriptionId: number;
    UserId: number
    ModeOfPayment: string;
    CreditCardNum: string;
    TransactionId: string;
    PaidAmount: number;
    PaymentStatus: number;
}
