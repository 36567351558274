import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HomeService } from "../../Shared/home.service";
import { Artist } from "../../models/Artist";
import { LoadScriptService } from 'src/app/loadScript.service';
import { PlayerService } from '../../Shared/player.service';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { Track } from '../../../../Player/ngx-audio-player/src/lib/model/track.model'
import { AlbumService } from 'src/app/Shared/album.service';
import { Playlist } from 'src/app/models/playlist';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from "ngx-spinner";
import { Location } from '@angular/common';
import { UserService } from 'src/app/Shared/user.service';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-artist-profile',
  templateUrl: './artist-profile.component.html',
  styleUrls: ['./artist-profile.component.css']
})
export class ArtistProfileComponent implements OnInit {
  @ViewChild('closeBtn') closeBtn: ElementRef;

  @ViewChild('f') form: NgForm;

  userId: string;
  userDetails: any;
  likeId: any;
  noOfLikeOrDislike: boolean[] = [];
  noLikeImage: boolean;
  favId;
  nofavImage: boolean;
  playlist = new Playlist();
  createchecked: boolean = true;
  updateextchecked: boolean;
  playListDetails: any;
  likedimage: boolean;
  noofLikes: any;
  noLikeImage1: boolean[] = [];
  noFavImage1: boolean[] = [];
  ssid: any;
  constructor(private router: Router, private route: ActivatedRoute,
    private loadScript: LoadScriptService, private spinner: NgxSpinnerService,
    private homeService: HomeService,
    public albumService: AlbumService,
    private playerService: AudioPlayerService,
    private toastr: ToastrService,
    public translate: TranslateService,
    private _playerService: PlayerService,
    public userService: UserService,
    public location: Location) { }
  path;
  Details;
  artistname;
  allsongs;
  tm
  timge;
  nooflike;
  description;
  albumId;
  TransList: Array<any> = [];
  songDetails = new Track();
  albumImage: boolean = true;
  noImageForArtist: boolean = true;
  artistId;
  result;
  param1;
  lm;
  songslikeid;
  songsfavid
  songid: any;
  playd = [];
  index;
  substatus: any;
  songIsthere: boolean = false;
  languageCode: any;
  theme: any;
  IsMobileReq:boolean;
  ngOnInit() {
    if (this.tm == null) {
      this.albumImage = true;
    }
    this.param1 = this.route.snapshot.queryParams["Id"];
    this.loadScript.getServiceBaseUrl();
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.userId = this.userDetails.userId;
    this.IsMobileReq = this.userDetails.isMobileReq;
    this.substatus = this.userDetails.subscriptionStatus;
    let InvId = localStorage.getItem("InvoiceID");
    if (InvId != "0") {
      this.Displayprofie(this.param1, this.userId,this.IsMobileReq)
    }
    this.freshplaylist();
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.languageCode = this.userDetails.languageCode;
    this.theme = this.userDetails.themeId
  }
  freshplaylist() {
    this.homeService.getPlaylist(this.userDetails.userId).subscribe((data: any) => {
      this.playListDetails = data.playListDetails;
      this.homeService.getLatestPlaylist(this.playListDetails);
    })
  }
  Displayprofie(artistId: string, userId: string,IsMobileReq: boolean) {
    this.spinner.show();
    this.homeService.getArtist(artistId, userId).subscribe(Response => {
      this.TransList.push(Response);
      this.path = this.TransList[0].path;
      this.Details = this.TransList[0].artistDetails.album;
      this.albumId = this.TransList[0].artistDetails.album.albumId;

      //this.artistname = this.TransList[0].artistDetails.artistName;
      this.artistname = this.TransList[0].artistDetails.fullName;
      this.description = this.TransList[0].artistDetails.description
      this.allsongs = this.TransList[0].artistDetails.songs;
      this.artistId = this.param1;
      for (var a = 0; a < this.allsongs.length; a++) {
        if (this.allsongs[a].likeId == 0) {
          this.noLikeImage1[a] = false;
        } else {
          this.noLikeImage1[a] = true;
        }
      }
      for (var a = 0; a < this.allsongs.length; a++) {
        if (this.allsongs[a].favId == 0) {
          this.noFavImage1[a] = false;
        } else {
          this.noFavImage1[a] = true;
        }
      }

      let currentSongId = localStorage.getItem('CurrentSongId');
      let getSong = this.allsongs.findIndex(x => x.songId == currentSongId)
      if (getSong != -1) {
        let isSongPlaying = localStorage.getItem('IsPlaying');
        if (isSongPlaying == 'true' || isSongPlaying == null) {
          this.playerService.playPauseBtn[getSong] = true;
        } else if (isSongPlaying == 'false') {
          this.playerService.playPauseBtn[getSong] = false;
        }
      } else {
        this.playerService.playPauseBtn[this.playerService.indexSong] = false;
      }

      this.tm = this.TransList[0].artistDetails.thumbImage;
      this.lm = this.TransList[0].artistDetails.largeImage;
      if (this.tm == null) {
        this.albumImage = false;
      } else {
        this.albumImage = true;
      }
      this.timge = this.path + this.allsongs.thumbImage;
      this.nooflike = this.TransList[0].artistDetails.noofLikes;
      this.likeId = this.TransList[0].artistDetails.likeId;
      if (this.likeId == 0) {
        this.noLikeImage = true;
      } else {
        this.noLikeImage = false;
      }
      this.favId = this.TransList[0].artistDetails.favId;
      if (this.favId == 0) {
        this.nofavImage = true;
      } else {
        this.nofavImage = false;
      }
      this.spinner.hide();
    })
  }

  Viewalbum(artistId: number) {

    this.albumService.albumDetails = this.TransList;
    this.router.navigate(['/album/album'], { queryParams: { Id: artistId } });
  }
  Viewallsongs(artistId: number) {
    this.router.navigate(['/album/allsongs'], { queryParams: { Id: artistId } });
  }

  albumdetails(albumId: number) {
    localStorage.removeItem("AlbumId");
    let val = albumId;
    localStorage.answer = JSON.stringify(val);
    let saved = JSON.parse(localStorage.answer);
    localStorage.setItem("AlbumId", saved);
    localStorage.setItem("image", this.timge);
    this.router.navigate(['/album/album-details'], { queryParams: { Id: albumId } });
  }
  songsdetails(songId: String) {
    this.router.navigate(['/album/songsdetails'], { queryParams: { Id: songId } });
  }
  fetchSongId(i, j) {
    this.playlist.SongId = i;
    this.songslikeid = j;
  }
  fetchSongname(i) {
    this.playlist.title = i;
  }

  LikeArtist(artistId: string, userId: string) {
    this.noLikeImage = !this.noLikeImage;
    this.homeService.LikeArtist(this.artistId, this.userId).subscribe((res: any) => {
      this.result = res.data;
      this.nooflike = this.result.NoofLikes;
    });
  }
  FavouriteArtists(artistId: string, UserId: string) {
    this.nofavImage = !this.nofavImage;
    this.homeService.FavouriteArtist(this.artistId, this.userId).subscribe((res: any) => {
    })
  }
  Likesongs(songId: string, UserId: string, i) {
    this.noLikeImage1[i] = !this.noLikeImage1[i];
    this.homeService.LikeSong(songId, this.userId).subscribe((res: any) => {
      this.result = res.data;
      this.noofLikes = this.result.noofLike;
    }
    )
  }
  FavouriteSongs(songId: string, UserId: string, i) {
    this.noFavImage1[i] = !this.noFavImage1[i];
    //  this.nofavImage = !this.nofavImage;
    this.homeService.FavouriteSongs(this.playlist.SongId, this.userId).subscribe((res: any) => {
      this.result = res.data;
    })

  }
  refreshplaylist() {
    this.homeService.getPlaylist(this.userDetails.userId).subscribe((data: any) => {
      this.playListDetails = data.playListDetails;
      if (this.playListDetails.length >= 10) {
        for (var i = this.index; i < 10; i++) {
          this.playd[i] = this.playListDetails[i];
          this.homeService.playId[i] = this.playListDetails[i];
        }
      } else {
        for (var i = this.index; i < this.playListDetails.length; i++) {
          this.playd[i] = this.playListDetails[i];
          this.homeService.playId[i] = this.playListDetails[i];
        }
      }
    });
  }
  playSong(songs, index) {
    let songId = songs[index].songId;
    localStorage.setItem('CurrentSongId', songId);

    let indexForPlayPause = this.playerService.playPauseBtn.findIndex(x => x == true);
    if (indexForPlayPause == index) {
      localStorage.setItem('IsPlaying', 'false');
      this.playerService.playPauseBtn[index] = !this.playerService.playPauseBtn[index];
    }
    else {
      localStorage.setItem('IsPlaying', 'true');
      if (indexForPlayPause == -1) {
        indexForPlayPause = 0;
      }
      this.playerService.playPauseBtn[indexForPlayPause] = false;
      this.playerService.playPauseBtn[index] = true;
    }
    this._playerService.playSong(songs, this.path, index);

  }
  onSubmit() {

    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.playlist.UserId = this.userDetails.userId;
    this.homeService.addOrUpdateplaylist(this.playlist).subscribe((res: any) => {

      if (res.status == 200) {
        if (this.playlist.PlaylistId != 0 || this.playlist.PlaylistId == undefined) {

          this.closeBtn.nativeElement.click();
          this.toastr.success("Playlist added successfully");
          this.freshplaylist();
          this.createchecked = true;
          this.updateextchecked = false
          this.form.reset();
          this.form.resetForm();

        } else {
          this.closeBtn.nativeElement.click();
          this.toastr.success("Playlist updated successfully");
          this.freshplaylist();
          this.playlist.Name = '';
          this.createchecked = true;
          this.updateextchecked = false
          this.form.reset();
          this.form.resetForm();
        }
      }
      else {
        this.toastr.success("Playlist Already Exists");
        this.freshplaylist();
        this.createchecked = true;
        this.updateextchecked = false
      }
    })
  }

  checked() {
    this.createchecked = true;
    this.updateextchecked = false;
    this.playlist.Name = '';
  }

  updatechecked() {
    this.updateextchecked = true;
    this.createchecked = false;
    this.playlist.PlaylistId = undefined;
  }
  updatecount(songId) {
    this.homeService.updatecount(songId).subscribe((res: any) => {
      this.result = res.data;
    })

  }
  IsCheckLang() {
    if (this.translate.currentLang == 'ar', this.translate.currentLang == 'as') {
      return true;

    } else {
      return false;
    }

  }
  back() {
    this.location.back();
  }
  CheckSubscription(songs, index) {
    if (this.substatus == "False") {

      document.getElementById("SubcheckForm").style.display = "block";
    }
    else {
      this.songIsthere = true;
      this.playSong(songs, index);
    }
  }


  closeSubcheckForm() {
    document.getElementById("SubcheckForm").style.display = "none";
    this.router.navigate(['/account/upgradeplan']);
  }

}
