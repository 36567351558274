import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { LoginService } from 'src/app/Shared/login.service';
import { Router } from '@angular/router';
import { Users } from '../../models/users';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css']
})
export class AdminLoginComponent implements OnInit {
  @ViewChild('closeBtn') closeBtn: ElementRef;
  userName;
  password;
  loginFailed: boolean;
  userDetails = new Users();
  usertype: any;

  constructor(private _loginService: LoginService, private _router: Router, public translate: TranslateService) { }

  ngOnInit() {
  }

  onSubmit() {
    this._loginService.userLogin(this.userName, this.password).subscribe((res: any) => {

      if (res.status == "200") {

        this.userDetails = res.userDetails;
        this.usertype = this.userDetails.userType;
        if (this.usertype == "SUPER_ADMIN") {
          localStorage.clear();
          localStorage.setItem('UserDetails', JSON.stringify(this.userDetails));
          this._router.navigate([`/home`]);
        }
        else {
          // alert("Not a Vaild Admin Please enter Admin Email id");
          this.loginFailed = true;
        }

      } else {
        this.loginFailed = true;
      }
    })
  }
  reset() {
    this.userName = '';
    this.password = '';
  }


}
