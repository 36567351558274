export class Album {
     albumId: any;
     title: any;
     artistId: any;
     fullName: any;
     thumbImage: any;
     length: any;
     createdBy: any;
     public statusId: number;
     releasedOn: any;
     updatedBy: any;
     ////==========base64formatedata Varibles=========////
     Base64Data: any;
     Base64DataForNewRelease: any;
     Base64DataForNewRelease1: any;
     Base64DataForNewRelease2: any;
     Base64DataForNewRelease3: any;
     Base64DataForNewRelease4: any;
     Base64DataForNewRelease5: any;
     FileName: any;
     FileNames:any;
     FileNames1:any;
     FileNames2:any;
     FileNames3:any;
     FileNames4:any;
     FileNames5:any;
     // createdBy: any;
     FileType: any;
     NewFileType:any;
     NewFileType1:any;
     NewFileType2:any;
     NewFileType3:any;
     NewFileType4:any;
     NewFileType5:any;
     isComingUp: any;
     isforKid: boolean; 
     thumbImageNew : any;
     thumbImageNew1 : any;
     thumbImageNew2 : any;
     thumbImageNew3 : any;
     thumbImageNew4 : any;
     thumbImageNew5 : any;
     CoArtistId : string;
}

export class FeaturedAlbums {
     FeatureAlbumId:any;
     AlbumId:any;
     IsFeaturedAlbum:boolean;
     createdBy: any;
     updatedBy: any;
}