import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { LoginService } from 'src/app/Shared/login.service';
import { Problem } from 'src/app/models/problem';
import { Users } from 'src/app/models/users';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { UserService } from 'src/app/Shared/user.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
  userIdError: boolean;
  summaryError: boolean;
  descriptionError: boolean;
  problemDetails = new Problem();
  userDetails = new Users();
  massage: string;
  description;
  problemForm: FormGroup;
  submitted = false;

  constructor(private toastr: ToastrService, private _router: Router, public userService: UserService, private _loginService: LoginService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.problemForm = this.formBuilder.group({
      summary: ['', Validators.required],
    });
    $(document).ready(function () {
      $(".focuss").focus();

    })
  }
  get f() { return this.problemForm.controls; }




  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.problemForm.invalid) {
      return;
    }

    this.problemDetails.userId = this.userDetails.userId;
    this.problemDetails.summary = this.problemForm.get('summary').value;
    this.problemDetails.description = this.description;
    this._loginService.addProblem(this.problemDetails).subscribe((res: any) => {
    })
    this.massage = 'Problem Added Successfully';
    this.successmsg(this.massage);
    this._router.navigate([`/home`]);


  }
  successmsg(msg: string) {
    this.toastr.success(msg)
  }
  successmsg1() {
    this.toastr.success("Toastr Success message", 'Success')
  }
  cancel() {
    this._router.navigate([`/home`]);
  }
}

