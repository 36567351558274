export class Suggestion {
    public userId:number;
    public summary: any;
    public description: any;
    public status:any;
    public updatedBy:any;
    public userName:any;
    public createdOn:any;
}

