import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { LoadScriptService } from 'src/app/loadScript.service';
import { HomeService } from "../../Shared/home.service";
import { Router, ActivatedRoute, Params } from '@angular/router';
import { PlayerService } from '../../Shared/player.service';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { Track } from '../../../../Player/ngx-audio-player/src/lib/model/track.model'
import { Playlist } from 'src/app/models/playlist';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { NgForm } from '@angular/forms';
import { UserService } from 'src/app/Shared/user.service';
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from '@ngx-translate/core';




@Component({
  selector: 'app-playlistsongs',
  templateUrl: './playlistsongs.component.html',
  styleUrls: ['./playlistsongs.component.css']
})
export class PlaylistsongsComponent implements OnInit {
  @ViewChild('closeBtn') closeBtn: ElementRef;

  @ViewChild('f') form: NgForm;
  @ViewChild('audioPlayer') player: ElementRef;


  path: any;
  Details: any;
  artistname: any;
  TransList: Array<any> = [];
  playlistDetail: any;
  songDetails = new Track();
  allsongs: any = [];
  playlist = new Playlist();
  userDetails: any;
  createchecked: boolean = true;
  updateextchecked: boolean;
  playListDetails: any;
  userId: any;
  likeId: any;
  favId: any;
  likedimage: boolean;
  nofavImage: boolean;
  songId: any;
  result: any;
  noofLikes: any;
  index = 0;
  songsforid = [];
  noLikeImage1: boolean[] = [];
  nofavimage1: boolean[] = [];
  title: any;
  substatus: any;
  songIsthere: boolean = false;
  playBtn: boolean[] = [];
  paramId: any;
  languageCode: any;
  theme: any;
  songLength: any;
  topImages: any;
  placeholder: string;
  fileToUpload: File = null;
  files: any;
  noImage: any;
  imgURL: any;
  AdminSongid: any;
  playlistId: number;
  isChecked: boolean[] = [];
  selectedName: string;
  playlistname: any;
  adminUserId: number;
  playlistTrack: any;
  tempIndex: number = 0;
  isSongPlaying: boolean;
  issongplay: boolean = true;

  IsActiveSongId: any;
  currentTime: number = 0;
  duration: number = 0.01;
  fourImages = false;
  singleImage = '';
  ShowPlayer: boolean = true;
  loaderDisplay: boolean = false;
  isSongplay: boolean = false;
  isUserPlyList: boolean = false;
  userImage: any;
  formattedSongLength: string;
  userFullname: any;
  isPlaylist: boolean = false;
  isLoading: boolean;

  constructor(
    private loadScript: LoadScriptService,
    public homeService: HomeService,
    private route: ActivatedRoute,
    private playerService: AudioPlayerService,
    private _playerService: PlayerService,
    private toastr: ToastrService,
    public location: Location,
    private router: Router,
    private spinner: NgxSpinnerService,
    public userService: UserService,
    public translate: TranslateService,
    public playlistService: AudioPlayerService



  ) {
    this.homeService.playListSongs.subscribe((res: any) => {
      this.allsongs = res;
    });
    this.playerService.getIsblogorCountryScreen().subscribe(value => {

      this.IsActiveSongId = value;
    });
    this.playerService.registerPlaySongCallbackno((song) => {
      if (song == true) {
        this.isSongplay = true;
      }
      else {
        this.isSongplay = false;
      }
    });
  }

  ngOnInit() {
    debugger
    localStorage.removeItem('currentSongIndex');
    this.loadScript.getServiceBaseUrl();

    this.playlistname = this.route.snapshot.queryParams["admin"];


    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.substatus = this.userDetails.subscriptionStatus;
    this.userId = this.userDetails.userId;
    this.userFullname = this.userDetails["fullName"];
    this.userImage = this.userDetails["image"];
    if (!this.userImage) {
      this.userImage = "../../../assets/images/NoUserFound.png";
    }
    if (this.playlistname != null) {
      this.paramId = this.route.snapshot.queryParams["Id"];
      this.getAdminpl(this.playlistname);
      this.freshplaylist();
    }
    else {
      this.paramId = this.route.snapshot.queryParams["Id"];
      this.allsong(this.paramId);
      console.log(this.homeService.playListArray)
      if (this.homeService.playListArray.length == 0) {
        // this.title = JSON.parse(localStorage.getItem("service"));
        this.allsong(this.paramId);
      } else {
        //this.title = this.homeService.playlistName.toUpperCase() + this.homeService.playlistName.slice(1);
        this.allsongs = this.homeService.playListArray;
      }
      // this.title = JSON.parse(localStorage.getItem("service"));
      this.allsong(this.paramId);
      if (this.playListDetails == undefined) {
        this.freshplaylist();
      }
    }
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.languageCode = this.userDetails.languageCode;
    this.theme = this.userDetails.themeId
    this.placeholder = 'Upload image';
    // this.playerService.registerPlaySongCallbackno((song) => {
    //   if (song == true) {
    //     this.isSongplay = true;
    //   }
    //   else {
    //     this.isSongplay = false;
    //   }
    // });
    console.log(this.title)
    if (this.songLength) {
      this.formattedSongLength = this.songLength.toString().padStart(2, '0');
    }
  }

  allsong(playlistId: string) {
    debugger;
    this.isLoading=true;
    this.homeService.playlist(playlistId, this.userId).subscribe((Response: any) => {
      console.log(Response)
      this.isPlaylist = true;
      this.allsongs = Response.playlistDetails;
      this.isLoading=false;
      console.log('plsngs', this.allsongs);
      this.TransList.push(Response);
      this.path = this.TransList[0].path;
      //this.songLength = this.TransList[0].playlistDetails.length;
      if (this.allsongs[0].songId) {
        this.songLength = this.allsongs.length;
      } else {
        this.songLength = 0;
      }
      if (this.songLength >= 4) {
        this.topImages = this.allsongs.slice(0, 4);
        this.fourImages = true
      }
      else {
        this.singleImage = this.allsongs[0];
      }
      //if(this.TransList[0].playlistDetails[0].songId!=0){
      this.title = ((Response.playlistDetails[0].name).charAt(0).toUpperCase(1) + (Response.playlistDetails[0].name).slice(1))
      //}else{}
      for (var a = 0; a < this.allsongs.length; a++) {
        if (this.allsongs[a].likeId == 0) {
          this.noLikeImage1[a] = false;
        } else {
          this.noLikeImage1[a] = true;
        }
      }
      for (var a = 0; a < this.allsongs.length; a++) {
        if (this.allsongs[a].favId == 0) {
          this.nofavimage1[a] = false;
        } else {
          this.nofavimage1[a] = true;
        }
      }
      this.playerService.playPauseBtn = [];
      let currentSongId = localStorage.getItem('CurrentSongId');
      let getSong = this.allsongs.findIndex(x => x.songId == currentSongId)
      if (getSong != -1) {
        let isSongPlaying = localStorage.getItem('IsPlaying');
        if (isSongPlaying == 'true' || isSongPlaying == null) {
          this.playerService.playPauseBtn[getSong] = true;
        } else if (isSongPlaying == 'false') {
          this.playerService.playPauseBtn[getSong] = false;
        }
      } else {
        this.playerService.playPauseBtn[this.playerService.indexSong] = false;
      }

      if (this.likeId == 0) {
        this.likedimage = true;
      }
      else {
        this.likedimage = false
      }
      this.favId = this.TransList[0].playlistDetails[0].favId;
      if (this.favId == 0) {
        this.nofavImage = true;
      } else {
        this.nofavImage = false;
      }
      console.log(this.title, (Response.playlistDetails[0].name).charAt(0).toUpperCase(1) + (Response.playlistDetails[0].name).slice(1))
    })
  }

  addToQueue(songs) {

    var getPlayList = this.playerService.getPlaylist();
    var index = getPlayList.findIndex(x => x.link == songs.streamUrl);
    this._playerService.msaapPlaylist = [];
    if (index == -1) {
      let tractObj = new Track;
      tractObj.index = getPlayList.length;
      tractObj.image = this.path + songs.thumbImage;
      tractObj.artistName = songs.artistName;
      tractObj.link = songs.streamUrl;
      tractObj.title = songs.title;
      tractObj.songId = songs.songId;
      getPlayList.push(tractObj);
    }
    this._playerService.msaapPlaylist = getPlayList
    this.toastr.success('Added to Queue Successfully.')
  }


  getplaylist() {
    this.spinner.show();
    this.homeService.getPlaylist(this.userId).subscribe((data: any) => {
      this.playListDetails = data.playListDetails;
      this.homeService.playId = data.playListDetails;
      this.spinner.hide();
    })
  }


  checked() {
    this.createchecked = true;
    this.updateextchecked = false;
  }
  updatechecked() {
    this.updateextchecked = true;
    this.createchecked = false;
    this.playlist.PlaylistId = undefined;
  }
  removefromplaylist(SongId: number) {
    if (confirm('Are you sure you want to Remove from Playlist?')) {
      let paramId = this.route.snapshot.queryParams["Id"];
      this.homeService.removefromplaylist(paramId, this.userId, this.allsongs[SongId].songId).subscribe((res: any) => {
        console.log(res); debugger
        this.TransList = [];
        this.allsong(paramId);
        if (res.playList.noofSongs < 4)
          this.fourImages = false;
      });
    }
  }
  fetchplaylistname(i, j) {
    this.playlist.Name = i;
    this.playlist.PlaylistId = j;
  }
  Likesongs(songId: string, i) {
    debugger
    this.noLikeImage1[i] = !this.noLikeImage1[i];

    this.homeService.LikeSong(songId, this.userId).subscribe((res: any) => {
      if (res.data.LikeId) {
        this.toastr.success("Song liked.");

        if (this.playlistname != null) {
          this.adminsongs(this.paramId);
        }
        else {
          this.allsong(this.paramId);
        }
      }
      else {
        this.toastr.success("song disliked.");

        if (this.playlistname != null) {
          this.adminsongs(this.paramId);
        }
        else {
          this.allsong(this.paramId);
        }
      }
    }
    )
  }
  updatecount(songId) {
    this.homeService.updatecount(songId).subscribe((res: any) => {
    })
  }
  FavouriteSongs(songId: string, i) {
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.userId = this.userDetails.userId;
    this.homeService.FavouriteSongs(songId, this.userId).subscribe((res: any) => {
      debugger;
      this.result = res.data.FavId ? res.data.FavId : 0;
      if (res.data && this.result) {
        this.toastr.success("Song added to favourites.");
        if (this.playlistname != null) {
          this.adminsongs(this.paramId);
        }
        else {
          this.allsong(this.paramId);
        }
      }
      else {
        this.toastr.success("Song removed from favourites.");
        //this.allsong(this.paramId);
        if (this.playlistname != null) {
          this.adminsongs(this.paramId);
        }
        else {
          this.allsong(this.paramId);
        }
      }
    })
  }
  fetchSongId(i, j) {
    this.playlist.SongId = i;
  }
  back() {
    this.location.back();
  }
  closeForm() {
    document.getElementById("Subscriptioncheck").style.display = "none";
    this.router.navigate(['/account/upgradeplan']);
  }
  onSubmit(f) {
    console.log(f.form.status)
    if (f.form.status == 'INVALID') {
      this.toastr.warning("Please enter playlist name!")
      return;
    }
    else {
      this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
      this.playlist.UserId = this.userDetails.userId;
      this.playlist.SongId = this.homeService.albumSongId//this.playerService.getcurrentsongid;

      this.homeService.addOrUpdateplaylist(this.playlist).subscribe((res: any) => {
        if (res.status == 200) {

          this.closeBtn.nativeElement.click();
          this.playlist.PlaylistId = undefined;
          this.updateextchecked = false;
          this.createchecked = true;
          if (f) {
            f.submitted = false;
            this.form.resetForm();
          }
          this.toastr.success("Playlist added successfully");
          this.getplaylist();
        }
        else {

          this.toastr.success("Playlist Already Exists");
        }
      })
    }
  }
  getPlayListDetails(songDetails) {
    debugger
    this.songId = songDetails.songId;
    this.homeService.albumSongId = songDetails.songId
  }
  getsongDetails(event: any) {

  }
  onSubmitforupdate(f) {
    console.log(f)
    if (f.form.status == 'INVALID') {
      this.toastr.warning("Please select the existing playlist name!")
      return;
    }
    else {
      this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
      this.playlist.UserId = this.userDetails.userId;
      this.playlist.SongId = this.songId;
      this.spinner.show();

      this.homeService.addOrUpdateplaylist(this.playlist).subscribe((res: any) => {
        if (res.status == 200) {
          if (this.playlist.PlaylistId! == 0) {
            this.freshplaylist();
            this.toastr.success("Playlist added successfully");
            this.isChecked = [];
            this.playlist.Name = '';
            this.createchecked = true;
            this.updateextchecked = false;
            this.form.resetForm();
            this.form.reset();
          } else {
            this.closeBtn.nativeElement.click();
            this.freshplaylist();
            this.toastr.success("Playlist updated successfully");
            this.playlist.Name = '';
            this.isChecked = [];
            this.createchecked = true;
            this.updateextchecked = false;
            this.form.resetForm();
            this.form.reset();
          }
          this.spinner.hide();
        }
        else {
          this.toastr.success("Playlist Already Exists");
          this.spinner.hide();

        }
        this.spinner.hide();
      }, err => {
        this.toastr.error(err)
        this.spinner.hide();
      });
    }
  }
  freshplaylist() {
    this.homeService.getPlaylist(this.userId).subscribe((data: any) => {
      this.playListDetails = data.playListDetails;
      this.playListDetails.forEach((p, i) => { if (p.playlistId == this.paramId) { this.playListDetails.splice(i, 1) } })

    })
  }

  getAdminpl(name) {

    this.title = name;
    this.adminsongs(this.paramId);
    this.isUserPlyList = true;
  }

  adminsongs(playlistId: string) {
    // this.adminUserId = 2
    debugger;
    this.isLoading=true;
    this.homeService.adminPlaylist(playlistId, this.userId).subscribe((Response: any) => {
      this.isPlaylist = false;
      this.allsongs = Response.playlistDetails;
      this.TransList.push(Response);
      this.path = this.TransList[0].path;
      this.isLoading=false;
      //this.allsongs = this.TransList[0].playlistDetails;
      if (this.allsongs[0].songId) {
        this.songLength = this.allsongs.length;
      } else {
        this.songLength = 0;
      }
      for (var a = 0; a < this.songLength; a++) {
        if (this.allsongs[a].likeId == 0) {
          this.noLikeImage1[a] = false;
        } else {
          this.noLikeImage1[a] = true;
        }
      }
      for (var a = 0; a < this.songLength; a++) {
        if (this.allsongs[a].favId == 0) {
          this.nofavimage1[a] = false;
        } else {
          this.nofavimage1[a] = true;
        }
      }
      if (this.songLength >= 4) {
        this.topImages = this.allsongs.slice(0, 4);
        this.fourImages = true
      }
      else {
        this.singleImage = this.allsongs[0];
      }


    })
  }



  // playSongs(songs, index) {

  //   if (songs.length > 1) {
  //     this._playerService.msaapPlaylist = [];
  //     for (var i = 0; i < songs.length; i++) {
  //       const tractObj = new Track();
  //       tractObj.index = i;
  //       tractObj.image = this.path + songs[i].thumbImage;
  //       tractObj.artistName = songs[i].artistName;
  //       tractObj.link = songs[i].streamUrl;
  //       tractObj.title = songs[i].title;
  //       tractObj.songId = songs[i].songId;
  //       this._playerService.msaapPlaylist.push(tractObj);
  //     }

  //     if (this.playerService.repeatSong) {
  //       this.playerService.repeatSong = false;
  //     }
  //     this.playerService.setPlaylist(this._playerService.msaapPlaylist);
  //     this.playerService.selectATrack(index + 1);
  //     this._playerService.updateData(this._playerService.msaapPlaylist);
  //     this.playerService.playSongCallback(true);
  //   }
  //   else {
  //     this._playerService.msaapPlaylist = [];
  //     const tractObj = new Track();
  //     tractObj.index = index
  //     tractObj.image = this.path + songs.thumbImage;
  //     tractObj.artistName = songs.artistName;
  //     tractObj.link = songs.streamUrl;
  //     tractObj.title = songs.title;
  //     tractObj.songId = songs.songId;
  //     this._playerService.msaapPlaylist.unshift(tractObj);
  //     this.playerService.setPlaylist(this._playerService.msaapPlaylist);
  //     this.playerService.selectATrack(index);
  //     this._playerService.updateData(songs);
  //     this.playerService.playSongCallback(true);

  //   }
  // }

  private areArraysSameDeep(a: any[], b: any[]): boolean {
    return a.length === b.length && a.every((val, index) => val === b[index]);
  }

  playAllsongs(items, play) {
    let currentIndex = items.findIndex(x => x.songId == Number(localStorage.getItem('CurrentSongId')))
    if (play == 'play') {
      if (!this.areArraysSameDeep(this._playerService.msaapPlaylist, items)) {
        this._playerService.msaapPlaylist = [];
        if (currentIndex != -1) {
          this.CheckSubscription(items, currentIndex);
        }
        else {
          this.CheckSubscription(items, 0);
        }
        setTimeout(() => {
          this.isSongplay = true;
        }, 2000);
      }
      else if (this._playerService.msaapPlaylist == items) {
        this.playerService.playSongCallback(true);
        this.playlistService.playlistCallbackmain(this.paramId, this.isPlaylist);
      }
      else {
        this.playerService.playSongCallback(true);
        this.playlistService.playlistCallbackmain(this.paramId, this.isPlaylist);
      }
    }
    else {
      this.playerService.currentTime = this.player.nativeElement.currentTime;
      this.isSongplay = false;

      this.playerService.playSongCallback(false);
      this.playlistService.playlistCallbackmain(this.paramId, this.isPlaylist);

    }

  }
  CheckSubscription(song, i) {
    this.playSongs(song, i);

  }

  Artistprofile(ArtistId: number) {
    this.router.navigate(['/newdiscover'], { queryParams: { Id: ArtistId } });
  }

  playSongs(songs, index) {
    debugger;
    this._playerService.msaapPlaylist = [];
    songs.forEach((song, i) => {
      const trackObj = new Track();
      trackObj.index = i;
      trackObj.image = this.path + song.thumbImage;
      trackObj.artistName = song.artistName;
      trackObj.link = song.streamUrl;
      trackObj.title = song.title;
      trackObj.songId = song.songId;
      this._playerService.msaapPlaylist.push(trackObj);
    });
    this.playerService.playSongCallback(true);
    this.playlistService.playlistCallbackmain(this.paramId, this.isPlaylist);

    if (this.playerService.repeatSong) {
      this.playerService.repeatSong = false;
    }
    this.playerService.setPlaylist(this._playerService.msaapPlaylist);
    this.playerService.selectATrack(index + 1);
    this._playerService.updateData(this._playerService.msaapPlaylist);
  }

}


