import { Component, OnInit } from '@angular/core';
import { LoadScriptService } from 'src/app/loadScript.service';
import { HomeService } from '../../Shared/home.service';
import { ArtistsService } from '../../Shared/artists.service';
import { Playlist } from 'src/app/models/playlist';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { UserService } from 'src/app/Shared/user.service';
import { AlbumService } from 'src/app/Shared/album.service';
@Component({
  selector: 'app-allartists',
  templateUrl: './allartists.component.html',
  styleUrls: ['./allartists.component.css']
})
export class AllartistsComponent implements OnInit {
  userDetails: any;
  userId: any;
  favitems: any;
  favdetailitems: any;
  favalbums = [];
  favsongs = [];
  favartists = [];
  favdjmix = [];
  fdjmix = [];
  favplaylist: any;
  playListDetails = [];
  fplayLis = [];
  path: any;
  index = 0;
  falbums = [];
  fartists = [];
  playlist = new Playlist();
  ArtistLikeImage: boolean[] = [];
  Artistfavimage: boolean[] = [];
  result: any;
  artistId: any;
  substatus: any;
  songIsthere: boolean = false;
  languageCode: any;
  theme: any;
  IsMobileReq:boolean;
  constructor(
    private loadScript: LoadScriptService, private _homeService: HomeService, private router: Router,
    public artistsService: ArtistsService, public translate: TranslateService,
    public location: Location, public userService: UserService,
    private albumService: AlbumService

  ) { }

  ngOnInit() {
    this.loadScript.getServiceBaseUrl();
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.userId = this.userDetails.userId;
    this.IsMobileReq = this.userDetails.isMobileReq;
    this.substatus = this.userDetails.subscriptionStatus;
    this.getfavourites();

    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.languageCode = this.userDetails.languageCode;
    this.theme = this.userDetails.themeId
  }
  getfavourites() {
    let a = '';
    let b = 0;
    let c = 0;
    this.artistsService.Getallfavartist(this.userId, a, b, c).subscribe((data: any) => {
      this.favitems = data;
      this.favartists = this.favitems.viewAllFavouriteArtists;
      this.path = this.favitems.path;
      for (var a = 0; a < this.favartists.length; a++) {
        if (this.favartists[a].likeId == 0) {
          this.ArtistLikeImage[a] = false;
        } else {
          this.ArtistLikeImage[a] = true;
        }
      }
      for (var a = 0; a < this.favartists.length; a++) {
        if (this.favartists[a].favId == 0) {
          this.Artistfavimage[a] = false;
        } else {
          this.Artistfavimage[a] = true;
        }
      }
    });
  }
  refreshartist() {
    let a = '';
    let b = 0;
    let c = 0;
    this.artistsService.Getallfavartist(this.userId, a, b, c).subscribe((data: any) => {
      this.artistsService.playId = data.viewAllFavouriteArtists
    });
  }
  Artistprofile(ArtistId: number) {
    this.router.navigate(['/artist/artistprofile'], { queryParams: { Id: ArtistId } });
  }
  LikeArtist(artistId: string, userId: string, i) {
    this.ArtistLikeImage[i] = !this.ArtistLikeImage[i];
    this._homeService.LikeArtist(artistId, this.userId).subscribe((res: any) => {
      this.result = res.data;
    });
  }
  FavouriteArtists(artistId: string, UserId: string, i) {
    this.Artistfavimage[i] = !this.Artistfavimage[i];
    this._homeService.FavouriteArtist(artistId, this.userId).subscribe((res: any) => {
      this.refreshartist();
    })
  }
  IsCheckLang() {
    if (this.translate.currentLang == 'ar', this.translate.currentLang == 'as') {
      return true;
    } else {
      return false;
    }
  }
  back() {
    this.location.back();
  }
}
