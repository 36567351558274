import { Component, OnInit } from '@angular/core';
import { Users } from 'src/app/models/users';
import { UserService } from 'src/app/Shared/user.service';

@Component({
  selector: 'app-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.css']
})
export class MainFooterComponent implements OnInit {

  languageCode: any = 'en';
  abouttoby: any;
  termscondbt: boolean = false;
  termscondbtns: boolean = false;
  termscondbtcoks: boolean = false;
  termscondbtads: boolean = true;
  years: number[] = [];
  Toby: boolean = false;
  Communities: boolean = false;
  LegalInformation: boolean = false;
  requiredError: boolean = false;
  // private purchaseSubscription: Subscription;
  selectedPlanId: any;
  termscondbtart: boolean = false;
  termscondbtinv: boolean = false;
  termscondbtabt: boolean = false;
  termscondbtwork: boolean = false;
  termscondbtcontact: boolean = false;
  result: any;
  terms: any;
  lanCode: any;
  Code: any;
  heading: string = "";
  userDetails = new Users();
  emailid: any;
  username: any;
  userId: any;

  constructor(public userService: UserService,) { }

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.emailid = this.userDetails.email;
    this.username = this.userDetails.userName;
    this.userId = this.userDetails.userId;
  }


  getContentByCode(Code) {
    this.lanCode = this.languageCode.toUpperCase()
    this.Code = Code + this.lanCode;
    this.userService.GetContentByCode(this.Code).subscribe((data: any) => {
      this.result = data.data.pageContent;
      this.terms = data.data.pageContentTitle;
    })
  }
  getAboutUsByCode(Code) {
    // this.lanCode = this.languageCode.toUpperCase()
    // this.Code = Code + this.lanCode;
    this.userService.GetContentByCode(Code).subscribe((data: any) => {
      this.abouttoby = data.data.pageContent;
      this.result = data.data.pageContent;
      this.terms = data.data.pageContentTitle;
      this.Toby = true;
      this.Communities = false;
      this.LegalInformation = false;
      this.heading = "Toby";
      this.term('ABOUT_TOBY_EN_LIGHT');
    })
  }

  term(Code) {

    if (Code == 'TOBY_WORK_' || Code == 'TOBY_CONTACT_' || Code == 'ABOUT_TOBY_EN_LIGHT') {
      this.Toby = true;
      this.Communities = false;
      this.LegalInformation = false;
      this.heading = "Toby";
    }
    if (Code == 'TOBY_MUSIC_CONDITIONS_AND_TERMS_OF_USE_' || Code == 'TOBY_MUSIC_PRIVACY_POLICY_' || Code == 'TOBY_MUSIC_COOKIE_POLICY_' || Code == 'TOBY_MUSIC_ADVERTISING_') {
      this.Toby = false;
      this.Communities = false;
      this.LegalInformation = true;
      this.heading = "Legal Information";
    }
    if (Code == 'TOBY_ARTISTS_' || Code == 'TOBY_INVESTORS_') {
      this.Toby = false;
      this.Communities = true;
      this.LegalInformation = false;
      this.heading = "Communities";
    }
    console.log(this.Toby, this.Communities, this.LegalInformation, "Tobyyyy")
    if (Code != 'ABOUT_TOBY_EN_LIGHT') {
      this.getContentByCode(Code);
    }
    if ('TOBY_MUSIC_CONDITIONS_AND_TERMS_OF_USE_' == Code) {
      this.termscondbt = true;
      this.termscondbtns = false;
      this.termscondbtcoks = false;
      this.termscondbtads = false;

    }
    else if ('TOBY_MUSIC_PRIVACY_POLICY_' == Code) {
      this.termscondbtns = true;
      this.termscondbt = false;
      this.termscondbtcoks = false;
      this.termscondbtads = false;

    }
    else if ('TOBY_MUSIC_COOKIE_POLICY_' == Code) {
      this.termscondbtcoks = true;
      this.termscondbtns = false;
      this.termscondbt = false;
      this.termscondbtads = false;

    }
    else if ('TOBY_MUSIC_ADVERTISING_' == Code) {
      this.termscondbtcoks = false;
      this.termscondbtns = false;
      this.termscondbt = false;
      this.termscondbtads = true;

    }
    else if ('TOBY_ARTISTS_' == Code) {
      this.termscondbtart = true;
      this.termscondbtinv = false;
    }
    else if ('TOBY_INVESTORS_' == Code) {
      this.termscondbtinv = true;
      this.termscondbtart = false;
    }
    else if ('ABOUT_TOBY_EN_LIGHT' == Code) {
      this.termscondbtabt = true;
      this.termscondbtwork = false;
      this.termscondbtcontact = false;
    }
    else if ('TOBY_WORK_' == Code) {
      this.termscondbtabt = false;
      this.termscondbtwork = true;
      this.termscondbtcontact = false;
    }
    else if ('TOBY_CONTACT_' == Code) {
      this.termscondbtabt = false;
      this.termscondbtwork = false;
      this.termscondbtcontact = true;
    }
    else {
      this.termscondbt = false;
      this.termscondbtns = false;
      this.termscondbtcoks = false;
      this.termscondbtads = false;

    }
  }
}
