export class Faq {
    public faqId:any;
    public faqTitle:string;
    public faqDescription:string;
    public faqOrder:number;
    public faqType:string;
    public  CreatedOn:Date;
    public  UpdatedOn:Date;
    public  CreatedBy:number;
    public  UpdatedBy:number;
}
