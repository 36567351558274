import { Component, OnInit } from '@angular/core';
import { LoadScriptService } from 'src/app/loadScript.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/Shared/user.service';
import { ToastrService } from 'ngx-toastr';
import { Pagination } from 'src/app/models/pagination';
import { PaginationServiceForUserService } from 'src/app/Shared/pagination-service-for-user.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  UserId: any;
  Userlist: any;
  // pageIndex: any=1;
  pageSize: any = Pagination.fifth;
  columName: any = "User Name";
  sortedOrder: any = "asc";
  searchName; any;
  searchForUser;

  first: Number = Pagination.first;
  second: Number = Pagination.second;
  third: Number = Pagination.third;
  fourth: Number = Pagination.fourth;
  fifth: Number = Pagination.fifth;

  firstrow: boolean = false;
  secondrow: boolean = false;
  thirdrow: boolean = false;
  fourthrow: boolean = false;
  fifthrow: boolean = true;
  Checkrecords: boolean = false;
  pageField = [];
  tempPageIndex: number;
  prev: boolean = false;
  next: boolean = false;
  searchHistory: boolean = false;
  userlist: any;
  npPageIndex: boolean = false;
  totalpage: number;
  activePage: boolean[] = [];
  userperpage: number;
  pageNumber: any;
  arrow: boolean;
  path: any;
  tempData: number = 1;
  tempArray = [];
  tempArrayPath: any;
  tempDataArray: any;
  //currentpage: any;


  //Pagination Variables
  pageNumberPerPage = 0;
  pageNumberShow = Pagination.displayNoOfPagesPerPage;
  temppage: number = 0;
  disabledNextBtn: boolean;
  disabledPrevBtn: boolean = true;
  exactPageList: any;
  prevtrue: boolean;
  nexttrue: boolean;
  //currentPage = 1;
  showNoOfCurrentPage: any = 1;
  showPageOnlyOntabsChange: boolean = true;
  paginationData: number;
  pageIndex: any = 1;

  noDataFound: boolean = false;
  imgURL: any;

  constructor(private loadScript: LoadScriptService,
    private router: Router,
    private _toastr: ToastrService,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    public paginationService: PaginationServiceForUserService, public translate: TranslateService) { }

  ngOnInit() {
    this.loadScript.getServiceBaseUrl();
    this.paginationService.temppage = 0;
    this.paginationService.currentPage = 1;
    this.paginationService.showNoOfCurrentPage = 1;

    this.paginationService.showPageOnlyOntabsChange = true;
    this.searchName = this.userService.searchText;
    this.activePage[0] = true;
    this.getUserDetails();
  }

  searchUser($event, i) {
    this.Checkrecords = false;
    if ($event == "") {
      this.pageField = [];
      this.pageIndex = 1;
      this.tempPageIndex = 1;
      this.prev = false;
      this.next = false;
      this.searchHistory = false;
      this.userlist = [];
      this.userlist = this.tempArray;
      this.path = this.tempArrayPath;
      this.getPageNumber(this.tempDataArray);
      this.getRows(this.fifth);
    }
    if ($event != '') {
      this.searchName = $event;
      this.pageField = [];
      this.pageIndex = 1;
      this.getUserDetails();
      //this.npPageIndex=true;
      for (var x = 0; x < this.totalpage + 1; x++) {
        this.activePage[x] = false;
      }
      if (i == 0) {
        this.activePage[0] = true;
      }
      else {
        this.activePage[i] = true;
      }
    }
  }

  getUserDetails() {
    this.spinner.show();
    this.userService.getUserDetails(this.columName, this.sortedOrder, this.searchName, this.pageIndex, this.pageSize).subscribe((data: any) => {

      if (this.tempData == 1) {
        this.tempDataArray = data;
        this.tempArray = data.users;
        this.tempArrayPath = data.path;
        this.tempData = 0;
      }

      this.Userlist = data.users;
      this.imgURL = data.path;
      this.getPageNumber(data);

      this.spinner.hide();

    });
  }

  deleteUser(userId) {
    if (confirm('Please note! This action can NOT be undone. Are you sure you want to delete?')) {
      this.userService.deleteUser(userId).subscribe((response) => {
        if (response.isSuccess == "true") {
          this._toastr.success("User has been deleted successfully");
          this.getUserDetails();
        }
      }, err => { this._toastr.error(err) });
    }
  }

  ViewUserProfile(userid: string) {
    this.router.navigate([`/admin/viewuserprofile/` + userid + ``]);
  }



  getPageNumber(data) {
    // for(var i=this.pageField.length;i>0;i--)
    // {
    //   this.pageField.pop();
    // }
    // if (data.count > this.pageSize) {
    //   this. userperpage = Number(data.count / this.pageSize);
    //   for (var a = 0; a < this. userperpage; a++) {
    //     this.pageField[a] = a + 1;
    //     this.pageNumber = this.pageField[a];
    //     this.totalpage = a;
    //   }
    //   if (this.pageNumber == this.pageIndex) {
    //     this.next = false;
    //   } else {
    //     this.next = true;
    //   }
    // }
    // else if (this.Userlist.length == 0) {
    //   this.Checkrecords = true;
    //   this.searchHistory = true;

    // } else {
    //   this.Checkrecords = false;

    //   this.pageField = [];
    //   this.pageField[0] = 1;
    // }

    if (this.Userlist.length == 0) {
      this.pageField = [];
      this.noDataFound = true;
      this.Checkrecords = true;
    } else {
      this.noDataFound = false;
      this.Checkrecords = false;
      this.paginationData = Number(data.count / this.pageSize);
      let tempPageData = this.paginationData.toFixed();
      if (Number(tempPageData) < this.paginationData) {
        this.exactPageList = Number(tempPageData) + 1;
        this.paginationService.exactPageList = this.exactPageList;
      } else {
        this.exactPageList = Number(tempPageData);
        this.paginationService.exactPageList = this.exactPageList
      }

      this.paginationService.pageOnLoad();
      this.pageField = this.paginationService.pageField;

    }


  }

  getRows(page) {
    var k;
    var lis = document.getElementById("page1").getElementsByTagName("li");
    for (k = 0; k < lis.length; k++) {
      lis[k].removeAttribute("style");
    }
    if (page == Pagination.first) {
      this.firstrow = true;
      this.secondrow = false;
      this.thirdrow = false;
      this.fourthrow = false;
      this.fifthrow = false;
      this.pageIndex = 1;
      this.paginationService.temppage = 0;
      this.pageSize = Pagination.first;
      this.activePage[0] = true;
      this.getUserDetails();
    }
    else if (page == Pagination.second) {
      this.firstrow = false;
      this.secondrow = true;
      this.thirdrow = false;
      this.fourthrow = false;
      this.fifthrow = false;
      this.pageIndex = 1;
      this.paginationService.temppage = 0;
      this.pageSize = Pagination.second;
      this.activePage[0] = true;
      this.getUserDetails();
    }
    else if (page == Pagination.third) {
      this.firstrow = false;
      this.secondrow = false;
      this.thirdrow = true;
      this.fourthrow = false;
      this.fifthrow = false;
      this.pageIndex = 1;
      this.paginationService.temppage = 0;
      this.pageSize = Pagination.third;
      this.activePage[0] = true;
      this.getUserDetails();
    }
    else if (page == Pagination.fourth) {
      this.firstrow = false;
      this.secondrow = false;
      this.thirdrow = false;
      this.fourthrow = true;
      this.fifthrow = false;
      this.pageIndex = 1;
      this.paginationService.temppage = 0;
      this.pageSize = Pagination.fourth;
      this.activePage[0] = true;
      this.getUserDetails();
    }
    else if (page == Pagination.fifth) {
      this.firstrow = false;
      this.secondrow = false;
      this.thirdrow = false;
      this.fourthrow = false;
      this.fifthrow = true;
      this.pageIndex = 1;
      this.paginationService.temppage = 0;
      this.pageSize = Pagination.fifth;
      this.activePage[0] = true;
      this.getUserDetails();
    }
  }
  showNextUsers(page, i) {

    //   var k;
    //   var lis = document.getElementById("page1").getElementsByTagName("li");
    //   for( k=0;k<lis.length;k++)
    //      {
    //        lis[k].removeAttribute("style");
    //      }
    //  lis[i].style.color="orange";
    this.pageIndex = page;

    this.activePage = [];
    this.activePage[i] = true;

    this.getUserDetails();
    // this.activePage[0] = false;
  }


  nextPage() {
    if (this.paginationService.disabledNextBtn == false) {
      this.activePage = [];
      this.activePage[0] = true;
      this.paginationService.nextPage();
      this.pageIndex = this.paginationService.pageField[0];
      this.getUserDetails();
    }

  }

  previous() {
    if (this.paginationService.disabledPrevBtn == false) {
      this.activePage = [];
      this.activePage[0] = true;
      this.paginationService.previous();
      this.pageIndex = this.paginationService.pageField[0];
      this.getUserDetails();
    }

  }

  orderBy(coloumnName) {
    var i = 0;
    var t = document.getElementsByTagName("th");
    for (i = 0; i < t.length; i++) {
      t[i].removeAttribute("style");
    }
    // ="#bec1c3";
    let r = document.getElementById(coloumnName);
    r.style.color = "orange";
    this.columName = coloumnName;
    if (this.sortedOrder == "asc") {
      this.sortedOrder = "desc";

    } else {
      this.sortedOrder = "asc";

    }
    this.getUserDetails();
  }

}
