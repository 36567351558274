import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlbumService } from 'src/app/Shared/album.service';
import { ArtistsService } from 'src/app/Shared/artists.service';
import { SongsService } from 'src/app/Shared/songs.service';
import { ConfigService } from 'src/app/app.config';
import { Location } from '@angular/common';
import { UserHomeBanner, Users } from 'src/app/models/users';
import { UserService } from 'src/app/Shared/user.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-add-update-homebanner',
  templateUrl: './add-update-homebanner.component.html',
  styleUrls: ['./add-update-homebanner.component.css']
})
export class AddUpdateHomebannerComponent implements OnInit {

  artists: any;
  albums: any;
  songs: any;
  imageType: any;
  isArtist: boolean = false;
  isAlbum: boolean = false;
  isSongs: boolean = false;
  ispromotion: boolean = false;
  numbers: any;
  x;
  i = 0;
  homeBanner = new UserHomeBanner();
  userDetails = new Users();
  imgURL: any;
  fileToUpload: File;
  files: any;
  imgURLMobile: any;
  fileToUploadMobile: File;
  filesMobile: any;
  noImage: boolean = true;
  noImageM: boolean = true;
  bannerlist: any;
  timgN: any;
  timgM: any;
  songDropdownSettings: IDropdownSettings = {}
  albumDropdownSettings: IDropdownSettings = {}
  artistDropDownSettings: IDropdownSettings = {}
  textName: any;
  searchTexts: string = "";
  selectedItems: any;
  objType: any;
  selectedSongIds: any;
  selectedSongs = [];
  allSongs: any;
  edit: boolean;
  limgN: any;
  limgM: any;
  bannerDetails: any;
  IsDuplicate: boolean = false;
  //artistPhoto: any;

  constructor(private _songsService: SongsService, private _artistsService: ArtistsService,
    private formBuilder: FormBuilder, private albumService: AlbumService,
    public location: Location,
    public translate: TranslateService,
    private spinner: NgxSpinnerService,
    private configService: ConfigService,
    private userService: UserService,
    private toastr: ToastrService, private route: ActivatedRoute) {
    var i = 1
    this.numbers = new Array(50).fill(0).map((x, i) => i + 1);
    console.log(this.numbers);
    if (this.route.snapshot.paramMap.get("id")) {
      this.homeBanner.HomeBannerId = this.route.snapshot.paramMap.get("id");
      setTimeout(() => {
        this.getbannerdetailsbyId(this.homeBanner.HomeBannerId);
      }, 1000);
    }
    else {
      this.homeBanner.HomeBannerId = 0;
    }
  }

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.getAlbum();
    this.getArtists();
    this.getSongs(this.searchTexts);
    this.songDropdownSettings = {
      singleSelection: true,
      idField: 'songId',
      textField: 'title',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'Data Not Found!',
    };

    this.albumDropdownSettings = {
      singleSelection: true,
      idField: 'albumId',
      textField: 'title',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: false,
      noDataAvailablePlaceholderText: 'Data Not Found!',
    };
    this.artistDropDownSettings = {
      singleSelection: true,
      idField: 'artistId',
      textField: 'fullName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'Data Not Found!',

    };
    this.getallbannerdetails();
  }

  search(e) {
    debugger;
    console.log('wo', e.data);
    this.textName = e.target.value
    console.log('wor', e.target.value);
    if (this.textName == null || this.textName == '') {
      this.searchTexts = '';
      this.getSongs(this.searchTexts);
    }
    else {
      this.searchTexts = this.textName;
      this.getSongs(this.searchTexts);
    }
  }

  getArtists() {
    //this._songsService.getArtists();
    this._songsService.getArtists().subscribe((data: any) => {
      this.artists = data.artistDetails;
    });
  }

  getAlbum() {
    this.albumService.getalbum().subscribe((data: any) => {
      this.albums = data.albums;
      console.log(data);
    });
  }

  // getSongs() {
  //   //this._songsService.getArtists();
  //   this._songsService.getSongs().subscribe((data: any) => {
  //     this.songs = data.songs;
  //     console.log('song', data);
  //   });
  // }

  getSongs(searchTexts) {
    debugger;
    if (searchTexts != '') {
      this._songsService.getSongs(searchTexts, this.userDetails.userId).subscribe((data: any) => {
        this.songs = data.songs;
        this.allSongs = data.songs;
        console.log('song', data);
      });
    }
    else {
      this._songsService.getSongs(searchTexts, this.userDetails.userId).subscribe((data: any) => {
        this.songs = data.songs.slice(0, 100);
        this.allSongs = data.songs;
        console.log('song', data);
      });
    }
  }

  selectType(e: any) {
    debugger;
    this.imageType = e.target.value;
    console.log(this.imageType);
    if (this.imageType == "1") {
      this.isArtist = true;
      this.isAlbum = false;
      this.isSongs = false;
      this.ispromotion = false;
    }
    if (this.imageType == "2") {
      this.isAlbum = true;
      this.isSongs = false;
      this.ispromotion = false;
      this.isArtist = false;
    }
    if (this.imageType == "3") {
      this.isSongs = true;
      this.getSongs(this.searchTexts);
      this.ispromotion = false;
      this.isArtist = false;
      this.isAlbum = false;
    }
    if (this.imageType == "4") {
      this.ispromotion = true;
      this.isArtist = false;
      this.isAlbum = false;
      this.isSongs = false;
    }
    this.clearForm();
  }

  back() {
    this.location.back();
  }

  selectPhoto(file: FileList) {
    this.homeBanner.WebthumbImage = file.item(0).name;
    this.fileToUpload = file.item(0);
    var File = this.fileToUpload.name;
    this.homeBanner.fileName = File.split('.')[0];
    this.files = File.split('.');
    this.homeBanner.fileType = this.files[this.files.length - 1];
    //this.artistDetails.FileType = File.split('.')[1];
    this.noImage = false;
    //Show Image Preview
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imgURL = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.homeBanner.base64Data = btoa(binaryString);
  }

  onSubmit() {
    debugger;
    if (this.noImage || this.noImageM) {
      return;
    }
    if (this.noImage) {
      this.homeBanner.base64Data = null;
    } else {
      this.homeBanner.base64Data = this.imgURL.split(",")[1];
    }
    if (this.noImageM) {
      this.homeBanner.base64DataMobile = null;
    } else {
      this.homeBanner.base64DataMobile = this.imgURLMobile.split(",")[1];
    }
    this.homeBanner.SongId = this.selectedSongIds;
    console.log(this.homeBanner);

    if (this.IsDuplicate) {
      this.toastr.error("Order Already Exist.");
      return false;
    }

    // if (this.isArtist) {
    //   alert("isArtist");
    //   const validSelectedItems = this.bannerDetails
    //     .filter(item => item.artistId == this.homeBanner.ArtistId && item.orderImage == this.homeBanner.OrderImage);
    //   if (validSelectedItems.length > 0) {
    //     this.toastr.error("Order Already Exist.");
    //     return false;

    //   }

    // }
    // if (this.isAlbum) {
    //   const validSelectedItems = this.bannerDetails
    //     .filter(item => item.albumId == this.homeBanner.AlbumId && item.orderImage == this.homeBanner.OrderImage);
    //   if (validSelectedItems.length > 0) {
    //     this.toastr.error("Order Already Exist.");
    //     return false;

    //   }

    // }
    // if (this.isSongs) {
    //   const validSelectedItems = this.bannerDetails
    //     .filter(item => item.songId == this.homeBanner.SongId && item.orderImage == this.homeBanner.OrderImage);
    //   if (validSelectedItems.length > 0) {
    //     this.toastr.error("Order Already Exist.");
    //     return false;

    //   }

    // }
    // if (this.ispromotion) {


    // }

    this.userService.addupdatehomeBanner(this.homeBanner).subscribe((data: any) => {
      debugger;
      console.log(data);
      if (data.status == 200) {
        if (this.homeBanner.HomeBannerId != 0) {
          this.toastr.success("Banner updated successfully");
          this.spinner.hide();
        } else {
          this.toastr.success("Banner added successfully");
          this.spinner.hide();
        }
        this.location.back();
      }
    }, err => {
      this.toastr.error(err)
      this.spinner.hide();
    })
  }

  getbannerdetailsbyId(homebannerId) {
    debugger;
    this.edit = true;
    this.userService.getBannerDetailsById(homebannerId).subscribe((data: any) => {
      console.log(data);
      debugger;
      this.bannerlist = data.homeBannerById[0];
      console.log(this.bannerlist);
      this.homeBanner.OrderImage = this.bannerlist.orderImage;
      if (this.bannerlist.webThumbImage != '' || this.bannerlist.webThumbImage != null) {
        this.timgN = this.bannerlist.webThumbImage.split('/');
        this.timgN = this.timgN[this.timgN.length - 1];
        this.homeBanner.WebthumbImage = this.timgN;
       // this.artistPhoto = this.homeBanner.WebthumbImage;
        console.log(this.homeBanner.WebthumbImage);
        this.imgURL = this.bannerlist.webThumbImage;
        this.noImage = false;
      }
      if (this.bannerlist.webLargeImage != '' || this.bannerlist.webLargeImage != null) {
        this.limgN = this.bannerlist.webLargeImage.split('/');
        this.limgN = this.limgN[this.limgN.length - 1];
        this.homeBanner.WeblargeImage = this.limgN;
        this.imgURL = this.bannerlist.webLargeImage;
        this.noImage = false;
      }
      if (this.bannerlist.mobThumbImage != null) {
        this.timgM = this.bannerlist.mobThumbImage.split('/');
        this.timgM = this.timgM[this.timgM.length - 1];
        this.homeBanner.MobthumbImage = this.timgM;
        //console.log(this.homeBanner.WebthumbImage);
        this.imgURLMobile = this.bannerlist.mobThumbImage;

        this.noImageM = false;
      }
      if (this.bannerlist.mobLargeImage != null) {
        this.limgM = this.bannerlist.mobLargeImage.split('/');
        this.limgM = this.limgM[this.limgM.length - 1];
        this.homeBanner.MoblargeImage = this.limgM;
        this.imgURLMobile = this.bannerlist.mobLargeImage;
        this.noImageM = false;
      }
      if (this.bannerlist.artistId != 0) {
        this.homeBanner.ImageTypeId = 1;
        this.homeBanner.ArtistId = this.bannerlist.artistId;
        this.isArtist = true;
        this.isAlbum = false;
        this.isSongs = false;
        this.ispromotion = false;
      }
      if (this.bannerlist.albumId != 0) {
        this.homeBanner.ImageTypeId = 2;
        this.homeBanner.AlbumId = this.bannerlist.albumId;
        this.isArtist = false;
        this.isAlbum = true;
        this.isSongs = false;
        this.ispromotion = false;
      }
      if (this.bannerlist.songId != 0) {
        this.homeBanner.ImageTypeId = 3;
        //this.homeBanner.SongId = this.bannerlist.songId;
        this.selectedItems = this.bannerlist;
        this.isArtist = false;
        this.isAlbum = false;
        this.isSongs = true;
        this.ispromotion = false;
        var songIds = this.bannerlist.songId;
        var seletecids = this.allSongs.find(x => x.songId === songIds); debugger
        var obj = {
          "songId": seletecids.songId,
          "title": seletecids.title
        }
        this.selectedSongs.push(obj);
        console.log(this.selectedSongs);
        // const validSelectedItems = this.selectedSongs
        //   .filter(item => item.songId);
        // const selectedItemsString = validSelectedItems.map(item => item.songId).join(',');
        // var obj = ""
        // var str = obj + selectedItemsString;
        // this.objType = str;
        // this.selectedSongIds = selectedItemsString;
        // this.selectType("3");
      }
      if (this.bannerlist.promotion != null) {
        this.homeBanner.ImageTypeId = 4;
        this.homeBanner.Promotion = this.bannerlist.promotion;
        this.isArtist = false;
        this.isAlbum = false;
        this.isSongs = false;
        this.ispromotion = true;
        //this.selectType("4");
      }
    });
  }

  onItemSelect(item) {
    this.objType = item.songId;
    this.selectedSongIds = this.objType;
  }

  onItemDeSelect(item: any) {
    debugger;
    this.objType = null;
    this.selectedSongIds = null;
    console.log('deleted', this.objType);
  }


  selectMobilePhoto(fileMobile: FileList) {
    debugger;
    this.homeBanner.MobthumbImage = fileMobile.item(0).name;
    this.fileToUploadMobile = fileMobile.item(0);
    var File = this.fileToUploadMobile.name;
    this.homeBanner.fileNameMobile = File.split('.')[0];
    this.filesMobile = File.split('.');
    this.homeBanner.fileTypeMobile = this.filesMobile[this.filesMobile.length - 1];
    //this.artistDetails.FileType = File.split('.')[1];
    this.noImageM = false;
    //Show Image Preview
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imgURLMobile = event.target.result;
    }
    reader.readAsDataURL(this.fileToUploadMobile);
  }

  getallbannerdetails() {
    this.userService.getAllBannerDetails().subscribe((data: any) => {
      this.bannerDetails = data.bannerDetails;
      console.log(data);
    });
  }
  checkDuplicate() {
    debugger;
    const validSelectedItems = this.bannerDetails
      .filter(item => item.orderImage == this.homeBanner.OrderImage);
    if (this.edit) {
      if (validSelectedItems.length > 0 && validSelectedItems[0].orderImage != this.bannerlist.orderImage) {
        this.toastr.error("Order Already Exist.");
        this.IsDuplicate = true;
        return false
      }
      else {
        this.IsDuplicate = false;
      }
    }
    else {
      if (validSelectedItems.length > 0) {
        this.toastr.error("Order Already Exist.");
        this.IsDuplicate = true;
        return false
      }
      else {
        this.IsDuplicate = false;
      }
    }


  }

  clearForm(){
    this.homeBanner.AlbumId = 0;
    this.homeBanner.ArtistId = 0;
    this.homeBanner.SongId = 0;
    this.homeBanner.Promotion = null;
  }

}
