import { Component, OnInit } from '@angular/core';
import { LoadScriptService } from 'src/app/loadScript.service';
import { SubscriptionService } from '../../Shared/subscription.service'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Users } from '../../models/users';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/Shared/user.service';
import { DatePipe } from '@angular/common';
import { ConfigService } from 'src/app/app.config';
@Component({
  selector: 'app-upgrade-sunscription',
  templateUrl: './upgrade-sunscription.component.html',
  styleUrls: ['./upgrade-sunscription.component.css']
})
export class UpgradeSunscriptionComponent implements OnInit {
  userDetails = new Users();
  userId: number;
  emailid: any;
  username: any;
  subplanname: any;
  subscription: any;
  subplandesc: any;
  subplanprice: any;
  subplannMulti: any;
  subplandescmulti: any;
  subplanpricemulti: any;
  createchecked: boolean = true;
  updateextchecked: boolean;
  showPaymentSection: boolean = false;
  showconfirmpayment: boolean = false;
  showHidePlan: boolean = true;
  email: any;
  BraintrreId: any;
  massage: string;
  b: any = 0;
  chargeAmount: any;
  braintreeid: any;
  bpainid: any;
  updateprice: any;
  subscriptionId: any;
  braintreeSubscriptionId: any;
  braintreecustomerId: any;
  Date: Date;
  endDate: number;
  date: Date;
  currantDate: string;
  price: any;
  discount: number;
  baseUrl: any;
  clientTokenUrl: string;
  constructor(private loadScript: LoadScriptService,
    private subscriptionService: SubscriptionService,
    private http: HttpClient,
    private router: Router, public userService: UserService,
    private toastr: ToastrService, private datePipe: DatePipe,
    private configService: ConfigService
  ) {
    this.baseUrl = configService.getServiceBaseUrl();
    this.clientTokenUrl = this.baseUrl + 'api/Paypal/GenerateToken';
  }

  ngOnInit() {
    this.date = new Date();
    this.date.setDate(this.date.getDate() + 3);
    this.loadScript.getServiceBaseUrl();
    this.getSubscriptionplan();
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.emailid = this.userDetails.email;
    this.username = this.userDetails.userName;
    this.subscriptionId = this.userDetails.braintreeSubscriptionId;
    this.braintreecustomerId = this.userDetails.braintreeCustomerId;

    this.Date = new Date();
    this.currantDate = this.datePipe.transform(new Date(), "MMM d, y");
    this.Date = new Date();

    this.endDate = (this.Date.setDate(this.Date.getDate() + 30));
  }
  addForPayment() {
    this.showPaymentSection = true;
    this.showHidePlan = false;
    this.chargeAmount = this.subscription.subscriptionDetails[this.b].offeredprice;
  }
  
  getSubscriptionplan() {
    this.subscriptionService.getSubscriptionplan().subscribe((data: any) => {
      this.subscription = data;

      this.subplanname = this.subscription.subscriptionDetails[0].planName;
      this.subplandesc = this.subscription.subscriptionDetails[0].planDescription;
      this.subplanprice = this.subscription.subscriptionDetails[0].offeredprice;
      this.subplannMulti = this.subscription.subscriptionDetails[1].planName;

      this.subplandescmulti = this.subscription.subscriptionDetails[1].planDescription;
      this.subplanpricemulti = this.subscription.subscriptionDetails[1].offeredprice;
      this.price = this.subscription.subscriptionDetails[1].price;
      this.discount = this.price - this.subplanpricemulti;
      
    });
  }

  singledevice() {
    this.subscriptionService.getSubscriptionplan().subscribe((data: any) => {
      this.b = 0;
      this.subscription.subscriptionPlans[0].brainTreePlanId;

    });
  }
  updatechecked() {
    this.b = 1;
    this.updateextchecked = true;
    this.createchecked = false;
  }
  UpdateCustomerPurchase = (nonce: string, chargeAmount: number, Userid: number, email: string, name: string, BraintrreId: string, bpainid: string, updateprice: string) => {
    debugger
    this.userId = this.userDetails.userId;
    this.email = this.userDetails.email;
    this.username = this.userDetails.fullName;
    this.braintreeSubscriptionId = this.userDetails.braintreeSubscriptionId;

    this.bpainid = this.subscription.subscriptionDetails[this.b].brainTreePlanId;
    this.updateprice = this.subscription.subscriptionDetails[this.b].price;
    this.braintreecustomerId = this.userDetails.braintreeCustomerId;

    this.BraintrreId = this.subscription.subscriptionDetails[this.b].brainTreePlanId;
    this.chargeAmount = this.subscription.subscriptionDetails[this.b].offeredprice;

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });debugger
    return this.http
      .post(this.baseUrl + 'api/PayPal/UpdateBraintreeplan', { nonce: nonce, chargeAmount: this.chargeAmount, Userid: this.userId, email: this.email, bpainid: this.bpainid, BraintrreId: this.BraintrreId, updateprice: this.updateprice, braintreeSubscriptionId: this.braintreeSubscriptionId, braintreecustomerId: this.braintreecustomerId, name: this.username }, { 'headers': headers })
      .subscribe((response: any) => { debugger
        // return response;
        var retrievedObject = localStorage.getItem('UserDetails');
        var jsObj = JSON.parse(retrievedObject);
        this.massage = 'Payment done Successfully';
        jsObj.braintreeSubscriptionId = response.subscriptionId;
        localStorage.setItem('UserDetails', JSON.stringify(jsObj))

        if (response == false) {
          this.toastr.success('Pre-Approved Payment enabled PayPal account required for vaulting.')
          this.showHidePlan = true;
          this.showPaymentSection = false;
        }
        else {
          var retrievedObject = localStorage.getItem('UserDetails');
          var jsObj = JSON.parse(retrievedObject);
          this.massage = 'Payment done Successfully';
          jsObj.subscriptionStatus = "True";
          jsObj.braintreeSubscriptionId = response.subscriptionId;
          localStorage.setItem('UserDetails', JSON.stringify(jsObj))
          this.toastr.success('Subscription Update done successfully.');
          this.router.navigate([`/home`]);
        }
      },
        err => {
          console.log(err)
          this.toastr.error('Something  Went Wrong ! Please Try Again Later');
          this.showHidePlan = true;
          this.showPaymentSection = false;

        });
  }
  onPaymentStatus(event){
    console.log(event)
  }
}


