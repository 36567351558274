import { Component, OnInit, ViewChild } from '@angular/core';
import { LoadScriptService } from 'src/app/loadScript.service';
import { UserService } from 'src/app/Shared/user.service';
import { Users } from 'src/app/models/users';
import { Pagecontent } from 'src/app/models/pagecontent';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import * as ClassicEditor  from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-editterms',
  templateUrl: './editterms.component.html',
  styleUrls: ['./editterms.component.css']
})
export class EdittermsComponent implements OnInit {
  name = 'ng2-ckeditor';
  ckeConfig: any;
  mycontent: string;
  log: string = '';
  @ViewChild("myckeditor") ckeditor: any;

  pagecontent = new Pagecontent();
  userDetails = new Users();
  languageCode: any;
  Code: any;
  result: any;
  terms: any;
  pagecntent: any;
  pgcontent: any = undefined;
  submitted = false;
  public editorConfig = {
    toolbar: [
      'heading', '|',
      'bold', 'italic', 'underline', 'strikethrough', '|',
      'link', 'bulletedList', 'numberedList', 'blockQuote', 'codeBlock', '|',
      'insertTable', 'imageUpload', 'mediaEmbed', '|',
      'alignment', 'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', '|',
      'undo', 'redo'
    ],
    image: {
      toolbar: [
        'imageTextAlternative', 'imageStyle:full', 'imageStyle:side'
      ]
    },
    table: {
      contentToolbar: [
        'tableColumn', 'tableRow', 'mergeTableCells'
      ]
    }
    
  };
  public Editor = ClassicEditor;
  constructor(private loadScript: LoadScriptService, private _userService: UserService, public location: Location, private _router: Router, private toastr: ToastrService) {
  }

  ngOnInit() {
    this.mycontent="";
    this.GetAllContent();
    this.ckeConfig = {
      allowedContent: false,
      //extraPlugins: 'divarea',
      forcePasteAsPlainText: true
    };

    this.loadScript.getServiceBaseUrl();
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.languageCode = this.userDetails.languageCode;
    //this.getContentByCode();
  }
  getContentByCode(Code) {
    this._userService.GetContentByCode(Code).subscribe((data: any) => {
      this.pagecontent = data.data;
      this.mycontent = data.data.pageContent;
    })
  }

  onChange($event: any): void {
  }
  onSubmit() {
    this.pagecontent.PageContent = this.mycontent;
    this._userService.AddUpdateContent(this.pagecontent).subscribe((data: any) => {
      this.toastr.success("Page content updated successsfully");
      this._router.navigate(['/home']);

    })
  }
  GetAllContent() {
    this._userService.GetAllContents().subscribe((data: any) => {
      this.pagecntent = data.data;
    })
  }
  pagecont(page) {

    this.mycontent = '';
    this.getContentByCode(page);

  }
  cancel() {
    this._router.navigate(['/home']);
  }
}
