import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/Shared/user.service';
import { MailNotification, Notification } from 'src/app/models/notification';
import * as ClassicEditor  from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-manage-notification',
  templateUrl: './manage-notification.component.html',
  styleUrls: ['./manage-notification.component.css']
})
export class ManageNotificationComponent implements OnInit {
  notificationId: number;
  notification = new Notification();
  subject: any;
  description: string;
  pushDescription: any;
  tittle: any;
  isInvalidSubject: boolean = false;
  isInvalidDesc: boolean = false;
  isInvalidPushNotif: boolean = false;
  mailNotification = new MailNotification();

  ckeConfig: any;
  @ViewChild("myckeditor") ckeditor: any;
  public editorConfig = {
    toolbar: [
      'heading', '|',
      'bold', 'italic', 'underline', 'strikethrough', '|',
      'link', 'bulletedList', 'numberedList', 'blockQuote', 'codeBlock', '|',
      'insertTable', 'imageUpload', 'mediaEmbed', '|',
      'alignment', 'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', '|',
      'undo', 'redo'
    ],
    image: {
      toolbar: [
        'imageTextAlternative', 'imageStyle:full', 'imageStyle:side'
      ]
    },
    table: {
      contentToolbar: [
        'tableColumn', 'tableRow', 'mergeTableCells'
      ]
    }
  };
  public Editor = ClassicEditor;

  constructor(private route: ActivatedRoute, private _userService: UserService, private toastr: ToastrService) { }

  ngOnInit() {
    this.mailNotification.Descriptions= "";
    this.notificationId = Number(this.route.snapshot.paramMap.get("id"));
    console.log(this.notificationId);
    this.getNotificationById();
  }

  getNotificationById() {
    this._userService.getNotificationById(this.notificationId).subscribe((notif: any) => {
      console.log(notif);
      this.notification = notif.notification;
      this.tittle = notif.notification.title;
    })
  }

  sendMail() {
    debugger;
    if ((this.mailNotification.Subject == undefined || this.mailNotification.Subject == "") && (this.mailNotification.Descriptions == undefined || this.mailNotification.Descriptions == "")) {
      this.isInvalidSubject = true;
      this.isInvalidDesc = true;
      return;
    }
    else if (this.mailNotification.Subject == undefined || this.mailNotification.Subject == "") {
      this.isInvalidSubject = true;
      return;
    }
    else if (this.mailNotification.Descriptions == undefined || this.mailNotification.Descriptions == "") {
      this.isInvalidDesc = true;
      return;
    }
    this.mailNotification.NotificationId = this.notificationId;
    this._userService.sendMail(this.mailNotification).subscribe((res: any) => {
      console.log(res);
      if (res.isSuccess == true) {
        this.toastr.success("Email Sent successfully");
        this.mailNotification.Subject = '';
        this.mailNotification.Descriptions = '';
      }
      else {
        this.toastr.error("email not Sent");
      }
    });
  }

  sendPush() {
    console.log(this.notificationId, this.tittle, this.pushDescription);
    if (this.pushDescription == undefined || this.pushDescription == "") {
      this.isInvalidPushNotif = true;
    }
    this._userService.SendPushNotification(this.notificationId, this.tittle, this.pushDescription).subscribe((res: any) => {
      console.log(res);
    })
  }

}
