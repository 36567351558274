import { Component, OnInit } from '@angular/core';
import { LoadScriptService } from 'src/app/loadScript.service';
import { HomeService } from '../../Shared/home.service';
import { AlbumService } from '../../Shared/album.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { UserService } from 'src/app/Shared/user.service';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { PlayerService } from '../../Shared/player.service';
import { ConfigService } from 'src/app/app.config';
@Component({
  selector: 'app-favalbum',
  templateUrl: './favalbum.component.html',
  styleUrls: ['./favalbum.component.css']
})
export class FavalbumComponent implements OnInit {
  userDetails: any;
  favitems: any;
  favalbums: any;
  path: any;
  userId: any;
  LikeAlbumImage: boolean[] = [];
  FavAlbumImage: boolean[] = [];
  result: any;
  albumid: any;
  languageCode: any;
  theme: any;
  playPauseBtn: any;
  baseUrl: string;

  constructor(private loadScript: LoadScriptService,
    public albumService: AlbumService,
    private homeService: HomeService,
    private router: Router,
    private playerService: AudioPlayerService,
    public userService: UserService,
    public translate: TranslateService,
    private _playerService: PlayerService,
    private configService: ConfigService,
    public location: Location) {
    this.baseUrl = configService.getServiceBaseUrl();
  }

  ngOnInit() {
    this.loadScript.getServiceBaseUrl();
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.userId = this.userDetails.userId;
    this.getfavouritealbums();
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.languageCode = this.userDetails.languageCode;
    this.theme = this.userDetails.themeId
    this.refreshalbum();
  }
  getfavouritealbums() {
    let a = '';
    let b = 0;
    let c = 0;
    this.albumService.Getallfavlbum(this.userId, a, b, c).subscribe((data: any) => {
      this.favitems = data;
      this.favalbums = this.favitems.viewAllFavouriteAlbums;
      this.path = this.favitems.path;
      for (var a = 0; a < this.favalbums.length; a++) {
        if (this.favalbums[a].likeId == 0) {
          this.LikeAlbumImage[a] = false;
        } else {
          this.LikeAlbumImage[a] = true;
        }
      }
      for (var a = 0; a < this.favalbums.length; a++) {
        if (this.favalbums[a].favId == 0) {
          this.FavAlbumImage[a] = false;
        } else {
          this.FavAlbumImage[a] = true;
        }
      }

    });

  }
  refreshalbum() {
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.userId = this.userDetails.userId;
    let a = '';
    let b = 0;
    let c = 0;
    this.albumService.Getallfavlbum(this.userId, a, b, c).subscribe((data: any) => {
      this.albumService.playId = data.viewAllFavouriteAlbums;
      this.path = this.favitems.path;
    })
  }
  albumdetails(albumId: number) {

    this.router.navigate(['/album/album-details'], { queryParams: { Id: albumId } });
  }
  playSong(songs, index) {
    this.playPauseBtn = [];
    this.playPauseBtn[index] = true;
    localStorage.setItem('CurrentSongId', songs[index].songId);
    this.playerService.indexSong = 0;
    let path = this.baseUrl + "uploads/";
    this._playerService.playSong(songs, path, index);
  }
  IsCheckLang() {
    if (this.translate.currentLang == 'ar') {
      return true;
    } else {
      return false;
    }
  }
  LikeAlbums(albumId: string, UserId: string, i) {
    this.LikeAlbumImage[i] = !this.LikeAlbumImage[i];
    this.homeService.LikeAlbums(albumId, this.userId).subscribe((res: any) => {
      this.result = res.data;
    })
  }
  FavouriteAlbums(albumId: string, UserId: string, i) {
    this.FavAlbumImage[i] = !this.FavAlbumImage[i];
    // 
    //this.favalbums=  this.favalbums.slice(i);
    this.homeService.FavouriteAlbums(albumId, this.userId).subscribe((res: any) => {
      //this.getfavouritealbums();
      this.refreshalbum();

    })

  }
  CheckSubscription(song, index) {
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    if (this.userDetails.subscriptionStatus == "False") {
      document.getElementById("Subscriptioncheck").style.display = "block";
    }
    else {
      this.playSong(song, index);
    }
  }
  closeForm() {
    document.getElementById("Subscriptioncheck").style.display = "none";
    this.router.navigate(['/account/upgradeplan']);
  }
  back() {
    this.location.back();
  }

}
