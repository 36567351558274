import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HomeService } from "../../Shared/home.service";
// import { Artist } from "../../models/Artist";

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit {
  userDetails: any;
  userId: any;
  IsMobileReq: boolean;

  constructor(private router: Router, private homeService: HomeService) { }
  // TransList: Array<Artist> = [];
  artistId = 1;
  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.userId = this.userDetails.userId;
    this.IsMobileReq = this.userDetails.isMobileReq;
    let InvId = localStorage.getItem("InvoiceID");
    if (InvId != "0") {
      this.SalesInvoiceEdit(InvId, this.userId)
    }
  }

  SalesInvoiceEdit(artistId: string, userId: string) {
    this.homeService.getArtist(artistId, userId).subscribe(Response => {
      // this.TransList.push(Response)
    })
  }
}
