import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AlbumService } from 'src/app/Shared/album.service';
import { ArtistsService } from 'src/app/Shared/artists.service';
import { PaginationServiceForAlbum } from 'src/app/Shared/paginationserviceForAlbum.service';
import { LoadScriptService } from 'src/app/loadScript.service';

@Component({
  selector: 'app-admin-howto',
  templateUrl: './admin-howto.component.html',
  styleUrls: ['./admin-howto.component.css']
})
export class AdminHowtoComponent implements OnInit {
  howtolist: any;

  constructor(private loadScript: LoadScriptService,
    private router: Router,
    private albumService: AlbumService, private spinner: NgxSpinnerService,
    private _toastr: ToastrService, private route: ActivatedRoute,
    public paginationService: PaginationServiceForAlbum, public translate: TranslateService,
    private _artistsService: ArtistsService) { }

  ngOnInit() {
    this.GetAllHowTo();
  }


  orderBy(){

  }

  add(){
    this.router.navigate(['/admin/howto/add-update-howto']);
  }


  GetAllHowTo(){
    this._artistsService.GetAllHowto().subscribe((res: any) => {
        console.log(res);
        this.howtolist = res.allhowto.list;
      // if (res.status == 200) {
      //   console.log(res);
      // }
    })
  }

  editHowto(e){
    debugger;
    this.router.navigate([`/admin/howto/update-howto/` + e + ``]);
  }

  deleteHowto(item){
    debugger;
    if (confirm('Please note! Are you sure you want to delete Howto?')) {
      this._artistsService.deleteHowto(item).subscribe((response) => {
        if (response) {
          this._toastr.success("How To Deleted Succesfully");
          this.GetAllHowTo();
        }
      }, err => {
        this._toastr.error(err)
        this.spinner.hide();
      });
    }
  }

  
}
