import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ArtistsService } from '../Shared/artists.service';
import { Users } from '../models/users';
import { SongsService } from '../Shared/songs.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AlbumService } from '../Shared/album.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PlayerService } from '../Shared/player.service';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Track } from 'Player/ngx-audio-player/src/lib/model/track.model';
import { ViewportRuler } from '@angular/cdk/scrolling';
declare var bootstrap: any;
import { Modal } from 'bootstrap';
import { Playlist } from '../models/playlist';
import { HomeService } from '../Shared/home.service';
import { NgForm } from '@angular/forms';
import { SharedService } from '../Shared/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-user-discover',
  templateUrl: './user-discover.component.html',
  styleUrls: ['./user-discover.component.css']
})
export class UserDiscoverComponent implements OnInit, AfterViewInit {
  artistdata: any = [];
  SearchText = "";
  pageIndex = 1;
  pageSize = 1;
  totalRows: number = 0;
  userDetails = new Users();
  isArtist = true;
  isSong = false;
  isAlbum = false;
  allSongs: any;
  searchTexts: string = "";
  itemSize: number;
  filteredSongs: any[] = [];
  filterAlbum: any[] = [];
  filterArtists: any[] = [];
  playlist = new Playlist();
  @ViewChild(CdkVirtualScrollViewport) songtable!: CdkVirtualScrollViewport;
  @ViewChild(CdkVirtualScrollViewport) viewport!: CdkVirtualScrollViewport;
  isDiscover = false;

  private destroy$: Subject<void> = new Subject<void>();
  albums: any;
  selectedChar: string | null = null;
  songid: any;
  favId: any;
  likeId: any;
  SelectedId: number = 0;
  songIsthere: boolean = false;
  IsActiveSongId: any;
  defaultItemSize: number = 80;
  refreshCarousel: boolean = false;
  createchecked: boolean = true;
  updateextchecked: boolean;
  playListDetails: any;
  userId: any;
  @ViewChild('closeBtn') closeBtn: ElementRef;
  @ViewChild('f') form: NgForm;

  constructor(private _artistService: ArtistsService, private _songService: SongsService,
    private _albumService: AlbumService, private cdr: ChangeDetectorRef,
    private _playerService: PlayerService,
    public playerService: AudioPlayerService,
    private router: Router,
    private toastr: ToastrService,
    private viewportRuler: ViewportRuler,
    private _homeService: HomeService,
    private sharedService: SharedService,
    private spinner: NgxSpinnerService
  ) {
    this.playerService.getIsblogorCountryScreen().subscribe(value => {
      //console.info('san: ' + value);
      this.IsActiveSongId = value;
    });
    this.updateViewportHeight();
    //this.calculateItemSize();
  }

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.userId = this.userDetails.userId
    this.getArtist();
    this.getSongs(this.SearchText);
    this.calculateItemSize();
    this.freshplaylist();
    document.body.style.overflow = 'hidden'; 
  }

  alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

  allChips = [
    { isActive: true, id: 1, name: "Artists" },
    { isActive: false, id: 2, name: "Albums" },
    { isActive: false, id: 3, name: "Songs" },
  ]

  scrollTo(char: string) {

    this.selectedChar = char;
    // const section = document.querySelector(`[data-section="${char}"]`);
    // if (section) {
    //   section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    // }
    if (this.isArtist) {
      const index = this.filterArtists.findIndex(artist => artist.fullName[0].toUpperCase() === char);
      if (index != -1) {
        const rowElement = document.getElementById(`song-${index}`);
        this.viewport.scrollToIndex(index, 'smooth');
        // const rowElement = document.getElementById(`song-${index1}`);
        // const adjustedIndex = Math.max(index1 + 2, 0);
        // this.songtable.scrollToIndex(adjustedIndex , 'smooth')
        if (rowElement) {
          // const offset = rowElement.offsetTop;
          // this.viewport.scrollToOffset(offset);
        }// Delay to ensure viewport has scrolled to the approximate position
      }
      else {
        this.toastr.warning(`No Artist with Alphabet ${char} exist!`);
      }
    }

    if (this.isSong) {
      //   const index = this.allSongs.findIndex(song => song.title[0].toUpperCase() === char);
      // if (index !== -1) {
      //   this.viewport.scrollToIndex(index, 'smooth');
      // }
      // const index = this.filteredSongs.findIndex(song => song.title[0].toUpperCase() === char);
      // if (index !== -1) {
      //   this.viewport.scrollToIndex(index, 'smooth');
      //   setTimeout(() => {
      //     // Adjust for the exact alignment
      //     const exactIndex = this.filteredSongs.findIndex(song => song.title[0].toUpperCase() === char);
      //     if (exactIndex !== -1) {
      //       this.viewport.scrollToIndex(exactIndex, 'auto');
      //     }
      //   }, 100);
      // }

      // const index = this.filteredSongs.findIndex(song => song.title[0].toUpperCase() === char);
      // if (index !== -1) {
      //   this.viewport.scrollToIndex(index);
      // }

      this.selectedChar = char;
      // const index = this.filteredSongs.findIndex(song => song.title[0].toUpperCase() === char);
      // if (index !== -1) {
      //   this.viewport.scrollToIndex(index, 'smooth');
      // }

      const index = this.filteredSongs.findIndex(song => song.title[0].toUpperCase() === char);
      if (index !== -1) {
        const rowElement = document.getElementById(`song-${index}`);
        this.songtable.scrollToIndex(index, 'smooth');
        // const rowElement = document.getElementById(`song-${index}`);
        if (rowElement) {
          // const offset = rowElement.offsetTop;
          // this.songtable.scrollToOffset(offset);
        }// Delay to ensure viewport has scrolled to the approximate position
      }
      //  if (index != -1) {
      //   const adjustedIndex = Math.max(index + 2, 0);
      //   this.songtable.scrollToIndex(adjustedIndex , 'smooth')
      //  }


      // const index = this.filteredSongs.findIndex(song => song.title[0].toUpperCase() === char);
      // if (index !== -1) {
      //   this.viewport.scrollToIndex(index);
      //   // Adjust for the exact alignment
      //   setTimeout(() => {
      //     this.viewport.scrollToIndex(index, 'auto');
      //   }, 300); // Adjust timeout duration as needed
      // }

      // const firstCharIndex = this.filteredSongs.findIndex(song => song.title[0].toUpperCase() === char);
      // if (firstCharIndex !== -1) {
      //   this.viewport.scrollToIndex(firstCharIndex, 'smooth');
      //   // Ensure the selected section is displayed at the top
      //  // this.viewport.elementRef.nativeElement.scrollTop = 0;
      // }
      else {
        this.toastr.warning(`No Song with Alphabet ${char} exist!`);
      }
    }

    if (this.isAlbum) {
      const index1 = this.filterAlbum.findIndex(album => album.title[0].toUpperCase() === char);
      if (index1 != -1) {
        const rowElement = document.getElementById(`song-${index1}`);
        this.viewport.scrollToIndex(index1, 'smooth');
        // const rowElement = document.getElementById(`song-${index1}`);
        // const adjustedIndex = Math.max(index1 + 2, 0);
        // this.songtable.scrollToIndex(adjustedIndex , 'smooth')
        if (rowElement) {
          // const offset = rowElement.offsetTop;
          // this.viewport.scrollToOffset(offset);
        }// Delay to ensure viewport has scrolled to the approximate position
      }
      else {
        this.toastr.warning(`No Album with Alphabet ${char} exist!`);
      }
    }
    // const prefix = String.fromCharCode(char.charCodeAt(0) - 1);
    // const suffix = String.fromCharCode(char.charCodeAt(0) + 1);
    // this.filteredSongs = this.allSongs.filter(song => {
    //   const firstChar = song.title[0].toUpperCase();
    //   return firstChar === char || firstChar === prefix || firstChar === suffix;
    // });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    //console.log('chk width', event.target.innerWidth);
    this.calculateItemSize();
    document.body.style.overflow = 'hidden'; 
  }

  // scrollTo(char: string) {
  //   debugger;
  //   const section = document.querySelector(`[data-section='${char}']`);
  //   if (section) {
  //     const headerOffset = 120; // Height of your fixed header
  //     const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset;
  //     const offsetPosition = sectionPosition - headerOffset;
  //     section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  //     window.scrollTo({
  //       top: offsetPosition,
  //       behavior: 'smooth'
  //     });
  //   }
  // }



  getArtist() {
    this._artistService.GetArtist(this.SearchText, this.pageIndex, this.pageSize, this.userDetails.userId).subscribe(
      (response: any) => {
        if (response.isSuccess) {
          //console.log(response.artist);
          this.artistdata = response.artist;
          // this.GetArtistDetailsForMatPills.push(...response.artist.artists);
          // this.totalPage = response.artist.totalPages;
          // this.pageIndex = response.artist.currentPage;
          // this.pageSize = response.artist.pageSize;
          // this.totalRows = response.artist.totalRows;

          this.sortSongs('artist')
        } else {
          console.log("No records found");
        }
      }
    )
  }

  all(id: any, index: number) {
    const ele = document.getElementsByClassName('alpha');
    for (let j = 0; j < ele.length; j++) {
      ele[j].setAttribute("style", "color: #e2dddd")
    }
    for (let i = 0; i < this.allChips.length; i++) {
      this.allChips[i].isActive = false
    }
    this.allChips[index].isActive = true;
    // console.log(id, index, this.allChips);
    //this.totalPage = 0;

    // for resetting to [] of pills A-Z
    //this.resetAlphaColor();
    if (id == 1 && !this.isArtist) {
      //this.SelectedId = 0;
      this.SearchText = "";
      this.pageIndex = 100;
      this.pageSize = 1000;
      this.isArtist = true;
      this.isSong = false;
      this.isAlbum = false;

      // this.isPillsSelected = false;
      // this.GetArtistDetails = [];
      // this.GetArtistDetailsForMatPills = [];
      //this.serarchArtist = null;
      // this.GetArtistdata(this.SearchText, this.pageIndex, this.pageSize);
      //this.GetInitialArtistdata(this.SearchText, this.pageIndex, this.pageSize);
      // this.GetArtistdata("", 0, 0);
      //this.GetArtistdata(this.SearchText, this.pageIndex, this.pageSize);
    }
    if (id == 2 && !this.isAlbum) {
      // this.SelectedId = 0;

      // this.SearchText = "";
      // this.pageIndex = 1;
      // this.pageSize = 1000;
      this.isSong = false;
      this.isArtist = false;
      this.isAlbum = true;
      this.GetAllAlbums();
      //this.isPillsSelected = false;

      //this.albums = [];
      //this.AlbumsForMatPills = [];
      // this.GetAllAlbums();
      // this.GetAllAlbumsByUserId();
      // this.GetAllAlbumData(this.SearchText, this.pageIndex, this.pageSize);
      //this.GetAllAlbumInitialData(this.SearchText, this.pageIndex, this.pageSize);
    }
    if (id == 3 && !this.isSong) {
      //this.SelectedId = 0;
      this.SearchText = "";
      this.pageIndex = 1;
      this.pageSize = 30;
      this.isSong = true;
      this.isArtist = false;
      this.isAlbum = false;

      //this.isPillsSelected = false;
      // this.GetAllSongs();
      //this.songs = []
      //this.SongsForMatPills = [];
      // this.GetAllSongsdata(this.SearchText, this.pageIndex, this.pageSize);
      // this.GetAllSongsInitialdata(this.SearchText, this.pageIndex, this.pageSize);
    }

    window.scrollTo(0, 0);
  }

  getSongs(searchTexts) {

    this._songService.getSongs(searchTexts, this.userDetails.userId).subscribe((data: any) => {
      this.allSongs = data.songs;
      //this.filteredSongs = this.allSongs;
      this.sortSongs('song');
    });
  }

  sortSongs(type): void {
    if (type === "song") {
      const filteredSongs = this.allSongs.filter(song => /^[A-Za-z]/.test(song.title));
      // Sort the filtered songs alphabetically by title with null checks
      this.filteredSongs = filteredSongs.sort((a, b) => {
        if (!a.title) return 1; // Treat null or undefined title as greater
        if (!b.title) return -1; // Treat null or undefined title as less
        return a.title.localeCompare(b.title);
      });
      //console.log(this.filteredSongs);
    }
    if (type === "artist") {
      //console.log(this.artistdata)
      const filteredArtists = this.artistdata.filter(artist => /^[A-Za-z]/.test(artist.fullName));
      // Sort the filtered songs alphabetically by title with null checks
      this.filterArtists = filteredArtists.sort((a, b) => {
        if (!a.fullName) return 1; // Treat null or undefined fullName as greater
        if (!b.fullName) return -1; // Treat null or undefined fullName as less
        return a.fullName.localeCompare(b.fullName);
      });
      //console.log(this.filterArtists);
    }
    else {
      if (this.albums) {

        const filteredAlbums = this.albums.filter(a => /^[A-Za-z]/.test(a.title));
        // Sort the filtered albums alphabetically by title with null checks
        this.filterAlbum = filteredAlbums.sort((a, b) => {
          if (!a.title) return 1; // Treat null or undefined title as greater
          if (!b.title) return -1; // Treat null or undefined title as less
          return a.title.localeCompare(b.title);

        });

        //console.log(this.filterAlbum);
      }
    }
  }

  filterSongsByChar(char: string): void {
    const prefix = String.fromCharCode(char.charCodeAt(0) - 1);
    const suffix = String.fromCharCode(char.charCodeAt(0) + 1);
    if (this.isSong) {
      this.filteredSongs = this.allSongs.filter(song => {
        const firstChar = song.title[0].toUpperCase();
        return firstChar === char || firstChar === prefix || firstChar === suffix;
      });
    }
    else {
      this.filterAlbum = this.albums.filter(song => {
        const firstChar = song.title[0].toUpperCase();
        return firstChar === char || firstChar === prefix || firstChar === suffix;
      });
    }

  }

  GetAllAlbums() {
    let UserId = Number(this.userDetails["userId"]);
    this._albumService.GetAlbumsbyUserId(UserId).subscribe((album: any) => {
      //console.log(album)
      this.albums = album.albums;
      this.sortSongs('album');
    })
    // this._albumService.getalbum().subscribe((album: any) => {
    //   this.albums = album.albums;
    //   console.log( this.albums);
    //   this.sortSongs('album');
    // });

  }

  onScroll(event: Event) {
    //console.log('scroll',event.target );
    const scrollableTableWrapper = event.target as HTMLElement;
    // console.log(scrollableTableWrapper)
    if (scrollableTableWrapper.classList.contains('scrollable-table')) {
      const elements = scrollableTableWrapper.querySelectorAll('[data-section]');
      Array.from(elements).forEach((element: Element) => {

        const rect = element.getBoundingClientRect();
        const wrapperRect = scrollableTableWrapper.getBoundingClientRect();
        // console.log(rect, wrapperRect)
        if (rect.top >= wrapperRect.top && rect.top <= wrapperRect.bottom) {
          const char = element.getAttribute('data-section');
          //console.log(char)
          if (char) {
            this.selectedChar = char;
          }
        }
      });
    }
  }

  // @ViewChild('UpdateplaylistModalsongs') playlistModal!: ElementRef;  // Reference the modal

  // modalInstance!: Modal;

  ngAfterViewInit() {
    //this.modalInstance = new Modal(this.playlistModal.nativeElement);
    if (this.isSong) {
      this.songtable.scrolledIndexChange.subscribe(() => {
        this.onScrollcdk();
      });
    }
    else {
      if (this.viewport) {
        this.viewport.scrolledIndexChange.subscribe(() => {
          this.onScrollcdk();
        });
      }
    }
  }

  onScrollcdk() {
    //debugger
    if (this.isSong) {
      const renderedRange = this.songtable.getRenderedRange();
      const visibleItems = this.filteredSongs.slice(renderedRange.start, renderedRange.end);
      const firstVisibleChar = visibleItems.length > 0 ? visibleItems[3].title[0].toUpperCase() : null;
      if (firstVisibleChar) {
        this.selectedChar = firstVisibleChar;
      }
    }
    else if (this.isArtist) {
      const renderedRange = this.viewport.getRenderedRange();
      const visibleItems = this.filterArtists.slice(renderedRange.start, renderedRange.end);
      const firstVisibleChar = visibleItems.length > 0 ? visibleItems[3].fullName[0].toUpperCase() : null;
      if (firstVisibleChar) {
        this.selectedChar = firstVisibleChar;
      }
    }
    else {
      const renderedRange = this.viewport.getRenderedRange();
      const visibleItems = this.filterAlbum.slice(renderedRange.start, renderedRange.end);
      const firstVisibleChar = visibleItems.length > 0 ? visibleItems[3].title[0].toUpperCase() : null;
      if (firstVisibleChar) {
        this.selectedChar = firstVisibleChar;
      }
    }

  }

  getArtistDataByArtistId() {
    //debugger;
    let UserId = Number(this.userDetails["userId"]);
    const selectedID = Number(this.SelectedId);

    // Create a map to store artist data by artist ID
    const artistMap = {};

    // Populate the map with artist data
    this.artistdata.forEach((artist, index) => {
      artistMap[artist.artistId] = { index, data: artist };
    });

    if (selectedID) {
      this._artistService.getArtistDataByArtistIdAndUserId(selectedID, UserId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (response: any) => {
            // Check if the selected artist ID exists in the map
            if (artistMap[selectedID]) {
              const { index } = artistMap[selectedID];
              this.artistdata[index] = response[0];
              //console.log(this.artistdata[index]);
              this.cdr.detectChanges();
            }
          },
          (error: any) => {
            console.error("Error occurred while fetching specific record: ", error);
          }
        );
    }
  }

  getIdSelected(Id: number) {
    this.SelectedId = Id;
  }

  // getSongDataBySongId() {
  //   debugger;
  //   const userId = Number(this.userDetails["userId"]);
  //   const selectedID = Number(this.SelectedId);
  //   let songIndexMap = {};

  //   this.filteredSongs.forEach((song, index) => {
  //     songIndexMap[song.songId] = { index, data: song };
  //   });

  //   if (selectedID) {
  //     this._songService.getSongBySongIdandUserId(selectedID, userId)
  //       .pipe(takeUntil(this.destroy$))
  //       .subscribe(
  //         (response: any) => {
  //           if (songIndexMap[selectedID]) {
  //             const { index } = songIndexMap[selectedID];
  //             this.filteredSongs[index] = response[0];
  //             this.cdr.markForCheck();
  //           }
  //         },
  //         (error: any) => {
  //           console.error("Error occurred while fetching specific record: ", error);
  //         }
  //       );
  //   }
  // }

  getSongDataBySongId(songId: number) {
    //debugger;
    const userId = Number(this.userDetails["userId"]);
    this.SelectedId = songId;  // Set SelectedId to the clicked song's ID
    const selectedID = Number(this.SelectedId);

    let songIndexMap = {};

    this.filteredSongs.forEach((song, index) => {
      songIndexMap[song.songId] = { index, data: song };
    });

    if (selectedID) {
      this._songService.getSongBySongIdandUserId(selectedID, userId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (response: any) => {
            if (songIndexMap[selectedID]) {
              const { index } = songIndexMap[selectedID];
              this.filteredSongs[index] = response[0];
              this.cdr.markForCheck();  // Mark the change detection for the updated song
            }
          },
          (error: any) => {
            console.error("Error occurred while fetching specific record: ", error);
          }
        );
    }
  }

  trackBySongId(index: number, song: any): number {
    return song.songId;
  }

  getAlbumDataByAlbumId() {
    //debugger;
    let UserId = Number(this.userDetails["userId"]);
    const selectedID = Number(this.SelectedId);

    let albumIndexMap = {};
    // if (!this.isPillsSelected) {
    this.filterAlbum.forEach((album, index) => {
      albumIndexMap[album.albumId] = { index, data: album };
    });
    // } else {
    //   this.AlbumsForMatPills.forEach((album, index) => {
    //     albumIndexMap.set(album.albumId, index);
    //   });
    // }

    if (selectedID) {
      this._albumService.GetAlbumByAlbumIdAndUserId(selectedID, UserId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (response: any) => {
            if (albumIndexMap[selectedID]) {
              const { index } = albumIndexMap[selectedID];
              // if (!this.isPillsSelected) {
              this.filterAlbum[index] = response[0];
              // } else {
              //   this.AlbumsForMatPills[index] = response[0];
              // }
              this.cdr.detectChanges();
            }
          },
          (error: any) => {
            console.error("Error occurred while fetching specific record: ", error);
          }
        );
    }
  }

  CheckSubscription(song, i) {
    this.songIsthere = true;
    this.playSong(song, i);
  }

  playSong(songs, index) {
    //debugger;
    // const updatedItem = {
    //   ...songs,
    //   artistName: songs.fullName
    // };
    // this._playerService.playSongs(updatedItem);
    // if (this.playerService.repeatSong) {
    //   this.playerService.repeatSong = false;
    // }
    // this.playerService.setPlaylist(this._playerService.msaapPlaylist);
    // this.playerService.selectATrack(index + 1);
    // this._playerService.updateData(this._playerService.msaapPlaylist);
    // this.playerService.playSongCallback(true);
    this._playerService.msaapPlaylist = [];
    songs.forEach((song, i) => {
      const trackObj = new Track();
      trackObj.index = i;
      trackObj.image = song.thumbImage;
      trackObj.artistName = song.fullName;
      trackObj.link = song.streamUrl;
      trackObj.title = song.title;
      trackObj.songId = song.songId;
      this._playerService.msaapPlaylist.push(trackObj);
    });
    this.playerService.playSongCallback(true);

    if (this.playerService.repeatSong) {
      this.playerService.repeatSong = false;
    }
    this.playerService.setPlaylist(this._playerService.msaapPlaylist);
    this.playerService.selectATrack(index + 1);
    this._playerService.updateData(this._playerService.msaapPlaylist);
  }

  Artistprofile(ArtistId: number) {
    localStorage.removeItem("InvoiceID");
    let val = ArtistId;
    localStorage.answer = JSON.stringify(val);
    let saved = JSON.parse(localStorage.answer);
    localStorage.setItem("InvoiceID", saved);
    //this.router.navigate(['/artist/artistprofile'], { queryParams: { Id: ArtistId } });
    this.router.navigate(['/newdiscover'], { queryParams: { Id: ArtistId } });
  }

  AlbumPage(AlbumId: number) {
    //console.log(AlbumId);
    this.router.navigate(['/discoverAlbum/'], { queryParams: { Id: AlbumId } });
  }

  calculateItemSize(): void {
    const viewportHeight = this.viewportRuler.getViewportSize().height;
    const numberOfItems = Math.floor(viewportHeight / this.defaultItemSize);

    this.itemSize = Math.floor(viewportHeight / numberOfItems);
    //console.log(viewportHeight, numberOfItems, this.itemSize);
    this.refreshCarousel = false;  // Temporarily disable carousel to clear the old instance
    this.cdr.detectChanges();
    //this.checkButtonVisibility();     // Trigger change detection to update view
    setTimeout(() => {
      this.refreshCarousel = true;  // Re-enable carousel
      this.cdr.detectChanges();
      // Trigger change detection again to recreate the carousel
    });
  }

  viewportHeight: any;

  @HostListener('window:resize', ['$event'])
  onResizes(event: Event): void {
    this.updateViewportHeight();
    this.calculateItemSize();
    document.body.style.overflow = 'hidden'; 
  }

  updateViewportHeight(): void {
    //debugger;
    const baseHeightVh = 70; // Initial base height in vh at 100% zoom
    const windowHeight = window.innerHeight;
    const screenHeight = screen.height;

    // Calculate the adjustment factor based on the height ratio
    const heightRatio = windowHeight / screenHeight;
    let adjustedHeightVh = baseHeightVh * heightRatio;

    // Cap the height to the maximum screen height
    if (adjustedHeightVh > 100) {
      adjustedHeightVh = 100;
    }

    this.viewportHeight = `${adjustedHeightVh}vh`;

  }
  // @HostListener('window:wheel', ['$event'])
  // onWheel(event: WheelEvent): void {
  //   // This listener detects zoom changes via the wheel event
  //   this.calculateItemSize();
  // }

  @ViewChild('UpdateplaylistModalsongs') UpdateplaylistModalsongs!: ElementRef;

  // Handle the event from the app-like-favourite-dropdown component
  openPlaylistModal(album) {
    //console.log(album)
    //debugger
    if (album.songId) {
      this._homeService.albumSongId = album.songId;
      this._homeService.likefavalbumId = 0;
    }
    else {
      this._homeService.likefavalbumId = album.albumId;
      this._homeService.albumSongId = 0;
    }
    const modalElement: HTMLElement = this.UpdateplaylistModalsongs.nativeElement;
    modalElement.classList.add('show'); // Add show class
    modalElement.style.display = 'block'; // Set display to block
    (window as any).$('body').addClass('modal-open');
  }

  // // Optional: Use this to unblock dropdown if needed
  // unblockDropdown() {
  //   this.isDropdownBlocked = false;
  // }

  closePlaylistModal() {
    const modalElement: HTMLElement = this.UpdateplaylistModalsongs.nativeElement;
    (window as any).$(`#${modalElement.id}`).modal('hide');
    modalElement.style.display = 'none'; // Hide the modal
    setTimeout(() => {
      (window as any).$('body').removeClass('modal-open'); // Remove 'modal-open' class
      (window as any).$('.modal-backdrop').remove(); // Remove modal backdrop
    }, 1000);
  }


  onSubmit(f) {debugger;
    //console.log(f)
    this.playlist.UserId = this.userId;
    if (this._homeService.likefavalbumId) {
      this._songService.getSongsByAlbumId(this.userId, this._homeService.likefavalbumId, 1, 20).subscribe((data: any) => {
        //console.log(data)
        data.songs.forEach((s: any) => {

          this.playlist.SongIds.push(s.songId);
        });
        this._homeService.AddPlayListMultiplex(this.playlist).subscribe((res: any) => {
          //console.log(res)
          if (res.status == 200) {
            //this.freshplaylist()
            //this.closeForm();
            //this.closeBtn.nativeElement.click();
            this.closePlaylistModal();
            this.playlist.PlaylistId = undefined;
            this.updateextchecked = false;
            this.createchecked = true;
            if (f) {
              f.submitted = false;
              this.form.resetForm();
            }
            this.toastr.success("Playlist added successfully");
            this.sharedService.triggerMethodLikeFavorite();
            this.freshplaylist();
          }
          else {
            // this.freshplaylist();
            this.toastr.success("Playlist Already Exists");
          }
        })
      })
    }
    else if (this._homeService.albumSongId) {
      this.playlist.SongIds.push(this._homeService.albumSongId);
      this._homeService.AddPlayListMultiplex(this.playlist).subscribe((res: any) => {
        if (res.status == 200) {
          this.closePlaylistModal();
          this.playlist.PlaylistId = undefined;
          this.updateextchecked = false;
          this.createchecked = true;
          if (f) {
            f.submitted = false;
            this.form.resetForm();
          }
          this.toastr.success("Playlist added successfully");
          this.sharedService.triggerMethodLikeFavorite();
          this.freshplaylist();
        }
        else {
          this.toastr.success("Playlist Already Exists");
        }
      })
    }
  }
  onSubmitforupdate() {debugger;
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.playlist.UserId = this.userDetails.userId;
    //this.playlist.PlaylistId=this.playlistId;
    if (this._homeService.likefavalbumId) {
      this._songService.getSongsByAlbumId(this.userId, this._homeService.likefavalbumId, 1, 20).subscribe((data: any) => {
        data.songs.forEach((s: any) => {
          this.playlist.SongId = s.songId;
          this.playlist.SongIds.push(s.songId);
        });
        this.spinner.show();
        this._homeService.AddPlayListMultiplex(this.playlist).subscribe((res: any) => {
          if (res.status == 200) {
            if (this.playlist.PlaylistId! == 0) {
              this.toastr.success("Playlist added successfully");
              this.closePlaylistModal();
              this.playlist.Name = '';
              this.createchecked = true;
              this.updateextchecked = false
              this.form.resetForm();
              this.form.reset();
            } else {
              this.closePlaylistModal();
              // this.freshplaylist();
              this.toastr.success("Playlist updated successfully");
              this.playlist.Name = '';
              this.createchecked = true;
              this.updateextchecked = false
              this.form.resetForm();
              this.form.reset();
            }
            this.sharedService.triggerMethodLikeFavorite();
            this.freshplaylist();
            this.spinner.hide();
          }
          else {
            this.toastr.success("Playlist Already Exists");
            this.spinner.hide();

          }
          this.spinner.hide();
        }, err => {
          this.toastr.error(err)
          this.spinner.hide();
        });
      })
    }
    else if (this._homeService.albumSongId) {
      this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
      this.playlist.UserId = this.userDetails.userId;
      //this.playlist.PlaylistId=this.playlistId;
      this.playlist.SongId = this._homeService.albumSongId;
      //this.playlist.Name=this.allsongs.name
      this.spinner.show();
      this._homeService.addOrUpdateplaylist(this.playlist).subscribe((res: any) => {
        if (res.status == 200) {
          if (this.playlist.PlaylistId! == 0) {
            //this.freshplaylist();
            this.toastr.success("Playlist added successfully");
            //this.isChecked = [];
            this.playlist.Name = '';
            this.createchecked = true;
            this.updateextchecked = false
            this.form.resetForm();
            this.form.reset();
          } else {
            this.closePlaylistModal();
            // this.freshplaylist();
            this.toastr.success("Playlist updated successfully");
            this.playlist.Name = '';
            //this.isChecked = [];
            this.createchecked = true;
            this.updateextchecked = false
            this.form.resetForm();
            this.form.reset();
          }
          this.sharedService.triggerMethodLikeFavorite();
          this.freshplaylist();
          this.spinner.hide();
        }
        else {
          this.toastr.success("Playlist Already Exists");
          this.spinner.hide();

        }
        this.spinner.hide();
      }, err => {
        this.toastr.error(err)
        this.spinner.hide();
      });
    }
  }
  checked() {
    this.createchecked = true;
    this.updateextchecked = false;
  }
  updatechecked() {
    this.updateextchecked = true;
    this.createchecked = false;
    //this.playlist.PlaylistId = undefined;
  }

  freshplaylist() {
    this._homeService.getPlaylist(this.userDetails.userId).subscribe((data: any) => {
      this.playListDetails = data.playListDetails;
      this._homeService.getLatestPlaylist(this.playListDetails);

    })
  }

  onInnerScroll(event: Event): void {
    debugger
    document.body.style.overflow = 'hidden'; // Disable browser scroll
  }

  ngOnDestroy(): void {
    console.log('Scroll enabled');
    document.body.style.overflow = 'auto';
  }

}
