import { Component, OnInit } from '@angular/core';
import { LoadScriptService } from 'src/app/loadScript.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HomeService } from 'src/app/Shared/home.service';
import { Approvalstatus } from '../../models/approvalstatus';
import { Miniapproval } from '../../models/miniapproval';
import { Users } from '../../models/users';
import { ApproverejectService } from '../../Shared/approvereject.service';
import { ToastrService } from 'ngx-toastr';
import { Track } from '../../../../Player/ngx-audio-player/src/lib/model/track.model'
import { PlayerService } from '../../Shared/player.service';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { Playlist } from 'src/app/models/playlist';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-adminapproval',
  templateUrl: './viewalbum.component.html',
  styleUrls: ['./viewalbum.component.css']
})
export class ViewalbumComponent implements OnInit {

  constructor(private loadScript: LoadScriptService, private playerService: AudioPlayerService,
    private _playerService: PlayerService, private route: ActivatedRoute, private homeService: HomeService, private approvereject: ApproverejectService, private toastr: ToastrService,
    public location: Location, private router: Router, public translate: TranslateService) { }
  allsongs;
  album;
  albumby;
  noofSongs;
  tm;
  lm;
  tmn;
  lmn;
  releasedOn;
  TransList: Array<any> = [];
  approvalstatus_rejected: any;
  approvalstatus_approved: any;
  approvalstatus_resubmit: any;
  approverejectalbum = new Miniapproval();
  userDetails = new Users();
  path;
  albuminfo: boolean = false;
  loginasartist: boolean = false;
  commnets;
  statuscode;
  statusId;
  
  ngOnInit() {
    this.loadScript.getServiceBaseUrl();
    this.approverejectalbum.albumId = this.route.snapshot.paramMap.get("id");
    this.DisplayalbumDetail(this.approverejectalbum.albumId, "0")
    this.approvalstatus_rejected = Approvalstatus.REJECTED;
    this.approvalstatus_approved = Approvalstatus.APPROVED;
    this.approvalstatus_resubmit = Approvalstatus.RESUBMIT;
    this.getcomments(this.approverejectalbum.albumId);
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    if (this.userDetails["artistId"] != undefined) {
      this.loginasartist = true;
      document.getElementById("resubmit").style.display = "block";
      document.getElementById("approve").style.display = "none";
      document.getElementById("reject").style.display = "none";
      this.approverejectalbum.createdBy = this.userDetails["artistId"];
      this.approverejectalbum.userType = "Artist";
    }
    else {
      this.approverejectalbum.createdBy = this.userDetails["userId"];
      this.approverejectalbum.userType = "Admin";
    }

  }
  playSong(songs, index) {
    let songId = songs[index].songId;
    localStorage.setItem('CurrentSongId', songId);

    let indexForPlayPause = this.playerService.playPauseBtn.findIndex(x => x == true);
    if (indexForPlayPause == index) {
      localStorage.setItem('IsPlaying', 'false');
      this.playerService.playPauseBtn[index] = !this.playerService.playPauseBtn[index];
    }
    else {
      localStorage.setItem('IsPlaying', 'true');
      if (indexForPlayPause == -1) {
        indexForPlayPause = 0;
      }
      this.playerService.playPauseBtn[indexForPlayPause] = false;
      this.playerService.playPauseBtn[index] = true;
    }
    this._playerService.playSong(songs, this.path, index);
    this._playerService.playForArtist = true;
  }
  DisplayalbumDetail(albumId: string, UserId: string) {

    this.homeService.getAlbumbyId(albumId, UserId).subscribe(Response => {

      this.TransList.push(Response);
      console.log("album view",this.TransList[0].albumDetails)
      this.path = this.TransList[0].path;
      this.album = this.TransList[0].albumDetails.title;
      this.albumby = this.TransList[0].albumDetails.albumBy;
      this.noofSongs = this.TransList[0].albumDetails.noofSongs;
      this.releasedOn = this.TransList[0].albumDetails.releasedOn;
      this.allsongs = this.TransList[0].albumDetails.songs;
      this.tm = this.TransList[0].albumDetails.thumbImage;
      this.lm = this.TransList[0].albumDetails.largeImage;
      this.tmn = this.TransList[0].albumDetails.thumbImageNew;
      this.lmn = this.TransList[0].albumDetails.largeImageNew;
      this.statuscode = this.TransList[0].albumDetails.statusCode;
      this.statusId = this.TransList[0].albumDetails.statusId;
      if (this.statusId == Approvalstatus.APPROVED) {
        document.getElementById("resubmit").style.display = "none";
        document.getElementById("approve").style.display = "none";
      }
      if (this.statusId == Approvalstatus.REJECTED || this.statusId == Approvalstatus.DELETED) {
        document.getElementById("reject").style.display = "none";

      }
      if (this.statusId == Approvalstatus.DELETED) {
        document.getElementById("approve").style.display = "none";
        document.getElementById("comment").style.display = "none";
      }
    })
  }
  show() {

  }
  UpdateApproval(statusId) {

    this.approverejectalbum.statusId = statusId;

    if (this.approverejectalbum.comments == undefined || this.approverejectalbum.comments.trim() == "") {
      this.albuminfo = true;
    } else {
      this.appovreject();
    }

  }
  appovreject() {

    this.approvereject.approveRejectAlbum(this.approverejectalbum).subscribe((res: any) => {
      if (res.isSuccess) {

        this.toastr.success("Record is updated successfully");
      } else {

        this.toastr.success("Record is not updated");
      }
      // this.router.navigate(['/admin/album']);
      //this.location.back();
      this.location.back();

    });
  }
  comment($event) {
    if ($event == "")
      this.albuminfo = true;
    else
      this.albuminfo = false;
  }
  getcomments(AlbumId) {
    this.approvereject.getcommentsofAlbum(AlbumId).subscribe((res: any) => {

      this.commnets = res.comments;


    });
  }


}
