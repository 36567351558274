import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ArtistsService } from 'src/app/Shared/artists.service';
import { ToDoList } from 'src/app/models/todoList';
import { Users } from 'src/app/models/users';
import { Location } from '@angular/common';

@Component({
  selector: 'app-add-update-todolist',
  templateUrl: './add-update-todolist.component.html',
  styleUrls: ['./add-update-todolist.component.css']
})
export class AddUpdateTodolistComponent implements OnInit {

  artists: any;

  todoList = new ToDoList();
  todoForm: FormGroup;
  userDetails = new Users();
  edit: boolean=false;
  tittle = "Add";
  isSubmitted = false;
  f: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _artistService: ArtistsService,
    public translate: TranslateService,
    private spinner: NgxSpinnerService,
    public location: Location,
  ) {
    this.todoList.ToDoListId = this.route.snapshot.paramMap.get("id");
   }

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.getAllArtists();
    if (this.todoList.ToDoListId != null) {
      this._artistService.GettodolistById(this.todoList.ToDoListId ).subscribe((res: any) => {
        console.log(res);
        this.edit = true;
        this.tittle = "Edit";
        this.todoList.title = res.todolistid[0].title;
        this.todoList.artistId = res.todolistid[0].artistId;
    });
    }
    else{
      this.todoList.ToDoListId = 0;
    }
  }

  getAllArtists() {
    this._artistService.GetArtists().subscribe((data: any) => {
      this.artists = data.artistDetails;
      console.log(this.artists);
    },
     err => { this.toastr.error(err) }); 
  }

  back(){
    this.location.back();
  }

  onSubmit() {
    debugger;

    if (this.userDetails["artistId"] != undefined) {
      this.todoList.createdBy = this.userDetails["artistId"].toString();
      this.todoList.updatedBy = this.userDetails["artistId"].toString();
    }
    if (this.userDetails["userId"] != undefined) {
      this.todoList.createdBy = this.userDetails["userId"].toString();
      this.todoList.updatedBy = this.userDetails["userId"].toString();
    }

    console.log(this.todoList);
    this._artistService.addOrUpdateTodoList(this.todoList).subscribe((res: any) => {
      if (res.status == 200) {
        console.log(res.data);
        debugger;
        if (this.todoList.ToDoListId != 0) {
          this.toastr.success("TodoList updated successfully");
          this.spinner.hide();
        } else {
          this.toastr.success("TodoList added successfully");
          this.spinner.hide();
        }
        this.location.back();
        //this._router.navigate(['/admin/songs']);
      }
    }, err => {
      this.toastr.error(err)
      this.spinner.hide();
    })
    
    //console.log(this.todoList);
  }



}
