import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { LoadScriptService } from 'src/app/loadScript.service';
import { HomeService } from '../../Shared/home.service'
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { Track } from '../../../../Player/ngx-audio-player/src/lib/model/track.model';
import { PlayerService } from 'src/app/Shared/player.service';
import { Router } from '@angular/router';
import { Users } from '../../models/users';
import { Playlist } from 'src/app/models/playlist';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { Location } from '@angular/common';
import { UserService } from '../../Shared/user.service';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-adminplaylist',
  templateUrl: './adminplaylist.component.html',
  styleUrls: ['./adminplaylist.component.css']
})
export class AdminplaylistComponent implements OnInit {
  @ViewChild('closeBtn') closeBtn: ElementRef;
  @ViewChild('closeBtn') closeBtn1: ElementRef;
  @ViewChild('g') form: NgForm;
  playListDetails: any;
  userDetails: any;
  playlist = new Playlist();
  userId: number;
  path;
  playd = [];
  index: any;
  message: string;
  playsongslist: any;
  substatus: any;
  songIsthere: boolean = false;
  placeholder: string;
  fileToUpload: File = null;
  files: any;
  noImage: any;
  imgURL: any;
  constructor(private loadScript: LoadScriptService,
    public _homeService: HomeService,
    private playerService: AudioPlayerService,
    private _playerService: PlayerService,
    private toastr: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService,
    public location: Location,
    public userService: UserService) { }

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.userId = this.userDetails.userId;
    this.substatus = this.userDetails.subscriptionStatus;
    this.loadScript.getServiceBaseUrl();
    this.getplaylist();
    this.playlist.thumbImage;
    this.placeholder = 'Upload image'

  }
  getplaylist() {
    this.spinner.show();
    this._homeService.getadminPlaylist().subscribe((data: any) => {
      this.playListDetails = data.playListDetails;
      this._homeService.playId = data.playListDetails;
      this.path = data.path + 'Images/AdminPlaylist';
      this.spinner.hide();
    })
  }
  deleteplaylist(PlaylistId) {
    if (confirm('Are you sure you want to delete?')) {
      this._homeService.deleteAdminPlaylist(PlaylistId).subscribe((res: any) => {
        this.getplaylist();
        this.toastr.success("Playlist Deleted successfully");
      })

    }

  }
  playSong(songs, index) {
    this._playerService.playSong(songs, this.path, index);
  }
  CheckSubscription(song, index) {
    if (this.substatus == "False") {
      this.router.navigate(['/account/upgradeplan']);
      alert("Your Subscription is End");
    }
    else {
      this.songIsthere = true;
      this.playSong(song, index);
    }
  }
  onSubmit() {
    this._homeService.renameAdminPlaylist(this.playlist.PlaylistId, this.userId, this.playlist.Name).subscribe((res: any) => {
      this.message = 'PlaylistName Changed Successfully';
      this.successmsg(this.message);
      this.closeBtn.nativeElement.click();
      this.getplaylist();
    }, err => {
      this.toastr.error(err)
      this.spinner.hide();
    });

  }
  fetchplaylistname(i, j) {
    this.playlist.Name = i;
    this.playlist.PlaylistId = j;
  }
  fetchplaylistimage(i, j) {
    this.playlist.thumbImage = i;
    if (this.playlist.thumbImage != null) {
      this.noImage = false;
    } else {
      this.noImage = true;
    }
    this.playlist.PlaylistId = j;
  }
  fetchplayId(i) {
    this.playlist.SongId = i;
  }
  playlistdetails1(playlistId: number, Name: any) {
    this.router.navigate([`/admin/playlistdetails`], { queryParams: { Id: playlistId, playListName: Name } });
  }
  viewallsongs(playlistId: number, playListName: string) {
    this._homeService.playListArray = [];
    this._homeService.playlistName = playListName;
    this._homeService.playlist(playlistId, this.userDetails.userId).subscribe((Response: any) => {

      let test = Response.playlistDetails;
      for (var a = 0; a < Response.playlistDetails.length; a++) {
        this._homeService.playListArray.push(Response.playlistDetails[a]);
      }
      this.router.navigate(['/admin/playlistdetails'], { queryParams: { Id: playlistId } });
    })

  }

  successmsg(msg: string) {
    this.toastr.success(msg)
  }
  successmsg1() {
    this.toastr.success("Toastr Success message", 'Success')
  }
  back() {
    this.location.back();
  }
  selectPhoto(file: FileList) {
    this.playlist.thumbImage = file.item(0).name;
    if (this.playlist.thumbImage == '') {
      this.placeholder = 'Upload image';
    }
    else {
      this.placeholder = this.playlist.thumbImage
    }
    this.fileToUpload = file.item(0);
    var File = this.fileToUpload.name;
    this.playlist.FileName = File.split('.')[0];
    this.files = File.split('.');
    this.playlist.FileType = this.files[this.files.length - 1];
    this.playlist.FileType = File.split('.')[1];
    this.noImage = false;
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imgURL = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.playlist.Base64Data = btoa(binaryString);

  }
  updateimage() {
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.playlist.UserId = this.userDetails.userId;
    if (this.noImage) {
      this.playlist.Base64Data = null;
    }
    else {
      this.playlist.Base64Data = this.imgURL.split(",")[1];
    }
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.playlist.UserId = this.userDetails.userId;
    this.spinner.show();
    this._homeService.UpdatePlayListImage(this.playlist).subscribe((res: any) => {
      if (res.status == 200) {
        if (this.playlist.PlaylistId! == 0) {


          this.toastr.success("Playlist added successfully");
          this.playlist.Name = '';
        } else {
          this.closeBtn1.nativeElement.click();
          this.toastr.success("Image updated successfully");
          this.getplaylist();
          this.playlist.Name = '';
        }
        this.spinner.hide();
      }
      else {
        this.toastr.success("Playlist Already Exists");
        this.spinner.hide();
      }
      this.spinner.hide();
    }, err => {
      this.toastr.error(err)
      this.spinner.hide();
    });
  }

  AddPlaylist() {

  }
  onSubmitplylist() {
    debugger;
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.playlist.UserId = this.userDetails.userId;
    if (this.noImage) {
      this.playlist.Base64Data = null;
    }
    else {
      this.playlist.Base64Data = this.imgURL.split(",")[1];
    }
    // var AdminSongids = [];
    // AdminSongids.push(this.AdminSongid.toString());
    // this.playlist.AdminSongId = AdminSongids;
    //this.spinner.show();
    console.log('abc', this.playlist);
    this._homeService.AddUpdateAdminPlayList(this.playlist).subscribe((res: any) => {
      if (res.status == 200) {
        this.toastr.success("Playlist added successfully");
        //this.closeBtn.nativeElement.click();
        this.closeBtn.nativeElement.click();
        this.getplaylist();
        //this.spinner.hide();
        this.form.resetForm();
        this.form.reset();

      }
      else {
        this.toastr.error("Something went wrong");
        // this.isChecked = [];

      }
      // this.spinner.hide();
    }, err => {
      this.toastr.error(err)
      // this.spinner.hide();
    });
  }


}
