import { Component, OnInit } from '@angular/core';
import { LoadScriptService } from 'src/app/loadScript.service';
import { ActivatedRoute, Router } from '@angular/router';
import { _MatChipListMixinBase } from '@angular/material';
import { LoginService } from 'src/app/Shared/login.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-artist-resetpassword',
  templateUrl: './artist-resetpassword.component.html',
  styleUrls: ['./artist-resetpassword.component.css']
})
export class ArtistResetpasswordComponent implements OnInit {
  massage: string;
  artistName;
  constructor(private loadScript: LoadScriptService, private route: ActivatedRoute,
    private _router: Router,
    private _loginService: LoginService,
    private _toastr: ToastrService
  ) {
    let url = this._router.url
    let subStringData = url.substr(21);

    this.id = subStringData;
    this.id = this.id.slice(1);

    // this.artistName = this.id.split('.')[0];
    // this.artistName=this.id.split('/');
    // this.artistName = this.artistName[this.artistName.length - 1];
  }
  sub: any;
  password;
  ConfirmPassword;
  id: any;

  ngOnInit() {
  }


  onSubmit() {
    if (this.password == this.ConfirmPassword) {
      this._loginService.artistforgetpassword(this.id, this.password, this.ConfirmPassword).subscribe((res: any) => {
        this.massage = 'Password changed successfully';
        this.successmsg(this.massage);
        this.clear();
        this._router.navigate([`/home`]);
      }, err => { this._toastr.error(err) })
    }
    else {
      this.massage = 'Password does not match';
      this.successmsg(this.massage);
    }
  }


  clear() {
    this.artistName = "";
    this.password = "";
    this.ConfirmPassword = "";
  }
  cancel() {
    this.password = "";
    this.ConfirmPassword = "";
  }

  successmsg(msg: string) {
    this._toastr.success(msg)
  }
}
