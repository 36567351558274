import { Component, OnInit } from '@angular/core';
import { LoadScriptService } from 'src/app/loadScript.service';
import { UserService } from 'src/app/Shared/user.service';
import { Users } from 'src/app/models/users';
import { NgxSpinnerService } from "ngx-spinner";
import { Location } from '@angular/common';
import * as $ from 'jquery';
@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {
  userDetails = new Users();
  languageCode: any;
  Code: any;
  result: any;
  terms: any;
  theme: any;
  themecode: string;
  constructor(private loadScript: LoadScriptService,
    public _userService: UserService,
    private spinner: NgxSpinnerService,
    public location: Location) { }

  ngOnInit() {
    $(document).ready(function () {
      $(".focuss").focus();

    })
    // $(document).ready(function(){
    //   $( "#p2" ).html( "this.result");
    //     $("#p2").css("color", "red");
    //   });

    // $( "div b" )
    //   .append( document.createTextNode( "!!!" ) )
    //   .css( "color", "red" );
    this.loadScript.getServiceBaseUrl();
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.languageCode = this.userDetails.languageCode;
    this.theme = this.userDetails.themeId

    if (this.theme == '1') {
      this.themecode = '_DARK';
      this.Code = "TOBY_MUSIC_TERMS_" + this.languageCode + this.themecode;
    }
    else {
      this.themecode = '_LIGHT';
      this.Code = "TOBY_MUSIC_TERMS_" + this.languageCode + this.themecode;
    }
    this.getContentByCode();
  }

  getContentByCode() {
    this.languageCode = this.languageCode.toUpperCase();
    this._userService.GetContentByCode(this.Code).subscribe((data: any) => {
      this.result = data.data.pageContent;
      this.terms = data.data.pageContentTitle;
    })
  }
  back() {
    this.location.back();
  }
  

}
