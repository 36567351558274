import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Users } from '../models/users';
import { HomeService } from '../Shared/home.service';

@Component({
  selector: 'app-user-albums',
  templateUrl: './user-albums.component.html',
  styleUrls: ['./user-albums.component.css']
})
export class UserAlbumsComponent implements OnInit {
  paramId:any;
  path:string="";
  albums:any[];
  userDetails = new Users();


  constructor(private route: ActivatedRoute,private _homeService: HomeService,) {
    //this.updateDisplayCount(window.innerWidth);
   }

  ngOnInit() {
    window.scroll(0,0);
    this.paramId = this.route.snapshot.queryParams["Id"];
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.getAlbums();
  }
   displayCount = 5;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // this.updateDisplayCount(event.target.innerWidth);
    console.log('chk width', event.target.innerWidth);

  }
  updateDisplayCount(width: number) {
    if (width >= 1500 && width <= 1600) {
      this.displayCount = 5;
    }
    else if (width >= 1700 && width <= 1899) {
      this.displayCount = 6;
    }
    else if (width >= 1900 && width <= 1999) {
      this.displayCount = 7;
    }
    else if (width >= 2000 && width <= 3000) {
      this.displayCount = 7;
    }
    else if (width >= 3000 && width <= 7000) {
      this.displayCount = 8;
    }
    else if (width >= 1300 && width <= 1400) {
      this.displayCount = 5;
    }
    else if (width >= 878 && width <= 1200) {
      this.displayCount = 3;
    }
    else if (width >= 700 && width <= 877) {
      this.displayCount = 2;
    }
    else if (width <= 1404) {
      this.displayCount = 4;
    }

  }

   getAlbums(){
    this._homeService.getArtist(this.paramId, this.userDetails.userId).subscribe((Response:any) => {
      this.albums=Response.artistDetails.album;
      //this.path=Response.path;
      console.log(Response,this.albums);
   })
  }
  truncateTitle(title: string, maxLength: number): string {
    if (!title) {
      return "No Title";
    }
    if (title.length <= maxLength) {
      return title;
    }
    return `${title.substring(0, maxLength)}...`;
  }
}
