import { Component, OnInit } from '@angular/core';
import { Suggestion } from '../../models/suggestion';
import { Router } from '@angular/router';
import { LoginService } from '../../Shared/login.service'
import { Users } from '../../models/users';
import { LoadScriptService } from '../../loadScript.service';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { UserService } from 'src/app/Shared/user.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-suggestion',
  templateUrl: './suggestion.component.html',
  styleUrls: ['./suggestion.component.css']
})
export class SuggestionComponent implements OnInit {

  constructor(private toastr: ToastrService, private _router: Router,
    private loadScript: LoadScriptService, public userService: UserService,
    private formBuilder: FormBuilder, private _loginService: LoginService) { }
  suggestion = new Suggestion();
  userDetails = new Users();
  summaryError: boolean;
  suggestionForm: FormGroup;
  submitted = false;
  description;
  massage: string; a
  ngOnInit() {
    this.suggestionForm = this.formBuilder.group({
      summary: ['', Validators.required]

    });
    //this.loadScript.getServiceBaseUrl();
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    $(document).ready(function () {
      $(".focuss").focus();

    })
  }
  successmsg(msg: string) {
    this.toastr.success(msg)
  }
  get f() { return this.suggestionForm.controls; }

  onSubmit() {
    this.submitted = true;
    // this.massage = 'Suggestions Submitted Successfully';
    // this.successmsg(this.massage);
    if (this.suggestionForm.invalid) {
      return;
    }
    this.suggestion.userId = this.userDetails.userId;
    this.suggestion.summary = this.suggestionForm.get('summary').value;
    this.suggestion.description = this.description;
    this._loginService.addSuggestion(this.suggestion).subscribe((res: any) => {
    })
    this.massage = 'Suggestion Added Successfully';
    this.successmsg(this.massage);
    this._router.navigate([`/home`]);
  }
  cancel() {
    this._router.navigate([`/home`]);
  }
}
