import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { Users } from '../models/users';
import { Role } from '../models/role';
import { ActivatedRoute, Router } from '@angular/router';
import { AlbumService } from '../Shared/album.service';
import { SongsService } from '../Shared/songs.service';
import { ArtistsService } from '../Shared/artists.service';
import { TranslateService } from '@ngx-translate/core';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { PlayerService } from '../Shared/player.service';
import { HomeService } from '../Shared/home.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { Track } from 'Player/ngx-audio-player/src/public_api';
import { Playlist } from '../models/playlist';
import { NgForm } from '@angular/forms';
import { SharedService } from '../Shared/shared.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-discover-album',
  templateUrl: './discover-album.component.html',
  styleUrls: ['./discover-album.component.css']
})
export class DiscoverAlbumComponent implements OnInit {
  @ViewChild('audioPlayer') player: ElementRef;
  userLogin: boolean = false
  userDetails = new Users();
  artistLogin: boolean = false;
  playlist = new Playlist();
  paramId: any;
  image: any;
  name: any;
  artistId: any;
  artistName: any;
  date: any;
  artistCreateDate: any;
  imagel: any;
  artistImage: any;
  artistImagel: any;
  songdata: any;
  pageIndex: any = 1;
  pageSize: any = 20;
  totalcount: 0;
  totalTime = 0;
  albumMinutes: any;
  albumSeconds: any;
  sText = 'song';
  theme1: boolean = true;
  index = 0;
  IsActiveSongId: any;
  allsongs: any = [];
  isLoading: boolean;
  UserId: any;

  @ViewChild('closeBtn') closeBtn: ElementRef;
  @ViewChild('f') form: NgForm;
  updateextchecked: boolean;
  createchecked: boolean = true;
  isSongPlaying = true;
  playListDetails: any;
  playListLatest: any;
  userId: number;
  FavAlbumImage: boolean;
  Details: any;
  songid: any;
  favId: any;
  likeId: any;
  albumId: any;
  currentTime: number = 0;
  ShowPlayer: boolean = true;
  loaderDisplay: boolean = false;
  isSongplay: boolean = false;
  marginRightMusicContainer: any;
  path: any;
  allsongsdata: any;
  songLength: any;
  sub: Subscription
  albumSongDuration: any;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateScreenCss(event.target.innerWidth);
  }

  constructor(private route: ActivatedRoute, private router: Router,
    private albumService: AlbumService,
    private songsService: SongsService,
    private artistsService: ArtistsService,
    public translate: TranslateService,
    private _playerService: PlayerService,
    public playerService: AudioPlayerService,
    public _homeService: HomeService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public homeService: HomeService,
    private sharedService: SharedService,
  ) {
    this.homeService.playListSongs.subscribe((res: any) => {
      this.allsongs = res; console.log(this.allsongs)
    });

    this._homeService.allPlayList.subscribe((res: any) => {
      if (res.length == 0) {
        this._homeService.playId = [];
      } else {
        this._homeService.playId = res;
      }
    });
    this.playerService.registerPlaySongCallbackno((song) => {
      console.log(song)
      console.log('playingchk', song)
      if (song == true) {
        this.isSongplay = true;
      }
      else {
        this.isSongplay = false;
      }
    });
    this.updateScreenCss(window.innerWidth);
    // router.routeReuseStrategy.shouldReuseRoute = () => false;
    // this.router.routeReuseStrategy.shouldReuseRoute = function () {
    //   return false;
    // };
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.sub = route.params.subscribe(params => {
      this.getAlbum(params['id'],this.userDetails.userId);
      this.GetSongsDetailsbyalbumid(params['id']);
      this.paramId = params['id'];
      console.log(this.paramId, this.isSongPlaying);
    })
  }

  ngOnInit() {
    this._homeService.share.subscribe(x =>
      this.playListLatest = x)
    this.playListLatest = this.playListLatest;
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    //this.isSongPlaying = localStorage.getItem('IsSongPlaying');
    //this.route.params.subscribe(params => { this.paramId = params['id'] });
    console.log(this.paramId, this.isSongPlaying);
    this.getAlbum(this.paramId,this.userDetails.userId);
    this.GetSongsDetailsbyalbumid(this.paramId);
    if (this.userDetails.userType == Role.superAdmin) {
      this.userLogin = false;
    } else if (this.userDetails.userType == Role.user) {
      this.userLogin = true;
      this.getUserPlaylist();
      this.userId = this.userDetails.userId;
    }
    else {
      this.artistLogin = true;
      this.userLogin = false;
    }
    window.scroll(0, 0);
    this.playerService.getIsblogorCountryScreen().subscribe(value => {
      this.IsActiveSongId = value;
      console.log(this.IsActiveSongId);
    })
    this.getFavouriteAlbums();
  }
  getAlbum(albumId,userId) {
    this.spinner.show();
    this.albumService.GetAlbumsbyalbum(albumId,userId).subscribe((data: any) => {
      console.log('albumsss', data)
      this.spinner.hide();
      this.image = data.path.concat(data.albumDetails.albumId, '/', data.albumDetails.thumbImage);
      this.imagel = data.path.concat(data.albumDetails.albumId, '/', data.albumDetails.largeImage);
      this.name = data.albumDetails.title;
      this.date = data.albumDetails.releasedOn;
      this.artistId = data.albumDetails.artistId;
      this.albumId = data.albumDetails.albumId;
      this.favId = data.albumDetails.favId;
      this.likeId = data.albumDetails.likeId;
      this.albumMinutes = data.albumDetails.length.minutes;
      this.getFavouriteAlbums();
      if (this.albumMinutes < 10) { this.albumMinutes = '0' + this.albumMinutes };
      this.albumSeconds = data.albumDetails.length.seconds;
      if (this.albumSeconds < 10) { this.albumSeconds = '0' + this.albumSeconds };
      this.artistsService.getArtistDetails(data.albumDetails.artistId).subscribe((data: any) => {
        this.artistImage = data.path.concat(data.artistDetails.thumbImage);
        this.artistImagel = data.path.concat(data.artistDetails.largeImage);
        this.artistName = data.artistDetails.fullName;
        //this.artistCreateDate = data.artistDetails.createdOn;
      })
    })
  }

  GetSongsDetailsbyalbumid(album: any) {
    debugger;
    this.isLoading = true;
    this.songsService.getSongsByAlbumId(this.userDetails.userId, album, this.pageIndex, this.pageSize).subscribe((data: any) => {
      this.totalcount = data.count;
      this.isLoading = false;
      if (this.totalcount > 1) this.sText = 'songs';
      console.log('Songs:', data);
      this.songLength = data.songs.length;
      this.songdata = data.songs;
      this.allsongsdata = data.songs;
      this.songdata.forEach((s: any) => { s.length = s.length.slice(3, 8), this.totalTime = +s.length });
      this.albumSongDuration = this.sumSongLengths(this.songdata);
    });
  }

  private areArraysSameDeep(a: any[], b: any[]): boolean {
    return a.length === b.length && a.every((val, index) => val === b[index]);
  }

  playAllsongs(items, play) {
    debugger
    let currentIndex = items.findIndex(x => x.songId == Number(localStorage.getItem('CurrentSongId')))
    if (play == 'play') {
      if (!this.areArraysSameDeep(this._playerService.msaapPlaylist, items)) {
        this._playerService.msaapPlaylist = [];
        // this.playSongs(items, currentIndex);
        // this.CheckSubscription(items, 0);
        if (currentIndex != -1) {
          this.playSongs(items, currentIndex);
        }
        else {
          this.playSongs(items, 0);
        }
        setTimeout(() => {
          this.isSongplay = true;
        }, 1000);
      }
      else if (this._playerService.msaapPlaylist == items) {
        this.playerService.playSongCallback(true);
      }
      else {
        this.playerService.playSongCallback(true);
      }
    }
    else {
      this.playerService.currentTime = this.player.nativeElement.currentTime;
      console.log('onpause', this._playerService.msaapPlaylist, items.length, this.currentTime);
      this.isSongplay = false;
      // this.player.nativeElement.paused = false;
      //this.playBtnHandler();
      this.playerService.playSongCallback(false);
    }
  }
  CheckSubscription(song, i) {
    debugger;

    this.playSongs(song, i);
  }

  playSongs(songs, index) {
    debugger;
    this._playerService.msaapPlaylist = [];
    songs.forEach((song, i) => {
      const trackObj = new Track();
      trackObj.index = i;
      trackObj.image = song.thumbImage;
      trackObj.artistName = song.artistName;
      trackObj.link = song.streamUrl;
      trackObj.title = song.title;
      trackObj.songId = song.songId;
      this._playerService.msaapPlaylist.push(trackObj);
    });
    this.playerService.playSongCallback(true);
    // for (var i = 0; i < songs.length; i++) {
    //   const tractObj = new Track();
    //   tractObj.index = i;
    //   tractObj.image = this.path + songs[i].thumbImage;
    //   tractObj.artistName = songs[i].artistName;
    //   tractObj.link = songs[i].streamUrl;
    //   tractObj.title = songs[i].title;
    //   tractObj.songId = songs[i].songId;
    //   this._playerService.msaapPlaylist.push(tractObj);
    // }

    if (this.playerService.repeatSong) {
      this.playerService.repeatSong = false;
    }
    this.playerService.setPlaylist(this._playerService.msaapPlaylist);
    this.playerService.selectATrack(index + 1);
    this._playerService.updateData(this._playerService.msaapPlaylist);

    // else {
    //   this._playerService.msaapPlaylist = [];
    //   const tractObj = new Track();
    //   tractObj.index = index
    //   tractObj.image = songs.thumbImage;
    //   tractObj.artistName = songs.artistName;
    //   tractObj.link = songs.streamUrl;
    //   tractObj.title = songs.title;
    //   tractObj.songId = songs.songId;
    //   this._playerService.msaapPlaylist.unshift(tractObj);
    //   this.playerService.setPlaylist(this._playerService.msaapPlaylist);
    //   this.playerService.selectATrack(index);
    //   this._playerService.updateData(songs);
    //   this.playerService.playSongCallback(true);

    // }
  }





  playSong(songs, index) {
    this._playerService.playSongs(songs);
    if (this.playerService.repeatSong) {
      this.playerService.repeatSong = false;
    }
    this.playerService.setPlaylist(this._playerService.msaapPlaylist);
    this.playerService.selectATrack(index + 1);
    this._playerService.updateData(this._playerService.msaapPlaylist);
    this.playerService.playSongCallback(true);
  }

  IsCheckLang() {
    if (this.translate.currentLang == 'ar') {
      return true;

    } else if (this.translate.currentLang == 'as') {
      return true;
    }
    else {
      return false;
    }
  }
  Changetheme() {
    if (this.userDetails.themeId == '2') {
      this.theme1 = false;
      return true;

    }
    else {
      this.theme1 = true;
      return false;
    }
  }

  getUserPlaylist() {
    this._homeService.getPlaylist(this.userDetails.userId).subscribe((data: any) => {
      this.playListDetails = data.playListDetails;
      console.log(this.playListDetails);
      if (this.playListDetails.length >= 5) {
        for (var i = this.index; i < 5; i++) {
          this._homeService.playId[i] = this.playListDetails[i];
        }
      } else if (this.playListDetails.length != 0) {
        for (var i = this.index; i < this.playListDetails.length; i++) {
          this._homeService.playId[i] = this.playListDetails[i];
        }
      } else {
        this._homeService.playId.length = 0;
      }
    });
  }

  viewallsongs(playlistId: number, playListName: string) {
    this._homeService.playListArray = [];
    this._homeService.playlistName = playListName;

    localStorage.setItem('service', JSON.stringify(this._homeService.playlistName));
    this._homeService.playlist(playlistId, this.userDetails.userId).subscribe((Response: any) => {

      let test = Response.playlistDetails;
      for (var a = 0; a < Response.playlistDetails.length; a++) {
        this._homeService.playListArray.push(Response.playlistDetails[a]);
      }
      this.router.navigate(['/playlist/allplaylistsongs'], { queryParams: { Id: playlistId } });
    })
  }

  FavouriteAlbums() {
    this.FavAlbumImage = !this.FavAlbumImage;
    this._homeService.FavouriteAlbums(this.paramId, this.userId).subscribe((res: any) => {
      console.log('favrts', res);
      if (res.data.FavId) {
        this.toastr.success("Album added to Favourite");
      }
      else {
        this.toastr.success("Album removed from Favourite");
      }
    })
  }

  getFavouriteAlbums() {
    this._homeService.getFavouritesAlbum(this.userId, this.paramId).subscribe((res: any) => {
      console.log(res);
      if (res.length != 0) {
        this.FavAlbumImage = false;
      }
      else {
        this.FavAlbumImage = true;
      }
    })
  }

  updateScreenCss(width: number) {
    const mainElement = document.querySelector('.main') as HTMLElement;
    const musicContainerElement = document.querySelector('.music-container') as HTMLElement;


    // Calculate margin-right dynamically for screen widths between 2049 and 5464
    if (musicContainerElement) {
      if (width >= 2049 && width <= 6144) {
        // Calculate the percentage value for margin-right
        const marginRightPercentage = ((width - 2049) / (6144 - 2049)) * 69; // Adjust 69 as needed
        musicContainerElement.style.marginRight = marginRightPercentage + '%';
      } else {
        musicContainerElement.style.marginRight = ''; // Reset margin-right for other screen widths
      }
      this.marginRightMusicContainer = musicContainerElement.style.marginRight;
    }
  }

  onSubmit(f) {
    debugger
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.playlist.UserId = this.userDetails["userId"];;

    this.songdata.forEach((s: any) => {

      this.playlist.SongIds.push(s.songId);

    });

    this._homeService.AddPlayListMultiplex(this.playlist).subscribe((res: any) => {
      if (res.status == 200) {
        //this.freshplaylist()
        //this.closeForm();
        this.closeBtn.nativeElement.click();
        this.playlist.PlaylistId = undefined;
        this.updateextchecked = false;
        this.createchecked = true;
        if (f) {
          f.submitted = false;
          this.form.resetForm();
        }
        this.toastr.success("Playlist added successfully");
        this.sharedService.triggerMethodLikeFavorite();
        this.freshplaylist();
      }
      else {
        // this.freshplaylist();
        this.toastr.success("Playlist Already Exists");
      }
    })

  }


  freshplaylist() {
    this._homeService.getPlaylist(this.UserId).subscribe((data: any) => {
      this.playListDetails = data.playListDetails;
      this._homeService.getLatestPlaylist(this.playListDetails);

    })
  }


  onSubmitforupdate() {
    debugger
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.playlist.UserId = this.userDetails.userId;
    //this.playlist.PlaylistId=this.playlistId;


    this.songdata.forEach((s: any) => {
      this.playlist.SongId = s.songId;
      this.playlist.SongIds.push(s.songId);
    });
    this.spinner.show();
    this._homeService.AddPlayListMultiplex(this.playlist).subscribe((res: any) => {
      if (res.status == 200) {
        if (this.playlist.PlaylistId! == 0) {
          //this.freshplaylist();
          this.toastr.success("Playlist added successfully");
          //this.isChecked = [];
          this.playlist.Name = '';
          this.createchecked = true;
          this.updateextchecked = false
          this.form.resetForm();
          this.form.reset();
        } else {
          this.closeBtn.nativeElement.click();
          // this.freshplaylist();
          this.toastr.success("Playlist updated successfully");

          this.playlist.Name = '';
          //this.isChecked = [];
          this.createchecked = true;
          this.updateextchecked = false
          this.form.resetForm();
          this.form.reset();
        }
        this.sharedService.triggerMethodLikeFavorite();
        this.freshplaylist();
        this.spinner.hide();
      }
      else {
        this.toastr.success("Playlist Already Exists");
        this.spinner.hide();

      }
      this.spinner.hide();
    }, err => {
      this.toastr.error(err)
      this.spinner.hide();
    });



  }


  checked() {
    this.createchecked = true;
    this.updateextchecked = false;
  }
  updatechecked() {
    this.updateextchecked = true;
    this.createchecked = false;
    this.playlist.PlaylistId = undefined;
  }
  Artistprofile(ArtistId: number) {
    this.router.navigate(['/newdiscover'], { queryParams: { Id: ArtistId } });
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  sumSongLengths(songData: any[]): string {
    let totalSeconds = 0;

    // Convert each "MM:SS" to seconds and add to the total
    songData.forEach(song => {
      const parts = song.length.split(":");  // Split the "MM:SS" format
      const minutes = parseInt(parts[0], 10);  // Convert minutes part to integer
      const seconds = parseInt(parts[1], 10);  // Convert seconds part to integer
      totalSeconds += (minutes * 60) + seconds;  // Convert to total seconds
    });

    // Convert total seconds to minutes and seconds
    const totalMinutes = Math.floor(totalSeconds / 60);  // Get total minutes
    const remainingSeconds = totalSeconds % 60;  // Get remaining seconds

    // Format with leading zeros if necessary
    const formattedMinutes = totalMinutes < 10 ? totalMinutes.toString().padStart(2, '0') : totalMinutes;
    const formattedSeconds = remainingSeconds.toString().padStart(2, '0');  // Always pad seconds to 2 digits

    // Return the result in "X min Y sec" format
    return `${formattedMinutes} min ${formattedSeconds} sec`;
  }

}
