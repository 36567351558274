import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild, ChangeDetectorRef, QueryList, ViewChildren, AfterViewInit } from '@angular/core';
import { Users } from 'src/app/models/users';
import { Role } from '../../models/role';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HomeService } from 'src/app/Shared/home.service';
import { UserService } from 'src/app/Shared/user.service';
import { LoadScriptService } from 'src/app/loadScript.service';
import { AlbumService } from 'src/app/Shared/album.service';
import { SongsService } from 'src/app/Shared/songs.service';
import { LoginService } from 'src/app/Shared/login.service';
import { Playlist } from 'src/app/models/playlist';
import { Track } from 'Player/ngx-audio-player/src/public_api';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { PlayerService } from 'src/app/Shared/player.service';
import { ConfigService } from 'src/app/app.config';
import { ArtistsService } from 'src/app/Shared/artists.service';
import { Alldetails } from 'src/app/models/alldetails';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
declare const $: any;

@Component({
  selector: 'app-user-home',
  templateUrl: './user-home.component.html',
  styleUrls: ['./user-home.component.css']
})
export class UserHomeComponent implements OnInit {
  featuredAlbum: any;
  bannerDetails: any;
  slides = [];
  featuredSong: any;
  path: any;
  songIsthere: boolean;
  msaapPlaylist: Track[] = [];
  refreshCarousel: boolean = false;
  maxWidth: string = "1157";
  themeidcode: string;
  userDetails = new Users();
  substatus: any;
  theme: any;
  hImages: any = [];
  userLogin: boolean = false
  loaderDisplay: boolean = false;
  routing = ["/likedsongs", "/mostplayedsongs", "/mostplayedartist", "/recommendedsongs", "/mostplayedalbums", "/newlyaddedsongs"];
  adminplstpath: any;
  adminplaylist: any;
  featuredPlaylist: any[] = [];
  IsActiveSongId: any;
  allData: any[] = [];
  allDetails: Alldetails[] = [];
  songDetail: any = [];
  albumDetail: any = [];
  artistDetail: any = [];
  playlistDetail: any = [];
  songs: any = [];
  feaPlaylistDetail: any = [];
  public userClickedRightArrow: boolean = false;
  showLeftArrow: boolean = false;
  showRightArrow: boolean = true;
  showLeftArrows: boolean = false;
  showRightArrows: boolean = true;
  showLeftArrowsply: boolean = false;
  showRightArrowsply: boolean = true;
  showLeftArrowsArt: boolean = false;
  showRightArrowsArt: boolean = true;
  showLeftArrowsbzz: boolean = false;
  showRightArrowsbzz: boolean = true;
  currentSlideIndex: number = 0;
  totalSlides: number;
  showLeftArrowSectionsng: boolean = false;
  currentSongIndex: number = 0;
  songSections: any[];
  currentCarouselIndex: { [sectionIndex: number]: number } = {};
  showLeftArrowSectionply: boolean = false;
  showRightArrowSectionply: boolean = true;
  isPlaylistMore: boolean = false;
  sectionData: any[];
  //showNextButtons: boolean[] = [];
  showNextButtons = new Array(5).fill(true);
  sectionLength: any;
  showNextButtonsForPlaylists: boolean[] = [];
  showRightArrowSectionsng: boolean = true;
  loader: boolean = false;
  tfavsongs: any;
  tmostArtist: any;
  mstPlayedSongLstMnth: any;
  top25newArtAlbSng: any;
  newArtist: any;
  newAlbum: any;
  newSong: any;
  categories: any;
  height: any;
  top: any;
  topind: any;


  constructor(private renderer: Renderer2, private el: ElementRef, public translate: TranslateService,
    private router: Router,
    private loadScript: LoadScriptService,
    private _loginService: LoginService,
    private _homeService: HomeService,
    public userService: UserService,
    private spinner: NgxSpinnerService,
    private albumService: AlbumService,
    private artistService: ArtistsService,
    private _songservice: SongsService,
    private configService: ConfigService
    , private _playerService: PlayerService, private playerService: AudioPlayerService, private cdr: ChangeDetectorRef
  ) {
    setTimeout(() => {
      this.adjustSlidesToShow(window.innerWidth);
    }, 50);
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.themeidcode = localStorage.getItem('themeId');
    this.loadScript.getServiceBaseUrl();
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.substatus = this.userDetails.subscriptionStatus;
    this.translate.use(this.userDetails.languageCode);
    this.theme = this.userDetails.themeId;
    this.getfeaAlbum();
    this.getallbannerdetails();
    this.getfeaSongs();
    this.getfeaPlaylists();
    this.getAllDetails();
    this.gettopfavsong();
    this.gettopmostplayedArtist();
    this.getlastmonthtopmostplayedSong();
    this.gettop25newArtistAlbumSongs();
    this.getallCategories();
    this.gethomeimages(this.userDetails.userId);
    var screenHeight = window.innerHeight;
    console.log('height', screenHeight)
    //const slidesToShow = this.adjustSlidesToShow(window.innerWidth);


    if (this.userDetails.userType == Role.user) {
      this.userLogin = true;
      //this.getmostplayedalbumbyUserid(this.userDetails.userId);

    }
    this.playerService.getIsblogorCountryScreen().subscribe(value => {
      this.IsActiveSongId = value;
    });
    this.allData.forEach((_, index) => this.currentIndex[index] = 0);
  }


  truncateTitle(title: string, maxLength: number): string {
    if (!title) {
      return "No Title";
    }
    if (title.length <= maxLength) {
      return title;
    }
    return `${title.substring(0, maxLength)}...`;
  }

  getfeaAlbum() {
    this.loader = true;
    this.albumService.GetFeaturedAlbum().subscribe((data: any) => {
      this.featuredAlbum = data.feaAlbumDetails.list;
      this.loader = false;
      this.adjustSlidesToShow(window.innerWidth);
      //this.adjustSlidesToShow();
      console.log(this.featuredAlbum);
    });
  }

  slideConfigForSongs = {
    slidesToShow: 5,
    slidesToScroll: 5,
    dots: false,
    infinite: false,
    //nextArrow: "<button class='nav-btn next-slide' aria-label='Next' type='button' style='color: #6c757d; background: none; border: none; font-size: 50px; cursor: pointer;top:38%;'><i class='fa fa-angle-left' aria-hidden='true'></i></button>" ,
    //prevArrow: "<button class='nav-btn prev-slide' aria-label='Previous' type='button' style='color: #6c757d; background: none; border: none; font-size: 50px; cursor: pointer;top:38%;'><i class='fa fa-angle-right' aria-hidden='true'></i></button>",
    nextArrow: '',
    prevArrow: '',
  };

  slideConfigForsecSong = {
    slidesToShow: 5,
    slidesToScroll: 5,
    dots: false,
    infinite: false,
    //nextArrow: "<button class='nav-btn next-slide' aria-label='Next' type='button' style='color: #6c757d; background: none; border: none; font-size: 50px; cursor: pointer;top:38%;'><i class='fa fa-angle-left' aria-hidden='true'></i></button>" ,
    //prevArrow: "<button class='nav-btn prev-slide' aria-label='Previous' type='button' style='color: #6c757d; background: none; border: none; font-size: 50px; cursor: pointer;top:38%;'><i class='fa fa-angle-right' aria-hidden='true'></i></button>",
    nextArrow: '',
    prevArrow: '',
  }


  displayArtists = true;
  displayAlbums = true;
  displaySongs = true;

  @ViewChild('slickModal1') slickModal1: SlickCarouselComponent;
  @ViewChild('slickModal2') slickModal2: SlickCarouselComponent;
  @ViewChild('slickModal3') slickModal3: SlickCarouselComponent;
  @ViewChild('slickModal4') slickModal4: SlickCarouselComponent;
  @ViewChild('slickModal5') slickModal5: SlickCarouselComponent;
  @ViewChild('slickModalsArtist') slickModalsArtist: SlickCarouselComponent;
  @ViewChild('slickModalPlaylist') slickModalPlaylist: SlickCarouselComponent;
  @ViewChild('slickModalAlbums') slickModalAlbums: SlickCarouselComponent;


  next(): void {
    this.slickModal1.slickNext();
    const itemsPerSlide = this.slideConfigForSongs.slidesToShow;
    this.currentSlideIndex = (this.currentSlideIndex + itemsPerSlide) % this.featuredAlbum.length;
    const totalSets = Math.ceil(this.featuredAlbum.length / itemsPerSlide);
    const currentSet = Math.floor(this.currentSlideIndex / itemsPerSlide) + 1;

    this.showLeftArrow = currentSet > 1;
    this.showRightArrow = currentSet < totalSets;
    //this.updateArrowVisibility();
    //this.showRightArrow = this.currentSlideIndex == 0;
  }

  previous(): void {
    this.slickModal1.slickPrev();
    const itemsPerSlide = this.slideConfigForSongs.slidesToShow;
    this.currentSlideIndex =
      (this.currentSlideIndex - itemsPerSlide + this.featuredAlbum.length) % this.featuredAlbum.length;
    const totalSets = Math.ceil(this.featuredAlbum.length / itemsPerSlide);
    const currentSet = Math.floor(this.currentSlideIndex / itemsPerSlide) + 1;

    this.showLeftArrow = currentSet > 1;
    this.showRightArrow = currentSet < totalSets;
    //this.updateArrowVisibility();
    //this.showRightArrow = this.currentSlideIndex == 0;

  }

  private updateArrowVisibility(): void {
    this.showLeftArrow = this.currentSlideIndex !== 0;
    this.showRightArrow = this.currentSlideIndex == 0;
  }

  nextSong() {
    this.slickModal2.slickNext();
    const itemsPerSlide = this.slideConfigForSongs.slidesToShow;
    this.currentSlideIndex = (this.currentSlideIndex + itemsPerSlide) % 10;
    const totalSets = Math.ceil(10 / itemsPerSlide);
    const currentSet = Math.floor(this.currentSlideIndex / itemsPerSlide) + 1;

    this.showLeftArrows = currentSet > 1;
    this.showRightArrows = currentSet < totalSets;
    // this.showLeftArrows = this.currentSlideIndex !== 0;
    // this.showRightArrows = this.currentSlideIndex == 0;
  }

  previousSong() {
    this.slickModal2.slickPrev();
    const itemsPerSlide = this.slideConfigForSongs.slidesToShow;
    this.currentSlideIndex =
      (this.currentSlideIndex - itemsPerSlide + 10) % 10;
    const totalSets = Math.ceil(10 / itemsPerSlide);
    const currentSet = Math.floor(this.currentSlideIndex / itemsPerSlide) + 1;

    this.showLeftArrows = currentSet > 1;
    this.showRightArrows = currentSet < totalSets;
    // this.showLeftArrows = this.currentSlideIndex !== 0;
    // this.showRightArrows = this.currentSlideIndex == 0;
  }

  // nextply() {
  //   debugger;
  //   // this.slickModal3.slickNext();
  //   // this.currentSlideIndex = (this.currentSlideIndex + 1) % this.tfavsongs.length;
  //   // this.showLeftArrowsply = this.currentSlideIndex !== 0;
  //   // this.showRightArrowsply = this.currentSlideIndex == 0;
  //   this.slickModal3.slickNext();
  //   this.currentSlideIndex = (this.currentSlideIndex + 5) % this.tfavsongs.length;
  //   this.updateArrows();
  // }

  // previousply() {
  //   debugger;
  //   // this.slickModal3.slickPrev();
  //   // this.currentSlideIndex = (this.currentSlideIndex - 1 + this.tfavsongs.length) % this.tfavsongs.length;
  //   // this.showLeftArrowsply = this.currentSlideIndex !== 0;
  //   // this.showRightArrowsply = this.currentSlideIndex == 0;
  //   this.slickModal3.slickPrev();
  //   this.currentSlideIndex = (this.currentSlideIndex - 5 + this.tfavsongs.length) % this.tfavsongs.length;
  //   this.updateArrows();
  // }

  nextply() {
    this.slickModal3.slickNext();
    const itemsPerSlide = this.slideConfigForSongs.slidesToShow;
    this.currentSlideIndex = (this.currentSlideIndex + itemsPerSlide) % this.tfavsongs.length;
    this.updateArrows();
  }

  previousply() {
    this.slickModal3.slickPrev();
    const itemsPerSlide = this.slideConfigForSongs.slidesToShow;
    this.currentSlideIndex = (this.currentSlideIndex - itemsPerSlide + this.tfavsongs.length) % this.tfavsongs.length;
    this.updateArrows();
  }


  updateArrows() {
    const itemsPerSlide = this.slideConfigForSongs.slidesToShow;
    const totalSets = Math.ceil(this.tfavsongs.length / itemsPerSlide);
    const currentSet = Math.floor(this.currentSlideIndex / itemsPerSlide) + 1;

    this.showLeftArrowsply = currentSet > 1;
    this.showRightArrowsply = currentSet < totalSets;
  }
  // updateArrows() {
  //   const totalSets = Math.ceil(this.tfavsongs.length / 5);
  //   const currentSet = Math.floor(this.currentSlideIndex / 5) + 1;

  //   this.showLeftArrowsply = currentSet > 1;
  //   this.showRightArrowsply = currentSet < totalSets;
  // }

  previousArts() {
    this.slickModal4.slickPrev();
    this.currentSlideIndex = (this.currentSlideIndex - this.slideConfigForSongs.slidesToShow + this.tmostArtist.length) % this.tmostArtist.length;
    const itemsPerSlide = this.slideConfigForSongs.slidesToShow;
    const totalSets = Math.ceil(this.tmostArtist.length / itemsPerSlide);
    const currentSet = Math.floor(this.currentSlideIndex / itemsPerSlide) + 1;

    this.showLeftArrowsArt = currentSet > 1;
    this.showRightArrowsArt = currentSet < totalSets;
    // this.showLeftArrowsArt = this.currentSlideIndex !== 0;
    // this.showRightArrowsArt = this.currentSlideIndex == 0;
  }

  nextArts() {
    this.slickModal4.slickNext();
    this.currentSlideIndex = (this.currentSlideIndex + this.slideConfigForSongs.slidesToShow) % this.tmostArtist.length;
    const itemsPerSlide = this.slideConfigForSongs.slidesToShow;
    const totalSets = Math.ceil(this.tmostArtist.length / itemsPerSlide);
    const currentSet = Math.floor(this.currentSlideIndex / itemsPerSlide) + 1;

    this.showLeftArrowsArt = currentSet > 1;
    this.showRightArrowsArt = currentSet < totalSets;
    // this.showLeftArrowsArt = this.currentSlideIndex !== 0;
    // this.showRightArrowsArt = this.currentSlideIndex == 0;
  }

  previousbzz() {
    this.slickModal5.slickPrev();
    const itemsPerSlide = this.slideConfigForSongs.slidesToShow;
    this.currentSlideIndex = (this.currentSlideIndex - itemsPerSlide + this.mstPlayedSongLstMnth.length) % this.mstPlayedSongLstMnth.length;
    const totalSets = Math.ceil(this.mstPlayedSongLstMnth.length / itemsPerSlide);
    const currentSet = Math.floor(this.currentSlideIndex / itemsPerSlide) + 1;
    this.showLeftArrowsbzz = currentSet > 1;
    this.showRightArrowsbzz = currentSet < totalSets;
  }

  nextbzz() {
    this.slickModal5.slickNext();
    const itemsPerSlide = this.slideConfigForSongs.slidesToShow;
    this.currentSlideIndex = (this.currentSlideIndex + itemsPerSlide) % this.mstPlayedSongLstMnth.length;
    const totalSets = Math.ceil(this.mstPlayedSongLstMnth.length / itemsPerSlide);
    const currentSet = Math.floor(this.currentSlideIndex / itemsPerSlide) + 1;
    this.showLeftArrowsbzz = currentSet > 1;
    this.showRightArrowsbzz = currentSet < totalSets;
  }

  @ViewChildren('slickModalAlbum') slickCarousels1: QueryList<any>;
  @ViewChildren('slickModalsArtist') slickCarousels2: QueryList<any>;
  @ViewChildren('slickModalSong') slickCarousels4: QueryList<SlickCarouselComponent>;
  @ViewChildren('slickModal') slickModals: QueryList<any>;
  @ViewChildren('slickModal1') slickModals1: QueryList<any>;
  @ViewChildren('slickModalAlbums') slickCarouselss: QueryList<any>;
  @ViewChildren('slickModalPlaylist') slickCarouselpls: QueryList<any>;


  public currentIndex: number[] = [];
  slickCarouselIndexMap: number[] = [];
  slickCarouselIndexMapArtist: number[] = [];
  slickCarouselIndexMapAl: number[] = [];
  slickCarouselIndexMappls: number[] = [];
  visibilityStates: { [key: number]: { showPrev: boolean, showNext: boolean } } = {};

  initializeCarouselMapping() {
    let carouselIndex = 0;
    let carouselIndexArt = 0;
    let carouselIndexAl = 0;
    let carouselIndexpls = 0;
    this.allData.forEach((section, index) => {
      if (section.type === 'Song') {
        this.slickCarouselIndexMap[index] = carouselIndex++;
      }
      if (section.type === 'Artist') {
        this.slickCarouselIndexMapArtist[index] = carouselIndexArt++;
      }
      if (section.type === 'Album') {
        this.slickCarouselIndexMapAl[index] = carouselIndexAl++;
      }
      if (section.type === 'Playlist') {
        this.slickCarouselIndexMappls[index] = carouselIndexpls++;
      }
    });
  }


  // prevItem(sectionIndex: number) {debugger
  //   const carouselIndex = this.slickCarouselIndexMap[sectionIndex];
  //   const slickInstance = this.slickModals.toArray()[carouselIndex];
  //   if (slickInstance) {
  //     slickInstance.slickPrev();
  //     if (slickInstance) {
  //       const currentIndex = this.getCurrentSlideIndex(slickInstance);
  //       const totalSlides = slickInstance.slides ? slickInstance.slides.length : 0;
  //       console.log('updateVisibilityStates - currentIndex:', currentIndex, 'totalSlides:', totalSlides);
  //       this.visibilityStates[sectionIndex] = {
  //         showPrev: currentIndex > 0, // Show prev if not the first slide
  //         showNext: currentIndex < totalSlides - 1 // Show next if not the last slide
  //       };
  //     }
  //     //this.updateVisibilityStates(sectionIndex);
  //   } else {
  //     //console.error('No carousel instance found for section index:', sectionIndex);
  //   }
  // }

  // nextItem(sectionIndex: number) {debugger
  //   const carouselIndex = this.slickCarouselIndexMap[sectionIndex];
  //   const slickInstance = this.slickModals.toArray()[carouselIndex];
  //   if (slickInstance && slickInstance.currentIndex < slickInstance.slides.length - 1) {
  //     slickInstance.slickNext();
  //     if (slickInstance) {
  //       const currentIndex = this.getCurrentSlideIndex(slickInstance);
  //       const totalSlides = slickInstance.slides ? slickInstance.slides.length : 0;
  //       console.log('updateVisibilityStates - currentIndex:', currentIndex, 'totalSlides:', totalSlides);
  //       this.visibilityStates[sectionIndex] = {
  //         showPrev: currentIndex < totalSlides - 1, // Show prev if not the first slide
  //         showNext: currentIndex > 0 // Show next if not the last slide
  //       };
  //     }
  //     //this.updateVisibilityStates(sectionIndex);
  //   } else {
  //     //console.error('No carousel instance or at the last slide for section index:', sectionIndex);
  //   }
  // }

  // prevItem(sectionIndex: number) {
  //   const carouselIndex = this.slickCarouselIndexMap[sectionIndex];
  //   const slickInstance = this.slickModals.toArray()[carouselIndex];

  //   if (slickInstance) {
  //     // Move to the previous slide
  //     slickInstance.slickPrev();

  //     // Get the updated slide index after moving
  //     const currentIndex = this.getCurrentSlideIndex(slickInstance);
  //     const totalSlides = slickInstance.slides ? slickInstance.slides.length : 0;

  //     const itemsPerSlide = this.slideConfigForSongs.slidesToShow;
  //     const totalSets = Math.ceil(totalSlides / itemsPerSlide);
  //     const currentSet = Math.floor(currentIndex / itemsPerSlide) + 1;

  //     // Update visibility states for arrows
  //     this.visibilityStates[sectionIndex] = {
  //       showPrev: currentIndex > 0, // Hide "prev" if we're on the first slide
  //       showNext: currentSet < totalSets // Show "next" if we're not on the last set
  //     };
  //   }
  // }

  // nextItem(sectionIndex: number) {
  //   const carouselIndex = this.slickCarouselIndexMap[sectionIndex];
  //   const slickInstance = this.slickModals.toArray()[carouselIndex];

  //   if (slickInstance) {
  //     // Move to the next slide only if we're not on the last slide
  //     if (slickInstance.currentIndex < slickInstance.slides.length - 1) {
  //       slickInstance.slickNext();

  //       // Get the updated slide index after moving
  //       const currentIndex = this.getCurrentSlideIndex(slickInstance);
  //       const totalSlides = slickInstance.slides ? slickInstance.slides.length : 0;

  //       const itemsPerSlide = this.slideConfigForSongs.slidesToShow;
  //       const totalSets = Math.ceil(totalSlides / itemsPerSlide);
  //       const currentSet = Math.floor(currentIndex / itemsPerSlide) + 1;

  //       // Update visibility states for arrows
  //       this.visibilityStates[sectionIndex] = {
  //         showPrev: currentIndex > 0, // Show "prev" if we're not on the first slide
  //         showNext: currentIndex < totalSlides // Show "next" if we're not on the last slide
  //       };
  //     }
  //   }
  // }

  currentSlideIndexMap: { [key: number]: number } = {};

  nextItem(sectionIndex: number) {
    const carouselIndex = this.slickCarouselIndexMap[sectionIndex];
    const slickInstance = this.slickModals.toArray()[carouselIndex];

    if (slickInstance) {
      slickInstance.slickNext(); // Move to the next slide.

      const itemsPerSlide = this.slideConfigForartist.slidesToShow;
      const totalSlides = slickInstance.slides ? slickInstance.slides.length : 0;

      // Update currentSlideIndex for this section.
      if (this.currentSlideIndexMap[sectionIndex] === undefined) {
        this.currentSlideIndexMap[sectionIndex] = 0;
      }
      this.currentSlideIndexMap[sectionIndex] =
        (this.currentSlideIndexMap[sectionIndex] + itemsPerSlide) % totalSlides;

      // Update arrow visibility.
      this.updateArrowss(sectionIndex, totalSlides);
    }
  }

  prevItem(sectionIndex: number) {
    const carouselIndex = this.slickCarouselIndexMap[sectionIndex];
    const slickInstance = this.slickModals.toArray()[carouselIndex];

    if (slickInstance) {
      slickInstance.slickPrev(); // Move to the previous slide.

      const itemsPerSlide = this.slideConfigForartist.slidesToShow;
      const totalSlides = slickInstance.slides ? slickInstance.slides.length : 0;

      // Update currentSlideIndex for this section.
      if (this.currentSlideIndexMap[sectionIndex] === undefined) {
        this.currentSlideIndexMap[sectionIndex] = 0;
      }
      this.currentSlideIndexMap[sectionIndex] =
        (this.currentSlideIndexMap[sectionIndex] - itemsPerSlide + totalSlides) % totalSlides;

      // Update arrow visibility.
      this.updateArrowss(sectionIndex, totalSlides);
    }
  }

  updateArrowss(sectionIndex: number, totalSlides: number) {
    const currentIndex = this.currentSlideIndexMap[sectionIndex] || 0;
    const itemsPerSlide = this.slideConfigForartist.slidesToShow;

    this.visibilityStates[sectionIndex] = {
      showPrev: currentIndex > 0, // Show "Prev" if not on the first slide.
      showNext: currentIndex + itemsPerSlide < totalSlides // Show "Next" if not on the last slide.
    };

    console.log(`Section ${sectionIndex}: CurrentIndex = ${currentIndex}, TotalSlides = ${totalSlides}`);
  }




  updateVisibilityStates(sectionIndex: number): void {
    const carouselIndex = this.slickCarouselIndexMap[sectionIndex];
    const slickInstance = this.slickModals.toArray()[carouselIndex];
    if (slickInstance) {
      const currentIndex = this.getCurrentSlideIndex(slickInstance);
      const totalSlides = slickInstance.slides ? slickInstance.slides.length : 0;
      console.log('updateVisibilityStates - currentIndex:', currentIndex, 'totalSlides:', totalSlides);
      this.visibilityStates[sectionIndex] = {
        showPrev: currentIndex < totalSlides - 1, // Show prev if not the first slide
        showNext: currentIndex > 0 // Show next if not the last slide
      };
    } else {
      console.error('No carousel instance found for section index:', sectionIndex);
    }
  }

  getCurrentSlideIndex(slickInstance: any): number {
    // Attempt to retrieve the current slide index from slickInstance
    if (slickInstance.slick && slickInstance.slick.currentSlide !== undefined) {
      return slickInstance.slick.currentSlide;
    }
    console.warn('Unable to determine current slide index, defaulting to 0');
    return 0; // Default to 0 if unable to determine
  }
  // updateVisibilityStates(sectionIndex: number): void {
  //   const carouselIndex = this.slickCarouselIndexMap[sectionIndex];
  //   const slickInstance = this.slickModals.toArray()[carouselIndex];
  //   if (slickInstance) {
  //     const currentIndex = slickInstance.currentIndex;
  //     const totalSlides = slickInstance.slides.length;
  //     this.visibilityStates[sectionIndex] = {
  //       showPrev: totalSlides > 0, // Show prev if not the first slide
  //       showNext: currentIndex < totalSlides - 1 // Show next if not the last slide
  //     };
  //   }
  // }



  prevArtist(sectionIndex) {
    const carouselIndexArt = this.slickCarouselIndexMapArtist[sectionIndex];
    const slickInstanceArt = this.slickModals1.toArray()[carouselIndexArt];
    if (slickInstanceArt && slickInstanceArt.currentIndex < slickInstanceArt.slides.length - 1) {
      slickInstanceArt.slickPrev();
      this.updateVisibilityStatesArtist(sectionIndex);
    } else {
      //console.error('No carousel instance or at the last slide for section index:', sectionIndex);
    }
  }

  nextArtist(sectionIndex: number) {
    const carouselIndexArt = this.slickCarouselIndexMapArtist[sectionIndex];
    const slickInstanceArt = this.slickModals1.toArray()[carouselIndexArt];
    if (slickInstanceArt && slickInstanceArt.currentIndex < slickInstanceArt.slides.length - 1) {
      slickInstanceArt.slickNext();
      this.updateVisibilityStatesArtist(sectionIndex);
    } else {
      // console.error('No carousel instance or at the last slide for section index:', sectionIndex);
    }
  }

  updateVisibilityStatesArtist(sectionIndex: number): void {
    const carouselIndexArt = this.slickCarouselIndexMapArtist[sectionIndex];
    const slickInstanceArt = this.slickModals1.toArray()[carouselIndexArt];
    if (slickInstanceArt) {
      const currentIndex = slickInstanceArt.currentIndex;
      const totalSlides = slickInstanceArt.slides.length;
      this.visibilityStates[sectionIndex] = {
        showPrev: currentIndex > 0,
        showNext: currentIndex < totalSlides - 1
      };
    }
  }

  public currentIndexs: number[] = [];

  nextSlideAlbum(index: number) {
    if (index < 0 || index >= this.currentIndexs.length) {
      // console.error('Index out of bounds', index);
      return;
    }

    const carousel = this.slickCarousels1.toArray()[index];
    if (carousel && this.currentIndexs[index] < this.allData[index].items.length - 1) {
      this.currentIndexs[index] += 1;
      carousel.slickNext();
    }
  }

  prevSlideAlbum(index: number) {
    if (index < 0 || index >= this.currentIndexs.length) {
      //console.error('Index out of bounds', index);
      return;
    }

    const carousel = this.slickCarousels1.toArray()[index];
    if (carousel && this.currentIndexs[index] > 0) {
      this.currentIndexs[index] -= 1;
      carousel.slickPrev();
    }
  }

  public currentIndexa: number[] = [];
  prevSlideArtist(sectionIndex: number) {
    debugger
    const carouselIndex = this.slickCarouselIndexMapArtist[sectionIndex];
    const slickInstance = this.slickCarousels2.toArray()[carouselIndex];
    if (slickInstance) {
      slickInstance.slickPrev();
      if (slickInstance) {
        const currentIndex = this.getCurrentSlideIndex(slickInstance);
        const totalSlides = slickInstance.slides ? slickInstance.slides.length : 0;
        console.log('updateVisibilityStates - currentIndex:', currentIndex, 'totalSlides:', totalSlides);
        this.visibilityStates[sectionIndex] = {
          showPrev: currentIndex > 0, // Show prev if not the first slide
          showNext: currentIndex < totalSlides - 1 // Show next if not the last slide
        };
      }
      //this.updateVisibilityStates(sectionIndex);
    } else {
      //console.error('No carousel instance found for section index:', sectionIndex);
    }
    // const carousel = this.slickModalsArtist;
    // if (carousel) {

    //   this.currentIndexa[index] = Math.max(this.currentIndexa[index] - 1, 0);

    //   carousel.slickPrev();
    //   this.showLeftArrowSectionsng = this.currentIndexa[index] !== 0;
    //   this.showRightArrowSectionsng = this.currentIndexa[index] == 0;
    // }
  }

  nextSlideArtist(sectionIndex: number) {
    debugger
    const carouselIndex = this.slickCarouselIndexMapArtist[sectionIndex];
    const slickInstance = this.slickCarousels2.toArray()[carouselIndex];
    if (slickInstance && slickInstance.currentIndex < slickInstance.slides.length - 1) {
      slickInstance.slickNext();
      if (slickInstance) {
        const currentIndex = this.getCurrentSlideIndex(slickInstance);
        const totalSlides = slickInstance.slides ? slickInstance.slides.length : 0;
        console.log('updateVisibilityStates - currentIndex:', currentIndex, 'totalSlides:', totalSlides);
        this.visibilityStates[sectionIndex] = {
          showPrev: currentIndex < totalSlides - 1, // Show prev if not the first slide
          showNext: currentIndex > 0 // Show next if not the last slide
        };
      }
      //this.updateVisibilityStates(sectionIndex);
    } else {
      //console.error('No carousel instance or at the last slide for section index:', sectionIndex);
    }
    // const carousel = this.slickModalsArtist;
    // if (carousel) {
    //   this.allData.forEach((_, index) => this.currentIndexa[index] = 0);
    //   this.currentIndexa[index] = Math.min(this.currentIndexa[index] + 1, this.allData[index].items.length - 1);
    //   carousel.slickNext();
    //   this.showLeftArrowSectionsng = this.currentIndexa[index] !== 0;
    //   this.showRightArrowSectionsng = this.currentIndexa[index] == 0;
    // }
  }

  // prevItemAl(sectionIndex: number) {
  //   debugger
  //   const carouselIndex = this.slickCarouselIndexMapAl[sectionIndex];
  //   const slickInstance = this.slickCarouselss.toArray()[carouselIndex];
  //   if (slickInstance) {
  //     slickInstance.slickPrev();
  //     if (slickInstance) {
  //       const currentIndex = this.getCurrentSlideIndex(slickInstance);
  //       const totalSlides = slickInstance.slides ? slickInstance.slides.length : 0;
  //       console.log('updateVisibilityStates - currentIndex:', currentIndex, 'totalSlides:', totalSlides);
  //       this.visibilityStates[sectionIndex] = {
  //         showPrev: currentIndex > 0, // Show prev if not the first slide
  //         showNext: currentIndex < totalSlides - 1 // Show next if not the last slide
  //       };
  //     }
  //     //this.updateVisibilityStates(sectionIndex);
  //   } else {
  //     //console.error('No carousel instance found for section index:', sectionIndex);
  //   }
  // }

  // nextItemAl(sectionIndexs: number) {
  //   debugger
  //   const carouselIndex = this.slickCarouselIndexMapAl[sectionIndexs];
  //   const slickInstance = this.slickCarouselss.toArray()[carouselIndex];
  //   if (slickInstance && slickInstance.currentIndex < slickInstance.slides.length - 1) {
  //     slickInstance.slickNext();
  //     if (slickInstance) {
  //       const currentIndex = this.getCurrentSlideIndex(slickInstance);
  //       const totalSlides = slickInstance.slides ? slickInstance.slides.length : 0;
  //       console.log('updateVisibilityStates - currentIndex:', currentIndex, 'totalSlides:', totalSlides);
  //       this.visibilityStates[sectionIndexs] = {
  //         showPrev: currentIndex < totalSlides - 1, // Show prev if not the first slide
  //         showNext: currentIndex > 0 // Show next if not the last slide
  //       };
  //     }
  //     //this.updateVisibilityStates(sectionIndex);
  //   } else {
  //     //console.error('No carousel instance or at the last slide for section index:', sectionIndex);
  //   }
  // }

 
  currentSlideIndexesAl: number[] = [];

  prevItemAl(sectionIndex: number) {
    const carouselIndex = this.slickCarouselIndexMapAl[sectionIndex];
    const slickInstance = this.slickCarouselss.toArray()[carouselIndex];
  
    if (slickInstance) {
      slickInstance.slickPrev();
  
      const itemsPerSlide = this.slideConfigForSongs.slidesToShow; // Adjust this if the config is different for this carousel
      const totalSlides = slickInstance.slides ? slickInstance.slides.length : 0;
  
      // Update current index
      this.currentSlideIndexesAl[sectionIndex] = 
        (this.currentSlideIndexesAl[sectionIndex] - itemsPerSlide + totalSlides) % totalSlides;
  
      // Update visibility states
      this.updateArrowsAl(sectionIndex, itemsPerSlide, totalSlides);
    }
  }
  
  nextItemAl(sectionIndex: number) {
    const carouselIndex = this.slickCarouselIndexMapAl[sectionIndex];
    const slickInstance = this.slickCarouselss.toArray()[carouselIndex];
  
    if (slickInstance) {
      slickInstance.slickNext();
  
      const itemsPerSlide = this.slideConfigForSongs.slidesToShow; // Adjust this if the config is different for this carousel
      const totalSlides = slickInstance.slides ? slickInstance.slides.length : 0;
  
      // Update current index
      this.currentSlideIndexesAl[sectionIndex] = 
        (this.currentSlideIndexesAl[sectionIndex] + itemsPerSlide) % totalSlides;
  
      // Update visibility states
      this.updateArrowsAl(sectionIndex, itemsPerSlide, totalSlides);
    }
  }
  
  // Helper method to update visibility states
  updateArrowsAl(sectionIndex: number, itemsPerSlide: number, totalSlides: number) {
    const currentSet = Math.floor(this.currentSlideIndexesAl[sectionIndex] / itemsPerSlide) + 1;
    const totalSets = Math.ceil(totalSlides / itemsPerSlide);
  
    this.visibilityStates[sectionIndex] = {
      showPrev: currentSet > 1, // Show prev if not on the first set
      showNext: currentSet < totalSets // Show next if not on the last set
    };
  }
  

  //currentSlideIndexes: { [key: number]: number } = {};
  // prevItemAl(sectionIndex: number) {
  //   const carouselIndex = this.slickCarouselIndexMapAl[sectionIndex];
  //   const slickInstance = this.slickCarouselss.toArray()[carouselIndex];
  //   if (slickInstance) {
  //     slickInstance.slickPrev();

  //     const itemsPerSlide = this.slideConfigForartist.slidesToShow; // Assuming you have a similar config for this carousel
  //     const totalSlides = slickInstance.slides ? slickInstance.slides.length : 0;

  //     // Calculate current index
  //     this.currentSlideIndexes[sectionIndex] =
  //       (this.currentSlideIndexes[sectionIndex] - itemsPerSlide + totalSlides) % totalSlides;

  //     const currentIndex = this.currentSlideIndexes[sectionIndex] || 0;


  //     this.visibilityStates[sectionIndex] = {
  //       showPrev: currentIndex > 0, // Show "Prev" if not on the first slide.
  //       showNext: currentIndex + itemsPerSlide < totalSlides // Show "Next" if not on the last slide.
  //     };

  //     //this.updateArrowss(sectionIndex, totalSlides);
  //     // Update visibility
  //     // const currentSet = Math.floor(this.currentSlideIndexes[sectionIndex] / itemsPerSlide) + 1;
  //     // const totalSets = Math.ceil(totalSlides / itemsPerSlide);

  //     // this.visibilityStates[sectionIndex] = {
  //     //   showPrev: currentSet > 1, // Show prev if not on the first set
  //     //   showNext: currentSet < totalSets // Show next if not on the last set
  //     // };
  //   }
  // }

  // nextItemAl(sectionIndex: number) {
  //   const carouselIndex = this.slickCarouselIndexMapAl[sectionIndex];
  //   const slickInstance = this.slickCarouselss.toArray()[carouselIndex];
  //   if (slickInstance) {
  //     slickInstance.slickNext();

  //     const itemsPerSlide = this.slideConfigForSongs.slidesToShow; // Assuming you have a similar config for this carousel
  //     const totalSlides = slickInstance.slides ? slickInstance.slides.length : 0;

  //     // Calculate current index
  //     this.currentSlideIndexes[sectionIndex] =
  //       (this.currentSlideIndexes[sectionIndex] + itemsPerSlide) % totalSlides;

  //     const currentIndex = this.currentSlideIndexes[sectionIndex] || 0;


  //     this.visibilityStates[sectionIndex] = {
  //       showPrev: currentIndex > 0, // Show "Prev" if not on the first slide.
  //       showNext: currentIndex + itemsPerSlide < totalSlides // Show "Next" if not on the last slide.
  //     };
  //     //this.updateArrowss(sectionIndex, totalSlides);
  //     // Update visibility
  //     // const currentSet = Math.floor(this.currentSlideIndexes[sectionIndex] / itemsPerSlide) + 1;
  //     // const totalSets = Math.ceil(totalSlides / itemsPerSlide);

  //     // this.visibilityStates[sectionIndex] = {
  //     //   showPrev: currentSet > 1, // Show prev if not on the first set
  //     //   showNext: currentSet < totalSets // Show next if not on the last set
  //     // };
  //   }
  // }


  public currentIndexp: number[] = [];
  @ViewChildren('slickModalArtist') slickCarousels3: QueryList<SlickCarouselComponent>;
  prevSlidePlaylist(index: number) {
    const carousel = this.slickModalPlaylist;
    if (carousel) {
      this.currentIndexp[index] = Math.max(this.currentIndexp[index] - 1, 0);
      carousel.slickPrev();
      this.showLeftArrowSectionply = this.currentIndexp[index] !== 0;
      this.showRightArrowSectionply = this.currentIndexp[index] == 0;
    }
  }
  // else{
  //   const slickInstanceArt = this.slickModals1.toArray()[index];
  // if (slickInstanceArt && slickInstanceArt.currentIndex < slickInstanceArt.slides.length - 1) {
  //   slickInstanceArt.slickPrev();
  //   //this.updateVisibilityStatesArtist(sectionIndex);
  // } else {
  //   //console.error('No carousel instance or at the last slide for section index:', sectionIndex);
  // }
  // }


  nextSlidePlaylist(index: number) {
    const carousel = this.slickModalPlaylist;
    if (carousel) {
      this.allData.forEach((_, index) => this.currentIndexp[index] = 0);
      this.currentIndexp[index] = Math.min(this.currentIndexp[index] + 1, this.allData[index].items.length - 1);
      carousel.slickNext();
      this.showLeftArrowSectionply = this.currentIndexp[index] !== 0;
      this.showRightArrowSectionply = this.currentIndexp[index] == 0;

    }
  }

  slideConfigForartist = {
    slidesToShow: 5,
    slidesToScroll: 5,
    dots: false,
    infinite: false,
    nextArrow: '',
    prevArrow: '',
    //prevArrow: "<button class='nav-btn prev-slide' aria-label='Previous' type='button' style='color: #6c757d; background: none; border: none; font-size: 50px; cursor: pointer;'><i class='fa fa-angle-right' aria-hidden='true'></i></button>",
    //nextArrow: "<button class='nav-btn next-slide' aria-label='Next' type='button' style='color: #6c757d; background: none; border: none; font-size: 50px; cursor: pointer ;'><i class='fa fa-angle-left' aria-hidden='true'></i></button>",
  }

  addSlide() {
    this.slides.push({ img: "http://placehold.it/350x150/777777" })
  }

  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }

  slickInit(e) {

  }

  breakpoint(e) {

  }

  afterChange(e) {
  }

  beforeChange(e) {

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.adjustSlidesToShow(event.target.innerWidth);
    var screenHeight = window.innerHeight;
    console.log('height', screenHeight)
    if (screenHeight === 1366) {
      this.height = 29;
      this.top = 21;
      this.topind = 49;
    }
    else if ((event.target.innerWidth == 768 && screenHeight >= 598) && screenHeight <= 700) {
      this.height = 50;
      this.top = 35;
      this.topind = 61;
    }
    else if (event.target.innerWidth == 1366 && screenHeight >= 1024) {
      this.height = 54;
      this.top = 46;
      this.topind = '';
    }
    else if (event.target.innerWidth == 1180 && screenHeight >= 820) {
      this.height = 56;
      this.top = 39;
      this.topind = '';
    }
    else if (event.target.innerWidth == 1024 && screenHeight >= 768) {
      this.height = 52;
      this.top = 37;
      this.topind = '';
    }
    else if (event.target.innerWidth == 1280 && screenHeight >= 600) {
      this.height = 63;
      this.top = 47;
    }
    else {
      this.height = '';
      this.topind = '';
      this.top = '';
    }
    // if (screenHeight === 642) {
    //   this.height = 69;
    //   this.topind = 50;
    //   this.top = 46;
    // }
    // else{
    //   this.height = '';
    //   this.topind = '';
    //   this.top = '';
    // }
    //this.checkButtonVisibility();
    console.log(screenHeight);
  }

  carouselKey = 0;
  adjustSlidesToShow(event) {
    const zoomFactor = window.innerWidth / document.documentElement.clientWidth;
    //const currentWidth = window.innerWidth * zoomFactor;
    const currentWidth = event;
    var screenHeight = window.innerHeight;
    if (screenHeight === 1366) {
      this.height = 31;
      this.top = 21;
      this.topind = 49;
    }
    else if ((currentWidth == 768 && screenHeight >= 598) && screenHeight <= 700) {
      this.height = 50;
      this.top = 35;
      this.topind = 61;
    }
    else if (currentWidth == 1366 && screenHeight >= 1024) {
      this.height = 54;
      this.top = 46;
      this.topind = '';
    }
    else if (currentWidth == 1180 && screenHeight >= 820) {
      this.height = 56;
      this.top = 39;
      this.topind = '';
    }
    else if (currentWidth == 1024 && screenHeight >= 768) {
      this.height = 52;
      this.top = 37;
      this.topind = '';
    }
    else if (currentWidth == 1280 && screenHeight == 600) {
      this.height = 63;
      this.top = 47;
    }
    else {
      this.height = '';
      this.topind = '';
      this.top = '';
    }
    // if (screenHeight === 642) {
    //   this.height = 69;
    //   this.topind = 50;
    //   this.top = 46;
    // }
    // Assuming full zoom is 100% (1x), adjust slides to show based on calculated width
    if (currentWidth < 480) {
      this.slideConfigForSongs.slidesToShow = 1;
      this.slideConfigForSongs.slidesToScroll = 1;
      this.slideConfigForartist.slidesToShow = 1
    }
    else if (currentWidth >= 500 && currentWidth <= 699) {
      this.slideConfigForSongs.slidesToShow = 3;
      this.slideConfigForSongs.slidesToScroll = 3;
      this.slideConfigForartist.slidesToShow = 3
      this.maxWidth = "422";
    }
    else if (currentWidth >= 700 && currentWidth <= 800) {
      this.slideConfigForSongs.slidesToShow = 4;
      this.slideConfigForSongs.slidesToScroll = 4;
      this.slideConfigForartist.slidesToShow = 4
      this.maxWidth = "570";
    }
    else if (currentWidth >= 800 && currentWidth <= 1000) {
      this.slideConfigForSongs.slidesToShow = 4;
      this.slideConfigForSongs.slidesToScroll = 4;
      this.slideConfigForartist.slidesToShow = 4
      this.maxWidth = "642";
    } else if (currentWidth >= 1000 && currentWidth < 1150) {
      this.slideConfigForSongs.slidesToShow = 4;
      this.slideConfigForSongs.slidesToScroll = 4;
      this.slideConfigForartist.slidesToShow = 4;
      this.maxWidth = "774";
    }
    else if (currentWidth >= 1150 && currentWidth < 1200) {
      this.slideConfigForSongs.slidesToShow = 5;
      this.slideConfigForSongs.slidesToScroll = 5;
      this.slideConfigForartist.slidesToShow = 5;
      this.maxWidth = "870";
    }
    else if (currentWidth >= 1200 && currentWidth < 1300) {
      this.slideConfigForSongs.slidesToShow = 5;
      this.slideConfigForSongs.slidesToScroll = 5;
      this.slideConfigForartist.slidesToShow = 5;
      this.maxWidth = "950";
    } else if (currentWidth >= 1300 && currentWidth < 1500) {
      this.slideConfigForSongs.slidesToShow = 5;
      this.slideConfigForartist.slidesToShow = 5;
      this.maxWidth = "1000";
    } else if (currentWidth >= 1650 && currentWidth < 1700) {
      this.slideConfigForSongs.slidesToShow = 6;
      this.slideConfigForartist.slidesToShow = 6;
      this.maxWidth = "1263";
    }
    else if (currentWidth >= 1700 && currentWidth < 1800) {
      this.slideConfigForSongs.slidesToShow = 6;
      this.slideConfigForartist.slidesToShow = 6;
      this.maxWidth = "1263";
    }
    else if (currentWidth >= 1800 && currentWidth < 1900) {
      this.slideConfigForSongs.slidesToShow = 6;
      this.slideConfigForartist.slidesToShow = 6
      this.maxWidth = "1284";
    }
    else if (currentWidth >= 1900 && currentWidth < 2000) {
      this.slideConfigForSongs.slidesToShow = 6;
      this.slideConfigForartist.slidesToShow = 6
      this.maxWidth = "1420";
    }
    else if (currentWidth >= 2000 && currentWidth < 3000) {
      this.slideConfigForSongs.slidesToShow = 7;
      this.slideConfigForartist.slidesToShow = 7
      this.maxWidth = "1520";
    }
    else if (currentWidth >= 3000 && currentWidth < 4000) {
      this.slideConfigForSongs.slidesToShow = 9;
      this.slideConfigForartist.slidesToShow = 9
      this.maxWidth = "1976";
    }
    else if (currentWidth >= 4000 && currentWidth < 5000) {
      this.slideConfigForSongs.slidesToShow = 10;
      this.slideConfigForartist.slidesToShow = 10;
      this.maxWidth = "2030";
    }
    else if (currentWidth >= 5000 && currentWidth < 6000) {
      this.slideConfigForSongs.slidesToShow = 9;
      this.slideConfigForartist.slidesToShow = 9;
      this.maxWidth = "1730";
    }
    else if (currentWidth >= 6000 && currentWidth < 7000) {
      this.slideConfigForSongs.slidesToShow = 10;
      this.slideConfigForartist.slidesToShow = 10;
      this.maxWidth = "2130";
    }
    else if (currentWidth >= 7000) {
      this.slideConfigForSongs.slidesToShow = 15;
      this.slideConfigForartist.slidesToShow = 15;
      this.maxWidth = "1930";
    }
    else {
      this.slideConfigForSongs.slidesToShow = 5;
      this.slideConfigForartist.slidesToShow = 5;
      this.maxWidth = "1157";
    }

    this.refreshCarousel = false;  // Temporarily disable carousel to clear the old instance
    this.cdr.detectChanges();
    //this.checkButtonVisibility();     // Trigger change detection to update view
    setTimeout(() => {
      this.refreshCarousel = true;  // Re-enable carousel
      this.cdr.detectChanges();
      // Trigger change detection again to recreate the carousel
    });
    this.btnsizeChange();
    // Force refresh the carousel (if required, depending on ngx-slick-carousel implementation)
  }

  getfeaSongs() {
    this._songservice.GetFeaturedSongs().subscribe((data: any) => {
      this.featuredSong = data.feaSongs;
      this.path = data.feaSongs.path;
    });
  }
  snoop: any;
  CheckSubscription(song, i) {
    this.songIsthere = true;
    const songsArray: any[] = [];
    const pathsArray: string[] = [];

    song.forEach(item => {
      if (item.hasOwnProperty('songs') && Array.isArray(item.songs) && item.songs.length > 0) {
        item.songs.forEach((song, index) => {
          // Ensure thumbImage and add path if exists
          song.thumbImage = song.thumbImage ? item.path + song.thumbImage : '';
          // Add index to song object
          song.index = index;
          // Push the modified song to songsArray
          songsArray.push(song);
          // Push path to pathsArray
          //pathsArray.push(item.path);
        });
      } else if (item.hasOwnProperty('songs') && !Array.isArray(item.songs)) {
        // Handle case where 'songs' is a single object, not an array
        let song = item.songs;
        // Ensure thumbImage and add path if exists
        song.thumbImage = song.thumbImage ? item.path + song.thumbImage : '';
        // Add index to song object (assuming index 0 for single object)
        song.index = 0;
        // Push the modified song to songsArray
        songsArray.push(song);
        // Push path to pathsArray
        //pathsArray.push(item.path);
      }
    });


    this.sectionSongPlay(songsArray, i);
  }

  sectionSongPlay(item, i) {

    this.playSong(item, i);
    this.playerService.playSongCallbackmain(true);
  }

  playSong(songs, index) {
    this._playerService.msaapPlaylist = [];
    songs.forEach((song, i) => {
      const trackObj = new Track();
      trackObj.index = i;
      trackObj.image = song.thumbImage;
      trackObj.artistName = song.artistName;
      trackObj.link = song.streamUrl;
      trackObj.title = song.title;
      trackObj.songId = song.songId;
      this._playerService.msaapPlaylist.push(trackObj);
    });
    this.playerService.playSongCallback(true);

    if (this.playerService.repeatSong) {
      this.playerService.repeatSong = false;
    }
    this.playerService.setPlaylist(this._playerService.msaapPlaylist);
    this.playerService.selectATrack(index + 1);
    this._playerService.updateData(this._playerService.msaapPlaylist);
  }

  getallbannerdetails() {
    this.userService.getAllBannerDetails().subscribe((data: any) => {
      this.bannerDetails = data.bannerDetails;
    });
  }

  gethomeimages(userId) {
    debugger;
    var folderName = "Songs";
    this._homeService.gethomeimages(userId).subscribe((data: any) => {
      console.log('homeImages', data)
      //var ids = data.homeImages.homeImages[0];
      data.homeImages.newHomeDetails.map((im, i) => {
        if (im.type == "Users Favorits") {
          im.routing = "/userfav";
        }
        else if (im.type == "Beloved Beats") {
          im.routing = "/mostplayedsongs";
        }
        else if (im.type == "Hit Makers") {
          folderName = "Artists";
          im.routing = "/hitmakers";
        }
        else if (im.type == "Album Addicts") {
          folderName = "Albums";
          im.routing = "/mostplayedalbums";
        }
        else if (im.type == "Past Makers") {
          folderName = "Songs";
          im.routing = "/newlyaddedsongs";
        }
        else if (im.type == "Go-To-Tracks") {
          folderName = "Songs";
          im.routing = "";
        }

        //ids.mostPlayedSongsId==0||ids.mostPlayedArtistsId==0||ids.recommendedSongsId==0||ids.mostPlayedAlbumsId==0||ids.newlyAddedSongsId==0
        // if(ids.likedSongsId==0){
        //   im.thumbImage='https://t4.ftcdn.net/jpg/04/73/25/49/360_F_473254957_bxG9yf4ly7OBO5I0O5KABlN930GwaMQz.jpg';  
        //   im.type=="Liked Songs"
        // }
        // else{
        im.thumbImage = data.path + 'images/' + folderName + '/' + im.homeImageId + '/' + im.thumbImage;
        // }      
        //im.routing = this.routing[i];
      })
      this.hImages = data.homeImages.newHomeDetails;
      // if (ids.likedSongsId == 0) {
      //   this.hImages.unshift({ homeImageId: 0, type: 'Users Favorits', routing: "/userfav", thumbImage: '../../assets/images/Nosong.png' })
      // }
      // if (ids.mostPlayedSongsId == 0) {
      //   //this.hImages.push({homeImageId:0,type:'Most Played Songs',routing:"/mostplayedsongs",thumbImage:'../../assets/images/Nosong.png'})
      //   this.hImages.splice(1, 0, { homeImageId: 0, type: 'Beloved Beats', routing: "/mostplayedsongs", thumbImage: '../../assets/images/Nosong.png' })
      // }
      // if (ids.mostPlayedArtistsId == 0) {
      //   this.hImages.splice(2, 0, { homeImageId: 0, type: 'Hit Makers', routing: "/hitmakers", thumbImage: '../../assets/images/Nosong.png' })
      // }
      // if (ids.recommendedSongsId == 0) {
      //   this.hImages.splice(3, 0, { homeImageId: 0, type: 'Album Addicts', routing: "/mostplayedalbums", thumbImage: '../../assets/images/Nosong.png' })
      // }
      // if (ids.mostPlayedAlbumsId == 0) {
      //   this.hImages.splice(4, 0, { homeImageId: 0, type: 'Past Makers', routing: "/newlyaddedsongs", thumbImage: '../../assets/images/NoAlbum.png' })
      // }
      // if (ids.newlyAddedSongsId == 0) {
      //   this.hImages.splice(5, 0, { homeImageId: 0, type: 'Go-To-Tracks', routing: "", thumbImage: '../../assets/images/Nosong.png' })
      // }
      console.log(this.hImages)
    })
  }


  getfeaPlaylists() {
    this._homeService.getadminPlaylist().subscribe((data: any) => {
      let playList = data.playListDetails;
      this.adminplstpath = data.path;
      this.adminplaylist = data.adminPlaylistPath;
      for (let i = 0; i <= 7; i++) {
        var j = Math.floor(Math.random() * playList.length);
        if (playList[j] != undefined) {
          this.featuredPlaylist.push(playList[j]);
          playList.splice(j, 1);
        }
        //console.log(this.featuredPlaylist);
      }

    })
  }


  getPlaylistDetails(item) {
    this.router.navigate(['/playlist/allplaylistsongs'], { queryParams: { Id: item.playlistId, admin: item.name } });
  }

  getAllDetails() {
    this.userService.getAllHomeSection().subscribe((hsData: any) => {
      const promises = [];

      hsData.homeSectionobj.forEach(section => {
        if (section.isActive) {
          let newData = { sectionTitle: section.sectionTitle, items: [], type: section.sectionType, order: section.orderSection };

          let ids = {
            songIds: section.songId ? section.songId.split(',') : [],
            albumIds: section.albumId ? section.albumId.split(',') : [],
            artistIds: section.artistId ? section.artistId.split(',') : [],
            playlistIds: section.playlistId ? section.playlistId.split(',') : []
          };

          promises.push(
            Promise.all([
              ...ids.songIds.map(id => this._songservice.getSongDetails(id).toPromise().then(result => ({ ...result, type: 'Songs' }))),
              ...ids.albumIds.map(id => this.albumService.GetAlbumsbyAlbumId(id).toPromise().then(result => ({ ...result, type: 'Albums' }))),
              ...ids.artistIds.map(id => this.artistService.getArtistDetails(id).toPromise().then(result => ({ ...result, type: 'Artists' }))),
              ...ids.playlistIds.map(id => this._homeService.getadminPlaylistById(id).toPromise().then(result => ({ ...result, type: 'Playlist' }))),
              // Handle playlists separately if needed
            ])
              .then(results => {
                results.forEach(item => {
                  item.thumbImage = item.path + (
                    item.songs ? item.songs.thumbImage :
                      item.artistDetails ? item.artistDetails.thumbImage :
                        item.albumDetails ? (item.albumDetails.albumId + '/' + item.albumDetails.thumbImage) :
                          item.playListDetails ? item.playListDetails.thumbImage :
                            ''
                  );

                  item.link = item.albumDetails ? `/discoverAlbum/${item.albumDetails.albumId}` : '';
                  item.duration = item.songs ? item.songs.length.slice(3, 8) : "00:00";
                  newData.items.push(item);
                  console.log('img', item)
                });
                // .then(results => {
                //   // console.log(section);
                //   results.forEach(item => {
                //     item.thumbImage = item.path + (item.songs ? item.songs.thumbImage : '' || item.artistDetails ? item.artistDetails.thumbImage : '' ||
                //       item.albumDetails ? item.albumDetails.thumbImage : '' || item.playListDetails ? item.playListDetails.thumbImage : ''); // Simplified for clarity
                //     item.link = item.albumDetails ? `/discoverAlbum/${item.albumDetails.albumId}` : '';
                //     item.duration = item.songs ? item.songs.length.slice(3, 8) : "00:00";
                //     newData.items.push(item);

                //   });
                this.allData.push(newData);
                console.log(this.allData);
                this.allData.sort((a, b) => a.order - b.order);
                this.sectionData = this.allData;
                this.initializeCarouselMapping();
                this.btnsizeChange();
              })
          );
        }
      });

      // Execute all grouped promises
      Promise.all(promises).then(() => {
        this.cdr.detectChanges(); // Manually trigger change detection
        // console.log('All data has been loaded and processed:', this.allData);
      }).catch(error => {
        //console.error('Error while processing data:', error);
      });
    }, error => {
      //console.error('Error fetching home section data:', error);
    });
  }

  getFilteredSongs(sectionTitle: string) {
    // Assuming songDetail is an array of arrays, each containing song objects with a 'type' property
    return this.songDetail.flat().filter(song => song.type === sectionTitle);
  }

  slideConfig = {
    "slidesToShow": 5,
    "slidesToScroll": 2,
    "dots": true,
    "infinite": false
  };

  Artistprofile(ArtistId: number) {
    this.router.navigate(['/newdiscover'], { queryParams: { Id: ArtistId } });
  }

  onBannerClick(item) {
    //console.log(item);
    if (item.promotion != null) {
      window.open(item.promotion, '_blank');
    }
    else if (item.imageType == "Album") {
      this.router.navigate([`/discoverAlbum/` + item.albumId + ``]);
    }
    else if (item.imageType == "Artist") {
      this.router.navigate(['/newdiscover'], { queryParams: { Id: item.artistId } });
    }
    else {
      this.router.navigate([`/bannerdetails/` + item.homeBannerId + ``]);
    }
  }

  btnsizeChange() {
    this.allData.forEach((section, idx) => {
      this.visibilityStates[idx] = {
        showPrev: false,
        showNext: section.items.length > this.slideConfigForSongs.slidesToShow
      };
    });
  }

  items = [
    { id: 1, title: 'Top 50', subtitle: 'Global', category: 'Top 50 Global', backgroundColor: 'linear-gradient(0deg, #8a2386 0%, #8a2386 50%, #ffffff 100%)' },
    { id: 2, title: 'Top 50', subtitle: 'Sweden', category: 'Top 50 Sweden', backgroundColor: 'linear-gradient(0deg, #e2af80 0%, #e2af80 50%, #ffffff 100%)' },
    { id: 3, title: 'Top 50', subtitle: 'Germany', category: 'Top 50 Germany', backgroundColor: 'linear-gradient(0deg, #dacc8a 0%, #dacc8a 50%, #ffffff 100%)' },
    { id: 4, title: 'Top 50', subtitle: 'West', category: 'Top 50 West', backgroundColor: 'linear-gradient(0deg, #e9d0e6 0%, #e9d0e6 50%, #ffffff 100%)' },
    { id: 5, title: 'Top 50', subtitle: 'East', category: 'Top 50 East', backgroundColor: 'linear-gradient(0deg, #bcbff9 0%, #bcbff9 50%, #ffffff 100%)' },
    { id: 6, title: 'Top 50', subtitle: 'Gen Z', category: 'Top 50 Gen Z', backgroundColor: 'linear-gradient(0deg, #b2d5f7 0%, #b2d5f7 50%, #ffffff 100%)' },
    { id: 7, title: 'Top 25', subtitle: 'East Classics', category: 'Top 25 East Classics', backgroundColor: 'linear-gradient(0deg, #bcb4fe 0%, #bcb4fe 50%, #ffffff 100%)' },
    { id: 8, title: 'Top 10', subtitle: 'Playlists', category: 'Top 10 Playlists', backgroundColor: 'linear-gradient(0deg, #cde592 0%, #cde592 50%, #ffffff 100%)' },
    { id: 9, title: 'Top 25', subtitle: 'Church music', category: 'Top 25 Church music', backgroundColor: 'linear-gradient(0deg, #eed4a6 0%, #eed4a6 50%, #ffffff 100%)' },
    { id: 10, title: 'Top 25', subtitle: 'West Classics', category: 'Top 25 West Classics', backgroundColor: 'linear-gradient(0deg, #edb6e0 0%, #edb6e0 50%, #ffffff 100%)' }
  ];

  onClick(e) {
    this.router.navigate([`/topmostsongs/` + e + ``]);
    //this.router.navigate(['topmostsongs'], { queryParams: { id: e } });
  }

  gettopfavsong() {
    this._songservice.GetTopFavSongs(this.userDetails.userId).subscribe((data: any) => {
      console.log('tfavsongs', data.getTopFavSongs.slice(0, 15));
      this.tfavsongs = data.getTopFavSongs.slice(0, 15);
    });
  }

  gettopmostplayedArtist() {
    this.artistService.GetTopMostPlayedArtist().subscribe((res: any) => {
      this.tmostArtist = res.getTopMPArtist;
      console.log('tmostArtist', res.getTopMPArtist);
    });
  }

  // getBorderColor(id: number): string {
  //   const colorMap: { [key: number]: string } = {
  //     1: '2px solid #8a2386',
  //     2: '2px solid #e2af80',
  //     3: '2px solid #dacc8a',
  //     4: '2px solid #e9d0e6',
  //     5: '2px solid #bcbff9',
  //     6: '2px solid #b2d5f7',
  //     7: '2px solid #bcb4fe',
  //     8: '2px solid #cde592',
  //     9: '2px solid #eed4a6',
  //     10: '2px solid #edb6e0'
  //   };
  //   return colorMap[id] || 'black'; // Default color if ID not found in map
  // }

  colors = ['3px solid #8a2386', '3px solid #e2af80',
    '3px solid #dacc8a', '3px solid #e9d0e6', '3px solid #bcbff9', '3px solid #b2d5f7', '3px solid #bcb4fe', '3px solid #cde592', '3px solid #eed4a6', '3px solid #edb6e0'];

  getBorderColor(index: number): string {
    return this.colors[index % this.colors.length];
  }

  getlastmonthtopmostplayedSong() {
    this._songservice.Getlastmonthtopmostplayedsongs(this.userDetails.userId).subscribe((data: any) => {
      console.log('lastmntsongs', data.lastMonthPlayedSong);
      this.mstPlayedSongLstMnth = data.lastMonthPlayedSong.slice(0, 15);
      //this.tfavsongs = data.getTopFavSongs.slice(0, 15);
    });
  }

  gettop25newArtistAlbumSongs() {
    this._homeService.getTopNewArtistAlbumSongs(this.userDetails.userId).subscribe((data: any) => {
      console.log('top25new', data.newArtAlbSngs);
      this.top25newArtAlbSng = data.newArtAlbSngs;
      this.newArtist = data.newArtAlbSngs.artists[0].thumbImage;
      this.newAlbum = data.newArtAlbSngs.albums[0].thumbImage;
      this.newSong = data.newArtAlbSngs.songs[0].thumbImage;
      console.log('top25new', data.newArtAlbSngs, this.newArtist);
    })
  }

  cards = [
    { title: 'Dance mix', color: '#bd5e5e', icon: 'assets/images/dancemix.png', width: '115px', opacity: '0.600' },
    { title: 'Folklore', color: '#77a6e1', icon: 'assets/images/7.svg', width: '', opacity: '', bottom: '45px' },
    { title: 'Rock', color: '#508f3f', icon: 'assets/images/rock.png', width: '150px', opacity: '0.600', },
    { title: 'Oldies', color: '#bd895e', icon: 'assets/images/2.svg', width: '', bottom: '20px' },
    { title: 'Church hymns', color: '#babd5e', icon: 'assets/images/6.svg', width: '', bottom: '50px' },
    { title: 'Disco mix', color: '#7d5ebd', icon: 'assets/images/discomix.png', width: '', opacity: '0.600' },
    { title: 'Kids', color: '#bd5ebc', icon: 'assets/images/kids.png', width: '', opacity: '0.600' },
    { title: 'HipHop/Rap', color: '#5ebd8b', icon: 'assets/images/hipop.png', width: '', opacity: '0.600' },
    { title: 'Pop', color: '#b8560e', icon: 'assets/images/5.svg', width: '' }
  ];

  getallCategories() {
    this._songservice.GetallCategories().subscribe((data: any) => {
      // this.categories = data.categories;
      this.categories = data.categories.map(category => {
        const matchingCard = this.cards.find(card => card.color === category.categoryColor);
        if (matchingCard) {
          return {
            ...category,
            width: matchingCard.width,
            opacity: matchingCard.opacity,
            bottom: matchingCard.bottom
          };
        }
        return category; // Return category as-is if no matching card is found
      });
      this.categories = this.shuffleArray(this.categories);
      console.log('cat', this.categories)
    });
    // console.log('cat', this.categories)
    //this.categories = this.shuffleArray(this.categories);
    //console.log('cat', this.categories)
  }

  shuffleArray(array: any[]): any[] {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
    return array;
  }

  categoryPage(item: any) {
    this.router.navigate([`/topcategorysongs/` + item + ``]);
  }

  gotoTrack() {
    this.router.navigate(['/favourites'], { queryParams: { Id: 5 } });
  }

   //for Album this
  currentSlideIndexes: { [key: number]: number } = {};
  nextItemAlb(sectionIndex: number) {
    const carouselIndex = this.slickCarouselIndexMapAl[sectionIndex];
    const slickInstance = this.slickCarouselss.toArray()[carouselIndex];

    if (slickInstance) {
      slickInstance.slickNext(); // Move to the next slide.

      const itemsPerSlide = this.slideConfigForartist.slidesToShow;
      const totalSlides = slickInstance.slides ? slickInstance.slides.length : 0;

      // Update currentSlideIndex for this section.
      if (this.currentSlideIndexes[sectionIndex] === undefined) {
        this.currentSlideIndexes[sectionIndex] = 0;
      }
      this.currentSlideIndexes[sectionIndex] =
        (this.currentSlideIndexes[sectionIndex] + itemsPerSlide) % totalSlides;

      // Update arrow visibility.
      this.updateArrowssAlb(sectionIndex, totalSlides);
    }
  }

  prevItemAlb(sectionIndex: number) {
    const carouselIndex = this.slickCarouselIndexMapAl[sectionIndex];
    const slickInstance = this.slickCarouselss.toArray()[carouselIndex];

    if (slickInstance) {
      slickInstance.slickPrev(); // Move to the previous slide.

      const itemsPerSlide = this.slideConfigForartist.slidesToShow;
      const totalSlides = slickInstance.slides ? slickInstance.slides.length : 0;

      // Update currentSlideIndex for this section.
      if (this.currentSlideIndexes[sectionIndex] === undefined) {
        this.currentSlideIndexes[sectionIndex] = 0;
      }
      this.currentSlideIndexes[sectionIndex] =
        (this.currentSlideIndexes[sectionIndex] - itemsPerSlide + totalSlides) % totalSlides;

      // Update arrow visibility.
      this.updateArrowssAlb(sectionIndex, totalSlides);
    }
  }

  updateArrowssAlb(sectionIndex: number, totalSlides: number) {
    const currentIndex = this.currentSlideIndexes[sectionIndex] || 0;
    const itemsPerSlide = this.slideConfigForartist.slidesToShow;

    this.visibilityStates[sectionIndex] = {
      showPrev: currentIndex > 0, // Show "Prev" if not on the first slide.
      showNext: currentIndex + itemsPerSlide < totalSlides // Show "Next" if not on the last slide.
    };

    console.log(`Section ${sectionIndex}: CurrentIndex = ${currentIndex}, TotalSlides = ${totalSlides}`);
  }

  //for Artist this
  currentSlideIndexesss: { [key: number]: number } = {};
  nextItemArt(sectionIndex: number) {
    const carouselIndex = this.slickCarouselIndexMapArtist[sectionIndex];
    const slickInstance = this.slickCarousels2.toArray()[carouselIndex];

    if (slickInstance) {
      slickInstance.slickNext(); // Move to the next slide.

      const itemsPerSlide = this.slideConfigForartist.slidesToShow;
      const totalSlides = slickInstance.slides ? slickInstance.slides.length : 0;

      // Update currentSlideIndex for this section.
      if (this.currentSlideIndexesss[sectionIndex] === undefined) {
        this.currentSlideIndexesss[sectionIndex] = 0;
      }
      this.currentSlideIndexesss[sectionIndex] =
        (this.currentSlideIndexesss[sectionIndex] + itemsPerSlide) % totalSlides;

      // Update arrow visibility.
      this.updateArrowssArt(sectionIndex, totalSlides);
    }
  }

  prevItemArt(sectionIndex: number) {
    const carouselIndex = this.slickCarouselIndexMapArtist[sectionIndex];
    const slickInstance = this.slickCarousels2.toArray()[carouselIndex];

    if (slickInstance) {
      slickInstance.slickPrev(); // Move to the previous slide.

      const itemsPerSlide = this.slideConfigForartist.slidesToShow;
      const totalSlides = slickInstance.slides ? slickInstance.slides.length : 0;

      // Update currentSlideIndex for this section.
      if (this.currentSlideIndexesss[sectionIndex] === undefined) {
        this.currentSlideIndexesss[sectionIndex] = 0;
      }
      this.currentSlideIndexesss[sectionIndex] =
        (this.currentSlideIndexesss[sectionIndex] - itemsPerSlide + totalSlides) % totalSlides;

      // Update arrow visibility.
      this.updateArrowssArt(sectionIndex, totalSlides);
    }
  }

  updateArrowssArt(sectionIndex: number, totalSlides: number) {
    const currentIndex = this.currentSlideIndexesss[sectionIndex] || 0;
    const itemsPerSlide = this.slideConfigForartist.slidesToShow;

    this.visibilityStates[sectionIndex] = {
      showPrev: currentIndex > 0, // Show "Prev" if not on the first slide.
      showNext: currentIndex + itemsPerSlide < totalSlides // Show "Next" if not on the last slide.
    };

    console.log(`Section ${sectionIndex}: CurrentIndex = ${currentIndex}, TotalSlides = ${totalSlides}`);
  }

  //for Playlist

  currentSlideIndexespls: { [key: number]: number } = {};
  nextItempls(sectionIndex: number) {
    const carouselIndex = this.slickCarouselIndexMappls[sectionIndex];
    const slickInstance = this.slickCarouselpls.toArray()[carouselIndex];

    if (slickInstance) {
      slickInstance.slickNext(); // Move to the next slide.

      const itemsPerSlide = this.slideConfigForartist.slidesToShow;
      const totalSlides = slickInstance.slides ? slickInstance.slides.length : 0;

      // Update currentSlideIndex for this section.
      if (this.currentSlideIndexespls[sectionIndex] === undefined) {
        this.currentSlideIndexespls[sectionIndex] = 0;
      }
      this.currentSlideIndexespls[sectionIndex] =
        (this.currentSlideIndexespls[sectionIndex] + itemsPerSlide) % totalSlides;

      // Update arrow visibility.
      this.updateArrowsspls(sectionIndex, totalSlides);
    }
  }

  prevItempls(sectionIndex: number) {
    const carouselIndex = this.slickCarouselIndexMappls[sectionIndex];
    const slickInstance = this.slickCarouselpls.toArray()[carouselIndex];

    if (slickInstance) {
      slickInstance.slickPrev(); // Move to the previous slide.

      const itemsPerSlide = this.slideConfigForartist.slidesToShow;
      const totalSlides = slickInstance.slides ? slickInstance.slides.length : 0;

      // Update currentSlideIndex for this section.
      if (this.currentSlideIndexespls[sectionIndex] === undefined) {
        this.currentSlideIndexespls[sectionIndex] = 0;
      }
      this.currentSlideIndexespls[sectionIndex] =
        (this.currentSlideIndexespls[sectionIndex] - itemsPerSlide + totalSlides) % totalSlides;

      // Update arrow visibility.
      this.updateArrowsspls(sectionIndex, totalSlides);
    }
  }

  updateArrowsspls(sectionIndex: number, totalSlides: number) {
    const currentIndex = this.currentSlideIndexespls[sectionIndex] || 0;
    const itemsPerSlide = this.slideConfigForartist.slidesToShow;

    this.visibilityStates[sectionIndex] = {
      showPrev: currentIndex > 0, // Show "Prev" if not on the first slide.
      showNext: currentIndex + itemsPerSlide < totalSlides // Show "Next" if not on the last slide.
    };

    console.log(`Section ${sectionIndex}: CurrentIndex = ${currentIndex}, TotalSlides = ${totalSlides}`);
  }
}
