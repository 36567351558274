import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/Shared/user.service';
import { Users } from 'src/app/models/users';
import {
  AuthService, SocialLoginModule,
  FacebookLoginProvider,
  GoogleLoginProvider,

} from 'angular-6-social-login';
import { LoginService } from 'src/app/Shared/login.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-login-methods',
  templateUrl: './edit-login-methods.component.html',
  styleUrls: ['./edit-login-methods.component.css'],
  providers: [SocialLoginModule, AuthService]
})
export class EditLoginMethodsComponent implements OnInit, AfterViewInit {
  userDetails = new Users();
  userLoginDetails: any;
  isEmail: boolean = false;
  isFB: boolean = false;
  isGoogle: boolean = false;
  userId: number;
  subscriptionStatus: any;
  showHideRegistraionPage: boolean = true;
  showHidePlan: boolean = false;
  isMobile:boolean=true;

  constructor(private _userService: UserService, private toastr: ToastrService, public socialAuthService: AuthService,
    private _loginService: LoginService, private _router: Router,public translate: TranslateService,
  ) { }

  ngAfterViewInit(): void {
    //document.getElementById("email").focus();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    //this.userEmail = this.userDetails.email;
    console.log(this.userDetails)
    this.getUserDetails();
  }
  

  changeEmailId(f) {
    console.log(this.userDetails)
    if (f.valid) {
      this._userService.UpdateEmailId(this.userDetails.userId, this.userDetails.email).subscribe(
        (user: any) => {
          console.log(user); debugger;
          if (user.isSuccess) {
            this.userDetails.email = user.emailId.email;
            localStorage.setItem('UserDetails', JSON.stringify(this.userDetails))
            this.toastr.success(user.message);
          }
        })
    }
    else {
      this.toastr.error("Please enter emailId.")
    }
  }
  getUserDetails() {
    debugger
    this._userService.GetUserDetails(this.userDetails.userId).subscribe((user: any) => {
      this.userLoginDetails=user.userDetail;
      console.log(user,this.userDetails,this.userLoginDetails)
      // if (user.userDetail.deviceType==null){ 
      //    this.isMobile=false;
      // }
      // if (user.userDetail.isDefaultLogin) {
      //   this.isEmail = true;
      // }
      // else if (user.userDetail.isFBLogin) {
      //   this.isFB = true;
      // }
      // else 
      if (user.userDetail.isDefaultLogin == false && user.userDetail.isFBLogin == false && user.userDetail.isGoogleLogin == false) {
        this.userLoginDetails.isDefaultLogin=true;
      }
      // else {
      //   this.isGoogle = true;
      // }
    })
  }

  public socialSignIn(socialPlatform: string) {
    let socialPlatformProvider;
    if (socialPlatform === 'facebook') {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else if (socialPlatform === 'google') {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }
    debugger;
    this.socialAuthService.signIn(socialPlatformProvider).then(userData => {
      this.apiConnection(userData);
    },
      err => {
        this.toastr.error(err);
      });
  }


  apiConnection(data) {
    debugger;
    this.userDetails = new Users();
    this.userDetails.email = data.email;
    if (data.provider === "google") {
      this.userDetails.googleEmail = data.email;
    }
    else {
      this.userDetails.fbEmail = data.email;
    }
    this.userDetails.displayName = data.name;
    this.userDetails.provider = data.provider;
    this.userDetails.provider_id = data.id;
    this.userDetails.image = data.image;
    // this.userDetails.Token = data.token;

    this.userDetails.userType = "USER";
    this.userDetails.countryId = 1;
    this._loginService.postData(this.userDetails).subscribe((res: any) => {
      this.userDetails.userType = "USER";
      this.userDetails = res.userDetail;
      localStorage.clear();
      localStorage.setItem('UserDetails', JSON.stringify(this.userDetails));
      this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
      this.userId = this.userDetails.userId;
      this.subscriptionStatus = this.userDetails.subscriptionStatus;

      if (this.subscriptionStatus == "True") {
        // this._router.navigate([`/home`]);
        this._userService.IsUser(true);
        this._router.navigate([`/newhome`]);
      }
      else if (this.subscriptionStatus == "False") {
        // this._router.navigate([`/home`]);
        this._userService.IsUser(true);
        this._router.navigate([`/newhome`]);

      }
      else if (this.subscriptionStatus == null) {
        this.toastr.success("Your are successfully registered !");
        this.showHideRegistraionPage = false;
        this.showHidePlan = true;
        this._router.navigate(['/toby-home']);
      }
    }, err => {
      this.toastr.error(err)
    });
  }
  defaultLogin(){
    let UserId = this.userDetails.userId;
    let SessionId = this.userDetails.sessionId;
    this._loginService.logOutAudit(UserId, SessionId).subscribe((res: any) => {
    })
    localStorage.clear();
    localStorage.setItem('themeid', this.userDetails.themeId);
    localStorage.setItem('languagecode', this.userDetails.languageCode);
    this.translate.use("en");
    localStorage.clear();
    this._router.navigate(['/login']);
    localStorage.removeItem("UserDetails");
  }
}
