import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccountsRoutingModule } from '../account/account-routing.module';
// import { SettingComponent } from './setting/setting.component';
// import { ThemeComponent } from './theme/theme.component';
// import { SuggestionComponent } from './suggestion/suggestion.component';
// import { ReportComponent } from './report/report.component';
// import { ChangeLanguageComponent } from './change-language/change-language.component';
  import { AccountComponent } from '../account/account.component';
//import { UseraccountHeaderComponent } from './useraccount-header/useraccount-header.component';
//import { UserAccountComponent } from './user-account/user-account.component';
// import { AccountsRoutingModule } from '../account/account-routing.module';
// import { EditprofileComponent } from './editprofile/editprofile.component';
// import { ProfileComponent } from './profile/profile.component';
// import { AboutusComponent } from './aboutus/aboutus.component';
// import { TermsComponent } from './terms/terms.component';
// import { PaymentComponent } from './payment/payment.component';
import {MatIconModule} from '@angular/material/icon';
import { ChangePaymentComponent } from './change-payment/change-payment.component';
import { UpgradeSubscriptionComponent } from './upgrade-subscription/upgrade-subscription.component';
//import { UserOrderHistoryComponent } from './user-order-history/user-order-history.component';
//import { EditLoginMethodsComponent } from './edit-login-methods/edit-login-methods.component';
//import { UserNotificationSettingsComponent } from './user-notification-settings/user-notification-settings.component';
//import { ChangeUserPasswordComponent } from './change-user-password/change-user-password.component';

@NgModule({
    imports: [
        CommonModule, FormsModule, AccountsRoutingModule, ReactiveFormsModule
    ],
    declarations: [
        // SettingComponent,
        // PaymentComponent,
        // TermsComponent,
        AccountComponent,
        //UserOrderHistoryComponent,
        //UpgradeSubscriptionComponent,
        //EditLoginMethodsComponent,
        //UserNotificationSettingsComponent,
        //ChangePaymentComponent,
       // ChangeUserPasswordComponent,
        //UseraccountHeaderComponent,
        //UserAccountComponent,
        // ThemeComponent,
        // SuggestionComponent,
        // ChangeLanguageComponent,
        // ReportComponent,
        // EditprofileComponent,
        // AboutusComponent,
        // ProfileComponent,
    ],
    exports: [RouterModule,
        MatIconModule]

})
export class AccountModule { }
