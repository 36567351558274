export class  ChatModel {
    text: any = "";
    isSender: true;
    date: Date;
    image: any;
    senderId: any;
    receiverID: any;
    ACEAppointmentId: any;   
    MsgType:any;
    caseId: any;
    InstantAceApptid:any;
    ClientName:any;
    ClientId:any;
    Name:any;
    Type:any;
    Message:any;
    DateTime:any;  
}

