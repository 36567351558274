import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Suggestionstatus } from '../../../models/suggestionstatus';
import { UserService } from 'src/app/Shared/user.service';
import { Problem } from 'src/app/models/problem';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { Users } from '../../../models/users';
@Component({
  selector: 'app-update-problem',
  templateUrl: './update-problem.component.html',
  styleUrls: ['./update-problem.component.css']
})
export class UpdateProblemComponent implements OnInit {
  userDetails = new Users();
  problemId: any;
  status: any;
  problemdetails = new Problem();
  sgstatus: any;
  sgstatusForDone: Suggestionstatus;
  sgstatusForHol: Suggestionstatus;
  constructor(private route: ActivatedRoute, private userService: UserService, private toastr: ToastrService, public location: Location, private _router: Router) {
    this.problemId = this.route.snapshot.paramMap.get("id");
  }

  ngOnInit() {
    this.GetproblemById(this.problemId)
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
  }
  GetproblemById(problemId: string) {
    this.userService.GetproblemById(problemId).subscribe((data: any) => {
      this.problemdetails = data.problemdetails;
    })
  };
  onSubmit() {
    if (this.userDetails.userId != undefined) {
      this.problemdetails.updatedBy = this.userDetails.userId;
      //  this.song.updatedBy = this.userDetails["artistId"];

    }
    this.userService.addupdateproblem(this.problemdetails).subscribe((res: any) => {
      if (res.status == 200) {


      }
    })
    this.toastr.success("Status Updated successsfully");

    //this.location.back();
    this._router.navigate(['/admin/admin-problems']);
  }
  cancel() {
    this._router.navigate(['/home']);
  }
}