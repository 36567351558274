import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { LoadScriptService } from '../../loadScript.service';
import { HomeService } from '../../Shared/home.service';
import { Role } from 'src/app/models/role';
import { PlayerService } from 'src/app/Shared/player.service';
import { Track } from '../../../../Player/ngx-audio-player/src/lib/model/track.model';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { getPackedSettings } from 'http2';
import { Router } from '@angular/router';
import { Pagination } from 'src/app/models/pagination';
import { Playlist } from 'src/app/models/playlist';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/Shared/user.service';
import { ArtistsService } from '../../Shared/artists.service';
import { SongsService } from '../../Shared/songs.service';
import * as $ from 'jquery';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { AlbumService } from 'src/app/Shared/album.service';
import { NgForm } from '@angular/forms';
@Component({
    selector: 'app-rightside-menu',
    templateUrl: './rightside-menu.component.html',
    styleUrls: ['./rightside-menu.component.css']
})
export class RightsideMenuComponent implements OnInit {
    @ViewChild('closeBtn') closeBtn: ElementRef;
    //@ViewChild('sectionNeedToScroll') sectionNeedToScroll: ElementRef;
    @ViewChild('f') form: NgForm;
    //Songs Details To play
    searchArtist: boolean = false;
    artistData: boolean;
    songDetails = new Track();
    SearchSongsdetails = [];
    searchForSongs;
    pageIndex = 1;
    pageSize = 6;
    songsPerPage;
    searchHistorykeyword: boolean = true;
    Checkrecords: boolean = false;
    searchSong; any;
    activePage: boolean[] = [];
    searchHistory: boolean = false;
    pageNumber: boolean[] = [];
    totalPage: number;
    tempPageIndex: number;
    userDetails: any;
    userLogin: boolean;
    pageData: boolean = false;
    path: any;
    indexForSong = 1;
    searchForartist;
    searchtext: any = '';
    checkedvalue: any = [];
    checkedvcategory: any;
    searchartistdetails: any;
    searchDetails: any = [];
    searchalbumdetails: any;
    fullName: any;
    SearchName: any;
    artistTabSelected: boolean = true;
    albumTabSelected: boolean = false;
    songsTabSelected: boolean = false;
    djMixTabSelected: boolean = false;
    songsNextPageSelected: any;
    artistList = [];
    playlist = new Playlist();
    noofLikes: any;
    previousdisplay: boolean = true;
    nextdisplay: boolean = false;
    noLikeImage: boolean[] = [];
    nofavImage: boolean[] = [];
    tempData: number = 1;
    tempArray = [];
    tempArrayPath: any;
    tempDataArray: any;
    noRecordData: string = "Artists";
    artistId;
    playListDetails: any;
    index = 0;
    playd = [];
    pageNumberPerPage = 0;
    pageNumberShow = Pagination.displayNumberperpage;
    temppage: number = 0;
    disabledNextBtn: boolean;
    disabledPrevBtn: boolean = true;
    pageField = [];
    exactPageList: any;
    prevtrue: boolean;
    nexttrue: boolean;
    currentPage = 1;
    noRecords: boolean = false;
    lastPage: any = 0;
    // pageNumber: boolean[] = [];
    showNoOfCurrentPage: any = 1;
    showPageOnlyOntabsChange: boolean = true;
    noLikeImage1: boolean[] = [];
    nofavimage1: boolean[] = [];
    //Albumfavimage1: boolean[] = [];

    ArtistLikeImage: boolean[] = [];
    Artistfavimage: boolean[] = [];
    AlbumLikeImage: boolean[] = [];
    Albumfavimage: boolean[] = [];
    route: any;
    paginationData: number;
    UserId: any;
    songslikeid: any;
    result: any;
    createchecked: boolean = true;
    updateextchecked: boolean;
    LikeAlbumImage: boolean[] = [];
    FavAlbumImage: boolean[] = [];
    substatus: any;
    songIsthere: boolean = false;
    isChecked: any;
    songlangugae: any;
    categories: any;
    langChecked: boolean[] = [];
    categoryChecked: boolean[] = [];
    playlistId: any;
    selectedLanguages = [];
    selectlangname = [];
    selectedcategory = [];
    langname: any;
    selectedcategoryname = [];
    showfilter: boolean = false;
    showcatfilter: boolean;
    display = 'none'; //default Variable
    languageCode: any;
    theme: any;
    favsong: any
    favitems: any;
    falbums = [];
    favalbums = [];
    favdjmix = [];
    favsongs = [];
    favartists = [];
    currentSearchId: any = 0;
    playlistEntities = [];
    IsMobileReq:boolean;
    constructor(
        private router: Router,
        private loadScript: LoadScriptService,
        private _homeService: HomeService,
        private _playerService: PlayerService,
        private playerService: AudioPlayerService,
        private translate: TranslateService,
        private toastr: ToastrService,
        public userService: UserService,
        public artistService: ArtistsService,
        public _songsService: SongsService,
        public albumService: AlbumService,
        private artistsService: ArtistsService,
        public songsService: SongsService,
    ) {

    }

    ngOnInit() {
        window.scrollTo(0, 0)
        $(document).ready(function () {
            $(".Focus").focus();

        })
        // this.loadScript.getServiceBaseUrl();
        this.refresh();
        this.refreshalbum();
        this.refreshartist()
        this.getfavourites();
        this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
        this.substatus = this.userDetails.subscriptionStatus;
        this.UserId = this.userDetails.userId;
        this.freshplaylist();
        this.getsongLanguage();
        if (this.userDetails.userType == Role.superAdmin) {
            this.userLogin = false;
        }
        else if (this.userDetails.userType == Role.user) {
            this.userLogin = false;
        }
        else {
            this.userLogin = false;
        }
        this.getSearchHistory();
        this.getallCategories();
        this.searchHistory = true;
        this.langname = this.selectlangname.join(',');
        this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
        this.languageCode = this.userDetails.languageCode;
        this.theme = this.userDetails.themeId

    }
    // ngAfterViewInit() {
    //     this.sectionNeedToScroll.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
    // }
    searchSongs($event, i) {
        this.pageIndex = 1;
        this.activePage = [];
        this.activePage[0] = true;
        this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
        this.substatus = this.userDetails.subscriptionStatus;
        this.SearchSongsdetails = [];
        if ($event !== "" || ($event == "" && (this.showfilter || this.showcatfilter))) {
            this.SearchSongsdetails = [];
            this.searchArtist = true;
            this.searchHistory = false;
            this.searchtext = $event;
            this.searchHistorykeyword = false;
            this.pageField = [];
            this.activePage[0] = true;
            this.Checkrecords = false;
            if (this.artistTabSelected) {
                this.temppage = 0;
                this.callArtistService(i);
            } else if (this.albumTabSelected) {
                this.temppage = 0;
                this.callAlbumService(i);
            } else if (this.songsTabSelected) {
                this.temppage = 0;
                this.callSongService(i);
            } else if (this.djMixTabSelected) {
                this.temppage = 0;
                this.callSongdjmixService(i);
            }
        }
        else if (($event == "" && !this.showfilter)) {
            this.pageIndex = 1;

            this.previousdisplay = false;
            this.nextdisplay = false;
            this.searchHistorykeyword = true;
            this.Checkrecords = false;
            this.searchHistory = true;
            this.pageData = false;
            this.searchtext = "";
            this.searchArtist = false;
            if (this.checkedvalue.length == 0 && this.searchtext == '') {

                //  alert(1)
                this.getSearchHistory();
            } else {
                if (this.artistTabSelected) {
                    this.temppage = 0;

                    if (this.checkedvalue.length == 0 || this.searchtext == '') {
                        //  alert(2);
                        this.SearchSongsdetails = [];
                        this.getSearchHistory();
                    }
                    else {

                        this.callArtistService(i);
                    }

                } else if (this.albumTabSelected) {
                    this.temppage = 0;
                    if (this.checkedvalue.length == 0 || this.searchtext == 0) {
                        // alert(1);
                        this.getSearchHistory();
                    }
                    else {

                        this.callAlbumService(i);
                    }

                } else if (this.songsTabSelected) {
                    this.temppage = 0;
                    if (this.checkedvalue.length == 0 || this.searchtext == 0) {

                        this.getSearchHistory();
                    }
                    else {
                        this.searchHistory = false;
                        this.callSongService(i);
                    }

                } else if (this.djMixTabSelected) {
                    this.temppage = 0;
                    if (this.checkedvalue.length == 0 || this.searchtext == 0) {
                        // alert(1);
                        this.getSearchHistory();
                    }
                    else {

                        this.callSongdjmixService(i);
                    }
                }
            }
        }
    }
    SaveSearch(KeyWord) {
        if (KeyWord != "") {
            this._homeService.SaveSearch(this.UserId, KeyWord).subscribe((data: any) => {
            });
        }
    }
    callArtistService(i) {
        this.SearchSongsdetails = [];
        this.showPageOnlyOntabsChange = true;
        this.checkedvalue = this.selectedLanguages.join(',');
        this.searchArtist = true;
        //this.checkedvalue =this.isChecked
        this._homeService.Searchartist(this.searchtext, this.pageIndex, this.pageSize, this.UserId, this.checkedvalue).subscribe((data: any) => {

            this.pageData = true;
            this.searchHistory = false;

            this.SearchSongsdetails = data.artist;
            this.artistData = true;
            this.getpaginationdata(data);

            for (var a = 0; a < this.SearchSongsdetails.length; a++) {
                if (this.SearchSongsdetails[a].likeId == 0) {
                    this.ArtistLikeImage[a] = false;
                } else {
                    this.ArtistLikeImage[a] = true;
                }
            }
            for (var a = 0; a < this.SearchSongsdetails.length; a++) {
                if (this.SearchSongsdetails[a].favId == 0) {
                    this.Artistfavimage[a] = false;
                } else {
                    this.Artistfavimage[a] = true;
                }
            }
        });
    }
    callAlbumService(i) {
        this.SearchSongsdetails = [];
        this.searchArtist = true;
        this.showPageOnlyOntabsChange = true;
        this.checkedvalue = this.selectedLanguages.join(',');
        this.langname = this.selectlangname.join(',');
        this._homeService.Searchalbum(this.searchtext, this.pageIndex, this.pageSize, this.UserId, this.checkedvalue).subscribe((data: any) => {
            this.pageData = true;
            this.searchHistory = false;
            this.SearchSongsdetails = data.albums;
            this.artistData = false;
            this.getpaginationdata(data);

            for (var a = 0; a < this.SearchSongsdetails.length; a++) {
                if (this.SearchSongsdetails[a].likeId == 0) {
                    this.AlbumLikeImage[a] = false;
                } else {
                    this.AlbumLikeImage[a] = true;
                }
            }
            for (var a = 0; a < this.SearchSongsdetails.length; a++) {
                if (this.SearchSongsdetails[a].favId == 0) {
                    this.Albumfavimage[a] = false;
                } else {
                    this.Albumfavimage[a] = true;
                }
            }
        });
    }
    callSongService(i) {

        this.SearchSongsdetails = [];
        this.searchArtist = true;
        this.showPageOnlyOntabsChange = true;
        this.checkedvalue = this.selectedLanguages.join(',');
        this.langname = this.selectlangname.join(',');
        this.checkedvcategory = this.selectedcategory.join(',');
        this._homeService.SearchSong(this.searchtext, this.pageIndex, this.pageSize, this.UserId, this.checkedvalue, this.checkedvcategory).subscribe((data: any) => {

            this.pageData = true;
            this.searchHistory = false;
            this.SearchSongsdetails = data.songs;
            this.path = data.path;
            this.artistData = true;
            this.getpaginationdata(data);
            for (var a = 0; a < this.SearchSongsdetails.length; a++) {
                if (this.SearchSongsdetails[a].likeId == 0) {
                    this.noLikeImage1[a] = false;
                } else {
                    this.noLikeImage1[a] = true;
                }
            }
            for (var a = 0; a < this.SearchSongsdetails.length; a++) {
                if (this.SearchSongsdetails[a].favId == 0) {
                    this.nofavimage1[a] = false;
                } else {
                    this.nofavimage1[a] = true;
                }
            }
        });
    }

    callSongdjmixService(i) {
        this.SearchSongsdetails = [];
        this.searchArtist = true;
        this.selectedcategory = [];
        this.selectedcategoryname = [];
        this.showPageOnlyOntabsChange = true;
        this.checkedvalue = this.selectedLanguages.join(',');
        this.langname = this.selectlangname.join(',');
        this._homeService.Searchdjmix(this.searchtext, this.pageIndex, this.pageSize, this.UserId, this.checkedvalue).subscribe((data: any) => {
            this.pageData = true;
            this.searchHistory = false;
            this.SearchSongsdetails = data.djMix;
            this.path = data.path;
            this.artistData = false;
            this.getpaginationdata(data);
            for (var a = 0; a < this.SearchSongsdetails.length; a++) {
                if (this.SearchSongsdetails[a].likeId == 0) {
                    this.noLikeImage1[a] = false;
                } else {
                    this.noLikeImage1[a] = true;
                }
            }
            for (var a = 0; a < this.SearchSongsdetails.length; a++) {
                if (this.SearchSongsdetails[a].favId == 0) {
                    this.nofavimage1[a] = false;
                } else {
                    this.nofavimage1[a] = true;
                }
            }
        });
    }

    getpaginationdata(data) {
        if (data.count == 0) {
            this.noRecords = false;
            if (this.artistTabSelected) {
                this.noRecordData = "Artists";
            }
            else if (this.albumTabSelected) {
                this.noRecordData = "Album";
            }
            else if (this.songsTabSelected) {
                this.noRecordData = "Songs";
            }
            else {
                this.noRecordData = "Dance Mix";
            }
            this.Checkrecords = true;
            return;
        } else {
            this.noRecords = true;
            this.Checkrecords = false;
            this.paginationData = Number(data.count / this.pageSize);

            let tempPageData = this.paginationData.toFixed();
            if (Number(tempPageData) < this.paginationData) {
                this.exactPageList = Number(tempPageData) + 1;
            } else {
                this.exactPageList = Number(tempPageData);
                // this.activePage[0] = true;
            }

            if (this.temppage == 0 && this.showPageOnlyOntabsChange) {
                this.showPageOnlyOntabsChange = true;
                this.pageField = [];
                for (var a = 0; a < this.pageNumberShow; a++) {
                    this.pageField[a] = this.temppage + 1;
                    this.temppage = this.temppage + 1;
                    if (this.exactPageList == this.pageField[a]) {
                        for (var b = 0; b < this.pageNumberShow - 3; b++) {
                            if (a == b) {
                                this.temppage = this.temppage - (b + 1);
                                this.prevtrue = false;

                                break;
                            }
                        }
                        this.disabledNextBtn = true;

                        break;
                    } else {
                        this.disabledNextBtn = false;
                    }
                }
            }
        }

    }
    //Play Songs
    playSong(songs) {
        var getPlayList = this.playerService.getPlaylist();
        var index = getPlayList.findIndex(x => x.link == songs.streamUrl);

        this._playerService.msaapPlaylist = [];

        if (index == -1) {
            const tractObj = new Track();
            tractObj.image = this.path + songs.thumbImage;
            tractObj.artistName = songs.artistName;
            tractObj.link = songs.streamUrl;
            tractObj.title = songs.title;
            tractObj.index = 0;
            tractObj.songId = songs.songId;
            this._playerService.msaapPlaylist.unshift(tractObj);
            this.playerService.setPlaylist(this._playerService.msaapPlaylist);
            this.playerService.selectATrack(1);
        } else {
            const tractObj = new Track();
            tractObj.image = this.path + songs.thumbImage;
            tractObj.artistName = songs.artistName;
            tractObj.link = songs.streamUrl;
            tractObj.title = songs.title;
            tractObj.index = 0;
            tractObj.songId = songs.songId;
            this._playerService.msaapPlaylist.unshift(tractObj);
            this.playerService.setPlaylist(this._playerService.msaapPlaylist);
            this.playerService.selectATrack(index + 1);
        }
        // this.playerService.playPauseBtn = [];
        let songId = songs.songId;
        localStorage.setItem('CurrentSongId', songId);
        let indexForPlayPause = this.playerService.playPauseBtn.findIndex(x => x == true);
        if (indexForPlayPause != index) {
            // localStorage.setItem('IsPlaying', 'false');
            this.playerService.playPauseBtn = [];
            this.playerService.playPauseBtn[indexForPlayPause] = false;
            // localStorage.setItem('IsPlaying', 'false');
            // this.playerService.playPauseBtn[index] = !this.playerService.playPauseBtn[index];
        }
        // else {
        //     localStorage.setItem('IsPlaying', 'true');
        //     if (indexForPlayPause == -1) {
        //         indexForPlayPause = 0;
        //     }
        //     this.playerService.playPauseBtn[indexForPlayPause] = false;
        //     this.playerService.playPauseBtn[index] = true;
        // }

    }

    searchData(id) {
        this.activePage = [];
        this.pageField = [];
        this.pageIndex = 1;
        this.showNoOfCurrentPage = 1;
        this.artistTabSelected = false;
        this.albumTabSelected = false;
        this.songsTabSelected = false;
        this.djMixTabSelected = false;
        this.currentSearchId = id;
        if (id != 2) {
            this.selectedcategory = [];
            this.selectedcategoryname = [];
            this.categoryChecked = [];
        }

        if (id == 0) {
            //search for artist
            this.artistTabSelected = true;
            if (this.searchtext == undefined) {
                this.searchHistory = true;
            }
            else if (this.searchtext != '' || this.selectedLanguages.length != 0) {
                this.activePage[0] = true;
                this.temppage = 0;
                this.callArtistService(0);
                this.searchHistorykeyword = false;
            }
            else {
                //this.searchHistorykeyword=false;
                this.searchHistory = true;
                this.SearchSongsdetails = [];
                this.pageData = false;
                this.getSearchHistory();
            }
        }
        else if (id == 1) {
            this.albumTabSelected = true;
            if (this.searchtext == undefined) {
                this.searchHistory = true;
            }
            else if (this.searchtext != '' || this.selectedLanguages.length != 0) {
                this.activePage[0] = true;
                this.temppage = 0;
                this.callAlbumService(0);
                this.searchHistorykeyword = false;
            }
            else {
                this.searchHistory = true;
                this.SearchSongsdetails = [];
                this.pageData = false;
                this.getSearchHistory();
            }
        }
        else if (id == 2) {
            this.songsTabSelected = true;
            if (this.searchtext == undefined) {
                this.searchHistory = true;
            }
            else if (this.searchtext != '' || this.selectedLanguages.length != 0 || this.selectedcategory.length != 0) {
                this.activePage[0] = true;
                this.temppage = 0;
                this.callSongService(0);
                this.searchHistorykeyword = false;
            }
            else {
                this.searchHistory = true;
                this.SearchSongsdetails = [];
                this.pageData = false;
                this.getSearchHistory();

            }
        } else if (id == 3) {
            this.djMixTabSelected = true;
            if (this.searchtext == undefined) {
                this.searchHistory = true;
            }
            else if (this.searchtext != '' || this.selectedLanguages.length != 0) {
                this.activePage[0] = true;
                this.temppage = 0;
                this.callSongdjmixService(0);
                this.searchHistorykeyword = false;
                this.searchHistory = true;
            }
            else {
                this.SearchSongsdetails = [];
                this.pageData = false;
                this.getSearchHistory();

            }
        }

    }

    showSelectedSongs(pageNumber, i) {

        this.activePage = [];
        this.activePage[i] = true;
        this.showNextSongs(pageNumber, i);
    }

    showNextSongs(pageNumber, i) {
        this.pageIndex = pageNumber;
        if (this.artistTabSelected) {

            this.callArtistService(0);


        } else if (this.albumTabSelected) {

            this.callAlbumService(0);


        } else if (this.songsTabSelected) {

            this.callSongService(0);

        } else if (this.djMixTabSelected) {

            this.callSongdjmixService(0);

        } else if (this.songsNextPageSelected) {

            this.searchSongs(this.searchSong, i);
        }
    }
    pageOnLoad() {
        if (this.temppage == 0 && this.showPageOnlyOntabsChange) {
            this.showPageOnlyOntabsChange = true;
            this.pageField = [];
            for (var a = 0; a < this.pageNumberShow; a++) {
                this.pageField[a] = this.temppage + 1;
                this.temppage = this.temppage + 1;
                if (this.exactPageList == this.pageField[a]) {
                    for (var b = 0; b < this.pageNumberShow - 3; b++) {
                        if (a == b) {
                            this.temppage = this.temppage - (b + 1);
                            this.prevtrue = false;
                            break;
                        }
                    }
                    this.disabledNextBtn = true;
                    break;
                } else {
                    this.disabledNextBtn = false;
                }
            }
        }
    }

    previous() {
        if (this.showNoOfCurrentPage != 1) {
            this.pageNumber[0] = true;
            this.nexttrue = true;
            this.disabledNextBtn = false;
            this.showNoOfCurrentPage = this.showNoOfCurrentPage - 1;
            if (this.prevtrue) {
                if (this.lastPage == 0) {
                    this.temppage = this.temppage - 6;
                    this.prevtrue = false;
                } else {
                    this.temppage = this.lastPage;
                    this.prevtrue = false;
                    this.lastPage = 0;
                }
            }
            for (var a = this.pageNumberShow - 1; a >= 0; a--) {
                this.pageField[a] = this.temppage;
                this.temppage = this.temppage - 1;
            }

            if (this.temppage == 0) {
                this.showPageOnlyOntabsChange = false;
            }
            this.currentPage = this.pageField[0];
            this.activePage = [];
            this.activePage[0] = true;
            this.showNextSongs(this.currentPage, 1);
            if (this.temppage != 0) {
                this.nextPage();

            }

        }

    }

    nextPage() {
        if (this.disabledNextBtn == false) {
            this.disabledPrevBtn = false;
            this.pageField = [];
            this.prevtrue = true;
            this.showNoOfCurrentPage = this.showNoOfCurrentPage + 1;
            this.pageNumber[0] = true;
            // if (this.nexttrue) {
            //     this.temppage = this.temppage + 6;
            //     this.nexttrue = false;
            // }
            // if (this.prevtrue) {
            //     if (this.lastPage == 0) {
            //         this.temppage = this.temppage + 6;
            //         this.prevtrue = false;
            //     } else {
            //         this.temppage = this.lastPage;
            //         this.prevtrue = false;
            //         this.lastPage = 0;
            //     }
            // }
            for (var a = 0; a < this.pageNumberShow; a++) {
                this.pageField[a] = this.temppage + 1;
                this.temppage = this.temppage + 1;
                if (this.exactPageList == this.pageField[a]) {
                    this.lastPage = this.pageField[a];
                    this.lastPage = this.lastPage - (a + 1);
                    for (var b = 0; b < this.pageNumberShow - 3; b++) {
                        if (a == b) {
                            this.temppage = this.temppage - (b + 1);

                            this.prevtrue = false;
                            break;
                        }
                    }
                    this.disabledNextBtn = true;
                    break;
                } else {
                    this.disabledNextBtn = false;
                }
            }
            this.currentPage = this.pageField[0];
            this.activePage = [];
            this.activePage[0] = true;
            this.showNextSongs(this.currentPage, 1);
        }


    }

    Artistprofile(ArtistId: number) {
        localStorage.removeItem("InvoiceID");
        let val = ArtistId;
        localStorage.answer = JSON.stringify(val);
        let saved = JSON.parse(localStorage.answer);
        localStorage.setItem("InvoiceID", saved);
        this.router.navigate(['/artist/artistprofile'], { queryParams: { Id: ArtistId } });

    }

    Albumprofile(AlbumId: number) {
        localStorage.removeItem("InvoiceID");
        let val = AlbumId;
        localStorage.answer = JSON.stringify(val);
        let saved = JSON.parse(localStorage.answer);
        localStorage.setItem("InvoiceID", saved);
        this.router.navigate(['/album/album-details'], { queryParams: { Id: AlbumId } });
    }
    filersongs(data, songData, index) {

        if (data.recType == "S" || data.recType == "DJ") {
            let tempSongList = [];
            let j = 0;
            for (var i = 0; i < songData.length; i++) {
                if (songData[i].recType == "S" || songData[i].recType == "DJ") {
                    tempSongList[j] = songData[i];
                    if (index == i) {
                        index = j;
                    }
                    j++;
                }
            }
            this._playerService.playSong(tempSongList, this.path, index);
        } else {

        }

    }
    getSearchHistory() {
        this._homeService.getHistory(this.UserId).subscribe((data: any) => {

            if (data.isSuccess) {
                this.SearchSongsdetails = [];
                this.SearchSongsdetails = data.history;
                this.searchHistorykeyword = true;
                this.searchArtist = false;
                this.Checkrecords = false;
            }


        });
    }
    SearchtextfromHistory(text) {
        this.searchtext = text;
        this.searchForSongs = text;
        this.searchData(this.currentSearchId);
    }
    fetchSongId(i, j) {

        this.playlist.SongId = i;
        this.songslikeid = j;
    }
    IsCheckLang() {
        if (this.translate.currentLang == 'ar') {
            return true;

        } else if (this.translate.currentLang == 'as') {
            return true;
        }
        else {
            return false;
        }
    }
    updatecount(songId) {
        debugger;
        this._homeService.updatecount(songId).subscribe((res: any) => {
            this.result = res.data;
        })
    }
    Likesongs(songId: string, UserId: string, i) {
        this.noLikeImage1[i] = !this.noLikeImage1[i];
        this._homeService.LikeSong(songId, this.UserId).subscribe((res: any) => {
            this.result = res.data;
            this.noofLikes = this.result.noofLike;
        })
    }
    FavouriteSongs(songId: string, UserId: string, i) {
        this.nofavimage1[i] = !this.nofavimage1[i];
        // this.nofavImage = !this.nofavImage;
        this._homeService.FavouriteSongs(this.playlist.SongId, this.UserId).subscribe((res: any) => {
            this.result = res.data;
            this.refresh();
            this.refreshdjmix();
            this.getfavourites();
        })

    }
    LikeArtist(artistId: string, userId: string, i) {
        this.ArtistLikeImage[i] = !this.ArtistLikeImage[i];
        this._homeService.LikeArtist(artistId, this.UserId).subscribe((res: any) => {
            this.result = res.data;
        });
    }
    FavouriteArtists(artistId: string, userId: string, i) {

        this.Artistfavimage[i] = !this.Artistfavimage[i];
        this._homeService.FavouriteArtist(artistId, this.UserId).subscribe((res: any) => {
            this.result = res.data;
            this.refreshartist();
            this.getfavourites();
        })

    }
    refreshplaylist() {
        this._homeService.getPlaylist(this.userDetails.userId).subscribe((data: any) => {
            this.playListDetails = data.playListDetails;
            if (this.playListDetails.length >= 100) {
                for (var i = this.index; i < 100; i++) {
                    this.playd[i] = this.playListDetails[i];
                    this._homeService.playId[i] = this.playListDetails[i];
                }
            } else {
                for (var i = this.index; i < this.playListDetails.length; i++) {
                    this.playd[i] = this.playListDetails[i];
                    this._homeService.playId[i] = this.playListDetails[i];
                }
            }
        });
    }
    refresh() {

        this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
        this.UserId = this.userDetails.userId;
        this.IsMobileReq = this.userDetails.isMobileReq;
        let a = '';
        let b = 0;
        let c = 0;
        this._songsService.Getallfavsongs(this.UserId, 0, a, b, c).subscribe((data: any) => {
            //this.favsong=data.viewAllFavouriteSongs
            this._songsService.favsongs = data.viewAllFavouriteSongs;
            // this._songsService.getLatestPlaylist(data.viewAllFavouriteSongs);
        })
    }
    refreshalbum() {

        this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
        this.UserId = this.userDetails.userId;
        let a = '';
        let b = 0;
        let c = 0;
        this.albumService.Getallfavlbum(this.UserId, a, b, c).subscribe((data: any) => {
            this.albumService.playId = data.viewAllFavouriteAlbums;
            this.albumService.getLatestPlaylist(data.viewAllFavouriteAlbums)
        })
    }
    refreshartist() {

        this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
        this.UserId = this.userDetails.userId;
        this.IsMobileReq = this.userDetails.isMobileReq;
        let a = '';
        let b = 0;
        let c = 0;
        this.artistsService.Getallfavartist(this.UserId, a, b, c).subscribe((data: any) => {
            this.artistService.playId = data.viewAllFavouriteArtists;
            this.artistService.getLatestPlaylist(data.viewAllFavouriteArtists)
        })
    }
    refreshdjmix() {
        this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
        this.UserId = this.userDetails.userId;
        let a = '';
        let b = 0;
        let c = 0;
        this.songsService.Getallfavdjmix(this.UserId, 1, a, b, c).subscribe((data: any) => {
            this.songsService.playId = data.viewAllFavouriteSongs;
            this.userService.getLatestPlaylist(data.viewAllFavouriteSongs);
        })
    }

    getfavourites() {
        this._homeService.favouritesItems(this.UserId,this.IsMobileReq).subscribe((data: any) => {
            this.favitems = data;
            this.favalbums = this.favitems.favouriteDetails.favouriteAlbums;
            this.favdjmix = this.favitems.favouriteDetails.favouriteDjMix;
            this.favsongs = this.favitems.favouriteDetails.favouriteSong;
            this.favartists = this.favitems.favouriteDetails.favouriteArtists;
            this.playlistEntities = this.favitems.favouriteDetails.playlistEntities;
            if (this.playlistEntities.length <= 3) {
                // this.songsService.getplaylistEntities(this.playlistEntities)
                this.songsService.favplaylistentities = this.playlistEntities;
            }
            if (this.favalbums.length >= 6) {
                this.songsService.falbumss = this.favalbums;
                this.songsService.getfavalbum(this.favalbums);
            }
            else {
                this.songsService.falbumss = this.favalbums;
                this.songsService.getfavalbum(this.favalbums);
            }
            if (this.favdjmix.length >= 6) {
                this.songsService.favdjmix = this.favdjmix;
                this.songsService.getfavadjmix(this.favdjmix);
            }
            else {
                this.songsService.favdjmix = this.favdjmix;
                this.songsService.getfavadjmix(this.favdjmix);
            }

            if (this.favsongs.length >= 6) {
                this.songsService.favsongs = this.favsongs;
            }
            else {
                this.songsService.favsongs = this.favsongs;
            }

            if (this.favartists.length >= 6) {
                this.songsService.favartist = this.favartists;
            }
            else {
                this.songsService.favartist = this.favartists;
            }

        });

    }
    Refreshplaylistdetails() {
        this._homeService.playlist(this.playlistId, this.userDetails.userId).subscribe((Response: any) => {

            this._homeService.playListArray = Response.playlistDetails;
            //   for (var a = 0; a < Response.playlistDetails.length; a++) {
            //     this._homeService.playListArray.push(Response.playlistDetails[a]);
            //   }
        })
    }

    onSubmit() {
        this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
        this.playlist.UserId = this.userDetails.userId;
        this.playlistId = this.playlist.PlaylistId;
        this._homeService.addOrUpdateplaylist(this.playlist).subscribe((res: any) => {
            if (res.status == 200) {
                if (this.playlist.PlaylistId != 0) {
                    this.toastr.success("Playlist added successfully");
                    this.freshplaylist();
                    this.form.resetForm();
                    this.form.reset();
                    this.refreshplaylist()
                    this.Refreshplaylistdetails();
                    this.createchecked = true;
                    this.updateextchecked = false
                    this.getfavourites();
                } else {

                    this.toastr.success("Playlist updated successfully");
                    this.freshplaylist();
                    this.form.resetForm();
                    this.form.reset();
                    this.refreshplaylist()
                    this.Refreshplaylistdetails();
                    this.createchecked = true;
                    this.updateextchecked = false
                    this.getfavourites();
                }
            }
            else {
                this.toastr.success("Playlist Already Exists");
                this.form.reset();
            }
        })
        this.closeBtn.nativeElement.click();
        // $(document).ready(function() {           
        //       $("#myModalsong").on('hide.bs.modal', function() { 
        //         alert('The modal is about to be hidden.'); 
        //     }); 
        // });
        //document.getElementById("myModalsong").hidden = false;
        // document.getElementById("myModalsong").style.visibility = "hidden";
        //document.getElementById("myModalsong").style.display = "none";
        //    var elem = document.querySelector('#myModalsong');
        //    elem.parentNode.removeChild(elem)
        //    this.closeModalDialog();
    }

    checked() {
        this.createchecked = true;
        this.updateextchecked = false;
        //this.playlist.Name = '';
        this.freshplaylist();
    }

    updatechecked() {
        this.updateextchecked = true;
        this.createchecked = false;
        this.playlist.PlaylistId = undefined;
        this.freshplaylist();
    }
    freshplaylist() {
        this._homeService.getPlaylist(this.UserId).subscribe((data: any) => {
            this.playListDetails = data.playListDetails;
            this._homeService.getLatestPlaylist(this.playListDetails);
        })
    }
    LikeAlbums(albumId: string, UserId: string, i) {
        this.AlbumLikeImage[i] = !this.AlbumLikeImage[i];
        this._homeService.LikeAlbums(albumId, this.UserId).subscribe((res: any) => {
            this.result = res.data;
        })
    }
    FavouriteAlbums(albumId: string, UserId: string, i) {
        this.Albumfavimage[i] = !this.Albumfavimage[i];
        this._homeService.FavouriteAlbums(albumId, this.UserId).subscribe((res: any) => {
            this.refreshalbum();
            this.getfavourites();

        })

    }
    deletehistory(searchId) {
        this._homeService.deletehistory(searchId, this.UserId).subscribe((data: any) => {

            if (data.isSuccess) {
                this.SearchSongsdetails = [];
                this.SearchSongsdetails = data.history;
                this.searchHistorykeyword = true;
                this.Checkrecords = false;
            }
        });
    }
    showSlider(playlist, index) {
        //this.router.navigate(['/album/songsdetails'], { queryParams: { Id: index } });
        this._homeService.playerSong.next(index);
    }

    CheckSubscription(song) {
        this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
        this.substatus = this.userDetails.subscriptionStatus;
        if (this.substatus == "False") {
            // this.router.navigate(['/account/upgradeplan']);
            document.getElementById("SubcheckForm").style.display = "block";
        }
        else {
            this.songIsthere = true;
            this.playSong(song);
            this.showSlider(this.playerService.playlist, this.playerService.indexSong)
        }
    }

    closeSubcheckForm() {
        document.getElementById("SubcheckForm").style.display = "none";
        this.router.navigate(['/account/upgradeplan']);
    }

    openForm() {
        document.getElementById("myForm").style.display = "block";
    }
    closeForm() {
        document.getElementById("myForm").style.display = "none";
    }

    Checked(value) {
        this.isChecked = value;
    }
    getsongLanguage() {
        this.artistService.GetsongLanguage().subscribe((data: any) => {
            this.songlangugae = data.languages;
        });
    }

    getallCategories() {
        this._songsService.GetallCategories().subscribe((data: any) => {
            this.categories = data.categories;
        });
    }

    checklanguage($event, Code, name, i) {
        this.langChecked[i] = $event;
        if ($event) {
            this.selectedLanguages.push(Code);
            this.selectlangname.push({ name, i });
            if (this.selectlangname == null || this.selectlangname == []) {
                this.showfilter = false;
            }
            else {
                this.showfilter = true;
            }

        } else {
            this.selectedLanguages = this.selectedLanguages.filter(obj => obj !== Code);
            this.selectlangname = this.selectlangname.filter(obj => obj.name !== name);
            this.langChecked[i] = $event;
            let test = this.langChecked.findIndex(x => x == true)
            if (test == -1) {
                this.langChecked = [];
                this.selectedLanguages = [];
                this.selectlangname = [];
                this.searchHistory = true;
                this.showfilter = false;
                this.searchHistory = true;
            }
        }
        this.searchSongs(this.searchtext, 0);
    }
    checkcategory($event, id, name, i) {
        this.categoryChecked[i] = $event;
        if ($event) {
            this.selectedcategory.push(id);
            this.selectedcategoryname.push({ name, i });

            if (this.selectedcategoryname == null || this.selectedcategoryname == []) {
                this.showcatfilter = false;
            }
            else {
                this.showcatfilter = true;
            }

        } else {
            this.selectedcategory = this.selectedcategory.filter(obj => obj !== id);
            this.selectedcategoryname = this.selectedcategoryname.filter(obj => obj.name !== name);
            this.categoryChecked[i] = false;
            let test = this.categoryChecked.findIndex(x => x == true)

            if (test == -1) {
                this.categoryChecked = [];
                this.selectedcategory = [];
                this.selectedcategoryname = [];
                this.showcatfilter = false;
                this.searchHistory = true;
            }
        }
        this.searchSongs(this.searchtext, 0);
    }
    resetlanguage() {
        this.langChecked[0] = false;
        this.selectedLanguages = [];
        this.selectlangname = [];
        this.selectedcategoryname = [];
        this.selectedcategory = [];
        this.langChecked = [];
        this.searchSongs(this.searchSong, 0)
        // this.SearchSongsdetails=[];
        // this.callArtistService(0);
        // this.callAlbumService(0);
        // this.callArtistService(0)
        // this.callSongService(0);
    }
    deleteMsg(msg: string, index) {
        if (index !== -1) {
            let LangIndex = this.selectlangname.findIndex(obj => obj.name == msg);
            this.selectlangname = this.selectlangname.filter(obj => obj.name != msg);
            let Code = this.selectedLanguages[LangIndex];
            this.selectedLanguages = this.selectedLanguages.filter(obj => obj !== Code);
            this.langChecked[index] = false;

            let test = this.langChecked.findIndex(x => x == true)

            if (test == -1) {
                this.langChecked = [];
                this.selectedLanguages = [];
                this.selectlangname = [];
                this.searchHistory = true;
                this.showfilter = false;
                this.searchHistory = true;
            }
            this.searchSongs(this.searchtext, 0);
        }
    }
    deletecategory(msg: string, index) {
        if (index !== -1) {
            this.selectedcategoryname = this.selectedcategoryname.filter(obj => obj.name != msg);
            this.selectedcategory = this.selectedcategory.filter(obj => obj != index + 1);

            this.categoryChecked[index] = false;
            let test = this.categoryChecked.findIndex(x => x == true)

            if (test == -1) {
                this.categoryChecked = [];
                this.selectedcategory = [];
                this.selectedcategoryname = [];
                this.showcatfilter = false;
                this.searchHistory = true;
            }
            this.searchSongs(this.searchtext, 0);
        }
    }

}