import { Component, OnInit } from '@angular/core';
import { LoadScriptService } from '../../loadScript.service';
import { Users } from '../../models/users';
import { HomeService } from '../../Shared/home.service';
import { Router } from '@angular/router';
import { LoginService } from '../../Shared/login.service';
import { LanguageService } from '../../Shared/language.service';
import { Language } from '../../models/language';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/Shared/user.service';
@Component({
  selector: 'app-change-language',
  templateUrl: './change-language.component.html',
  styleUrls: ['./change-language.component.css']
})
export class ChangeLanguageComponent implements OnInit {
  languages: any;
  languageId;
  message: string;
  languageCode: any;
  UserId: any;
  theme: any;
  constructor(private _router: Router, private loadScript: LoadScriptService,
    private _homeService: HomeService,
    private _loginService: LoginService,
    private toastr: ToastrService,
    private _languageService: LanguageService,
    public userService: UserService) { }
  userDetails = new Users();
  languageDetails = new Language();
  ngOnInit() {
    //Service to load js file runtime
    this.loadScript.getServiceBaseUrl();
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.UserId = this.userDetails.userId;
    this.getLanguages(this.UserId);
    this.theme = this.userDetails.themeId

  }
  getLanguages(UserId) {
    this._languageService.getLanguages(UserId).subscribe((data: any) => {
      this.languages = data;
    });
  }
  onLangChange(data) {
    this.languageDetails = data;
  }
  onSubmit() {

    this._languageService.updateLanguage(this.userDetails.userId, this.languageDetails.languageCode).subscribe((res: any) => {
    })
    var languageIdFromLocalStorage = JSON.parse(localStorage.getItem('UserDetails'));

    // Change value
    languageIdFromLocalStorage.languageCode = this.languageDetails.languageCode;
    localStorage.setItem("UserDetails", JSON.stringify(languageIdFromLocalStorage));
    this.message = 'Language Changed Successfully';
    this.successmsg(this.message);
    this._router.navigate([`/home`]);

  }

  onDismiss() {
    this._router.navigate([`/home`]);
  }

  successmsg(msg: string) {
    this.toastr.success(msg)
  }
  successmsg1() {
    this.toastr.success("Toastr Success message", 'Success')
  }


}
