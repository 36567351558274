export class Song {
    songId: any;
    artistId: any;
    albumId: any;
    artistName: any;
    albumName: any;
    categoryName: any;
    composerName: any;
    writerName: any;
    title: any;
    length: any;
    NoofLikes: any = 0;
    NoofFavs: any = 0;
    lyrics: any;
    categoryId: number;
    songLanguageCode: any;
    thumbImage: any;
    largeImage: any;
    createdBy: any;
    updatedBy: any;
    streamUrl: any;
    favId: any;
    likeId: any;
    statusCode: any;
    statusId: any;
    releasedOn: any;
    thumbImageNew: any;
    largeImageNew: any;
    ////==========base64formatedata Varibles=========////
    base64Data: any;
    fileName: any;
    fileType: any;
    base64DataForSong: any;
    songName: any;
    songType: any;
    isComingUp: any;
    languageId: any;
    languageCode: any;
    themeLanguageCode: any;
    isforKid: any;
    base64DataForNewRelease: any;
    fileNameNew: any;
    fileTypeNew: any;
    playCounts: any = 0;
    lengthString: any;
}

export class FeaturedSong {
    FeaturedSongsId: any;
    SongId: any;
    IsFeaturedSong: boolean;
    createdBy: any;
    updatedBy: any;
}

export class RecommendSong {
    RecommendedSongsId: any;
    SongId: any;
    IsRecommendedSong: boolean;
    createdBy: any;
    updatedBy: any;
}

export class localSong {
   public songId: number;
   public title: any;
}