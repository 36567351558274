import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Miniapproval } from 'src/app/models/miniapproval';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SongsService } from 'src/app/Shared/songs.service';
import { Users } from 'src/app/models/users';
import { PlayerService } from 'src/app/Shared/player.service';
import { ApproverejectService } from 'src/app/Shared/approvereject.service';
import { ToastrService } from 'ngx-toastr';
import { Approvalstatus } from 'src/app/models/approvalstatus';
import { CommonModule } from "@angular/common"
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { Track } from 'Player/ngx-audio-player/src/lib/model/track.model';
import { trimTrailingNulls } from '@angular/compiler/src/render3/view/util';
import { Location } from '@angular/common';
import { LoadScriptService } from 'src/app/loadScript.service';
@Component({
  selector: 'app-viewsong',
  templateUrl: './viewsong.component.html',
  styleUrls: ['./viewsong.component.css']
})
export class ViewsongComponent implements OnInit {

  constructor(private loadScript: LoadScriptService, private _playerService: PlayerService, private router: Router, private route: ActivatedRoute, private _songsService: SongsService, private approvereject: ApproverejectService, private toastr: ToastrService,
    private playerService: AudioPlayerService, public location: Location, public playlistService: AudioPlayerService) {

  }
  songinfo: boolean = false;
  allsongs;
  album;
  albumby;
  noofSongs;
  tm;
  releasedOn;
  TransList: Array<any> = [];
  approvalstatus_rejected: any;
  approvalstatus_approved: any;
  approvalstatus_resubmit: any;
  approverejectsong = new Miniapproval();
  userDetails = new Users();
  path;
  title;
  thumbImage;
  artistName;
  length;
  statusCode;
  commnets;
  largeImage;
  statusId;
  isPlaying: boolean = false;
  tempIndex: number = 0;
  @ViewChild('audioPlayer') player: ElementRef;
  loaderDisplay: boolean = false;
  currentTime: number = 0;
  songIsthere: boolean = false;

  //approverejectalbum = new Miniapproval();
  ngOnInit() {
    this.loadScript.getServiceBaseUrl();
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.approverejectsong.songId = this.route.snapshot.paramMap.get("id");
    this.getsongdetails(this.approverejectsong.songId);
    this.getcomments(this.approverejectsong.songId);
    this.approvalstatus_rejected = Approvalstatus.REJECTED;
    this.approvalstatus_approved = Approvalstatus.APPROVED;
    this.approvalstatus_resubmit = Approvalstatus.RESUBMIT;
    if (this.userDetails["artistId"] != undefined) {
      document.getElementById("resubmit").style.display = "block";
      document.getElementById("approve").style.display = "none";
      document.getElementById("reject").style.display = "none";
      this.approverejectsong.createdBy = this.userDetails["artistId"];
      this.approverejectsong.userType = "Artist";

    }
    else {
      this.approverejectsong.createdBy = this.userDetails["userId"];
      this.approverejectsong.userType = "Admin";
    }

    if (this.statusId == Approvalstatus.APPROVED) {
      document.getElementById("resubmit").style.display = "none";
    }


  }


  //Play Songs
  playSong(songs) {
    debugger;
    var getPlayList = this.playerService.getPlaylist();
    this._playerService.playForArtist = true;
    var index = getPlayList.findIndex(x => x.link == songs.streamUrl);
    this._playerService.msaapPlaylist = [];
    if (index == -1) {
      const tractObj = new Track();
      tractObj.image = this.path + songs.thumbImage;
      tractObj.artistName = songs.artistName;
      tractObj.link = songs.streamUrl;
      tractObj.title = songs.title;
      this._playerService.msaapPlaylist.unshift(tractObj);
      this.playerService.setPlaylist(this._playerService.msaapPlaylist);
      this.playerService.selectATrack(1);
    }
    else {
      this.playerService.selectATrack(1);
    }
    this._playerService.updateData(songs);
    this.isPlaying = !this.isPlaying;
  }

  playPause() {
    if (this.playlistService.playlist.length != 1) {
      let currentIndex = this.playlistService.playlist.findIndex(x => x.songId == localStorage.getItem('CurrentSongId'))
      if (currentIndex != -1) {
        if (this.playlistService.playPauseBtn[this.playlistService.indexSong]) {
          this.playlistService.playPauseBtn[this.playlistService.indexSong] = false;
          localStorage.setItem('IsSongPlaying', 'false');
          localStorage.setItem('IsPlaying', 'false');
        } else {
          this.playlistService.playPauseBtn[this.playlistService.indexSong] = true;
          localStorage.setItem('IsSongPlaying', 'true');
          localStorage.setItem('IsPlaying', 'false');
        }
      }
    } else {
      let currentIndex = this.playlistService.playPauseBtn.findIndex(x => x == true);

      if (this.playlistService.playPauseBtn[currentIndex]) {
        this.playlistService.playPauseBtn[currentIndex] = false;
        this.tempIndex = currentIndex;
        localStorage.setItem('IsSongPlaying', 'false');
        localStorage.setItem('IsPlaying', 'false');
      } else {
        this.playlistService.playPauseBtn[this.tempIndex] = true;
        localStorage.setItem('IsSongPlaying', 'true');
        localStorage.setItem('IsPlaying', 'false');
      }

      let Cursong = localStorage.getItem('IsSongPlaying');
      if (Cursong == 'true') {
        localStorage.setItem('IsSongPlaying', 'false');
      } else {
        localStorage.setItem('IsSongPlaying', 'true');
      }
      let CurPlayingsong = localStorage.getItem('IsPlaying');
      if (CurPlayingsong == 'true') {
        localStorage.setItem('IsPlaying', 'false');
      } else {
        localStorage.setItem('IsPlaying', 'true');
      }
      //this.playlistService.playPauseBtn = [];
    }
  }

  playBtnHandler(): void {
    if (this.loaderDisplay) {
      return;
    }
    if (this.player.nativeElement.paused) {
      this.player.nativeElement.play(this.currentTime);
    } else {
      this.currentTime = this.player.nativeElement.currentTime;
      this.player.nativeElement.pause();
    }
  };


  getsongdetails(SongId) {
    this._songsService.getSongDetails(SongId).subscribe((Response: any) => {
      this.TransList.push(Response);
      this.path = Response.path;
      this.album = Response.songs.albumName;
      this.albumby = Response.songs.artistName;
      this.title = Response.songs.title;
      this.length = Response.songs.length;
      this.releasedOn = Response.songs.releasedOn;
      this.allsongs = Response.songs;
      this.thumbImage = Response.songs.thumbImage;
      this.largeImage = Response.songs.largeImage;
      this.statusCode = Response.songs.statusCode;
      this.statusId = Response.songs.statusId;
      //this.albumId=Response.songs.albumId;
      this.approverejectsong.albumId = Response.songs.albumId;

      if (this.statusId == Approvalstatus.APPROVED) {
        document.getElementById("resubmit").style.display = "none";
        document.getElementById("approve").style.display = "none";
        //document.getElementById("reject").style.display="none";
      }
      if (this.statusId == Approvalstatus.REJECTED || this.statusId == Approvalstatus.DELETED) {
        document.getElementById("reject").style.display = "none";
      }
      if (this.statusId == Approvalstatus.DELETED) {
        document.getElementById("approve").style.display = "none";
        document.getElementById("comment").style.display = "none";
        document.getElementById("reject").style.display = "none";
      }

    });
  }

  UpdateApproval(statusId) {
    this.approverejectsong.statusId = statusId;

    if (this.approverejectsong.comments == undefined || this.approverejectsong.comments.trim() == "") {
      this.songinfo = true;
    } else {
      this.approverejectalbum();
    }

  }
  comment($event) {
    if ($event == "")
      this.songinfo = true;
    else
      this.songinfo = false;
  }

  approverejectalbum() {
    //this.approverejectsong.statusCode=statuscode;
    this.approverejectsong.createdBy = this.userDetails["userId"];
    this.approvereject.approveRejectSong(this.approverejectsong).subscribe((res: any) => {
      if (res.isSuccess) {

        this.toastr.success("Record is updated successfully");
      } else {

        this.toastr.success("Record is not updated");
      }
      //  this.router.navigate(['/admin/songs']);
      this.location.back();
      // this.location.back();

    });

  }
  getcomments(SongId) {
    this.approvereject.getcommentsofSong(SongId).subscribe((res: any) => {
      this.commnets = res.comments;


    });
  }
}

