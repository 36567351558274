import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { Users } from '../models/users';
import { Router } from '@angular/router';
import { HomeService } from '../Shared/home.service';
import { LoadScriptService } from '../loadScript.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SongsService } from '../Shared/songs.service';
import { PlayerService } from '../Shared/player.service';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { Playlist } from '../models/playlist';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { Track } from 'Player/ngx-audio-player/src/public_api';

@Component({
  selector: 'app-recommended-songs',
  templateUrl: './recommended-songs.component.html',
  styleUrls: ['./recommended-songs.component.css']
})
export class RecommendedSongsComponent implements OnInit {

  userDetails = new Users();
  playlist = new Playlist();
  UserId: any;
  userFullname: any;
  homeDetails = [];
  recommendedSongs = [];
  path;
  totalcount = 0;
  recomdSongs: any;
  userImage: any;
  recmdSngImg: any;
  IsActiveSongId: any;
  isSongplay: boolean = false;
  createchecked: boolean = true;
  updateextchecked: boolean;
  playListDetails: any;
  songId: any;
  favSong: boolean[] = [];
  likeSong: boolean[] = [];
  songid: any;
  favId: any;
  likeId: any;
  public songsLists: Track = new Track();

  currentTime: number = 0;
  @ViewChild('audioPlayer') player: ElementRef;
  ShowPlayer: boolean = true;
  loaderDisplay: boolean = false;

  @Output() play = new EventEmitter<any>();
  @ViewChild('closeBtn') closeBtn: ElementRef;
  marginRightMusicContainer: string;
  fontSizeTitleText: string;
  marginLeftMain: string;
  marginLeftMusicContainer: string;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateScreenCss(event.target.innerWidth);
  }
  isPlaying: boolean;
  duration: number;

  constructor(
    private _homeService: HomeService,
    private router: Router,
    private _playerService: PlayerService,
    public playerService: AudioPlayerService,
    private loadScript: LoadScriptService,
    private spinner: NgxSpinnerService,
    private _songservice: SongsService,
    private toastr: ToastrService
  ) {
    this.updateScreenCss(window.innerWidth);
  }

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.UserId = this.userDetails["userId"];
    this.userFullname = this.userDetails["fullName"];
    this.userImage = this.userDetails["image"];
    console.log(this.userDetails, this.UserId)
    if(!this.userImage){
      this.userImage="../../../assets/images/NoUserFound.png";
    }
    //this.isSongplay = localStorage.getItem('IsSongPlaying');
    //this.getHomeDetails(this.userDetails.userId);
    this.getrecommendedSongs();
    window.scrollTo(0, 0);
    this.playerService.getIsblogorCountryScreen().subscribe(value => {
      this.IsActiveSongId = value;
      //this.getrecommendedSongs();
    });
    if (this.playListDetails == undefined) {
      this.freshplaylist();
    }
    this.playerService.registerPlaySongCallbackno((song) => {
      if (song == true) {
        this.isSongplay = true;
      }
      else {
        this.isSongplay = false;
      }
    });
    // this.playerService.registerPlaySongCallback((play) => {
    //   if(play == true){
    //     this.isSongplay = play;
    //   }
    //   else{
    //     this.isSongplay = play;
    //   }
    // });
  }

  updateScreenCss(width: number) {
    const mainElement = document.querySelector('.main') as HTMLElement;
    const musicContainerElement = document.querySelector('.music-container') as HTMLElement;

    // Calculate margin-right dynamically for screen widths between 2049 and 5464
    if (musicContainerElement) {
      if (width >= 2049 && width <= 6144) {
        // Calculate the percentage value for margin-right
        const marginRightPercentage = ((width - 2049) / (6144 - 2049)) * 69; // Adjust 69 as needed
        musicContainerElement.style.marginRight = marginRightPercentage + '%';
      } else {
        musicContainerElement.style.marginRight = ''; // Reset margin-right for other screen widths
      }
      this.marginRightMusicContainer = musicContainerElement.style.marginRight;
    }
  }

  getHomeDetails(userId) {
    this.spinner.show();
    debugger;
    console.log();
    this._homeService.getHomeDetails(userId).subscribe((data: any) => {
      this.homeDetails = data;
      console.log('this.homeDetails', this.homeDetails);
      this.path = data.path;
      this.recommendedSongs = data.homePageDetails.recommendedSongs;
      this.totalcount = this.recommendedSongs.length;
      this.spinner.hide();
    });


  }
  getrecommendedSongs() {
    this._songservice.GetRecommendedSongs(this.UserId).subscribe((data: any) => {
      console.log(data);
      this.recomdSongs = data.recmdSongs;
      this.totalcount = this.recomdSongs.length;
      this.recmdSngImg = this.recomdSongs[0].thumbImage;
      // this.path = data.feaSongs.path;

    });
  }

  private areArraysSameDeep(a: any[], b: any[]): boolean {
    return a.length === b.length && a.every((val, index) => val === b[index]);
  }

  playAllsongs(items, play) {
    if (play == 'play') {
      if (!this.areArraysSameDeep(this._playerService.msaapPlaylist, items)) {
        this._playerService.msaapPlaylist = [];
        this.CheckSubscription(items, 0);
        this.isSongplay = true;
      }
      else if (this._playerService.msaapPlaylist == items) {
        this.playerService.playSongCallback(true);
      }
      else {
        this.playerService.playSongCallback(true);
      }
    }
    else {
      this.playerService.currentTime = this.player.nativeElement.currentTime;
      console.log('onpause', this._playerService.msaapPlaylist, items.length, this.currentTime);
      this.isSongplay = false;
      // this.player.nativeElement.paused = false;
      //this.playBtnHandler();
      this.playerService.playSongCallback(false);

    }

  }
  CheckSubscription(song, i) {
    debugger;
    if (song.length > 0) {
      this.playSong(song, i);
    }
    else {
      this.playSong(song, i);

    }
    //this.playerService.playPause();
    // if (this.substatus == "False") {

    //     document.getElementById("Subscriptioncheck").style.display = "block";
    //     //this.router.navigate(['/account/upgradeplan']);
    // }
    // else {
    //     this.songIsthere = true;

    // }
  }

  playSong(songs, index) {
    // this._playerService.playSongs(songs);
    // if (this.playerService.repeatSong) {
    //   this.playerService.repeatSong = false;
    // }
    // this.playerService.setPlaylist(this._playerService.msaapPlaylist);
    // this.playerService.selectATrack(index + 1);
    // this._playerService.updateData(this._playerService.msaapPlaylist);
    // //this.playerService.Issongplay(true);
    // console.log('Emitting playSong event for:');
    // //this.playerService.playSong(true);
    // this.playerService.playSongCallback(true);
    // // this.player.nativeElement.paused = true;
    // // this.playBtnHandler();

    this._playerService.msaapPlaylist = [];
    songs.forEach((song, i) => {
      const trackObj = new Track();
      trackObj.index = i;
      trackObj.image = song.thumbImage;
      trackObj.artistName = song.artistName;
      trackObj.link = song.streamUrl;
      trackObj.title = song.title;
      trackObj.songId = song.songId;
      this._playerService.msaapPlaylist.push(trackObj);
    });
    this.playerService.playSongCallback(true);

    if (this.playerService.repeatSong) {
      this.playerService.repeatSong = false;
    }
    this.playerService.setPlaylist(this._playerService.msaapPlaylist);
    this.playerService.selectATrack(index + 1);
    this._playerService.updateData(this._playerService.msaapPlaylist);


  }
  playBtnHandler(): void {
    if (this.loaderDisplay) {
      return;
    }
    if (this.player.nativeElement.paused) {
      // this.player.nativeElement.play(this.currentTime);
      (document.getElementById("audioPlayer") as HTMLMediaElement).play();
    } else {
      this.currentTime = this.player.nativeElement.currentTime;
      // this.player.nativeElement.pause();
      (document.getElementById("audioPlayer") as HTMLMediaElement).pause();
    }
  };

  getPlayListDetails(songDetails) {
    debugger
    console.log(songDetails);
    this.songId = songDetails.songId;
  }
  freshplaylist() {
    this._homeService.getPlaylist(this.userDetails.userId).subscribe((data: any) => {
      this.playListDetails = data.playListDetails;
      this._homeService.getLatestPlaylist(this.playListDetails);
    });
  }



}
