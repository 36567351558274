import { Component, OnInit } from '@angular/core';
import { LoadScriptService } from 'src/app/loadScript.service';
import { HomeService } from '../Shared/home.service'
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { PlayerService } from 'src/app/Shared/player.service';
import { UserService } from 'src/app/Shared/user.service';
import { Playlist } from '../models/playlist';
import { Location } from '@angular/common';
@Component({
  selector: 'app-playlistdetails',
  templateUrl: './playlistdetails.component.html',
  styleUrls: ['./playlistdetails.component.css']
})
export class PlaylistdetailsComponent implements OnInit {
  userDetails: any;
  playlist = new Playlist();
  substatus: any;
  userId: any;
  allsongs: any;
  TransList: Array<any> = [];
  path: any;
  paramId: any;
  title: any;
  playlistname: any;
  likeId: any;
  favId: any;
  likedimage: boolean;
  nofavImage: boolean;
  songId: any;
  result: any;
  noofLikes: any;
  index = 0;
  songsforid = [];
  noLikeImage1: boolean[] = [];
  nofavimage1: boolean[] = [];
  songIsthere: boolean = false;
  theme: any;
  constructor(
    private loadScript: LoadScriptService,
    public homeService: HomeService,
    private route: ActivatedRoute,
    private playerService: AudioPlayerService,
    private _playerService: PlayerService,
    private toastr: ToastrService,
    private router: Router,
    public location: Location,
    public userService: UserService
  ) {
  }

  ngOnInit() {

    this.loadScript.getServiceBaseUrl();
    this.paramId = this.route.snapshot.queryParams["Id"];
    this.playlistname = this.route.snapshot.queryParams["playListName"];
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.substatus = this.userDetails.subscriptionStatus;
    this.userId = this.userDetails.userId;
    this.theme = this.userDetails.themeId;

    if (this.homeService.playListArray.length == 0) {
      this.title = JSON.parse(localStorage.getItem("service"));
      this.allsong(this.paramId);
    } else {
      this.title = this.homeService.playlistName;
      this.allsongs = this.homeService.playListArray;
    }
    this.title = JSON.parse(localStorage.getItem("service"));
    this.allsong(this.paramId);
  }
  allsong(playlistId: string) {
    this.homeService.adminPlaylist(this.paramId, this.userId).subscribe(Response => {
      this.TransList.push(Response);
      this.path = this.TransList[0].path;
      this.homeService.playListArray = this.TransList[0].playlistDetails;

      for (var a = 0; a < this.homeService.playListArray.length; a++) {
        if (this.homeService.playListArray[a].likeId == 0) {
          this.noLikeImage1[a] = false;
        } else {
          this.noLikeImage1[a] = true;
        }
      }
      for (var a = 0; a < this.homeService.playListArray.length; a++) {
        if (this.homeService.playListArray[a].favId == 0) {
          this.nofavimage1[a] = false;
        } else {
          this.nofavimage1[a] = true;
        }
      }
      if (this.likeId == 0) {
        this.likedimage = true;
      }
      else {
        this.likedimage = false
      }
      this.favId = this.TransList[0].playlistDetails[0].favId;
      let currentSongId = localStorage.getItem('CurrentSongId');
      let getSong = this.homeService.playListArray.findIndex(x => x.songId == currentSongId)
      if (getSong != -1) {
        let isSongPlaying = localStorage.getItem('IsPlaying');
        if (isSongPlaying == 'true' || isSongPlaying == null) {
          this.playerService.playPauseBtn[this.playerService.indexSong] = true;
        } else if (isSongPlaying == 'false') {
          this.playerService.playPauseBtn[this.playerService.indexSong] = false;
        }

      } else {
        this.playerService.playPauseBtn[this.playerService.indexSong] = false;
      }

      if (this.favId == 0) {
        this.nofavImage = true;
      } else {
        this.nofavImage = false;
      }
    })
  }
  Likesongs(songId: string, UserId: string, i) {
    this.noLikeImage1[i] = !this.noLikeImage1[i];
    this.homeService.LikeSong(songId, this.userId).subscribe((res: any) => {
    }
    )
  }
  updatecount(songId) {
    debugger;
    this.homeService.updatecount(songId).subscribe((res: any) => {
    })
  }
  FavouriteSongs(songId: string, UserId: string, i) {
    this.nofavimage1[i] = !this.nofavimage1[i];
    this.homeService.FavouriteSongs(this.playlist.SongId, this.userId).subscribe((res: any) => {
      this.result = res.data;
    })
  }
  fetchSongId(i, j) {
    this.playlist.SongId = i;
  }

  CheckSubscription(song, i) {
    if (this.substatus == "False") {
      document.getElementById("Subscriptioncheck").style.display = "block";
    }
    else {
      this.songIsthere = true;
      this.playSong(song, i);
    }

  }
  back() {
    this.location.back();
  }
  closeForm() {
    document.getElementById("Subscriptioncheck").style.display = "none";
    this.router.navigate(['/account/upgradeplan']);
  }

  playSong(songs, index) {
    let songId = songs[index].songId;
    localStorage.setItem('CurrentSongId', songId);
    let indexForPlayPause = this.playerService.playPauseBtn.findIndex(x => x == true);
    if (indexForPlayPause == index) {
      localStorage.setItem('IsPlaying', 'false');
      this.playerService.playPauseBtn[index] = !this.playerService.playPauseBtn[index];
    }
    else {
      localStorage.setItem('IsPlaying', 'true');
      if (indexForPlayPause == -1) {
        indexForPlayPause = 0;
      }
      this.playerService.playPauseBtn[indexForPlayPause] = false;
      this.playerService.playPauseBtn[index] = true;
    }
    this._playerService.playSong(songs, this.path, index);
  }

}
