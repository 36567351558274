import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { log } from 'console';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubscriptionService } from 'src/app/Shared/subscription.service';
import { Users } from 'src/app/models/users';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subscriptions } from 'src/app/models/subscriptions';

@Component({
  selector: 'app-upgrade-subscription',
  templateUrl: './upgrade-subscription.component.html',
  styleUrls: ['./upgrade-subscription.component.css']
})
export class UpgradeSubscriptionComponent implements OnInit {
  chosenPlan: boolean = false;
  userDetails = new Users();
  Userlist: any;
  userId: number;
  currentplan: any;
  subscriptionId: number;
  cancelmsg: any;
  planName: any;
  subscriptionPlans: any[];
  showPlanTobyFamily: boolean = false;
  showPlanTobyPremium: boolean = false;
  showPlanTobyDuo: boolean = false;
  showChoosePlanBtn: boolean = false;
  notFreeTrial: boolean = false;
  planButton: boolean = false;
  CurrentStatus: any;
  shuffledPlans: any[];
  isCurrentPlan: boolean = false;
  selectedPlan=new Subscriptions();

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    console.log(event.target.innerWidth);
  }

  constructor(private _router: Router, private subscriptionService: SubscriptionService,
    private spinner: NgxSpinnerService, private sanitizer: DomSanitizer) {
    //this.UserSubscriptiobHistory();
  }

  ngOnInit() {
    this.spinner.show();
    window.scrollTo(0, 0);
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.getSubscriptionPlans();
    this.UserSubscriptiobHistory();

  }

  shufflePlans() {
    debugger;
    const order = ['Toby Premium', 'Toby Duo', 'Toby Family']; // Desired order of plan names
    this.shuffledPlans = order.map(name => this.subscriptionPlans.find(plan => plan.planName === name));
  }

  getSubscriptionPlans() {
    this.spinner.show();
    this.subscriptionService.getSubscriptionplan().subscribe((subscriptions: any) => {
      this.subscriptionPlans = subscriptions.subscriptionDetails;
      this.spinner.hide();
      this.shufflePlans();
      console.log(subscriptions, this.subscriptionPlans)
    })
  }
  UserSubscriptiobHistory() {
    debugger;
    //this.spinner.show();
    this.subscriptionService.Getsubscriptionhistory(this.userDetails.userId).subscribe((data: any) => {
      console.log(data)
      this.Userlist = data.detail.paymentHistory;
      debugger;
      this.CurrentStatus = data.detail.paymentHistory[0].currentstatus;
      this.currentplan = data.detail.paymentHistory[0].planName;
      if (data.detail.paymentHistory[0].isActive && this.currentplan !== 'Free Trial') {
        if (this.currentplan == 'Toby Family') {
          this.showPlanTobyFamily = true;
          this.showPlanTobyPremium = false;
          this.showPlanTobyDuo = false;
          this.notFreeTrial = true;
          this.isCurrentPlan = true;
          let table = document.getElementById("tfamily");
          let heading = document.getElementById("tfamilyHeading");
          //table.style.borderTopLeftRadius=table.style.borderTopRightRadius=heading.style.borderRadius="0px";
        }
        else if (this.currentplan == 'Toby Premium') {
          this.showPlanTobyPremium = true;
          this.showPlanTobyFamily = false;
          this.showPlanTobyDuo = false;
          this.notFreeTrial = true;
          this.isCurrentPlan = true;
          let table = document.getElementById("tpremium");
          let heading = document.getElementById("tpremiumHeading");
          //table.style.borderTopLeftRadius=table.style.borderTopRightRadius=heading.style.borderRadius="0px";
        }
        else if (this.currentplan == 'Toby Duo') {
          this.showPlanTobyDuo = true;
          this.showPlanTobyPremium = false;
          this.showPlanTobyFamily = false;
          this.notFreeTrial = true;
          this.isCurrentPlan = true;
          let table = document.getElementById("tduo");
          let heading = document.getElementById("tduoHeading");
          //table.style.borderTopLeftRadius=table.style.borderTopRightRadius=heading.style.borderRadius="0px";
        }
      }
      else {
        this.notFreeTrial = false;
        this.showPlanTobyDuo = false;
        this.showPlanTobyPremium = false;
        this.showPlanTobyFamily = false;
        // this.showCurrentPlan = true;
      }
      console.log(this.CurrentStatus, this.currentplan, this.showChoosePlanBtn);
      this.spinner.hide();
    })
  }
  chooseTobyPlan(color: string, planName: string) {
    debugger;
    this.chosenPlan = true;
    this.planName = planName;
    if (planName == 'Toby Premium') {
      color = '#905800';
      this.subscriptionPlans.filter(plan=>{
        if(plan.planName=='Toby Premium'){
          this.selectedPlan.SubscriptionPlanId=plan.subscriptionPlanId
          this.selectedPlan.planName=planName;
          this.selectedPlan.price=plan.price;
          this.selectedPlan.noOfUsers=plan.noOfUsers;
        }
      })
    }
    else if (planName == 'Toby Family') {
      color = '#6f008f';
      this.subscriptionPlans.filter(plan=>{
        if(plan.planName=='Toby Family'){
          this.selectedPlan.SubscriptionPlanId=plan.subscriptionPlanId
          this.selectedPlan.planName=planName;
          this.selectedPlan.price=plan.price;
          this.selectedPlan.noOfUsers=plan.noOfUsers;
        }
      })
    }
    else if (planName == 'Toby Duo') {
      color = '#1E90FFf';
      this.subscriptionPlans.filter(plan=>{
        if(plan.planName=='Toby Duo'){
          this.selectedPlan.SubscriptionPlanId=plan.subscriptionPlanId
          this.selectedPlan.planName=planName;
          this.selectedPlan.price=plan.price;
          this.selectedPlan.noOfUsers=plan.noOfUsers;
        }
      })
    }
    console.log(this.selectedPlan);
    this.setId(this.selectedPlan.SubscriptionPlanId);
    setTimeout(() => {debugger;
      //this.spinner.show();
      //let ele=document.getElementById("plan");
      let eleHeading = document.getElementById("heading");
      let eleList = document.getElementById("list");
      let eleRow = document.getElementById("tr1")
      let eleBill = document.getElementById("bill")
      let eleBtn = document.querySelectorAll(".btnorg");
      let eleTable = document.getElementById("chosenTable")
      let backbutton = document.getElementById("backbtn");
      backbutton.style.marginLeft = "25%";
      console.log(eleList, eleRow, eleBtn, eleTable.style.backgroundColor, color);
      //ele.style.backgroundColor=color;
      if (planName == 'Toby Family') {
       // this.setId(this.subscriptionPlans[1].subscriptionPlanId);
        eleBtn[0].classList.remove("bluebtn");
        eleBtn[0].classList.remove("btnorg");        
        eleBtn[0].classList.add("lavenderbtn");
        if (eleBtn[1]) {
          eleBtn[1].className = "btnstyle2";
        }
      }
      // if(color=='#905800'){

      // }
      else if (planName == 'Toby Duo') {
        eleBtn[0].classList.remove("lavenderbtn");
        eleBtn[0].classList.remove("btnorg");        
        eleBtn[0].classList.add("bluebtn");

        if (eleBtn[1]) {
          eleBtn[1].className = "bluebtn";
        }
      }      
    }, 50)
    //this.spinner.hide();
    //ele.style.backgroundColor=color;
  }
  cancelPlan() {
    this.chosenPlan = false;
    let backbutton = document.getElementById("backbtn");
    backbutton.style.marginLeft = "1%";
  }

  setId(id: number) {
    this.subscriptionService.changeId(id)    ;
  }

  sanitizeContent(content: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }
 
}
