export class Notification {
    public NotificationId: any;
    public Title: string;
    public Description:string;
    public createdBy: any;
    public updatedBy: any;
}

export class MailNotification {
    public NotificationId: any;
    public Subject: string;
    public Descriptions:string;
}