import { AfterViewChecked, AfterViewInit, Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { Users } from '../models/users';
import { Router } from '@angular/router';
import { HomeService } from '../Shared/home.service';
import { LoadScriptService } from '../loadScript.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlbumService } from '../Shared/album.service';
import { PlayerService } from '../Shared/player.service';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';

@Component({
  selector: 'app-most-played-albums',
  templateUrl: './most-played-albums.component.html',
  styleUrls: ['./most-played-albums.component.css']
})
export class MostPlayedAlbumsComponent implements OnInit, AfterViewInit {

  userDetails = new Users();
  UserId: any;
  userFullname: any;
  mplayedAlbum = [];
  path;
  totalcount: any = 0;
  userImage: any;
  mostPlayedImage: any;
  marginRightMusicContainer: string;
  tempAlbumAddicts: any = new Array();
  showAllLess = "Show all";
  showAllAlbums = "Show all albums";
  minNum: number = 50;
  isLoading: boolean;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateScreenCss(event.target.innerWidth);
  }
  constructor(
    private _homeService: HomeService,
    private router: Router,
    private _playerService: PlayerService,
    public playerService: AudioPlayerService,
    private loadScript: LoadScriptService,
    private spinner: NgxSpinnerService,
    private albumService: AlbumService,
    private renderer: Renderer2
  ) {
    this.updateScreenCss(window.innerWidth);
  }

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.UserId = this.userDetails["userId"];
    this.userFullname = this.userDetails["fullName"];
    this.userImage = this.userDetails["image"];
    if (!this.userImage) {
      this.userImage = "../../../assets/images/NoUserFound.png";
    }
    this.getmostplayedalbumbyUserid(this.userDetails.userId);
  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }
  updateScreenCss(width: number) {
    const mainElement = document.querySelector('.main') as HTMLElement;
    const musicContainerElement = document.querySelector('.music-container') as HTMLElement;

    // Calculate margin-right dynamically for screen widths between 2049 and 5464
    if (musicContainerElement) {
      if (width >= 2049 && width <= 6144) {
        // Calculate the percentage value for margin-right
        const marginRightPercentage = ((width - 2049) / (6144 - 2049)) * 69; // Adjust 69 as needed
        musicContainerElement.style.marginRight = marginRightPercentage + '%';
      } else {
        musicContainerElement.style.marginRight = ''; // Reset margin-right for other screen widths
      }
      this.marginRightMusicContainer = musicContainerElement.style.marginRight;
    }
  }
  getmostplayedalbumbyUserid(userId) {
    this.isLoading = true;
    this.albumService.getmostplayedalbumbyuserid(userId).subscribe((data: any) => {
      this.isLoading = false;
      this.mplayedAlbum = data.mostPlayedAlbum;
      this.tempAlbumAddicts = this.mplayedAlbum.slice(0, this.minNum);
      this.totalcount = this.formatSongCount(data.mostPlayedAlbum.length);
      this.mostPlayedImage = data.mostPlayedAlbum[0].largeImage;
      console.log(data, this.mostPlayedImage);

      //this.renderer.setProperty(document.documentElement, 'scrollTop', 0);
    });
  }
  playAllsongs(items) {
    debugger;
    this.CheckSubscription(items, 0);
  }
  CheckSubscription(song, i) {
    debugger;
    this.playSong(song, i);
  }

  playSong(songs, index) {
    this._playerService.playSongs(songs);
    console.log(songs, index)
    if (this.playerService.repeatSong) {
      this.playerService.repeatSong = false;
    }
    this.playerService.setPlaylist(this._playerService.msaapPlaylist);
    this.playerService.selectATrack(index + 1);
    this._playerService.updateData(this._playerService.msaapPlaylist);
  }

  showMoresongs() {
    this.isLoading = true;
    this.minNum = this.minNum + 50;
    if (this.minNum > this.mplayedAlbum.length || this.minNum == this.mplayedAlbum.length) {
      this.minNum = this.mplayedAlbum.length
      this.showAllLess = "Show less";
    }
    // Introduce an artificial delay of 5-10 seconds
    const delay = Math.floor(Math.random() * 4000) + 1000; // Random delay between 5000ms (5s) and 11000ms (11s)

    setTimeout(() => {
      this.tempAlbumAddicts = this.mplayedAlbum.slice(0, this.minNum);
      this.isLoading = false;
    }, delay);
  }

  formatSongCount(length: number): string {
    if (length < 10 && length>0) {
      // Format numbers less than 10 with a leading zero
      return '0' + length;
    } else if (length >= 10000) {
      // Round the number to the nearest hundred for numbers >= 10,000
      const roundedLength = Math.round(length / 100) * 100;
      return new Intl.NumberFormat('en-US').format(roundedLength);
    } else {
      // Simply format numbers >= 10 and < 10,000 with commas
      return new Intl.NumberFormat('en-US').format(length);
    }
  }
}
