import { Component, Input, OnInit } from '@angular/core';
import { HomeService } from 'src/app/Shared/home.service';
import { UserService } from 'src/app/Shared/user.service';

@Component({
  selector: 'app-useraccount-header',
  templateUrl: './useraccount-header.component.html',
  styleUrls: ['./useraccount-header.component.css']
})
export class UseraccountHeaderComponent implements OnInit {
  userName: string;
  userDetails: any;
  userImage: any;
  isSupportActive:boolean=false;
  supportOrSetting="Support";

  constructor(private _homeService: HomeService,private _userService:UserService) { }

  ngOnInit() {
    debugger;
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.userDetails.image;
    this._homeService.userInitials = this.getInitials(this.userDetails.fullName);
    this.userName = this._homeService.userInitials;
    console.log('1', this._homeService.profileImage);
    if (this.userDetails.image != null) {
      // this.userImage = false;
      this._homeService.profileImage = this.userDetails.image;
      console.log(this._homeService.profileImage);
      this.userImage = this._homeService.profileImage;
    }
    else {
      this._homeService.profileImage = null;
    }
    //this.userImage =  this.userDetails.image;
    this._userService.displaySupport.subscribe(res=>{this.isSupportActive=res})
  }

  getInitials(fullName: string): string {
    debugger;
    if (!fullName) {
      return '';
    }
  
    const names = fullName.trim().split(' ');
  
    if (names.length === 1) {
      return names[0].charAt(0).toUpperCase();
    }
  
    const firstNameInitial = names[0].charAt(0).toUpperCase();
    const lastNameInitial = names[1] ? names[1].charAt(0).toUpperCase() : '';
  
    return firstNameInitial + lastNameInitial;
  }

  // getInitials(fullName: string): string {
  //   if (!fullName) {
  //     return '';
  //   }
  //   const names = fullName.trim().split(' ');
  //   if (names.length === 1) {
  //     return names[0].charAt(0).toUpperCase();
  //   }
  //   const initials = names.map(name => name.charAt(0).toUpperCase()).join('');
  //   return initials;
  // }



  handleError(): void {
    debugger;
    if (this.userImage == null || this.userImage == '') {
      this.userImage = '../../../assets/images/NoUserFound.png'; // Fallback image
    }
    else if (this._homeService.profileImage == null || this._homeService.profileImage == '') {
      this.userImage = '../../../assets/images/NoUserFound.png'; // Fallback image
    }
  }

  showSupportPart() {debugger
    this.isSupportActive=!this.isSupportActive;
    this._userService.showSupportPage(this.isSupportActive);
    console.log(this.isSupportActive,this.supportOrSetting);
  }
}
