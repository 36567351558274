import { Component, OnInit } from '@angular/core';
import { LoadScriptService } from 'src/app/loadScript.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/app.config';
import { UserService } from 'src/app/Shared/user.service';
import * as $ from 'jquery';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-userinvite',
  templateUrl: './userinvite.component.html',
  styleUrls: ['./userinvite.component.css']
})

export class UserInviteComponent implements OnInit {
  InviteForm: FormGroup;  
  fullName;
  email; 
  submitted = false;
  userDetails: any;
  InviteUsers:any = new InviteUsers();
  noOfUsers: any = 0;
  offeredPrice: any = 0;
 
  constructor(private toastr: ToastrService, private router: Router, private loadScript: LoadScriptService, private spinner: NgxSpinnerService,
    public translate: TranslateService, public userService: UserService, private formbulider: FormBuilder, private configService: ConfigService) {  }
 
  ngOnInit() {
    this.InviteForm = this.formbulider.group({
      fullName: ['', Validators.required],
      email: ['', Validators.required],
    })
    this.loadScript.getServiceBaseUrl();
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.translate.use(this.userDetails.languageCode);  

    let subs = this.userDetails.subscriptionPlans.filter((res: any) => {
      if(res.planCode == "MON_MULTI_30"){
        return res;
      }
    });
    let subs2 = this.userDetails.subscriptionPlans.filter((res: any) => {
      if(res.planCode == "MON_MULTI_INSTANT"){
        return res;
      }
    });
    let subs3 = this.userDetails.subscriptionPlans.filter((res: any) => {
      if(res.planCode == "MON_ADD_USER"){
        return res;
      }
    });
    if(this.userDetails.subscriptionPlanId == subs[0].subscriptionPlanId || this.userDetails.subscriptionPlanId == subs[0].subscriptionPlanId.toString()){
      this.noOfUsers = subs[0].noOfUsers - 1;
    }
    if(this.userDetails.subscriptionPlanId == subs2[0].subscriptionPlanId || this.userDetails.subscriptionPlanId == subs2[0].subscriptionPlanId.toString()){
      this.noOfUsers = subs2[0].noOfUsers - 1;
    }
    this.offeredPrice = subs3[0].offeredprice;

    $(document).ready(function () {
      $(".focus").focus();
    })
  }
  get f() { return this.InviteForm.controls; }

  cancel() {
    this.router.navigate([`/account/profile`]);
  }

  onFormSubmit() {
    this.submitted = true;
    if (this.InviteForm.invalid) {
      return;
    }
    this.InviteUser();
  }

  InviteUser() {
    this.spinner.show();    
    this.InviteUsers.userId = this.userDetails.userId;
    this.InviteUsers.fullName = this.InviteForm.get('fullName').value;
    this.InviteUsers.email = this.InviteForm.get('email').value;
    this.InviteUsers.status = false;
    this.userService.InviteUser(this.InviteUsers).subscribe((res: any) => {
      if(res.status == "200"){
        this.spinner.hide();
        this.toastr.success("Invite user successfully");
        this.router.navigate([`account/profile`]);
      }
      else if(res.status == "400"){
        this.spinner.hide();
        this.toastr.warning("Invite user successfully but Email not sent due to network error");
        this.router.navigate([`account/profile`]);
      } else if(res.status == 401) {
        this.toastr.warning("Email is already exist!");
        this.spinner.hide();
      } else if(res.status == 402) {
        this.toastr.warning("Please insert valid email !");
        this.spinner.hide();
      }
    });
  }
 
}

export class InviteUsers {
  userId: number;
  fullName: any;
  email: any;
  status: any;
}