import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlbumService } from 'src/app/Shared/album.service';
import { ArtistsService } from 'src/app/Shared/artists.service';
import { SongsService } from 'src/app/Shared/songs.service';
import { ConfigService } from 'src/app/app.config';
import { Location } from '@angular/common';
import { UserHomeBanner, Users } from 'src/app/models/users';
import { UserService } from 'src/app/Shared/user.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-banner-details',
  templateUrl: './banner-details.component.html',
  styleUrls: ['./banner-details.component.css']
})
export class BannerDetailsComponent implements OnInit {

  artists: any;
  albums: any;
  songs: any;
  imageType: any;
  isArtist: boolean = false;
  isAlbum: boolean = false;
  isSongs: boolean = false;
  ispromotion: boolean = false;
  numbers: any;
  x;
  i = 0;
  homeBanner = new UserHomeBanner();
  userDetails = new Users();
  imgURL: any;
  defaultImage:any;
  fileToUpload: File;
  files: any;
  noImage: boolean = true;
  bannerlist: any;
  timgN: any;
  image: any;
  name: any;
  date: any;
  imagel: any;
  userImage: any;
  userFullname: any;
  ArtistFavId: number;
  ArtistLikeId: number;
  AlbumFavId: number;
  AlbumLikeId: number;
  SongFavId: number;
  SongLikeId: number;
  UserId: number;
  dataOfArtist: any;
  AlbumId: any;
  ArtistId: any;
  SongId: any;
  AlbumBy: any;
  numberOfSongs: any;
  albumLength: any;
  songArtistName: any;
  songAlbumName: any;
  songLength: any;
  albumTitle: any;
  songTitle: any;

  constructor(
    private _songsService: SongsService, private _artistsService: ArtistsService,
    private formBuilder: FormBuilder, private albumService: AlbumService,
    public location: Location,
    public translate: TranslateService,
    private spinner: NgxSpinnerService,
    private configService: ConfigService,
    private userService: UserService,
    private toastr: ToastrService, private route: ActivatedRoute,
    private router: Router
  ) { 
    this.homeBanner.HomeBannerId = this.route.snapshot.paramMap.get("id");
    this.getbannerdetailsbyId(this.homeBanner.HomeBannerId);
  }

  ngOnInit() {
    this.userDetails=JSON.parse(localStorage.getItem('UserDetails'));
    this.UserId = this.userDetails["userId"];
    console.log(this.userDetails,"userDetails..");
    this.defaultImage='assets/images/NoUserFound.png'
    this.userFullname = this.userDetails["fullName"];
    this.userImage = this.userDetails["image"];
  }

  getbannerdetailsbyId(homebannerId) {
    debugger;
    this.userService.getBannerDetailsById(homebannerId).subscribe((data: any) => {
      console.log(data);
      this.bannerlist = data.homeBannerById[0];
      this.homeBanner.OrderImage = this.bannerlist.orderImage;
      if (this.bannerlist.thumbImage != 0) {
        this.timgN = this.bannerlist.thumbImage.split('/');
        this.timgN = this.timgN[this.timgN.length - 1];
        this.homeBanner.WebthumbImage = this.timgN;
        //console.log(this.homeBanner.WebthumbImage);
        this.imgURL = this.bannerlist.thumbImage;
        this.noImage = false;
      }
     
      if (this.bannerlist.artistId != 0) {
        this.homeBanner.ImageTypeId = 1;
        this.homeBanner.ArtistId = this.bannerlist.artistId;
        this.getArtist(this.bannerlist.artistId, this.UserId);
        this.isArtist = true;
        this.isAlbum = false;
        this.isSongs = false;
        this.ispromotion = false;
      }
      if (this.bannerlist.albumId != 0) {
        this.homeBanner.ImageTypeId = 2;
        this.homeBanner.AlbumId = this.bannerlist.albumId;
        this.getAlbum(this.bannerlist.albumId,this.UserId);
        this.isArtist = false;
        this.isAlbum = true;
        this.isSongs = false;
        this.ispromotion = false;
        //this.selectType("2");
      }
      if (this.bannerlist.songId != 0) {
        this.homeBanner.ImageTypeId = 3;
        this.homeBanner.SongId = this.bannerlist.songId;
        this.getSong(this.bannerlist.songId, this.UserId);
        this.isArtist = false;
        this.isAlbum = false;
        this.isSongs = true;
        this.ispromotion = false;
       // this.selectType("3");
      }
      if (this.bannerlist.promotion != null) {
        this.homeBanner.ImageTypeId = 4;
        this.homeBanner.Promotion = this.bannerlist.promotion;
        this.isArtist = false;
        this.isAlbum = false;
        this.isSongs = false;
        this.ispromotion = true;
        //this.selectType("4");
      }
    });
  }

  getArtist(artistid,userid){
    this._artistsService.getArtistDetailsByUserId(artistid,userid).subscribe((data: any) => {
      debugger;
      console.log('getArtistdata' , data)
      this.ArtistId=data.artistDetails.artistId;
      this.image = data.path.concat(data.artistDetails.thumbImage);
      this.imagel = data.path.concat(data.artistDetails.largeImage);
      this.name = data.artistDetails.fullName;
      this.date = data.artistDetails.createdTime;
      this.imageType= data.artistDetails.imageType;
      this.ArtistFavId= Number(data.artistDetails.favId);
      this.ArtistLikeId= Number(data.artistDetails.likeId);
      // this.dataOfArtist=data.artistDetails;
    })
  }

  getAlbum(albumId,userId){
    this.albumService.GetBannerAlbumsDetailByUserId(albumId,userId).subscribe((data: any) => {
      debugger;
      console.log('getalbdata' , data)
      this.AlbumId=data.albumDetails.albumId;
      this.image = data.path.concat(data.albumDetails.albumId, '/',data.albumDetails.thumbImage);
      this.imagel = data.path.concat(data.albumDetails.albumId, '/',data.albumDetails.largeImage);
      this.name = data.albumDetails.title;
      this.date = data.albumDetails.createdOn;
      this.imageType=data.albumDetails.imageType;
      this.AlbumFavId= Number(data.albumDetails.favId);
      this.AlbumLikeId= Number(data.albumDetails.likeId);
      this.AlbumBy=data.albumDetails.albumBy;
      this.numberOfSongs= data.albumDetails.noOfSongs;
      this.albumLength= data.albumDetails.length;
      this.albumTitle=data.albumDetails.title;
    })
  }

  getSong(songId,userId){
    this._songsService.getSongDetailsByUserId(songId,userId,false).subscribe((data: any) => {
      console.log('getsongdata' , data);
      this.SongId=data.songs.songId;
      this.image = data.path.concat(data.songs.thumbImage);
      this.imagel = data.path.concat(data.songs.largeImage);
      this.name = data.songs.title;
      this.date = data.songs.createdOn;
      this.SongFavId= Number(data.songs.favId);
      this.SongLikeId= Number(data.songs.likeId);
      this.songArtistName= data.songs.artistName;
      this.songAlbumName=data.songs.albumName;
      this.songLength=data.songs.length;
      this.songTitle=data.songs.title;
    });
  }

  refreshBannerDetails(){
    this.router.navigate([`/bannerdetails/${this.route.snapshot.paramMap.get("id")}`]);
  }
}
