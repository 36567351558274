import { Component, OnInit } from '@angular/core';
import { LoadScriptService } from 'src/app/loadScript.service';
import { HomeService } from "../../Shared/home.service";
import { Users } from 'src/app/models/users';
import { Router } from '@angular/router';
import { Miniuser } from '../../models/miniuser';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { UserService } from 'src/app/Shared/user.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  imgURL: any;
  fileToUpload: File;
  userDetails = new Users();
  userdatalist = new Miniuser();
  userdata: any;
  path;
  languageCode: any;
  theme: any
  noImage: boolean = true;
  userupload;
  fullName;
  city;
  CountryName;
  Email;
  MobileNo;
  userPhoto;
  userId: any;
  userImage: boolean;
  IsMobileReq : boolean;
  checked :boolean;
  ProfileType: any;
  isChecked:boolean;
  invitedUsers: any = [];
  invitedId: any;
  invitedEmail: any;
  invitedStatus: any;
  IsInvitedUser: boolean = false;
  IsTobyFamilyUser: boolean = false;
  constructor(private loadScript: LoadScriptService,
    private router: Router,
    private homeService: HomeService,
    public translate: TranslateService,
    public location: Location,
    public userService: UserService,
    private _toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) { }


  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.loadScript.getServiceBaseUrl();
    this.userId = this.userDetails.userId;
    this.IsMobileReq = this.userDetails.isMobileReq;
    this.Getuser(this.userDetails.userId ,this.userDetails.isMobileReq == null ?false : true);
    this.translate.use(this.userDetails.languageCode);
    this.languageCode = this.userDetails.languageCode;
    this.theme = this.userDetails.themeId;
    this.invitedUsers = [];
    let subs = this.userDetails.subscriptionPlans.filter((res: any) => {
      if(res.planCode == "MON_MULTI_30"){
        return res;
      }
    });
    let subs2 = this.userDetails.subscriptionPlans.filter((res: any) => {
      if(res.planCode == "MON_MULTI_INSTANT"){
        return res;
      }
    });
    if(this.userDetails.subscriptionPlanId == subs[0].subscriptionPlanId || this.userDetails.subscriptionPlanId == subs[0].subscriptionPlanId.toString()
      || this.userDetails.subscriptionPlanId == subs2[0].subscriptionPlanId || this.userDetails.subscriptionPlanId == subs2[0].subscriptionPlanId.toString()){
      this.IsTobyFamilyUser = true;
    } else {
      this.IsTobyFamilyUser = false;
    }
    if(this.IsTobyFamilyUser == true){
      this.GetInviteUsers(this.userDetails.userId);
    }
  }

  UserEdit() {
    localStorage.setItem("userid", this.userId.toString());
    this.router.navigate(['/account/editprofile'], { queryParams: { Id: this.userId } });
  }

  uploadphoto() {
    if (this.noImage) {
      this.userdatalist.base64String = null;
    } else {
      this.userdatalist.base64String = this.imgURL.split(",")[1];
    }
    this.homeService.uploadphoto(this.userdatalist).subscribe((data: any) => {
      this.imgURL = data.userProfile.fileName;
      if (this.imgURL == null) {
        this.userImage = false;
      }
      else if (this.imgURL != null) {
        this.userImage = true;
      }
      this.userdata = data;
      this.path = data.path;
    });
  }

  Getuser(userid ,IsMobileReq) {
    this.userdatalist.userId = userid;
    this.userDetails.isMobileReq = IsMobileReq;
    this.homeService.GetUserDetails(this.userDetails.userId).subscribe((data: any) => {
      this.userdata = data;
      console.log("userdata" , data)
      this.path = data.path;
      this.fullName = this.userdata.userDetail.fullName;
      this.city = this.userdata.userDetail.city
      this.CountryName = this.userdata.userDetail.countryName
      this.Email = this.userdata.userDetail.email
      this.MobileNo = this.userdata.userDetail.mobileNo
      this.imgURL = data.userDetail.image;
      this.homeService.profileImage = this.imgURL;
      if (this.imgURL == null) {
        this.userImage = false;
      }
      else if (this.imgURL != null) {
        this.userImage = true;
      }      
      //switch kid space
     if(data.userDetail.profileType == 'k'){
          this.isChecked = true;
     }
     else{
      this.isChecked = false;
     } console.log(data.userDetail,this.imgURL)
    });
  }

  selectPhoto(file: FileList) {
    this.userDetails.image = file.item(0).name;
    this.fileToUpload = file.item(0);
    var File = this.fileToUpload.name;
    var filetype = File.split('.');
    this.userdatalist.fileName = File.split('.')[0];
    filetype.forEach(element => {
      this.userdatalist.fileType = element
    });


    //this.userdatalist.fileType = File.split('.');
    this.noImage = false;
    //Show Image Preview
    //this.file[x].name.split('.')[0]
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imgURL = event.target.result;
      this.homeService.profileImage = this.imgURL;
      this.uploadphoto();
    }
    reader.readAsDataURL(this.fileToUpload);

  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.userdatalist.base64String = btoa(binaryString);
  }
  back() {
    this.location.back();
  }

  switch(event){
    this.checked=event.target.checked;
    if(this.checked==true){
      this.ProfileType='k';
    }
    else{
      this.ProfileType= '';
    }
      this.userService.UpdateProfileType(this.userId,this.ProfileType).subscribe((res:any)=>{
        if(res.status == 200){
          this._toastr.success("Kids Space Switched successfully")
        }
        else{
          this._toastr.error("Kids space not Switched")
        }
      })
  }

  GetInviteUsers(userId){
    this.invitedUsers = [];
    this.IsInvitedUser = false;
    this.spinner.show();
    this.userService.GetInviteUsers(userId).subscribe((data: any) => {
      if(data.isSuccess == true){
        this.invitedUsers = data.inviteUsers;        
      }
      if(this.invitedUsers.length > 0){
        this.IsInvitedUser = false;
        this.spinner.hide();
      } else{
        this.IsInvitedUser = true;
        this.spinner.hide();
      }      
    });
  }

  UserInvite() {
    localStorage.setItem("userid", this.userId.toString());
    this.router.navigate(['/account/userinvite'], { queryParams: { Id: this.userId } });
  }

  deleteInvitedUser(invtId, email, stats){
    if (confirm('Are you sure you want to delete?')) {
      this.userService.DeleteInvitedUser(invtId,email,stats).subscribe((res: any) => {
        if(res.isSuccess == true){
          this.GetInviteUsers(this.userDetails.userId);
          this._toastr.success("User deleted successfully");
        } else {
          this._toastr.success("Something went wrong please contact administration");
        }
      });
    }
  }

}