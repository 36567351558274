import { Component, OnInit } from '@angular/core';
import { LoadScriptService } from '../loadScript.service';
import { Users } from '../models/users';
import { HomeService } from '../Shared/home.service';
import { Router } from '@angular/router';
import { Role } from '../models/role';
import { Track } from '../../../Player/ngx-audio-player/src/lib/model/track.model'
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { PlayerService } from '../Shared/player.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from "ngx-spinner";
import { UserService } from '../Shared/user.service';


@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
    [x: string]: any;

    slides = [];
    artistList = [];
    topSongs = [];
    homeDetails = [];
    newReleaseSongs = [];
    recommendedSongs = [];
    favouriteSongs = [];
    mostPlayedSongs = [];
    userLogin: boolean;
    path;
    index = 0;
    track: Track[] = [];
    public count: number = 0;
    userDetails = new Users();
    songDetails = new Track();
    artistLogin: boolean = false;

    noImage: boolean[] = [];
    newReleaseImage: boolean[] = [];
    noMostplayedImage: boolean[] = [];
    nofavouriteSongsImage: boolean[] = [];
    noArtistmage: boolean[] = [];
    substatus: any;
    songIsthere: boolean = false;
    themeidcode: string;
    adminplaylist: any;
    theme: any;
    artiststatus: any;
    totalabum: any;
    totalSongs: any;
    totalLikes: any;
    totalFav: any;
    totalListners: any;
    adminStats: any;
    totalUsers: any;
    totalActiveUsers: any;
    totalIOsUsers: any;
    totalAndroidUsers: any;
    totalTobyPremium: any;
    totalTobyFamily: any;
    totalCancelSubscriptions: any;
    totalabums: any;
    totalartistSongs: any;
    artistid: any;
    artistsong: any;

    constructor(
        private router: Router,
        private loadScript: LoadScriptService,
        private _homeService: HomeService,
        private playerService: AudioPlayerService,
        private _playerService: PlayerService,
        public translate: TranslateService,
        public userService: UserService,
        private spinner: NgxSpinnerService
    ) { }


    ngOnInit() {
        this.themeidcode = localStorage.getItem('themeId');
        this.loadScript.getServiceBaseUrl();
        this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
        this.substatus = this.userDetails.subscriptionStatus;
        this.translate.use(this.userDetails.languageCode);
        this.theme = this.userDetails.themeId;
        this.Changetheme();
        // this.getartistStatus();
        if (this.userDetails.userType == Role.superAdmin) {
            this.userLogin = false;
            this.getAdminStats();
        } else if (this.userDetails.userType == Role.user) {
            this.userLogin = true;
            this.getHomeDetails(this.userDetails.userId);
        }
        else {
            this.artistLogin = true;
            this.userLogin = false;
            this.getartistStatus();
            this.ArtistSongsById();
        }
    }
    languageChange(language: string) {
        localStorage.setItem('locale', language);
        this.translate.use(language);
    }
    CheckSubscription(song, i) {

        if (this.substatus == "False") {

            document.getElementById("Subscriptioncheck").style.display = "block";
            //this.router.navigate(['/account/upgradeplan']);
        }
        else {
            this.songIsthere = true;
            this.playSong(song, i);
        }
    }

    openForm() {
        document.getElementById("myForm").style.display = "block";
    }
    getHomeDetails(userId) {
        this.spinner.show();
        debugger;
        console.log();
        this._homeService.getHomeDetails(userId).subscribe((data: any) => {
            this.homeDetails = data;
            console.log('this.homeDetails', this.homeDetails)
            this.path = data.path;
            this.artistList = data.homePageDetails.artist;
            this.topSongs = data.homePageDetails.topSongs;
            this.newReleaseSongs = data.homePageDetails.newRelease;
            this.recommendedSongs = data.homePageDetails.recommendedSongs;
            this.favouriteSongs = data.homePageDetails.favouriteSongs;
            this.mostPlayedSongs = data.homePageDetails.mostPlayedSongs;
            this.adminplaylist = data.homePageDetails.adminPlayLists;
            this.spinner.hide();
        });

    }
    getartistStatus() {
        this.spinner.show();
        this.artistid = this.userDetails.artistId;
        // console.log('artiststatus' , this.artistid)
        this._homeService.getArtistStatus(this.artistid).subscribe((data: any) => {
            if (data.artistStatus !== null) {
                this.artiststatus = data;
                this.totalabums = this.artiststatus.artistStatus.totalAlbums;
                this.totalartistSongs = this.artiststatus.artistStatus.totalSongs;
                this.totalLikes = this.artiststatus.artistStatus.totalLikes;
                this.totalFav = this.artiststatus.artistStatus.totalFav;
                this.totalListners = this.artiststatus.artistStatus.totalListners;
            }
            this.spinner.hide();
        });
    }
    getAdminStats() {
        this._homeService.getAdminStats().subscribe((data: any) => {
            this.adminStats = data;
            this.totalUsers = this.adminStats.adminStats[0].totalUsers;
            this.totalArtists = this.adminStats.adminStats[0].totalArtists;
            this.totalSongs = this.adminStats.adminStats[0].totalSongs;
            this.totalabum = this.adminStats.adminStats[0].totalAlbums;
            this.totalActiveUsers = this.adminStats.adminStats[0].totalActiveUsers;
            this.totalIOsUsers = this.adminStats.adminStats[0].totalIOsUsers;
            this.totalAndroidUsers = this.adminStats.adminStats[0].totalAndroidUsers;
            this.totalWebUsers = this.adminStats.adminStats[0].totalWebUsers;
            this.totalFreeSubscriptions = this.adminStats.adminStats[0].totalFreeSubscriptions;
            this.totalTobyPremium = this.adminStats.adminStats[0].totalTobyPremium;
            this.totalTobyFamily = this.adminStats.adminStats[0].totalTobyFamily;
            this.totalCancelSubscriptions = this.adminStats.adminStats[0].totalCancelSubscriptions;
            this.spinner.hide();
        });
    }
    ArtistSongsById() {
        this._homeService.ArtistSongsById(this.artistid).subscribe((data: any) => {
            this.artistsong = data.artistsongs;
        });

    }


    Artistprofile(ArtistId: number) {
        this.router.navigate(['/artist/artistprofile'], { queryParams: { Id: ArtistId } });
    }

    playSong(songs, index) {
        this._playerService.playSong(songs, this.path, index);
    }

    slideConfig = {
        "slidesToShow": 2,
        "slidesToScroll": 2,
        "prevArrow": "<div class='nav-btn next-slide'> <button style='float:left;margin-top: 42px;background: none;border: none;padding: 0 !important;color:  #6c757d;font-size: 30px;margin: 0px; padding: 4px 7px;/* background: #D6D6D6; */display: inline-block;cursor: pointer; border-radius: 3px;opacity: 0.5;' type='button' role='presentation'><i class='fa fa-angle-left' aria-hidden='true'></i></button></div>",
        "nextArrow": "<div class='nav-btn prev-slide'> <button style='float:right;margin-top: 42px;  background: none;border: none;padding: 0 !important;color:  #6c757d;font-size: 30px;margin: 0px; padding: 4px 7px;/* background: #D6D6D6; */display: inline-block;cursor: pointer; border-radius: 3px;opacity: 0.5;' type='button' role='presentation'><i class='fa fa-angle-right' aria-hidden=true'></i></button></div>",
        "dots": false,
        "infinite": false

    };

    slideConfigForSongs = {
        "slidesToShow": 6,
        "slidesToScroll": 6,
        "prevArrow": "<div class='nav-btn next-slide'> <button style='float:left;margin-top: 42px;  background: none;border: none;padding: 0 !important;color:  #6c757d;font-size: 30px;color:#596675;margin: 0px; padding: 4px 7px;/* background: #D6D6D6; */display: inline-block; border-radius: 3px;opacity: 0.5;cursor: pointer;' type='button' role='presentation'><i class='fa fa-angle-left'  aria-hidden='true'></i></button></div>",
        "nextArrow": "<div class='nav-btn prev-slide'> <button style='float:right;margin-top: 42px; background: none;border: none;padding: 0 !important;color:  #6c757d;font-size: 30px;color:#596675;margin: 0px; padding: 4px 7px;/* background: #D6D6D6; */display: inline-block; border-radius: 3px;opacity: 0.5;cursor: pointer;' type='button' role='presentation'><i class='fa fa-angle-right' aria-hidden=true'></i></button></div>",
        "dots": false,
        "infinite": false
    };

    addSlide() {
        this.slides.push({ img: "http://placehold.it/350x150/777777" })
    }

    removeSlide() {
        this.slides.length = this.slides.length - 1;
    }

    slickInit(e) {

    }

    breakpoint(e) {

    }

    afterChange(e) {

    }

    beforeChange(e) {

    }
    filersongs(data, songData, index) {

        if (data.recType == "S" || data.recType == "DJ") {
            if (this.substatus == "False") {
                document.getElementById("Subscriptioncheck").style.display = "block";
                // document.getElementById("Subscriptioncheck").style.display = "block";
                //this.router.navigate(['/account/upgradeplan']);
                //alert("Your Subscription has Ended");

            }
            else {
                this.songIsthere = true;
                let tempSongList = [];
                let j = 0;
                for (var i = 0; i < songData.length; i++) {

                    if (songData[i].recType == "S" || songData[i].recType == "DJ") {
                        tempSongList[j] = songData[i];
                        if (index == i) {
                            index = j;
                        }
                        j++;
                    }
                }
                this._playerService.playSong(tempSongList, this.path, index);
            }

        } else {

            this.router.navigate(['/album/album-details'], { queryParams: { Id: data.recId } });

        }

    }

    viewallsongs(playlistId: number, Name: any) {
        this.router.navigate([`/Suggestedplaylistdetail`], { queryParams: { Id: playlistId, playListName: Name } });
    }
    Changetheme() {
        if (this.userDetails.themeId == '2') {
            this.userService.theme1 = false;
        }
        else {
            this.userService.theme1 = true;
        }
    }

    closeForm() {
        document.getElementById("Subscriptioncheck").style.display = "none";
        this.router.navigate(['/account/upgradeplan']);
    }


}