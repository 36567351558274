import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { LoadScriptService } from 'src/app/loadScript.service';
import { HomeService } from '../../Shared/home.service';
import { Track } from '../../../../Player/ngx-audio-player/src/lib/model/track.model'
import { PlayerService } from '../../Shared/player.service';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Playlist } from 'src/app/models/playlist';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from "ngx-spinner";
import { Location } from '@angular/common';
import { UserService } from 'src/app/Shared/user.service';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-albumdetails',
  templateUrl: './albumdetails.component.html',
  styleUrls: ['./albumdetails.component.css']
})
export class AlbumdetailsComponent implements OnInit {
  @ViewChild('f') form: NgForm;

  @ViewChild('closeBtn') closeBtn: ElementRef;
  userId: string;
  userDetails: any;
  noImageForArtist: boolean = true;
  result;
  likeId: any;
  favId: any;
  likedimage: boolean;
  nofavImage: boolean;
  allsongsid: any;
  playid: any = [];
  createchecked: boolean = true;
  updateextchecked: boolean;
  noLikeImage: boolean;
  noLikeImage1: boolean[] = [];
  nofavimage1: boolean[] = [];
  pausebutton: boolean[] = [];
  playbutton: boolean[] = [];
  playPauseButton = [];

  substatus: any;
  songIsthere: boolean = false;
  playBtn: boolean[] = [];
  currentSongId: any;
  paramId: any;


  constructor(private router: Router,
    private loadScript: LoadScriptService,
    private homeService: HomeService,
    private playerService: AudioPlayerService,
    private _playerService: PlayerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    public translate: TranslateService,
    private spinner: NgxSpinnerService,
    public location: Location,
    public userService: UserService,
  ) { }
  path;
  Details;
  artistname;
  allsongs = [];
  tm;
  timge;
  nooflike;
  description;
  album;
  albumby;
  noofSongs;
  releasedOn;
  noofLikes;
  albumid;
  tmimages = [];
  noImage: boolean[] = [];
  TransList: Array<any> = [];
  songDetails = new Track();
  albumImage: boolean = true;
  playlist = new Playlist();
  playListDetails: any;
  CompanyIdUpdate = "0";
  playd = [];
  index;
  languageCode: any;
  theme: any;
  ngOnInit() {

    let param1 = this.route.snapshot.queryParams["Id"];
    this.paramId = this.route.snapshot.queryParams["Id"];
    this.loadScript.getServiceBaseUrl();
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.userId = this.userDetails.userId;
    this.substatus = this.userDetails.subscriptionStatus;
    let InvId = localStorage.getItem("AlbumId");
    let Img = localStorage.getItem("image");
    this.freshplaylist();
    if (InvId != "0") {
      this.DisplayalbumDetail(param1, this.userId);
    }

    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.languageCode = this.userDetails.languageCode;
    this.theme = this.userDetails.themeId

  }
  DisplayalbumDetail(albumId: string, UserId: string) {
    this.spinner.show();
    this.homeService.getAlbumbyId(albumId, UserId).subscribe(Response => {
      this.TransList.push(Response);
      this.path = this.TransList[0].path;
      this.album = this.TransList[0].albumDetails.title;
      this.albumid = this.TransList[0].albumDetails.albumId;
      this.albumby = this.TransList[0].albumDetails.albumBy;
      this.noofSongs = this.TransList[0].albumDetails.noofSongs;
      this.releasedOn = this.TransList[0].albumDetails.releasedOn;
      this.noofLikes = this.TransList[0].albumDetails.noofLikes;
      this.likeId = this.TransList[0].albumDetails.likeId;
      if (this.TransList[0].albumDetails.songs.length > 0)
        this.allsongs = this.TransList[0].albumDetails.songs;
      let currentSongId = localStorage.getItem('CurrentSongId');
      let getSong = this.allsongs.findIndex(x => x.songId == currentSongId)
      if (getSong != -1) {
        let isSongPlaying = localStorage.getItem('IsPlaying');
        if (isSongPlaying == 'true') {
          this.playerService.playPauseBtn[this.playerService.indexSong] = true;
        } else if (isSongPlaying == 'false') {
          this.playerService.playPauseBtn[this.playerService.indexSong] = false;
        }

      } else {
        this.playerService.playPauseBtn[this.playerService.indexSong] = false;
      }

      this.favId = this.TransList[0].albumDetails.favId;
      this.tm = this.TransList[0].albumDetails.thumbImage;
      for (var a = 0; a < this.allsongs.length; a++) {
        if (this.allsongs[a].likeId == 0) {
          this.noLikeImage1[a] = false;
        } else {
          this.noLikeImage1[a] = true;
        }
      }
      for (var a = 0; a < this.allsongs.length; a++) {
        if (this.allsongs[a].favId == 0) {
          this.nofavimage1[a] = false;
        } else {
          this.nofavimage1[a] = true;
        }
      }
      if (this.likeId == 0) {
        this.likedimage = true;
      }
      else {
        this.likedimage = false
      }
      if (this.favId == 0) {
        this.nofavImage = true;
      } else {
        this.nofavImage = false;
      }
      for (var i = 0; i < this.allsongs.length; i++) {
        if (this.allsongs.length != 0) {
        }

      }
      this.spinner.hide();
    })
  }
  LikeAlbums(albumId: string, UserId: string) {
    this.likedimage = !this.likedimage;
    this.homeService.LikeAlbums(this.albumid, this.userId).subscribe((res: any) => {
      this.result = res.data;
      this.noofLikes = this.result.NoofLikes;
    })
  }
  FavouriteAlbums(albumId: string, UserId: string) {
    this.nofavImage = !this.nofavImage;
    this.homeService.FavouriteAlbums(this.albumid, this.userId).subscribe((res: any) => {
    })
  }

  playSong(songs, index) {
    let songId = songs[index].songId;
    localStorage.setItem('CurrentSongId', songId);

    let indexForPlayPause = this.playerService.playPauseBtn.findIndex(x => x == true);
    if (indexForPlayPause == index) {
      localStorage.setItem('IsPlaying', 'false');
      this.playerService.playPauseBtn[index] = !this.playerService.playPauseBtn[index];
    }
    else {
      localStorage.setItem('IsPlaying', 'true');
      if (indexForPlayPause == -1) {
        indexForPlayPause = 0;
      }
      this.playerService.playPauseBtn[indexForPlayPause] = false;
      this.playerService.playPauseBtn[index] = true;
    }
    this._playerService.playSong(songs, this.path, index);
  }

  fetchSongId(i) {
    this.playlist.SongId = i;
  }

  onSubmit() {
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.playlist.UserId = this.userDetails.userId;
    this.homeService.addOrUpdateplaylist(this.playlist).subscribe((res: any) => {
      if (res.status == 200) {
        if (this.playlist.PlaylistId! == 0) {
          this.freshplaylist();
          this.toastr.success("Playlist added successfully");
          this.createchecked = true;
          this.updateextchecked = false
          this.form.reset();
          this.form.resetForm();

        } else {
          this.closeBtn.nativeElement.click();
          this.freshplaylist();
          this.toastr.success("Playlist updated successfully");
          this.createchecked = true;
          this.updateextchecked = false
          this.form.reset();
          this.form.resetForm();
        }
      }
      else {
        this.toastr.success("Playlist Already Exists");

      }
    })
  }

  CheckSubscription(song, i) {
    if (this.substatus == "False") {
      document.getElementById("Subscriptioncheck").style.display = "block";
    }
    else {
      this.songIsthere = true;
      this.playSong(song, i);
    }
  }
  closeForm() {
    document.getElementById("Subscriptioncheck").style.display = "none";
    this.router.navigate(['/account/upgradeplan']);
  }
  checked() {
    this.createchecked = true;
    this.updateextchecked = false;
  }
  updatechecked() {
    this.updateextchecked = true;
    this.createchecked = false;
    this.playlist.PlaylistId = undefined;
  }
  Likesongs(songId: string, UserId: string, i) {
    this.noLikeImage1[i] = !this.noLikeImage1[i];
    // this.noLikeImage = !this.noLikeImage;
    this.homeService.LikeSong(songId, this.userId).subscribe((res: any) => {

    }
    )
  }
  FavouriteSongs(songId: string, UserId: string, i) {
    this.nofavimage1[i] = !this.nofavimage1[i];
    this.homeService.FavouriteSongs(this.playlist.SongId, this.userId).subscribe((res: any) => {
      this.result = res.data;
    })
  }
  updatecount(songId) {
    debugger;
    this.homeService.updatecount(songId).subscribe((res: any) => {
      this.result = res.data;
    })

  }
  IsCheckLang() {

    if (this.translate.currentLang == 'ar', this.translate.currentLang == 'as') {
      return true;

    } else {
      return false;
    }
  }
  refreshplaylist() {
    this.homeService.getPlaylist(this.userDetails.userId).subscribe((data: any) => {
      this.playListDetails = data.playListDetails;
      if (this.playListDetails.length >= 10) {
        for (var i = this.index; i < 10; i++) {
          this.playd[i] = this.playListDetails[i];
          this.homeService.playId[i] = this.playListDetails[i];
        }
      } else {
        for (var i = this.index; i < this.playListDetails.length; i++) {
          this.playd[i] = this.playListDetails[i];
          this.homeService.playId[i] = this.playListDetails[i];
        }
      }
    });
  }
  freshplaylist() {
    this.homeService.getPlaylist(this.userDetails.userId).subscribe((data: any) => {
      this.playListDetails = data.playListDetails;
      //this.homeService.getLatestPlaylist(this.playListDetails);
    })
  }
  back() {
    this.location.back();
  }
}


