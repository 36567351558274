import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from "ngx-spinner";
import { Location } from '@angular/common';
import { UserService } from 'src/app/Shared/user.service';
import { LoadScriptService } from 'src/app/loadScript.service';
import { Users } from 'src/app/models/users';
import { Router } from '@angular/router';
import { SubscriptionService } from '../../Shared/subscription.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-order-history',
  templateUrl: './user-order-history.component.html',
  styleUrls: ['./user-order-history.component.css']
})
export class UserOrderHistoryComponent implements OnInit {
  userDetails = new Users();
  Userlist: any;
  userId: number;
  currentplan: any;
  subscriptionId: number;
  showcancl: boolean;
  CurrentStatus: any;

  constructor(private toastr: ToastrService, public translate: TranslateService, private loadScript: LoadScriptService,
    private spinner: NgxSpinnerService, private router: Router, private SubService: SubscriptionService,
    public userService: UserService, public location: Location,) { }

  // constructor() { }

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.userId = this.userDetails.userId;
    this.subscriptionId = this.userDetails.braintreeSubscriptionId;
    this.SubHistory();
  }

  SubHistory() {
    this.SubService.Getsubscriptionhistory(this.userId).subscribe((data: any) => {
      this.Userlist = data.detail.paymentHistory;
      this.CurrentStatus = data.detail.paymentHistory[0].currentstatus;
      this.currentplan = data.detail.paymentHistory[0].planName;
      if (data.detail.paymentHistory[0].isActive && this.currentplan !== 'Free Trial') {
        this.showcancl = true;
      }
      else {
        this.showcancl = false;
      }
    })
  }

  back() {
    this.location.back();
  }
}
