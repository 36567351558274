import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Track } from '../../../../Player/ngx-audio-player/src/lib/model/track.model';
import { PlayerService } from 'src/app/Shared/player.service';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { UserService } from 'src/app/Shared/user.service';
import { HomeService } from 'src/app/Shared/home.service';
import { SongsService } from 'src/app/Shared/songs.service';
import { Location } from '@angular/common';
import { first, take, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { ArtistsService } from 'src/app/Shared/artists.service';
import { Playlist } from 'src/app/models/playlist';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/Shared/shared.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  msbapTitle = 'Title';
  msbapAudioUrl = 'Link URL';
  msaapDisplayTitle = true;
  msaapDisplayPlayList = true;
  pageSizeOptions = [2, 4, 6]
  songsLists = new Track();
  msbapDisplayTitle = false;
  msaapPlaylist: Track[];

  notshow = false;
  img: any;
  songsDetailsList: any;
  artistSongs: any;
  favouriteSongs: any;
  songsArray: any;
  playlistTitle: string;
  playlistArtistName: string;
  newReleaseSongs;
  playlist: Track[];
  userDetails: any;
  artistId: any;
  artistIsLoggedIn: boolean = false;
  hide: boolean;
  featuredSong: any;
  path: any;
  showSongDetail: boolean = false;
  isClosing: boolean = false;
  Songdata: any;
  songPath: any;
  private destroy = new Subject();
  private subscription: Subscription;
  showSongData: string;
  artistImage: any = '';
  songDetails: any;
  lyricsArray: string[] = [];
  isSongplay: boolean = false;
  SongId: any;
  playlists = new Playlist();
  UserId: any;
  createchecked: boolean = true;
  updateextchecked: boolean;
  @Input() isAddtoplaylistmultiplesong: boolean = false;

  @ViewChild('closeBtn') closeBtn: ElementRef;
  @ViewChild('f') form: NgForm;
  playListDetails: any;
  IsActiveSongId: any;
  thumbImage: any;
  tfavsongs: any;
  lastplayedSong: any;
  constructor(public playerService: PlayerService,
    private _playerService: AudioPlayerService,
    private _homeService: HomeService,
    public userService: UserService,
    private _songservice: SongsService,
    public location: Location,
    private artistsService: ArtistsService,
    private toastr: ToastrService,
    private sharedService: SharedService, private router: Router) {
    const isUserpresent = localStorage.getItem('Isuser') === 'true';
    if (isUserpresent == true) {
      this.userService.IsUser(true);
    }

    this._playerService.getIsblogorCountryScreen().subscribe(value => {
      this.IsActiveSongId = value;
      if (this.showSongDetail == true) {
        document.body.style.overflow = 'hidden';
        this.isSongplay = true;
        this.getsongbyID(this.IsActiveSongId, this.UserId);
      }
      //console.log(this.IsActiveSongId);
    })

    // this.userService.getIssongPage().pipe(

    //   takeUntil(this.destroy)     // import takeUntil from rxjs/operators.
    //    ).subscribe(obj => {
    //     debugger;
    //     alert("check");
    //  });

    // this.userService.getIssongPage().subscribe((data: any) => {
    //   debugger;
    //   //  window.scroll(0,0);
    //   console.log(data);
    //   alert("check")
    //   // this.getsongbyID(value);
    //   // this.showSongDetail = true;

    // });
  }



  ngOnInit() {
    debugger;
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.UserId = this.userDetails.userId;
    let ismobileReq = this.userDetails.isMobileReq;
    //Check wheather user is logged in or Artist
    this.artistId = this.userDetails.artistId;
    this.isAddtoplaylistmultiplesong;
    this.freshplaylist();

    if (this.artistId != null) {
      this.playerService.playForArtist = false;
      this.notshow = true;
      this.hide = true;
    }
    this.getLastPlayedSongs(this.UserId);
    // this._playerService.registerPlaySongCallbackno((song) => {
    //   if (song == true) {
    //     this.isSongplay = true;
    //   }
    //   else {
    //     this.isSongplay = false;
    //   }
    // });

    // this.userService.getIssongPage().subscribe((data: any) => {
    //   debugger;
    //   window.scroll(0, 0);
    //   if (data != null) {
    //     this.getsongbyID(data.songId);
    //     this.showSongDetail = data.isSongPage;
    //   }


    // });
    // this._homeService.getHomeDetails(userId).subscribe((data: any) => {
    //   this.newReleaseSongs = data.homePageDetails.newRelease;
    //   console.log('footersongs',this.newReleaseSongs);
    //   this.newReleaseSongs = data.homePageDetails.topSongs;
    //   for (let i = 0; i < this.newReleaseSongs.length; i++) {
    //     this.newReleaseSongs[i].image = data.path + this.newReleaseSongs[i].thumbImage;
    //     this.newReleaseSongs[i].link = this.newReleaseSongs[i].streamUrl;
    //   }

    //   this.msaapPlaylist = this.newReleaseSongs;
    // });

    this._songservice.GetTopFavSongs(this.userDetails.userId).subscribe((data: any) => {
      this.tfavsongs = data.getTopFavSongs.slice(0, 15);
      this.getLastPlayedSongs(this.UserId);
      // });
      // this._songservice.GetFeaturedSongs().subscribe((data: any) => {
      //   this.featuredSong = data.feaSongs;
      //   this.path = data.feaSongs.path;
      this.newReleaseSongs = this.tfavsongs;
      this.newReleaseSongs.forEach(song => {
        song.image = song.thumbImage;
        song.link = song.streamUrl;
      });
      debugger;
      if (this.lastplayedSong != null) {
        //this.msaapPlaylist = this.newReleaseSongs;
        const modifiedLastSong = {
          title: this.lastplayedSong.title,
          link: this.lastplayedSong.streamUrl,  // Change stream to link
          image: this.lastplayedSong.thumbImage,
          artistName: this.lastplayedSong.artistName,
          songId: this.lastplayedSong.songId,
          // Change thumbimage to image
        };
        console.log('test audio', this.newReleaseSongs);
        this.newReleaseSongs.unshift(modifiedLastSong);
        this.msaapPlaylist = this.newReleaseSongs;
      }
      else {
        this.msaapPlaylist = this.newReleaseSongs;
      }

      console.log('test audio', this.msaapPlaylist);
      // for (let i = 0; i < this.newReleaseSongs.length; i++) {
      //   this.newReleaseSongs[i].image = this.newReleaseSongs[i].thumbImage;
      //   this.newReleaseSongs[i].link = this.newReleaseSongs[i].streamUrl;
      // }
      // this.msaapPlaylist = this.newReleaseSongs;
    });

    //this.getsongbyID(localStorage.getItem('IsSelectSong'));

  }

  // close() {
  //   this.isClosing = true;
  //   setTimeout(() => {
  //     this.showSongDetail = false;
  //   }, 500);
  //   setTimeout(() => {
  //     this.isClosing = false;
  //   }, 500);
  //   document.body.style.overflow = 'auto';
  //   //this.userService.IssongPages({ isSongPage: false, songId: 0 })
  // }

  close() {
    this.isClosing = true;
    setTimeout(() => {
      this.showSongDetail = false;
    }, 300); // This time should match the animation duration
    setTimeout(() => {
      this.isClosing = false;
    }, 300);
    document.body.style.overflow = 'auto';
  }


  changeMsbapDisplayTitle(event) {

    this.msbapDisplayTitle = event.checked;
  }

  changeMsaapDisplayTitle(event) {

    this.msaapDisplayTitle = event.checked;
  }

  changeMsaapDisplayPlayList(event) {

    this.msaapDisplayPlayList = event.checked;
  }

  getsongbyID(sondId, userId) {
    debugger;
    this._songservice.getSongDetailsfooter(sondId, userId).subscribe((data: any) => {
      console.log('songid', data);
      this.Songdata = data.songs;
      this.thumbImage = data.path + data.songs.thumbImage;
      //this.songPath = data.path;
      this.showSongDetail = true;
      document.body.style.overflow = 'hidden';
      //this.splitLyricsIntoChunks(this.Songdata.lyrics);
      // this.Songdata.lyrics =  
      this.convertDuration(this.Songdata.length);
      this.getArtistDetail(this.Songdata.artistId);
      this.getSongByArtistId(this.Songdata.artistId, this.UserId);
    });
  }

  selectedSongPage(data: any) {
    debugger; console.log(data)
    if (data != null) {
      window.scroll(0, 0);
      this.SongId = data.songid;
      this.getsongbyID(data.songid, this.UserId);
      this.showSongDetail = data.isSongPage;
      // this._playerService.registerPlaySongCallbackno((song) => {
      //   if (song == true) {
      //     this.isSongplay = true;
      //   }
      //   else {
      //     this.isSongplay = false;
      //   }
      // });
    }
  }

  getArtistDetail(artistId) {
    this.artistImage = "";
    this.artistsService.getArtistDetails(artistId).subscribe((data: any) => {
      console.log('getArtistdata', data);
      this.artistImage = data.path.concat(data.artistDetails.thumbImage);
      // this.artistImagel = data.path.concat(data.artistDetails.largeImage);
      // this.artistName = data.artistDetails.fullName;
      // this.artistCreateDate = data.artistDetails.createdOn;
    })
  }

  getSongByArtistId(artistId, UserId) {
    this._homeService.ArtistSongsByIdfooter(artistId, UserId).subscribe((data: any) => {
      console.log('getdata', data);
      this.songDetails = data.artistsongs;
      //this.totalsongcount = data.artistsongs.length;
    });
  }

  splitLyricsIntoChunks(lyrics: string): void {
    const maxChars = 100; // Maximum characters per chunk
    let currentIndex = 0; // Current index in lyrics

    while (currentIndex < lyrics.length) {
      let endIndex = Math.min(currentIndex + maxChars, lyrics.length);
      // Ensure we don't break words
      if (endIndex < lyrics.length && lyrics[endIndex] !== ' ' && lyrics[endIndex - 1] !== ' ') {
        while (endIndex > currentIndex && lyrics[endIndex] !== ' ') {
          endIndex--;
        }
      }
      // Add the substring to the array
      this.lyricsArray.push(lyrics.substring(currentIndex, endIndex).trim());
      // Update the current index
      currentIndex = endIndex;
      console.log(this.lyricsArray);
    }
  }

  convertDuration(duration: string): string {
    const parts = duration.split(':');
    const minutes = parts[0];
    const seconds = parts[1];
    return `${minutes} mins ${seconds} secs`;
  }

  onSubmit(f) {
    debugger
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.playlists.UserId = this.UserId;
    if (this.isAddtoplaylistmultiplesong) {
      this.Songdata.forEach((s: any) => {

        this.playlists.SongIds.push(s.songId);
      });
    }
    else {
      this.playlists.SongIds.push(this._homeService.albumSongId);
    }
    this._homeService.AddPlayListMultiplex(this.playlists).subscribe((res: any) => {
      if (res.status == 200) {
        //this.freshplaylist()
        //this.closeForm();
        this.closeBtn.nativeElement.click();
        this.playlists.PlaylistId = undefined;
        this.updateextchecked = false;
        this.createchecked = true;
        if (f) {
          f.submitted = false;
          this.form.resetForm();
        }
        this.toastr.success("Playlist added successfully");
        this.sharedService.triggerMethodLikeFavorite();
        this.freshplaylist();
      }
      else {
        // this.freshplaylist();
        this.toastr.success("Playlist Already Exists");
      }
    })

  }

  freshplaylist() {
    this._homeService.getPlaylist(this.UserId).subscribe((data: any) => {
      this.playListDetails = data.playListDetails;
      this._homeService.getLatestPlaylist(this.playListDetails);
    });
  }

  goToArtist(ArtistId: number) {
    this.showSongDetail = false;
    this.userService.IsRouting(true);
    this.router.navigate(['/newdiscover'], { queryParams: { Id: ArtistId } });
    document.body.style.overflow = 'auto';

  }
  goToAlbum(albumId) {
    this.showSongDetail = false;
    this.userService.IsRouting(true);
    //this.close();
    this.router.navigateByUrl("/discoverAlbum/" + albumId);
    document.body.style.overflow = 'auto';
  }
  getPlayListDetails(songId) {
    this._homeService.albumSongId = songId;
  }

  fetchSongId(i, j) {
    debugger;
    this.playlists.SongId = i;
  }

  likeSongs(songId: string, UserId: string) {
    debugger;
    this._homeService.LikeSong(songId, UserId).subscribe((res: any) => {
      if (res.data.LikeId) {
        this.toastr.success("Song liked.");
        this.getsongbyID(this.SongId, this.UserId);
      }
      else {
        this.getsongbyID(this.SongId, this.UserId);
        this.toastr.success("Song unliked.");
      }
    }
    )
  }

  playSong() {
    if (!this.isSongplay) {
      this._playerService.playSongCallback(true);
      this.isSongplay = true;
    }
    else {
      this._playerService.playSongCallback(false);
      this.isSongplay = false;
    }
  }

  playArtistSong(songs, index) {
    debugger;
    this.playerService.msaapPlaylist = [];
    songs.forEach((song, i) => {
      const trackObj = new Track();
      trackObj.index = i;
      trackObj.image = song.thumbImage;
      trackObj.artistName = song.artistName;
      trackObj.link = song.streamUrl;
      trackObj.title = song.title;
      trackObj.songId = song.songId;
      this.playerService.msaapPlaylist.push(trackObj);
    });
    this._playerService.playSongCallback(true);

    if (this._playerService.repeatSong) {
      this._playerService.repeatSong = false;
    }
    this._playerService.setPlaylist(this.playerService.msaapPlaylist);
    this._playerService.selectATrack(index + 1);
    this._playerService.updateData(this.playerService.msaapPlaylist);
  }

  getLastPlayedSongs(userId) {
    this._songservice.getLastPlayedSongs(userId).subscribe((res: any) => {
      this.lastplayedSong = res.songs;
      console.log('abc', res.songs);
    })
  }

  change() {
    this.showSongDetail = false;
    document.body.style.overflow = 'auto';
    //this.close();
  }

  @ViewChild('UpdateplaylistModalsongs') UpdateplaylistModalsongs!: ElementRef;

  // Handle the event from the app-like-favourite-dropdown component
  openPlaylistModal(album) {
    //console.log(album)
    //debugger
    this._homeService.albumSongId = album.songId;
    this._homeService.likefavalbumId = 0;
    const modalElement: HTMLElement = this.UpdateplaylistModalsongs.nativeElement;
    modalElement.classList.add('show'); // Add show class
    modalElement.style.display = 'block'; // Set display to block
    (window as any).$('body').addClass('modal-open');
  }

  // // Optional: Use this to unblock dropdown if needed
  // unblockDropdown() {
  //   this.isDropdownBlocked = false;
  // }

  closePlaylistModal() {
    const modalElement: HTMLElement = this.UpdateplaylistModalsongs.nativeElement;
    (window as any).$(`#${modalElement.id}`).modal('hide');
    modalElement.style.display = 'none'; // Hide the modal
    setTimeout(() => {
      (window as any).$('body').removeClass('modal-open'); // Remove 'modal-open' class
      (window as any).$('.modal-backdrop').remove(); // Remove modal backdrop
    }, 1000);
  }

  checked() {
    this.createchecked = true;
    this.updateextchecked = false;
  }
  updatechecked() {
    this.updateextchecked = true;
    this.createchecked = false;
    //this.playlist.PlaylistId = undefined;
  }

  onSubmitforupdate() {
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.playlists.UserId = this.userDetails.userId;
    //this.playlist.PlaylistId=this.playlistId;
    this.playlists.SongId = this._homeService.albumSongId;
    //this.playlist.Name=this.allsongs.name
    // this.spinner.show();
    this._homeService.addOrUpdateplaylist(this.playlists).subscribe((res: any) => {
      if (res.status == 200) {
        if (this.playlists.PlaylistId! == 0) {
          //this.freshplaylist();
          this.toastr.success("Playlist added successfully");
          //this.isChecked = [];
          this.playlists.Name = '';
          this.createchecked = true;
          this.updateextchecked = false
          this.form.resetForm();
          this.form.reset();
        } else {
          this.closePlaylistModal();
          // this.freshplaylist();
          this.toastr.success("Playlist updated successfully");
          this.playlists.Name = '';
          //this.isChecked = [];
          this.createchecked = true;
          this.updateextchecked = false
          this.form.resetForm();
          this.form.reset();
        }
        this.sharedService.triggerMethodLikeFavorite();
        this.freshplaylist();
        // this.spinner.hide();
      }
      else {
        this.toastr.success("Playlist Already Exists");
        // this.spinner.hide();

      }
      // this.spinner.hide();
    }, err => {
      this.toastr.error(err)
      // this.spinner.hide();
    });
  }



}