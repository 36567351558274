import { Component, ElementRef, OnInit, ViewChild,HostListener } from '@angular/core';
import { Users } from '../../models/users';
import { HomeService } from '../../Shared/home.service';
import { Role } from '../../models/role';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { Track } from '../../../../Player/ngx-audio-player/src/lib/model/track.model';
import { PlayerService } from 'src/app/Shared/player.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/Shared/user.service';
import { Playlist } from 'src/app/models/playlist';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { SharedService } from 'src/app/Shared/shared.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-leftside-menu',
  templateUrl: './leftside-menu.component.html',
  styleUrls: ['./leftside-menu.component.css']
})
export class LeftsideMenuComponent implements OnInit {
  @ViewChild('myModal') myModal: ElementRef;
  @ViewChild('closeBtn') closeBtn: ElementRef;
  @ViewChild('f') form: NgForm;
  @ViewChild('dropdownContainer') dropdownContainer: ElementRef;
  userDetails = new Users();
  userImage: boolean = true;
  playListDetails: any;
  //Songs Details To play
  songDetails = new Track();
  showHideSettingTab: boolean = false;
  showSettingTab: boolean = false;
  showPlayList: boolean = true;
  shownestingset: boolean = false;
  role: any;
  userLoggedIn: boolean = false;
  artistLoggedIn: boolean = false;
  superAdminLoggedIn: boolean = false;
  path: any;
  playlistid: void;
  index = 0;
  paramId: any;
  playd = [];
  imgURL: any;
  playListLatest: any;
  theme1: boolean = true;
  playlist1 = new Playlist();
  showview: boolean;
  type: any;
  languageCode: any;
  theme: any;
  IsAddlUser: boolean = false;
  isuserlg: boolean = false;
  isactive= false;
  updateextchecked: boolean;
  createchecked: boolean=true;
  isNewHomeActive: boolean=false;
  isDiscoverActive: boolean=false;
  isFavoritesActive: boolean=false;
  homeicons: boolean;
  discovericon: boolean;
  favouriteicon: boolean;
  selectedPlaylistId: number;
  playlisticon: boolean=false;
  showForm: boolean=false;
  footerHeight: number = 60;
  constructor(private _homeService: HomeService,
    private playerService: AudioPlayerService,
    private _playerService: PlayerService, private router: Router,
    public translate: TranslateService, public userService: UserService,
    private route: ActivatedRoute,
    public playlistService: AudioPlayerService,
    private homeService: HomeService,
    private toastr: ToastrService,
    private sharedService: SharedService
  ) {
    this._homeService.allPlayList.subscribe((res: any) => {
      if (res.length == 0) {
        this._homeService.playId = [];
      } else {
        this._homeService.playId = res;
      }
    })
    this.sharedService.triggerMethodsLikeFavorite$.subscribe(()=>{
      this.refreshPlaylistData();
    })
  }

  ngOnInit() {
    debugger    

    this.route.queryParams.subscribe(params => {
      this.selectedPlaylistId = params['Id'];
    });
 const currentUrl = window.location.href;
if (currentUrl.includes('/playlist/')) {
  this.showSettingTab = true;
  const queryParams = new URLSearchParams(window.location.search);
  this.selectedPlaylistId = parseInt(queryParams.get('Id'), 10);
  const playlistId = this.selectedPlaylistId
  const playListName = this._homeService.playlistName
  this.viewallsongs(playlistId,playListName);
}
    if(this.router.url === '/newhome'){
      this.isNewHomeActive=true;
      if(this.isNewHomeActive){
        this.homeicons=true;
        this.playlisticon=false;
      }
    }
   
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      this.isNewHomeActive = event.url === '/newhome';
      this.homeicons = this.isNewHomeActive;
      this.isFavoritesActive = event.url === '/favourites';
      this.favouriteicon=this.isFavoritesActive;
      this.isDiscoverActive = event.url === '/discover';
      this.discovericon=this.isDiscoverActive;
    });
    if(this.router.url === '/discover'){
      this.isDiscoverActive=true;
      if(this.isDiscoverActive){
        this.discovericon=true;
      }
    }
    if(this.router.url === '/favourites'){
      this.isFavoritesActive=true;
      if(this.isFavoritesActive){
        this.favouriteicon=true;
      }
    }
   

    this._homeService.share.subscribe(x =>
      this.playListLatest = x)
    this.playListLatest = this.playListLatest;
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    debugger
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));console.log(this.userDetails);

    this.role = this.userDetails.userType;
    if (this.role == Role.user) {
      this.userLoggedIn = true;
    } else if (this.role === Role.superAdmin) {
      this.superAdminLoggedIn = true;
    } else {
      this.artistLoggedIn = true;
      this.imgURL = this.userDetails["thumbImage"];
    }
    debugger
    if (this.userDetails.image == null) {
      this.userImage = false;
      this._homeService.profileImage = this.userDetails.image;
    }
    else if (this.userDetails.image != null) {
      this.userImage = true;
      this._homeService.profileImage = this.userDetails.image;
    }
    if (this.userDetails["thumbImage"] != null) {
      this.userImage = true;
      this._homeService.profileImage = this.userDetails.image;
    }

    if (this.userLoggedIn) {
      this._homeService.getPlaylist(this.userDetails.userId).subscribe((data: any) => {
        this.playListDetails = data.playListDetails;
        if (this.playListDetails.length >= 5) {
          for (var i = this.index; i < 5; i++) {
            this._homeService.playId[i] = this.playListDetails[i];
          }
        } else if (this.playListDetails.length != 0) {
          for (var i = this.index; i < this.playListDetails.length; i++) {
            this._homeService.playId[i] = this.playListDetails[i];
          }
        } else {
          this._homeService.playId.length = 0;
        }
      })
    }
    this.languageCode = this.userDetails.languageCode;
    this.theme = this.userDetails.themeId;
    let subs = this.userDetails.subscriptionPlans.filter((res: any) => {
      if(res.planCode == "MON_ADD_USER"){
        return res;
      }
    });
    if(this.userDetails.subscriptionPlanId == null || this.userDetails.subscriptionPlanId == "" || this.userDetails.subscriptionPlanId == subs[0].subscriptionPlanId || this.userDetails.subscriptionPlanId == subs[0].subscriptionPlanId.toString()){
      this.IsAddlUser = true;
    } else {
      this.IsAddlUser = false;
    }   
  }
  truncateTitle(title: string, maxLength: number): string {
    if (!title) {
      return "No Title";
    }
    if (title.length <= maxLength) {
      return title;
    }
    return `${title.substring(0, maxLength)}...`;
  }
 
  refreshPlaylistData(){
    this.freshplaylist();
    this._homeService.allPlayList.subscribe((res: any) => {
      if (res.length == 0) {
        this._homeService.playId = [];
      } else {
        this._homeService.playId = res;
      }
    })
  }
  viewallsongs(playlistId: number, playListName: string) {
    this.playlisticon=true;
    this.selectedPlaylistId = playlistId;
    this._homeService.playListArray = [];
    this._homeService.playlistName = playListName;
    this.sharedService.triggerMethod();
    // localStorage.setItem('selectedPlaylistId', this.selectedPlaylistId.toString());
    localStorage.setItem('service', JSON.stringify(this._homeService.playlistName));
    this._homeService.playlist(playlistId, this.userDetails.userId).subscribe((Response: any) => {

      let test = Response.playlistDetails;
      for (var a = 0; a < Response.playlistDetails.length; a++) {
        this._homeService.playListArray.push(Response.playlistDetails[a]);
      }
      this.router.navigate(['/playlist/allplaylistsongs'], { queryParams: { Id: playlistId } });  
    })

  }
  playSong(songs) {

    var getPlayList = this.playerService.getPlaylist();
    var index = getPlayList.findIndex(x => x.link == this.songDetails.link);

    this.songDetails.image = this.path + songs.thumbImage;
    this.songDetails.index = 1;
    this.songDetails.link = songs.streamUrl;
    this.songDetails.title = songs.title;
    this.songDetails.artistName = songs.artistName;
    this._playerService.updateData(this.songDetails);
    if (index == -1) {
      getPlayList.push(this.songDetails);
      this.playerService.setPlaylist(getPlayList);
      this.playerService.selectATrack(getPlayList.length);
    }
    else {
      this.playerService.selectATrack(index);
    }
  }
  showHideSetting() {
   
    debugger;
    if(this._homeService.playId.length==0 && !this.showForm){
      this.toastr.warning("Please add playlist.");
    }
    this.showSettingTab = !this.showSettingTab;
    // this.playlisticon=false;
    this.showPlayList = true;
    this.isNewHomeActive=false;
    this.isDiscoverActive=false;
    this.isFavoritesActive=false;
    this.discovericon=false;
    this.favouriteicon=false;
    if(!this.isNewHomeActive){
      this.homeicons=false
    }
    if(this.showForm){
      this.showSettingTab = false;
    }
    if (this.showSettingTab) {
      this.adjustDropdownHeight();
    }
  }
  showHidePlayList() {
    this.showPlayList = !this.showPlayList;
    this.showSettingTab = true;

  }
  showHidenestingset() {
    this.showHideSettingTab = !this.showHideSettingTab;

  }
  closeAllTabs() {
    this.showSettingTab = true;
    this.showPlayList = true;
  }
  IsCheckLang() {
    if (this.translate.currentLang == 'ar') {
      return true;

    } else if (this.translate.currentLang == 'as') {
      return true;
    }
    else {
      return false;
    }
  }
  Changetheme() {
    if (this.userDetails.themeId == '2') {
      this.theme1 = false;
      return true;

    }
    else {
      this.theme1 = true;
      return false;
    }
  }
  homelogo() {
    this.type = this.router.routerState.snapshot.url;
    if (this.type != '/home') {
      this.router.navigate(['/home']);
    }

  }

  isRouteActive(route: string): boolean {
    return this.router.isActive(route, true);
  }
  // gotoUrl(){
  //   this.userService.IsRouting(true);
  // }

  checked() {
    this.createchecked = true;
    this.updateextchecked = false;
  
  }
  updatechecked() {
    this.updateextchecked = true;
    this.createchecked = false;
   
  }

  onSubmit(f) {
    debugger
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.playlist1.UserId = this.userDetails.userId;
    // this.playlist1.SongId = this.playlistService.getcurrentsongid;

    console.log(this.playlist1);
    this.homeService.addOrUpdateplaylist(this.playlist1).subscribe((res: any) => {
      console.log(res.status)
        if (res.status == 200) {
            this.freshplaylist()
            //this.closeForm();
            this.closeBtn.nativeElement.click();
            this.playlist1.PlaylistId = undefined;
            this.updateextchecked = false;
            this.createchecked = true;
            // f.submitted = false;
            // this.toastr.success("Playlist added successfully");
            // this.form.resetForm();
            if (f) {
              f.submitted = false;
              this.form.resetForm();
            }
            this.playlist1.Name = null;
            this.form.resetForm();
            this.toastr.success("Playlist added successfully");
        }
        else {
            this.freshplaylist();
            this.toastr.success("Playlist Already Exists");
        }
    })
    this.showForm=false;
}

freshplaylist() {
  this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
  this.playlist1.UserId = this.userDetails.userId;
  this.homeService.getPlaylist(this.playlist1.UserId).subscribe((data: any) => {
      this.playListDetails = data.playListDetails;
      this.paramId = this.route.snapshot.queryParams["Id"];
      let playlist = data.playListDetails.filter(x => x.playlistId == this.paramId);
      this.homeService.allPlayList.next(this.playListDetails);

      // this.homeService.playlist(this.paramId, this.playlist1.UserId).subscribe((Response: any) => {
      //     let songs = playlist[0].songs;
      //     songs = Response.playlistDetails;
      //     this.homeService.playListSongs.next(songs);
      // })

  })
}

@HostListener('window:popstate', ['$event'])
onPopState(routePath) {
const currentUrl = window.location.href;
if (currentUrl.includes('/playlist/')) {
 this.showSettingTab = true;
  const queryParams = new URLSearchParams(window.location.search);
  this.selectedPlaylistId = parseInt(queryParams.get('Id'), 10);
  const playlistId = this.selectedPlaylistId
  const playListName = this._homeService.playlistName
  this.viewallsongs(playlistId,playListName);


}else{
  this.onMenuChange(routePath) 
}
}

onMenuChange(routePath){
  this.selectedPlaylistId=null;
  if(routePath==='/newhome'){
    this.isNewHomeActive=true;
    this.isFavoritesActive=false;
    this.isDiscoverActive=false;
    this.homeicons=true;
    this.discovericon=false;
    this.favouriteicon=false;
  }else if(routePath==='/discover'){
    this.isDiscoverActive=true;
    this.isFavoritesActive=false;
    this.isNewHomeActive=false;
    this.homeicons=false;
    this.discovericon=true;
    this.favouriteicon=false;
  }else if(routePath==='/favourites'){
    this.isDiscoverActive=false;
    this.isFavoritesActive=true;
    this.isNewHomeActive=false;   
    this.homeicons=false;
    this.favouriteicon=true;
    this.discovericon=false;
  }
  this.showSettingTab = false;
  this.playlisticon=false;
  this.showPlayList = false;
  this.sharedService.triggerMethod();
  this.router.navigate([routePath]);
}

onAddNewIconClick() {
  this.showForm = true;
  this.playlist1.Name = null;
}

closePopup() {
this.showForm=false;
this.playlist1.Name = null;
  if (this.myModal) {
    const modal: any = this.myModal.nativeElement;
    if (modal) {
      modal.modal('hide'); 
    }
  }

}

@HostListener('window:resize')
onWindowResize() {
  if (this.showSettingTab) {
    this.adjustDropdownHeight();
  }
}



adjustDropdownHeight() {
  setTimeout(() => {
    if (this.dropdownContainer) {
      const dropdownElement = this.dropdownContainer.nativeElement;
      const windowHeight = window.innerHeight;
      const dropdownRect = dropdownElement.getBoundingClientRect();
      const availableHeight = windowHeight - dropdownRect.top - this.footerHeight - 20; // Adjust the 20px padding as needed

      dropdownElement.style.maxHeight = `${availableHeight}px`;
    }
  });
}


}
