import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { LoadScriptService } from 'src/app/loadScript.service';
import { HomeService } from '../../Shared/home.service';
import { PlayerService } from '../../Shared/player.service';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { Track } from '../../../../Player/ngx-audio-player/src/lib/model/track.model';
import { Playlist } from 'src/app/models/playlist';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { UserService } from 'src/app/Shared/user.service';
@Component({
  selector: 'app-favplaylist',
  templateUrl: './favplaylist.component.html',
  styleUrls: ['./favplaylist.component.css']
})
export class FavplaylistComponent implements OnInit {
  @ViewChild('closeBtn') closeBtn: ElementRef;
  favitems: any;
  playListDetails: any;
  path: any;
  userDetails: any;
  playlist = new Playlist();
  userId: number;
  playsongslist: any;
  languageCode: any;
  theme: any;
  constructor(private loadScript: LoadScriptService, private homeService: HomeService,
    private _playerService: PlayerService, private router: Router, private spinner: NgxSpinnerService,
    public location: Location, public userService: UserService, private _toastr: ToastrService) { }

  ngOnInit() {
    this.loadScript.getServiceBaseUrl();
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.userId = this.userDetails.userId;
    this.getfavouriteplaylist();
    this.languageCode = this.userDetails.languageCode;

    this.theme = this.userDetails.themeId

  }
  getfavouriteplaylist() {
    this.homeService.getPlaylist(this.userId).subscribe((data: any) => {
      this.playListDetails = data.playListDetails;
      this.playsongslist = this.playListDetails[0].songs

      this.homeService.playId = data.playListDetails;
      this.path = data.path;
      this.spinner.hide();
    });
  }
  deleteplaylist(PlaylistId) {
    if (confirm('Are you sure you want to delete?')) {
      this.homeService.deleteplaylist(PlaylistId).subscribe((res: any) => {
        this.getfavouriteplaylist();
      })
    }
  }
  onSubmit() {
    this.homeService.renameplaylist(this.playlist.PlaylistId, this.userId, this.playlist.Name).subscribe((res: any) => {
      if (res.status == 200) {
        this.closeBtn.nativeElement.click();
        this._toastr.success('Playlist Name Changed Successfully');
        this.getfavouriteplaylist();
      } else {
        this._toastr.warning("Playlist Name Already Exists");
      }
    })
  }
  fetchplaylistname(i, j) {
    this.playlist.Name = i;
    this.playlist.PlaylistId = j;
  }
  viewallplaylistsongs(playlistId: number, playListName: string) {
    this.homeService.playListArray = [];
    this.homeService.playlistName = playListName;
    this.homeService.playlist(playlistId, this.userDetails.userId).subscribe((Response: any) => {

      let test = Response.playlistDetails;
      for (var a = 0; a < Response.playlistDetails.length; a++) {
        this.homeService.playListArray.push(Response.playlistDetails[a]);
      }
      this.router.navigate(['/playlist/allplaylistsongs'], { queryParams: { Id: playlistId } });
    })

  }
  playSong(songs, index) {

    this._playerService.playSong(songs, this.path, index);
  }

  back() {
    this.location.back();
  }
}
