import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ConfigService {

  private readonly startdate = 1900;

  private readonly serviceBase = 'https://localhost:44304/';
  //private readonly serviceBase = 'https://toby.ascdevs2.com/';
  //private readonly serviceBase = 'http://82.165.59.169/';
  //private readonly serviceBase = 'https://uat.toby.audio/';


  constructor() { }

  getServiceBaseUrl() {
    return this.serviceBase;
  }
  getstartDate() {
    return this.startdate;
  }
}
