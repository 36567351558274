import { Component, OnInit, ViewChild, ElementRef, HostListener, ChangeDetectorRef, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { LoadScriptService } from '../loadScript.service';
import { ArtistsService } from '../Shared/artists.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { Location } from '@angular/common';
import { UserService } from '../Shared/user.service';
import { Users } from '../models/users';
import { AlbumService } from '../Shared/album.service';
import { SongsService } from '../Shared/songs.service';
import { Pagination } from '../models/pagination';
import { PaginationServiceForSongs } from '../Shared/paginationserviceForSongs.service';
import { Subject, Subscription, timer } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { PlayerService } from '../Shared/player.service';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
@Component({
  selector: 'app-discover',
  templateUrl: './discover.component.html',
  styleUrls: ['./discover.component.css']
})
export class DiscoverComponent implements OnInit, AfterViewInit {
  private destroy$: Subject<void> = new Subject<void>(); // Subject for component destruction
  private scrollEvent$: Subject<void> = new Subject<void>(); // Subject for scrolling event
  loaders = Array(30).fill(0);
  GetArtistDetails = [];
  GetArtistDetailsForMatPills = [];
  AlbumsForMatPills = [];
  SongsForMatPills = [];
  songs = [];
  albums = [];

  SearchText = "";
  pageIndex = 1;
  pageSize = 1000;
  totalRows: number = 0;

  alphabeticArray = [];
  sortArray = [];
  searchedArtists = [];
  clickedAlbhabet: boolean[] = [];
  isArtist = true;
  isSong = false;
  isAlbum = false;
  isSearch = false;
  isPillsSelected = false;
  serarchArtist: any = null;
  userDetails = new Users();
  allAlbum: any;
  alldata: any;
  topArtist: any;
  MPSongs: any;
  featuredSong: any;
  discography: any;
  songList: any;
  topArtistimg: any;
  discoverArtist: any;
  discoverAlbum: any;
  discoverSongs: any;
  songdataList: any = [];
  nextSonglist: any = [];
  data: any[];
  pageField = [];
  Checkrecords: boolean = false;
  prev: boolean = false;
  next: boolean = false;
  activePage: boolean[] = [];
  pageNumber: any;
  totalPage: number = 0;
  tempPageIndex: number;
  arrow: boolean;
  pageNumberPerPage = 0;
  pageNumberShow = Pagination.displayNoOfPagesPerPage;
  temppage: number = 0;
  disabledNextBtn: boolean;
  disabledPrevBtn: boolean = true;
  exactPageList: any;
  prevtrue: boolean;
  nexttrue: boolean;
  //currentPage = 1;
  showNoOfCurrentPage: any = 1;
  showPageOnlyOntabsChange: boolean = true;
  paginationData: number;
  searchname: any;
  noDataFound: boolean = false;
  songid: any;
  favId: any;
  likeId: any;
  throttle = 1;
  scrollDistance = 1;
  busyLoading: boolean = false;


  @ViewChildren('rows') rows: QueryList<ElementRef>; // QueryList to get references to rows

  // @ViewChild('sectionNeedToScroll') sectionNeedToScroll: ElementRef;
  temp: any;
  previousAlphabet: any = 'a';
  isScrolled = false;
  scrollTable: boolean = false;
  callalphaColorIndex: any;
  SelectedId: number = 0;
  songIsthere: boolean;
  path: any;
  IsActiveSongId: any;
  isloader: boolean = false;
  busyInitialLoading: boolean = false;
  index: number;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    // const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    // //console.log('chk scroll', scrollPosition);
    // this.isScrolled = scrollPosition > 100;
    // this.scrollTable = scrollPosition > 100; // Adjust 100 to the scroll position you desire
    this.checkVisibleElement();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    //this.updateDisplayCount(event.target.innerWidth);
    console.log('chk width', event.target.innerWidth);

  }

  // @ViewChild('image')
  // public img: ElementRef;

  // ngAfterViewInit() {
  //   if (this.img && this.img.nativeElement) {
  //     const image: HTMLImageElement = this.img.nativeElement;

  //     image.onload = () => {
  //       if (this.index && this.GetArtistDetails[this.index]) {
  //         this.GetArtistDetails[this.index].loaded = true; 
  //         this.cdr.detectChanges();
  //       }
  //     };
  //   }
  // }

  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   if (!this.isCalledOnResize && (event.target.innerWidth >= 4098 && event.target.innerWidth <=7200)) 
  //   {
  //     this.isCalledOnResize=true;
  //     this.pageSize += 10;
  //     if (this.isArtist) {
  //       this.GetArtistdata(this.SearchText, this.pageIndex, this.pageSize);
  //     }
  //     else if (this.isSong) {
  //       this.GetAllSongsdata(this.SearchText, this.pageIndex, this.pageSize);
  //     }
  //     else if (this.isAlbum) {
  //       this.GetAllAlbumData(this.SearchText, this.pageIndex, this.pageSize);
  //     }
  //   }
  // }

  constructor(private router: Router,
    private loadScript: LoadScriptService,
    private _artistService: ArtistsService,
    private _albumService: AlbumService,
    private _songService: SongsService,
    private spinner: NgxSpinnerService,
    public location: Location,
    public userService: UserService,
    public paginationService: PaginationServiceForSongs,
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
    private route: ActivatedRoute,
    private _playerService: PlayerService,
    public playerService: AudioPlayerService,) {
    this.playerService.getIsblogorCountryScreen().subscribe(value => {
      //console.info('san: ' + value);
      this.IsActiveSongId = value;
    });
  }

  private timerSubscription!: Subscription;


  ngOnInit() {
    //Service to load js file runtime
    // this.spinner.show();
    window.scrollTo(0, 0);
    this.loadScript.getServiceBaseUrl();
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    //console.log(this.isArtist);
    this.genCharArray('a', 'z');
    this.GetInitialArtistdata(this.SearchText, this.pageIndex, this.pageSize);
    // this.callalphaColor(25);
    // this.callalphaColor(0);
    // debugger;
    // if (!this.isCalledOnResize && (window.innerWidth >= 4098 && window.innerWidth <= 7200)) {
    //   this.isCalledOnResize = true;
    //   this.pageIndex++;
    //   this.pageSize = 10;
    //   if (this.isArtist) {
    //     this.GetArtistdata(this.SearchText, this.pageIndex, this.pageSize);
    //   }
    //   // else if (this.isSong) {
    //   //   this.GetAllSongsdata(this.SearchText, this.pageIndex, this.pageSize);
    //   // }
    //   // else if (this.isAlbum) {
    //   //   this.GetAllAlbumData(this.SearchText, this.pageIndex, this.pageSize);
    //   // }
    // }

    // this.GetArtistDetailsNew('a');
    // this.GetAllAlbums();

    this.paginationService.temppage = this.paginationService.temppage;
    if (this.paginationService.currentpageee != null || this.paginationService.currentpageee != undefined) {
      this.searchname = this._songService.searchText;
      this.paginationService.currentPage = this.paginationService.currentpageee
      this.activePage[this.paginationService.indexxx] = true;
    }
    else {
      this.searchname = this._songService.searchText;
      this.paginationService.currentPage = this.paginationService.currentPage;
      this.activePage[0] = true;
    }
    this.paginationService.showPageOnlyOntabsChange = this.paginationService.showPageOnlyOntabsChange;

    if (this.searchname !== "") {
      this.paginationService.temppage = 0
      this.paginationService.currentPage = 1;
      this.activePage[0] = true;
    }
    this.pageIndex = this.paginationService.currentPage;
    this.searchname = this._songService.searchText;

    this.scrollEvent$
      .pipe(takeUntil(this.destroy$)) // Unsubscribe when component is destroyed
      .subscribe(() => {
        // Load more data when scrolling event occurs
        this.loadMoreData();
      });
    // this.spinner.hide()
    // const source = timer(0, 2000);

    // Subscribe to the timer
    // this.timerSubscription = source.subscribe(val => {
    //   this.pageSize += 100;
    //   this.GetArtistdata(this.SearchText, this.pageIndex, this.pageSize);
    //   // Increase the page size by 1 on each tick
    //   console.log('Page size updated to:', this.pageSize);
    // });
  }


  ngAfterViewInit() {
    this.checkVisibleElement();
  }



  checkVisibleElement() {
    const header = document.querySelector('thead .tableheader') as HTMLElement;
    const headerRect = header.getBoundingClientRect();

    let anchors ;
    if(this.isArtist){
      anchors= document.querySelectorAll('tbody .artistname .anchor');
    }else if(this.isAlbum){
      anchors= document.querySelectorAll('tbody .tbl-title .anchor');
    }else{
      return;
    }
    const anchorArray = Array.from(anchors) as HTMLElement[];

    for (let anchor of anchorArray) {
      const rect = anchor.getBoundingClientRect();
      let characterInVisibility = '';
      if (rect.top < headerRect.bottom && rect.bottom > headerRect.top) {
        characterInVisibility = anchor.textContent.charAt(0); // Perform any action with the full name
        const CharacterIndex = this.alphabeticArray.findIndex((e) => e.toLowerCase() === characterInVisibility.toLowerCase());
        (CharacterIndex !== -1)
        { this.alphaColor(CharacterIndex); }
        break; // Stop after finding the first intersecting element
      }
    }
  }

  loadMoreData() {
    if (this.isArtist) {
      this.GetArtistdata(this.SearchText, this.pageIndex, this.pageSize);
    }
    else if (this.isSong) {
      this.GetAllSongsdata(this.SearchText, this.pageIndex, this.pageSize);
    }
    else if (this.isAlbum) {
      this.GetAllAlbumData(this.SearchText, this.pageIndex, this.pageSize);
    }
  }

  genCharArray(charA, charZ) {
    var self = this;
    var i = charA.charCodeAt(0), j = charZ.charCodeAt(0);
    for (; i <= j; ++i) {
      self.alphabeticArray.push(String.fromCharCode(i));
    }
  }

  GetArtistDetailsNew(alphLetter) {
    const artists = this.GetArtistDetails.slice(0, 99);
    const result = this.GetArtistDetails.filter(x => x.fullName.toLowerCase().startsWith(alphLetter));
    console.log(result)
    // this.getPageNumber(result);
    return result;
  }

  GetMatPillsDetails(alphLetter) {
    let result;
    if (this.isArtist) {
      result = this.GetArtistDetailsForMatPills.filter(x => x.fullName.toLowerCase().startsWith(alphLetter));
    }
    else if (this.isSong) {
      result = this.SongsForMatPills.filter(x =>
        x.title.toLowerCase().startsWith(alphLetter));
    }
    else if (this.isAlbum) {
      result = this.AlbumsForMatPills.filter(x => x.title.toLowerCase().startsWith(alphLetter));
    }
    return result;
  }

  GetArtistdata(SearchText: string, pageIndex: number, pageSize: number) {
    let UserId = Number(this.userDetails["userId"]);
    if (this.busyLoading) {
      return;
    }

    this.busyLoading = true;
    //this.spinner.show(); // Show spinner before API call

    this._artistService.getPaginatedArtists(SearchText, pageIndex, pageSize, UserId)
      .pipe(
        takeUntil(this.destroy$), // Unsubscribe when component is destroyed
        finalize(() => {
          //this.spinner.hide(); // Hide spinner after response or error
          this.busyLoading = false;
        })
      )
      .subscribe((response: any) => {
        if (response.isSuccess && (this.GetArtistDetails.length !== response.artist.totalRows || this.totalRows === 0)) {
          this.GetArtistDetails.push(...response.artist.artists);
          // this.GetArtistDetails.push(...response.artist.artists.map((x)=>{
          //   return ({...x,loaded:false});
          // }));
          this.totalPage = response.artist.totalPages;
          this.pageIndex = response.artist.currentPage;
          this.pageSize = response.artist.pageSize;
          this.totalRows = response.artist.totalRows;
        } else {
          console.log("No records found");
        }
      },
        (error: any) => {
          console.error("Error occurred while fetching artists: ", error);
        });
  }


  // imageLoaded(index: number) {
  //   this.index=index;
  // }

  GetInitialArtistdata(SearchText: string, pageIndex: number, pageSize: number) {
    let UserId = Number(this.userDetails["userId"]);
    if (this.busyInitialLoading) {
      return;
    }

    this.busyInitialLoading = true;
    //this.spinner.show(); // Show spinner before API call

    this._artistService.getPaginatedArtists(SearchText, pageIndex, pageSize, UserId)
      .pipe(
        takeUntil(this.destroy$), // Unsubscribe when component is destroyed
        finalize(() => {
          //this.spinner.hide(); // Hide spinner after response or error
          this.busyInitialLoading = false;
        })
      )
      .subscribe((response: any) => {
        if (response.isSuccess && (this.GetArtistDetails.length !== response.artist.totalRows || this.totalRows === 0)) {
          this.GetArtistDetails.push(...response.artist.artists);
          // this.GetArtistDetails.push(...response.artist.artists.map((x)=>{
          //   return ({...x,loaded:false});
          // }));
          this.totalPage = response.artist.totalPages;
          this.pageIndex = response.artist.currentPage;
          this.pageSize = response.artist.pageSize;
          this.totalRows = response.artist.totalRows;
        } else {
          console.log("No records found");
        }
      },
        (error: any) => {
          console.error("Error occurred while fetching artists: ", error);
        });
  }


  getArtistDataByArtistId() {
    let UserId = Number(this.userDetails["userId"]);
    const selectedID = Number(this.SelectedId);

    // Create a map to store artist data by artist ID
    const artistMap = {};

    // Populate the map with artist data
    this.GetArtistDetails.forEach((artist, index) => {
      artistMap[artist.artistId] = { index, data: artist };
    });

    if (selectedID) {
      this._artistService.getArtistDataByArtistIdAndUserId(selectedID, UserId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (response: any) => {
            // Check if the selected artist ID exists in the map
            if (artistMap[selectedID]) {
              const { index } = artistMap[selectedID];
              this.GetArtistDetails[index] = response[0];
              this.cdr.detectChanges();
            }
          },
          (error: any) => {
            console.error("Error occurred while fetching specific record: ", error);
          }
        );
    }
  }


  getSongDataBySongId() {
    let UserId = Number(this.userDetails["userId"]);
    const selectedID = Number(this.SelectedId);

    let songIndexMap = {};

    // if (!this.isPillsSelected) {
    this.songs.forEach((song, index) => {
      songIndexMap[song.songId] = { index, data: song };
    });
    // }
    // else {
    //   this.SongsForMatPills.forEach((song, index) => {
    //     songIndexMap.set(song.songId, index);
    //   });
    // }

    if (selectedID) {
      this._songService.getSongBySongIdandUserId(selectedID, UserId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (response: any) => {
            if (songIndexMap[selectedID]) {
              const { index } = songIndexMap[selectedID];
              // if (!this.isPillsSelected) {
              this.songs[index] = response[0];
              // } else {
              //   this.SongsForMatPills[index] = response[0];
              // }
              this.cdr.detectChanges();
            }
          },
          (error: any) => {
            console.error("Error occurred while fetching specific record: ", error);
          }
        );
    }
  }

  getAlbumDataByAlbumId() {
    let UserId = Number(this.userDetails["userId"]);
    const selectedID = Number(this.SelectedId);

    let albumIndexMap = {};
    // if (!this.isPillsSelected) {
    this.albums.forEach((album, index) => {
      albumIndexMap[album.albumId] = { index, data: album };
    });
    // } else {
    //   this.AlbumsForMatPills.forEach((album, index) => {
    //     albumIndexMap.set(album.albumId, index);
    //   });
    // }

    if (selectedID) {
      this._albumService.GetAlbumByAlbumIdAndUserId(selectedID, UserId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (response: any) => {
            if (albumIndexMap[selectedID]) {
              const { index } = albumIndexMap[selectedID];
              // if (!this.isPillsSelected) {
              this.albums[index] = response[0];
              // } else {
              //   this.AlbumsForMatPills[index] = response[0];
              // }
              this.cdr.detectChanges();
            }
          },
          (error: any) => {
            console.error("Error occurred while fetching specific record: ", error);
          }
        );
    }
  }

  getIdSelected(Id: number) {
    this.SelectedId = Id;
  }


  GetArtistdataforMatPills(SearchText: string, pageIndex: number, pageSize: number) {
    let UserId = Number(this.userDetails["userId"]);
    if (this.busyLoading) {
      return;
    }

    this.busyLoading = true;
    this.spinner.show();

    this._artistService.getPaginatedArtists(SearchText, pageIndex, pageSize, UserId).subscribe(
      (response: any) => {
        if (response.isSuccess) {
          this.GetArtistDetailsForMatPills.push(...response.artist.artists);
          this.totalPage = response.artist.totalPages;
          this.pageIndex = response.artist.currentPage;
          this.pageSize = response.artist.pageSize;
          this.totalRows = response.artist.totalRows;
        } else {
          console.log("No records found");
        }
        this.spinner.hide();
        this.busyLoading = false;
      },
      (error: any) => {
        console.error("Error occurred while fetching artists: ", error);
        this.spinner.hide();
        this.busyLoading = false;
      }
    );
  }

  GetSongsdataforMatPills(SearchText: string, pageIndex: number, pageSize: number) {
    let UserId = Number(this.userDetails["userId"]);
    if (this.busyLoading) {
      return;
    }

    this.busyLoading = true;
    //this.spinner.show();

    setTimeout(() => {
      this._songService.getPaginatedSongs(SearchText, pageIndex, pageSize, UserId).subscribe(
        (response: any) => {
          if (response.isSuccess) {
            this.SongsForMatPills.push(...response.songs.songsList);
            console.log("this.SongsForMatPills: ", this.SongsForMatPills);
            this.totalPage = response.songs.totalPages;
            this.pageIndex = response.songs.currentPage;
            this.pageSize = response.songs.pageSize;
            this.totalRows = response.songs.totalRows;
          } else {
            console.log("No records found");
          }
          //this.spinner.hide();

        },
        (error: any) => {
          console.error("Error occurred while fetching songs: ", error);
          //this.spinner.hide();
        }
      );

      this.busyLoading = false;
    }, 2000);
  }

  // onScroll() {debugger;
  //   const scrollHeight = document.documentElement.scrollHeight;
  //   const scrollTop = document.documentElement.scrollTop;
  //   const clientHeight = document.documentElement.clientHeight;

  //   if (scrollTop + clientHeight >= scrollHeight && this.pageIndex < this.totalPage) {
  //     this.pageIndex++;
  //     this.GetArtistdata(this.SearchText,this.pageIndex,this.pageSize);
  //   }
  // }

  onScroll() {
    debugger;
    if (this.totalPage !== this.pageIndex) {
      this.pageIndex++;
      this.pageSize = 20;
      // if (this.isPillsSelected) {
      //   this.isPillsSelected = false;
      // }

      this.scrollEvent$.next(); // Emit scrolling event}
    }
  }

  GetAlbumData(alphLetter) {
    let album = this.albums.slice(0, 99);//first 100 albums
    const result = album.filter(x => x.title.toLowerCase().startsWith(alphLetter));
    return result;
  }

  GetAllAlbums() {
    this.spinner.show();
    this._albumService.getalbum().subscribe((album: any) => {
      this.albums = album.albums;
      console.log("album", this.albums);
      this.spinner.hide();
      this.getPageNumber(this.albums);
      console.log(album, this.albums);
    })

  }
  GetSongData(alphLetter) {
    let song = this.songs.slice(0, 200); // first 200 songs
    console.log(this.songs, song)
    //this.spinner.show();
    const result = song.filter(x =>
      x.title.toLowerCase().startsWith(alphLetter));
    //this.spinner.hide();
    console.log(result);
    return result;

  }

  GetAllSongs() {
    debugger
    //this.spinner.show();
    this._songService.getSongs('', this.userDetails.userId).subscribe((songs: any) => {
      this.songs = songs.songs;
      //this.spinner.hide();
      console.log('song', this.songs);
      this.getPageNumber(this.songs)
    })

  }

  GetAllSongsdata(SearchText: string, pageIndex: number, pageSize: number) {
    let UserId = Number(this.userDetails["userId"]);
    if (this.busyLoading) {
      return;
    }

    this.busyLoading = true;
    //this.spinner.show();

    this._songService.getPaginatedSongs(SearchText, pageIndex, pageSize, UserId).pipe(takeUntil(this.destroy$),
      finalize(() => {
        // this.spinner.hide(); // Hide spinner after response or error
        this.busyLoading = false;
      })).subscribe(
        (response: any) => {
          if (response.isSuccess && (this.songs.length !== response.songs.totalRows || this.totalRows === 0)) {
            this.songs.push(...response.songs.songsList);
            this.sortSongs();
            this.totalPage = response.songs.totalPages;
            this.pageIndex = response.songs.currentPage;
            this.pageSize = response.songs.pageSize;
            this.totalRows = response.songs.totalRows;
          } else {
            console.log("No records found");
          }

        },
        (error: any) => {
          console.error("Error occurred while fetching songs: ", error);
        }
      );
  }
  sortSongs(): void {
    const filteredSongs = this.songs.filter(song => /^[A-Za-z]/.test(song.title));

    // Sort the filtered songs alphabetically by title
   this.songs = filteredSongs.sort((a, b) => a.title.localeCompare(b.title));

  }

  GetAllSongsInitialdata(SearchText: string, pageIndex: number, pageSize: number) {
    let UserId = Number(this.userDetails["userId"]);
    if (this.busyInitialLoading) {
      return;
    }

    this.busyInitialLoading = true;
    //this.spinner.show();

    this._songService.getPaginatedSongs(SearchText, pageIndex, pageSize, UserId).pipe(takeUntil(this.destroy$),
      finalize(() => {
        // this.spinner.hide(); // Hide spinner after response or error
        this.busyInitialLoading = false;
      })).subscribe(
        (response: any) => {
          if (response.isSuccess && (this.songs.length !== response.songs.totalRows || this.totalRows === 0)) {
            this.songs.push(...response.songs.songsList);
            this.sortSongs();
            this.totalPage = response.songs.totalPages;
            this.pageIndex = response.songs.currentPage;
            this.pageSize = response.songs.pageSize;
            this.totalRows = response.songs.totalRows;
          } else {
            console.log("No records found");
          }

        },
        (error: any) => {
          console.error("Error occurred while fetching songs: ", error);
        }
      );
  }

  // alphabet(alphabet, i) {
  //   this.clickedAlbhabet = [];
  //   this.clickedAlbhabet[i] = true;
  //   let tempalphabet = this.sectionNeedToScroll.nativeElement.classList[1].split('-')[0].replace(this.previousAlphabet, alphabet);
  //   this.previousAlphabet = alphabet;
  //   this.sectionNeedToScroll.nativeElement.classList[1].replace(tempalphabet + "-alphabet")
  //   this.sectionNeedToScroll.nativeElement.scrollIntoView({ behavior: 'auto' })
  //   console.log(tempalphabet, this.sectionNeedToScroll.nativeElement.classList[1].replace(tempalphabet + "-alphabet"))

  //   //  var elmnt = document.getElementById("indexs");
  //   // elmnt.scrollIntoView();
  // }
  alphaColor(i) {
    debugger
    const ele = document.getElementsByClassName('alpha');
    ele[i].setAttribute("style", "color:#ff9d00")
    for (let j = 0; j < ele.length; j++) {
      if (j != i) {
        ele[j].setAttribute("style", "color: #e2dddd")
      }
    }

  }

  resetAlphaColor(){
    const ele = document.getElementsByClassName('alpha');
    const arr = Array.from(ele) as  HTMLElement[];
    arr.forEach(element => {
      element.setAttribute("style","color:white")
    });
  }

  // callalphaColor(i) {
  //   debugger
  //   // var links = document.getElementById(i);
  //   // links.click();
  //   let AlphabeticArray = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
  //   this.isPillsSelected = true;
  //   this.callalphaColorIndex = i;
  //   if (this.isArtist) {
  //     this.GetArtistDetailsForMatPills = [];
  //     this.GetArtistdataforMatPills(AlphabeticArray[i], 1, 50);
  //   }
  //   else if (this.isSong) {
  //     this.SongsForMatPills = [];
  //     this.GetSongsdataforMatPills(AlphabeticArray[i], 1, 50);
  //   }
  //   else if (this.isAlbum) {
  //     this.AlbumsForMatPills = [];
  //     this.GetAlbumsforMatPills(AlphabeticArray[i], 1, 50);
  //   }
  // }

  callalphaColor(i: number) {
    // Scroll to the selected letter's initial record
    const rowIndex = this.getInitialRecordIndex(i);
    this.alphaColor(i);
    if (rowIndex !== null) {
      this.scrollToRow(rowIndex);
    }
  }

  getInitialRecordIndex(index: number): number | null {
    const letter = this.alphabeticArray[index];
    if (this.isArtist) {
      const startIndex = this.GetArtistDetails.findIndex(row => row.fullName.toLowerCase().startsWith(letter));
      return startIndex !== -1 ? startIndex : null;
    }
    if (this.isAlbum) {
      const startIndex = this.albums.findIndex(row => row.title.toLowerCase().startsWith(letter));
      return startIndex !== -1 ? startIndex : null;
    }

  }

  scrollToRow(rowIndex: number) {
    if (this.rows && this.rows.length > rowIndex) {
      const rowElement = this.rows.toArray()[rowIndex].nativeElement;
      rowElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  Artistprofile(ArtistId: number) {
    localStorage.removeItem("InvoiceID");
    let val = ArtistId;
    localStorage.answer = JSON.stringify(val);
    let saved = JSON.parse(localStorage.answer);
    localStorage.setItem("InvoiceID", saved);
    //this.router.navigate(['/artist/artistprofile'], { queryParams: { Id: ArtistId } });
    this.router.navigate(['/newdiscover'], { queryParams: { Id: ArtistId } });
  }

  AlbumPage(AlbumId: number) {
    console.log(AlbumId);
    this.router.navigate(['/discoverAlbum/'], { queryParams: { Id: AlbumId } });
  }

  back() {
    this.location.back();
  }
  all(id: any, index: number) {
    const ele = document.getElementsByClassName('alpha');
    for (let j = 0; j < ele.length; j++) {
      ele[j].setAttribute("style", "color: #e2dddd")
    }
    for (let i = 0; i < this.allChips.length; i++) {
      this.allChips[i].isActive = false
    }
    this.allChips[index].isActive = true;
    console.log(id, index, this.allChips);
    this.totalPage = 0;
    debugger;
    // for resetting to [] of pills A-Z
    this.resetAlphaColor();
    if (id == 1 && !this.isArtist) {
      this.SelectedId = 0;
      this.SearchText = "";
      this.pageIndex = 1;
      this.pageSize = 1000;
      this.isArtist = true;
      this.isSong = false;
      this.isAlbum = false;

      this.isPillsSelected = false;
      this.GetArtistDetails = [];
      this.GetArtistDetailsForMatPills = [];
      //this.serarchArtist = null;
      // this.GetArtistdata(this.SearchText, this.pageIndex, this.pageSize);
      this.GetInitialArtistdata(this.SearchText, this.pageIndex, this.pageSize);
      // this.GetArtistdata("", 0, 0);
      //this.GetArtistdata(this.SearchText, this.pageIndex, this.pageSize);
    }
    if (id == 2 && !this.isAlbum) {
      this.SelectedId = 0;
      this.SearchText = "";
      this.pageIndex = 1;
      this.pageSize = 1000;
      this.isSong = false;
      this.isArtist = false;
      this.isAlbum = true;
      this.isPillsSelected = false;

      this.albums = [];
      this.AlbumsForMatPills = [];
      // this.GetAllAlbums();
      // this.GetAllAlbumsByUserId();
      // this.GetAllAlbumData(this.SearchText, this.pageIndex, this.pageSize);
      this.GetAllAlbumInitialData(this.SearchText, this.pageIndex, this.pageSize);
    }
    if (id == 3 && !this.isSong) {
      this.SelectedId = 0;
      this.SearchText = "";
      this.pageIndex = 1;
      this.pageSize = 30;
      this.isSong = true;
      this.isArtist = false;
      this.isAlbum = false;

      this.isPillsSelected = false;
      // this.GetAllSongs();
      this.songs = []
      this.SongsForMatPills = [];
      // this.GetAllSongsdata(this.SearchText, this.pageIndex, this.pageSize);
      this.GetAllSongsInitialdata(this.SearchText, this.pageIndex, this.pageSize);
    }

    window.scrollTo(0, 0);
  }

  GetAllAlbumsByUserId() {
    let UserId = Number(this.userDetails["userId"]);
    this.spinner.show();
    this._albumService.GetAlbumsbyUserId(UserId).subscribe((album: any) => {
      this.albums = album.albums;
      this.spinner.hide();
      this.getPageNumber(this.albums);
      console.log(album, this.albums);
    })
  }

  GetAllAlbumData(SearchText: string, pageIndex: number, pageSize: number) {
    let UserId = Number(this.userDetails["userId"]);
    if (this.busyLoading) {
      return;
    }

    this.busyLoading = true;
    // this.spinner.show();
    this._albumService.GetPaginatedAlbums(SearchText, pageIndex, pageSize, UserId).pipe(takeUntil(this.destroy$),
      finalize(() => {
        // this.spinner.hide(); // Hide spinner after response or error
        this.busyLoading = false;
      })).subscribe(
        (response: any) => {
          if (response.isSuccess && (this.albums.length !== response.albums.totalRows || this.totalRows === 0)) {
            this.albums.push(...response.albums.albumsList);
            this.totalPage = response.albums.totalPages;
            this.pageIndex = response.albums.currentPage;
            this.pageSize = response.albums.pageSize;
            this.totalRows = response.albums.totalRows;
          } else {
            console.log("No records found");
          }
        },
        (error: any) => {
          console.error("Error occurred while fetching albums: ", error);
        }
      );
  }

  GetAllAlbumInitialData(SearchText: string, pageIndex: number, pageSize: number) {
    let UserId = Number(this.userDetails["userId"]);
    if (this.busyInitialLoading) {
      return;
    }

    this.busyInitialLoading = true;
    // this.spinner.show();
    this._albumService.GetPaginatedAlbums(SearchText, pageIndex, pageSize, UserId).pipe(takeUntil(this.destroy$),
      finalize(() => {
        // this.spinner.hide(); // Hide spinner after response or error
        this.busyInitialLoading = false;
      })).subscribe(
        (response: any) => {
          if (response.isSuccess && (this.albums.length !== response.albums.totalRows || this.totalRows === 0)) {
            this.albums.push(...response.albums.albumsList);
            this.totalPage = response.albums.totalPages;
            this.pageIndex = response.albums.currentPage;
            this.pageSize = response.albums.pageSize;
            this.totalRows = response.albums.totalRows;
          } else {
            console.log("No records found");
          }
        },
        (error: any) => {
          console.error("Error occurred while fetching albums: ", error);
        }
      );
  }

  GetAlbumsforMatPills(SearchText: string, pageIndex: number, pageSize: number) {
    let UserId = Number(this.userDetails["userId"]);
    if (this.busyLoading) {
      return;
    }

    this.busyLoading = true;
    //this.spinner.show();

    setTimeout(() => {
      this._albumService.GetPaginatedAlbums(SearchText, pageIndex, pageSize, UserId).subscribe(
        (response: any) => {
          if (response.isSuccess) {
            this.AlbumsForMatPills.push(...response.albums.albumsList);
            this.totalPage = response.albums.totalPages;
            this.pageIndex = response.albums.currentPage;
            this.pageSize = response.albums.pageSize;
            this.totalRows = response.albums.totalRows;
          } else {
            console.log("No records found");
          }
          // this.spinner.hide();

        },
        (error: any) => {
          console.error("Error occurred while fetching albums: ", error);
          // this.spinner.hide();
        }
      );

      this.busyLoading = false;
    }, 2000);
  }

  allChips = [
    { isActive: true, id: 1, name: "Artists" },
    { isActive: false, id: 2, name: "Albums" },
    { isActive: false, id: 3, name: "Songs" },
  ]

  // checkalpha(alphabet,i){
  //   console.log(i);
  //   //this.SearchText = i;
  //   this.alphabet(alphabet , i);
  // }

  getPageNumber(data) {
    console.log(data, data.length)
    if (data.length == 0) {
      this.pageField = [];
      this.noDataFound = true;
      this.Checkrecords = true;
    } else {
      this.Checkrecords = false;
      this.paginationData = Number(data.length / this.pageSize);
      let tempPageData = this.paginationData.toFixed();
      if (Number(tempPageData) < this.paginationData) {
        this.exactPageList = Number(tempPageData) + 1;
        this.paginationService.exactPageList = this.exactPageList;
      } else {
        this.exactPageList = Number(tempPageData);
        this.paginationService.exactPageList = this.exactPageList
      }
      console.log(this.paginationData, tempPageData, this.exactPageList, this.paginationService.pageField)
      this.paginationService.pageOnLoad();
      this.pageField = this.paginationService.pageField;
    }

  }
  showNextPage(page, i) {
    this.pageIndex = page;
    this.paginationService.currentpageee = this.pageIndex
    this.paginationService.indexxx = i;
    this.activePage = [];
    this.activePage[i] = true;
    if (this.isArtist) {
      this.GetArtistdata('', 0, 0);
    }
    else if (this.isSong) {
      this.GetAllSongs();
    }
    else if (this.isAlbum) {
      this.GetAllAlbums();
    }
  }

  nextPage() {

    if (this.paginationService.disabledNextBtn == false) {
      this.activePage = [];
      this.activePage[0] = true;
      this.paginationService.nextPage();
      this.pageIndex = this.paginationService.pageField[0];
      if (this.isArtist) {
        this.GetArtistdata('', 0, 0);
      }
      else if (this.isSong) {
        this.GetAllSongs();
      }
      else if (this.isAlbum) {
        this.GetAllAlbums();
      }
    }
  }

  previous() {
    if (this.paginationService.disabledPrevBtn == false) {
      this.activePage = [];
      this.activePage[0] = true;
      this.paginationService.previous();
      this.pageIndex = this.paginationService.pageField[0];
      if (this.isArtist) {
        this.GetArtistdata('', 0, 0);
      }
      else if (this.isSong) {
        this.GetAllSongs();
      }
      else if (this.isAlbum) {
        this.GetAllAlbums();
      }
    }
  }


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  CheckSubscription(song, i) {
    this.songIsthere = true;
    this.playSong(song, i);
  }

  playSong(songs, index) {
    debugger;
    const updatedItem = {
      ...songs,
      artistName: songs.fullName
    };
    this._playerService.playSongs(updatedItem);
    if (this.playerService.repeatSong) {
      this.playerService.repeatSong = false;
    }
    this.playerService.setPlaylist(this._playerService.msaapPlaylist);
    this.playerService.selectATrack(index + 1);
    this._playerService.updateData(this._playerService.msaapPlaylist);
    this.playerService.playSongCallback(true);
  }


}
