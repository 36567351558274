import { Component, OnInit } from '@angular/core';
import { LoadScriptService } from 'src/app/loadScript.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AlbumService } from 'src/app/Shared/album.service';
import { ToastrService } from 'ngx-toastr';
import { Album, FeaturedAlbums } from 'src/app/models/album';
import { Users } from '../../models/users';
import { Pagination } from 'src/app/models/pagination';
import { Approvalstatus } from '../../models/approvalstatus';
import { PaginationServiceForAlbum } from '../../Shared/paginationserviceForAlbum.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from "ngx-spinner";
import { Location } from '@angular/common';
@Component({
  selector: 'app-album',
  templateUrl: './album.component.html',
  styleUrls: ['./album.component.css']
})
export class AlbumComponent implements OnInit {

  artistname: any;
  imageZoom = [];
  userDetails = new Users();
  searchForalbum: any;
  albumPerPage;
  searchName; any;
  // pageIndex: any = 1;
  pageSize: any = 50;
  ArtistId: any = 0;
  pageField = [];
  Checkrecords: boolean = false;
  prev: boolean = false;
  next: boolean = false;
  albumList: any;
  path: any;
  albumDetails = new Album();
  featuredAlbum = new FeaturedAlbums();
  activePage: boolean[] = [];
  searchHistory: boolean = false;
  pageNumber: any;
  totalPage: number;
  tempPageIndex: number;
  //Pagination = Pagination.;

  first: number = Pagination.first;
  second: number = Pagination.second;
  third: number = Pagination.third;
  fourth: number = Pagination.fourth;
  fifth: number = Pagination.fifth;


  loginasartist: boolean = true;
  firstrow: boolean = false;
  secondrow: boolean = false;
  thirdrow: boolean = false;
  fourthrow: boolean = false;
  fifthrow: boolean = true;
  columName: any = "createdOn";
  sortedOrder: any = "desc";
  tempData: number = 1;
  tempArray = [];
  tempArrayPath: any;
  tempDataArray: any;


  //Pagination Variables
  pageNumberPerPage = 0;
  pageNumberShow = Pagination.displayNoOfPagesPerPage;
  temppage: number = 0;
  disabledNextBtn: boolean;
  disabledPrevBtn: boolean = true;
  exactPageList: any;
  prevtrue: boolean;
  nexttrue: boolean;
  //currentPage = 1;
  showNoOfCurrentPage: any = 1;
  showPageOnlyOntabsChange: boolean = true;
  paginationData: number;
  pageIndex: any = 1;
  isChecked: boolean[] = [];


  approvalstatus_submit: any;
  approvalstatus_resubmit: any;
  approvalstatus_rejected: any;
  approvalstatus_approved: any;
  approvalstatus_delete: any;
  noDataFound: boolean = false;
  totalcount;
  feaAlbumData: any;
  feaAlbumId: any;
  constructor(private loadScript: LoadScriptService,
    private router: Router,
    private albumService: AlbumService, private spinner: NgxSpinnerService,
    private _toastr: ToastrService, private route: ActivatedRoute,
    public paginationService: PaginationServiceForAlbum, public translate: TranslateService,
    public location: Location) {
    if (this.route.snapshot.paramMap.get("id") == null)
      this.ArtistId = 0;
    else
      this.ArtistId = this.route.snapshot.paramMap.get("id");
  }

  ngOnInit() {
    this.albumService.artistId = 0;
    this.approvalstatus_submit = Approvalstatus.SUBMIT;
    this.approvalstatus_rejected = Approvalstatus.REJECTED;
    this.approvalstatus_resubmit = Approvalstatus.RESUBMIT;
    this.approvalstatus_approved = Approvalstatus.APPROVED;
    this.approvalstatus_delete = Approvalstatus.DELETED;
    this.loadScript.getServiceBaseUrl();
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    if (this.userDetails["artistId"] != undefined) {
      this.ArtistId = this.userDetails["artistId"];
    }
    // this.loadScript.getServiceBaseUrl();
    this.paginationService.temppage = this.paginationService.temppage;
    // this.paginationService.currentPage = 1;
    if (this.paginationService.currentpageee != null || this.paginationService.currentpageee != undefined) {
      this.searchName = this.albumService.searchText;
      this.paginationService.currentPage = this.paginationService.currentpageee
      this.activePage[this.paginationService.indexxx] = true;
    }
    else {
      this.searchName = this.albumService.searchText;
      this.paginationService.currentPage = this.paginationService.currentPage;
      this.activePage[0] = true;
    }
    this.paginationService.showPageOnlyOntabsChange = this.paginationService.showPageOnlyOntabsChange;
    if (this.searchName !== "") {
      this.paginationService.temppage = 0
      this.paginationService.currentPage = 1;
    }
    this.searchName = this.albumService.searchText;
    this.pageIndex = this.paginationService.currentPage
    this.getAllAlbum();

  }
  myOptions = {
    'placement': 'left',
    'show-delay': 500
  }

  searchAlbums($event, i) {

    this.albumService.searchText = $event
    this.Checkrecords = false;
    if ($event == "") {

      this.pageField = [];
      this.pageIndex = 1;
      this.tempPageIndex = 1;
      this.prev = false;
      this.next = false;
      this.searchHistory = false;
      this.albumList = [];
      this.searchName = "";
      this.albumList = this.tempArray;
      this.path = this.tempArrayPath;

      this.getPageNumber(this.tempDataArray);
      this.getRows(this.fifth);
    }
    if ($event !== '') {
      this.searchName = $event;

      this.pageField = [];
      this.pageIndex = 1;
      this.paginationService.temppage = 0;
      this.getAllAlbum();
      //  this.npPageIndex=true;
      for (var x = 0; x < this.totalPage + 1; x++) {
        this.activePage[x] = false;
      }
      if (i == 0) {
        this.activePage[0] = true;
      } else {
        this.activePage[i] = true;
      }
    }
  }
  getAllAlbum() {
    this.spinner.show();
    this.albumService.GetAllAlbum(this.ArtistId, this.columName, this.sortedOrder, this.searchName, this.pageIndex, this.pageSize).subscribe((data: any) => {
      this.totalcount = data.count;

      // if (this.tempData == 1) {
      //   this.tempDataArray = data;
      //   this.tempArray = data.albumDetails;
      //   this.tempArrayPath = data.path;
      //   this.tempData = 0;
      // }
      if (this.tempData == 1) {
        this.tempDataArray = data;
        this.tempArray = data.albumDetails;
        this.tempArrayPath = data.path;
        // this.paginationService.temppage = 0;
      }
      // this.paginationService.temppage = 0;
      if (this.ArtistId != 0 && data.isSuccess == "true") {
        var str3 = "(By ";
        this.artistname = str3.concat(data.albumDetails[0].albumBy, ')');
      }

      this.albumList = data.albumDetails;
      console.log("albumgetall data", this.albumList)
      this.path = data.path;

      this.getPageNumber(data);
      this.spinner.hide();
    }, err => {
      this._toastr.error(err)
      this.spinner.hide();
    });
  }
  getPageNumber(data) {
    if (data.count == 0) {
      this.pageField = [];
      this.noDataFound = true;
      this.Checkrecords = true;
    } else {
      this.Checkrecords = false;
      this.paginationData = Number(data.count / this.pageSize);
      let tempPageData = this.paginationData.toFixed();
      if (Number(tempPageData) < this.paginationData) {
        this.exactPageList = Number(tempPageData) + 1;
        this.paginationService.exactPageList = this.exactPageList;
      } else {
        this.exactPageList = Number(tempPageData);
        this.paginationService.exactPageList = this.exactPageList
      }

      this.paginationService.pageOnLoad();
      this.pageField = this.paginationService.pageField;

    }

  }

  addAlbum() {
    this.albumService.artistId = this.ArtistId;
    this.router.navigate(['/admin/album/add-album']);
  }


  editAlbumProfile(albumId) {

    this.albumService.searchText = this.searchName;
    this.router.navigate([`/admin/album/update-album/` + albumId + ``]);


  }
  getallsongs(artistId, albumId) {

    this.router.navigate([`/admin/songs/` + artistId + '/' + albumId + ``]);
  }

  deleteAlbum(albumId) {

    if (confirm('Please note! This action can NOT be undone. Are you sure you want to delete?')) {
      this.albumService.deleteAlbum(albumId).subscribe((response) => {

        if (response.isSuccess) {
          this._toastr.success("Album has been deleted successfully");

          this.getAllAlbum();
        }
      }, err => {
        this._toastr.error(err)
        this.spinner.hide();
      });
    }
  }


  getRows(page) {
    var k;
    var lis = document.getElementById("page1").getElementsByTagName("li");
    for (k = 0; k < lis.length; k++) {
      lis[k].removeAttribute("style");
    }
    if (page == Pagination.first) {
      this.firstrow = true;
      this.secondrow = false;
      this.thirdrow = false;
      this.fourthrow = false;
      this.fifthrow = false;
      this.pageIndex = 1;
      this.paginationService.temppage = 0;
      this.pageSize = Pagination.first;
      this.activePage[0] = true;
      this.getAllAlbum();

    } else if (page == Pagination.second) {
      this.firstrow = false;
      this.secondrow = true;
      this.thirdrow = false;
      this.fourthrow = false;
      this.fifthrow = false;
      this.pageIndex = 1;
      this.paginationService.temppage = 0;
      this.pageSize = Pagination.second;
      this.activePage[0] = true;
      this.getAllAlbum();

    }
    else if (page == Pagination.third) {
      this.firstrow = false;
      this.secondrow = false;
      this.thirdrow = true;
      this.fourthrow = false;
      this.fifthrow = false;
      this.pageIndex = 1;
      this.paginationService.temppage = 0;
      this.pageSize = Pagination.third;
      this.activePage[0] = true;
      this.getAllAlbum();

    }
    else if (page == Pagination.fourth) {
      this.firstrow = false;
      this.secondrow = false;
      this.thirdrow = false;
      this.fourthrow = true;
      this.fifthrow = false;
      this.pageIndex = 1;
      this.paginationService.temppage = 0;
      this.pageSize = Pagination.fourth;
      this.activePage[0] = true;
      this.getAllAlbum();

    }
    else if (page == Pagination.fifth) {
      this.firstrow = false;
      this.secondrow = false;
      this.thirdrow = false;
      this.fourthrow = false;
      this.fifthrow = true;
      this.pageIndex = 1;
      this.paginationService.temppage = 0;
      this.pageSize = Pagination.fifth;
      this.activePage[0] = true;
      this.getAllAlbum();

    }
  }

  showNextAlbums(page, i) {
    this.pageIndex = page;
    this.paginationService.currentpageee = this.pageIndex
    this.paginationService.indexxx = i;
    this.activePage = [];
    this.activePage = [];
    this.activePage[i] = true;
    this.getAllAlbum();


  }


  nextPage() {
    if (this.paginationService.disabledNextBtn == false) {
      this.activePage = [];
      this.activePage[0] = true;
      this.paginationService.nextPage();
      this.pageIndex = this.paginationService.pageField[0];
      this.getAllAlbum();
    }

  }

  previous() {
    if (this.paginationService.disabledPrevBtn == false) {
      this.activePage = [];
      this.activePage[0] = true;
      this.paginationService.previous();
      this.pageIndex = this.paginationService.pageField[0];
      this.getAllAlbum();
      this.searchHistory = true;
    }

  }



  orderBy(coloumnName) {
    var i = 0;
    var t = document.getElementsByTagName("th");
    for (i = 0; i < t.length; i++) {
      t[i].removeAttribute("style");
    }
    let r = document.getElementById(coloumnName);
    r.style.color = "orange";
    this.columName = coloumnName;
    if (this.sortedOrder == "asc") {
      this.sortedOrder = "desc";

    } else {
      this.sortedOrder = "asc";

    }

    this.getAllAlbum();
  }
  picbig(i) {
    this.imageZoom[i] = false;
  }
  pic(i) {
    this.imageZoom[i] = true;
  }
  viewalbum(albumId) {
    this.router.navigate([`/admin/viewalbum/` + albumId + ``]);
  }

  fetchAlbumId(i, item) {
    debugger;
    // console.log('' , i.target.checked);
    this.isChecked = i.target.checked;
    //this.getfeaAlbumbyAlbumId(item.albumId);
    this.AddIsFeaturedAlbum(item, this.isChecked);

  }

  

  AddIsFeaturedAlbum(id: any, isfeaAlbum: any) {
    debugger;
    if (this.userDetails["userId"] != undefined) {
      this.featuredAlbum.createdBy = this.userDetails["userId"];
      this.featuredAlbum.updatedBy = this.userDetails["userId"];
    }
    this.featuredAlbum.AlbumId = id.albumId;
    this.featuredAlbum.IsFeaturedAlbum = isfeaAlbum;
    if (id.featureAlbumId != 0) {
      this.featuredAlbum.FeatureAlbumId = id.featureAlbumId;
    }
    console.log(this.featuredAlbum);  
    this.albumService.AddUpdateFeaturedAlbums(this.featuredAlbum).subscribe((res: any) => {
      if (res.status == 200) {
        console.log(res);
        if (res.featureAlbumId.featureAlbumId == 0) {
          this._toastr.success("IsFeaturedAlbum added successfully");
          this.spinner.hide();
        } else {
          this._toastr.success("IsFeaturedAlbum updated successfully");
          this.spinner.hide();
        }
      }
    }, err => {
      this._toastr.error(err);
      this.spinner.hide();
    });

  }



}
