import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class ApproverejectService {
  private url = '';
  private baseUrl = '';
  constructor(private http: HttpClient, private configService: ConfigService) {
    this.baseUrl = configService.getServiceBaseUrl();
  }
  approveRejectAlbum(albumData) {
    this.url = this.baseUrl + 'api/Albums/ApproveRejectAlbum';
    return this.http.post(this.url, albumData);
  }
  approveRejectSong(songData) {
    this.url = this.baseUrl + 'api/Songs/ApproveRejectSong';
    return this.http.post(this.url, songData);
  }
  getcommentsofAlbum(AlbumId)
  {
    this.url = this.baseUrl + 'api/Albums/GetCommentsofAlbum?AlbumId='+AlbumId;
    return this.http.get(this.url);
  }
  getcommentsofSong(SongId)
  {
    this.url = this.baseUrl + 'api/Songs/GetCommentsofSong?SongId='+SongId;
    return this.http.get(this.url);
  }
}
