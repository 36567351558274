import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Users } from 'src/app/models/users';
import { LoadScriptService } from 'src/app/loadScript.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/Shared/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/Shared/login.service';

@Component({
  selector: 'app-viewuserprofile',
  templateUrl: './viewuserprofile.component.html',
  styleUrls: ['./viewuserprofile.component.css']
})
export class ViewuserprofileComponent implements OnInit {
  userDetails = new Users();
  userdata: any;
  UserId;
  sub: any;
  id: any;
  enable: boolean = true;
  Email: any;
  requiredError: boolean = false;
  emailError: boolean = false;

  checkActive: boolean = false;
  massage: string;
  Emaillist: Array<any> = [];
  inCorrectEmail: boolean = false;
  userEmaillist: Array<any> = [];
  @ViewChild('closeResetBtn') closeResetBtn: ElementRef;
  constructor(private loadScript: LoadScriptService, private router: Router, private _userService: UserService, private route: ActivatedRoute, private spinner: NgxSpinnerService, private _toastr: ToastrService,
    private _loginService: LoginService,
  ) {
    this.sub = this.route.params.subscribe(params => {
      this.id = params['id']

    })
  }
  ngOnInit() {

    this.GetUserDetails(this.id);
    this.getalluseremail();
  }
  GetUserDetails(userid) {

    this._userService.GetUserDetails(userid).subscribe((data: any) => {
      this.userdata = data;
      this.checkActive = this.userdata.userDetail.isActive;
    })
  }

  enabledisableuser(isActive) {

    this._userService.enabledisableuser(this.id, isActive).subscribe((data: any) => {
      this.GetUserDetails(this.id);
    });

  }

  successmsg(msg: string) {
    this._toastr.success(msg)
  }

  getalluseremail() {
    this._loginService.getalluseremail().subscribe((data: any) => {
      this.Emaillist = data.users;
    });
  }

  userCheckError(event) {
    debugger
    this.Email = event;
    const validEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!this.Email.trim()) {
      this.requiredError = true;
      this.emailError = false; // Clear email error
    } else {
      this.requiredError = false;


      if (!validEmailRegEx.test(this.Email)) {
        this.emailError = true; // Email is invalid, set email error
      } else {
        this.emailError = false;
      }

    }

  }


  pressEnterForResetPass(event) {
    if (event.keyCode === 13) {
      this.resetpassword();
    }
  }

  resetpassword() {
    this.spinner.show();
    console.log(this.Email);
    if (!this.Email) {
      this.requiredError = true; // Set required error flag
      this.spinner.hide();
      return; // Exit the function if the email is empty
    } else {
      this.requiredError = false; // Clear required error flag
    }
    if (this.Email == null || this.Email == "" || this.Email == undefined || this.emailError == true) {
      if (this.Email == undefined || this.Email == "") {
        this.emailError = true;
        setTimeout(() => {
          this.Email = "";
        }, 1000);
      }
      else if (this.Email != undefined || this.Email != "") {
        this.massage = 'Enter valid email';
        this.successmsg(this.massage);
      }
      this.spinner.hide();
    }
    else {
      this.Emaillist.forEach(element => {
        if (element.email == this.Email) {
          this.inCorrectEmail = true;
        }
      });
      if (this.inCorrectEmail) {
        this._loginService.resetpassword(this.Email).subscribe((res: any) => {
          if (res.status == 200) {
            this.massage = 'Mail sent Successfully';
            this.successmsg(this.massage);
          } else {
            this._toastr.error('Mail sending failed');
          }
          this.closeResetBtn.nativeElement.click();
          this.Email = "";
          this.inCorrectEmail = false;
          this.spinner.hide();
        })
      }
      else {
        //this.closeResetBtn.nativeElement.click();
        this._toastr.error('Email is not Registered')
        this.spinner.hide();
      }
    }
  }

  clearemail() {
    this.Email = '';
    this.emailError = false;
    this.requiredError = false;
  }
}
