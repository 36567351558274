import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { LoadScriptService } from 'src/app/loadScript.service';
import { HomeService } from '../Shared/home.service'
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { Track } from '../../../Player/ngx-audio-player/src/lib/model/track.model';
import { PlayerService } from 'src/app/Shared/player.service';
import { Router } from '@angular/router';
import { Users } from '../models/users';
import { Playlist } from 'src/app/models/playlist';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { Location } from '@angular/common';
import { UserService } from '../Shared/user.service';
@Component({
  selector: 'app-allplaylist',
  templateUrl: './allplaylist.component.html',
  styleUrls: ['./allplaylist.component.css']
})
export class AllplaylistComponent implements OnInit {
  @ViewChild('closeBtn') closeBtn: ElementRef;
  playListDetails: any;
  userDetails: any;
  playlist = new Playlist();
  userId: number;
  path;
  playd = [];
  index: any;
  message: string;
  playsongslist: any;
  substatus: any;
  songIsthere: boolean = false;
  playBtn: boolean;
  playPauseBtn: any;
  languageCode: any;
  theme: any;
  constructor(
    private loadScript: LoadScriptService,
    public _homeService: HomeService,
    private playerService: AudioPlayerService,
    private _playerService: PlayerService,
    private toastr: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService,
    public location: Location,
    public userService: UserService
  ) {
    this._homeService.allPlayList.subscribe((res: any) => {
      this.playListDetails = res;
    })
  }

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.userId = this.userDetails.userId;
    this.substatus = this.userDetails.subscriptionStatus;
    this.loadScript.getServiceBaseUrl();
    this.getplaylist();
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.languageCode = this.userDetails.languageCode;
    this.theme = this.userDetails.themeId
  }
  getplaylist() {
    this.spinner.show();
    this._homeService.getPlaylist(this.userId).subscribe((data: any) => {
      this.playListDetails = data.playListDetails;
      this._homeService.allPlayList.next(data.playListDetails);
      this.path = data.path;
      this.spinner.hide();
    });
  }
  deleteplaylist(PlaylistId) {
    if (confirm('Are you sure you want to delete?')) {
      this._homeService.deleteplaylist(PlaylistId).subscribe((res: any) => {
        this.getplaylist();
      })

    }

  }
  playSong(songs, index) {
    this.playPauseBtn = [];
    this.playPauseBtn[index] = true;
    localStorage.setItem('CurrentSongId', songs[index].songId);
    this.playerService.indexSong = 0;

    this._playerService.playSong(songs, this.path, index);
  }
  CheckSubscription(song, index) {
    if (this.substatus == "False") {
      document.getElementById("Subscriptioncheck").style.display = "block";
    }
    else {
      this.songIsthere = true;
      this.playSong(song, index);
    }
  }
  onSubmit() {
    this._homeService.renameplaylist(this.playlist.PlaylistId, this.userId, this.playlist.Name).subscribe((res: any) => {
      if (res.status == 200) {
        this.message = 'Playlist Name Changed Successfully';
        this.successmsg(this.message);
        this.closeBtn.nativeElement.click();
        this.getplaylist();
      } else {
        this.toastr.warning("Playlist Name Already Exists");
      }

    })

  }
  fetchplaylistname(i, j) {
    this.playlist.Name = i;
    this.playlist.PlaylistId = j;
  }
  fetchplayId(i) {
    this.playlist.SongId = i;
  }
  playlistdetails(playlistId: number) {
    this.router.navigate(['/playlist/playlist-details'], { queryParams: { Id: playlistId } });
  }
  viewallsongs(playlistId: number, playListName: string) {
    this._homeService.playListArray = [];
    this._homeService.playlistName = playListName;
    this._homeService.playlist(playlistId, this.userDetails.userId).subscribe((Response: any) => {

      let test = Response.playlistDetails;
      for (var a = 0; a < Response.playlistDetails.length; a++) {
        this._homeService.playListArray.push(Response.playlistDetails[a]);
      }
      this.router.navigate(['/playlist/allplaylistsongs'], { queryParams: { Id: playlistId } });
    })

  }

  successmsg(msg: string) {
    this.toastr.success(msg)
  }
  successmsg1() {
    this.toastr.success("Toastr Success message", 'Success')
  }
  back() {
    this.location.back();
  }
  closeForm() {
    document.getElementById("Subscriptioncheck").style.display = "none";
    this.router.navigate(['/account/upgradeplan']);
  }

}


