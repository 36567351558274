import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserProblemOrSuggestion, Users } from 'src/app/models/users';
import { Role } from 'src/app/models/role';
import { UserService } from 'src/app/Shared/user.service';
import { LoginService } from 'src/app/Shared/login.service';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { ArtistsService } from 'src/app/Shared/artists.service';
import { ToastrService } from 'ngx-toastr';
import { SubscriptionService } from 'src/app/Shared/subscription.service';
@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.css']
})
export class UserAccountComponent implements OnInit {

  userDetails = new Users();
  problemOrSuggestion = new UserProblemOrSuggestion();
  role: any;
  superAdminLoggedIn: boolean;
  singledisprice: boolean = true;
  multipledisprice: boolean = false;
  freetrailchecked: boolean = true;

  createchecked: boolean;
  updateextchecked: boolean;
  subplanname: any;
  subplanprice: any;
  subplansingle: any;
  subplansingleprice: any;
  subplanmultiprice: any;
  subplansingleofferedprice: any;
  subplanmultiofferprice: any;
  savedPriceMultipledevice: number;
  savedPrice;
  planNames: any;
  userSubscriptionId: any;
  subscriptionPlan: any;
  userSubscriptionDetails: any;
  currentPlanName: any;
  firstPart: any;
  secondPart: any;
  CurrentPrice: any;
  name: any;
  memberDetail: any;
  memberDetailsCount: any;
  availablecount: number;
  isSupport: boolean;
  supportButton: boolean = false;
  isStartedopen: boolean = false;
  isFAQopen: boolean = false;
  allFaqs: any[] = [];
  showAllQuestions: boolean = false;
  queLabel = "See all questions";
  lanCode: any;
  languageCode: any = 'en';
  Code: any;
  result: any;
  terms: any;
  howtolist: any[] = [];
  videoTitle: string;
  videoURL: any;
  fileToUpload: File;
  noImage: boolean = true;
  imgURL: any;
  ispdf: boolean = false;
  isdoc: boolean = false;
  isImg: boolean = false;
  problemsuggestionTypes: any;
  descriptions: any;
  isExpand: boolean[] = [false];
  @ViewChild('videoplayerModal') videoplayerModal: ElementRef;
  parentId: any;
  textColor: any;

  constructor(private toastr: ToastrService, public userService: UserService, private _loginService: LoginService,
    private sanitizer: DomSanitizer, private _artistService: ArtistsService, private _subcriptionHistory: SubscriptionService) {
    this.userService.displaySupport.subscribe(show => {
      if (show) {
        this.isSupport = show;
        console.log(this.isSupport)
        this.GetAllHowTo();
        this.getAllproblemorsuggestionTypes();
        //this.support();
      }
      // else{
      //   this.isSupport = false;
      // }
      //
    })
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.parentId = this.userDetails.parentId;
    console.log('users', this.userDetails);
    if (this.parentId) {
      this._subcriptionHistory.Getsubscriptionhistory(this.parentId).subscribe((userData: any) => {
        this.subscriptionPlan = userData.detail.currentPlan[0].planName;
        if (this.subscriptionPlan != 'Free Trial') {
          const parts = this.subscriptionPlan.split(' ');
          this.firstPart = parts[0];
          this.secondPart = parts.slice(1).join(' ');
        }
        else {
          this.currentPlanName = this.subscriptionPlan;
        }
        console.log(userData, this.subscriptionPlan)
      })
    }
    this.userService.displaySupport.subscribe(show => {
      this.isSupport = show
    })
    this.getAllFAQs();
    //this.name = this.userDetails.fullName.slice(1,0);

    if (this.role == Role.superAdmin) {
      this.superAdminLoggedIn = true;
    }
    console.log(this.userDetails.subscriptionPlans);
    this.subplanname = this.userDetails.subscriptionPlans[0].planName;
    this.subplanprice = this.userDetails.subscriptionPlans[0].price;
    this.subplansingle = this.userDetails.subscriptionPlans[1].planName;
    this.subplansingleprice = this.userDetails.subscriptionPlans[1].price;
    this.subplansingleofferedprice = this.userDetails.subscriptionPlans[1].offeredprice;
    this.savedPrice = this.subplansingleprice - this.subplansingleofferedprice;
    this.subplanmultiprice = this.userDetails.subscriptionPlans[2].price;
    this.subplanmultiofferprice = this.userDetails.subscriptionPlans[2].offeredprice;
    this.savedPriceMultipledevice = this.subplanmultiprice - this.subplanmultiofferprice;
    this.userSubscriptionId = parseInt(this.userDetails.subscriptionPlanId, 10);
    this.userSubscriptionDetails = this.userDetails.subscriptionPlans.filter(x => x.subscriptionPlanId == this.userSubscriptionId);
    this.CurrentPrice = this.userSubscriptionDetails[0].price;
    console.log(this.userSubscriptionDetails);
    if (this.userSubscriptionDetails[0].planName != 'Free Trial') {
      const parts = this.userSubscriptionDetails[0].planName.split(' ');
      this.firstPart = parts[0];
      this.secondPart = parts.slice(1).join(' ');
      if (this.userSubscriptionDetails[0].planName == "Toby Premium") {
        this.textColor = '#d2830b';
      }
      else if (this.userSubscriptionDetails[0].planName == "Toby Family") {
        this.textColor = '#6f008f';
      }
      else{
        console.log(this.userSubscriptionDetails[0].planName);
         this.textColor = '#1E90FF';
      }
    }
    else {
      this.currentPlanName = this.userSubscriptionDetails[0].planName;
    }
    this.getusermemberDetails();

    //this.userDetails.subscriptionPlans.forEach(x => x.)
  }

  getusermemberDetails() {
    this.userService.GetMemberDetailsbyId(this.userDetails.userId).subscribe((data: any) => {
      console.log('member', data);
      if (data.isSuccess) {
        this.memberDetail = data.memberDetails;
        this.memberDetailsCount = data.memberDetails.length;
        this.availablecount = 4 - this.memberDetailsCount;
      }
      else {
        this.availablecount = 4;
        this.memberDetailsCount = 0;
      }
    })
  }

  support() {
    debugger
    this.isSupport = !this.isSupport;
    this.userService.showSupportPage(this.isSupport);
  }

  getStarted() {
    this.isStartedopen = !this.isStartedopen;
    this.isFAQopen = false;
    // event.stopPropagation();
  }

  getAllFAQs() {
    this._loginService.getAllFaqs().subscribe((faqs: any) => {
      faqs.faqDetails.forEach(faq => {
        if (faq.faqType == "Web player") {
          this.allFaqs.push(faq);
        }
      })
      console.log(faqs)
    });
  }

  showAll() {
    debugger;
    this.showAllQuestions = !this.showAllQuestions;
    // this.isExpand.map(val=>false);
    this.isExpand.forEach((v, ind) => this.isExpand[ind] = false)
    this.showAllQuestions ? this.queLabel = "See less questions" : this.queLabel = "See all questions";

  }

  sanitizeContent(content: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }

  getContentByCode(Code) {
    this.lanCode = this.languageCode.toUpperCase();
    this.Code = Code + this.lanCode;
    this.userService.GetContentByCode(this.Code).subscribe((data: any) => {
      this.result = data.data.pageContent;
      this.terms = data.data.pageContentTitle;
    })
  }
  GetAllHowTo() {
    this._artistService.GetAllHowto().subscribe((res: any) => {
      console.log(res);
      this.howtolist = [];
      res.allhowto.list.forEach((rec, i) => {
        debugger
        if (rec.userType == "Web player") {
          this.howtolist.push(rec)
        }
      });
      console.log(this.howtolist)
    })
  }

  getVideoById(howToId) {
    debugger;
    this.isStartedopen = !this.isStartedopen;
    this._artistService.GetHowtoById(howToId).subscribe((res: any) => {
      this.videoTitle = res.allhowto.tittle;
      this.descriptions = res.allhowto.description;
      this.videoURL = this.sanitizer.bypassSecurityTrustResourceUrl(res.allhowto.streamUrl);
      //this.isStartedopen = false;
    });
  }

  // sanitizeVideos(url: string): SafeHtml {
  //   return this.sanitizer.bypassSecurityTrustHtml(url);
  // }
  sanitizeVideos(url: string): SafeResourceUrl {
    // Example of basic sanitization
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  selectPhoto(file: FileList) {
    this.problemOrSuggestion.thumbImage = file.item(0).name;
    this.fileToUpload = file.item(0);
    console.log(this.fileToUpload);
    var File = this.fileToUpload.name;
    this.problemOrSuggestion.FileName = File.split('.')[0];
    this.problemOrSuggestion.FileType = File.split('.')[1];
    this.noImage = false;
    let FileSize = this.fileToUpload.size;
    if (FileSize > 26214400) {
      this.toastr.error('File size is more than 25mb');
      //this.noImage = true;
      return;
    }
    //Show Image Preview  26,214,400
    var reader = new FileReader();
    let type = [];
    type = this.fileToUpload.name.split(/[\s.]+/);
    const fileType = (type[type.length - 1]).toLowerCase();
    if (fileType == "pdf") {
      this.ispdf = true;
      this.isdoc = false;
    }
    else if (fileType == "docx" || fileType == "doc") {
      this.isdoc = true;
      this.ispdf = false;
    }
    else if (fileType == "mp4" || fileType == "xlsx") {
      this.toastr.error('please upload only Image, Doc, Pdf file only.');
      this.ispdf = false;
      this.isdoc = false;
      this.noImage = true;
      this.imgURL = "";
      return
    }
    else if (fileType == "jpeg" || fileType == "jpg" || fileType == "png") {
      //this.toastr.error('please upload only jpeg ,jpg , png , Doc, Pdf file only.');
      this.ispdf = false;
      this.isdoc = false;
      this.noImage = false;
    }
    else {
      this.toastr.error('please upload only jpeg ,jpg , png , Doc, Pdf file only.');
      this.ispdf = false;
      this.isdoc = false;
      this.noImage = true;
      this.imgURL = "";
      return
    }
    reader.onload = (event: any) => {
      this.imgURL = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);

  }

  getAllproblemorsuggestionTypes() {
    this.userService.GetAllProblemorSuggestionTypes().subscribe((data: any) => {
      this.problemsuggestionTypes = data.problemsSuggestions;
      //console.log(data);
    });
  }

  OnSavePOrS(problemOrSuggessionTypeId, description) {
    debugger;
    console.log();
    problemOrSuggessionTypeId.control.markAsTouched();
    description.control.markAsTouched();
    if (problemOrSuggessionTypeId.valid && description.valid) {
      if (this.noImage) {
        this.problemOrSuggestion.Base64Data = null;
      } else {
        this.problemOrSuggestion.Base64Data = this.imgURL.split(",")[1];
      }
      this.problemOrSuggestion.UserId = this.userDetails.userId;

      console.log(this.problemOrSuggestion);
      this.userService.addProblemOrSuggestion(this.problemOrSuggestion).subscribe((res: any) => {
        if (res.status == 200) {
          this.toastr.success("Problems/Suggestions  added successfully");
          this.imgURL = null;
          this.noImage = true;
          this.problemOrSuggestion.Description = "";
          this.problemOrSuggestion.ProblemOrSuggessionTypeId = undefined;
        }
      }, err => { this.toastr.error(err) });
    }
  }
  refreshOnClose() {
    this.problemOrSuggestion.ProblemOrSuggessionTypeId = undefined;
    this.problemOrSuggestion.Description = null;
    this.imgURL = null;
    this.noImage = true;
  }
  showFaq() {
    debugger
    this.isFAQopen = !this.isFAQopen;
    this.isStartedopen = false;
  }
  showMinus(faqId, i, en) {
    debugger;
    this.isExpand[i] = !this.isExpand[i];
    var eleplusminus = document.getElementById('plusminus' + faqId);
    var element = document.getElementById('collapseOne' + faqId);
    this.isExpand.forEach((v, ind) => {
      debugger
      if (ind != i) {
        this.isExpand[ind] = false;
      }
    })
    if (eleplusminus.classList.contains("fa-minus")) {
      element.classList.remove("show");
      this.noclick(en)
    }
  }

  noclick(en: Event) {
    debugger
    en.stopPropagation();
  }

  getClass(index: number): string {
    const baseClass = 'circle circle-';
    const classSuffix = String.fromCharCode(98 + index); // 'b' for 0, 'c' for 1, 'd' for 2, etc.
    return baseClass + classSuffix;
  }
}