import { Component, OnInit,AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AlbumService } from 'src/app/Shared/album.service';
import { ArtistsService } from 'src/app/Shared/artists.service';
import { ConfigService } from 'src/app/app.config';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Howto } from 'src/app/models/howto';
import { Location } from '@angular/common';
import { Users } from 'src/app/models/users';

@Component({
  selector: 'app-add-update-howto',
  templateUrl: './add-update-howto.component.html',
  styleUrls: ['./add-update-howto.component.css']
})
export class AddUpdateHowtoComponent implements OnInit  {

  tittle: any;
  description: any;
  howtoForm: FormGroup;
  howToDetails = new Howto();
  file: File;
  duration: number = 0;
  noUrl: boolean = true;
  userDetails = new Users();
  edit = false;
  hwtDetails: any;
  usertypeModel:any;
  videoError: any;

  constructor(public translate: TranslateService, private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder, private toastr: ToastrService,private _artistsService: ArtistsService,
    public location: Location, private route: ActivatedRoute
  ) { 
    this.howToDetails.HowToId = this.route.snapshot.paramMap.get("id");
  }

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    if (this.howToDetails.HowToId != null) {
      this._artistsService.GetHowtoById(this.howToDetails.HowToId).subscribe((res: any) => {
        console.log(res.allhowto);
        this.edit = true;
        //this.howToDetails = res.allhowto;
        this.howToDetails.tittle = res.allhowto.tittle;
        this.howToDetails.description = res.allhowto.description;
        this.howToDetails.streamUrl = res.allhowto.streamUrl.split('/')[6];
        this.howToDetails.length = this.formatDuration(res.allhowto.length);
        console.log(this.howToDetails.streamUrl.split('/')[6]);
        this.usertypeModel=res.allhowto.userType;
        if (this.howToDetails.streamUrl == '') {
          this.videoError = true;
          return;
        }
    });
    }
    else{
      this.howToDetails.HowToId = 0;
    }
  }

  back() {
    this.location.back();
  }

  onSubmit() {
    debugger;
    if (!this.file && !this.edit) {
      this.videoError = true;
      return;
    }
    if (this.userDetails["artistId"] != undefined) {
      this.howToDetails.createdBy = this.userDetails["artistId"].toString();
      this.howToDetails.updatedBy = this.userDetails["artistId"].toString();
    }
    if (this.userDetails["userId"] != undefined) {
      this.howToDetails.createdBy = this.userDetails["userId"].toString();
      this.howToDetails.updatedBy = this.userDetails["userId"].toString();
    }

    this.howToDetails.lengthString = this.howToDetails.length != null ? this.howToDetails.length.toString() : "";
    const time = {
      "Hours":16,
      "Minutes":8,
      "Seconds":45,
      "Milliseconds":0,
      "Ticks":581250000000,
      "Days":0,
      "TotalDays":0.6727430555555556,
      "TotalHours":16.145833333333332,
      "TotalMilliseconds":58125000,
      "TotalMinutes":968.75,
      "TotalSeconds":58125
     };
     this.howToDetails.length = time;
     this.howToDetails.userType=this.usertypeModel;

    console.log(this.howToDetails,this.howToDetails.length.toString());
    this._artistsService.addOrUpdateHowto(this.howToDetails).subscribe((res: any) => {
      if (res.status == 200) {
        console.log(res.data);
        debugger;
        if (this.howToDetails.HowToId == 0) {
          this.toastr.success("Howto added successfully");
          this.spinner.hide();
        } else {
          this.toastr.success("Howto updated successfully");
          this.spinner.hide();
        }
        this.location.back();
        //this._router.navigate(['/admin/songs']);
      }
    }, err => {
      this.toastr.error(err)
      this.spinner.hide();
    })
    console.log(this.howToDetails);
  }

  selectSong(evt) {
    this.file = evt.target.files[0];
    new Audio(URL.createObjectURL(this.file)).onloadedmetadata = (e: any) => {
      this.duration = e.currentTarget.duration;
      this.songlength(this.duration);
    }

    //var duration = moment.duration(seconds, "seconds");
    var File = evt.target.value;
    this.howToDetails.streamUrl = File.substr(12);
    let subStringData = File.substr(12);
    var FileName = subStringData.split('.')[0];
    var songfiletype = subStringData.split('.');
    songfiletype = songfiletype[songfiletype.length - 1];
    //var FileType = subStringData.split('.')[1];
    this.noUrl = false;
    this.howToDetails.VideoName = FileName;
    this.howToDetails.VideoType = songfiletype;

    var files = evt.target.files;
    var file = files[0];
    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoadedForSong.bind(this);
      reader.readAsBinaryString(file);
    }
    else{
      this.videoError = true;
    }
  }
  _handleReaderLoadedForSong(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.howToDetails.Base64DataForVideo = btoa(binaryString);
  }


  songlength(duration) {
    var h = Math.floor(duration / 3600);
    var m = Math.floor(duration % 3600 / 60);
    var s = Math.floor(duration % 3600 % 60);
    var minutes = h + ':' + m + ':' + s;
    this.howToDetails.length = minutes;
  }

  formatDuration(length: any): string {
    const hours = length.hours.toString().padStart(2, '0');
    const minutes = length.minutes.toString().padStart(2, '0');
    const seconds = length.seconds.toString().padStart(2, '0');
    
    return `${hours}:${minutes}:${seconds}`;
  }
}
