import { Component, ElementRef, OnInit, ViewChild, HostListener } from '@angular/core';
import { Users } from 'src/app/models/users';
import { Role } from '../../models/role';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/Shared/user.service';
import { LoadScriptService } from 'src/app/loadScript.service';
import { LoginService } from 'src/app/Shared/login.service';
import { ArtistsService } from '../../Shared/artists.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Location } from '@angular/common';
import { url } from 'inspector';
import { HomeService } from '../../Shared/home.service';
import { AlbumService } from '../../Shared/album.service';
import { SongsService } from '../../Shared/songs.service';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/Shared/shared.service';
import { shiftInitState } from '@angular/core/src/view';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { PlayerService } from 'src/app/Shared/player.service';
import { Track } from 'Player/ngx-audio-player/src/public_api';

@Component({
  selector: 'app-user-homesearch',
  templateUrl: './user-homesearch.component.html',
  styleUrls: ['./user-homesearch.component.css']
})
export class UserHomesearchComponent implements OnInit {

  userLogin: boolean = false
  userDetails = new Users();
  GetArtistDetails = [];
  SearchText = "";
  pageIndex: any = 1;
  alphabeticArray = [];
  sortArray = [];
  searchedArtists = [];
  clickedAlbhabet: boolean[] = [];
  slides = [];
  isAll = true;
  isArtist = false;
  isSong = false;
  isAlbum = false;
  isPlaylist = false;
  ArtistId: any = 0;
  columName: any = "createdOn";
  sortedOrder: any = "desc";
  @ViewChild('sectionNeedToScroll') sectionNeedToScroll: ElementRef;
  temp: any;
  previousAlphabet: any = 'a';
  homeDetails: any;
  path: any;
  artistList: any;
  topSongs: any;
  mostPlayedSongs = [];
  searchName: any;


  allAlbum: any;
  alldata: any;
  topArtist: any;
  MPSongs: any;
  featuredSong: any;
  discography: any;
  serarchArtist: any = null;
  songList: any;
  topArtistimg: any;
  columname: any;
  sortedorder: any = "desc";
  searchname: any;
  AlbumId: any = 0;
  pageSize: any = 50;
  discoverArtist: any;
  discoverAlbum: any;
  discoverSongs: any;
  discoverPlaylist: any;
  playListDetails: any;
  searchingText: any;
  userid: any;
  songdataList: any = [];
  nextSonglist: any = [];
  isSearch = false;
  topArtistLength: any;
  MostPlayedSongsLength: number;
  FeaturedSongsLength: number;
  DiscographyLength: number;
  SongsListLength: number;
  discoverArtistLength: number;
  discoverAlbumLength: number;
  discoverSongsLength: number;
  discoverPlaylistLength: number;
  artist: any;
  artistLength: number;
  albumLength: number;
  album: any;
  substatus: string;
  songIsthere: boolean = false;
  IsActiveSongId: any;
  parentId: number;
  isMainUser: any;
  topArtistItem: any;
  userImage: boolean = true;
  isHeartActive: boolean = false;
  isDisplayanima: boolean = false;
  animationInterval: any;

  constructor(public translate: TranslateService,
    private _toastr: ToastrService,
    private router: Router,
    private loadScript: LoadScriptService,
    private _loginService: LoginService,
    private _artistService: ArtistsService,
    private spinner: NgxSpinnerService,
    public location: Location,
    public userService: UserService,
    private _homeService: HomeService, private albumService: AlbumService,
    private _songsService: SongsService,
    private sharedService: SharedService,
    public playerService: AudioPlayerService,
    private _playerService: PlayerService,
    private route: ActivatedRoute) {
    // this.updateDisplayCount(window.innerWidth);
    this.playerService.getIsblogorCountryScreen().subscribe(value => {
      this.IsActiveSongId = value;
    });
    this.sharedService.triggerMethod$.subscribe(() => {
      this.serarchArtist = null;
      this.OnsearchedChange("");
    });
    this.userService.getIsRouting().subscribe(value => {
      if (value) {
        this.isSearch = false;
        this.serarchArtist = "";
      }
    });
  }

  ngOnInit() {
    this.loadScript.getServiceBaseUrl();
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    if (this.userDetails.image == null) {
      this.userImage = false;
      this._homeService.profileImage = this.userDetails.image;
    }
    else if (this.userDetails.image != null) {
      this.userImage = true;
      this._homeService.profileImage = this.userDetails.image;
    }
    if (this.userDetails["thumbImage"] != null) {
      this.userImage = true;
      this._homeService.profileImage = this.userDetails.image;
    }
    // this.isMainUser = this.userDetails.parentId;
    this.userid = this.userDetails.userId;
    this.parentId = this.userDetails.parentId;

    this.getplaylist(this.userDetails.userId);
  }

  displayCount = 5;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    //this.updateDisplayCount(event.target.innerWidth);
    console.log(event.target.innerWidth);

  }
  updateDisplayCount(width: number) {
    console.log(width);
    // if (width >= 1500 && width <= 1600) {
    //   this.displayCount = 6;
    // }
    // else if (width >= 1700 && width <= 1800) {
    //   this.displayCount = 7;
    // }
    // //75%
    // else if (width >= 1821) {
    //   this.displayCount = 8;
    // }
    // else if (width >= 1900 && width <= 1999) {
    //   this.displayCount = 7;
    // }
    // else if (width >= 2000 && width <= 7000) {
    //   this.displayCount = 8;
    // }

    // else if (width >= 1300 && width <= 1400) {
    //   this.displayCount = 5;
    // }
    // else if (width >= 878 && width <= 1000) {
    //   this.displayCount = 3;
    // }
    // else if (width >= 700 && width <= 877) {
    //   this.displayCount = 2;
    // }

    // else if (width <= 1404) {
    //   this.displayCount = 4;
    // }
    if (width >= 1500 && width <= 1600) {
      this.displayCount = 5;
    }
    else if (width >= 1700 && width <= 1899) {
      this.displayCount = 6;
    }
    else if (width >= 1900 && width <= 1999) {
      this.displayCount = 7;
    }
    else if (width >= 2000 && width <= 3000) {
      this.displayCount = 7;
    }
    else if (width >= 3000 && width <= 7000) {
      this.displayCount = 8;
    }
    else if (width >= 1300 && width <= 1400) {
      this.displayCount = 5;
    }
    else if (width >= 878 && width <= 1200) {
      this.displayCount = 3;
    }
    else if (width >= 700 && width <= 877) {
      this.displayCount = 2;
    }
    else if (width <= 1404) {
      this.displayCount = 4;
    }

  }

  get displayArtists() {
    if (this.artist) {
      return this.artist.slice(0, this.displayCount);
    }
  }

  get displayAlbums() {
    if (this.album) {
      return this.album.slice(0, this.displayCount);
    }
  }

  back() {
    this.location.back();
  }

  all(id: any, index: number) {

    for (let i = 0; i < this.allChips.length; i++) {
      this.allChips[i].isActive = false
    }
    this.allChips[index].isActive = true;

    if (id == 1) {
      this.isAll = true;
      this.isSong = false;
      this.isArtist = false;
      this.isAlbum = false;
      this.isPlaylist = false;
      this.getDiscoverDetails();
    }
    if (id == 2) {
      this.isArtist = true;
      this.isAll = false;
      this.isSong = false;
      this.isAlbum = false;
      this.isPlaylist = false;
      this.getDiscoverDetails();
    }
    if (id == 3) {
      this.isSong = false;
      this.isArtist = false;
      this.isAll = false;
      this.isAlbum = true;
      this.isPlaylist = false;
      this.getDiscoverDetails();


    }
    if (id == 4) {
      this.isSong = true;
      this.isArtist = false;
      this.isAll = false;
      this.isAlbum = false;
      this.isPlaylist = false;
      this.getDiscoverDetails();

    }
    if (id == 5) {
      this.isPlaylist = true;
      this.isSong = false;
      this.isArtist = false;
      this.isAll = false;
      this.isAlbum = false;
      this.getDiscoverDetails();
    }

  }

  allChips = [
    { isActive: true, id: 1, name: "All" },
    { isActive: false, id: 2, name: "Artists" },
    { isActive: false, id: 3, name: "Albums" },
    { isActive: false, id: 4, name: "Songs" },
    { isActive: false, id: 5, name: "Playlists" },
  ]

  getDiscoverDetails() {
    debugger
    this.isSearch = true;
    var serarchArtist = this.serarchArtist == null || this.serarchArtist == '' ? null : this.serarchArtist;
    this.spinner.show();
    this._artistService.GetDiscoverDetails(serarchArtist, this.userid).subscribe((data: any) => {
      console.log(data, this.isAlbum, this.isArtist, this.isPlaylist, this.isSong, this.isAll)
      debugger
      if (data.isSuccess) {
        this.spinner.hide();
        if (data.getdiscover.artists.length == 0 && data.getdiscover.discography.length == 0 && data.getdiscover.discoverAlbum.length == 0
          && data.getdiscover.discoverArtist.length == 0 && data.getdiscover.discoverPlaylist.length == 0 && data.getdiscover.discoverSongs.length == 0
          && data.getdiscover.mostPlayedSongs.length == 0 && data.getdiscover.songsList.length == 0 && data.getdiscover.topArtist.length == 0) {
          this.isDisplayanima = true;
        }
        else {
          this.isDisplayanima = false;
        }

        this.alldata = data.getdiscover;
        this.topArtistLength = this.alldata.topArtist.length;
        this.artistLength = this.alldata.artists.length;
        this.MostPlayedSongsLength = this.alldata.mostPlayedSongs.length;
        this.albumLength = this.alldata.discography.length;
        this.SongsListLength = this.alldata.songsList.length;
        this.discoverArtistLength = this.alldata.discoverArtist.length;
        this.discoverAlbumLength = this.alldata.discoverAlbum.length;
        this.discoverSongsLength = this.alldata.discoverSongs.length;
        this.discoverPlaylistLength = this.alldata.discoverPlaylist.length;
        this.topArtist = this.topArtistLength != 0 ? this.alldata.topArtist[0].fullName : "";
        this.topArtistimg = this.topArtistLength != 0 ? this.alldata.topArtist[0].largeImage : "../../assets/images/NoUserFound.png";
        this.topArtistItem = this.topArtistLength != 0 ? this.alldata.topArtist[0] : {};
        this.MPSongs = this.MostPlayedSongsLength != 0 ? this.alldata.mostPlayedSongs : [];
        this.album = this.albumLength != 0 ? this.alldata.discography : [];
        this.songList = this.SongsListLength != 0 ? this.alldata.songsList : [];
        this.discoverArtist = this.discoverArtistLength != 0 ? this.alldata.discoverArtist : [];
        this.discoverAlbum = this.discoverAlbumLength != 0 ? this.alldata.discoverAlbum : [];
        //console.info(this.discoverAlbum);
        this.discoverSongs = this.discoverSongsLength != 0 ? this.alldata.discoverSongs.slice(0, 50) : [];
        this.discoverPlaylist = this.discoverPlaylistLength != 0 ? this.alldata.discoverPlaylist : [];
        this.songdataList = (this.discoverSongs).slice(0, 10);
        this.nextSonglist = (this.discoverSongs).slice(10, 20);
        this.artist = this.artistLength != 0 ? this.alldata.artists : [];
      }
    });
  }

  // OnsearchedArtistsChange() {

  //   this.SearchText = this.serarchArtist;
  //   if (this.SearchText != null && this.SearchText != "") {

  //     this.getDiscoverDetails();

  //   }
  //   else if (this.SearchText == null || this.SearchText == "") {
  //     this.isSearch = false;
  //     this.userService.IsRouting(true);

  //   }

  // }

  OnsearchedChange($event) {
    if ($event != "") {
      this.searchingText = $event;
      this.SearchText = (this.searchingText);
      this.getDiscoverDetails();
      this.userService.IsRouting(false);
    }
    else if ($event == "") {
      this.isSearch = false;
      this.userService.IsRouting(true);
    }
    else {
      this.isSearch = false;
    }

  }

  showAllalbum() {

    this.all(3, 2)
    window.scrollTo(0, 0);
  }

  showAllartist() {

    this.all(2, 1)
    window.scrollTo(0, 0);
  }

  showAllsongs() {
    this.all(4, 3);
    window.scrollTo(0, 0);
  }

  getplaylist(userId) {

    this._homeService.getPlaylist(userId).subscribe((data: any) => {
      this.playListDetails = data.playListDetails;

      this._homeService.allPlayList.next(data.playListDetails);
      this.path = data.path;
    })
  }

  logOut() {
    debugger;
    let UserId = this.userDetails.userId;
    let SessionId = this.userDetails.sessionId;
    this._songsService.AddLastPlayedSong(this.IsActiveSongId, UserId).subscribe((res: any) => {
      console.log(res);
    });
    this._loginService.logOutAudit(UserId, SessionId).subscribe((res: any) => {
    })
    localStorage.clear();
    localStorage.setItem('themeid', this.userDetails.themeId);
    localStorage.setItem('languagecode', this.userDetails.languageCode);
    this.translate.use("en");
    // localStorage.clear();
    this.router.navigate(['/login']);
    localStorage.removeItem("UserDetails");
  }

  CheckSubscription(song, i) {
    debugger
    this.songIsthere = true;
    this.playSong(song, i);

  }

  playSong(songs, index) {
    // const updatedItem = {
    //   ...songs,
    //   thumbImage: this.path + songs.thumbImage
    // };
    // this._playerService.playSongs(updatedItem);
    // if (this.playerService.repeatSong) {
    //   this.playerService.repeatSong = false;
    // }
    // this.playerService.setPlaylist(this._playerService.msaapPlaylist);
    // this.playerService.selectATrack(index + 1);
    // this._playerService.updateData(this._playerService.msaapPlaylist);
    // this.playerService.playSongCallback(true);
    this._playerService.msaapPlaylist = [];
    songs.forEach((song, i) => {
      const trackObj = new Track();
      trackObj.index = i;
      trackObj.image = song.thumbImage; //this.path + song.thumbImage;
      trackObj.artistName = song.artistName;
      trackObj.link = song.streamUrl;
      trackObj.title = song.title;
      trackObj.songId = song.songId;
      this._playerService.msaapPlaylist.push(trackObj);
    });
    this.playerService.playSongCallback(true);

    if (this.playerService.repeatSong) {
      this.playerService.repeatSong = false;
    }
    this.playerService.setPlaylist(this._playerService.msaapPlaylist);
    this.playerService.selectATrack(index + 1);
    this._playerService.updateData(this._playerService.msaapPlaylist);
  }

  gotoArtistpage(item) {
    debugger;
    this.serarchArtist = "";
    this.isSearch = false;
    this.userService.IsRouting(true);
    this.router.navigate(['/newdiscover'], { queryParams: { Id: item.artistId } });
  }

  gotoAlbumPage(item) {
    debugger
    this.serarchArtist = "";
    this.isSearch = false;
    this.userService.IsRouting(true);
    //console.log(window.location.pathname.split("/").slice(1));
    //this.router.navigate(window.location.pathname.split("/").slice(1))
    this.router.navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/discoverAlbum', item.albumId]));
  }

  gotoPlaylistPage(item) {
    this.serarchArtist = "";
    this.isSearch = false;
    this.userService.IsRouting(true);
    this.router.navigate(['/playlist/allplaylistsongs'], { queryParams: { Id: item.playlistId } });
  }

  show() {
    this.userService.showSupportPage(false);
  }

}
