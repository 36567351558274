import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/Shared/login.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Users } from 'src/app/models/users';
import { LoadScriptService } from 'src/app/loadScript.service';
import { ToastrService } from 'ngx-toastr';
import { Role } from '../../models/role';
import { ArtistsService } from 'src/app/Shared/artists.service';
import { UserService } from 'src/app/Shared/user.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {
  OldPassword;
  NewPassword;
  ConfirmPassword;
  UserId: any;
  OldPasswordError: boolean;
  ConfirmPasswordError: boolean;
  NewPasswordError: boolean;
  changepasswordForm: FormGroup;
  submitted = false;
  massage: string;
  userDetails = new Users();
  ArtistId: any;
  roleType: any;
  result: any;
  inCorrectpassword: boolean = false;
  artistresult: any;
  tempUserData: any;
  IsMobileReq: boolean=false;
  constructor(private toastr: ToastrService, public userService: UserService, private _router: Router, private loadScript: LoadScriptService, private _loginService: LoginService, private formBuilder: FormBuilder, private _artistService: ArtistsService) { }

  ngOnInit() {
    this.loadScript.getServiceBaseUrl();

    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.roleType = this.userDetails.userType;
    this.changepasswordForm = this.formBuilder.group({
      NewPassword: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(10)]],
      ConfirmPassword: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(10)]],
      OldPassword: ['', Validators.required],

    })
    $(document).ready(function () {
      $(".focussss").focus();

    })
    this.getUserOldPassword();
  }
  getUserOldPassword() {
    this._loginService.getUserOldPassword(this.userDetails.userId, this.IsMobileReq).subscribe((data: any) => {
      this.tempUserData = data.users;
    })
  }

  get f() { return this.changepasswordForm.controls; }
  onSubmit() {
    this.submitted = true;
    if (this.changepasswordForm.invalid) {
      return;
    }
    this.OldPassword = this.changepasswordForm.get('OldPassword').value;
    this.NewPassword = this.changepasswordForm.get('NewPassword').value;
    this.ConfirmPassword = this.changepasswordForm.get('ConfirmPassword').value;

    if (this.roleType == Role.user) {
      this.UserId = this.userDetails.userId;
      if (this.OldPassword != this.NewPassword) {
        if (this.tempUserData.password == this.OldPassword) {
          if (this.NewPassword == this.ConfirmPassword) {
            this._loginService.changepassword(this.UserId, this.OldPassword, this.NewPassword, this.ConfirmPassword).subscribe((res: any) => {
              this.result = res;
              this.massage = 'Password Changed Successfully';
              this.successmsg(this.massage);
              this._router.navigate([`/home`]);

            }, err => { this.toastr.error(err) })
          } else {
            this.massage = 'New Password and ConfirmPassword not Same';
            this.successmsg(this.massage);
          }
        }
        else {
          this.massage = 'Old Password not matched';
          this.successmsg(this.massage);
        }
      }
      else {
        this.massage = 'Old Password and NewPassword Are Same';
        this.successmsg(this.massage);
      }
    }

    else {
      this.ArtistId = this.userDetails["artistId"];
      this._artistService.getArtistDetails(this.ArtistId).subscribe((data: any) => {
        this.artistresult = data.artistDetails;
        if (this.artistresult.password != this.NewPassword) {
          if (this.artistresult.password == this.OldPassword) {
            if (this.NewPassword == this.ConfirmPassword) {
              this._loginService.artistchangepassword(this.ArtistId, this.OldPassword, this.NewPassword, this.ConfirmPassword).subscribe((res: any) => {
                this.massage = 'Password Change Successfully';
                this.successmsg(this.massage);
                this._router.navigate([`/home`]);
              }, err => { this.toastr.error(err) })
            }
            else {
              this.massage = 'New Password and ConfirmPassword not Same';
              this.successmsg(this.massage);
            }

          }
          else {
            this.massage = 'Old Password dosnt match';
            this.successmsg(this.massage);
          }
        }
        else {
          this.massage = 'Old Password and NewPassword Are Same';
          this.successmsg(this.massage);
        }
      }, err => { this.toastr.error(err) })


    }

  }
  successmsg(msg: string) {
    this.toastr.success(msg)
  }
  successmsg1() {
    this.toastr.success("Toastr Success message", 'Success')
  }
  cancel() {
    this._router.navigate([`/account/profile`]);
  }

}
