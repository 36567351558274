import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HomeService } from "../../Shared/home.service";
import { Artist } from "../../models/Artist";
import { LoadScriptService } from 'src/app/loadScript.service';
import { AlbumService } from 'src/app/Shared/album.service';
import { Track } from '../../../../Player/ngx-audio-player/src/lib/model/track.model'
import { PlayerService } from '../../Shared/player.service';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/Shared/user.service';

import { Location } from '@angular/common';
@Component({
  selector: 'app-album',
  templateUrl: './album.component.html',
  styleUrls: ['./album.component.css']
})
export class AlbumComponent1 implements OnInit {
  userDetails: any;
  userId: any;
  LikeAlbumImage: boolean[] = [];
  FavAlbumImage: boolean[] = [];
  path;
  Details: any;
  artistname;
  allsongs;
  TransList: Array<any> = [];
  constructor(
    public userService: UserService,
    private loadScript: LoadScriptService,
    private homeService: HomeService,
    private albumService: AlbumService,
    private router: Router,
    private playerService: AudioPlayerService,
    private _playerService: PlayerService,
    public location: Location,
    public translate: TranslateService,
  ) { }

  ngOnInit() {
    this.displayallbums();
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.userId = this.userDetails.userId;
    this.loadScript.getServiceBaseUrl();
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.userId = this.userDetails.userId;
    let InvId = localStorage.getItem("InvoiceID");
    if (InvId != "0") {
      this.userId = this.userDetails.userId;

    }
  }
  displayallbums() {
    if (this.albumService.albumDetails.length != 0) {
      this.Details = this.albumService.albumDetails[0].artistDetails.album;
      this.path = this.albumService.albumDetails[0].path;
      this.artistname = this.albumService.albumDetails[0].artistDetails;
      this.allsongs = this.albumService.albumDetails[0].artistDetails.songs;
      for (var a = 0; a < this.Details.length; a++) {
        if (this.Details[a].likeId == 0) {
          this.LikeAlbumImage[a] = false;
        } else {
          this.LikeAlbumImage[a] = true;
        }
      }
      for (var a = 0; a < this.Details.length; a++) {
        if (this.Details[a].favId == 0) {
          this.FavAlbumImage[a] = false;
        } else {
          this.FavAlbumImage[a] = true;
        }
      }
    }

  }
  albumdetails(albumId: number) {
    localStorage.removeItem("AlbumId");
    let val = albumId;
    localStorage.answer = JSON.stringify(val);
    let saved = JSON.parse(localStorage.answer);
    localStorage.setItem("AlbumId", saved);
    this.router.navigate(['/album/album-details'], { queryParams: { Id: albumId } });
  }
  playSong(songs, index) {
    this._playerService.playSong(songs, this.path, index);
  }
  CheckSubscription(song, index) {
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    if (this.userDetails.subscriptionStatus == "False") {
      document.getElementById("Subscriptioncheck").style.display = "block";
    }
    else {

      this.playSong(song, index);
    }
  }
  closeForm() {
    document.getElementById("Subscriptioncheck").style.display = "none";
    this.router.navigate(['/account/upgradeplan']);
  }
  back() {
    this.location.back();
  }
  LikeAlbums(albumId: string, UserId: string, i) {
    this.LikeAlbumImage[i] = !this.LikeAlbumImage[i];
    this.homeService.LikeAlbums(albumId, this.userId).subscribe((res: any) => {
    })
  }
  FavouriteAlbums(albumId: string, UserId: string, i) {
    this.FavAlbumImage[i] = !this.FavAlbumImage[i];
    this.homeService.FavouriteAlbums(albumId, this.userId).subscribe((res: any) => {
    })

  }
}
