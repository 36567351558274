import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appSetHeight]'
})
export class SetHeightDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    // Call the function initially to set the height
    this.setDivHeight();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    // Call the function whenever the window is resized
    this.setDivHeight();
  }

  private setDivHeight() {
    const windowHeight = window.innerHeight-65;
    this.renderer.setStyle(this.el.nativeElement, 'height', `${windowHeight}px`);
  }

}
