import { Component, OnInit, ViewChild } from '@angular/core';
import { LoadScriptService } from 'src/app/loadScript.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscriptions, Subscriptionsplanedit } from '../../../models/subscriptions'
import { PaypalService } from '../../../Shared/paypal.service';
import { ToastrService } from 'ngx-toastr';
import { SubscriptionService } from '../../../Shared/subscription.service';
import * as ClassicEditor  from '@ckeditor/ckeditor5-build-classic';
@Component({
  selector: 'app-updatesubscriptionplan',
  templateUrl: './updatesubscriptionplan.component.html',
  styleUrls: ['./updatesubscriptionplan.component.css']
})
export class UpdatesubscriptionplanComponent implements OnInit {
  subscriptionDetails = new Subscriptions();
  subscriptionplanedit = new Subscriptionsplanedit();
  sandboxdata = [];
  Details;
  basicAuth = 'Basic QVlvb25tN3dKaWpfb1FnNjNYLTBCcjBlVGdHcjVzRS1rT1ZZb3g0OVk4eWZ5SUpVVHY2UElaQ1pxb09vN19Cb25XbE92bDhhOW5ZaDZ3UU46RURUbEF3N3NwUGN4ajdkRTY3VXdJYW1obEJrdUtTRXFUUWxNNmd1NHhWdURuRlV6cnJXMlZqaFMwek1rR3l6YWtqSlVnLWxHM0FZQjQtcFk=';
  massage: string;
  Details1: any;
  @ViewChild("myckeditor") ckeditor: any;
  public editorConfig = {
    toolbar: [
      'heading', '|',
      'bold', 'italic', 'underline', 'strikethrough', '|',
      'link', 'bulletedList', 'numberedList', 'blockQuote', 'codeBlock', '|',
      'insertTable', 'imageUpload', 'mediaEmbed', '|',
      'alignment', 'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', '|',
      'undo', 'redo'
    ],
    image: {
      toolbar: [
        'imageTextAlternative', 'imageStyle:full', 'imageStyle:side'
      ]
    },
    table: {
      contentToolbar: [
        'tableColumn', 'tableRow', 'mergeTableCells'
      ]
    }
  };
  public Editor = ClassicEditor;
  constructor(private loadScript: LoadScriptService,
    private router: Router, private paypalService: PaypalService,
    private route: ActivatedRoute, private toastr: ToastrService,
    private subscriptionService: SubscriptionService
  ) { }

  ngOnInit() {
    this.loadScript.getServiceBaseUrl();
    let param1 = this.route.snapshot.queryParams["Id"];
    this.paypalService.GetSubscriptionplanbyId(param1).subscribe((data: any) => {
      this.subscriptionDetails = data.detail;
      console.log(this.subscriptionDetails);
      this.GetsandboxData(this.subscriptionDetails.paypalPlanIdDev);
    })
  }
  Res;

  GetsandboxData(PlanId) {
    var self = this;
    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        self.Res = JSON.stringify(this.responseText);
        localStorage.setItem('Details', self.Res);
      }
    };
    xhttp.open('GET', 'https://api.sandbox.paypal.com/v1/billing/plans/' + PlanId, true);
    xhttp.setRequestHeader('Authorization', this.basicAuth);
    xhttp.send();
  }
  back() {
    this.router.navigate(['/admin/Subscriptionplan']);
  }
  Res1;
  Res2;
  Postdatatopaypal() {
    var self = this;
    var obj: any =
    {
      "id": "P-5M349757ML520363JLWEMT2Y",
      "product_id": "PROD-2EA400711T820884K",
      "name": "SINGLE DEVICE MONTHLY",
      "status": "ACTIVE",
      "description": "Single Device Monthly",
      "billing_cycles": [{
        "frequency": { "interval_unit": "MONTH", "interval_count": 1 },
        "tenure_type": "TRIAL", "sequence": 1, "total_cycles": 1
      },
      {
        "pricing_scheme": {
          "version": 1, "fixed_price": { "currency_code": "USD", "value": "25.0" },
          "create_time": "2019-09-23T13:34:35Z", "update_time": "2019-09-23T13:34:35Z"
        },
        "frequency": { "interval_unit": "MONTH", "interval_count": 1 },
        "tenure_type": "REGULAR", "sequence": 2, "total_cycles": 12
      }],
      "payment_preferences": {
        "auto_bill_outstanding": true,
        "setup_fee": { "currency_code": "USD", "value": "0.0" },
        "setup_fee_failure_action": "CONTINUE", "payment_failure_threshold": 3
      },
      "taxes": { "percentage": "0.0", "inclusive": true }, "quantity_supported": true,
      "create_time": "2019-09-23T13:34:35Z", "update_time": "2019-09-23T13:34:35Z",
      "links": [{
        "href": "https://api.sandbox.paypal.com/v1/billing/plans/P-5M349757ML520363JLWEMT2Y",
        "rel": "self", "method": "GET"
      },
      {
        "href": "https://api.sandbox.paypal.com/v1/billing/plans/P-5M349757ML520363JLWEMT2Y",
        "rel": "edit", "method": "PATCH"
      },
      {
        "href": "https://api.sandbox.paypal.com/v1/billing/plans/P-5M349757ML520363JLWEMT2Y/deactivate",
        "rel": "self", "method": "POST"
      }]
    }
      ;
    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {

      if (this.readyState === 4 && this.status === 201) {

        self.Res1 = JSON.parse(this.responseText);
        alert('Plan create Successfully');
        localStorage.setItem('Details', self.Res1);
      }
    };
    xhttp.open('Post', 'https://api.sandbox.paypal.com/v1/billing/plans/', true);
    xhttp.setRequestHeader('Authorization', this.basicAuth);
    xhttp.setRequestHeader('Content-Type', 'application/json');
    xhttp.send(JSON.stringify(obj));
    // this.UpdateSubscription();
    var id = self.Res1.id;
    xhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {

        self.Res2 = JSON.stringify(this.responseText);

        localStorage.setItem('Details1', self.Res2);

        var planDescription = self.Res2.description;

        var paypalPlanIdDev = self.Res2.id;

        var price = 2;

      }
    };
    xhttp.open('GET', 'https://api.sandbox.paypal.com/v1/billing/plans/' + id, true);
    xhttp.setRequestHeader('Authorization', this.basicAuth);
    xhttp.send();
    this.UpdateSubscription();

  }

  Updatesubscriptionplan(sub: Subscriptions) {
    // Users.userId = this.ProfileIdUpdate;
    this.paypalService.Updatesubscriptionplan(sub).subscribe(
      () => {
        if (this.subscriptionDetails.SubscriptionPlanId == 0) {
        }
        else {
          this.massage = 'Update Successfully';
        }
        this.successmsg(this.massage);
        this.router.navigate(['/admin/Subscriptionplan']);
      });
  }
  successmsg(msg: string) {
    this.toastr.success(msg)
  }
  onSubmit() {
    this.paypalService.Updatesubscriptionplan(this.subscriptionDetails).subscribe(
      () => {
        if (this.subscriptionDetails.SubscriptionPlanId == 0) {
        }
        else {
          this.massage = 'Updated successfully';
        }
        this.successmsg(this.massage);
        this.router.navigate(['/admin/Subscriptionplan']);
      }, err => { this.toastr.error(err) })
  }
  UpdateSubscription() {
    this.Details1 = JSON.parse(localStorage.getItem('Details1'));
    this.paypalService.Updatesubscriptionplan(this.subscriptionplanedit).subscribe(() => {
      this.massage = 'Subscription Plan Added Successfully';
    })
  }
}



