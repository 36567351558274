export class Usersubscription {
    UserSubscriptionId:any;
    UserId:any;
    SubscriptionPlanId:any;
    BrainTreePlanId:any;
    BrainTreeCustomerId:any;
    SubscriptionStartDate:any;;
    BillingStartDate:any;;
    BillingEndDate:any;
    PayableAmount:any;
    isActive:any;
}

