import { Component, OnInit } from '@angular/core';
import { LoadScriptService } from '../loadScript.service';

@Component({
  selector: 'app-playlist',
  templateUrl: './playlist.component.html',
  styleUrls: ['./playlist.component.css']
})
export class PlaylistComponent implements OnInit {

  constructor(private loadScript:LoadScriptService) { }

  ngOnInit() {
    
    //Service to load js file runtime
    this.loadScript.getServiceBaseUrl();
  }


}
