import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../Shared/login.service'
import { Users } from '../../models/users';
import { Country } from '../../models/country';
import { ToastrService } from 'ngx-toastr';
import { disableDebugTools } from '@angular/platform-browser';
import { LoadScriptService } from 'src/app/loadScript.service';
import { SubscriptionService } from '../../Shared/subscription.service';
import { Subscriptions } from '../../models/subscriptions';
import { PaymentTransaction } from '../../models/payment-transaction';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../Shared/language.service';
import { ArtistsService } from 'src/app/Shared/artists.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Artist } from 'src/app/models/artist';
import { UserService } from 'src/app/Shared/user.service';
import { Approvalstatus } from 'src/app/models/approvalstatus';
import * as $ from 'jquery';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-artist-register',
    templateUrl: './artist-register.component.html',
    styleUrls: ['./artist-register.component.css', '../login.component.css']
})
export class ArtistRegisterComponent implements OnInit {

    @ViewChild('closeResetBtn') closeResetBtn: ElementRef;
    @ViewChild('closeResetBtn1') closeResetBtn1: ElementRef;
    @ViewChild('closeModal') closeModal: ElementRef;
    country: Array<Country> = [];
    countryList: Array<Country> = [];
    userDetails: any;//= new Users();
    validEmail;

    searchCountry: boolean = false;

    userNameError: boolean;
    passwordError: boolean;
    emailIdError: boolean;
    countryError: boolean;
    showValidationModalError: boolean = false;
    abouttoby: any;
    //Validation for Login 
    // loginUserName;
    artistbutton: boolean = true;

    artistUserName
    artistPassword;
    loginUserNameError: boolean;
    loginPasswordError: boolean;
    loginFailed: boolean = false;
    isArtistLogIn: boolean;
    indexForCountry = 0;
    highlightCountry: boolean[] = [];
    emailError: boolean = false;
    massage: string;
    Email: any;
    Emaillist: Array<any> = [];
    inCorrectEmail: boolean = false;
    ArtistEmaillist: Array<any> = [];
    userId: number;
    subscription: any;
    singledevice: any;
    multipledevice: any;
    Singlesubscription = 0;
    Multiplesubscription = 0;
    offerprice: any;
    discountedPrice;
    savedPrice;
    offerprice1: any;
    discountedPrice1;
    savedPrice1;
    showPaymentSection: boolean = false;
    showconfirmpayment: boolean = false;
    showHidePlan: boolean = false;
    showHideRegistraionPage: boolean = true;
    subDuration: string;
    saveprice: any;
    MultiplediscountedPrice1: any;
    Multipleofferprice: any;
    MultiplesavedPrice: number;
    MultiplediscountedPrice: any;
    userSubscriptions = new Subscriptions();
    paymentTransaction = new PaymentTransaction();
    usersubid: any;
    usertype: any;
    userchecked: boolean = true;
    artistchecked: boolean;
    logindate: boolean = false;
    loginusername: boolean = false;
    languages: any;
    languageId;
    languageCode: any = 'en';
    countryId: any = 1;
    langcode: any;
    topfive = [];
    comingUp = [];
    PopularNow = [];
    path: any;
    topthrees = [];
    index = 0;
    result1: any;
    UserId: any;
    subscriptionStatus;
    result: any;
    terms: any;
    lanCode: any;
    Code: any;
    isChecked: boolean = true;
    isaccept: boolean = false;
    showArtistlogin: boolean = false;
    showUserlogin: boolean = true;
    artistDetails = new Artist();
    registerForm: FormGroup;
    recaptcha;
    siteKey: string = "6LctpVolAAAAAM3FPMsrVvhC9Wv9GYMv2SERbrNp";
    lang = "sv";
    accepts = false;
    submitted = false;
    type: string;
    loginPage: boolean = true;
    registerPage: boolean;
    buttonDisabled: boolean;
    btnstate: boolean = true;
    @ViewChild('scrollToTop') scrollToTop: ElementRef;
    modal: any;
    termscont = true;
    artistid: any[];
    userdata: any;
    closeResult: string;
    registers: boolean;
    constructor(private _router: Router,
        private route: ActivatedRoute,
        private _loginService: LoginService,
        private _toastr: ToastrService,
        private loadScript: LoadScriptService,
        private subscriptionService: SubscriptionService,
        public translate: TranslateService,
        private _languageService: LanguageService,
        private _userService: UserService,
        private artistService: ArtistsService, private formBuilder: FormBuilder,
        private spinner: NgxSpinnerService, public userService: UserService,
        private reCaptchaV3Service: ReCaptchaV3Service,
        private modalService: NgbModal) { }

    ngOnInit() {

        window.scrollTo(0, 0);
        this.registerForm = this.formBuilder.group({
            fullName: ['', Validators.required],
            mobileNumber: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(6)]]
        });
        this.GetUserDetails(this.userId)
        //this.getLanguages(this.UserId);
        this.getallartistemail();
        $(document).ready(function () {
            $(".artautofocus").focus();
        })

        this.route.queryParams.subscribe(params => {
            this.registers = params['registerPage'];
            this.registeraccess(this.registers);
        });

    }

    tobyMain() {
        this._router.navigate([`/artist-main-page`]);
    }

    get f() { return this.registerForm.controls; }
    onSubmit(f: NgForm) {

        this.submitted = true;
        // stop here if form is invalid
        this.spinner.show();
        if (f.form.invalid) {
            this.spinner.hide();
            return;
        } else if (f.form.controls.email.value !== f.form.controls.confirmEmail.value) {
            this._toastr.warning("Email and confirm email must be equal");
            this.spinner.hide();
            return;
        }
        else if (f.form.controls.password.value !== f.form.controls.confirmPassword.value) {
            this._toastr.warning("Password and confirm password must be equal");
            this.spinner.hide();
            return;
        } else if (this.recaptcha == undefined) {
            this._toastr.warning("Please tick the captcha");
            this.spinner.hide();
        }
        else {
            this.artistDetails.fullName = f.value.fullName;
            this.artistDetails.emailId = f.value.email;
            this.artistDetails.mobileNumber = f.value.mobileNumber;
            this.artistDetails.password = f.value.password;

            this.artistDetails.statusId = Approvalstatus.SUBMIT;
            this.artistService.addOrUpdateArtist(this.artistDetails).subscribe((res: any) => {
                if (res.status == 200) {
                    this.logindate = false;
                    this.userDetails = res.artistDetails;
                    this._toastr.success("Artist added successfully");
                    this.spinner.hide();
                    localStorage.clear();
                    localStorage.setItem('UserDetails', JSON.stringify(this.userDetails));
                    // this.closeBtn1.nativeElement.click();
                    this._router.navigate([`/home`]);

                } else if (res.status == 401) {
                    // this.logindate = true;
                    this._toastr.warning("Email is already exist!");
                    this.submitted = false;
                }
            }, err => {
                this._toastr.error(err)
                this.spinner.hide();
            })
        }
    }

    checkCapcha() {
        this.reCaptchaV3Service.execute(this.siteKey, 'homepage', (token) => {
            this.spinner.hide();
        });
    }


    checkForError($event, formValue) {
        if ($event != '') {
            this.setValidation(formValue, $event)
            if (formValue == 'countryError') {
                this.countryList = this.country.filter((res: any) => {
                    return (res.countryName.toLowerCase()).startsWith($event.toLowerCase());
                })
                this.highlightCountry.length = 0;
                this.indexForCountry = 0;
                if (this.countryList.length !== 0) {
                    this.searchCountry = true;
                } else {
                    this.searchCountry = false;
                }
            }

        } else {
            if (formValue == 'countryError') {
                this.countryList = [];
                this.searchCountry = false;
            }
            this.setValidationForError(formValue)
        }
    }

    setValidation(formValue, event) {
        if (formValue == 'userNameError') {
            this.userNameError = false;
        }
        if (formValue == 'emailIdError') {
            const validEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (validEmailRegEx.test(event)) {
                this.validEmail = false;
                this.emailIdError = false;

            } else {

                this.validEmail = true;
                this.emailIdError = true;
            }
            this.logindate = false;
        }
        if (formValue == 'passwordError') {
            this.passwordError = false;
        }
        //Validation for login
        if (formValue == 'loginUserNameError') {
            this.loginUserNameError = false;
        }
        if (formValue == 'loginPasswordError') {
            this.loginPasswordError = false;
        }
    }
    setValidationForError(formValue) {

        if (formValue == 'userNameError') {
            this.userNameError = true;
        }
        if (formValue == 'emailIdError') {
            this.emailIdError = true;
        }
        if (formValue == 'passwordError') {
            this.passwordError = true;
        }
        if (formValue == 'countryError') {
            this.countryError = true;
        }
        //Validation for login
        if (formValue == 'loginUserNameError') {
            this.loginUserNameError = true;
        }
        if (formValue == 'loginPasswordError') {
            this.loginPasswordError = true;
        }
    }

    //Send Id as 2 When User wants to login
    pressEnterUser(event) {
        if (event.keyCode === 13) {
            this.artistLogIn();
        }
    }

    pressEnterForResetPass(event) {
        if (event.keyCode === 13) {
            this.artistresetpassword();
        }
    }

    //Artist Login
    artistLogIn() {
        if (this.artistUserName == undefined || this.artistUserName == "") {
            this.loginUserNameError = true;
        }
        if (this.artistPassword == undefined || this.artistPassword == "") {
            this.loginPasswordError = true;
        }
        if (this.artistPassword != undefined && this.artistUserName != undefined) {
            this._loginService.artistLogin(this.artistUserName, this.artistPassword).subscribe((res: any) => {
                console.log(res);
                if (res.status == "200") {
                    this.userDetails = res.userDetails;
                    console.log(res);
                    localStorage.clear();
                    localStorage.setItem('UserDetails', JSON.stringify(this.userDetails));
                    if (this.userDetails.isTCAccepted == true) {
                        this._router.navigate([`/home`]);

                        //this.modalService.dismissAll();
                        //this.closeModal.nativeElement.click()


                    }
                    // if(this.isaccept==true){
                    //     this._router.navigate([`/home`]);
                    // }
                    else {
                        this.term('TOBY_MUSIC_CONDITIONS_AND_TERMS_OF_USE_');
                        //open t&c popup with accept Check box
                    }
                    // this.closeBtn1.nativeElement.click();
                    //this._router.navigate([`/home`]);


                } else {
                    this.loginFailed = true;
                    //this.closeModal.nativeElement.click();  
                }

            })

        }
    }

    //Clear all Textbox
    clear() {
        this.artistUserName = '';
        this.artistPassword = '';
        this.loginFailed = false;
    }
    clearemail() {
        this.Email = '';
        this.emailError = false;
    }

    resetpassword() {
        if (this.Email == null || this.Email == "" || this.Email == undefined || this.emailError == true) {

            if (this.Email == undefined || this.Email == "") {

                this.emailError = true;
                setTimeout(() => {    //<<<---    using ()=> syntax
                    this.emailError = false;
                    this.Email = "";
                    this.closeResetBtn.nativeElement.click();

                }, 1000);

            }
            else if (this.Email != undefined || this.Email != "") {
                this.massage = 'Enter valid email';
                this.successmsg(this.massage);
                //this.closeResetBtn.nativeElement.click();
            }
            // this.emailError = false;

        }

        else {
            for (var i = 0; i < this.Emaillist.length; i++) {
                let temp = this.Emaillist[i].email.includes(this.Email)
                if (temp) {
                    this.inCorrectEmail = true;
                }

            }

            if (this.inCorrectEmail) {
                this._loginService.resetpassword(this.Email).subscribe((res: any) => {
                    this.closeResetBtn.nativeElement.click();
                    this.massage = 'Mail sent Successfully';
                    this.successmsg(this.massage);
                    this.Email = "";
                    this.inCorrectEmail = false;
                })
            }
            else {
                this.closeResetBtn.nativeElement.click();
                this.massage = 'Email is not Registered';
                this.successmsg(this.massage);
            }
        }
    }
    successmsg(msg: string) {
        this._toastr.success(msg)
    }


    artistresetpassword() {
        this.spinner.show();
        if (this.Email == null || this.Email == "" || this.Email == undefined || this.emailError == true) {

            if (this.Email == undefined || this.Email == "") {

                this.emailError = true;
                setTimeout(() => {    //<<<---    using ()=> syntax
                    this.emailError = false;
                    this.Email = "";
                    // this.closeResetBtn1.nativeElement.click();

                }, 5000);
            }
            else if (this.Email != undefined || this.Email != "") {
                this.massage = 'Enter valid email';
                this.successmsg(this.massage);
            }
            this.spinner.hide();
        }
        else {
            for (var i = 0; i < this.ArtistEmaillist.length; i++) {
                let artisttemp = this.ArtistEmaillist[i].emailId.includes(this.Email)
                if (artisttemp) {
                    this.inCorrectEmail = true;
                }
            }
            if (this.inCorrectEmail) {
                this._loginService.artistresetpassword(this.Email).subscribe((res: any) => {
                    if (res.status === 200) {
                        this.massage = 'Mail sent Successfully';
                        this.successmsg(this.massage);
                    } else {
                        this._toastr.error('Mail sending failed');
                    }
                    this.closeResetBtn1.nativeElement.click();
                    this.Email = "";
                    this.inCorrectEmail = false;
                    this.spinner.hide();
                })
            }
            else {
                this.closeResetBtn1.nativeElement.click();
                this._toastr.error('Email is not Registered');
                this.spinner.hide();
            }
        }
    }

    ArtistcheckError(event) {
        this.Email = event;
        const validEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (validEmailRegEx.test(this.Email)) {

            this.emailError = false;

        } else {
            this.emailError = true;

        }
    }


    getallartistemail() {
        this._loginService.getallartistemail().subscribe((data: any) => {
            this.ArtistEmaillist = data.artistDetails;
            //console.log('email', this.ArtistEmaillist)
        });
    }


    loginUser() {
        this.scrollToTop.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        this._router.navigate(['login']);
    }
    login() {
        this.scrollToTop.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        this.loginPage = true;
        this.registerPage = false;
    }
    register() {
        this.scrollToTop.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        this.registerPage = true;
        this.loginPage = false;
    }

    registeraccess(e: boolean) {
        debugger;
        if (e == true) {
            this.registerPage = e;
            this.loginPage = false;
        }
        else
            // this.scrollToTop.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            this.loginPage = e;
    }

    getContentByCode(Code) {
        this.lanCode = this.languageCode.toUpperCase()
        this.Code = Code + this.lanCode;
        this._userService.GetContentByCode(this.Code).subscribe((data: any) => {
            this.result = data.data.pageContent;
            this.terms = data.data.pageContentTitle;
            // console.log('code', data.data.pageContent)
        })
    }
    getAboutUsByCode(Code) {
        // this.lanCode = this.languageCode.toUpperCase()
        // this.Code = Code + this.lanCode;
        this._userService.GetContentByCode(Code).subscribe((data: any) => {
            this.abouttoby = data.data.pageContent;
        })
    }

    term(Code) {
        this.getContentByCode(Code);

    }

    Checked(value) {
        this.isChecked = value;
        console.log('...', this.isChecked)
    }

    accept(event) {
        //alert(event.target.checked);
        this.isaccept = event.target.checked;
        this.termscont = !event.target.checked;
        // console.log('chk', this.isaccept);
        // if (this.isaccept = true) {
        //     this.termscont = false;
        // }
        // else {
        //     this.termscont = true;
        // }
    }

    continue() {
        this.artistid = this.userDetails.artistId;
        if (this.isaccept) {
            this._userService.addtermsand_condition(this.artistid, this.isaccept).subscribe((res: any) => {
                if (res.status == 200) {
                    this._toastr.success("T&C Accepted Sucessfully")
                    this._router.navigate([`/home`]);
                }
            })
        } else {
            this._toastr.error("Please accept T&C")
            //please accept tm...
        }

    }

    GetUserDetails(userid) {

        this._userService.GetUserDetails(userid).subscribe((data: any) => {
            this.userdata = data;
            console.log('chkl')
            console.log('user', data)
            //this.checkActive = this.userdata.userDetail.isActive;
        })
    }

}
