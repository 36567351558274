import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { LoadScriptService } from 'src/app/loadScript.service';
import { HomeService } from "../../Shared/home.service";
import { Router, ActivatedRoute, Params } from '@angular/router';
import { PlayerService } from '../../Shared/player.service';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { Track } from '../../../../Player/ngx-audio-player/src/lib/model/track.model';
import { Playlist } from 'src/app/models/playlist';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from "ngx-spinner";
import { Location } from '@angular/common';
import { UserService } from 'src/app/Shared/user.service';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-allsongs',
  templateUrl: './allsongs.component.html',
  styleUrls: ['./allsongs.component.css']
})
export class AllsongsComponent implements OnInit {

  @ViewChild('f') form: NgForm;
  @ViewChild('closeBtn') closeBtn: ElementRef;
  TransList: Array<any> = [];
  path: any;
  Details: any;
  artistname: any;
  userDetails: any;
  userId: any;
  IsMobileReq:boolean;
  songDetails = new Track();
  allsongs;
  playlist = new Playlist();
  createchecked: boolean = true;
  updateextchecked: boolean;
  playListDetails: any;
  noLikeImage: boolean;
  nofavImage: boolean;
  noLikeImage1: boolean[] = [];
  nofavimage1: boolean[] = [];
  playd = [];
  index;
  substatus: any;
  songIsthere: boolean = false;
  constructor(
    private loadScript: LoadScriptService,
    private homeService: HomeService,
    private route: ActivatedRoute,
    private playerService: AudioPlayerService,
    private _playerService: PlayerService,
    private toastr: ToastrService,
    public translate: TranslateService,
    private spinner: NgxSpinnerService,
    public location: Location,
    private router: Router,
    public userService: UserService,
  ) { }

  ngOnInit() {
    let param1 = this.route.snapshot.queryParams["Id"];
    this.loadScript.getServiceBaseUrl();
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.userId = this.userDetails.userId;
    this.IsMobileReq = this.userDetails.isMobileReq;
    this.substatus = this.userDetails.subscriptionStatus;
    this.allsong(param1, this.userId,this.IsMobileReq)
    this.freshplaylist();
  }
  freshplaylist() {
    this.homeService.getPlaylist(this.userDetails.userId).subscribe((data: any) => {
      this.playListDetails = data.playListDetails;
      this.homeService.getLatestPlaylist(this.playListDetails);
    })
  }
  allsong(artistId: string, userId: string, IsMobileReq :boolean) {
    this.spinner.show();
    this.homeService.getArtist(artistId, userId).subscribe(Response => {
      this.TransList.push(Response);
      this.path = this.TransList[0].path;
      this.Details = this.TransList[0].artistDetails.album;
      this.artistname = this.TransList[0].artistDetails;
      this.allsongs = this.TransList[0].artistDetails.songs;
      for (var a = 0; a < this.allsongs.length; a++) {
        if (this.allsongs[a].likeId == 0) {
          this.noLikeImage1[a] = false;
        } else {
          this.noLikeImage1[a] = true;
        }
      }
      for (var a = 0; a < this.allsongs.length; a++) {
        if (this.allsongs[a].favId == 0) {
          this.nofavimage1[a] = false;
        } else {
          this.nofavimage1[a] = true;
        }
      }
      let currentSongId = localStorage.getItem('CurrentSongId');
      let getSong = this.allsongs.findIndex(x => x.songId == currentSongId)
      if (getSong != -1) {
        let isSongPlaying = localStorage.getItem('IsPlaying');
        if (isSongPlaying == 'true' || isSongPlaying == null) {
          this.playerService.playPauseBtn[getSong] = true;
        } else if (isSongPlaying == 'false') {
          this.playerService.playPauseBtn[getSong] = false;
        }

      } else {
        this.playerService.playPauseBtn[this.playerService.indexSong] = false;
      }

      this.spinner.hide();
    })
  }
  fetchSongId(i) {
    this.playlist.SongId = i;
  }

  playSong(songs, index) {
    let songId = songs[index].songId;
    localStorage.setItem('CurrentSongId', songId);

    let indexForPlayPause = this.playerService.playPauseBtn.findIndex(x => x == true);
    if (indexForPlayPause == index) {
      localStorage.setItem('IsPlaying', 'false');
      this.playerService.playPauseBtn[index] = !this.playerService.playPauseBtn[index];
    }
    else {
      localStorage.setItem('IsPlaying', 'true');
      this.playerService.playPauseBtn[indexForPlayPause] = false;
      this.playerService.playPauseBtn[index] = true;
    }

    this._playerService.playSong(songs, this.path, index);
  }

  onSubmit() {
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.playlist.UserId = this.userDetails.userId;
    this.homeService.addOrUpdateplaylist(this.playlist).subscribe((res: any) => {
      if (res.status == 200) {
        if (this.playlist.PlaylistId! == 0) {
          this.toastr.success("Playlist added successfully");
          this.createchecked = true;
          this.updateextchecked = false;
          this.form.resetForm();
          this.form.reset();
        } else {
          this.closeBtn.nativeElement.click();
          this.freshplaylist();
          this.toastr.success("Playlist updated successfully");
          this.createchecked = true;
          this.updateextchecked = false;
          this.form.resetForm();
          this.form.reset();
        }
      }
      else {
        this.toastr.success("Playlist Already Exists");
      }
    })
  }
  refreshplaylist() {
    this.homeService.getPlaylist(this.userDetails.userId).subscribe((data: any) => {
      this.playListDetails = data.playListDetails;
      if (this.playListDetails.length >= 10) {
        for (var i = this.index; i < 10; i++) {
          this.playd[i] = this.playListDetails[i];
          this.homeService.playId[i] = this.playListDetails[i];
        }
      } else {
        for (var i = this.index; i < this.playListDetails.length; i++) {
          this.playd[i] = this.playListDetails[i];
          this.homeService.playId[i] = this.playListDetails[i];
        }
      }
    });
  }
  checked() {
    this.createchecked = true;
    this.updateextchecked = false;
    this.playlist.Name = '';
  }
  updatechecked() {
    this.updateextchecked = true;
    this.createchecked = false;
    this.playlist.PlaylistId = undefined;
  }
  Likesongs(songId: string, UserId: string, i) {
    this.noLikeImage1[i] = !this.noLikeImage1[i];
    this.homeService.LikeSong(songId, this.userId).subscribe((res: any) => {
    }
    )
  }
  FavouriteSongs(songId: string, UserId: string, i) {
    this.nofavimage1[i] = !this.nofavimage1[i];
    this.homeService.FavouriteSongs(this.playlist.SongId, this.userId).subscribe((res: any) => {
    })
  }
  updatecount(songId) {
    debugger;
    this.homeService.updatecount(songId).subscribe((res: any) => {
      //  this.result = res.data;
    })

  }
  IsCheckLang() {
    if (this.translate.currentLang == 'ar') {
      return true;

    } else {
      return false;
    }

  }
  back() {
    this.location.back();
  }
  CheckSubscription(song, i) {
    if (this.substatus == "False") {
      document.getElementById("Subscriptioncheck").style.display = "block";
    }
    else {
      this.songIsthere = true;
      this.playSong(song, i);
    }
  }

  closeForm() {
    document.getElementById("Subscriptioncheck").style.display = "none";
    this.router.navigate(['/account/upgradeplan']);
  }
}
