import { Injectable } from '@angular/core';
import { ConfigService } from '../app.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Album } from '../models/album';
import { Artist } from '../models/artist';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SongsService {

  private url = '';
  private baseUrl = '';
  public searchText = '';
  favplaylist: any = [];
  public favdjmix = [];
  favsdjmix: any = [];
  favalbum: any = [];
  playlistEntities: any = [];
  public playId = [];
  public falbumss = [];
  public favsongs = [];
  public favartist = [];
  public favplaylistentities = [];
  playlistId : any;

  //public favdjmixdata=[];
  public content = new BehaviorSubject<any>(this.favplaylist);
  public share = this.content.asObservable();
  public contentt = new BehaviorSubject<any>(this.favalbum);
  public sharee = this.contentt.asObservable();
  public djmixcontent = new BehaviorSubject<any>(this.favsdjmix);
  public djmixshare = this.djmixcontent.asObservable();
  public playlistcontent = new BehaviorSubject<any>(this.playlistEntities);
  public favplaylistentitie = this.playlistcontent.asObservable();
  // public contentt = new BehaviorSubject<any>(this.favdjmix);
  // public sharee = this.content.asObservable();
  constructor(private http: HttpClient, private configService: ConfigService) {
    this.baseUrl = configService.getServiceBaseUrl();
  }
  getLatestPlaylist(data) {
    this.content.next(data);
    this.playId = data;
  }
  getfavalbum(data) {
    this.contentt.next(data);
    this.falbumss = data;
  }
  getfavadjmix(data) {
    this.djmixcontent.next(data);
    this.favdjmix = data;
  }
  getfavsonga() {

  }
  getplaylistEntities(data) {
    this.playlistcontent.next(data);
    this.favplaylistentities = data;
  }

  GetSongsDetails(artistId, albumId, columname, sortedorder, searchname, pageIndex, pageSize) {
    this.url = this.baseUrl + 'api/Songs/GetAllSongs?ArtistId=' + artistId + '&AlbumId=' + albumId + '&ColumName=' + columname + '&SortedOrder=' + sortedorder + '&SearchName=' + searchname + '&PageIndex=' + pageIndex + '&PageSize=' + pageSize;
    return this.http.get(this.url);
  }

  GetallCategories() {
    this.url = this.baseUrl + 'api/Songs/GetAllCategories';
    return this.http.get(this.url);
  }
  GetsongLanguage() {
    this.url = this.baseUrl + 'api/Songs/GetsongLanguage';
    return this.http.get(this.url);
  }
  GetAllAbumbs(ArtistId) {
    this.url = this.baseUrl + 'api/Albums/GetAlbumsbyArtistId?ArtistId=' + ArtistId;
    //return this.http.get<Album[]>(this.url); 
    return this.http.get(this.url);
  }

  Getlangugae(ArtistId) {
    this.url = this.baseUrl + 'api/Artists/GetlanguagebyArtistId?ArtistId=' + ArtistId;
    //return this.http.get<Album[]>(this.url); 
    return this.http.get(this.url);
  }
  //getartist
  getArtists() {
    this.url = this.baseUrl + 'api/Artists/GetArtists';
    return this.http.get(this.url);
    //return this.http.get<Artist[]>(this.url);
  }
  addupdatesong(songs) {
    this.url = this.baseUrl + 'api/Songs/AddUpdateSongs';
    return this.http.post(this.url, songs);
  }
  getSongDetails(songId) {
    this.url = this.baseUrl + 'api/Songs/GetSongDetails?SongId=' + songId;
    return this.http.get(this.url);
  }
  getSongDetailsfooter(songId, userId) {
    this.url = this.baseUrl + 'api/Songs/GetSongDetails?SongId=' + songId + '&UserId=' + userId;
    return this.http.get(this.url);
  }

  /*
  * Get song details by User Id
  */
  getSongDetailsByUserId(songId, UserId, mobireq) {
    this.url = this.baseUrl + 'api/Songs/GetSongDetails?SongId=' + songId + '&UserId=' + UserId + '&IsMobileReq=' + mobireq;
    return this.http.get(this.url);
  }
  deleteSong(songId): Observable<any> {
    this.url = this.baseUrl + 'api/Songs/DeleteSong?SongId=' + songId;
    return this.http.post(this.url, songId)
  }
  Getallfavsongs(UserId, CategoryId, SearchText, pageIndex, pageSize) {
    this.url = this.baseUrl + 'api/Songs/ViewAllFavouriteSong?UserId=' + UserId + '&CategoryId=' + CategoryId + '&SearchText=' + SearchText + '&PageIndex=' + pageIndex + '&PageSize=' + pageSize;
    return this.http.get(this.url);
  }
  Getallfavdjmix(UserId, CategoryId, SearchText, pageIndex, pageSize) {
    this.url = this.baseUrl + 'api/Songs/ViewAllFavouriteSong?UserId=' + UserId + '&CategoryId=' + CategoryId + '&SearchText=' + SearchText + '&PageIndex=' + pageIndex + '&PageSize=' + pageSize;
    return this.http.get(this.url);
  }

  getSongsByAlbumId(userId, albumId, pageIndex, pageSize) {
    this.url = this.baseUrl + 'api/Songs/GetAllSongsbyAlbumId?UserId=' + userId + '&AlbumId=' + albumId + '&PageIndex=' + pageIndex + '&PageSize=' + pageSize;
    return this.http.get(this.url);
  }
  getLikedSongsByUserID(UserId) {
    this.url = this.baseUrl + 'api/Songs/GetAlllikedSongs?UserId=' + UserId;
    return this.http.get(this.url);
  }

  getNewlyAddedSongs(UserId) {
    this.url = this.baseUrl + 'api/Songs/GetNewlyAddedSong?UserId=' + UserId;
    return this.http.get(this.url);
  }

  getSongs(SearchText: string, UserId) {
    this.url = this.baseUrl + 'api/Songs/GetSongs?SearchText=' + SearchText + '&UserId=' + UserId;
    return this.http.get(this.url);
  }

  /*
  * get all songs data with pagination
  */
  getPaginatedSongs(searchText: string, pageIndex: number, pageSize: number, userId: number) {
    this.url = this.baseUrl + 'api/Songs/GetPaginatedSongs?SearchText=' + searchText + '&PageIndex=' + pageIndex + '&PageSize=' + pageSize + '&UserId=' + userId;
    return this.http.get(this.url);
  }

  /*
  * get song by songId and userId
  */
  getSongBySongIdandUserId(songId: number, userId: number) {
    this.url = this.baseUrl + 'api/Songs/GetSongsById?id=' + songId + '&userId=' + userId;
    return this.http.get(this.url);
  }

  addupdateFeaturedsong(songs) {
    this.url = this.baseUrl + 'api/Songs/AddUpdateFeaturedSongs';
    return this.http.post(this.url, songs);
  }

  addupdateRecommendsong(songs) {
    this.url = this.baseUrl + 'api/Songs/AddUpdateRecommendedSongs';
    return this.http.post(this.url, songs);
  }

  GetFeaturedSongs() {
    this.url = this.baseUrl + 'api/Songs/GetfeaturedSongs';
    return this.http.get(this.url);
  }
  GetRecommendedSongs(UserId) {
    this.url = this.baseUrl + 'api/Songs/GetRecommendedSongs?UserId=' + UserId;
    return this.http.get(this.url);
  }

  getPlaylistBySongid(SongId) {
    this.url = this.baseUrl + 'api/PlayLists/GetPlayListBySongId?SongId=' + SongId;
    return this.http.get(this.url);
  }

  GetMostPlayedTop50songs(userId) {
    this.url = this.baseUrl + 'api/Songs/GetMostPlayedSongsOnToby?UserId=' + userId;
    return this.http.get(this.url);
  }

  GetMostPlayedSongsByLangs(languageCode, userId) {
    this.url = this.baseUrl + 'api/Songs/GetMostPlayedSongsByLanguageCode?LanguageCode=' + languageCode + '&UserId=' + userId;
    return this.http.get(this.url);
  }
  GetMostPlayedSongsByUsersCountry(countryId, userId) {
    this.url = this.baseUrl + 'api/Songs/GetMostPlayedSongsByUsersCountry?CountryId=' + countryId + '&UserId=' + userId;
    return this.http.get(this.url);
  }
  GetMostPlayedSongsOnTobyByCategory(songCategory, userId, fetchCount) {
    this.url = this.baseUrl + 'api/Songs/GetMostPlayedSongsOnTobyByCategory?CategoryCode=' + songCategory + '&UserId=' + userId + '&FetchCount=' + fetchCount;
    return this.http.get(this.url);
  }
  GetMostPlayedSongsByUsersBornYear(UserId) {
    this.url = this.baseUrl + 'api/Songs/GetMostPlayedSongsByUsersBornYear?UserId=' + UserId;
    return this.http.get(this.url);
  }
  GetMostPlayedSongsByLangCodeNyear(languageCode, userId) {
    this.url = this.baseUrl + 'api/Songs/GetMostPlayedSongsOnTobyByLanguageCodeNyear?LanguageCode=' + languageCode + '&UserId=' + userId;
    return this.http.get(this.url);
  }
  GetMostPlayedPlaylistOnToby() {
    this.url = this.baseUrl + 'api/PlayLists/GetMostPlayedPlaylistOnToby';
    return this.http.get(this.url);
  }

  GetTopFavSongs(userId) {
    this.url = this.baseUrl + 'api/Songs/GetTopFavSongs?UserId=' + userId;
    return this.http.get(this.url);
  }

  Getlastmonthtopmostplayedsongs(userId) {
    this.url = this.baseUrl + 'api/Songs/GetLastMonthTopMostPlayedSongs?UserId=' + userId;
    return this.http.get(this.url);
  }

  GetUserFavoriteSongs(UserID) {
    this.url = this.baseUrl + 'api/Songs/GetUsersFavoriteSong?UserId=' + UserID;
    return this.http.get(this.url);
  }

  getPastMakersSongs(UserId) {
    this.url = this.baseUrl + 'api/Songs/GetPastMakersSong?UserId=' + UserId;
    return this.http.get(this.url);
  }

  AddLastPlayedSong(songId , UserId){
    this.url = this.baseUrl + 'api/Songs/AddLastPlayedSongs?songId=' + songId + '&UserId=' + UserId;
    return this.http.post(this.url ,songId, UserId);
  }

  getLastPlayedSongs(UserId) {
    this.url = this.baseUrl + 'api/Songs/GetLastPlayedSongs?UserId=' + UserId;
    return this.http.get(this.url);
  }
}
