import { Injectable } from '@angular/core';
import { ConfigService } from '../app.config';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { HttpModule } from '@angular/http';
import { Playlist } from '../models/playlist';
import { BehaviorSubject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable()

export class HomeService {
    private url = '';
    private baseUrl = '';
    public playlistName;
    public playId = [];
    public profileImage = '';
    public userInitials = '';
    public searchText = '';
    public playListArray = [];
    public playListSongs = new Subject<any>();
    public allPlayList = new Subject<any>();
    public playerSong = new Subject<any>();
    public artistListArray = [];
    public albumSongId: number = 0;
    public isAlbumQueue:boolean=false;
    public likefavalbumId:number=0;

    Playlist: any = [];
    songIds: any = [];

    public content = new BehaviorSubject<any>(this.Playlist);
    public share = this.content.asObservable();
    userDetails: any;
    userId: any;
    private triggerMethodSource = new BehaviorSubject<any>(null);

    triggerMethod$ = this.triggerMethodSource.asObservable();

    triggerMethodInComponentB(data: any) {
        this.triggerMethodSource.next(data);
    }

    constructor(private http: HttpClient, private route: ActivatedRoute, private configService: ConfigService) {
        this.baseUrl = configService.getServiceBaseUrl();
        this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
        if (this.userDetails != null) {
            this.userId = this.userDetails.userId
        }

    }
    getLatestPlaylist(data) {
        this.Playlist = this.content.next(data);
    }
    updatecount(SongId) {
        debugger;
        this.url = this.baseUrl + 'api/Songs/UpdatePlayCount?SongId=' + SongId + '&UserId=' + this.userId;
        return this.http.post(this.url, SongId)
    }

    getHomeDetails(userId) {
        this.url = this.baseUrl + 'api/TobyHomes/GetHomeDetails?UserId=' + userId;
        return this.http.get(this.url);
    }
    getArtist(artistId, userId) {
        this.url = this.baseUrl + 'api/Artists/GetArtistDetail?ArtistId=' + artistId + '&UserId=' + userId;
        return this.http.get(this.url);
    }
    SearchSong(SearchName, PageIndex, PageSize, UserId, SongLanguageCode, CategoryName) {
        this.url = this.baseUrl + 'api/Songs/SearchSongs?SearchName=' + SearchName + '&PageIndex=' + PageIndex + '&PageSize=' + PageSize + '&UserId=' + UserId + '&SongLanguageCode=' + SongLanguageCode + '&CategoryName=' + CategoryName;
        return this.http.get(this.url);
    }
    getPlaylist(userId) {
        this.url = this.baseUrl + 'api/PlayLists/GetPlayList?UserId=' + userId;
        return this.http.get(this.url);
    }
    getadminPlaylist() {
        this.url = this.baseUrl + 'api/PlayLists/GetAdminPlayList'
        return this.http.get(this.url);
    }
    getadminPlaylistById(playlistId) {
        this.url = this.baseUrl + 'api/PlayLists/GetAdminPlayListById?PlayListId=' + playlistId;
        return this.http.get(this.url);
    }
    GetUserDetails(UserId) {
        this.url = this.baseUrl + 'api/Users/GetUserDetails?UserId=' + UserId;
        return this.http.get(this.url);
    }
    getAlbumbyId(albumId, userId) {
        this.url = this.baseUrl + 'api/albums/GetAlbumDetails?AlbumId=' + albumId + '&UserId=' + userId;
        return this.http.get(this.url);
    }
    getSongbyId(songId, userId) {
        this.url = this.baseUrl + 'api/Songs/GetSongDetails?SongId=' + songId + '&UserId=' + userId;
        return this.http.get(this.url);
    }
    //Likes and Favourites functions
    LikeAlbums(albumId, userId) {
        this.url = this.baseUrl + 'api/Albums/LikeAlbums?AlbumId=' + albumId + '&UserId=' + userId;
        return this.http.post(this.url, albumId, userId)
    }
    LikeArtist(artistId, userId) {
        this.url = this.baseUrl + 'api/Artists/LikeArtist?ArtistId=' + artistId + '&UserId=' + userId;
        return this.http.post(this.url, artistId, userId)
    }
    LikeSong(songId, userId) {
        this.url = this.baseUrl + 'api/Songs/LikeSong?SongId=' + songId + '&UserId=' + userId;
        return this.http.post(this.url, songId, userId)
    }
    FavouriteAlbums(albumId, userId) {
        this.url = this.baseUrl + 'api/Albums/AddFavouriteAlbum?AlbumId=' + albumId + '&UserId=' + userId;
        return this.http.post(this.url, albumId, userId)
    }
    FavouriteArtist(artistId, userId) {
        this.url = this.baseUrl + 'api/Artists/AddFavouriteArtist?ArtistId=' + artistId + '&UserId=' + userId;
        return this.http.post(this.url, artistId, userId)
    }
    FavouriteSongs(songId, userId) {
        this.url = this.baseUrl + 'api/Songs/AddFavouriteSongs?songId=' + songId + '&UserId=' + userId;
        return this.http.post(this.url, songId, userId)
    }

    playlist(playlistId, userId) {
        this.url = this.baseUrl + 'api/PlayLists/GetPlayListDetails?PlaylistId=' + playlistId + '&UserId=' + userId;
        return this.http.get(this.url, playlistId)
    }
    addOrUpdateplaylist(playlistData) {
        this.url = this.baseUrl + 'api/PlayLists/AddUpdatePlayList';
        return this.http.post(this.url, playlistData);
    }
    AddUpdateAdminPlayList(playlistData) {

        this.url = this.baseUrl + 'api/PlayLists/AddUpdateAdminPlayList';
        return this.http.post(this.url, playlistData);
    }
    favouritesItems(userId, IsMobileReq) {
        this.url = this.baseUrl + 'api/Favourite/GetFavourites?UserId=' + userId;
        return this.http.get(this.url, userId)
    }
    deleteplaylist(playlistId) {
        this.url = this.baseUrl + 'api/PlayLists/DeletePlayList?PlaylistId=' + playlistId;
        return this.http.post(this.url, playlistId)
    }
    renameplaylist(PlaylistId, UserId, PlaylistName) {
        this.url = this.baseUrl + 'api/PlayLists/RenamePlayList?PlaylistId=' + PlaylistId + '&UserId=' + UserId + '&PlaylistName=' + PlaylistName;
        return this.http.post(this.url, PlaylistId);
    }
    removefromplaylist(PlaylistId, UserId, SongId) {
        this.url = this.baseUrl + 'api/PlayLists/RemoveFromPlaylist?PlaylistId=' + PlaylistId + '&UserId=' + UserId + '&SongId=' + SongId;
        return this.http.post(this.url, PlaylistId);
    }

    removefromAdminplaylist(PlaylistId, UserId, SongId) {
        this.url = this.baseUrl + 'api/PlayLists/RemoveFromAdminPlaylist?PlaylistId=' + PlaylistId + '&UserId=' + UserId + '&SongId=' + SongId;
        return this.http.post(this.url, PlaylistId);
    }

    //uploadphoto

    uploadphoto(userList) {
        this.url = this.baseUrl + 'api/Users/UploadImage';
        return this.http.post(this.url, userList)
    }


    //album

    Searchalbum(SearchText, PageIndex, PageSize, UserId, SongLanguageCode) {
        this.url = this.baseUrl + 'api/Albums/SearchAlbum?SearchText=' + SearchText + '&PageIndex=' + PageIndex + '&PageSize=' + PageSize + '&UserId=' + UserId + '&SongLanguageCode=' + SongLanguageCode;
        return this.http.get(this.url);
    }

    //artist

    Searchartist(searchtext, PageIndex, PageSize, UserId, SongLanguageCode) {
        this.url = this.baseUrl + 'api/Artists/SearchArtist?SearchText=' + searchtext + '&PageIndex=' + PageIndex + '&PageSize=' + PageSize + '&UserId=' + UserId + '&SongLanguageCode=' + SongLanguageCode
        return this.http.get(this.url);
    }

    //djmix
    Searchdjmix(SearchName, PageIndex, PageSize, UserId, SongLanguageCode) {
        this.url = this.baseUrl + 'api/Songs/SearchDJMixSongs?SearchName=' + SearchName + '&PageIndex=' + PageIndex + '&PageSize=' + PageSize + '&UserId=' + UserId + '&SongLanguageCode=' + SongLanguageCode;
        return this.http.get(this.url);
    }
    SaveSearch(UserId, KeyWord) {
        this.url = this.baseUrl + 'api/TobyHomes/SaveSearch?UserId=' + UserId + '&KeyWord=' + KeyWord;
        return this.http.post(this.url, UserId, KeyWord);
    }
    getHistory(UserId) {
        this.url = this.baseUrl + 'api/TobyHomes/GetHistory?UserId=' + UserId;
        return this.http.get(this.url);
    }
    deletehistory(searchId, UserId) {
        this.url = this.baseUrl + 'api/TobyHomes/DeleteSearchHistory?SearchId=' + searchId + '&UserId=' + UserId;
        return this.http.get(this.url);
    }
    deleteAdminPlaylist(playlistId) {
        this.url = this.baseUrl + 'api/PlayLists/DeleteAdminPlayList?PlaylistId=' + playlistId;
        return this.http.post(this.url, playlistId)
    }
    renameAdminPlaylist(PlaylistId, UserId, PlaylistName) {
        this.url = this.baseUrl + 'api/PlayLists/RenameAdminPlayList?PlaylistId=' + PlaylistId + '&UserId=' + UserId + '&PlaylistName=' + PlaylistName;
        return this.http.post(this.url, PlaylistId);
    }
    adminPlaylist(playlistId, userId) {
        this.url = this.baseUrl + 'api/PlayLists/GetAdminPlayListDetails?PlaylistId=' + playlistId + '&UserId=' + userId;
        return this.http.get(this.url, playlistId)
    }
    UpdatePlayListImage(playlistData) {

        this.url = this.baseUrl + 'api/PlayLists/UpdatePlayListImage';
        return this.http.post(this.url, playlistData);
    }

    getArtistStatus(ArtistId) {
        this.url = this.baseUrl + 'api/Artists/GetArtistStatus?ArtistId=' + ArtistId
        return this.http.get(this.url, ArtistId)
    }
    getAdminStats() {
        this.url = this.baseUrl + 'api/TobyHomes/GetAdminStats';
        return this.http.get(this.url)
    }
    ArtistSongsById(ArtistId) {
        this.url = this.baseUrl + 'api/Artists/GetArtistsongById?ArtistId=' + ArtistId;
        return this.http.get(this.url, ArtistId)
    }

    ArtistSongsByIdfooter(ArtistId, userId) {
        this.url = this.baseUrl + 'api/Artists/GetArtistsongById?ArtistId=' + ArtistId + '&UserId=' + userId;
        return this.http.get(this.url, ArtistId)
    }

    GetMostPlayedSongsByArtistId(ArtistId, PageSize, PageNo) {
        this.url = this.baseUrl + 'api/Artists/GetMostPlayedSongByArtistsId?ArtistId=' + ArtistId + '&PageSize=' + PageSize + '&PageNo=' + PageNo
        return this.http.get(this.url, ArtistId)
    }

    GetMostLikedAlbumandSongsByArtistId(ArtistId) {
        this.url = this.baseUrl + 'api/Artists/GetMostLikedAlbumandSongByArtistsId?ArtistId=' + ArtistId
        return this.http.get(this.url, ArtistId)
    }

    // getmostplayedAlbumsByArtistId(ArtistId) {
    //     this.url = this.baseUrl + 'api/Albums/getmostplayedAlbumsByArtistId?ArtistId=' + ArtistId
    getmostplayedAlbumsByArtistId(ArtistId, PageSize, PageNo) {
        this.url = this.baseUrl + 'api/Albums/getmostplayedAlbumsByArtistId?ArtistId=' + ArtistId + '&PageSize=' + PageSize + '&PageNo=' + PageNo
        return this.http.get(this.url)
    }

    getMostLikesAlbumsSongsByArtistId(ArtistId, PageSize, PageNo) {
        this.url = this.baseUrl + 'api/Artists/GetMostLikesAlbumsSongsByArtistId?ArtistId=' + ArtistId + '&PageSize=' + PageSize + '&PageNo=' + PageNo
        return this.http.get(this.url, ArtistId)
    }

    getDashboardArtistStatus(ArtistId) {
        this.url = this.baseUrl + 'api/Artists/GetDashboardArtistStatus?ArtistId=' + ArtistId
        return this.http.get(this.url)
    }

    updateCountnew(SongId, userId) {
        this.url = this.baseUrl + 'api/Songs/UpdatePlayCount?SongId=' + SongId + '&UserId=' + userId;
        return this.http.post(this.url, SongId, userId)
    }

    gethomeimages(userId) {
        this.url = this.baseUrl + 'api/TobyHomes/GetHomeImages?UserId=' + userId;
        return this.http.get(this.url)
    }

    getMostPlayedSongs(userId) {
        this.url = this.baseUrl + 'api/TobyHomes/GetMostPlayedSongs?UserId=' + userId;
        return this.http.get(this.url)
    }

    getFavouritesAlbum(userId, albumId) {
        this.url = this.baseUrl + 'api/Albums/GetFavouriteAlbum?userId=' + userId + '&albumId=' + albumId;
        return this.http.get(this.url)
    }

    AddPlayListMultiplex(playlistData) {
        debugger

        this.url = this.baseUrl + 'api/PlayLists/AddUpdateMultipleSongPlayList';
        return this.http.post(this.url, playlistData);
    }
    getAllFaqs() {
        this.url = this.baseUrl + 'api/TobyHomes/GetAllFAQs';
        return this.http.get(this.url)
    }
    getFaqById(faqId) {
        this.url = this.baseUrl + 'api/TobyHomes/GetFAQById?FaqId=' + faqId;
        return this.http.get(this.url)
    }
    AddUpdateFaq(faq) {
        this.url = this.baseUrl + 'api/TobyHomes/AddUpdateFAQ';
        return this.http.post(this.url, faq);
    }
    DeleteFaq(faqId) {
        debugger
        this.url = this.baseUrl + 'api/TobyHomes/DeleteFAQ?FaqId=' + faqId;
        return this.http.post(this.url, faqId);
    }

    getTopNewArtistAlbumSongs(userId) {
        this.url = this.baseUrl + 'api/TobyHomes/GetTopNewArtistAlbumSongs?UserId=' + userId;
        return this.http.get(this.url)
    }
    LikePlaylist(playlistId,userId){
        this.url = this.baseUrl + 'api/PlayLists/LikePlaylist?PlaylistId=' + playlistId + '&UserId=' + userId;
        return this.http.post(this.url, playlistId, userId)
    }
    FavPlaylist(playlistId,userId){
        this.url = this.baseUrl + 'api/PlayLists/FavPlaylist?PlaylistId=' + playlistId + '&UserId=' + userId;
        return this.http.post(this.url, playlistId, userId)
    }
    LikeAdminPlaylist(playlistId,userId){
        this.url = this.baseUrl + 'api/PlayLists/LikeAdminPlaylist?PlaylistId=' + playlistId + '&UserId=' + userId;
        return this.http.post(this.url, playlistId, userId)
    }
    FavAdminPlaylist(playlistId,userId){
        this.url = this.baseUrl + 'api/PlayLists/FavAdminPlaylist?PlaylistId=' + playlistId + '&UserId=' + userId;
        return this.http.post(this.url, playlistId, userId)
    }

    updatePlaylistplaycount(playlistId, songId,userId , IsUserPlaylist) {
        debugger;
        this.url = this.baseUrl + 'api/PlayLists/UpdatePlaylistPlayCount?PlaylistId=' + playlistId + '&SongId=' + songId +'&UserId=' + userId + '&IsUserPlaylist=' + IsUserPlaylist;
        return this.http.post(this.url,playlistId,songId);
    }
}

