import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AlbumService } from 'src/app/Shared/album.service';
import { HomeService } from 'src/app/Shared/home.service';
import { ConfigService } from 'src/app/app.config';
import { Faq } from 'src/app/models/faq';
import { Users } from 'src/app/models/users';
import { CKEditorComponent, CKEditorModule } from '@ckeditor/ckeditor5-angular';
// import { ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo } from 'ckeditor5';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';


@Component({
  selector: 'app-add-update-faq',
  templateUrl: './add-update-faq.component.html',
  styleUrls: ['./add-update-faq.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AddUpdateFaqComponent implements OnInit {
  faqDetails = new Faq();
  faqId: any;
  faq: any;
  numbers: any;
  title = "Add";
  edit: boolean = true;
  userDetails = new Users();
  ckeConfig: any;
  @ViewChild("myckeditor") ckeditor: any;
  public editorConfig = {
    toolbar: [
      'heading', '|',
      'bold', 'italic', 'underline', 'strikethrough', '|',
      'link', 'bulletedList', 'numberedList', 'blockQuote', 'codeBlock', '|',
      'insertTable', 'imageUpload', 'mediaEmbed', '|',
      'alignment', 'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', '|',
      'undo', 'redo'
    ],
    image: {
      toolbar: [
        'imageTextAlternative', 'imageStyle:full', 'imageStyle:side'
      ]
    },
    table: {
      contentToolbar: [
        'tableColumn', 'tableRow', 'mergeTableCells'
      ]
    }
  };


  faqData: any[];
  public Editor = ClassicEditor;
  faqExist: boolean = true;

  constructor(private formBuilder: FormBuilder, private albumService: AlbumService,
    //public location: Location,
    public translate: TranslateService,
    private spinner: NgxSpinnerService,
    private configService: ConfigService,
    private _router: Router,
    private toastr: ToastrService, private route: ActivatedRoute, public _homeService: HomeService) {
    var i = 1
    this.numbers = new Array(50).fill(0).map((x, i) => i + 1);
  }


  ngOnInit() {
    debugger
    this.getAllFaqs();
    if (this.route.snapshot.paramMap.get("id")) {
      this.title = "Edit";
      this.faqDetails.faqId = this.route.snapshot.paramMap.get("id");
      if (this.faqDetails.faqId) {
        setTimeout(() => {
          this.getFaqById(this.faqDetails.faqId);
        }, 50);
      }
    }
    else {
      this.faqDetails.faqId = 0;
      this.faqDetails.faqDescription = "";
    }
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    // this.ckeConfig = {
    //   allowedContent: false,
    // //   extraPlugins: 'divarea',
    //   forcePasteAsPlainText: true,
    //   removeButtons:'Source,NewPage,Save,Preview,Templates,PasteText,PasteFromWord,Find,Replace,SelectAll,SpellChecker,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Underline,Strike,Subscript,Superscript,CopyFormat,Blockquote,CreateDiv,Link,Unlink,Anchor,Flash,Table,Smiley,IFrame,Styles,Format,Font,Maximize,ShowBlocks,About,HorizontalRule,IFrame,SpecialChar,PageBreak,Language,TextDirection,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,FontSize,BidiLtr,BidiRtl',
    //   width : 600
    // };
  }
  getFaqById(id) {
    this._homeService.getFaqById(id).subscribe((faq: any) => {
      this.edit = false;
      this.faq = faq.faqDetails;
      this.faqDetails.faqTitle = this.faq.faqTitle;
      this.faqDetails.faqDescription = this.faq.faqDescription;
      this.faqDetails.faqOrder = this.faq.faqOrder;
      this.faqDetails.faqType = this.faq.faqType;
      //console.log(faq, this.faq)
    })
  }
  onSubmit(f) {
    debugger
    const IsfaqExist = this.isFaqExist(this.faqDetails.faqOrder, this.faqDetails.faqType);
    if (this.faqDetails.faqId) {
      this.faqDetails.UpdatedBy = this.userDetails.userId;
      if (IsfaqExist) {
        return false;

      }
    }
    else {
      this.faqDetails.CreatedBy = this.userDetails.userId;

      if (IsfaqExist) {
        // this.toastr.error("FAQ Order Already Exist.");
        return false;
      }

    }

    this._homeService.AddUpdateFaq(this.faqDetails).subscribe((result: any) => {
      console.log(result);
      if (result.isSuccess) {
        this.toastr.success(result.message)
        this._router.navigate(['/admin/admin-faq']);
      }
    }, err => {
      this.toastr.error(err)
      //this.spinner.hide();
    });
  }
  onChange($event: any): void {
  }

  cancel() {
    this._router.navigate(['/admin/admin-faq']);
  }

  getAllFaqs() {
    this._homeService.getAllFaqs().subscribe((faq: any) => {
      console.log(faq)
      this.faqData = faq.faqDetails;
      //this.truncatedText = this.faqDetails.map(text => this.getFirstSentence(text.faqTitle));
    })
  }

  isFaqExist(faqOrderId: any, faqType: any) {

    let exists = this.faqData.find(
      faq => faq.faqOrder == faqOrderId && faq.faqType == faqType
    ) !== undefined;
    if (this.faqDetails.faqId > 0) {
      exists = this.faqData.find(
        faq => faq.faqOrder == faqOrderId && faq.faqType == faqType && faq.faqId != this.faqDetails.faqId
      ) !== undefined;

    }

    if (exists) {
      this.toastr.error("FAQ Order Already Exist.");
      this.faqExist = true;
      return true;
    }

    return false;
  }

}
