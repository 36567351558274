import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { LoadScriptService } from 'src/app/loadScript.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SongsService } from 'src/app/Shared/songs.service';
import { ToastrService } from 'ngx-toastr';
import { Users } from '../../models/users';
import { Pagination } from 'src/app/models/pagination';
import { $ } from 'protractor';
import { AlbumService } from 'src/app/Shared/album.service';
import { Approvalstatus } from '../../models/approvalstatus';
import { PaginationServiceForSongs } from 'src/app/Shared/paginationserviceForSongs.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from "ngx-spinner";
import { Playlist } from 'src/app/models/playlist';
import { HomeService } from "../../Shared/home.service";
import { NgForm } from '@angular/forms';
import { FeaturedSong, RecommendSong } from 'src/app/models/song';
@Component({
  selector: 'app-songs',
  templateUrl: './songs.component.html',
  styleUrls: ['./songs.component.css']
})
export class AdminSongsComponent implements OnInit {
  @ViewChild('closeBtn') closeBtn: ElementRef;
  @ViewChild('f') form: NgForm;
  @ViewChild('g') forms: NgForm;
  @ViewChild('s') forme: NgForm;

  color: any;
  albumname: any = "";
  imageZoom = [];
  npPageIndex: boolean = false;
  AlbumId: any = 0;
  userDetails = new Users();
  //pageIndex: any = 1;
  pageSize: any = 50;
  sogsLists: any;
  path: any;
  searchForsong;
  pageField = [];
  Checkrecords: boolean = false;
  prev: boolean = false;
  next: boolean = false;
  activePage: boolean[] = [];
  searchHistory: boolean = false;
  pageNumber: any;
  totalPage: number;
  tempPageIndex: number;
  ArtistId: any = 0;
  first: number = Pagination.first;
  second: number = Pagination.second;
  third: number = Pagination.third;
  fourth: number = Pagination.fourth;
  fifth: number = Pagination.fifth;
  firstrow: boolean = false;
  secondrow: boolean = false;
  thirdrow: boolean = false;
  fourthrow: boolean = false;
  fifthrow: boolean = true;
  arrow: boolean;
  songPerPage;
  createchecked: boolean = true;
  updateextchecked: boolean;
  selectSongCheckbox = [];
  loginasartist: boolean = true;
  columname: any;
  sortedorder: any = "desc";
  searchname: any;
  tempData: number = 1;
  tempArray = [];
  tempArrayPath: any;
  tempDataArray: any;
  playlist = new Playlist();
  FeaturedSong = new FeaturedSong();
  RecommendSong = new RecommendSong();
  index;
  playd = [];
  //Pagination Variables
  pageNumberPerPage = 0;
  pageNumberShow = Pagination.displayNoOfPagesPerPage;
  temppage: number = 0;
  disabledNextBtn: boolean;
  disabledPrevBtn: boolean = true;
  exactPageList: any;
  prevtrue: boolean;
  nexttrue: boolean;
  //currentPage = 1;
  showNoOfCurrentPage: any = 1;
  showPageOnlyOntabsChange: boolean = true;
  paginationData: number;
  pageIndex: any = 1;
  fileToUpload: File = null;
  files: any;
  noImage: any;
  approvalstatus_submit: any;
  approvalstatus_rejected: any;
  approvalstatus_resubmit: any;
  approvalstatus_approved: any;
  approvalstatus_delete: any;
  noDataFound: boolean = false;
  playListDetails: any;
  totalcount;
  song: any;
  imgURL: any;
  createfile: boolean;
  isChecked: boolean[] = [];
  isCheckedfea: boolean[] = [];
  isCheckedrecmd: boolean[] = [];
  placeholder: string;
  AdminSongid: any;
  selectedSongIds: any[] = [];

  constructor(private loadScript: LoadScriptService, private router: Router, private _songsService: SongsService, private toastr: ToastrService
    , private albumService: AlbumService, private route: ActivatedRoute, private spinner: NgxSpinnerService,
    public paginationService: PaginationServiceForSongs, public translate: TranslateService, private homeService: HomeService) {
    if (this.route.snapshot.paramMap.get("id2") == null)
      this.AlbumId = 0;
    else {
      this.AlbumId = this.route.snapshot.paramMap.get("id2");
      this.ArtistId = this.route.snapshot.paramMap.get("id1");
    }
  }

  ngOnInit() {
    //Service to load js file runtime
    this.albumService.artistId = 0;
    this.albumService.albumId = 0;
    this.approvalstatus_submit = Approvalstatus.SUBMIT;
    this.approvalstatus_rejected = Approvalstatus.REJECTED;
    this.approvalstatus_resubmit = Approvalstatus.RESUBMIT;
    this.approvalstatus_approved = Approvalstatus.APPROVED;
    this.approvalstatus_delete = Approvalstatus.DELETED;
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    if (this.userDetails["artistId"] != undefined) {
      this.ArtistId = this.userDetails["artistId"];
    }
    this.freshplaylist();
    this.loadScript.getServiceBaseUrl();
    this.paginationService.temppage = this.paginationService.temppage;
    if (this.paginationService.currentpageee != null || this.paginationService.currentpageee != undefined) {
      this.searchname = this._songsService.searchText;
      this.paginationService.currentPage = this.paginationService.currentpageee
      this.activePage[this.paginationService.indexxx] = true;
    }
    else {
      this.searchname = this._songsService.searchText;
      this.paginationService.currentPage = this.paginationService.currentPage;
      this.activePage[0] = true;
    }
    this.paginationService.showPageOnlyOntabsChange = this.paginationService.showPageOnlyOntabsChange;

    if (this.searchname !== "") {
      this.paginationService.temppage = 0
      this.paginationService.currentPage = 1;
      this.activePage[0] = true;
    }
    this.pageIndex = this.paginationService.currentPage;
    this.searchname = this._songsService.searchText;

    this.GetSongsDetails();
    this.refreshplaylist();
    this.placeholder = 'Upload image'

    // this.song.thumbImage;
  }

  addSongs() {
    this.albumService.artistId = this.ArtistId;
    this.albumService.albumId = this.AlbumId;
    this.router.navigate(['/admin/songs/add-songs']);

  }
  searchSongs($event, i) {
    this._songsService.searchText = $event;
    this.Checkrecords = false;
    if ($event == "") {
      this.pageField = [];
      this.pageIndex = 1;
      this.tempPageIndex = 1;
      // this.GetSongsDetails();
      this.prev = false;
      this.next = false;
      this.searchHistory = false;
      this.sogsLists = [];
      this.searchname = "";
      this.sogsLists = this.tempArray;
      this.path = this.tempArrayPath;
      this.getPageNumber(this.tempDataArray);
      this.getRows(this.fifth);

    }
    if ($event !== '') {
      this.searchname = $event;
      this.pageField = [];
      this.pageIndex = 1;
      this.paginationService.temppage = 0;
      //this.npPageIndex=true;
      this.GetSongsDetails();
      for (var x = 0; x < this.totalPage + 1; x++) {
        this.activePage[x] = false;
      }
      if (i == 0) {
        this.activePage[0] = true;
      } else {
        this.activePage[i] = true;
      }
    }
  }

  selectPhoto(file: FileList) {

    this.playlist.thumbImage = file.item(0).name;

    if (this.playlist.thumbImage == '') {
      this.placeholder = 'Upload image';
    }
    else {
      this.placeholder = this.playlist.thumbImage
    }
    this.fileToUpload = file.item(0);
    var File = this.fileToUpload.name;
    this.playlist.FileName = File.split('.')[0];
    this.files = File.split('.');
    this.playlist.FileType = this.files[this.files.length - 1];
    this.playlist.FileType = File.split('.')[1];
    this.noImage = false;
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imgURL = event.target.result;


    }
    reader.readAsDataURL(this.fileToUpload);
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.playlist.Base64Data = btoa(binaryString);

  }


  GetSongsDetails() {

    this.spinner.show();
    this._songsService.GetSongsDetails(this.ArtistId, this.AlbumId, this.columname, this.sortedorder, this.searchname, this.pageIndex, this.pageSize).subscribe((data: any) => {
      this.totalcount = data.count;
      if (this.tempData == 1) {
        this.tempDataArray = data;
        this.tempArray = data.artistDetails;
        this.tempArrayPath = data.path;
        // this.paginationService.temppage = 0;
      }
      // this.paginationService.temppage = 0;

      if (this.AlbumId != 0 && data.isSuccess == "true") {
        var str3 = "(By ";
        this.albumname = str3.concat(data.songs[0].albumName, ')');
      }

      this.sogsLists = data.songs;
      console.log('songtabledetail', this.sogsLists)
      this.path = data.path;

      this.getPageNumber(data);

      this.spinner.hide();
    }, err => {
      this.toastr.error(err)
      this.spinner.hide();
    });
  }


  getPageNumber(data) {
    if (data.count == 0) {
      this.pageField = [];
      this.noDataFound = true;
      this.Checkrecords = true;
    } else {
      this.Checkrecords = false;
      this.paginationData = Number(data.count / this.pageSize);
      let tempPageData = this.paginationData.toFixed();
      if (Number(tempPageData) < this.paginationData) {
        this.exactPageList = Number(tempPageData) + 1;
        this.paginationService.exactPageList = this.exactPageList;
      } else {
        this.exactPageList = Number(tempPageData);
        this.paginationService.exactPageList = this.exactPageList
      }
      this.paginationService.pageOnLoad();
      this.pageField = this.paginationService.pageField;
    }

  }


  editSongDetails(songId) {
    this._songsService.searchText = this.searchname;
    this.router.navigate([`/admin/songs/update-songs/` + songId + ``]);
  }
  deleteSong(songId) {
    if (confirm('Please note! This action can NOT be undone. Are you sure you want to delete?')) {

      this._songsService.deleteSong(songId).subscribe((response) => {

        if (response.isSuccess) {
          this.toastr.success("Song has been deleted successfully");
          this.GetSongsDetails();
        }
      }, err => {
        this.toastr.error(err)
        this.spinner.hide();
      });
    }
  }

  getRows(page) {
    var k;
    var lis = document.getElementById("page1").getElementsByTagName("li");
    for (k = 0; k < lis.length; k++) {
      lis[k].removeAttribute("style");
    }
    if (page == Pagination.first) {
      this.firstrow = true;
      this.secondrow = false;
      this.thirdrow = false;
      this.fourthrow = false;
      this.fifthrow = false;
      this.pageIndex = 1;
      this.paginationService.temppage = 0;
      this.pageSize = Pagination.first;
      this.activePage[0] = true;
      this.GetSongsDetails();

    } else if (page == Pagination.second) {
      this.firstrow = false;
      this.secondrow = true;
      this.thirdrow = false;
      this.fourthrow = false;
      this.fifthrow = false;
      this.pageIndex = 1;
      this.paginationService.temppage = 0;
      this.pageSize = Pagination.second;
      this.activePage[0] = true;
      this.GetSongsDetails();

    }
    else if (page == Pagination.third) {
      this.firstrow = false;
      this.secondrow = false;
      this.thirdrow = true;
      this.fourthrow = false;
      this.fifthrow = false;
      this.pageIndex = 1;
      this.paginationService.temppage = 0;
      this.pageSize = Pagination.third;
      this.activePage[0] = true;
      this.GetSongsDetails();

    }
    else if (page == Pagination.fourth) {
      this.firstrow = false;
      this.secondrow = false;
      this.thirdrow = false;
      this.fourthrow = true;
      this.fifthrow = false;
      this.pageIndex = 1;
      this.paginationService.temppage = 0;
      this.pageSize = Pagination.fourth;
      this.activePage[0] = true;
      this.GetSongsDetails();

    }
    else if (page == Pagination.fifth) {
      this.firstrow = false;
      this.secondrow = false;
      this.thirdrow = false;
      this.fourthrow = false;
      this.fifthrow = true;
      this.pageIndex = 1;
      this.paginationService.temppage = 0;
      this.pageSize = Pagination.fifth;
      this.activePage[0] = true;
      this.GetSongsDetails();

    }
  }

  showNextSongs(page, i) {
    this.pageIndex = page;
    this.paginationService.currentpageee = this.pageIndex
    this.paginationService.indexxx = i;
    this.activePage = [];
    this.activePage[i] = true;
    this.GetSongsDetails();


  }

  nextPage() {

    if (this.paginationService.disabledNextBtn == false) {
      this.activePage = [];
      this.activePage[0] = true;
      this.paginationService.nextPage();
      this.pageIndex = this.paginationService.pageField[0];
      this.GetSongsDetails();
    }

  }

  previous() {
    if (this.paginationService.disabledPrevBtn == false) {
      this.activePage = [];
      this.activePage[0] = true;
      this.paginationService.previous();
      this.pageIndex = this.paginationService.pageField[0];
      this.GetSongsDetails();
    }

  }

  orderBy(coloumnName) {
    var i = 0;
    var t = document.getElementsByTagName("th");
    for (i = 0; i < t.length; i++) {
      t[i].removeAttribute("style");
    }
    // ="#bec1c3";
    let r = document.getElementById(coloumnName);
    r.style.color = "orange";

    this.columname = coloumnName;
    if (this.sortedorder == "asc") {
      this.sortedorder = "desc";

    } else {
      this.sortedorder = "asc";
    }

    this.GetSongsDetails();
  }



  picbig(i) {
    this.imageZoom[i] = false;
  }
  pic(i) {
    this.imageZoom[i] = true;
  }
  viewsong(songId) {
    this.router.navigate([`admin/viewsong/` + songId + ``]);
  }
  checked() {
    this.createchecked = true;
    this.createfile = true;
    this.updateextchecked = false;
    this.playlist.Name = '';
    this.playlist.thumbImage = '';
  }
  updatechecked() {
    this.updateextchecked = true;
    this.createchecked = false;
    this.playlist.PlaylistId = undefined;
  }
  onSubmit() {
    debugger;
    // this.checkedEvnt()
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.playlist.UserId = this.userDetails.userId;
    if(this.imgURL != null){
      if (this.noImage) {
        this.playlist.Base64Data = null;
      }
      else {
        this.playlist.Base64Data = this.imgURL.split(",")[1];
      }
    }
    
    this.spinner.show();
    console.log(this.playlist);
    this.homeService.AddUpdateAdminPlayList(this.playlist).subscribe((res: any) => {
      if (res.status == 200) {
        if (this.playlist.PlaylistId! == 0) {

          this.freshplaylist();
          this.toastr.success("Playlist added successfully");
          this.isChecked = [];
          this.createchecked = true;
          this.updateextchecked = false;
          this.form.reset();
          this.form.resetForm();


        } else {
          this.closeBtn.nativeElement.click();
          this.freshplaylist();
          this.toastr.success("Playlist updated successfully");
          this.createchecked = true;
          this.updateextchecked = false
          this.form.reset();
          this.form.resetForm();

          this.isChecked = [];
        }
        this.spinner.hide();
      }
      else {
        this.toastr.success("Playlist Already Exists");
        this.isChecked = [];

      }
      this.spinner.hide();
    }, err => {
      this.toastr.error(err)
      this.spinner.hide();
    });
  }
  onSubmitforupdate() {
    debugger;
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.playlist.UserId = this.userDetails.userId;
    var AdminSongids = [];
    AdminSongids.push(this.AdminSongid.toString());
    this.playlist.AdminSongId = AdminSongids;
    this.spinner.show();
    this.homeService.AddUpdateAdminPlayList(this.playlist).subscribe((res: any) => {
      if (res.status == 200) {
        if (this.playlist.PlaylistId! == 0) {
          this.freshplaylist();
          this.toastr.success("Playlist added successfully");
          this.isChecked = [];
          this.playlist.Name = '';
          this.createchecked = true;
          this.updateextchecked = false
          this.form.resetForm();
          this.form.reset();
        } else {
          this.closeBtn.nativeElement.click();
          this.freshplaylist();
          this.toastr.success("Playlist updated successfully");
          this.playlist.Name = '';
          this.isChecked = [];
          this.createchecked = true;
          this.updateextchecked = false
          this.form.resetForm();
          this.form.reset();
        }
        this.spinner.hide();
      }
      else {
        this.toastr.success("Playlist Already Exists");
        this.spinner.hide();

      }
      this.spinner.hide();
    }, err => {
      this.toastr.error(err)
      this.spinner.hide();
    });
  }
  fetchSongId(i, index) {
    debugger;
    console.log('', this.playlist.AdminSongId.includes(i))
    if (this.playlist.AdminSongId.includes(i)) {
      this.playlist.AdminSongId = this.playlist.AdminSongId.filter(x => x != i)
      this.isChecked[index] = false;

    } else {
      this.isChecked[index] = true;
      this.selectedSongIds.push(i.toString());
      this.playlist.AdminSongId = this.selectedSongIds;
      //console.log('', (this.playlist.AdminSongId).toString());
      // //this.playlist.AdminSongId = [];
      // this.playlist.AdminSongId = i;
      // console.log('',  this.playlist.AdminSongId);
      // this.playlist.AdminSongId.push(i);
      // this.AdminSongid = this.playlist.AdminSongId.push(i);
      // console.log('', this.AdminSongid);
    }
  }

  fetchPlaylist(item , index){
    debugger;
    this.AdminSongid = item.songId ;
    this.playlist.AdminSongId.push(this.AdminSongid);
  }
  fetchUpdatedPlaylist(item , index){
    //this.playlist.PlaylistId = item.playlistId;
    this.AdminSongid = item.songId;
    this.getplaylistbysongid(item.songId);
    this.playlist.AdminSongId.push(this.AdminSongid);
  }
  refreshplaylist() {
    this.homeService.getadminPlaylist().subscribe((data: any) => {
      this.playListDetails = data.playListDetails;
      if (this.playListDetails.length >= 10) {
        for (var i = this.index; i < 10; i++) {
          this.playd[i] = this.playListDetails[i];
          this.homeService.playId[i] = this.playListDetails[i];
        }
      } else {
        for (var i = this.index; i < this.playListDetails.length; i++) {
          this.playd[i] = this.playListDetails[i];
          this.homeService.playId[i] = this.playListDetails[i];
        }
      }
    });
  }
  freshplaylist() {
    this.homeService.getadminPlaylist().subscribe((data: any) => {
      this.playListDetails = data.playListDetails;
      console.log('playlist', this.playListDetails);
    })
  }
  cleartextbox() {
    this.playlist.Name = '';
    this.playlist.thumbImage = '';
    this.playlist.PlaylistId = undefined;
  }

  isfeaturedSong(e, item) {
    debugger;
    this.isCheckedfea = e.target.checked;
    if (this.userDetails["userId"] != undefined) {
      this.FeaturedSong.createdBy = this.userDetails["userId"];
      this.FeaturedSong.updatedBy = this.userDetails["userId"];
    }
    this.FeaturedSong.SongId = item.songId;
    this.FeaturedSong.IsFeaturedSong = e.target.checked;
    if (item.featuredSongsId != 0) {
      this.FeaturedSong.FeaturedSongsId = item.featuredSongsId;
    }
    console.log(this.FeaturedSong);
    this._songsService.addupdateFeaturedsong(this.FeaturedSong).subscribe((res: any) => {
      if (res.status == 200) {
        console.log(res);
        if (res.feaSonDetail.isFeaturedSong == 0) {
          this.toastr.success("IsFeaturedSong added successfully");
          this.spinner.hide();
        } else {
          this.toastr.success("IsFeaturedSong updated successfully");
          this.spinner.hide();
        }
      }
    }, err => {
      this.toastr.error(err);
      this.spinner.hide();
    });
  }

  isrecmdSongs(e, item) {
    debugger;
    this.isCheckedrecmd = e.target.checked;
    if (this.userDetails["userId"] != undefined) {
      this.RecommendSong.createdBy = this.userDetails["userId"];
      this.RecommendSong.updatedBy = this.userDetails["userId"];
    }
    this.RecommendSong.SongId = item.songId;
    this.RecommendSong.IsRecommendedSong = e.target.checked;
    if (item.recommendedSongsId != 0) {
      this.RecommendSong.RecommendedSongsId = item.recommendedSongsId;
    }
    console.log(this.FeaturedSong);
    this._songsService.addupdateRecommendsong(this.RecommendSong).subscribe((res: any) => {
      if (res.status == 200) {
        console.log(res);
        if (this.RecommendSong.RecommendedSongsId == 0) {
          this.toastr.success("IsRecommendedSong Added  successfully");
          this.spinner.hide();
        } else {
          this.toastr.success("IsRecommendedSong updated successfully");
          this.spinner.hide();
        }
      }
    }, err => {
      this.toastr.error(err);
      this.spinner.hide();
    });
  }

  onSubmitplylist() {
    debugger;
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.playlist.UserId = this.userDetails.userId;
    if (this.noImage) {
      this.playlist.Base64Data = null;
    }
    else {
      this.playlist.Base64Data = this.imgURL.split(",")[1];
    }
    var AdminSongids = [];
    AdminSongids.push(this.AdminSongid.toString());
    this.playlist.AdminSongId = AdminSongids;
    //this.spinner.show();
    console.log('abc', this.playlist);
    this.homeService.AddUpdateAdminPlayList(this.playlist).subscribe((res: any) => {
      if (res.status == 200) {
        if (this.playlist.PlaylistId! == 0) {
          this.freshplaylist();
          this.toastr.success("Playlist added successfully");
          this.isChecked = [];
          this.createchecked = true;
          this.updateextchecked = false
          this.form.reset();
          this.form.resetForm();
          this.closeBtn.nativeElement.click();
          //this.GetSongsDetails();

        } else {
          this.closeBtn.nativeElement.click();
          this.freshplaylist();
          this.toastr.success("Playlist updated successfully");
          this.createchecked = true;
          this.updateextchecked = false;
          this.form.reset();
          this.form.resetForm();
          this.closeBtn.nativeElement.click();
          this.isChecked = [];
          //this.GetSongsDetails();

        }
        this.spinner.hide();
      }
      else {
        this.toastr.success("Playlist Already Exists");
        this.isChecked = [];

      }
      this.spinner.hide();
    }, err => {
      this.toastr.error(err)
      this.spinner.hide();
    });
  }
  getplaylistbysongid(songid){
    debugger;
    this._songsService.getPlaylistBySongid(songid).subscribe((data: any) => {
      console.log(data);
      if(data.playlistDetails.length != 0){
        this.playlist.PlaylistId = data.playlistDetails[0].playlistId;
      }
      else{
        this.playlist.PlaylistId = undefined;
      }
      
    })
  }
}



