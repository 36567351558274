import { Component, OnInit } from '@angular/core';
import { Album } from '../../../models/album';
import { AlbumService } from 'src/app/Shared/album.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ArtistsService } from 'src/app/Shared/artists.service';
import { Users } from '../../../models/users';
import { Location } from '@angular/common';
import { IfStmt, debugOutputAstAsTypeScript } from '@angular/compiler';
import { Approvalstatus } from 'src/app/models/approvalstatus';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfigService } from 'src/app/app.config';
@Component({
  selector: 'app-add-update-album',
  templateUrl: './add-update-album.component.html',
  styleUrls: ['./add-update-album.component.css']
})
export class AddUpdateAlbumComponent implements OnInit {
  imgURL: any;
  imgURLS: any;
  imgURLS1: any;
  imgURLS2: any;
  imgURLS3: any;
  imgURLS4: any;
  imgURLS5: any;
  fileToUpload: File;
  isDesabled: boolean = false;
  userDetails = new Users();
  albumtitle: any = "Add Album";
  albumImage: any;
  noImage: boolean = true;
  noImages: boolean = true;
  noImages1: boolean = true;
  noImages2: boolean = true;
  noImages3: boolean = true;
  noImages4: boolean = true;
  noImages5: boolean = true;
  isdownload = true;
  isdownloadNew = true;
  isdownloadNew1 = true;
  isdownloadNew2 = true;
  isdownloadNew3 = true;
  isdownloadNew4 = true;
  isdownloadNew5 = true;
  albumDetails = new Album();
  addAlbumProfile: boolean = true;
  isChecked: boolean = true;
  path: any;
  artists: any;
  albumList: any;
  albumphoto;
  files: any;
  files1: any;
  files2: any;
  files3: any;
  files4: any;
  files5: any;
  file: any;
  startyear: any;
  Years: any[] = [];
  Index: any = 0;
  loginasartist: boolean = false;
  isSelected: boolean;
  fileToUploads: File;
  fileToUploads1: File;
  fileToUploads2: File;
  fileToUploads3: File;
  fileToUploads4: File;
  fileToUploads5: File;
  fileType: any;
  albumd: any;
  constructor(private albumService: AlbumService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _artistService: ArtistsService,
    public location: Location,
    public translate: TranslateService,
    private spinner: NgxSpinnerService,
    private configService: ConfigService
  ) {

    this.albumDetails.artistId = this.albumService.artistId;
    this.albumDetails.albumId = this.route.snapshot.paramMap.get("id");
    this.startyear = configService.getstartDate();
    this.dropdownyear();
    // this. arabicRegex = '[\u0600-\u06FF]';
  }

  ngOnInit() {
    this.albumDetails.isComingUp = this.isChecked;
    this.getAllArtists();
    if (this.albumDetails.artistId == 0) {
      this.isDesabled = false;
    } else {
      this.isDesabled = true;
    }

    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    if (this.userDetails["artistId"] != undefined) {
      this.albumDetails.artistId = this.userDetails["artistId"];
      this.isDesabled = true;
      this.loginasartist = true;
    }

    if (this.albumDetails.albumId != null) {

      this.albumtitle = "Update Album";
      this.isDesabled = true;
      this.addAlbumProfile = false;
      this.albumService.GetAlbumsbyalbum(this.albumDetails.albumId , this.userDetails.userId).subscribe((data: any) => {
        this.albumd = data;
        this.imgURL = data.path.concat(this.albumDetails.albumId, '/', data.albumDetails.thumbImage);
        this.imgURLS = data.path.concat(this.albumDetails.albumId, '/', data.albumDetails.thumbImageNew);
        this.imgURLS1 = data.path.concat(this.albumDetails.albumId, '/', data.albumDetails.thumbImageNew1);
        this.imgURLS2 = data.path.concat(this.albumDetails.albumId, '/', data.albumDetails.thumbImageNew2);
        this.imgURLS3 = data.path.concat(this.albumDetails.albumId, '/', data.albumDetails.thumbImageNew3);
        this.imgURLS4 = data.path.concat(this.albumDetails.albumId, '/', data.albumDetails.thumbImageNew4);
        this.imgURLS5 = data.path.concat(this.albumDetails.albumId, '/', data.albumDetails.thumbImageNew5);
        console.log('.,.,', data);
        if (data.albumDetails.isForKid == true) {
          this.isSelected = true;
        }
        else {
          this.isSelected = false;
        }

        if (data.albumDetails.thumbImageNew != null) {
          this.noImages = false;
          this.isdownload = false;
        } else {
          this.noImages = true;
        }

        if (data.albumDetails.thumbImage != null) {
          this.noImage = false;
          this.isdownloadNew = false;
        } else {
          this.noImage = true;
        }

        if (data.albumDetails.thumbImageNew1 != null) {
          this.noImages1 = false;
          this.isdownloadNew1 = false;
        } else {
          this.noImages1 = true;
        }
        if (data.albumDetails.thumbImageNew2 != null) {
          this.noImages2 = false;
          this.isdownloadNew2 = false;
        } else {
          this.noImages2 = true;
        }
        if (data.albumDetails.thumbImageNew3 != null) {
          this.noImages3 = false;
          this.isdownloadNew3 = false;
        } else {
          this.noImages3 = true;
        }
        if (data.albumDetails.thumbImageNew4 != null) {
          this.noImages4 = false;
          this.isdownloadNew4 = false;
        } else {
          this.noImages4 = true;
        }
        if (data.albumDetails.thumbImageNew5 != null) {
          this.noImages5 = false;
          this.isdownloadNew5 = false;
        } else {
          this.noImages5 = true;
        }

        this.albumDetails = data.albumDetails;
        this.isChecked = data.albumDetails.isComingUp;
        this.path = data.path;
      }, err => { this.toastr.error(err) })
    } else {
      this.albumDetails.albumId = 0;
    }
  }


  Checked(value) {
    this.albumDetails.isComingUp = value;
  }

  Checkedkid(event) {
    this.albumDetails.isforKid = event.target.checked
  }

  onSubmit() {
    debugger;
    this.spinner.show();
    if (this.noImage) {
      this.albumDetails.Base64Data = null;
    } else {
      this.albumDetails.Base64Data = this.imgURL.split(",")[1];
    }
    if (this.noImages) {
      this.albumDetails.Base64DataForNewRelease = null;
    } else {
      this.albumDetails.Base64DataForNewRelease = this.imgURLS.split(",")[1];
    }
    if (this.noImages1) {
      this.albumDetails.Base64DataForNewRelease1 = null;
    } else {
      this.albumDetails.Base64DataForNewRelease1 = this.imgURLS1.split(",")[1];
    }
    if (this.noImages2) {
      this.albumDetails.Base64DataForNewRelease2 = null;
    } else {
      this.albumDetails.Base64DataForNewRelease2 = this.imgURLS2.split(",")[1];
    }
    if (this.noImages3) {
      this.albumDetails.Base64DataForNewRelease3 = null;
    } else {
      this.albumDetails.Base64DataForNewRelease3 = this.imgURLS3.split(",")[1];
    }
    if (this.noImages4) {
      this.albumDetails.Base64DataForNewRelease4 = null;
    } else {
      this.albumDetails.Base64DataForNewRelease4 = this.imgURLS4.split(",")[1];
    }
    if (this.noImages5) {
      this.albumDetails.Base64DataForNewRelease5 = null;
    } else {
      this.albumDetails.Base64DataForNewRelease5 = this.imgURLS5.split(",")[1];
    }
    if (this.userDetails["artistId"] != undefined) {
      this.albumDetails.createdBy = this.userDetails["artistId"];
      this.albumDetails.updatedBy = this.userDetails["artistId"];

    }
    if (this.userDetails["userId"] != undefined) {
      this.albumDetails.createdBy = this.userDetails["userId"];
      this.albumDetails.updatedBy = this.userDetails["userId"];
    }

    this.albumDetails.statusId = Approvalstatus.SUBMIT;
    console.log('getalbum', this.albumDetails)
    this.albumService.addOrUpdateAlbum(this.albumDetails).subscribe((res: any) => {

      if (res.status == 200) {
        if (this.albumDetails.albumId == 0) {
          this.toastr.success("Album added successfully");
          this.spinner.hide();
        } else {
          this.toastr.success("Album updated successfully");
          this.spinner.hide();
        }
        this.location.back();
      }
    }, err => {
      this.toastr.error(err)
      this.spinner.hide();
    })
  }

  back() {
    this.location.back();
  }

  selectPhoto(file: FileList) {

    this.albumDetails.thumbImage = file.item(0).name;
    this.fileToUpload = file.item(0);
    var File = this.fileToUpload.name;
    this.albumDetails.FileName = File.split('.')[0];
    this.files = File.split('.');
    this.albumDetails.FileType = this.files[this.files.length - 1];
    this.noImage = false;
    //Show Image Preview
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imgURL = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }

  //new release
  selectPhotos(file: FileList) {
    this.albumDetails.thumbImageNew = file.item(0).name;
    this.fileToUploads = file.item(0);
    var File = this.fileToUploads.name;
    this.albumDetails.FileNames = File.split('.')[0];
    this.files = File.split('.');
    this.albumDetails.NewFileType = this.files[this.files.length - 1];
    this.noImages = false;
    //Show Image Preview
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imgURLS = event.target.result;
    }
    reader.readAsDataURL(this.fileToUploads);
  }

  selectPhotos1(file: FileList,type : any) {
    if(type == "type1"){
      this.albumDetails.thumbImageNew1 = file.item(0).name;
      this.fileToUploads1 = file.item(0);
      var File = this.fileToUploads1.name;
      this.albumDetails.FileNames1 = File.split('.')[0];
      this.files1 = File.split('.');
      this.albumDetails.NewFileType1 = this.files1[this.files1.length - 1];
      this.noImages1 = false;
      //Show Image Preview
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.imgURLS1 = event.target.result;
      }
      reader.readAsDataURL(this.fileToUploads1);
    }
    
    if(type == "type2"){
      this.albumDetails.thumbImageNew2 = file.item(0).name;
      this.fileToUploads2 = file.item(0);
      var File = this.fileToUploads2.name;
      this.albumDetails.FileNames2 = File.split('.')[0];
      this.files2 = File.split('.');
      this.albumDetails.NewFileType2 = this.files2[this.files2.length - 1];
      this.noImages2 = false;
      //Show Image Preview
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.imgURLS2 = event.target.result;
      }
      reader.readAsDataURL(this.fileToUploads2);
    }
    if(type == "type3"){
      this.albumDetails.thumbImageNew3 = file.item(0).name;
      this.fileToUploads3 = file.item(0);
      var File = this.fileToUploads3.name;
      this.albumDetails.FileNames3 = File.split('.')[0];
      this.files3 = File.split('.');
      this.albumDetails.NewFileType3 = this.files3[this.files3.length - 1];
      this.noImages3 = false;
      //Show Image Preview
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.imgURLS3 = event.target.result;
      }
      reader.readAsDataURL(this.fileToUploads3);
    }
    if(type == "type4"){
      this.albumDetails.thumbImageNew4 = file.item(0).name;
      this.fileToUploads4 = file.item(0);
      var File = this.fileToUploads4.name;
      this.albumDetails.FileNames4 = File.split('.')[0];
      this.files4 = File.split('.');
      this.albumDetails.NewFileType4 = this.files4[this.files4.length - 1];
      this.noImages4 = false;
      //Show Image Preview
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.imgURLS4 = event.target.result;
      }
      reader.readAsDataURL(this.fileToUploads4);
    }
    if(type == "type5"){
      this.albumDetails.thumbImageNew5 = file.item(0).name;
      this.fileToUploads5 = file.item(0);
      var File = this.fileToUploads5.name;
      this.albumDetails.FileNames5 = File.split('.')[0];
      this.files5 = File.split('.');
      this.albumDetails.NewFileType5 = this.files5[this.files5.length - 1];
      this.noImages5 = false;
      //Show Image Preview
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.imgURLS5 = event.target.result;
      }
      reader.readAsDataURL(this.fileToUploads5);
    }
  }

  _handleReaderLoaded(readerEvt) {
    const binaryString = readerEvt.target.result;
    this.albumDetails.Base64Data = btoa(binaryString);
  }

  getAllArtists() {
    this._artistService.GetArtists().subscribe((data: any) => {
      this.artists = data.artistDetails;
    }, err => { this.toastr.error(err) });
  }

  dropdownyear() {
    this.startyear = 1900;
    let endYear = new Date().getFullYear();
    let year = endYear - this.startyear;
    for (let i = 0; i <= year; i++) {
      this.Years[this.Index] = this.startyear + i;
      this.Index++;

    }
    this.Years = this.Years.reverse();
  }

  Downloadfile(imgtype : any) {
    if(imgtype == 'type'){
      let type = [];
      // type = this.albumd.path.concat(this.albumDetails.albumId, '/', this.albumd.albumDetails.thumbImage).split(/[\s.]+/);
      // this.fileType = type[type.length - 1];
      type = this.albumd.albumDetails.thumbImage.split(/[\s.]+/);
        this.fileType = type[type.length - 1];
      const linkSource = `data:application/${this.fileType};base64,` + this.albumd.albumDetails.thumbImageBytes;
      const downloadLink = document.createElement("a");
      const fileName =this.albumd.albumDetails.thumbImage;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
    if(imgtype == 'typenew'){
      let type = [];
      // type = this.albumd.path.concat(this.albumDetails.albumId, '/', this.albumd.albumDetails.thumbImage).split(/[\s.]+/);
      // this.fileType = type[type.length - 1];
      type = this.albumd.albumDetails.thumbImageNew.split(/[\s.]+/);
        this.fileType = type[type.length - 1];
      const linkSource = `data:application/${this.fileType};base64,` + this.albumd.albumDetails.thumbImageBytesNew;
      const downloadLink = document.createElement("a");
      const fileName =this.albumd.albumDetails.thumbImageNew;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
    if(imgtype == 'type1'){
      let type = [];
      // type = this.albumd.path.concat(this.albumDetails.albumId, '/', this.albumd.albumDetails.thumbImage).split(/[\s.]+/);
      // this.fileType = type[type.length - 1];
      type = this.albumd.albumDetails.thumbImageNew1.split(/[\s.]+/);
        this.fileType = type[type.length - 1];
      const linkSource = `data:application/${this.fileType};base64,` + this.albumd.albumDetails.thumbImageBytes1;
      const downloadLink = document.createElement("a");
      const fileName =this.albumd.albumDetails.thumbImageNew1;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
    if(imgtype == 'type2'){
      let type = [];
      // type = this.albumd.path.concat(this.albumDetails.albumId, '/', this.albumd.albumDetails.thumbImage).split(/[\s.]+/);
      // this.fileType = type[type.length - 1];
      type = this.albumd.albumDetails.thumbImageNew2.split(/[\s.]+/);
        this.fileType = type[type.length - 1];
      const linkSource = `data:application/${this.fileType};base64,` + this.albumd.albumDetails.thumbImageBytes2;
      const downloadLink = document.createElement("a");
      const fileName =this.albumd.albumDetails.thumbImageNew2;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
    if(imgtype == 'type3'){
      let type = [];
      // type = this.albumd.path.concat(this.albumDetails.albumId, '/', this.albumd.albumDetails.thumbImage).split(/[\s.]+/);
      // this.fileType = type[type.length - 1];
      type = this.albumd.albumDetails.thumbImageNew3.split(/[\s.]+/);
        this.fileType = type[type.length - 1];
      const linkSource = `data:application/${this.fileType};base64,` + this.albumd.albumDetails.thumbImageBytes3;
      const downloadLink = document.createElement("a");
      const fileName =this.albumd.albumDetails.thumbImageNew3;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
    if(imgtype == 'type4'){
      let type = [];
      // type = this.albumd.path.concat(this.albumDetails.albumId, '/', this.albumd.albumDetails.thumbImage).split(/[\s.]+/);
      // this.fileType = type[type.length - 1];
      type = this.albumd.albumDetails.thumbImageNew4.split(/[\s.]+/);
        this.fileType = type[type.length - 1];
      const linkSource = `data:application/${this.fileType};base64,` + this.albumd.albumDetails.thumbImageBytes4;
      const downloadLink = document.createElement("a");
      const fileName =this.albumd.albumDetails.thumbImageNew4;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
    if(imgtype == 'type5'){
      let type = [];
      // type = this.albumd.path.concat(this.albumDetails.albumId, '/', this.albumd.albumDetails.thumbImage).split(/[\s.]+/);
      // this.fileType = type[type.length - 1];
      type = this.albumd.albumDetails.thumbImageNew5.split(/[\s.]+/);
        this.fileType = type[type.length - 1];
      const linkSource = `data:application/${this.fileType};base64,` + this.albumd.albumDetails.thumbImageBytes5;
      const downloadLink = document.createElement("a");
      const fileName =this.albumd.albumDetails.thumbImageNew5;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
    
  }

//   downloadFile(fileUrl) {
//     var a = document.createElement('a');
//     a.href = fileUrl;
//     a.download = this.albumd.albumDetails.thumbImage; // Suggested filename
//     document.body.appendChild(a);
//     a.click();
//     document.body.removeChild(a);
//     return false; // Prevents the default link behavior
// }


}

