import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { LoadScriptService } from 'src/app/loadScript.service';
import { HomeService } from '../../Shared/home.service';
import { SongsService } from '../../Shared/songs.service';
import { PlayerService } from '../../Shared/player.service';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { Track } from '../../../../Player/ngx-audio-player/src/lib/model/track.model';
import { Playlist } from 'src/app/models/playlist';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { UserService } from 'src/app/Shared/user.service';
import { AlbumService } from 'src/app/Shared/album.service';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-allfavsongs',
  templateUrl: './allfavsongs.component.html',
  styleUrls: ['./allfavsongs.component.css']
})
export class AllfavsongsComponent implements OnInit {
  @ViewChild('f') form: NgForm;
  @ViewChild('closeBtn') closeBtn: ElementRef;
  userDetails: any;
  userId: any;
  favitems: any;
  favsongs = [];
  path: any;
  playlist = new Playlist();
  createchecked: boolean = true;
  updateextchecked: boolean;
  playListDetails: any;
  noLikeImage1: boolean[] = [];
  nofavimage1: boolean[] = [];
  songsid;
  playd = [];
  index;
  substatus: any;
  songIsthere: boolean = false;
  languageCode: any;
  theme: any;
  IsMobileReq:Boolean;
  constructor(private loadScript: LoadScriptService, private homeService: HomeService, private songsService: SongsService,
    private _playerService: PlayerService, private router: Router,
    private toastr: ToastrService,
    public translate: TranslateService,
    public userService: UserService,
    public location: Location,
    private route: ActivatedRoute,
    private playerService: AudioPlayerService,
    public _songsService: SongsService,
    private albumService: AlbumService

  ) { }

  ngOnInit() {
    this.refreshfavsong();
    this.loadScript.getServiceBaseUrl();
    let paramId = this.route.snapshot.queryParams["Id"];
    // let currentSongId =localStorage.getItem('CurrentSongDetailsId');
    // if(paramId != currentSongId){
    //   let index =this.playerService.playPauseBtn.findIndex(x=>x ==true)
    //   if(index !=-1){
    //     this.playerService.playPauseBtn[index]=false;
    //   }
    //   localStorage.setItem('CurrentSongDetailsId', paramId);
    // }
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.userId = this.userDetails.userId;
    console.log("viewsongs,.,", this.userId)
    this.IsMobileReq = this.userDetails.isMobileReq;
    this.substatus = this.userDetails.subscriptionStatus;
    this.getfavouritessongs();
    this.getplaylist();
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.languageCode = this.userDetails.languageCode;
    this.theme = this.userDetails.themeId

  }
  getplaylist() {
    this.homeService.getPlaylist(this.userDetails.userId).subscribe((data: any) => {
      this.playListDetails = data.playListDetails;
      this.homeService.getLatestPlaylist(this.playListDetails);
    });
  }
  getfavouritessongs() {
    let a = '';
    let b = 0;
    let c = 0;
    this.songsService.Getallfavsongs(this.userId, 0, a, b, c).subscribe((data: any) => {
      console.log("favsong", data)
      this.favitems = data;
      this.favsongs = this.favitems.viewAllFavouriteSongs;
      this.path = this.favitems.path;
      for (var a = 0; a < this.favsongs.length; a++) {
        if (this.favsongs[a].likeId == 0) {
          this.noLikeImage1[a] = false;
        } else {
          this.noLikeImage1[a] = true;
        }
      }
      for (var a = 0; a < this.favsongs.length; a++) {
        if (this.favsongs[a].favId == 0) {
          this.nofavimage1[a] = false;
        } else {
          this.nofavimage1[a] = true;
        }
      }

      let currentSongId = localStorage.getItem('CurrentSongId');
      let getSong = this.favsongs.findIndex(x => x.songId == currentSongId)
      if (getSong != -1) {
        let isSongPlaying = localStorage.getItem('IsPlaying');
        if (isSongPlaying == 'true' || isSongPlaying == null) {
          this.playerService.playPauseBtn[this.playerService.indexSong] = true;
        } else if (isSongPlaying == 'false') {
          this.playerService.playPauseBtn[this.playerService.indexSong] = false;
        }

      } else {
        this.playerService.playPauseBtn[this.playerService.indexSong] = false;
      }

    });
  }

  playSong(songs, index) {
    let songId = songs[index].songId;
    localStorage.setItem('CurrentSongId', songId);

    let indexForPlayPause = this.playerService.playPauseBtn.findIndex(x => x == true);
    if (indexForPlayPause == index) {
      localStorage.setItem('IsPlaying', 'false');
      this.playerService.playPauseBtn[index] = !this.playerService.playPauseBtn[index];
    }
    else {
      localStorage.setItem('IsPlaying', 'true');
      this.playerService.playPauseBtn[indexForPlayPause] = false;
      this.playerService.playPauseBtn[index] = true;
    }

    this._playerService.playSong(songs, this.path, index);
  }
  checked() {
    this.createchecked = true;
    this.updateextchecked = false;
  }
  updatechecked() {
    this.updateextchecked = true;
    this.createchecked = false;
    this.playlist.PlaylistId = undefined;
  }
  fetchSongId(i) {
    this.playlist.SongId = i;
  }
  onSubmit() {
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.playlist.UserId = this.userDetails.userId;

    this.homeService.addOrUpdateplaylist(this.playlist).subscribe((res: any) => {
      if (res.status == 200) {
        if (this.playlist.PlaylistId! == 0) {
          this.toastr.success("Playlist added successfully");
          this.createchecked = true;
          this.updateextchecked = false;
          this.form.resetForm();
          this.form.reset();
        } else {
          this.closeBtn.nativeElement.click();
          this.getplaylist();
          this.toastr.success("Playlist updated successfully");
          this.createchecked = true;
          this.updateextchecked = false
          this.form.resetForm();
          this.form.reset();
        }
      }
      else {
        this.toastr.success("Playlist Already Exists");
      }
    })
  }

  Likesongs(songId: string, UserId: string, i) {
    this.noLikeImage1[i] = !this.noLikeImage1[i];
    this.homeService.LikeSong(songId, this.userId).subscribe((res: any) => {
    }
    )
  }

  FavouriteSongs(songId: string, UserId: string, i) {
    this.nofavimage1[i] = !this.nofavimage1[i];
    this.homeService.FavouriteSongs(this.playlist.SongId, this.userId).subscribe((res: any) => {
      this.refreshfavsong()
    })

  }

  refreshfavsong() {
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.userId = this.userDetails.userId;
    this.IsMobileReq = this.userDetails.IsMobileReq;
    let a = '';
    let b = 0;
    let c = 0;
    this.songsService.Getallfavsongs(this.userId, 0, a, b, c).subscribe((data: any) => {
      this._songsService.favsongs = data.viewAllFavouriteSongs;
    })
  }
  updatecount(songId) {
    debugger;
    this.homeService.updatecount(songId).subscribe((res: any) => {
    })
  }
  IsCheckLang() {
    if (this.translate.currentLang == 'ar', this.translate.currentLang == 'as') {
      return true;
    } else {
      return false;
    }
  }

  refreshplaylist() {
    this.homeService.getPlaylist(this.userDetails.userId).subscribe((data: any) => {
      this.playListDetails = data.playListDetails;
      if (this.playListDetails.length >= 10) {
        for (var i = this.index; i < 10; i++) {
          this.playd[i] = this.playListDetails[i];
          this.homeService.playId[i] = this.playListDetails[i];
        }
      } else {
        for (var i = this.index; i < this.playListDetails.length; i++) {
          this.playd[i] = this.playListDetails[i];
          this.homeService.playId[i] = this.playListDetails[i];
        }
      }
    });
  }

  back() {
    this.location.back();
  }
  
  CheckSubscription(song, i) {
    if (this.substatus == "False") {
      document.getElementById("Subscriptioncheck").style.display = "block";
    }
    else {
      this.songIsthere = true;
      this.playSong(song, i);
    }
  }
  closeForm() {
    document.getElementById("Subscriptioncheck").style.display = "none";
    this.router.navigate(['/account/upgradeplan']);
  }
}
