import { Injectable } from '@angular/core';
import { ConfigService } from '../app.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaypalService {
  private url = '';
  private baseUrl = '';
  public searchText = '';
  constructor(private http: HttpClient, private configService: ConfigService) {
    this.baseUrl = configService.getServiceBaseUrl();
  }
  GetAllSubscriptionplan() {
    this.url = this.baseUrl + 'api/SubscriptionPlan/GetAllSubscriptionplan';
    return this.http.get(this.url);
  }
  GetSubscriptionplanbyId(SubscriptionPlanId) {
    this.url = this.baseUrl + 'api/SubscriptionPlan/GetSubscriptionDetail?SubscriptionPlanId=' + SubscriptionPlanId;
    return this.http.get(this.url);
  }
  createAuthorizationHeader(headers: Headers) {
    headers.append('Authorization', 'Basic ' +
      btoa('AYoonm7wJij_oQg63X-0Br0eTgGr5sE-kOVYox49Y8yfyIJUTv6PIZCZqoOo7_BonWlOvl8a9nYh6wQN:EDTlAw7spPcxj7dE67UwIamhlBkuKSEqTQlM6gu4xVuDnFUzrrW2VjhS0zMkGyzakjJUg-lG3AYB4-pY'));
  }
  GetsandboxData(PlanId) {
    let headers = new HttpHeaders();
    headers.append('Authorization', 'Basic Auth' +
      btoa('AYoonm7wJij_oQg63X-0Br0eTgGr5sE-kOVYox49Y8yfyIJUTv6PIZCZqoOo7_BonWlOvl8a9nYh6wQN:EDTlAw7spPcxj7dE67UwIamhlBkuKSEqTQlM6gu4xVuDnFUzrrW2VjhS0zMkGyzakjJUg-lG3AYB4-pY'));
    // this.createAuthorizationHeader(headers);
    this.url = 'https://api.sandbox.paypal.com/v1/billing/plans/' + PlanId;
    return this.http.get(this.url, { headers })
  }
  Updatesubscriptionplan(data) {
    this.url = this.baseUrl + 'api/PayPal/AddSubscriptionplans';
    return this.http.post(this.url, data)
  }

  BraintreeSubscription(UserId) {
    this.url = this.baseUrl + 'api/PayPal/Braintreepayment';
    return this.http.post(this.url, UserId)
  }

  GenerateToken() {
    this.url = this.baseUrl + 'api/Paypal/GenerateToken';
    return this.http.get(this.url);
  }

}
