import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AlbumService } from 'src/app/Shared/album.service';
//import { PaginationServiceForAlbum } from '../../Shared/paginationserviceForAlbum.service';
import { PaginationService } from 'src/app/Shared/pagination.service';
import { Approvalstatus } from '../../models/approvalstatus';
import { Pagination } from 'src/app/models/pagination';
import { Album } from 'src/app/models/album';
import { Users } from '../../models/users';
import { LoadScriptService } from 'src/app/loadScript.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { ArtistsService } from 'src/app/Shared/artists.service';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from 'src/app/app.config';
import { Location } from '@angular/common';
import { SongsService } from 'src/app/Shared/songs.service';
import { HomeService } from 'src/app/Shared/home.service';
import { Song } from '../../models/song';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { PlayerService } from 'src/app/Shared/player.service';
import { Track } from 'Player/ngx-audio-player/src/lib/model/track.model';
import { NgForm } from '@angular/forms';
import { Console } from 'console';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-artist-album',
  templateUrl: './artist-album.component.html',
  styleUrls: ['./artist-album.component.css']
})
export class ArtistAlbumComponent implements OnInit {

  artistname: any;
  userDetails = new Users();
  searchName; any;
  pageSize: any = Pagination.fifth;
  ArtistId: any = 0;
  pageField = [];
  Checkrecords: boolean = false;
  albumList: any = [];
  noalbumList: boolean = false;
  path: any;
  albumDetails = new Album();
  activePage: boolean[] = [];
  startyear: any;
  loginasartist: boolean = true;
  columName: any = "createdOn";
  sortedOrder: any = "desc";
  tempData: number = 1;
  tempArray = [];
  tempArrayPath: any;
  tempDataArray: any;
  //Pagination Variables
  temppage: number = 0;
  disabledNextBtn: boolean;
  disabledPrevBtn: boolean = true;
  exactPageList: any;
  paginationData: number;
  pageIndex: any = 0;
  PageNo: number = 1;
  artists: any;
  approvalstatus_submit: any;
  approvalstatus_resubmit: any;
  approvalstatus_rejected: any;
  approvalstatus_approved: any;
  approvalstatus_delete: any;
  noDataFound: boolean = false;
  totalcount: number = 0;
  Years: any[] = [];
  Index: any = 0;
  //
  imgURL: any;
  imgURLS: any;
  imgURLS1: any;
  imgURLS2: any;
  imgURLS3: any;
  imgURLS4: any;
  imgURLS5: any;
  fileToUpload: File;
  isDesabled: boolean = false;
  albumtitle: any = "ADD ALBUM";
  noImage: boolean = true;
  noImages: boolean = true;
  noImages1: boolean = true;
  noImages2: boolean = true;
  noImages3: boolean = true;
  noImages4: boolean = true;
  noImages5: boolean = true;
  addAlbumProfile: boolean = true;
  addSongProfile: boolean = false;
  isChecked: boolean = true;
  files: any;
  files1: any;
  files2: any;
  files3: any;
  files4: any;
  files5: any;
  file: any;
  isSelected: boolean;
  fileToUploads: File;
  fileToUploads1: File;
  fileToUploads2: File;
  fileToUploads3: File;
  fileToUploads4: File;
  fileToUploads5: File;
  artistalbumcounts: any;
  totalsongcount: any;
  noofLikes: any;
  nooffav: any;
  fullname: any;
  allsongs;
  album;
  albumby;
  noofSongs;
  tm;
  lm;
  tmn;
  lmn;
  releasedOn;
  TransList: Array<any> = [];
  albuminfo: boolean = false;
  commnets;
  statuscode;
  statusId;
  albumid;
  UserId: any;
  viewalbum: boolean = true;
  albumname: any;
  lengths: any;
  Action: string;
  restricts: boolean = false;
  arabicRegex: any;
  albumId: any = 0;
  albumSongsList: any = [];
  noSongInAlbum: boolean = false;
  viewAddEditAlbum: boolean = true;
  viewAlbumDetails: boolean = false;
  viewAddEditSongs: boolean = false;
  songtitle: any;
  song = new Song();
  imgURLSong: any;
  imgURLSSong: any;
  isDesabledalbum: boolean = false;
  noImageSong: boolean = true;
  noImagesSong: boolean = true;
  isDesabledArist: boolean = false;
  songlangugae: any = [];
  categories: any = [];
  duration: number = 0;
  noUrl: boolean = true;
  isCheckedComming: boolean = true;
  timg: any;
  limg: any;
  limgN: any;
  timgN: any;
  @ViewChild('audioPlayer') player: ElementRef;
  isPlaying: boolean = false;
  ShowPlayer: boolean = true;
  submitted: boolean = false;
  loaderDisplay: boolean = false;
  currentTime: number = 0;
  isrelesedon: boolean;
  tempIndex: number = 0;

  @ViewChild('jdScoll')
  jdScoll!: ElementRef;
  @ViewChild('jdScollalbum')
  jdScollalbum!: ElementRef;
  columname: any;
  sortedorder: any;
  searchname: any;
  songdetails: any;
  songdata: any;
  songurls: any = [];
  objToCoartist: string = "";
  SelectedCoArtistId: any[] = [];
  albumdataList: any;
  isiconShow: boolean;
  isComposer: boolean;


  constructor(private albumService: AlbumService, public paginationService: PaginationService, private loadScript: LoadScriptService,
    private spinner: NgxSpinnerService, private _toastr: ToastrService, private _artistService: ArtistsService, private toastr: ToastrService, private route: ActivatedRoute, private configService: ConfigService,
    public location: Location, private _songservice: SongsService, private _homeService: HomeService, private _songsService: SongsService,
    private _playerService: PlayerService, private playerService: AudioPlayerService, public playlistService: AudioPlayerService
  ) {
    this.albumDetails.artistId = this.albumService.artistId;
    this.albumDetails.albumId = this.route.snapshot.paramMap.get("id");
    this.arabicRegex = '[\u0600-\u06FF]';
    this.startyear = configService.getstartDate();
    this.dropdownyear();
  }

  ngOnInit() {
    debugger;
    window.scrollTo(0, 0);
    this.albumService.artistId = 0;
    this.approvalstatus_submit = Approvalstatus.SUBMIT;
    this.approvalstatus_rejected = Approvalstatus.REJECTED;
    this.approvalstatus_resubmit = Approvalstatus.RESUBMIT;
    this.approvalstatus_approved = Approvalstatus.APPROVED;
    this.approvalstatus_delete = Approvalstatus.DELETED;
    this.loadScript.getServiceBaseUrl();
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    if (this.userDetails["artistId"] != undefined) {
      this.ArtistId = this.userDetails["artistId"];
      this.getalbumbyartistId();
      this.ArtistSongsById();
      this.GetArtistDetails();
    }
    // this.loadScript.getServiceBaseUrl();
    this.paginationService.temppage = this.paginationService.temppage;
    // this.paginationService.currentPage = 1;
    if (this.paginationService.currentpageee != null || this.paginationService.currentpageee != undefined) {
      this.searchName = this.albumService.searchText;
      this.paginationService.currentPage = this.paginationService.currentpageee
      this.activePage[this.paginationService.indexxx] = true;
    }
    else {
      this.searchName = this.albumService.searchText;
      this.paginationService.currentPage = this.paginationService.currentPage;
      this.activePage[0] = true;
    }
    this.paginationService.showPageOnlyOntabsChange = this.paginationService.showPageOnlyOntabsChange;
    if (this.searchName !== "") {
      this.paginationService.temppage = 0
      this.paginationService.currentPage = 1;
    }
    this.searchName = this.albumService.searchText;
    this.pageIndex = this.PageNo;
    //this.paginationService.currentPage;
    this.getAllAlbum();
    this.albumDetails.isComingUp = this.isChecked;
    this.getAllArtists();
    if (this.albumDetails.artistId == 0) {
      this.isDesabled = false;
    } else {
      this.isDesabled = true;
    }
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    if (this.userDetails["artistId"] != undefined) {
      this.albumDetails.artistId = this.userDetails["artistId"];
      this.isDesabled = true;
      this.loginasartist = true;
    }
    if (this.albumDetails.albumId != null) {
      this.albumtitle = "Update Album";
      this.isDesabled = true;
      this.addAlbumProfile = false;
      debugger;
      this.albumService.GetAlbumsbyalbum(this.albumDetails.albumId,this.userDetails.userId).subscribe((data: any) => {
        console.log(data.albumDetails);
        this.imgURL = data.path.concat(this.albumDetails.albumId, '/', data.albumDetails.largeImage);
        this.imgURLS = data.path.concat(this.albumDetails.albumId, '/', data.albumDetails.largeImageNew);
        this.imgURLS1 = data.path.concat(this.albumDetails.albumId, '/', data.albumDetails.largeImageNew1);
        this.imgURLS2 = data.path.concat(this.albumDetails.albumId, '/', data.albumDetails.largeImageNew2);
        this.imgURLS3 = data.path.concat(this.albumDetails.albumId, '/', data.albumDetails.largeImageNew3);
        this.imgURLS4 = data.path.concat(this.albumDetails.albumId, '/', data.albumDetails.largeImageNew4);
        this.imgURLS5 = data.path.concat(this.albumDetails.albumId, '/', data.albumDetails.largeImageNew5);
        console.log(this.albumDetails.CoArtistId);
        if (data.albumDetails.isForKid == true) {
          this.isSelected = true;
        }
        else {
          this.isSelected = false;
        }
        if (data.albumDetails.thumbImageNew != null) {
          this.noImages = false;
        } else {
          this.noImages = true;
        }
        if (data.albumDetails.thumbImage != null) {
          this.noImage = false;
        } else {
          this.noImage = true;
        }
        if (data.albumDetails.thumbImageNew1 != null) {
          this.noImages1 = false;
        } else {
          this.noImages1 = true;
        }
        if (data.albumDetails.thumbImageNew2 != null) {
          this.noImages2 = false;
        } else {
          this.noImages2 = true;
        }
        if (data.albumDetails.thumbImageNew3 != null) {
          this.noImages3 = false;
        } else {
          this.noImages3 = true;
        }
        if (data.albumDetails.thumbImageNew4 != null) {
          this.noImages4 = false;
        } else {
          this.noImages4 = true;
        }
        if (data.albumDetails.thumbImageNew5 != null) {
          this.noImages5 = false;
        } else {
          this.noImages5 = true;
        }
        this.albumDetails = data.albumDetails;
        this.isChecked = data.albumDetails.isComingUp;
        this.path = data.path;
      }, err => { this.toastr.error(err) })
    } else {
      this.albumDetails.albumId = 0;
    }
  }

  Checked(value) {
    this.albumDetails.isComingUp = value;
  }

  Checkedkid(event) {
    this.albumDetails.isforKid = event.target.checked
  }

  doSomething(e) {
    const e1 = <KeyboardEvent>event;
    if (e.key === 'Tab' || e.key === 'TAB') {
      return;
    }
    let k;
    k = e.keyCode;
    if ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57)) {
      this.restricts = false;
      return;
    }
    else {
      this.restricts = true;
    }
    const ch = String.fromCharCode(e.keyCode);
    const regEx = new RegExp(this.arabicRegex);
    if (regEx.test(ch)) {
      this.restricts = true;
      return;
    }
    e.preventDefault();
  }

  // onSubmit(f: NgForm) {
  //   this.spinner.show();
  //   if (f.form.invalid) {
  //       this.spinner.hide();
  //       return;
  //   } 
  // }
  onSubmit(f: NgForm) {
    debugger;
    if (this.albumDetails.title == null || this.albumDetails.releasedOn == null) {
      if (this.albumDetails.title == null) {
        this.submitted = true
      }
      if (this.albumDetails.releasedOn == null) {
        this.isrelesedon = true
      }
      return;
    }
    this.submitted = false;
    this.isrelesedon = false;

    if (this.albumDetails.artistId == 0 || this.albumDetails.artistId == "0") {
      this.toastr.warning("Add Artist");
      return;
    }
    if (this.noImage) {
      this.albumDetails.Base64Data = null;
    } else {
      this.albumDetails.Base64Data = this.imgURL.split(",")[1];
    }
    if (this.noImages) {
      this.albumDetails.Base64DataForNewRelease = null;
    } else {
      this.albumDetails.Base64DataForNewRelease = this.imgURLS.split(",")[1];
    }
    if (this.noImages1) {
      this.albumDetails.Base64DataForNewRelease1 = null;
    } else {
      this.albumDetails.Base64DataForNewRelease1 = this.imgURLS1.split(",")[1];
    }
    if (this.noImages2) {
      this.albumDetails.Base64DataForNewRelease2 = null;
    } else {
      this.albumDetails.Base64DataForNewRelease2 = this.imgURLS2.split(",")[1];
    }
    if (this.noImages3) {
      this.albumDetails.Base64DataForNewRelease3 = null;
    } else {
      this.albumDetails.Base64DataForNewRelease3 = this.imgURLS3.split(",")[1];
    }
    if (this.noImages4) {
      this.albumDetails.Base64DataForNewRelease4 = null;
    } else {
      this.albumDetails.Base64DataForNewRelease4 = this.imgURLS4.split(",")[1];
    }
    if (this.noImages5) {
      this.albumDetails.Base64DataForNewRelease5 = null;
    } else {
      this.albumDetails.Base64DataForNewRelease5 = this.imgURLS5.split(",")[1];
    }
    if (this.userDetails["artistId"] != undefined) {
      this.albumDetails.createdBy = this.userDetails["artistId"].toString();

      this.albumDetails.updatedBy = this.userDetails["artistId"].toString();

    }
    if (this.userDetails["userId"] != undefined) {
      this.albumDetails.createdBy = this.userDetails["userId"].toString();
      this.albumDetails.updatedBy = this.userDetails["userId"].toString();
    }
    if (this.albumDetails.albumId == 0) {
      this.albumDetails.statusId = Approvalstatus.SUBMIT;
    }
    this.spinner.show();
    this.albumDetails.CoArtistId = this.objToCoartist;
    console.log(this.albumDetails);
    this.albumService.addOrUpdateAlbum(this.albumDetails).subscribe((res: any) => {
      if (res.status == 200) {
        if (this.albumDetails.albumId == 0) {
          this.toastr.success("Album added successfully");
          this.Action = "Added";
          this.ArtistAudit(this.Action, this.albumDetails.title);
          this.getAllAlbum();
          this.cancel();
          this.spinner.hide();

        } else {
          this.toastr.success("Album updated successfully");
          this.Action = "Updated"
          this.ArtistAudit(this.Action, this.albumDetails.title);
          this.getAllAlbum();
          this.cancel();
          this.spinner.hide();
        }
      }
    }, err => {
      this.toastr.error(err)
      this.spinner.hide();
    })
  }

  cancel() {
    this.albumtitle = "ADD ALBUM"
    this.addAlbumProfile = true;
    this.imgURL = "";
    this.imgURLS = "";
    this.imgURLS1 = "";
    this.imgURLS2 = "";
    this.imgURLS3 = "";
    this.imgURLS4 = "";
    this.imgURLS5 = "";
    this.albumDetails = new Album();
    this.albumDetails.artistId = (this.userDetails["artistId"]);
    this.submitted = false;
    this.isrelesedon = false;
    this.noImage = true;
    this.noImages = true;
    this.noImages1 = true;
    this.noImages2 = true;
    this.noImages3 = true;
    this.noImages4 = true;
    this.noImages5 = true;
  }

  selectPhoto(file: FileList) {
    this.albumDetails.thumbImage = file.item(0).name;
    this.fileToUpload = file.item(0);
    var File = this.fileToUpload.name;
    this.albumDetails.FileName = File.split('.')[0];
    this.file = File.split('.');
    this.albumDetails.FileType = this.file[this.file.length - 1];
    this.noImage = false;
    //Show Image Preview
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imgURL = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }

  //new release
  selectPhotos(file: FileList) {
    this.albumDetails.thumbImageNew = file.item(0).name;
    this.fileToUploads = file.item(0);
    var File = this.fileToUploads.name;
    this.albumDetails.FileNames = File.split('.')[0];
    this.files = File.split('.');
    this.albumDetails.NewFileType = this.files[this.files.length - 1];
    this.noImages = false;
    //Show Image Preview
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imgURLS = event.target.result;
    }
    reader.readAsDataURL(this.fileToUploads);
  }

  selectPhotos1(file: FileList, type: any) {
    if (type == "type1") {
      this.albumDetails.thumbImageNew1 = file.item(0).name;
      this.fileToUploads1 = file.item(0);
      var File = this.fileToUploads1.name;
      this.albumDetails.FileNames1 = File.split('.')[0];
      this.files1 = File.split('.');
      this.albumDetails.NewFileType1 = this.files1[this.files1.length - 1];
      this.noImages1 = false;
      //Show Image Preview
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.imgURLS1 = event.target.result;
      }
      reader.readAsDataURL(this.fileToUploads1);
    }

    if (type == "type2") {
      this.albumDetails.thumbImageNew2 = file.item(0).name;
      this.fileToUploads2 = file.item(0);
      var File = this.fileToUploads2.name;
      this.albumDetails.FileNames2 = File.split('.')[0];
      this.files2 = File.split('.');
      this.albumDetails.NewFileType2 = this.files2[this.files2.length - 1];
      this.noImages2 = false;
      //Show Image Preview
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.imgURLS2 = event.target.result;
      }
      reader.readAsDataURL(this.fileToUploads2);
    }
    if (type == "type3") {
      this.albumDetails.thumbImageNew3 = file.item(0).name;
      this.fileToUploads3 = file.item(0);
      var File = this.fileToUploads3.name;
      this.albumDetails.FileNames3 = File.split('.')[0];
      this.files3 = File.split('.');
      this.albumDetails.NewFileType3 = this.files3[this.files3.length - 1];
      this.noImages3 = false;
      //Show Image Preview
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.imgURLS3 = event.target.result;
      }
      reader.readAsDataURL(this.fileToUploads3);
    }
    if (type == "type4") {
      this.albumDetails.thumbImageNew4 = file.item(0).name;
      this.fileToUploads4 = file.item(0);
      var File = this.fileToUploads4.name;
      this.albumDetails.FileNames4 = File.split('.')[0];
      this.files4 = File.split('.');
      this.albumDetails.NewFileType4 = this.files4[this.files4.length - 1];
      this.noImages4 = false;
      //Show Image Preview
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.imgURLS4 = event.target.result;
      }
      reader.readAsDataURL(this.fileToUploads4);
    }
    if (type == "type5") {
      this.albumDetails.thumbImageNew5 = file.item(0).name;
      this.fileToUploads5 = file.item(0);
      var File = this.fileToUploads5.name;
      this.albumDetails.FileNames5 = File.split('.')[0];
      this.files5 = File.split('.');
      this.albumDetails.NewFileType5 = this.files5[this.files5.length - 1];
      this.noImages5 = false;
      //Show Image Preview
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.imgURLS5 = event.target.result;
      }
      reader.readAsDataURL(this.fileToUploads5);
    }
  }



  _handleReaderLoaded(readerEvt) {
    const binaryString = readerEvt.target.result;
    this.albumDetails.Base64Data = btoa(binaryString);
  }

  getAllAlbum() {
    this.spinner.show();
    this.albumService.GetAlbumByArtistAndCoArtistId(this.ArtistId, this.columName, this.sortedOrder, this.searchName, this.pageIndex, this.pageSize).subscribe((data: any) => {
      this.totalcount = data.count;
      this.paginationService.temppage = 0;
      if (this.tempData == 1) {
        this.tempDataArray = data;
        this.tempArray = data.albumDetails;
        this.tempArrayPath = data.path;
      }
      if (this.ArtistId != 0 && data.isSuccess == "true") {
        var str3 = "(By ";
        this.artistname = str3.concat(data.albumDetails[0].albumBy, ')');
      }
      this.albumList = data.albumDetails;
      this.path = data.path;
      this.getPageNumber(data);
      if (this.albumList.length > 0) {
        this.noalbumList = false;
      } else {
        this.noalbumList = true;
      }
      this.spinner.hide();
    }, err => {
      this._toastr.error(err)
      this.spinner.hide();
    });
  }

  getPageNumber(data) {
    if (data.count == 0) {
      this.pageField = [];
      this.totalcount = 0;
      this.noDataFound = true;
      this.Checkrecords = true;
    } else {
      this.Checkrecords = false;
      this.paginationData = Number(data.count / this.pageSize);
      let tempPageData = this.paginationData.toFixed();
      if (Number(tempPageData) < this.paginationData) {
        this.exactPageList = Number(tempPageData) + 1;
        this.paginationService.exactPageList = this.exactPageList;
      } else {
        this.exactPageList = Number(tempPageData);
        this.paginationService.exactPageList = this.exactPageList
      }
      this.paginationService.pageOnLoad();
      this.pageField = this.paginationService.pageField;
      if (this.pageField.length == 1 && this.pageIndex == 1) {
        this.paginationService.disabledNextBtn = true;
        this.paginationService.disabledPrevBtn = true;
      } else if (this.pageIndex == 1 && this.pageField.length > 1) {
        this.paginationService.disabledNextBtn = false;
        this.paginationService.disabledPrevBtn = true;
      } else if (this.pageIndex == this.pageField.length) {
        this.paginationService.disabledNextBtn = true;
        this.paginationService.disabledPrevBtn = false;
      } else {
        this.paginationService.disabledNextBtn = false;
        this.paginationService.disabledPrevBtn = false;
      }
      this.disabledNextBtn = this.paginationService.disabledNextBtn;
      this.disabledPrevBtn = this.paginationService.disabledPrevBtn;
    }
  }

  previous(page) {
    if (this.disabledPrevBtn == false) {
      this.paginationService.previous();
      this.pageIndex = page - 1;
      //this.paginationService.pageField[0];
      this.activePage = [];
      this.activePage[this.pageIndex - 1] = true;
      this.getAllAlbum();
      if (this.pageIndex == 1 && this.pageField.length > 1) {
        this.paginationService.disabledNextBtn = false;
        this.paginationService.disabledPrevBtn = true;
      } else if (this.pageIndex == this.pageField.length) {
        this.paginationService.disabledNextBtn = true;
        this.paginationService.disabledPrevBtn = false;
      } else {
        this.paginationService.disabledNextBtn = false;
        this.paginationService.disabledPrevBtn = false;
      }
      this.disabledNextBtn = this.paginationService.disabledNextBtn;
      this.disabledPrevBtn = this.paginationService.disabledPrevBtn;
    }
  }

  showNextAlbums(page, i) {
    this.pageIndex = page;
    this.activePage = [];
    this.activePage[i] = true;
    this.getAllAlbum();
    if (this.pageField.length == 1 && this.pageIndex == 1) {
      this.paginationService.disabledNextBtn = true;
      this.paginationService.disabledPrevBtn = true;
    } else if (this.pageIndex == 1 && this.pageField.length > 1) {
      this.paginationService.disabledNextBtn = false;
      this.paginationService.disabledPrevBtn = true;
    } else if (this.pageIndex == this.pageField.length) {
      this.paginationService.disabledNextBtn = true;
      this.paginationService.disabledPrevBtn = false;
    } else {
      this.paginationService.disabledNextBtn = false;
      this.paginationService.disabledPrevBtn = false;
    }
    this.disabledNextBtn = this.paginationService.disabledNextBtn;
    this.disabledPrevBtn = this.paginationService.disabledPrevBtn;
  }

  nextPage(page) {
    if (this.disabledNextBtn == false) {
      this.paginationService.nextPage();
      this.pageIndex = page + 1;
      //this.paginationService.pageField[0];
      this.activePage = [];
      this.activePage[this.pageIndex - 1] = true;
      this.getAllAlbum();
      if (this.pageIndex == 1 && this.pageField.length > 1) {
        this.paginationService.disabledNextBtn = false;
        this.paginationService.disabledPrevBtn = true;
      } else if (this.pageIndex == this.pageField.length) {
        this.paginationService.disabledNextBtn = true;
        this.paginationService.disabledPrevBtn = false;
      } else {
        this.paginationService.disabledNextBtn = false;
        this.paginationService.disabledPrevBtn = false;
      }
      this.disabledNextBtn = this.paginationService.disabledNextBtn;
      this.disabledPrevBtn = this.paginationService.disabledPrevBtn;
    }
  }

  ArtistAudit(action, albumname) {
    var Data = {
      "ArtistId": this.ArtistId,
      "Type": action,
      "FieldName": "Album" + " " + albumname,
      "CreatedBy": this.ArtistId,
    };
    this._artistService.ArtistAudit(Data).subscribe((res: any) => {
    });
  }

  getAllArtists() {
    this._artistService.GetArtists().subscribe((data: any) => {
      this.artists = data.artistDetails;
    }, err => { this.toastr.error(err) });
  }

  dropdownyear() {
    this.startyear = 1900;
    let endYear = new Date().getFullYear();
    let year = endYear - this.startyear;
    for (let i = 0; i <= year; i++) {
      this.Years[this.Index] = this.startyear + i;
      this.Index++;
    }
    this.Years = this.Years.reverse();
  }

  getalbumbyartistId() {
    this._songservice.GetAllAbumbs(this.ArtistId).subscribe((data: any) => {
      this.artistalbumcounts = data.albumDetails.length;
      this.albumid = data.albumDetails.albumId;
    });
  }

  ArtistSongsById() {
    this._homeService.ArtistSongsById(this.ArtistId).subscribe((data: any) => {
      this.totalsongcount = data.artistsongs.length;
    });
  }

  GetArtistDetails() {
    this._artistService.getArtistDetails(this.ArtistId).subscribe((data: any) => {
      this.noofLikes = data.artistDetails.noofLikes;
      this.nooffav = data.artistDetails.noofFavs;
      this.fullname = data.artistDetails.fullName;
    })
  }

  viewalbums(albumId) {
    this.UserId = "0";
    this.viewalbum = false;
    this.viewAddEditAlbum = false;
    this.viewAlbumDetails = true;
    this.viewAddEditSongs = false;
    this.TransList = [];
    this.albumId = 0;
    this.path = "";
    this.album = "";
    this.albumby = "";
    this.noofSongs = 0;
    this.releasedOn = "";
    this.allsongs = "";
    this.tm = "";
    this.lm = "";
    this.tmn = "";
    this.lmn = "";
    this.statuscode = "";
    this.statusId = 0;
    this.albumSongsList = [];
    this.spinner.show();
    this._homeService.getAlbumbyId(albumId, this.UserId).subscribe(Response => {
      this.TransList.push(Response);
      this.albumdataList = this.TransList[0].albumDetails;
      console.log(this.albumdataList);
      this.albumSongsList = this.TransList[0].albumDetails.songs;
      this.albumId = this.TransList[0].albumDetails.albumId;
      this.path = this.TransList[0].path;
      this.album = this.TransList[0].albumDetails.title;
      this.albumby = this.TransList[0].albumDetails.albumBy;
      this.noofSongs = this.TransList[0].albumDetails.nonApprovedSongs;
      this.releasedOn = this.TransList[0].albumDetails.releasedOn;
      this.allsongs = this.TransList[0].albumDetails.songs;
      this.tm = this.TransList[0].albumDetails.thumbImage;
      this.lm = this.TransList[0].albumDetails.largeImage;
      this.tmn = this.TransList[0].albumDetails.thumbImageNew;
      this.lmn = this.TransList[0].albumDetails.largeImageNew;
      this.statuscode = this.TransList[0].albumDetails.statusCode;
      this.statusId = this.TransList[0].albumDetails.statusId;
      this.spinner.hide();
      // if(this.albumSongsList.CreatedBy == this.ArtistId){
      //   this.isiconShow = true;
      // }
      if (this.statusId == Approvalstatus.APPROVED) {
        document.getElementById("resubmit").style.display = "none";
        document.getElementById("approve").style.display = "none";
      }
      if (this.statusId == Approvalstatus.REJECTED || this.statusId == Approvalstatus.DELETED) {
        document.getElementById("reject").style.display = "none";
      }
      if (this.statusId == Approvalstatus.DELETED) {
        document.getElementById("approve").style.display = "none";
        document.getElementById("comment").style.display = "none";
      }
      if (this.albumSongsList.length > 0) {
        this.noSongInAlbum = false;
      } else {
        this.noSongInAlbum = true;
      }
    })
  }

  editAlbumProfile(albumId) {
    debugger;
    this.jdScollalbum.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
    this.viewalbum = true;
    this.viewAddEditAlbum = true;
    this.viewAlbumDetails = false;
    this.viewAddEditSongs = false;
    this.albumtitle = "UPDATE ALBUM";
    this.albumService.GetAlbumsbyalbum(albumId , this.userDetails.userId).subscribe((data: any) => {
      this.isDesabled = true;
      this.addAlbumProfile = false;
      this.imgURL = data.path.concat(albumId, '/', data.albumDetails.largeImage);
      this.imgURLS = data.path.concat(albumId, '/', data.albumDetails.largeImageNew);
      this.imgURLS1 = data.path.concat(albumId, '/', data.albumDetails.largeImageNew1);
      this.imgURLS2 = data.path.concat(albumId, '/', data.albumDetails.largeImageNew2);
      this.imgURLS3 = data.path.concat(albumId, '/', data.albumDetails.largeImageNew3);
      this.imgURLS4 = data.path.concat(albumId, '/', data.albumDetails.largeImageNew4);
      this.imgURLS5 = data.path.concat(albumId, '/', data.albumDetails.largeImageNew5);
      this.SelectedCoArtistId = data.albumDetails.coArtistId != null ? data.albumDetails.coArtistId.split(',').map(Number) : '';
      console.log(data.albumDetails);
      if (data.albumDetails.isForKid == true) {
        this.isSelected = true;
      }
      else {
        this.isSelected = false;
      }
      if (data.albumDetails.thumbImageNew != null) {
        this.noImages = false;
      } else {
        this.noImages = true;
      }
      if (data.albumDetails.thumbImage != null) {
        this.noImage = false;
      } else {
        this.noImage = true;
      }
      if (data.albumDetails.thumbImageNew1 != null) {
        this.noImages1 = false;
      } else {
        this.noImages1 = true;
      }
      if (data.albumDetails.thumbImageNew2 != null) {
        this.noImages2 = false;
      } else {
        this.noImages2 = true;
      }
      if (data.albumDetails.thumbImageNew3 != null) {
        this.noImages3 = false;
      } else {
        this.noImages3 = true;
      }
      if (data.albumDetails.thumbImageNew4 != null) {
        this.noImages4 = false;
      } else {
        this.noImages4 = true;
      }
      if (data.albumDetails.thumbImageNew5 != null) {
        this.noImages5 = false;
      } else {
        this.noImages5 = true;
      }
      this.albumDetails = data.albumDetails;
      this.isChecked = data.albumDetails.isComingUp;
      this.path = data.path;
    }, err => { this.toastr.error(err) })
  }

  deleteAlbum(albumId) {
    if (confirm('Please note! This action can NOT be undone. Are you sure you want to delete?')) {
      this.albumService.deleteAlbum(albumId).subscribe((response) => {
        if (response.isSuccess) {
          this._toastr.success("Album has been deleted successfully");
          this.Action = "Deleted"
          this.ArtistAudit(this.Action, this.albumDetails.title);
          this.getAllAlbum();
          this.cancel();
          this.viewalbum = false;
          this.viewAddEditAlbum = true;
          this.viewAlbumDetails = false;
          this.viewAddEditSongs = false;
        }
      }, err => {
        this._toastr.error(err)
        this.spinner.hide();
      });
    }
  }

  addNewSongInAlbum() {
    this.getsongLanguage();
    this.getallCategories();
    this.song = new Song();
    this.songtitle = "ADD SONG";
    this.addSongProfile = true;
    this.viewalbum = false;
    this.viewAddEditAlbum = false;
    this.viewAlbumDetails = false;
    this.viewAddEditSongs = true;
    this.song.songId = 0;
    this.song.artistId = (this.userDetails["artistId"]);
    this.noImageSong = true;
    this.noImagesSong = true;
    this.imgURLSong = "";
    this.imgURLSSong = "";
    this.song.albumId = this.albumId;
    this.isDesabledArist = true;
    this.isDesabledalbum = true;
    this.song.categoryId = 0;
    this.song.songLanguageCode = 0;
  }

  editSongDetails(songId) {
    this.getsongLanguage();
    this.getallCategories();
    this.songtitle = "UPDATE SONG";
    this.addSongProfile = false;
    this.viewalbum = false;
    this.viewAddEditAlbum = false;
    this.viewAlbumDetails = false;
    this.viewAddEditSongs = true;
    this.isDesabledArist = true;
    this.isDesabledalbum = true;
    this._songsService.getSongDetails(songId).subscribe((data: any) => {
      if (data.songs.isForKid == true) {
        this.isSelected = true;
      }
      else {
        this.isSelected = false;
      }
      this.imgURLSong = data.path.concat(data.songs.largeImage);
      this.imgURLSSong = data.path.concat(data.songs.largeImageNew);
      this.isCheckedComming = data.songs.isComingUp;
      this.song = data.songs;
      this.song.streamUrl = data.songs.streamUrl;
      this.song.length = data.songs.length;
      if (data.songs.largeImage != null) {
        this.limg = data.songs.largeImage.split('/');
        this.limg = this.limg[this.limg.length - 1];
        this.song.largeImage = this.limg;
      }
      if (data.songs.largeImageNew != null) {
        this.limgN = data.songs.largeImageNew.split('/');
        this.limgN = this.limgN[this.limgN.length - 1];
        this.song.largeImageNew = this.limgN;
      }

      if (data.songs.thumbImage != null) {
        this.timg = data.songs.thumbImage.split('/');
        this.timg = this.timg[this.timg.length - 1];
        this.song.thumbImage = this.timg;
        this.noImageSong = false;
      } else {
        this.noImageSong = true;
      }

      if (data.songs.thumbImageNew != null) {
        this.timgN = data.songs.thumbImageNew.split('/');
        this.timgN = this.timgN[this.timgN.length - 1];
        this.song.thumbImageNew = this.timgN;
        this.noImagesSong = false;
      } else {
        this.noImagesSong = true;
      }
      if (data.songs.streamUrl != null) {
        this.song.streamUrl = data.songs.streamUrl.split('/');
        this.song.streamUrl = this.song.streamUrl[this.song.streamUrl.length - 1];
        this.noUrl = false;
      }
      else {
        this.noUrl = true;
      }
      this.song = data.songs;
      this.path = data.path;
    });
  }

  cancelSong() {
    this.songtitle = "ADD SONG"
    this.noImageSong = true;
    this.noImagesSong = true;
    this.isDesabledalbum = true;
    this.addSongProfile = true;
    this.imgURLSong = "";
    this.imgURLSSong = "";
    this.song = new Song();
    this.song.artistId = (this.userDetails["artistId"]);
    this.song.albumId = this.albumId;
    this.isDesabledArist = true;
    this.song.categoryId = 0;
    this.song.songLanguageCode = 0;
    this.submitted = false;
    this.isComposer = false;
    this.isrelesedon = false;
  }

  //Upload Files
  selectPhotoSong(file: FileList) {
    this.song.thumbImage = file.item(0).name;
    this.fileToUpload = file.item(0);
    var File = this.fileToUpload.name;
    this.song.fileName = File.split('.')[0];
    this.files = File.split('.');
    this.song.fileType = this.files[this.files.length - 1];
    this.noImageSong = false;
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imgURLSong = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }

  //upload Files for New Release
  selectPhotosSong(file: FileList) {
    this.song.thumbImageNew = file.item(0).name;
    this.fileToUploads = file.item(0);
    var File = this.fileToUploads.name;
    this.song.fileNameNew = File.split('.')[0];
    this.files = File.split('.');
    this.song.fileTypeNew = this.files[this.files.length - 1];
    //this.song.FileType = File.split('.')[1];
    this.noImagesSong = false;
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imgURLSSong = event.target.result;
    }
    reader.readAsDataURL(this.fileToUploads);
  }

  doSomethingSongTitle(e) {
    const e1 = <KeyboardEvent>event;
    if (e.key === 'Tab' || e.key === 'TAB') {
      return;
    }
    let k;
    k = e.keyCode;  // k = event.charCode;  (Both can be used)
    if ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57)) {
      this.restricts = false;
      return;
    }
    else {
      this.restricts = true;
    }
    const ch = String.fromCharCode(e.keyCode);
    const regEx = new RegExp(this.arabicRegex);
    if (regEx.test(ch)) {
      this.restricts = true;
      return;
    }
    e.preventDefault();
  }

  getallCategories() {
    this._songsService.GetallCategories().subscribe((data: any) => {
      this.categories = data.categories;
    });
  }

  getsongLanguage() {
    this._songsService.GetsongLanguage().subscribe((data: any) => {
      this.songlangugae = data.languages;
    });
  }

  //Upload Mp3 File
  selectSong(evt) {
    this.file = evt.target.files[0];
    new Audio(URL.createObjectURL(this.file)).onloadedmetadata = (e: any) => {
      this.duration = e.currentTarget.duration;
      this.songlength(this.duration);
    }
    //var duration = moment.duration(seconds, "seconds");
    var File = evt.target.value;
    this.song.streamUrl = File.substr(12);
    let subStringData = File.substr(12);
    var FileName = subStringData.split('.')[0];
    var songfiletype = subStringData.split('.');
    songfiletype = songfiletype[songfiletype.length - 1];
    //var FileType = subStringData.split('.')[1];
    this.noUrl = false;
    this.song.songName = FileName;
    this.song.songType = songfiletype;
    var files = evt.target.files;
    var file = files[0];
    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoadedForSong.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoadedForSong(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.song.base64DataForSong = btoa(binaryString);
  }

  songlength(duration) {
    var h = Math.floor(duration / 3600);
    var m = Math.floor(duration % 3600 / 60);
    var s = Math.floor(duration % 3600 % 60);
    var minutes = (h + ':' + m + ':' + s);
    this.song.length = minutes;
  }

  CheckedIsComming(value) {
    this.song.isComingUp = value;
  }

  onSubmitSong() {
    if (this.noImageSong) {
      this.song.base64Data = null;
    } else {
      this.song.base64Data = this.imgURLSong.split(",")[1];
    }
    if (this.noImagesSong) {
      this.song.base64DataForNewRelease = null;
    } else {
      this.song.base64DataForNewRelease = this.imgURLSSong.split(",")[1];
    }
    if (this.song.isComingUp == undefined) {
      this.song.isComingUp = false;
    }

    if (this.song.title == null || this.song.releasedOn == null || this.song.composerName == null) {
      if (this.song.title == null) {
        this.submitted = true
      }
      if (this.song.releasedOn == null) {
        this.isrelesedon = true
      }
      if (this.song.composerName == null) {
        this.isComposer = true;
      }
      return;
    }

    if (this.userDetails["artistId"] != undefined) {
      this.song.createdBy = this.userDetails["artistId"].toString();
      this.song.updatedBy = this.userDetails["artistId"].toString();
    }
    if (this.userDetails["userId"] != undefined) {
      this.song.createdBy = this.userDetails["userId"].toString();
      this.song.updatedBy = this.userDetails["userId"].toString();
    }
    if (this.song.songId == 0) {
      this.song.statusId = Approvalstatus.SUBMIT;
    }
    if (this.song.artistId == 0 || this.song.artistId == "0") {
      this.toastr.warning("Add Artist");
      return;
    }
    if (this.song.albumId == 0 || this.song.albumId == "0") {
      this.toastr.warning("Add Album");
      return;
    }
    if (this.song.composerName == null || this.song.composerName == "" || this.song.composerName == undefined) {
      this.toastr.warning("Add Composer");
      return;
    }
    if (this.song.writerName == null || this.song.writerName == "" || this.song.writerName == undefined) {
      this.toastr.warning("Add Writer");
      return;
    }
    this.song.lengthString = this.song.length.toString();
    const time = {
      "Hours": 16,
      "Minutes": 8,
      "Seconds": 45,
      "Milliseconds": 0,
      "Ticks": 581250000000,
      "Days": 0,
      "TotalDays": 0.6727430555555556,
      "TotalHours": 16.145833333333332,
      "TotalMilliseconds": 58125000,
      "TotalMinutes": 968.75,
      "TotalSeconds": 58125
    };
    this.song.length = time;
    this.spinner.show();
    this._songsService.addupdatesong(this.song).subscribe((res: any) => {
      if (res.status == 200) {
        if (this.song.songId == 0) {
          this.toastr.success("song added successfully");
          this.Action = "Added";
          this.spinner.hide();
          this.ArtistAuditForSong(this.Action, this.song.title);
          this.viewalbums(this.albumId);
          this.cancelSong();
        } else {
          this.toastr.success("song updated successfully");
          this.Action = "Updated";
          this.spinner.hide();
          this.ArtistAuditForSong(this.Action, this.song.title);
          this.viewalbums(this.albumId);
          this.cancelSong();
        }
      }
    }, err => {
      this.toastr.error(err)
      this.spinner.hide();
    })
  }

  deleteSong(songId) {
    if (confirm('Please note! This action can not be undo. Are you sure you want to delete?')) {
      this._songsService.deleteSong(songId).subscribe((response) => {
        if (response.isSuccess) {
          this.toastr.success("Song has been deleted successfully");
          this.Action = "Deleted"
          this.ArtistAuditForSong(this.Action, this.song.title);
          this.viewalbums(this.albumId);
          this.cancelSong();
        }
      }, err => {
        this.toastr.error(err)
        this.spinner.hide();
      });
    }
  }

  ArtistAuditForSong(action, songname) {
    var Data = {
      "ArtistId": this.ArtistId,
      "Type": action,
      "FieldName": "Song" + " " + songname,
      "CreatedBy": this.ArtistId,
    };
    this._artistService.ArtistAudit(Data).subscribe((res: any) => {
    });
  }

  //Play Songs
  playSong(songs) {
    debugger;
    this.ShowPlayer = false;
    var getPlayList = this.playerService.getPlaylist();
    this._playerService.playForArtist = true;
    var index = getPlayList.findIndex(x => x.link == songs.streamUrl);
    this._playerService.msaapPlaylist = [];
    if (index == -1) {
      const tractObj = new Track();
      tractObj.image = this.path + songs.thumbImage;
      tractObj.artistName = songs.artistName;
      tractObj.link = songs.streamUrl;
      tractObj.title = songs.title;
      this._playerService.msaapPlaylist.unshift(tractObj);
      this.playerService.setPlaylist(this._playerService.msaapPlaylist);
      this.playerService.selectATrack(1);
    }
    else {
      this.playerService.selectATrack(1);
    }
    this._playerService.updateData(songs);
    // this.playerService.nextSong();

    //this.isPlaying = !this.isPlaying;

    // var getPlayList = this.playerService.getPlaylist();
    // this._playerService.playForArtist = true;
    // var index = getPlayList.findIndex(x => x.link == songs.streamUrl);    
    // this._playerService.msaapPlaylist = [];
    // if (index == -1) {
    //   const tractObj = new Track();
    //   tractObj.image = this.path + songs.thumbImage;
    //   tractObj.artistName = songs.artistName;
    //   tractObj.link = songs.streamUrl;
    //   tractObj.title = songs.title;
    //   this._playerService.msaapPlaylist.unshift(tractObj);
    //   this.playerService.setPlaylist(this._playerService.msaapPlaylist);
    //   this.playerService.selectATrack(1);
    // }
    // else {
    //   this.playerService.selectATrack(1);
    // }
    // this._playerService.updateData(songs);
    // this.isPlaying = !this.isPlaying;
    // //this.play();
    // //this.player.nativeElement.play();
    // this.ShowPlayer = true;
  }


  // play(): void {
  //   setTimeout(() => {
  //       this.player.nativeElement.play();
  //   }, 0);
  // };

  //Play Songs


  playPause() {
    if (this.playlistService.playlist.length != 1) {
      let currentIndex = this.playlistService.playlist.findIndex(x => x.songId == localStorage.getItem('CurrentSongId'))
      if (currentIndex != -1) {
        if (this.playlistService.playPauseBtn[this.playlistService.indexSong]) {
          this.playlistService.playPauseBtn[this.playlistService.indexSong] = false;
          localStorage.setItem('IsSongPlaying', 'false');
          localStorage.setItem('IsPlaying', 'false');
        } else {
          this.playlistService.playPauseBtn[this.playlistService.indexSong] = true;
          localStorage.setItem('IsSongPlaying', 'true');
          localStorage.setItem('IsPlaying', 'false');
        }
      }
    } else {
      let currentIndex = this.playlistService.playPauseBtn.findIndex(x => x == true);

      if (this.playlistService.playPauseBtn[currentIndex]) {
        this.playlistService.playPauseBtn[currentIndex] = false;
        this.tempIndex = currentIndex;
        localStorage.setItem('IsSongPlaying', 'false');
        localStorage.setItem('IsPlaying', 'false');
      } else {
        this.playlistService.playPauseBtn[this.tempIndex] = true;
        localStorage.setItem('IsSongPlaying', 'true');
        localStorage.setItem('IsPlaying', 'false');
      }

      let Cursong = localStorage.getItem('IsSongPlaying');
      if (Cursong == 'true') {
        localStorage.setItem('IsSongPlaying', 'false');
      } else {
        localStorage.setItem('IsSongPlaying', 'true');
      }
      let CurPlayingsong = localStorage.getItem('IsPlaying');
      if (CurPlayingsong == 'true') {
        localStorage.setItem('IsPlaying', 'false');
      } else {
        localStorage.setItem('IsPlaying', 'true');
      }
      //this.playlistService.playPauseBtn = [];
    }
  }

  playBtnHandler(): void {
    if (this.loaderDisplay) {
      return;
    }
    if (this.player.nativeElement.paused) {
      this.player.nativeElement.play(this.currentTime);
    } else {
      this.currentTime = this.player.nativeElement.currentTime;
      this.player.nativeElement.pause();
    }
  };

  playalbumSong(songs, index) {
    debugger;
    this.ShowPlayer = false;
    this._playerService.playSong(songs, this.path, index);
    this._playerService.playForArtist = true;
    this._playerService.msaapPlaylist = [];
    for (var i = 0; i < songs.length; i++) {
      const tractObj = new Track();
      tractObj.index = i;
      tractObj.image = songs[i].thumbImage;
      tractObj.artistName = songs[i].artistName;
      tractObj.link = songs[i].streamUrl;
      tractObj.title = songs[i].title;
      tractObj.songId = songs[i].songId;
      this._playerService.msaapPlaylist.push(tractObj);
      // this.updatecount(tractObj.songId);
    }

    if (this.playerService.repeatSong) {
      this.playerService.repeatSong = false;
    }
    this.playerService.setPlaylist(this._playerService.msaapPlaylist);

    this.playerService.selectATrack(index + 1);
    this._playerService.updateData(this._playerService.msaapPlaylist);
  }

  GetSongsDetailsbyalbumid(album: any, index: any) {
    debugger;
    this._songsService.getSongsByAlbumId(this.userDetails.userId,album, this.pageIndex, this.pageSize).subscribe((data: any) => {
      // this.totalcount = data.count;
      console.log(data);
      this.songdata = data.songs;
      this.playalbumSong(this.songdata, index);
      // this.songurls = 
    });
  }

  OnartistChange(e: any) {
    if (e.length > -1) {
      this.objToCoartist = "";
      let obj = "";
      //this.objToSave[indx] = e;
      obj = e;
      this.objToCoartist = obj + this.objToCoartist;
      console.log(this.objToCoartist);
      this.submitted = false;
    }
  }

}
