export class Artist {
    artistId: any=[];
    description: string;
    fullName: string;
    thumbImage: any;
    largeImage: string;
    // userName: any;

    password: any;
    emailId: any;
    mobileNumber: any;
    statusId: number;
    isforKid:boolean;
    createdBy:any;
    updatedBy:any;
    NoofLikes:any;
    NoofFavs:any;
    ////==========base64formatedata Varibles=========////
    base64Data: any;
    fileName: any;
    fileType: any;
    languageCode:any;
    languageId:any;
    songLanguageCode:any;
    themeLanguageCode:any;
}

export class ArtistFollow{
    artistFollowerID:any;
    artistId:any;
    userId:any;
}